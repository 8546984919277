import React, {RefObject} from "react";
import {
    ActionType,
    BaseComponent,
    IBaseComponent,
    PageCacheProvider,
    TextAlign
} from "@reapptor-apps/reapptor-react-common";
import {CellAction, CellModel, ColumnActionDefinition, ColumnDefinition, ColumnType, DropdownRequiredType, Grid, GridHoveringType, GridOddType} from "@reapptor-apps/reapptor-react-components";
import User from "@/models/server/User";
import Boat from "@/models/server/bout/Boat";
import SaveBoatRequest from "@/models/server/requests/SaveBoatRequest";
import {BoatType} from "@/models/Enums";
import BoatPrice from "@/models/server/bout/BoatPrice";
import ListBoatPricesRequest from "@/models/server/requests/ListBoatPricesRequest";
import SaveMyBoatResponse from "@/models/server/responses/SaveMyBoatResponse";
import ListAreasRequest from "@/models/server/requests/ListAreasRequest";
import ListAreasResponse from "@/models/server/responses/ListAreasResponse";
import Area from "@/models/server/bout/Area";
import Localizer from "@/localization/Localizer";
import AppController from "@/pages/AppController";

interface IBoatsPanelProps {
    captain: User
}

interface IBoatsPanelState {
}

export default class BoatsPanel extends BaseComponent<IBoatsPanelProps, IBoatsPanelState> {

    state: IBoatsPanelState = {
    };

    private readonly _captainBoatsGridRef: RefObject<Grid<Boat>> = React.createRef();

    private readonly _boatColumns: ColumnDefinition[] = [
        {
            header: Localizer.boatsPanelGridBrandLanguageItemName,
            accessor: nameof<Boat>(o => o.brand),
            editable: false,
            minWidth: 110,
            stretch: true,
            settings: {
                infoAccessor: nameof<Boat>(o => o.model),
            }
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridCapacityLanguageItemName,
            accessor: nameof<Boat>(o => o.maxCapacity),
            editable: false,
            minWidth: 75,
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridHorsePowerLanguageItemName,
            accessor: nameof<Boat>(o => o.horsepower),
            format: "0",
            editable: true,
            type: ColumnType.Number,
            settings: {
                min: 0,
                max: 999,
                hideZero: true
            },
            minWidth: 75,
            maxWidth: 75
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridCruiseSpeedLanguageItemName,
            accessor: nameof<Boat>(o => o.cruiseSpeed),
            format: "0",
            editable: true,
            type: ColumnType.Number,
            settings: {
                min: 0,
                max: 99,
                hideZero: true
            },
            minWidth: 75,
            maxWidth: 75
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridIsSpeedLanguageItemName,
            accessor: (model: Boat) => model.isSpeedBoat ? "✓" : "",
            textAlign: TextAlign.Center,
            editable: false,
            minWidth: 50,
            maxWidth: 50
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridTypeLanguageItemName,
            accessor: nameof.full<Boat>(o => o.boatType),
            textAlign: TextAlign.Center,
            editable: false,
            minWidth: 75,
            maxWidth: 150,
            format: nameof(BoatType)
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridAreaLanguageItemName,
            accessor: nameof.full<Boat>(o => o.area),
            type: ColumnType.Dropdown,
            editable: true,
            minWidth: 150,
            callback: (cell: CellModel<Boat>) => this.setAreaAsync(cell),
            settings: {
                fetchItems: (cell: CellModel<Boat>) => this.fetchAreasAsync(cell),
                required: true,
                requiredType: DropdownRequiredType.AutoSelect,
            }
        } as ColumnDefinition,
        {
            name: "boatPrice",
            header: Localizer.boatsPanelGridPriceLanguageItemName,
            accessor: nameof.full<Boat>(o => o.boatPrice),
            type: ColumnType.Dropdown,
            editable: true,
            minWidth: 200,
            callback: (cell: CellModel<Boat>) => this.setBoatPriceAsync(cell),
            settings: {
                fetchItems: (cell: CellModel<Boat>) => this.getBoatPricesAsync(cell),
                required: true,
                requiredType: DropdownRequiredType.AutoSelect,
            },
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridMarginLanguageItemName,
            accessor: nameof<Boat>(o => o.margin),
            editable: true,
            type: ColumnType.Number,
            settings: {
                min: 0,
                max: 100,
                step: 0.1,
                hideZero: true,
            },
            minWidth: 75,
            maxWidth: 75
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridRatingLanguageItemName,
            accessor: (model: Boat) => (model.rating) ? model.rating : "-",
            editable: false,
            textAlign: TextAlign.Center,
            minWidth: 75,
            maxWidth: 75
        } as ColumnDefinition,
        {
            header: Localizer.genericActionsLanguageItemName,
            removable: false,
            init: (cell) => this.initBoatOperations(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.genericSave,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: (cell, action) => this.processBoatOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "cancel",
                    title: Localizer.genericCancel,
                    icon: "far ban",
                    type: ActionType.Delete,
                    callback: (cell, action) => this.processBoatOperationAsync(cell, action)
                } as ColumnActionDefinition,
            ]
        } as ColumnDefinition,
    ];

    private async getBoatPricesAsync(cell: CellModel<Boat>): Promise<BoatPrice[]> {
        const model: Boat = cell.model;
        const sender: IBaseComponent = cell.grid.instance;

        const request = new ListBoatPricesRequest();
        request.areaId = model.areaId;

        return await PageCacheProvider.getAsync(`listBoatPrices:${request.areaId}`, () => sender.postAsync("/api/admin/listBoatPrices", request));
    }

    private async fetchAreasAsync(cell: CellModel<Boat>): Promise<Area[]> {
        const model: Boat = cell.model;
        const sender: IBaseComponent = cell.grid.instance;
        
        const request = new ListAreasRequest();
        request.countryCode = model.homeLocation?.country || "fi";
        request.withBoatPricesOnly = true;
        request.asAdmin = AppController.asAdmin;
        request.asCaptain = AppController.asCaptain;

        const response: ListAreasResponse = await PageCacheProvider.getAsync(`listAreas:${request.countryCode}`, () => sender.postAsync("/api/country/listAreas", request));

        return response.areas ?? [];
    }

    private async fetchCaptainBoatsAsync(captain: User): Promise<Boat[]> {
        return (captain.id)
            ? await this.postAsync<Boat[]>("/api/boat/getCaptainBoats", captain.id)
            : [];
    }
    
    private async setBoatPriceAsync(cell: CellModel<Boat>): Promise<void> {
        const model: Boat = cell.model;

        model.boatPriceId = (model.boatPrice)
            ? model.boatPrice.id
            : null;
    }

    private async setAreaAsync(cell: CellModel<Boat>): Promise<void> {
        const model: Boat = cell.model;

        model.areaId = (model.area)
            ? model.area.id
            : null;

        const boatPriceCell: CellModel<Boat> = cell.next;

        await boatPriceCell.reloadAsync();
    }

    private async processBoatOperationAsync(cell: CellModel<Boat>, action: CellAction<Boat>): Promise<void> {
        const model: Boat = cell.model;

        switch (action.action.name) {
            case "save":
                
                const request = new SaveBoatRequest();
                request.id = model.id;
                request.margin = model.margin;
                request.horsepower = model.horsepower;
                request.cruiseSpeed = model.cruiseSpeed;
                request.boatPriceId = model.boatPriceId || null;
                request.areaId = model.areaId || null;
                const response: SaveMyBoatResponse = await this.postAsync("/api/boat/saveBoat", request);
                
                cell.row.model = response.boat!;
                
                await cell.row.saveAsync();
                
                break;
                
            case "cancel":
                
                await cell.row.cancelAsync();
                
                break;
        }
    }

    private initBoatOperations(cell: CellModel<Boat>): void {
        const modified: boolean = cell.row.modified;

        const saveAction: CellAction<Boat> = cell.actions[0];
        const cancelAction: CellAction<Boat> = cell.actions[1];

        saveAction.visible = modified;
        cancelAction.visible = modified;
    }

    public async reloadAsync() {
        await this._captainBoatsGridRef.current?.reloadAsync();
    }

    public render(): React.ReactNode {
        return (
            <Grid ref={this._captainBoatsGridRef}
                  hovering={GridHoveringType.Row}
                  odd={GridOddType.None}
                  minWidth="auto"
                  noDataText={Localizer.genericNoData}
                  columns={this._boatColumns}
                  fetchData={() => this.fetchCaptainBoatsAsync(this.props.captain)}
            />
        );
    }
}