// autogenerated

export default class ListWaypointsRequest {

    public countryId: string | null = null;

    public areaId: string | null = null;

    public countryIds: string[] | null = null;

    public areaIds: string[] | null = null;

    public fixedPriceBoutPriceId: string | null = null;

    public sourceServicePointId: string | null = null;

    public activeOnly: boolean = false;

    public asAdmin: boolean = false;

    public search: string | null = null;

    public includeIntermediateWaypoints: boolean = false;

    public includeFixedPrices: boolean = false;

    public pageNumber: number = 0;

    public pageSize: number = 0;

    public readonly isListWaypointsRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ListWaypointsRequest) || ((from as any).isListWaypointsRequest === true));
    }

    public static as(from?: object | null): ListWaypointsRequest | null {
        return (ListWaypointsRequest.is(from)) ? from as ListWaypointsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}