// autogenerated
import SaveUserRequestBase from "./SaveUserRequestBase";

export default class SaveUserRequest extends SaveUserRequestBase {

    public readonly isSaveUserRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof SaveUserRequest) || ((from as any).isSaveUserRequest === true));
    }

    public static as(from?: object | null): SaveUserRequest | null {
        return (SaveUserRequest.is(from)) ? from as SaveUserRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}