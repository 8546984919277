import React from "react";
import {GeoLocation, Utility} from "@reapptor-apps/reapptor-toolkit";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {
    AddressDivider,
    AddressDividerColumns,
    AddressInput,
    Checkbox,
    DateInput,
    Dropdown,
    DropdownAlign,
    DropdownOrderBy,
    EmailInput,
    Form,
    FourColumns,
    InlineType,
    NumberInput,
    NumberInputBehaviour,
    OneColumn,
    Panel, PasswordInput,
    PhoneInput,
    TextInput,
    TwoColumns
} from "@reapptor-apps/reapptor-react-components";
import ServiceProvider from "@/models/server/ServiceProvider";
import Country from "@/models/server/bout/Country";
import Area from "@/models/server/bout/Area";
import {ApplicationType, PaymentPolicy} from "@/models/Enums";
import ValidatePhoneNumberRequest from "@/models/server/requests/ValidatePhoneNumberRequest";
import AppConstants from "@/helpers/AppConstants";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./ApplicationPanel.module.scss"

interface IApplicationPanelProps {
    serviceProvider: ServiceProvider;
    countries: Country[];
    areas: Area[];
}

interface IApplicationPanelState {
}

export default class ApplicationPanel extends BaseComponent<IApplicationPanelProps, IApplicationPanelState> {

    state: IApplicationPanelState = {};

    private readonly _formRef: React.RefObject<Form> = React.createRef();

    private get selectedCountries(): Country[] {
        const countryIds: string[] = this.serviceProvider?.countryIds ?? [];
        return this.props.countries.where(item => countryIds.contains(item.id));
    }

    private get selectedCountriesCodes(): string[] {
        return this.selectedCountries.select(item => item.code);
    }

    private get hasBookingDepth(): boolean {
        return ((this.serviceProvider.minBookingDepthInHours != null) || (this.serviceProvider.maxBookingDepthInHours != null));
    }

    private get hasBookingRange(): boolean {
        return ((this.serviceProvider.minBookingRangeInMinutes != null) || (this.serviceProvider.maxBookingRangeInMinutes != null));
    }

    private get hasEstimatedBookingExpirationInMinutes(): boolean {
        return (this.serviceProvider.estimatedBookingExpirationInMinutes != null);
    }

    private async setSlugAsync(value: string): Promise<void> {
        if (this.serviceProvider.slug != value) {
            this.serviceProvider.slug = value;
            await this.reRenderAsync();
        }
    }

    private async setLandingPageAsync(value: boolean): Promise<void> {
        if (this.serviceProvider.landingPage != value) {
            this.serviceProvider.landingPage = value;
            await this.reRenderAsync();
        }
    }

    private async setPassengerSignUpAvailableAsync(value: boolean): Promise<void> {
        if (this.serviceProvider.passengerSignUpAvailable != value) {
            this.serviceProvider.passengerSignUpAvailable = value;
            await this.reRenderAsync();
        }
    }

    private async setFailedPaymentEmailAsync(value: boolean): Promise<void> {
        if (this.serviceProvider.failedPaymentEmail != value) {
            this.serviceProvider.failedPaymentEmail = value;
            await this.reRenderAsync();
        }
    }

    private async setCaptainSignUpAvailableAsync(value: boolean): Promise<void> {
        if (this.serviceProvider.captainSignUpAvailable != value) {
            this.serviceProvider.captainSignUpAvailable = value;
            await this.reRenderAsync();
        }
    }

    private async setAndroidAppAvailableAsync(value: boolean): Promise<void> {
        if (this.serviceProvider.androidAppAvailable != value) {
            this.serviceProvider.androidAppAvailable = value;
            await this.reRenderAsync();
        }
    }

    private async setAppleAppAvailableAsync(value: boolean): Promise<void> {
        if (this.serviceProvider.appleAppAvailable != value) {
            this.serviceProvider.appleAppAvailable = value;
            await this.reRenderAsync();
        }
    }

    private async setContactEmailAsync(value: string): Promise<void> {
        if (this.serviceProvider.contactEmail != value) {
            this.serviceProvider.contactEmail = value;
            await this.reRenderAsync();
        }
    }

    private async setEmailSenderAsync(value: string): Promise<void> {
        if (this.serviceProvider.emailSender != value) {
            this.serviceProvider.emailSender = value;
            await this.reRenderAsync();
        }
    }

    private async setEmailServerApiKeyAsync(value: string): Promise<void> {
        if (this.serviceProvider.emailServerApiKey != value) {
            this.serviceProvider.emailServerApiKey = value;
            await this.reRenderAsync();
        }
    }

    private async setEmailServerApiUrlAsync(value: string): Promise<void> {
        if (this.serviceProvider.emailServerApiUrl != value) {
            this.serviceProvider.emailServerApiUrl = value;
            await this.reRenderAsync();
        }
    }

    private async setPersonalInformationWarningAsync(value: boolean): Promise<void> {
        if (this.serviceProvider.personalInformationWarning != value) {
            this.serviceProvider.personalInformationWarning = value;
            await this.reRenderAsync();
        }
    }

    private async setApplicationUrlAsync(value: string): Promise<void> {
        if (this.serviceProvider.applicationUrl != value) {
            this.serviceProvider.applicationUrl = value;
            await this.reRenderAsync();
        }
    }

    private async setSenderPhoneNumberAsync(value: string): Promise<void> {
        if (this.serviceProvider.smsSenderPhoneNumber != value) {
            this.serviceProvider.smsSenderPhoneNumber = value;
            await this.reRenderAsync();
        }
    }

    private async setSmsSenderShortNameAsync(value: string): Promise<void> {
        if (this.serviceProvider.smsSenderShortName != value) {
            this.serviceProvider.smsSenderShortName = value;
            await this.reRenderAsync();
        }
    }

    private async setApplicationNameAsync(value: string): Promise<void> {
        if (this.serviceProvider.applicationName != value) {
            this.serviceProvider.applicationName = value;
            await this.reRenderAsync();
        }
    }

    private async setPaymentPolicyAsync(value: PaymentPolicy): Promise<void> {
        if (this.serviceProvider.paymentPolicy != value) {
            this.serviceProvider.paymentPolicy = value;
            await this.reRenderAsync();
        }
    }

    private async setNoRefundAsync(value: boolean): Promise<void> {
        if (this.serviceProvider.noRefund != value) {
            this.serviceProvider.noRefund = value;
            await this.reRenderAsync();
        }
    }

    private async setHasBookingDepthAsync(value: boolean): Promise<void> {
        if (this.serviceProvider) {

            if (value) {
                this.serviceProvider.minBookingDepthInHours = 0;
                this.serviceProvider.maxBookingDepthInHours = 0;
            } else {
                this.serviceProvider.minBookingDepthInHours = null;
                this.serviceProvider.maxBookingDepthInHours = null;
            }

            await this.reRenderAsync();
        }
    }

    private async setHasEstimatedBookingExpirationInMinutesAsync(value: boolean): Promise<void> {
        if (this.serviceProvider) {

            if (value) {
                this.serviceProvider.estimatedBookingExpirationInMinutes = 0;
            } else {
                this.serviceProvider.estimatedBookingExpirationInMinutes = null;
            }

            await this.reRenderAsync();
        }
    }

    private async setEstimatedBookingExpirationInMinutesAsync(value: number): Promise<void> {
        if (this.serviceProvider) {
            this.serviceProvider.estimatedBookingExpirationInMinutes = value;
        }
    }

    private async setHasBookingRangeAsync(value: boolean): Promise<void> {
        if (this.serviceProvider) {

            if (value) {
                this.serviceProvider.minBookingRangeInMinutes = 0;
                this.serviceProvider.maxBookingRangeInMinutes = 0;
            } else {
                this.serviceProvider.minBookingRangeInMinutes = null;
                this.serviceProvider.maxBookingRangeInMinutes = null;
            }

            await this.reRenderAsync();
        }
    }

    private async setHasShuttleMaxTicketsAsync(value: boolean): Promise<void> {
        this.serviceProvider.shuttleMaxTickets = (value)
            ? AppConstants.defaultShuttleMaxTickets
            : null;

        await this.reRenderAsync();
    }

    private async setShuttleMaxTicketsAsync(value: number): Promise<void> {
        this.serviceProvider.shuttleMaxTickets = value;
        await this.reRenderAsync();
    }

    private async setMinBookingDepthInHoursAsync(value: number): Promise<void> {
        if (this.serviceProvider) {
            this.serviceProvider.minBookingDepthInHours = value;
        }
    }

    private async setMaxBookingDepthInHoursAsync(value: number): Promise<void> {
        if (this.serviceProvider) {
            this.serviceProvider.maxBookingDepthInHours = value;
        }
    }

    private async setMinBookingRangeInMinutesAsync(value: Date): Promise<void> {
        if (this.serviceProvider) {
            const minutes: number = Utility.diff(value, Utility.today()).totalMinutes;
            
            this.serviceProvider.minBookingRangeInMinutes = minutes;
            
            const maxBookingRangeInMinutes: number = this.serviceProvider.maxBookingRangeInMinutes ?? 0;
            if (minutes > maxBookingRangeInMinutes) {
                this.serviceProvider.maxBookingRangeInMinutes = minutes;
                await this.reRenderAsync();
            }
        }
    }

    private async setMaxBookingRangeInMinutesAsync(value: Date): Promise<void> {
        if (this.serviceProvider) {
            const minutes: number = Utility.diff(value, Utility.today()).totalMinutes;
            
            this.serviceProvider.maxBookingRangeInMinutes = minutes;
            
            const minBookingRangeInMinutes: number = this.serviceProvider.minBookingRangeInMinutes ?? 0;
            if (minutes < minBookingRangeInMinutes) {
                this.serviceProvider.minBookingRangeInMinutes = minutes;
                await this.reRenderAsync();
            }
        }
    }

    private async onCompanyAddressChangeAsync(location: GeoLocation): Promise<void> {
        this.serviceProvider.companyVirtualAddress = null;
        this.serviceProvider.companyAddress = location;
    }

    private async onVirtualAddressChangeAsync(location: string): Promise<void> {
        this.serviceProvider.companyVirtualAddress = location;
        this.serviceProvider.companyAddress = null;
        this.serviceProvider.companyAddressId = null;
    }

    private async setLocationAsync(location: GeoLocation): Promise<void> {
        this.serviceProvider.location = location;
        await this.reRenderAsync();
    }

    private async onVirtualAddressCheckboxChangeAsync(value: boolean) {
        this.serviceProvider.isVirtualCompanyAddress = value;
        await this.setState({isCompanyVirtualAddress: value});
    }

    private async validatePhoneNumberAsync(value: string | null): Promise<boolean> {
        if (value) {
            const request = new ValidatePhoneNumberRequest();
            request.value = value;
            request.personalOnly = false;

            return await this.postAsync("/api/user/isPhoneNumberValid", request);
        }

        return false;
    }
    
    private async onBlurAsync(sender: PhoneInput): Promise<void> {
        const isValidPhoneNumber: boolean = await this.validatePhoneNumberAsync(this.serviceProvider.smsSenderPhoneNumber);

        if (!isValidPhoneNumber) {
            await sender.validateAsync();
        }
    }
    
    public async isFormValidAsync(): Promise<boolean> {
        return this._formRef.current?.validateAsync() || false;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public get serviceProvider(): ServiceProvider {
        return this.props.serviceProvider;
    }

    public get hasShuttleMaxTickets(): boolean {
        return (this.serviceProvider.shuttleMaxTickets != null);
    }

    public get hasShuttleType(): boolean {
        return (this.serviceProvider.applicationTypes.includes(ApplicationType.Shuttle));
    }

    public render(): React.ReactNode {
        return (
            <Panel className={this.css("flex-2", styles.applicationPanel)}>
                <div className="col-12">

                    <Form ref={this._formRef} className={styles.form}>

                        <TwoColumns>

                            <TextInput id="applicationName" required noAutoComplete
                                       label={Localizer.serviceProvidersManagementPageWhiteLabelApplicationName}
                                       value={this.serviceProvider.applicationName}
                                       onChange={(_, value) => this.setApplicationNameAsync(value)}
                            />

                            <TextInput id="applicationUrl" required noAutoComplete
                                       label={Localizer.serviceProvidersManagementPageWhiteLabelApplicationUrl}
                                       value={this.serviceProvider.applicationUrl}
                                       onChange={(_, value) => this.setApplicationUrlAsync(value)}
                            />

                        </TwoColumns>

                        <FourColumns>

                            <TextInput id="slug" required noAutoComplete
                                       label={Localizer.serviceProvidersManagementPageSlug}
                                       value={this.serviceProvider.slug}
                                       onChange={(_, value) => this.setSlugAsync(value)}
                            />

                            <Checkbox id={nameof(this.serviceProvider.landingPage)}
                                      label={Localizer.serviceProvidersManagementPageLandingPage}
                                      value={this.serviceProvider.landingPage ?? false}
                                      onChange={(_, value) => this.setLandingPageAsync(value)}
                            />

                        </FourColumns>

                        <FourColumns>

                            <Checkbox id={nameof(this.serviceProvider.passengerSignUpAvailable)}
                                      label={Localizer.serviceProvidersManagementPagePassengerSignUpAvailable}
                                      value={this.serviceProvider.passengerSignUpAvailable ?? false}
                                      onChange={(_, value) => this.setPassengerSignUpAvailableAsync(value)}
                            />

                            <Checkbox id={nameof(this.serviceProvider.captainSignUpAvailable)}
                                      label={Localizer.serviceProvidersManagementPageCaptainSignUpAvailable}
                                      value={this.serviceProvider.captainSignUpAvailable ?? false}
                                      onChange={(_, value) => this.setCaptainSignUpAvailableAsync(value)}
                            />

                            <Checkbox id={nameof(this.serviceProvider.androidAppAvailable)}
                                      label={Localizer.serviceProvidersManagementPageAndroidAppAvailable}
                                      value={this.serviceProvider.androidAppAvailable ?? false}
                                      onChange={(_, value) => this.setAndroidAppAvailableAsync(value)}
                            />

                            <Checkbox id={nameof(this.serviceProvider.appleAppAvailable)}
                                      label={Localizer.serviceProvidersManagementPageAppleAppAvailable}
                                      value={this.serviceProvider.appleAppAvailable ?? false}
                                      onChange={(_, value) => this.setAppleAppAvailableAsync(value)}
                            />

                        </FourColumns>

                        <FourColumns>

                            <Checkbox id={nameof(this.serviceProvider.failedPaymentEmail)}
                                      label={Localizer.serviceProvidersManagementPageFailedPaymentEmail}
                                      value={this.serviceProvider.failedPaymentEmail ?? false}
                                      onChange={(_, value) => this.setFailedPaymentEmailAsync(value)}
                            />

                        </FourColumns>

                        <FourColumns>

                            <PhoneInput trim noAutoComplete
                                        id="senderPhoneNumber"
                                        maxLength={AppConstants.descriptionLength}
                                        onBlur={(sender) => this.onBlurAsync(sender as PhoneInput)}
                                        label={Localizer.serviceProvidersManagementPageWhiteLabelSmsSenderPhone}
                                        value={this.serviceProvider.smsSenderPhoneNumber}
                                        onChange={(_, value) => this.setSenderPhoneNumberAsync(value)}
                            />

                            <TextInput id="smsSenderShortName" noAutoComplete
                                       maxLength={AppConstants.hashLength}
                                       label={Localizer.serviceProvidersManagementPageWhiteLabelSmsSenderShortName}
                                       value={this.serviceProvider.smsSenderShortName}
                                       onChange={(_, value) => this.setSmsSenderShortNameAsync(value)}
                            />

                        </FourColumns>

                        <hr/>

                        <TwoColumns>

                            <EmailInput id="contactEmail" required noAutoComplete
                                        label={Localizer.serviceProvidersManagementPageWhiteLabelContactEmail}
                                        value={this.serviceProvider.contactEmail}
                                        onChange={(_, value) => this.setContactEmailAsync(value)}
                            />

                            <EmailInput id="emailSender" required noAutoComplete
                                        label={Localizer.serviceProvidersManagementPageWhiteLabelEmailSender}
                                        value={this.serviceProvider.emailSender}
                                        onChange={(_, value) => this.setEmailSenderAsync(value)}
                            />

                        </TwoColumns>

                        <TwoColumns>

                            <TextInput id="emailServerApiUrl" noAutoComplete
                                       label={Localizer.serviceProvidersManagementPageEmailServerApiUrl}
                                       value={this.serviceProvider.emailServerApiUrl}
                                       onChange={(_, url: string) => this.setEmailServerApiUrlAsync(url)}
                            />

                            <PasswordInput id="emailServerApiKey" noAutoComplete
                                           label={Localizer.serviceProvidersManagementPageEmailServerApiKey}
                                           value={this.serviceProvider.emailServerApiKey}
                                           onChange={(_, key: string) => this.setEmailServerApiKeyAsync(key)}
                            />

                        </TwoColumns>

                        <TwoColumns>

                            <Checkbox id={nameof(this.serviceProvider.personalInformationWarning)}
                                      label={Localizer.serviceProvidersManagementPagePersonalInformationWarning}
                                      value={this.serviceProvider.personalInformationWarning ?? false}
                                      onChange={(_, value) => this.setPersonalInformationWarningAsync(value)}
                            />

                        </TwoColumns>

                        <FourColumns>

                            <Dropdown required autoCollapse noWrap noSubtext
                                      id="paymentPolicy"
                                      orderBy={DropdownOrderBy.None}
                                      align={DropdownAlign.Left}
                                      label={Localizer.serviceProvidersManagementPagePaymentPolicy}
                                      items={EnumProvider.getPaymentPolicyItems()}
                                      selectedItem={this.serviceProvider.paymentPolicy}
                                      onChange={(_, item) => this.setPaymentPolicyAsync(parseInt(item!.value))}
                            />

                            <Checkbox id="noRefund"
                                      label={Localizer.serviceProvidersManagementPageNoRefund}
                                      value={this.serviceProvider.noRefund}
                                      onChange={(_, value) => this.setNoRefundAsync(value)}
                            />

                            <Checkbox id="hasEstimatedBookingExpirationInMinutes"
                                      label={Localizer.serviceProvidersManagementPagePrebookingExpiration}
                                      value={this.hasEstimatedBookingExpirationInMinutes}
                                      onChange={(_, value) => this.setHasEstimatedBookingExpirationInMinutesAsync(value)}
                            />

                            {
                                (this.hasEstimatedBookingExpirationInMinutes) &&
                                (
                                    <NumberInput id={nameof(this.serviceProvider.estimatedBookingExpirationInMinutes)}
                                                 label={Localizer.serviceProvidersManagementPageExpirationTimeoutMin}
                                                 value={this.serviceProvider.estimatedBookingExpirationInMinutes}
                                                 onChange={(_, value) => this.setEstimatedBookingExpirationInMinutesAsync(value)}
                                    />
                                )
                            }

                        </FourColumns>

                        <TwoColumns>

                            <Checkbox id="hasBookingDepth"
                                      label={Localizer.serviceProvidersManagementPageBookingDepth}
                                      value={this.hasBookingDepth}
                                      onChange={(_, value) => this.setHasBookingDepthAsync(value)}
                            />

                            <Checkbox id="hasBookingRange"
                                      label={Localizer.serviceProvidersManagementPageBookingRange}
                                      value={this.hasBookingRange}
                                      onChange={(_, value) => this.setHasBookingRangeAsync(value)}
                            />

                        </TwoColumns>

                        <TwoColumns>

                            <TwoColumns>

                                {
                                    (this.hasBookingDepth) &&
                                    (
                                        <React.Fragment>

                                            <NumberInput id={"minBookingDepthInHours"}
                                                         label={Localizer.serviceProvidersManagementPageMinBookingDepth}
                                                         value={this.serviceProvider.minBookingDepthInHours}
                                                         onChange={(_, value) => this.setMinBookingDepthInHoursAsync(value)}
                                            />

                                            <NumberInput id={"maxBookingDepthInHours"}
                                                         label={Localizer.serviceProvidersManagementPageMaxBookingDepth}
                                                         value={this.serviceProvider.maxBookingDepthInHours}
                                                         onChange={(_, value) => this.setMaxBookingDepthInHoursAsync(value)}
                                            />

                                        </React.Fragment>
                                    )
                                }

                            </TwoColumns>

                            <TwoColumns>

                                {
                                    (this.hasBookingRange) &&
                                    (
                                        <React.Fragment>

                                            <DateInput showTime showOnlyTime
                                                       id={nameof(this.serviceProvider.minBookingRangeInMinutes)}
                                                       className={styles.minBookingRangeInput}
                                                       label={Localizer.serviceProvidersManagementPageBookingStartsAt}
                                                       timeIntervals={30}
                                                       value={Utility.today().addMinutes(this.serviceProvider.minBookingRangeInMinutes ?? 0)}
                                                       onChange={(value) => this.setMinBookingRangeInMinutesAsync(value)}
                                            />

                                            <DateInput showTime showOnlyTime
                                                       id={nameof(this.serviceProvider.maxBookingRangeInMinutes)}
                                                       className={styles.minBookingRangeInput}
                                                       label={Localizer.serviceProvidersManagementPageBookingEndsAt}
                                                       timeIntervals={30}
                                                       value={Utility.today().addMinutes(this.serviceProvider.maxBookingRangeInMinutes ?? 0)}
                                                       onChange={(value) => this.setMaxBookingRangeInMinutesAsync(value)}
                                            />

                                        </React.Fragment>
                                    )
                                }

                            </TwoColumns>

                        </TwoColumns>

                        <hr/>

                        {
                            (this.hasShuttleType) &&
                            (
                                <React.Fragment>

                                    <FourColumns className={styles.shuttleSection}>

                                        {
                                            (this.hasShuttleType) &&
                                            (
                                                <Checkbox id="hasShuttleMaxTickets"
                                                          label={Localizer.serviceProvidersManagementPageLimitTicketPurchases}
                                                          value={this.hasShuttleMaxTickets}
                                                          onChange={(_, value) => this.setHasShuttleMaxTicketsAsync(value)}
                                                />
                                            )
                                        }

                                        {
                                            (this.hasShuttleMaxTickets) &&
                                            (
                                                <NumberInput id="shuttleMaxTickets"
                                                             behaviour={NumberInputBehaviour.Restricted}
                                                             label={Localizer.serviceProvidersManagementPageMaxPurchaseTickets}
                                                             value={this.serviceProvider.shuttleMaxTickets}
                                                             min={1}
                                                             max={99}
                                                             onChange={(_, value) => this.setShuttleMaxTicketsAsync(value)}
                                                />
                                            )
                                        }

                                    </FourColumns>

                                    <hr/>

                                </React.Fragment>
                            )
                        }

                        <TwoColumns>

                            <AddressInput required locationPicker append
                                          id="location"
                                          label={Localizer.serviceProvidersManagementPageDefaultLocation}
                                          country={this.selectedCountriesCodes}
                                          value={this.serviceProvider.location?.formattedAddress || ""}
                                          onChange={(location) => this.setLocationAsync(location)}
                            />

                        </TwoColumns>

                        <OneColumn>

                            <Checkbox inline
                                      inlineType={InlineType.Right}
                                      className={styles.isVirtualAddress}
                                      label={Localizer.serviceProvidersManagementPageWhiteLabelIsVirtualAddress}
                                      value={this.serviceProvider.isVirtualCompanyAddress}
                                      onChange={(sender, item) => this.onVirtualAddressCheckboxChangeAsync(item!)}
                            />

                        </OneColumn>

                        <OneColumn>

                            {
                                (this.serviceProvider.isVirtualCompanyAddress)
                                    ?
                                    (
                                        <TwoColumns>
                                            <TextInput id="virtualAddress" required autoFocus
                                                       label={Localizer.genericAddress}
                                                       placeholder={Localizer.serviceProvidersManagementPageWhiteLabelVirtualAddress}
                                                       width={"100%"}
                                                       value={this.serviceProvider.companyVirtualAddress || undefined}
                                                       onChange={(_, location: string) => this.onVirtualAddressChangeAsync(location)}
                                            />
                                        </TwoColumns>
                                    )
                                    :
                                    (
                                        <AddressDivider id="customerLocation" required
                                                        columns={AddressDividerColumns.Two}
                                                        country={this.selectedCountriesCodes}
                                                        location={this.serviceProvider.companyAddress || undefined}
                                                        onChange={(_, location) => this.onCompanyAddressChangeAsync(location)}
                                        />
                                    )
                            }

                        </OneColumn>

                    </Form>

                </div>

            </Panel>
        );
    }
};