// autogenerated
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class EstimateBookingsRequest extends ETagRequest {

    public waypointId: string = "";

    public bookingTime: Date = new Date();

    public passengers: number = 0;

    public readonly isEstimateBookingsRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof EstimateBookingsRequest) || ((from as any).isEstimateBookingsRequest === true));
    }

    public static as(from?: object | null): EstimateBookingsRequest | null {
        return (EstimateBookingsRequest.is(from)) ? from as EstimateBookingsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}