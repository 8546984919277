import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonContainer, ButtonType, Checkbox, Dropdown, DropdownAlign, DropdownOrderBy, Form, LocationPicker, Modal, ModalSize, NumberInput, Spinner, TextInput, TwoColumns} from "@reapptor-apps/reapptor-react-components"
import {GeoLocation} from "@reapptor-apps/reapptor-toolkit";
import Area from "@/models/server/bout/Area";
import AppConstants from "@/helpers/AppConstants";
import Localizer from "@/localization/Localizer"

import styles from "./ServicePointsModal.module.scss";

interface IServicePointsModalProps {
    areas: Area[] | [];
    onModalSave?(geoLocation: GeoLocation, areaId: string, place: string, areaAlias: string | null, active: boolean): Promise<void>;
    onModalClose?(): Promise<void>;
}

interface IMapPointsModalState {
    geoLocation: GeoLocation | null;
    area: Area | null;
    place: string | null;
    areaAlias: string | null;
    active: boolean;
    canSave: boolean;
}

export default class ServicePointsModal extends BaseComponent<IServicePointsModalProps, IMapPointsModalState> {

    state: IMapPointsModalState = {
        geoLocation: null,
        area: null,
        place: null,
        areaAlias: null,
        active: false,
        canSave: true
    }
    
    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _locationPickerRef: React.RefObject<LocationPicker> = React.createRef();

    public async openAsync(geoLocation: GeoLocation | null = null, place: string | null = null, area: Area | null = null, areaAlias: string | null = null, active: boolean = false, animation: boolean = true): Promise<void> {
        if (this._modalRef.current) {

            await this.setState({place, active, area, areaAlias, geoLocation: geoLocation ? geoLocation : ServicePointsModal.initialLocation});

            await this._modalRef.current.openAsync(animation);
        }
    }

    public async onMapClickAsync(geoLocation: GeoLocation | null): Promise<void> {
        await this.setState({geoLocation})
    }

    public async closeAsync(): Promise<void> {
        if (this._modalRef.current) {
            await this._modalRef.current.closeAsync();
        }
        await this.clearState();
        await this.reRenderAsync();

        if (this.props.onModalClose) {
            await this.props.onModalClose();
        }
    }

    public async onAreaChangeAsync(area: Area): Promise<void> {
        await this.setState({area: area});
    }

    public async onAreaAliasChangeAsync(areaAlias: string): Promise<void> {
        await this.setState({areaAlias});
    }

    public async onPlaceChangeAsync(place: string): Promise<void> {
        await this.setState({place});
    }

    public async onActiveChangeAsync(active: boolean): Promise<void> {
        await this.setState({active});
    }
    
    private isValid(): boolean {
        return (this.state.place !== null) && (this.state.place.trim().length > 0);
    }

    private async clearState(): Promise<void> {
        await this.setState({geoLocation: null, place: null, canSave: true});
    }

    private static get initialLocation(): GeoLocation {
        return new GeoLocation(60.152114443344786, 24.946680470898425);
    }

    private get geoLocation(): GeoLocation | null {
        return this.state.geoLocation;
    }

    public async onSaveModalClickAsync(): Promise<void> {
        
        await this.setState({canSave: false})
        
        if (this.isValid()) {
            if (this.props.onModalSave) {
                await this.props.onModalSave(this.state.geoLocation!, this.state.area!.id, this.state.place!, this.state.areaAlias, this.state.active);
                await this.closeAsync();
            }
        }
    }

    public static get modalId(): string {
        return "servicePointsModal";
    }
    
    public renderContent(): React.ReactNode {
        return (
            <React.Fragment>

                <div className={styles.map}>

                    <LocationPicker ref={this._locationPickerRef}
                                    location={this.geoLocation || undefined}
                                    country={AppConstants.supportedLocationCountries}
                                    onChange={(sender, location) => this.onMapClickAsync(location)}
                    />

                </div>

                <Form id="form" 
                      onSubmit={() => this.onSaveModalClickAsync()}
                >
                    <TwoColumns>
                        <Dropdown required noWrap noSubtext
                                  id="areas"
                                  title={Localizer.waypointModalDropdownAreasTitle}
                                  label={Localizer.waypointModalDropdownAreasLabel}
                                  minWidth="80px"
                                  nothingSelectedText={Localizer.waypointModalDropdownAreasNothingSelectedText}
                                  align={DropdownAlign.Left}
                                  orderBy={DropdownOrderBy.Name}
                                  selectedItem={this.state.area}
                                  items={this.props.areas}
                                  onChange={(sender, value) => this.onAreaChangeAsync(value!)}
                        />
                        
                        <TextInput id="areaAlias"
                                   title={Localizer.waypointModalDropdownAreaAliasTitle}
                                   label={Localizer.waypointModalDropdownAreaAliasLabel}
                                   value={this.state.areaAlias || ""}
                                   onChange={(sender, value) => this.onAreaAliasChangeAsync(value)}
                        />

                    </TwoColumns>

                    <TwoColumns>

                        <TextInput required
                                   id="place"
                                   title={Localizer.servicePointsModalTextInputPlaceTitle}
                                   label={Localizer.servicePointsModalTextInputPlaceLabel}
                                   value={this.state.place || ""}
                                   onChange={(sender, value) => this.onPlaceChangeAsync(value)}
                        />

                        <Checkbox label={Localizer.servicePointsModalCheckboxActiveLabel}
                                  value={this.state.active}
                                  onChange={(sender, value) => this.onActiveChangeAsync(value)}
                        />

                    </TwoColumns>

                    <TwoColumns>

                        <NumberInput readonly
                                     title={Localizer.servicePointsModalNumberInputLatitudeTitle}
                                     label={Localizer.servicePointsModalNumberInputLatitudeLabel}
                                     //behaviour={NumberInputBehaviour.ValidationOnChange}
                                     value={this.geoLocation?.lat}
                        />

                        <NumberInput readonly
                                     title={Localizer.servicePointsModalNumberInputLongitudeTitle}
                                     label={Localizer.servicePointsModalNumberInputLongitudeLabel}
                                     //behaviour={NumberInputBehaviour.ValidationOnChange}
                                     value={this.geoLocation?.lon}
                        />

                    </TwoColumns>

                    {
                        (this.props.onModalSave) &&
                        (
                            <ButtonContainer>
                                
                                <Button submit
                                        type={ButtonType.Primary}
                                        label={Localizer.genericSave}
                                        icon={{name: "far save"}}
                                        disabled={!this.state.canSave}
                                />
                                
                                <Button type={ButtonType.Default}
                                        label={Localizer.genericCancel}
                                        onClick={() => this.closeAsync()}
                                />
                                
                            </ButtonContainer>
                        )
                    }
                    
                </Form>

            </React.Fragment>
        );
    }

    public render(): React.ReactNode {
        return (
            <Modal id={ServicePointsModal.modalId}
                   ref={this._modalRef}
                   className={styles.servicePointsModal}
                   title={Localizer.servicePointsModalModalTitle}
                   subtitle={Localizer.servicePointsModalModalSubtitle}
                   size={ModalSize.Large}
                   onClose={() => this.closeAsync()}
            >
                {
                    (this.isSpinning())
                        ? <Spinner/>
                        : this.renderContent()
                }

            </Modal>
        )
    }
}