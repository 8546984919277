import React from "react";
import {AlertModel, AlertType, BasePageParameters, LdData} from "@reapptor-apps/reapptor-react-common";
import AnonymousPage from "../../models/base/AnonymousPage";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import User from "../../models/server/User";
import {
    Button,
    ButtonContainer,
    ButtonType,
    EmailInput,
    Form,
    IStringInputModel,
    PageContainer,
    PageHeader,
    PageRow,
    TextAreaInput,
    TextInput,
    LdDataHeader,
    Alert
} from "@reapptor-apps/reapptor-react-components";
import AppController from "@/pages/AppController";
import ServiceProviderController from "@/pages/ServiceProviderController";
import Localizer from "../../localization/Localizer";

export interface IContactSupportParameters extends BasePageParameters {
    requestId: string | null;
}

interface IContactSupportState {
    applicationLdData: LdData | null;
}

export default class ContactSupport extends AnonymousPage<IContactSupportParameters> {

    state: IContactSupportState = {
        applicationLdData: null,
    };
    
    public email: IStringInputModel = { value: "" };
    public phone: IStringInputModel = { value: "" };
    public firstname: IStringInputModel = { value: "" };
    public lastname: IStringInputModel = {value: ""};
    public message: IStringInputModel = {value: ""};
    public formRef: React.RefObject<any> = React.createRef();
     
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const user: User | null = this.findUser();
        if (user) {
            this.email.value = user.email || "";
            this.phone.value = user.phone || "";
            this.firstname.value = user.firstname || "";
            this.lastname.value = user.lastName || "";
        }

        const pageUrl: string = window.location.origin;

        const applicationLdData: LdData = await this.postAsync("/api/application/getApplicationLdData", pageUrl);

        await this.setState({applicationLdData});
    }

    public async handleSubmitAsync(data: Dictionary<string, any>): Promise<void> {
        const contactSupportRequest = {} as any;
        data.keys().map(key => {
            return contactSupportRequest[key] = data.getValue(key);
        });
        
        await this.postAsync("/api/ContactSupport/ContactSupport", contactSupportRequest);
    }

    public get requestId(): string | null {
        return (this.props.parameters != null) ? this.props.parameters.requestId : null;
    }
    
    public get pageHeader(): string {
        return (ServiceProviderController.isHds)
            ? Localizer.contactSupportPageTitleHds
            : Localizer.contactSupportPageTitle;
    }

    private getAlert(): AlertModel | null {
        if (this.personalInformationWarning) {
            const alert = new AlertModel(Localizer.contactSupportPagePersonalInformationWarning, AlertType.Warning);
            alert.dismissible = false;
            return alert;
        }

        return null;
    }
    
    public get personalInformationWarning(): boolean {
        return ServiceProviderController.personalInformationWarning;
    }

    public getTitle(): string {
        return AppController.getFullPageTitle(Localizer.topNavContactSupport);
    }

    public render(): React.ReactNode {

        const alert: AlertModel | null = this.getAlert();
        
        return (
            <PageContainer>
                
                <LdDataHeader data={this.state.applicationLdData} 
                              canonical={"/support"}
                              defaultLanguage={ServiceProviderController.applicationDefaultLanguage}
                />
                
                <PageHeader title={this.pageHeader} />

                {
                    (alert) &&
                    (
                            <Alert model={alert}/>
                    )
                }
                
                <PageRow>
                    <div className="col-lg-12">
                        <Form id="form" onSubmit={(_, data) => this.handleSubmitAsync(data)} ref={this.formRef}>
                            <EmailInput id="email" label={Localizer.formInputEmail} model={this.email} required readonly={!!this.email.value} />
                            <TextInput id="phone" label={Localizer.formInputPhone} model={this.phone} />
                            <TextInput id="firstname" label={Localizer.formInputFirstname} model={this.firstname} />
                            <TextInput id="lastname" label={Localizer.formInputLastname} model={this.lastname} />
                            <TextAreaInput id="message" label={Localizer.formInputMessage} model={this.message} required autoFocus rows={6} />
                            
                            <ButtonContainer>
                                <Button type={ButtonType.Orange} label={Localizer.formSend} submit />
                            </ButtonContainer>
                        </Form>
                    </div>
                </PageRow>
                
            </PageContainer>
        );
    }
}