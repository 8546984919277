import {Utility} from "@reapptor-apps/reapptor-toolkit";
import {MessagePayload, NotificationPayload} from "@firebase/messaging";

export class FcmNotification {
    public messageId: string | null = null;

    public tokenId: string | null = null;

    public senderId: string | null = null;

    public googleCae: string | null = null;

    public title: string | null = null;

    public body: string | null = null;

    public fid: string | null = null;

    public gsmMessageId: string | null = null;
    
    public image: string | null = null;

    public isFcmNotification: true = true;

    public static restore(event: CustomEvent | MessagePayload | null): FcmNotification | null {
        if (event) {
            const message = (event as CustomEvent).detail;
            if (message) {
                const notification = new FcmNotification();
                notification.messageId = message["MessageId"];
                notification.tokenId = message["TokenId"];
                notification.googleCae = message["google.c.a.e"];
                notification.senderId = message["google.c.sender.id"];
                notification.fid = message["google.c.fid"];
                notification.gsmMessageId = message["gcm.message_id"];
                notification.title = Utility.findStringValueByAccessor(message, "aps.alert.title");
                notification.body = Utility.findStringValueByAccessor(message, "aps.alert.body");
                return notification;
            }
            
            const payload = event as MessagePayload;
            const notificationPayload: NotificationPayload | undefined = payload.notification;
            if (notificationPayload) {
                const notification = new FcmNotification();
                notification.senderId = payload.from;
                notification.gsmMessageId = payload.messageId;
                notification.title = notificationPayload.title || null;
                notification.body = notificationPayload.body || null;
                notification.image = notificationPayload.image || null;
                
                if (payload.data) {
                    notification.messageId = payload.data["MessageId"];
                    notification.tokenId = payload.data["TokenId"];
                }
                
                return notification;
            }
        }
        return null;
    }
}