import {BasePage, ch, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import UserContext from "../server/UserContext";
import User from "../server/User";
import PageDefinitions from "@/providers/PageDefinitions";
import {TBasePageParameters} from "@reapptor-apps/reapptor-react-common/src/base/BasePage";
import ServiceProvider from "@/models/server/ServiceProvider";
import ServiceProviderController from "@/pages/ServiceProviderController";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

export default abstract class AuthorizedPage<TProps extends TBasePageParameters = {}, TState = {}>
    extends BasePage<TProps, TState, UserContext> {

    public get serviceProviderSlug(): ServiceProvider | null {
        return ServiceProviderController.serviceProviderSlug;
    }
    
    public get serviceProviderSlugId(): string | null {
        return ServiceProviderController.serviceProviderSlug?.id || null;
    }
    
    public get isAcceptedRegulations(): boolean {
        return AppController.isAcceptedRegulations;
    }
    
    public get isRoleSelected(): boolean {
        const context: UserContext = this.getContext();
        return (context.role != null);
    }
    
    public getUser(): User {
        return ch.getUser();
    }
    
    public getUserId(): string {
        return ch.getUserId();
    }
    
    public get hasLanguageSelector(): boolean {
        return (!AppController.mobileAppContent);
    }
    
    public async initializeAsync(): Promise<void> {
        
        if (!this.isAuthorized) {
            await PageRouteProvider.redirectAsync(PageDefinitions.loginRoute, true, true);
        }

        await super.initializeAsync();
        
        if (!this.isAcceptedRegulations) {
            await this.alertWarningAsync(Localizer.myAccountPageAcceptanceRequired);
        }
    }
}