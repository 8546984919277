// autogenerated
import {PostTripEmailType} from "@/models/Enums";

export default class SendPostTripEmailRequest {

    public bookingId: string = "";

    public postTripEmailType: PostTripEmailType = PostTripEmailType.Complain;

    public content: string | null = null;

    public readonly isSendPostTripEmailRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof SendPostTripEmailRequest) || ((from as any).isSendPostTripEmailRequest === true));
    }

    public static as(from?: object | null): SendPostTripEmailRequest | null {
        return (SendPostTripEmailRequest.is(from)) ? from as SendPostTripEmailRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}