import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import {Form, NumberInput, TextInput, TwoColumns} from "@reapptor-apps/reapptor-react-components";
import User from "@/models/server/User";
import Boat from "@/models/server/bout/Boat";
import Localizer from "@/localization/Localizer"

import styles from "./UserCard.module.scss";

import thumbnail from "../../img/thumbnail.png";

interface IUserCardProps {
    id?: string;
    user?: User | null;
    boat?: Boat | null;
    className?: string;
    title: string;
}

interface IUserCardState {
    avatar?: FileModel
}

export default class UserCard extends BaseComponent<IUserCardProps, IUserCardState> {

    state: IUserCardState = {
    };

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (this.user) {
            await this.getAvatarAsync();
        }
    }

    public get user(): User | null | undefined {
        return this.props.user;
    }

    public get boat(): Boat | null | undefined {
        return this.props.boat;
    }

    public get isCaptain(): boolean {
        return (!this.user) || (this.user.isCaptain);
    }

    public get isPassenger(): boolean {
        return (this.user != null) && (this.user.isPassenger);
    }

    public get avatar(): string {
        if (this.state.avatar?.src) {
            return this.state.avatar?.src;
        } else {
            return thumbnail;
        }
    }

    public get userName(): string {
        return (this.user)
            ? "{0}".format(this.user)
            : Localizer.userCardPageUserName;
    }

    public get email(): string {
        return (this.user)
            ? this.user.email
            : Localizer.userCardPageEmail;
    }

    public async getAvatarAsync(): Promise<void> {
        if (this.user && this.user.avatarId) {
            const avatar: FileModel = await this.postAsync("/api/files/getAvatar", this.user.avatarId);

            await this.setState({avatar});
        }
    }

    public renderInfo(): React.ReactNode {
        return (
            <div className={styles.cardInfo}>
                
                <span className={styles.title}>
                    {this.props.title}
                </span>

                <h5>
                    {this.userName}
                </h5>

                <p>
                    {this.email}
                </p>

                {
                    this.renderCardForm()
                }

            </div>
        )
    }

    public renderCardForm(): React.ReactNode {
        return (
            <Form>

                <TwoColumns>

                    <TextInput readonly
                               label={Localizer.userCardPageTextInputMobileNumberLabel}
                               value={this.user?.phone}
                    />

                    <TextInput readonly
                               label={Localizer.userCardPageTextInputRolesLabel}
                               value={User.rolesToString(this.user)}
                    />

                </TwoColumns>
                
                <TwoColumns>

                    {
                        (this.isPassenger) &&
                        (
                            <NumberInput readonly hideArrows
                                         hideZero={!this.user}
                                         label={Localizer.userCardPageNumberInputRatingPassengerLabel}
                                         value={this.user!.ratingAsPassenger}
                            />
                        )
                    }

                    {
                        (this.isCaptain) &&
                        (
                            <NumberInput readonly hideArrows
                                         hideZero={!this.user}
                                         label={Localizer.userCardPageNumberInputRatingCaptainLabel}
                                         value={this.user?.ratingAsCaptain}
                            />
                        )
                    }

                    {
                        (this.isCaptain) &&
                        (
                            <NumberInput readonly hideArrows
                                         hideZero={!this.boat}
                                         label={Localizer.bookingInfoPageBoatRating}
                                         value={this.boat?.rating}
                            />
                        )
                    }
                    
                </TwoColumns>

            </Form>
        );
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(styles.userCard)}>

                <img src={this.avatar} alt="Avatar"/>

                {
                    this.renderInfo()
                }

            </div>
        )
    }
}
