// autogenerated
import {FileModel} from "@reapptor-apps/reapptor-toolkit";

export default class ImportServicePointsRequest {

    public file: FileModel | null = null;

    public overrideExisting: boolean = false;

    public readonly isImportServicePointsRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ImportServicePointsRequest) || ((from as any).isImportServicePointsRequest === true));
    }

    public static as(from?: object | null): ImportServicePointsRequest | null {
        return (ImportServicePointsRequest.is(from)) ? from as ImportServicePointsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}