// autogenerated

export default class ListServicePointsRequest {

    public countryId: string | null = null;

    public areaId: string | null = null;

    public isActive: boolean | null = null;

    public isShuttle: boolean | null = null;

    public hasStartWaypoints: boolean = false;

    public asAdmin: boolean = false;

    public search: string | null = null;

    public pageNumber: number = 0;

    public pageSize: number = 0;

    public readonly isListServicePointsRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ListServicePointsRequest) || ((from as any).isListServicePointsRequest === true));
    }

    public static as(from?: object | null): ListServicePointsRequest | null {
        return (ListServicePointsRequest.is(from)) ? from as ListServicePointsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}