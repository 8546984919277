import {initializeApp} from "firebase/app";
import {getMessaging, getToken} from "firebase/messaging";
import {FirebaseApp, FirebaseOptions} from "@firebase/app";
import {GetTokenOptions, Messaging} from "@firebase/messaging";
import {GeoCoordinate, ILanguage, Utility} from "@reapptor-apps/reapptor-toolkit";
import {
    ApiProvider,
    ch,
    DataStorageType,
    IBaseComponent,
    IBasePage,
    JQueryUtility,
    PageCacheTtl,
    PageRoute,
    PageRouteProvider,
    UserInteractionDataStorage
} from "@reapptor-apps/reapptor-react-common";
import {IFooterLink, ILeftNavProps, IMenuItem, ITopNavNotifications} from "@reapptor-apps/reapptor-react-components";
import {IMobileHomePage} from "@/pages/Mobile/Home/Home";
import UserContext from "../models/server/UserContext";
import User from "@/models/server/User";
import AppConstants from "@/helpers/AppConstants";
import PageDefinitions from "@/providers/PageDefinitions";
import OnlineData from "@/models/server/OnlineData";
import Booking from "@/models/server/bout/Booking";
import CancellationFee from "@/models/server/bout/CancellationFee";
import CancelTripRequest from "@/models/server/requests/CancelTripRequest";
import {FcmNotification} from "@/models/server/FcmNotification";
import UserNotificationMessage from "@/models/server/UserNotificationMessage";
import ApplicationSettings from "@/models/server/ApplicationSettings";
import FindMyPushNotificationRequest from "@/models/server/requests/FindMyPushNotificationRequest";
import CheckOnlineStatusRequest from "@/models/server/requests/CheckOnlineStatusRequest";
import Comparator from "@/helpers/Comparator";
import Country from "@/models/server/bout/Country";
import ListCountriesRequest from "@/models/server/requests/ListCountriesRequest";
import ListCountriesResponse from "@/models/server/responses/ListCountriesResponse";
import Area from "@/models/server/bout/Area";
import ListAreasRequest from "@/models/server/requests/ListAreasRequest";
import ListAreasResponse from "@/models/server/responses/ListAreasResponse";
import ApplicationContext from "@/models/server/ApplicationContext";
import ListServiceProvidersResponse from "@/models/server/responses/ListServiceProvidersResponse";
import ListServiceProvidersRequest from "@/models/server/requests/ListServiceProvidersRequest";
import ServiceProvider from "@/models/server/ServiceProvider";
import {IMyTripsPage} from "@/pages/Mobile/MyTrips/MyTrips";
import ServiceProviderController from "@/pages/ServiceProviderController";
import EnumProvider from "@/providers/EnumProvider";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";

import mobileAppStyles from "./Mobile/mobile.module.scss";
import Dashboard from "@/pages/Mobile/Dashboard/Dashboard";

export class RideBookingSettings {
    public minBookingDepthInHours: number | null = null;

    public maxBookingDepthInHours: number | null = null;

    public minBookingRangeInMinutes: number | null = null;

    public maxBookingRangeInMinutes: number | null = null;
}

export class RideBookingRules {
    public settings: RideBookingSettings = new RideBookingSettings();

    public now: Date = new Date();
    
    public minBookingDepthInHours: number = 0;
    
    public maxBookingDepthInHours: number = 0;
    
    public minBookingRangeInMinutes: number = 0;
    
    public maxBookingRangeInMinutes: number = 0;
    
    public depthStartAt: Date = new Date();
    
    public depthEndAt: Date = new Date();
    
    public rangeStartAt: Date = new Date();
    
    public rangeEndAt: Date = new Date();
    
    public bookingStartAt: Date = new Date();
    
    public bookingEndAt: Date = new Date();
    
    public rideStartAt: Date = new Date();
    
    public rideEndAt: Date = new Date();
    
    public get isBookingAvailable(): boolean {
        return (this.bookingStartAt.getTime() <= this.now.getTime());
    }
    
    public get isRideAvailable(): boolean {
        return (this.rideStartAt.getTime() <= this.now.getTime());
    }
    
    public get hasBookingRange(): boolean {
        return ((this.settings.minBookingRangeInMinutes != null) && (this.settings.maxBookingRangeInMinutes != null));
    }
    
    public get hasBookingDepth(): boolean {
        return ((this.settings.minBookingDepthInHours != null) && (this.settings.maxBookingDepthInHours != null));
    }
    
    public getStartAt(): Date {
        return (this.rideStartAt.getTime() > this.depthStartAt.getTime())
            ? this.rideStartAt
            : this.depthStartAt;
    }
    
    public getValidBookingTime(bookingTime: Date): Date {
        const minDate: Date = this.getMinDate();
        const maxDate: Date = this.getMaxDate();
        const minTime: Date = this.getMinTime(bookingTime);
        const maxTime: Date = this.getMaxTime(bookingTime);
        const min: Date = this.combineDateAndTime(minDate, minTime);
        const max: Date = this.combineDateAndTime(maxDate, maxTime);

        const time: number = bookingTime.getTime();
        if ((min.getTime() <= time) && (time <= max.getTime())) {
            return bookingTime;
        }
        
        const date: Date = bookingTime.date();
        const dateTime: number = date.getTime();
        if ((minDate.getTime() <= dateTime) && (dateTime <= maxDate.getTime())) {
            const dateMinTime: Date = this.getMinTime(date);
            return this.combineDateAndTime(date, dateMinTime);
        }
        
        return this.combineDateAndTime(minDate, minTime);
    }

    public combineDateAndTime(date: Date, time: Date): Date {
        const result: Date = new Date(date);
        result.setHours(time.getHours());
        result.setMinutes(time.getMinutes());
        result.setSeconds(time.getSeconds());
        result.setMilliseconds(time.getMilliseconds());
        return result;
    }
    
    public getMinDate(): Date {
        const time: Date = this.getStartAt();
        return time.date();
    }
    
    public getMaxDate(): Date {
        return this.bookingEndAt.date();
    }
    
    public getMinTime(bookingTime: Date): Date {
        const now: Date = this.now;
        const time: Date = this.getStartAt();
        return (bookingTime.isToday())
            ? (time.getTime() > now.getTime())
                ? time
                : now
            : (bookingTime.date().getTime() > time.date().getTime())
                ? this.combineDateAndTime(bookingTime, this.rangeStartAt)
                : time;
    }
    
    public getMaxTime(bookingTime: Date): Date {
        const bookingEndAt: Date = this.bookingEndAt;
        return (bookingTime.date().getTime() == bookingEndAt.date().getTime())
            ? bookingEndAt
            : this.combineDateAndTime(bookingTime, this.rideEndAt);
    }
    
    public get noBookingAvailableMessage(): string {
        let message: string = Localizer.mobileBoatSelectionPageNoBookingAvailableMessageRow1;
        
        if (this.hasBookingRange) {
            message += Localizer.mobileBoatSelectionPageNoBookingAvailableMessageRow2.format(this.rangeStartAt, this.rangeEndAt);
        }
        
        if (this.hasBookingDepth) {
            message += Localizer.mobileBoatSelectionPageNoBookingAvailableMessageRow3.format(this.settings.maxBookingDepthInHours);
        }
        
        return message;
    }
}

class AppController {
    private _initialized: boolean = false;
    private _initializing: boolean = false;
    private _fcmToken: string | null = "";

    public async authorizeAsync(): Promise<void> {
        UserInteractionDataStorage.set(AppConstants.hideTopNav, true, DataStorageType.Session);
    }

    public async cancelTripAsync(sender: IBaseComponent, booking: Booking, redirectRoute: PageRoute): Promise<void> {

        const bookingId: string = booking.id;
        
        const cancellationFee: CancellationFee = await sender.postAsync("/api/mobileApp/estimateCancellationFee", bookingId);

        const moneySymbol: string = cancellationFee.country?.moneySymbol ?? "";

        const message: string = (cancellationFee.price > 0)
            ? Localizer.mobileBookingDetailsPageCancelTripWithFeeConfirm.format(cancellationFee.price, moneySymbol, 100 * cancellationFee.margin)
            : (cancellationFee.mayBeAppliedManually)
                ? Localizer.mobileBookingDetailsPageCancelTripConfirmFeeMayBeApplied
                : Localizer.mobileBookingDetailsPageCancelTripConfirm;

        const confirmed: boolean = await ch.confirmAsync(message);

        if (confirmed) {
            const location: GeoCoordinate | null = await Utility.getLocationAsync();

            const context = ch.getContext() as UserContext;

            const request = new CancelTripRequest();
            request.asCaptain = !context.asPassenger;
            request.bookingId = bookingId;
            request.location = location;

            await sender.postAsync("/api/mobileApp/cancelTrip", request);

            await PageRouteProvider.redirectAsync(redirectRoute);

            await ch.alertMessageAsync(Localizer.mobileBookingDetailsPageAlertErrorTripCanceled, true, true);
        }
    }

    public get isIosNative(): boolean {
        return (typeof (window as any).webkit?.messageHandlers?.postMessageListener?.postMessage === "function");
    }

    private async closeIframeAsync(): Promise<void> {
        const isDevelopmentVS: boolean = ((ch.findContext() as any)?.isDevelopmentVS === true);
        if (isDevelopmentVS) {
            const node: JQuery = JQueryUtility.$("iframe");
            if ((node) && (node.length)) {
                const src: string | null = node.attr("src") as string | null;
                if (!src) {
                    node.remove();
                }
            }
            await Utility.wait(1000);
        }
    }

    // noinspection InfiniteRecursionJS
    private async pollOnlineDataAsync(): Promise<void> {
        try {
            if (ch.isAuthenticated) {
                const context = ch.getContext() as UserContext;

                if ((context.asPassenger) || (context.asCaptain)) {

                    if (!ApiProvider.isLoading) {

                        const id: string | null = context.onlineData?.id || null;

                        const request = new CheckOnlineStatusRequest(id);

                        if ((context.asCaptain) && (context.onlineData?.hasOngoingTrip)) {
                            const location: GeoCoordinate | null = await Utility.getLocationAsync({maximumAge: 60000, timeout: 5000});
                            
                            if (location) {
                                await ApiProvider.postAsync("/api/application/setOnlineLocation", location);
                            }
                        }

                        const modified: boolean = await ApiProvider.postAsync("/api/application/checkOnlineStatus", request);

                        if (modified) {
                            const prevOnlineData: OnlineData | null = (ch.findContext() as UserContext)?.onlineData;
                            
                            await ApiProvider.postAsync("/api/application/getOnlineData");

                            const onlineData: OnlineData | null = (ch.findContext() as UserContext)?.onlineData;

                            if (onlineData) {
                                try {
                                    await this.onOnlineDataAsync(onlineData, prevOnlineData);
                                } catch (e) {
                                }
                            }
                        }
                    }
                }
            }
        } catch (error) {
            await PageRouteProvider.exception(error as Error);
        } finally {
            await Utility.wait(1000 * AppConstants.pollTimeoutInSeconds);

            await this.pollOnlineDataAsync();
        }
    }

    // noinspection JSIgnoredPromiseFromCall
    private async setFcmTokenAsync(fcmToken: string): Promise<void> {
        this._fcmToken = fcmToken;

        await ApiProvider.postAsync("/api/application/setFcmToken", fcmToken);

        const windowInstance = (window as any);
        windowInstance.setFcmToken = null;
    }

    private async openPushAppNotificationAsync(fcmNotification: FcmNotification | null): Promise<void> {
        if ((fcmNotification) && (fcmNotification.messageId)) {

            const request = new FindMyPushNotificationRequest();
            request.messageId = fcmNotification.messageId;
            request.markAsRead = true;

            const notification: UserNotificationMessage | null = await ApiProvider.postAsync("/api/mobileApp/findMyPushNotification", request);
            if (notification) {
                if (notification.pageRoute) {
                    await PageRouteProvider.redirectAsync(notification.pageRoute);
                } else {
                    await PageRouteProvider.redirectAsync(PageDefinitions.notificationsRoute);
                }
            }
        }
    }

    private async initializePushServiceAsync(): Promise<void> {
        const windowInstance = (window as any);

        if (windowInstance.setFcmToken == null) {
            windowInstance.setFcmToken = async (fcmToken: string) => await this.setFcmTokenAsync(fcmToken);
        }

        window.addEventListener("push-notification", async (event: Event) => {
            //alert("push-notification = " + (event as CustomEvent).detail);
            const notification: FcmNotification | null = FcmNotification.restore(event as CustomEvent);
            await this.openPushAppNotificationAsync(notification);
        });

        // Initialize Firebase for Web
        const context: ApplicationContext | null = ch.findContext();
        if ((context) && (context.settings.googleWebConfigJson)) {
            const firebaseConfig: FirebaseOptions = JSON.parse(context.settings.googleWebConfigJson);

            // Initialize Firebase
            const firebaseApp: FirebaseApp = initializeApp(firebaseConfig);

            // Initialize Firebase Cloud Messaging and get a reference to the service
            try {
                const messaging: Messaging = getMessaging(firebaseApp);

                // Fetch FCM token information
                let fcmToken: string = "";

                try {
                    const options: GetTokenOptions = {
                        vapidKey: context.settings.googleWebVapidKey || ""
                    };

                    fcmToken = await getToken(messaging, options);
                } catch {
                    // Firebase: Notification is disabled.
                    // console.log("Firebase: Notification is disabled.");
                }

                if (fcmToken) {
                    await this.setFcmTokenAsync(fcmToken);
                }
            } catch {
                // Firebase: Failed to initialize Firebase Messaging - unsupported browsers.
                // console.log("Firebase: Failed to initialize Firebase Messaging - unsupported browsers.");
            }
        }
    }

    public async onOnlineDataAsync(onlineData: OnlineData, prevOnlineData: OnlineData | null): Promise<void> {
        const asPassenger: boolean = this.asPassenger;
        const asCaptain: boolean = this.asCaptain;

        const hasOngoingTripModified: boolean = (onlineData.hasOngoingTrip !== prevOnlineData?.hasOngoingTrip);
        const newNotificationsCountModified: boolean = (onlineData.newNotificationsCount !== prevOnlineData?.newNotificationsCount);

        const page: IBasePage | null = ch.findPage();
        const routeName: string | null = page?.routeName || null;

        if (asPassenger) {

            // Update ongoing trip status
            if ((onlineData.hasOngoingTrip) || (hasOngoingTripModified)) {

                if ((routeName == PageDefinitions.mobileHomeRouteName) || (routeName == PageDefinitions.mobileShuttleRouteName)) {
                    const mobileHomePage = page as IMobileHomePage;
                    await mobileHomePage.setCaptainLocationAsync(onlineData.captainLocation, hasOngoingTripModified);
                }
            }

            // Redirect to home page of trip rate is required
            if ((onlineData.tripRateRequired) && (ServiceProviderController.supportsRating)) {
                await PageRouteProvider.redirectAsync(PageDefinitions.mobileHomeRoute);
                return;
            }
        }

        if ((asPassenger) || (asCaptain)) {

            // Update my trips page if notifications count has been changed
            if ((hasOngoingTripModified) || (newNotificationsCountModified)) {

                if (routeName == PageDefinitions.myTripsRouteName) {
                    const myTripsPage = page as IMyTripsPage;
                    await myTripsPage.reloadAsync();
                }
            }
        }
    }

    public async initializeAsync(): Promise<void> {
        if ((!this._initialized) && (!this._initializing)) {
            this._initializing = true;
            try {
                // Init All Dependencies
                EnumProvider.initialize();
                TransformProvider.initialize();
                Localizer.initialize();
                PageDefinitions.initialize();
                Comparator.initialize();

                await this.initializePushServiceAsync();

                // parallel thread, do not await
                // noinspection ES6MissingAwait
                this.pollOnlineDataAsync();

                // parallel thread, do not await
                // noinspection ES6MissingAwait
                this.closeIframeAsync();

                this._initialized = true;
            } finally {
                this._initializing = false;
            }
        }
    }

    public async onLogoClickAsync(): Promise<void> {
        const route: PageRoute = (ch.isAuthenticated)
            ? (this.mobileAppContent)
                ? (this.asPassenger)
                    ? PageDefinitions.mobileDashboardRoute
                    : PageDefinitions.mobileHomeRoute
                : PageDefinitions.dashboardRoute
            : PageDefinitions.loginRoute;

        await PageRouteProvider.redirectAsync(route, false, true);
    }

    public async openCruiseAppAsync(): Promise<void> {
        await ApiProvider.invokeWithForcedSpinnerAsync(async () => {
            const tokenUrl: string = await ApiProvider.postAsync("/api/mobileApp/getCruiseUrl");

            ch.postToken(tokenUrl);
        }, true);
    }

    public async listServiceProvidersAsync(countryCode?: string | null, whiteLabelingOnly: boolean = false, shuttleOnly: boolean = false): Promise<ServiceProvider[]> {
        const request = new ListServiceProvidersRequest();
        request.countryCode = countryCode ?? null;
        request.whiteLabelingOnly = whiteLabelingOnly;
        request.shuttleOnly = shuttleOnly;
        
        const response: ListServiceProvidersResponse = await ApiProvider.postAsync("/api/serviceProvider/listServiceProviders", request);

        return response.serviceProviders ?? [];
    }

    public async getServiceProviderAsync(serviceProviderId: string): Promise<ServiceProvider> {
        return await ApiProvider.postAsync("/api/serviceProvider/getServiceProvider", serviceProviderId);
    }

    public async listCountriesAsync(serviceProviderId: string | null = null, activeOnly: boolean = false, withCruisePackagesOnly: boolean = false, asCaptain?: boolean): Promise<Country[]> {
        const request = new ListCountriesRequest();
        request.serviceProviderId = serviceProviderId;
        request.activeOnly = activeOnly;
        request.withCruisePackagesOnly = withCruisePackagesOnly;
        request.asCaptain = this.asCaptain && this.isAcceptedRegulations;
        request.asAdmin = this.asAdmin && this.isAcceptedRegulations;

        const response: ListCountriesResponse = await ApiProvider.postAsync("/api/country/listCountries", request, null, PageCacheTtl._5m);

        return response.countries ?? [];
    }

    public async listAreasAsync(serviceProviderId?: string | null, countryCode?: string | null, withBoatsOnly: boolean | null = false, withCruisePackagesOnly: boolean | null = false, caller: IBaseComponent | null = null): Promise<Area[]> {
        const request = new ListAreasRequest();
        request.countryCode = countryCode ?? null;
        request.withBoatsOnly = (withBoatsOnly == true);
        request.withCruisePackagesOnly = (withCruisePackagesOnly == true);
        request.serviceProviderId = serviceProviderId || null;
        request.asCaptain = this.asCaptain && this.isAcceptedRegulations;
        request.asAdmin = this.asAdmin && this.isAcceptedRegulations;

        const response: ListAreasResponse = await ApiProvider.postAsync("/api/country/listAreas", request, caller, PageCacheTtl._5m);

        return response.areas ?? [];
    }

    public get settings(): ApplicationSettings {
        const context: ApplicationContext = ch.getContext();
        return context.settings;
    }

    public get cruisePackagesEnabled(): boolean {
        return this.settings.cruisePackagesEnabled;
    }

    public get shuttleEnabled(): boolean {
        return this.settings.shuttleEnabled;
    }

    public get hasManyRoles(): boolean {
        if (ch.isAuthenticated) {
            const context = ch.getContext() as UserContext;
            const rolesCount: number = (context.user?.roles?.length || 0);
            return (rolesCount > 1);
        }
        return false;
    }

    public get asCaptain(): boolean {
        if (ch.isAuthenticated) {
            const context = ch.getContext() as UserContext;
            return context.asCaptain;
        }
        return false;
    }

    public get isAcceptedRegulations(): boolean {
        const user: User | null = ch.findUser();
        return ((user != null) && (User.isAcceptedRegulations(user, ServiceProviderController.serviceProviderSlug)));
    }

    public get acceptanceRequired(): boolean {
        return (ch.isAuthenticated) && (!this.isAcceptedRegulations);
    }

    public get asPassenger(): boolean {
        if (ch.isAuthenticated) {
            const context = ch.getContext() as UserContext;
            return context.asPassenger;
        }
        return false;
    }

    public get asInspector(): boolean {
        if (ch.isAuthenticated) {
            const context = ch.getContext() as UserContext;
            return context.asInspector;
        }
        return false;
    }

    public get asAdmin(): boolean {
        if (ch.isAuthenticated) {
            const context = ch.getContext() as UserContext;
            return context.asAdmin;
        }
        return false;
    }

    public get asServiceProviderManager(): boolean {
        if (ch.isAuthenticated) {
            const context = ch.getContext() as UserContext;
            return context.asServiceProviderManager;
        }
        return false;
    }

    public get timezoneOffset(): number {
        return ch.getContext().timezoneOffset;
    }

    public get activeTripsCount(): number | null {
        if (this.asPassenger) {
            const context: UserContext = ch.getContext() as UserContext;
            const activeTripsCount: number | null = context.onlineData?.activeTripsCount || null;
            return (activeTripsCount) ? activeTripsCount : null;
        }
        return null;
    }

    public get pendingPaymentsCount(): number | null {
        if (this.asPassenger) {
            const context: UserContext = ch.getContext() as UserContext;
            const hasPendingPayment: boolean = (context.onlineData?.hasPendingPayment === true);
            return (hasPendingPayment) ? 1 : null;
        }
        return null;
    }

    public get hasPendingPayments(): boolean {
        return ((this.pendingPaymentsCount != null) && (this.pendingPaymentsCount > 0));
    }

    public get hasValidPaymentMethod(): boolean {
        if (this.asPassenger) {
            const context: UserContext = ch.getContext() as UserContext;
            return context.user?.hasValidPaymentMethod ?? false;
        }

        return false;
    }

    public get newNotificationsCount(): number {
        const context: UserContext = ch.getContext() as UserContext;
        return context.onlineData?.newNotificationsCount ?? 0;
    }

    public get tripRateRequired(): boolean {
        if (this.asPassenger) {
            const context: UserContext = ch.getContext() as UserContext;
            return (context.onlineData?.tripRateRequired === true);
        }
        return false;
    }

    public get captainStripeKycVerified(): boolean {
        if (ch.isAuthenticated) {
            const context = ch.getContext() as UserContext;
            return (context.user?.stripeKycVerified === true);
        }
        return false;
    }

    public get allowedAsCaptain(): boolean {
        if (ch.isAuthenticated) {
            const context = ch.getContext() as UserContext;
            return (context.user?.allowAsCaptain === true);
        }
        return false;
    }

    public get isEntrepreneur(): boolean {
        if (ch.isAuthenticated) {
            const context = ch.getContext() as UserContext;
            return (context.user?.isEntrepreneur === true);
        }
        return false;
    }
    
    public test(): void {
        const settings1: RideBookingSettings = {
            minBookingDepthInHours: 0,
            maxBookingDepthInHours: 2,
            minBookingRangeInMinutes: 11 * 60,
            maxBookingRangeInMinutes: 18 * 60,
        };
        
        const settings2: RideBookingSettings = {
            minBookingDepthInHours: null,
            maxBookingDepthInHours: null,
            minBookingRangeInMinutes: null,
            maxBookingRangeInMinutes: null,
        };
        
        const settings3: RideBookingSettings = {
            // Between 11:00 and 18:00, min 48 hours (2 days in advance), max 3 days in advance
            minBookingDepthInHours: 1,
            maxBookingDepthInHours: 4,
            minBookingRangeInMinutes: 11 * 60,
            maxBookingRangeInMinutes: 18 * 60,
        };
        
        const settings4: RideBookingSettings = {
            // Between 11:00 and 18:00, min 0 hours, max 24 hours (1 day in advance)
            minBookingDepthInHours: 0,
            maxBookingDepthInHours: 24,
            minBookingRangeInMinutes: 11 * 60,
            maxBookingRangeInMinutes: 18 * 60,
        };
        
        const data = [
            {
                settings: settings1,
                now: new Date("2024-05-29T06:00:00.000+03:00"),
                isBookingAvailable: false,
                isRideAvailable: false,
                rideStartAt: new Date("2024-05-29T11:00:00.000+03:00"),
                rideEndAt: new Date("2024-05-29T18:00:00.000+03:00"),
                bookingStartAt: new Date("2024-05-29T09:00:00.000+03:00"),
                bookingEndAt: new Date("2024-05-29T08:00:00.000+03:00"),
                depthStartAt: new Date("2024-05-29T09:00:00.000+03:00"),
            },
            {
                settings: settings1,
                now: new Date("2024-05-29T19:00:00.000+03:00"),
                isBookingAvailable: false,
                isRideAvailable: false,
                rideStartAt: new Date("2024-05-30T11:00:00.000+03:00"),
                rideEndAt: new Date("2024-05-30T18:00:00.000+03:00"),
                bookingStartAt: new Date("2024-05-30T09:00:00.000+03:00"),
                bookingEndAt: new Date("2024-05-30T18:00:00.000+03:00"),
                depthStartAt: new Date("2024-05-30T09:00:00.000+03:00"),
            },
            {
                settings: settings1,
                now: new Date("2024-05-29T09:00:00.000+03:00"),
                isBookingAvailable: true,
                isRideAvailable: false,
                rideStartAt: new Date("2024-05-29T11:00:00.000+03:00"),
                rideEndAt: new Date("2024-05-29T18:00:00.000+03:00"),
                bookingStartAt: new Date("2024-05-29T09:00:00.000+03:00"),
                bookingEndAt: new Date("2024-05-29T11:00:00.000+03:00"),
                depthStartAt: new Date("2024-05-29T09:00:00.000+03:00"),
            },
            {
                settings: settings1,
                now: new Date("2024-05-29T11:00:00.000+03:00"),
                isBookingAvailable: true,
                isRideAvailable: true,
                rideStartAt: new Date("2024-05-29T11:00:00.000+03:00"),
                rideEndAt: new Date("2024-05-29T18:00:00.000+03:00"),
                bookingStartAt: new Date("2024-05-29T11:00:00.000+03:00"),
                bookingEndAt: new Date("2024-05-29T13:00:00.000+03:00"),
                depthStartAt: new Date("2024-05-29T11:00:00.000+03:00"),
            },
            {
                settings: settings1,
                now: new Date("2024-05-29T16:00:00.000+03:00"),
                isBookingAvailable: true,
                isRideAvailable: true,
                rideStartAt: new Date("2024-05-29T16:00:00.000+03:00"),
                rideEndAt: new Date("2024-05-29T18:00:00.000+03:00"),
                bookingStartAt: new Date("2024-05-29T16:00:00.000+03:00"),
                bookingEndAt: new Date("2024-05-29T18:00:00.000+03:00"),
                depthStartAt: new Date("2024-05-29T16:00:00.000+03:00"),
            },
            {
                settings: settings2,
                now: new Date("2024-05-14T14:00:00.000+03:00"),
                isBookingAvailable: true,
                isRideAvailable: true,
                rideStartAt: new Date("2024-05-14T14:00:00.000+03:00"),
                rideEndAt: new Date("2025-05-14T23:59:00.000+03:00"),
                bookingStartAt: new Date("2024-05-14T14:00:00.000+03:00"),
                bookingEndAt: new Date("2025-05-14T23:59:00.000+03:00"),
                depthStartAt: new Date("2024-05-14T14:00:00.000+03:00"),
            },
            {
                settings: settings3,
                now: new Date("2024-05-19T15:00:00.000+03:00"),
                isBookingAvailable: true,
                isRideAvailable: true,
                rideStartAt: new Date("2024-05-19T15:00:00.000+03:00"),
                rideEndAt: new Date("2024-05-19T18:00:00.000+03:00"),
                bookingStartAt: new Date("2024-05-19T15:00:00.000+03:00"),
                bookingEndAt: new Date("2024-05-19T18:00:00.000+03:00"),
                depthStartAt: new Date("2024-05-19T16:00:00.000+03:00"),
            },
            {
                settings: settings4,
                now: new Date("2024-06-02T19:00:00.000+03:00"),
                isBookingAvailable: true,
                isRideAvailable: false,
                rideStartAt: new Date("2024-06-03T11:00:00.000+03:00"),
                rideEndAt: new Date("2024-06-03T18:00:00.000+03:00"),
                bookingStartAt: new Date("2024-06-02T19:00:00.000+03:00"),
                bookingEndAt: new Date("2024-06-03T18:00:00.000+03:00"),
                depthStartAt: new Date("2024-06-02T19:00:00.000+03:00"),
            },
        ];
        
        console.log("---- TEST start ----");
        
        const length: number = data.length;
        for (let i: number = 0; i < length; i++) {
            const test = data[i];
            
            const settings: RideBookingSettings = test.settings;
            const now: Date = test.now;
            
            const expectedIsBookingAvailable: boolean = test.isBookingAvailable;
            const expectedIsRideAvailable: boolean = test.isRideAvailable;
            const expectedRideStartAt: Date = test.rideStartAt;
            const expectedRideEndAt: Date = test.rideEndAt;
            const expectedBookingStartAt: Date = test.bookingStartAt;
            const expectedBookingEndAt: Date = test.bookingEndAt;
            const expectedDepthStartAt: Date = test.depthStartAt;

            //console.log(`Test #${i} NOW=${now.format("dt")} START *****`);
            
            const bookingRules: RideBookingRules = this.getRideBookingRules(settings, now);
            
            const rideStartAt: Date = bookingRules.rideStartAt;
            const rideEndAt: Date = bookingRules.rideEndAt;
            const bookingStartAt: Date = bookingRules.bookingStartAt;
            const bookingEndAt: Date = bookingRules.bookingEndAt;
            const depthStartAt: Date = bookingRules.depthStartAt;
            const isBookingAvailable: boolean = bookingRules.isBookingAvailable;
            const isRideAvailable: boolean = bookingRules.isRideAvailable;
            
            const passed: boolean = (
                (isBookingAvailable == expectedIsBookingAvailable) &&
                (isRideAvailable == expectedIsRideAvailable) &&
                (rideStartAt.getTime() == expectedRideStartAt.getTime()) &&
                (rideEndAt.getTime() == expectedRideEndAt.getTime()) &&
                (bookingStartAt.getTime() == expectedBookingStartAt.getTime()) &&
                (bookingEndAt.getTime() == expectedBookingEndAt.getTime()) &&
                (depthStartAt.getTime() == expectedDepthStartAt.getTime())
            );
            
            if (passed) {
                console.log(`Test #${i} PASSED NOW=${now.format("dt")}`);
            } else {
                console.log(`Test #${i} FAILED NOW=${now.format("dt")} isBookingAvailable=${isBookingAvailable}/${expectedIsBookingAvailable} isRideAvailable=${isRideAvailable}/${expectedIsRideAvailable} bookingStartAt=${bookingStartAt.format("dt")}/${expectedBookingStartAt.format("dt")} bookingEndAt=${bookingEndAt.format("dt")}/${expectedBookingEndAt.format("dt")} rideStartAt=${rideStartAt.format("dt")}/${expectedRideStartAt.format("dt")} rideEndAt=${rideEndAt.format("dt")}/${expectedRideEndAt.format("dt")} depthStartAt=${depthStartAt.format("dt")}/${expectedDepthStartAt.format("dt")}`);
            }
        }

        console.log("---- TEST end ----");
    }
    
    public getRideBookingSettings(): RideBookingSettings {
        return {
            minBookingDepthInHours: ServiceProviderController.minBookingDepthInHours,
            maxBookingDepthInHours: ServiceProviderController.maxBookingDepthInHours,
            minBookingRangeInMinutes: ServiceProviderController.minBookingRangeInMinutes,
            maxBookingRangeInMinutes: ServiceProviderController.maxBookingRangeInMinutes,
        }
    }
    
    public getRideBookingRules(settings: RideBookingSettings = this.getRideBookingSettings(), now: Date = Utility.now()): RideBookingRules {

        const today: Date = now.date();

        const minutesPerDay: number = 1440;
        const lastMinute: number = minutesPerDay - 1;

        const minBookingDepthInHours: number = settings.minBookingDepthInHours ?? 0;
        const maxBookingDepthInHours: number = settings.maxBookingDepthInHours ?? 0;
        const minBookingRangeInMinutes: number = settings.minBookingRangeInMinutes ?? 0;
        const maxBookingRangeInMinutes: number = settings.maxBookingRangeInMinutes ?? lastMinute;
        
        let depthStartAt: Date = now.addHours(minBookingDepthInHours);
        const depthEndAt: Date = (maxBookingDepthInHours > 0)
            ? now.addHours(maxBookingDepthInHours)
            : today.addYears(1).addMinutes(lastMinute);

        const rangeStartAt: Date = today.addMinutes(minBookingRangeInMinutes);
        const rangeEndAt: Date = today.addMinutes(maxBookingRangeInMinutes);

        const rideStartAt: Date = (now.getTime() > rangeEndAt.getTime())
            ? rangeStartAt.addDays(1)
            : (rangeStartAt.getTime() < now.getTime())
                ? new Date(now)
                : rangeStartAt;

        let bookingStartAt: Date = rideStartAt.addHours(-maxBookingDepthInHours);
        if (bookingStartAt.getTime() < now.getTime()) {
            bookingStartAt = new Date(now);
        }
        
        // if (bookingStartAt.getTime() < depthStartAt.getTime()) {
        //     bookingStartAt = depthStartAt;
        // }
        
        if (depthStartAt.getTime() < bookingStartAt.getTime()) {
            depthStartAt = bookingStartAt;
        }
        
        const hasRangeLimit: boolean = (settings.maxBookingRangeInMinutes != null);
        
        const isRideStartToday: boolean = (rideStartAt.date().getTime() == today.getTime());

        const rideEndAt: Date = (hasRangeLimit)
            ? (isRideStartToday)
                ? rangeEndAt
                : rangeEndAt.addDays(1)
            : rangeEndAt.addYears(1);
        
        const depthEndAtDateOnly: Date = depthEndAt.date();
        const depthEndAtMinutesOnly: number = 60 * depthEndAt.getHours() + depthEndAt.getMinutes();
        const rideEndAtDateOnly: Date = rideEndAt.date();
        const rideEndAtMinutesOnly: number = 60 * rideEndAt.getHours() + rideEndAt.getMinutes();
        
        const bookingEndAtDateOnly: Date = (depthEndAtDateOnly.getTime() > rideEndAtDateOnly.getTime())
            ? depthEndAtDateOnly
            : rideEndAtDateOnly;
        
        const bookingEndAtMinutesOnly: number = (depthEndAtMinutesOnly < rideEndAtMinutesOnly)
            ? depthEndAtMinutesOnly
            : rideEndAtMinutesOnly;
        
        let bookingEndAt: Date = new Date(bookingEndAtDateOnly);
        bookingEndAt.setMinutes(bookingEndAtMinutesOnly);

        if ((bookingEndAt.date().getTime() > rideEndAt.date().getTime()) && (bookingEndAtMinutesOnly < minBookingRangeInMinutes)) {
            bookingEndAt = rideEndAt;
        }
        
        // console.log("now=", now.format("dt"));
        // console.log("depthStartAt=", depthStartAt.format("dt"));
        // console.log("depthEndAt=", depthEndAt.format("dt"));
        // console.log("rideStartAt=", rideStartAt.format("dt"));
        // console.log("rideEndAt=", rideEndAt.format("dt"));
        // console.log("bookingStartAt=", bookingStartAt.format("dt"));
        // console.log("bookingEndAt=", bookingEndAt.format("dt"));

        const rules = new RideBookingRules();
        rules.settings = settings;
        rules.now = now;
        rules.minBookingDepthInHours = minBookingDepthInHours;
        rules.maxBookingDepthInHours = maxBookingDepthInHours;
        rules.minBookingRangeInMinutes = minBookingRangeInMinutes;
        rules.maxBookingRangeInMinutes = maxBookingRangeInMinutes;
        rules.depthStartAt = depthStartAt;
        rules.depthEndAt = depthEndAt;
        rules.rangeStartAt = rangeStartAt;
        rules.rangeEndAt = rangeEndAt;
        rules.bookingStartAt = bookingStartAt;
        rules.bookingEndAt = bookingEndAt;
        rules.rideStartAt = rideStartAt;
        rules.rideEndAt = rideEndAt;

        return rules;
    }

    public get mobileAppContent(): boolean {
        const pages: string[] = [
            PageDefinitions.signUpRouteName,
            PageDefinitions.loginRouteName,
            //PageDefinitions.resetPasswordRouteName,
            //PageDefinitions.forgotPasswordRouteName,
            PageDefinitions.contactSupportRouteName
        ];
        return (
            ((ch.isAuthenticated) && ((this.asCaptain) || (this.asPassenger) || (this.asInspector))) ||
            (ch.findRouteName().startsWith("Mobile/")) ||
            (((ch.mobileApp) || (ch.mobile)) && (!this.asAdmin)) ||
            (pages.includes(ch.findRouteName()))
        );
    }

    public getLeftNav(): ILeftNavProps | null {
        if ((ch.isAuthenticated) && ((this.asCaptain) || (this.asPassenger) || (this.asInspector)) && (!this.acceptanceRequired)) {
            const menuItems: IMenuItem[] | null = [
                {
                    icon: "fad fa-water",
                    label: Localizer.mobileHomeMenuItemLanguageItemName, //Dashboard
                    route: PageDefinitions.mobileDashboardRoute,
                    visible: () => this.asPassenger,
                },
                {
                    icon: "fad fa-water",
                    label: Localizer.mobileHomeMenuItemLanguageItemName, //Home
                    route: PageDefinitions.mobileHomeRoute,
                    visible: () => ((this.asCaptain) && (this.allowedAsCaptain) && ((this.captainStripeKycVerified) || (!this.isEntrepreneur))),
                },
                {
                    icon: "fad fa-user-friends",
                    label: Localizer.mobileSelectRoleMenuItemLanguageItemName, //SwitchRole
                    route: PageDefinitions.mobileSelectRoleRoute,
                    visible: () => this.hasManyRoles,
                },
                {
                    icon: "fal fa-island-tropical",
                    label: Localizer.mobilePackagesMenuItemLanguageItemName, //Cruise packages
                    route: PageDefinitions.cruisePackagesManagementRoute,
                    visible: () => ((this.asCaptain) && (this.allowedAsCaptain) && (this.isEntrepreneur) && (this.captainStripeKycVerified) && (ch.desktop)),
                },
                {
                    icon: "fad fa-route",
                    label: Localizer.myTripsPageTitleLanguageItemName, //MyTrips
                    route: PageDefinitions.myTripsRoute,
                    count: () => this.activeTripsCount,
                    visible: () => ((this.asPassenger) || ((this.asCaptain) && (this.allowedAsCaptain) && ((this.captainStripeKycVerified) || (!this.isEntrepreneur)))),
                },
                {
                    icon: "fad fa-chart-line",
                    label: Localizer.mobileMyEarningsPageTitleLanguageItemName, //MyEarnings,
                    route: PageDefinitions.myEarningsRoute,
                    visible: () => ((this.asCaptain) && (this.allowedAsCaptain) && (this.isEntrepreneur) && (this.captainStripeKycVerified)),
                },
                {
                    icon: "fal fa-calendar",
                    label: Localizer.myCalendarPageTitleLanguageItemName, //MyCalendar,
                    route: PageDefinitions.myCalendarRoute,
                    visible: () => ((this.asCaptain) && (this.allowedAsCaptain) && ((this.captainStripeKycVerified) || (!this.isEntrepreneur))),
                },
                {
                    icon: "fal fa-ship",
                    label: Localizer.mobileMyBoatsPageTitleLanguageItemName, //My boats
                    route: PageDefinitions.myBoatsRoute,
                    visible: () => ((this.asCaptain) && (this.allowedAsCaptain) && ((this.captainStripeKycVerified) || (!this.isEntrepreneur))),
                },
                {
                    icon: "fal fa-credit-card",
                    label: Localizer.myPaymentMethodsPageTitleLanguageItemName, //MyPaymentMethods
                    route: PageDefinitions.myPaymentMethodsRoute,
                    visible: () => (this.asPassenger),
                    count: () => (this.hasPendingPayments) ? this.pendingPaymentsCount : (!this.hasValidPaymentMethod) ? 0 : null, // 0 - just for rendering
                    countClassName: () => (!this.hasValidPaymentMethod) ? mobileAppStyles.noDefaultBankCard : ""
                },
                {
                    icon: "fal fa-user-check",
                    label: Localizer.mobileKycPageMenuItemLanguageItemName, //Kyc
                    route: PageDefinitions.kycRoute,
                    visible: () => ((this.asCaptain) && (this.allowedAsCaptain) && (this.isEntrepreneur) && (!this.captainStripeKycVerified)),
                },
                {
                    icon: "far fa-qrcode",
                    label: Localizer.mobileInspectorMenuItemLanguageItemName, //Inspector
                    route: PageDefinitions.mobileInspectorRoute,
                    visible: () => this.asInspector,
                },
                {
                    icon: "far fa-users",
                    label: Localizer.mobilePassengersOnboardMenuItemLanguageItemName, //PassengersOnBoard
                    route: PageDefinitions.passengersOnBoardRoute,
                    visible: () => this.asInspector,
                },
                {
                    icon: "fal fa-user-alt",
                    label: Localizer.mobileProfilePageTitleLanguageItemName, //Profile,
                    route: PageDefinitions.profileRoute,
                    visible: () => ((this.asPassenger) || (this.asInspector) || ((this.asCaptain) && (this.allowedAsCaptain))),
                },
                {
                    icon: "fal user-headset",
                    label: Localizer.topNavContactSupportLanguageItemName, //Contact,
                    route: PageDefinitions.contactSupportRoute,
                    visible: () => (ServiceProviderController.isPjta) && (this.asPassenger),
                },
                {
                    icon: "fal fa-cog",
                    label: Localizer.settingsPageTitleLanguageItemName, //Settings,
                    route: PageDefinitions.settingsRoute,
                    visible: () => ((!ServiceProviderController.isHds) && ((this.asPassenger) || ((this.asCaptain) && (this.allowedAsCaptain) && ((this.captainStripeKycVerified) || (!this.isEntrepreneur))))),
                },
                {
                    icon: "fal fa-info",
                    label: Localizer.mobileAttractionsMenuItemLanguageItemName , //Attractions,
                    route: () => Dashboard.openAttractionModalAsync(),
                    visible: () => ((ServiceProviderController.isHds) && (this.asPassenger)),
                },
                {
                    icon: "fal fa-arrow-circle-left",
                    label: Localizer.topNavLogoutLanguageItemName, //Logout
                    bottom: true,
                    route: PageDefinitions.logoutRoute
                }
            ];
            
            const asPassengerStyle = this.asPassenger && nameof(this.asPassenger);
            const asCaptainStyle = this.asCaptain && nameof(this.asCaptain);
            
            return {
                fixed: ch.desktop,
                userProfile: true,
                className: Utility.css(mobileAppStyles.leftNav, asPassengerStyle, asCaptainStyle),
                items: menuItems
            };
        }

        return null;
    }

    public getNotifications(): ITopNavNotifications | null {
        if (((ch.isAuthenticated) && ((this.asCaptain) || (this.asPassenger)))) {
            return {
                className: mobileAppStyles.notifications,
                count: this.newNotificationsCount,
                onClick: async () => {
                    await PageRouteProvider.redirectAsync(PageDefinitions.notificationsRoute)
                }
            }
        }

        return null;
    }
    
    public getFullPageTitle(title: string): string {
        return (!!title)
            ? `${title} - ${ServiceProviderController.applicationName}`
            : ServiceProviderController.applicationName;
    }

    // public get context(): Context {
    //     if ((!this._initialized) || (this._context == null))
    //         throw Error("Context is not initialized yet, async method initializeAsync() is not called or not completed yet.");
    //
    //     return this._context!;
    // }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    public get user(): User {
        return ch.getUser();
    }

    public get userId(): string {
        return ch.getUserId();
    }

    public get companyName(): string {
        const serviceProviderSlug: ServiceProvider | null = ServiceProviderController.serviceProviderSlug;
        
        return (serviceProviderSlug?.companyName != null) 
            ? serviceProviderSlug.companyName
            : AppConstants.applicationName;
    }

    public get languages(): ILanguage[] {
        const order: string[] = ["fr", "en", "es", "de", "it", "fi", "sv", "nl"];

        const languages: ILanguage[] = Localizer.supportedLanguages;

        languages.sort((a, b) => order.indexOf(a.code) - order.indexOf(b.code));

        return languages;
    }

    public get footerLinks(): IFooterLink[] {
        const serviceProviderSlug: ServiceProvider | null = ServiceProviderController.serviceProviderSlug;
        
        if (serviceProviderSlug != null) {
            const links: IFooterLink[] = [];
            
            if (serviceProviderSlug.url) {
                links.push({href: serviceProviderSlug.url, label: Localizer.componentFooterFrontpageLanguageItemName})
                links.push({href: `${serviceProviderSlug.url}/contact`, label: Localizer.componentFooterContactLanguageItemName})
            }
            
            if (serviceProviderSlug.socialMediaLinks) {
                
                if (serviceProviderSlug.socialMediaLinks.youtubeUrl) {
                    links.push({href: serviceProviderSlug.socialMediaLinks.youtubeUrl, label: "Youtube"})
                }
                
                if (serviceProviderSlug.socialMediaLinks.discordUrl) {
                    links.push({href: serviceProviderSlug.socialMediaLinks.discordUrl, label: "Discord"})
                }
                
                if (serviceProviderSlug.socialMediaLinks.linkedinUrl) {
                    links.push({href: serviceProviderSlug.socialMediaLinks.linkedinUrl, label: "LinkedIn"})
                }
                
                if (serviceProviderSlug.socialMediaLinks.instagramUrl) {
                    links.push({href: serviceProviderSlug.socialMediaLinks.instagramUrl, label: "Instagram"})
                }
                
                if (serviceProviderSlug.socialMediaLinks.twitterUrl) {
                    links.push({href: serviceProviderSlug.socialMediaLinks.twitterUrl, label: "Twitter"})
                }
                
                if (serviceProviderSlug.socialMediaLinks.facebookUrl) {
                    links.push({href: serviceProviderSlug.socialMediaLinks.facebookUrl, label: "Facebook"})
                }
            }
            
            return links;
        }
        
        return [
            {href: "https://www.bout.fi", label: Localizer.componentFooterFrontpageLanguageItemName},
            {href: "https://www.bout.fi/contact", label: Localizer.componentFooterContactLanguageItemName},
        ];
    }
}

//Singleton
export default new AppController();