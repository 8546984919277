import React from "react";
import {BaseComponent, IBaseComponent} from "@reapptor-apps/reapptor-react-common";
import Container from "@/components/Container/Container";
import Country from "@/models/server/bout/Country";
import Localizer from "@/localization/Localizer";

import styles from "./CountryContainer.module.scss";

export interface ICountryContainerProps {
    countries: Country[];
    activeOnly: boolean;
    search?: string;
    onClick?(sender: IBaseComponent, item: Country): Promise<void>;
}

export default class CountryContainer extends BaseComponent<ICountryContainerProps> {
    
    private async onClickAsync(item: Country): Promise<void> {
        if (this.props.onClick) {
            await this.props.onClick(this, item);
        }
    }

    private renderCountry(item: Country, index: number): React.ReactNode {
        const inactiveStyle = ((item) && (!item.active)) && styles.inactive;

        return (
            <Container.Item key={index} className={this.css(styles.country, inactiveStyle)}>
                {
                    (item.logo) &&
                    (
                        <img className={styles.logo} src={item.logo} alt={item.name || undefined} title={item.nativeName || undefined} />
                    )
                }
                <div className={styles.info} onClick={() => this.onClickAsync(item)}>
                    <span>
                        {item.name} ({item.code})
                        <p>{item.nativeName}</p>
                    </span>
                    <span>{item.moneyThreeLetterName} ({item.moneySymbol})</span>
                    <span>{item.vatPercentage} ({item.vatValue})</span>
                    <span>{item.contactEmail} {item.contactPhone}</span>
                </div>
            </Container.Item>
        );
    }
    
    private filter(item: Country, search: string): boolean {
        return (
            ((!!item.name) && (item.name.toLowerCase().includes(search))) ||
            ((!!item.nativeName) && (item.nativeName.toLowerCase().includes(search))) ||
            ((!!item.code) && (item.code == search))
        )
    }
    
    private getCountries(): Country[] {
        const search: string | null = this.props.search || null;
        const activeOnly: boolean = this.props.activeOnly;
        let countries: Country[] = this.props.countries;
        if (activeOnly) {
            countries = countries.filter(item => item.active);
        }
        return (search)
            ? countries.filter(item => this.filter(item, search.toLowerCase()))
            : countries;
    }

    public render(): React.ReactNode {
        const countries: Country[] = this.getCountries();
        return (
            <div id={this.id} className={this.css(styles.countryContainer)}>
                {
                    (countries.length > 0)
                        ?
                        (
                            <Container>
                                {countries.map((item, index) => this.renderCountry(item, index))}
                            </Container>
                        )
                        :
                        (
                            <span className={styles.noCountries}>{Localizer.countryContainerNoCountries}</span>
                        )
                }
            </div>
        )
    }
}