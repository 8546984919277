import React from "react";
import {FileModel, ILanguage, IPagedList, TimeSpan, Utility} from "@reapptor-apps/reapptor-toolkit";
import {
    AlertModel,
    AlertType,
    BasePageParameters,
    ch,
    TextAlign,
    VerticalAlign
} from "@reapptor-apps/reapptor-react-common";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import User from "../../models/server/User";
import UserRole from "../../models/server/UserRole";
import SaveUserRequest from "../../models/server/requests/SaveUserRequest";
import {AuthType, LoginResultStatus} from "@/models/Enums";
import DeleteUserResponse from "@/models/server/responses/DeleteUserResponse";
import UserInvitation from "@/models/server/UserInvitation";
import SaveUserResponse from "@/models/server/responses/SaveUserResponse";
import GetUsersRequest from "@/models/server/requests/GetUsersRequest";
import {
    Alert,
    BaseRegexValidatorErrorMessage,
    Button,
    ButtonContainer,
    ButtonType,
    CellModel,
    Checkbox,
    ColumnDefinition,
    ColumnType,
    Dropdown,
    DropdownAlign,
    DropdownOrderBy,
    DropdownRequiredType,
    EmailInput,
    Form,
    Grid,
    IconSize,
    ImageInput,
    Inline,
    JustifyContent,
    List,
    NumberInput, 
    OneColumn,
    PageContainer,
    PageHeader,
    PageRow,
    PhoneInput,
    SelectListItem,
    Tab,
    TabContainer, 
    TextAreaInput,
    TextInput,
    ToolbarButton,
    ToolbarContainer,
    ToolbarRow,
    TwoColumns
} from "@reapptor-apps/reapptor-react-components";
import AppConstants from "@/helpers/AppConstants";
import BoatsPanel from "@/pages/UserManagement/BoutsPanel/BoatsPanel";
import Country from "@/models/server/bout/Country";
import StripeKycStatus from "@/models/server/stripe/kycstatus/StripeKycStatus";
import ValidatePhoneNumberRequest from "@/models/server/requests/ValidatePhoneNumberRequest";
import ListServiceProvidersResponse from "@/models/server/responses/ListServiceProvidersResponse";
import ListServiceProvidersRequest from "@/models/server/requests/ListServiceProvidersRequest";
import ServiceProvider from "@/models/server/ServiceProvider";
import TransformProvider from "@/providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import AppController from "@/pages/AppController";
import Localizer from "../../localization/Localizer";

import styles from "./UserManagement.module.scss";

import thumbnailImage from "../../img/thumbnail.png";
import StripeCreditCardStatus from "@/models/server/bout/StripeCreditCardStatus";
import UpdateUserStripePaymentMethodRequest from "@/models/server/requests/UpdateUserStripePaymentMethodRequest";
import Waypoint from "@/models/server/bout/Waypoint";
import ListWaypointsRequest from "@/models/server/requests/ListWaypointsRequest";
import Comparator from "@/helpers/Comparator";

export interface IUserManagementProps extends BasePageParameters {
    roleName?: string | null;
}

enum UserOrder {
    Name,

    CreatedAt
}

interface IUserManagementState {
    showDeleted: boolean;
    showLockedOnly: boolean,
    showExpired: boolean;
    acceptedRegistration: boolean;
    validCreditCard: boolean;
    hasBoats: boolean;
    filterRoleNames: string[];
    filterServiceProviderId: string | null;
    user: User | null;
    prevUser: User | null;
    roles: UserRole[];
    showAddButton: boolean;
    isModified: boolean;
    serviceProviders: ServiceProvider[];
    waypoints: Waypoint[];
    avatar: FileModel | null;
    countries: Country[];
    isValidPhoneNumber: boolean;
    orderBy: UserOrder;
    kycStatus: StripeKycStatus | null;
    stripeCreditCardStatuses: StripeCreditCardStatus[];
}

export default class UserManagement extends AuthorizedPage<IUserManagementProps, IUserManagementState> {

    state: IUserManagementState = {
        showDeleted: false,
        showLockedOnly: false,
        showExpired: false,
        acceptedRegistration: false,
        validCreditCard: false,
        hasBoats: false,
        filterRoleNames: this.initializeFilterRoleNames(),
        filterServiceProviderId: null,
        user: null,
        prevUser: null,
        roles: [],
        showAddButton: true,
        isModified: false,
        serviceProviders: [],
        waypoints: [],
        avatar: null,
        countries: [],
        isValidPhoneNumber: true,
        orderBy: UserOrder.Name,
        kycStatus: null,
        stripeCreditCardStatuses: []
    };

    private readonly _listRef: React.RefObject<List<User>> = React.createRef();
    private readonly _emailRef: React.RefObject<EmailInput> = React.createRef();
    private readonly _boatsPanelRef: React.RefObject<BoatsPanel> = React.createRef();
    private readonly _tabContainerRef: React.RefObject<TabContainer> = React.createRef();

    private _originalUser: User | null = null;

    private readonly _invitationColumns: ColumnDefinition[] = [
        {
            header: Localizer.adminGridCreatedByLanguageItemName,
            accessor: "createdBy",
            minWidth: 100
        } as ColumnDefinition,
        {
            header: Localizer.adminGridTypeLanguageItemName,
            accessor: "type",
            type: ColumnType.Custom,
            format: "InvitationType",
            minWidth: 90
        } as ColumnDefinition,
        {
            header: Localizer.adminGridAuthTypeLanguageItemName,
            accessor: "authType",
            type: ColumnType.Custom,
            format: "AuthType",
            minWidth: 90,
        } as ColumnDefinition,
        {
            header: Localizer.adminGridCreatedAtLanguageItemName,
            accessor: "createdAt",
            format: "dt",
            textAlign: TextAlign.Center,
            minWidth: 90,
            verticalAlign: VerticalAlign.Middle
        } as ColumnDefinition,
        {
            header: Localizer.adminGridExpiresAtLanguageItemName,
            accessor: "validTill",
            format: "dt",
            textAlign: TextAlign.Center,
            minWidth: 90,
            init: (cell) => this.initValidTill(cell)
        } as ColumnDefinition,
        {
            header: Localizer.adminGridProcessedAtLanguageItemName,
            accessor: "processedAt",
            format: "dt",
            textAlign: TextAlign.Center,
            minWidth: 90
        } as ColumnDefinition,
        {
            header: Localizer.adminGridReusableLanguageItemName,
            accessor: (model: UserInvitation) => model.reusable ? "✓" : "",
            textAlign: TextAlign.Center,
            minWidth: 90
        } as ColumnDefinition
    ];

    private userOrderToListItem(orderBy: UserOrder): SelectListItem {
        const item = new SelectListItem();
        item.value = orderBy.toString();
        item.ref = orderBy;
        item.text = (orderBy == UserOrder.Name)
            ? Localizer.userManagementPageUserOrderName
            : Localizer.userManagementPageUserOrderCreation;
        return item;
    }

    private orderByToSelectedText(orderBy: UserOrder): string {
        return (orderBy == UserOrder.Name)
            ? Localizer.userManagementPageUserOrderNameShort
            : Localizer.userManagementPageUserOrderCreationShort;
    }

    private initializeFilterRoleNames(): string[] {
        const parameters = this.parameters as IUserManagementProps | null;

        if (parameters?.roleName) {
            return [parameters.roleName];
        }

        return [];
    }

    private initValidTill(cell: CellModel<UserInvitation>): void {
        const model: UserInvitation = cell.row.model;
        const diff: TimeSpan = Utility.diff(model.validTill, Utility.utcNow());
        const expired: boolean = (diff.totalMilliseconds < 0);
        if (expired) {
            cell.className = "danger";
        }
    }

    private get countries(): Country[] {
        return this.state.countries;
    }

    private get isCaptain(): boolean {
        return (this.user != null) && (User.hasCaptainRole(this.user));
    }

    private get isPassenger(): boolean {
        return (this.user != null) && (User.hasPassengerRole(this.user));
    }

    private get isInspector(): boolean {
        return (this.user != null) && (User.hasInspectorRole(this.user));
    }

    private get isServiceProviderManager(): boolean {
        return (this.user != null) && (User.hasServiceProviderManagerRole(this.user));
    }

    private get isEntrepreneur(): boolean {
        return (this.user != null) && (this.user.isEntrepreneur);
    }

    private get pictures(): FileModel[] | string | null {
        return (this.state.avatar != null)
            ? [this.state.avatar]
            : thumbnailImage;
    }

    private async cancelModifyingAsync(): Promise<void> {
        Utility.copyTo(this._originalUser, this.user);
        await this.setIsModified(false);
    }

    private async fetchUserRolesAsync(): Promise<UserRole[]> {
        return await this.getAsync("/api/admin/getUserRoles");
    }

    private async fetchWaypointsAsync(): Promise<Waypoint[]> {

        const request = new ListWaypointsRequest();
        request.pageNumber = 1;
        request.pageSize = AppConstants.maxPageSize;
        request.asAdmin = AppController.asAdmin;

        const response: IPagedList<Waypoint> = await this.postAsync("/api/admin/listWaypoints", request);

        return response.items;
    }

    private async fetchServiceProvidersAsync(): Promise<ServiceProvider[]> {
        const request = new ListServiceProvidersRequest();

        const serviceProvidersResponse: ListServiceProvidersResponse = await this.postAsync("/api/cruisePackage/listServiceProviders", request);

        return serviceProvidersResponse.serviceProviders ?? [];
    }

    private async setSelectedUser(selectedUser: User | null): Promise<void> {
        if (this.user !== selectedUser) {

            if (selectedUser != null) {
                selectedUser = await this.postAsync("/api/admin/getUser", selectedUser.id);
            }

            await this.setState({kycStatus: null, stripeCreditCardStatuses: []});

            if (this.state.showAddButton) {

                await this.setState({user: selectedUser, prevUser: null, showAddButton: true, avatar: null});

                if (this._boatsPanelRef.current != null) {
                    await this._boatsPanelRef.current.reloadAsync();
                }

                if (this.user && this.user.avatarId) {
                    const avatar: FileModel | null = await this.postAsync("/api/files/getAvatar", this.user.avatarId);
                    await this.setState({avatar});
                }
            }

            this._originalUser = Utility.clone(selectedUser);

            await this.reRenderAsync();
        }
    }

    private get userFullName(): string {
        return (this.user) ? TransformProvider.userToString(this.user) : "";
    }

    private async setAvatarAsync(pictures: FileModel[]): Promise<void> {
        if (this.user) {
            const avatar: FileModel | null = pictures.firstOrDefault();

            await this.setState({avatar});
        }
    }

    private async saveAsync(): Promise<void> {
        const user: User = this.user!;
        const newUser: boolean = User.isNew(user);

        const request = new SaveUserRequest();
        request.id = user.id;
        request.authType = AuthType.Email;
        request.roleNames = user.roles.map(item => item.roleName);
        request.email = user.email;
        request.phone = user.phone;
        request.firstname = user.firstname;
        request.lastName = user.lastName;
        request.language = user.language;
        request.agreementAccepted = User.isAgreementAccepted(user, this.serviceProviderSlug);
        request.registrationAccepted = User.isRegistrationAccepted(user, this.serviceProviderSlug);
        request.cancellationPolicyAccepted = User.isCancellationPolicyAccepted(user, this.serviceProviderSlug);
        request.adult = User.isAdult(user, this.serviceProviderSlug);
        request.allowAsCaptain = user.allowAsCaptain;
        request.allowAsPassenger = user.allowAsPassenger;
        request.isEntrepreneur = user.isEntrepreneur;
        request.businessId = user.businessId;
        request.countryId = user.countryId;
        request.testUser = user.testUser;
        request.serviceProviderId = user.serviceProviderId;
        request.waypointIds = user.waypointIds;

        if ((this.isCaptain) && (this.state.avatar == null)) {
            await this.alertErrorAsync(Localizer.adminAlertErrorCaptainAvatarRequired, true, true);
            return;
        }

        request.avatar = this.state.avatar;

        const response: SaveUserResponse = await this.postAsync("/api/admin/saveUser", request);

        if (response.businessIdNotUnique) {
            await this.alertErrorAsync(Localizer.adminAlertErrorBusinessIdNotUnique, true);
            return;
        }

        if (response.userAlreadyExists) {
            const message: string = (response.duplicatedEmail)
                ? Localizer.adminAlertErrorAccountWithSameEmailExist
                : Utility.format(Localizer.adminAlertErrorAccountExist, this.userFullName);

            await this.alertErrorAsync(message, true);
            return;
        }

        if (response.invitationSentFailed) {
            await this.alertErrorAsync(Localizer.adminAlertErrorInvitationSentFailed, true);
            return;
        }

        let message: string = Utility.format(Localizer.adminAlertMessageAccountSaved, this.userFullName);

        if (!response.duplicatedPhoneNumber) {
            await this.alertMessageAsync(message, true);
        } else {
            message = message + Localizer.adminAlertWarningDuplicatedPhoneNumber;
            await this.alertWarningAsync(message, false);
        }

        const responseUser: User = response.user!;

        this._originalUser = responseUser;

        if (newUser) {

            await this.list.reloadAsync();

            await this.setState({user: responseUser, showAddButton: true, isModified: false});

            this.list.scrollToSelected();

        } else {
            await this.setIsModified(false);

            await this.list.reRenderAsync();
        }
    }

    private async resetPasswordAsync(): Promise<void> {
        const userId: string = this.user!.id;
        await this.postAsync<LoginResultStatus>("/api/admin/ResetPassword", userId);
        await this.list.reloadAsync();
        await this.list.reRenderAsync();
    }

    private async updateKycStatusAsync(): Promise<void> {
        const userId: string = this.user!.id;
        const stripeKycStatus: StripeKycStatus = await this.postAsync("/api/admin/updateKycStatus", userId);
        await this.setState({kycStatus: stripeKycStatus});
    }

    private async checkStripePaymentMethodStatusAsync(): Promise<void> {
        const userId: string = this.user!.id;
        const stripeCreditCardStatuses: StripeCreditCardStatus[] = await this.postAsync("/api/admin/checkStripePaymentMethodStatus", userId);
        await this.setState({stripeCreditCardStatuses});
    }

    private async updatePaymentMethodAsync(fingerPrint: string): Promise<void> {
        const userId: string = this.user!.id;
        const request = new UpdateUserStripePaymentMethodRequest();
        request.userId = userId;
        request.fingerPrint = fingerPrint;

        const result: boolean = await this.postAsync("/api/admin/updateStripePaymentMethod", request);

        if (result) {
            await ch.alertMessageAsync(Localizer.userManagementPageUpdatePaymentMethodSuccess);
        } else {
            await ch.alertErrorAsync(Localizer.userManagementPageUpdatePaymentMethodFailure)
        }

        await this.checkStripePaymentMethodStatusAsync();
    }

    private async setShowDeletedAsync(showDeleted: boolean): Promise<void> {
        await this.setState({showDeleted});
        await this.list.reloadAsync();
    }

    private async setShowLockedOnlyAsync(showLockedOnly: boolean): Promise<void> {
        await this.setState({showLockedOnly: showLockedOnly});
        await this.list.reloadAsync();
    }

    private async setAcceptedRegistrationAsync(acceptedRegistration: boolean): Promise<void> {
        await this.setState({acceptedRegistration});
        await this.list.reloadAsync();
    }

    private async setValidCreditCardAsync(validCreditCard: boolean): Promise<void> {
        await this.setState({validCreditCard});
        await this.list.reloadAsync();
    }

    private async setHasBoatsAsync(hasBoats: boolean): Promise<void> {
        await this.setState({hasBoats});
        await this.list.reloadAsync();
    }

    private async setShowExpiredAsync(showExpired: boolean): Promise<void> {
        await this.setState({showExpired});
        await this.list.reloadAsync();
    }

    private async setFilterRoleNamesAsync(filterRoles: UserRole[]): Promise<void> {
        const filterRoleNames: string[] = filterRoles.map(item => item.roleName);
        await this.setState({filterRoleNames});
        await this.list.reloadAsync();
    }

    private async setFilterServiceProvidersAsync(serviceProvider: ServiceProvider | null): Promise<void> {
        const filterServiceProviderId: string | null = serviceProvider?.id ?? null;
        await this.setState({filterServiceProviderId});
        await this.list.reloadAsync();
    }

    private async setServiceProviderAsync(serviceProviderId: string | undefined, userInteraction: boolean): Promise<void> {
        if (this.user != null) {
            this.user.serviceProviderId = serviceProviderId ?? null;

            if (userInteraction) {
                await this.setIsModified();
            }
        }
    }

    private async setExclusiveWaypointsAsync(sender: Dropdown<Waypoint>, userInteraction: boolean): Promise<void> {
        const waypoints: Waypoint[] = sender.selectedItems;
        if (this.user != null) {
            const waypointIds: string[] = waypoints.select(item => item.id);
            
            const modified: boolean = Comparator.isEqual(waypointIds, this.user.waypointIds ?? []);
            
            this.user.waypointIds = waypointIds;

            if ((modified) && (userInteraction)) {
                await this.setIsModified();
            }
        }
    }

    private async addUserAsync(): Promise<void> {
        const prevUser: User | null = this.user;

        const user = new User();
        user.language = Localizer.language;
        user.role = this.state.roles[0];
        User.setAdult(user, this.serviceProviderSlug, true);

        if (this.countries.length > 0) {
            user.countryId = this.countries[0].id;
            user.country = this.countries[0];
        }

        await this.setState({user, prevUser, showAddButton: false, avatar: null});

        if (this._emailRef.current) {
            this._emailRef.current.focus();
        }
    }

    private async orderByAsync(order: UserOrder): Promise<void> {
        if (this.state.orderBy != order) {
            this.state.orderBy = order;
            await this.list.reloadAsync();
        }
    }

    private async resendInvitationAsync(): Promise<void> {
        const userId: string = this.user!.id;
        await this.postAsync("/api/admin/resendInvitation", userId);
        await this.list.reloadAsync();
        await this.list.reRenderAsync();
    }

    private async setIsModified(isModified = true, forceReRender: boolean = false): Promise<void> {
        isModified = isModified && this.state.showAddButton;
        if ((forceReRender) || (isModified !== this.state.isModified)) {
            await this.setState({isModified});
        }
    }

    private get isModified(): boolean {
        return this.state.isModified;
    }

    private async setPhone(value: string): Promise<void> {
        this.user!.phone = value;
        await this.setIsModified();
    }

    private async setEmailAsync(value: string): Promise<void> {
        this.user!.email = value;
        await this.setIsModified();
    }

    public getTitle(): string {
        return Localizer.topNavAdmin;
    }

    public get alert(): AlertModel {
        const alertModel = new AlertModel();
        alertModel.message = Utility.format(Localizer.adminAlertUserPasswordInfo, this.userFullName);
        alertModel.dismissible = false;
        alertModel.alertType = AlertType.Info;
        return alertModel;
    }

    public async deleteUserAsync(): Promise<void> {
        const user: User = this.user!;

        const response: DeleteUserResponse = await this.postAsync("/api/admin/deleteUser", user.id);

        const message: string = (response.removedPermanently)
            ? Utility.format(Localizer.adminUserDeletedPermanently, this.userFullName)
            : Utility.format(Localizer.get(Localizer.adminUserDeleted, this.userFullName));

        await this.alertMessageAsync(message, true);

        if (!this.showDeleted || response.removedPermanently) {
            await this.setState({user: null});

            await this.list.reloadAsync();

            this.list.scrollToSelected();
        } else {
            user.isDeleted = true;

            await this.list.reRenderAsync();

            await this.reRenderAsync();
        }
    }

    private async restoreUserAsync(): Promise<void> {
        const user: User = this.user!;

        await this.postAsync("/api/admin/restoreUser", user.id);

        user.isDeleted = false;

        await this.alertMessageAsync(Localizer.get(Localizer.adminUserRestored, user.email), true);

        await this.list.reRenderAsync();

        await this.reRenderAsync();
    }

    private async unlockUserAsync(): Promise<void> {
        const user: User = this.user!;

        await this.postAsync("/api/admin/unlockUser", user.id);

        user.isLocked = false;

        const message: string = Utility.format(Localizer.adminAlertMessageUnlockUser, this.userFullName);

        await this.alertMessageAsync(message, true);

        await this.list.reloadAsync();
        await this.list.reRenderAsync();

        await this.reRenderAsync();
    }

    private async resetStripeKeysAsync(): Promise<void> {
        const user: User = this.user!;

        await this.postAsync("/api/admin/resetStripeKeys", user.id);

        const message: string = Utility.format(Localizer.adminAlertMessageStripeKeysResetSuccessfully);

        await this.alertMessageAsync(message, true);

        await this.list.reRenderAsync();

        await this.reRenderAsync();
    }

    private async setDefaultPasswordAsync(): Promise<void> {
        const user: User = this.user!;

        await this.postAsync("/api/admin/setDefaultPassword", user.id);
    }

    private async cancelAddUserAsync(): Promise<void> {
        await this.setState({user: this.state.prevUser, prevUser: null, showAddButton: true});
    }

    private get canDelete(): boolean {
        const user: User = ch.getUser();
        const me: boolean = (user.id == this.user!.id);
        return !me;
    }

    private async getUsersAsync(sender: List<User>): Promise<User[]> {
        const request = new GetUsersRequest();
        request.showDeleted = this.showDeleted;
        request.showLockedOnly = this.showLockedOnly;
        request.showExpired = this.showExpired;
        request.acceptedRegistration = this.acceptedRegistration;
        request.validCreditCard = this.validCreditCard;
        request.hasBoats = this.hasBoats;
        request.roleNames = this.filterRoleNames;
        request.orderByCreatedAt = (this.state.orderBy == UserOrder.CreatedAt);
        request.includeDetails = false;
        request.serviceProviderId = this.filterServiceProviderId;

        return await sender.postAsync("/api/admin/getUsers", request);
    }

    private async onFirstNameChangeAsync(item: string): Promise<void> {
        const user = this.user;
        user!.firstname = item;
        await this.setIsModified();
    }

    private async onLastNameChangeAsync(item: string): Promise<void> {
        const user = this.user;
        user!.lastName = item;
        await this.setIsModified();
    }

    private async onBusinessIdChangeAsync(item: string): Promise<void> {
        const user = this.user;
        user!.businessId = item;
        await this.setIsModified();
    }

    private async onAllowAsCaptainCheckboxChangeAsync(item: boolean): Promise<void> {
        const user = this.user;
        user!.allowAsCaptain = item;
        await this.setIsModified(true, false);
    }

    private async onIsEntrepreneurChangeAsync(item: boolean): Promise<void> {
        const user = this.user;
        user!.isEntrepreneur = item;
        await this.setIsModified(true, true);
    }

    private async onAllowAsPassengerCheckboxChangeAsync(item: boolean): Promise<void> {
        const user = this.user;
        user!.allowAsPassenger = item;
        await this.setIsModified(true, false);
    }

    private async onTestUserCheckboxChangeAsync(item: boolean): Promise<void> {
        const user = this.user;
        user!.testUser = item;
        await this.setIsModified(true, false);
    }

    private async setUserRoles(selectedUserRoles: UserRole[], userInteraction: boolean): Promise<void> {
        if (userInteraction) {

            const user: User = this.user!;

            user.roles = selectedUserRoles.map(role => role);
            user.isCaptain = User.hasCaptainRole(user);
            user.isPassenger = User.hasPassengerRole(user);

            if (!user.isCaptain) {
                user.allowAsCaptain = false;
            }

            user.allowAsPassenger = (user.isPassenger);

            await this.setIsModified(true, true);
        }
    }

    private async setCountryAsync(country: Country, userInteraction: boolean): Promise<void> {
        this.user!.country = country;
        this.user!.countryId = country.id;
        if (userInteraction) {
            await this.setIsModified();
        }
    }

    private async setLanguageAsync(language: ILanguage, userInteraction: boolean): Promise<void> {
        this.user!.language = language.code;

        if (userInteraction) {
            await this.setIsModified();
        }
    }

    private get user(): User | null {
        return this.state.user;
    }

    private get list(): List<User> {
        return this._listRef.current!;
    }

    private get showDeleted(): boolean {
        return this.state.showDeleted;
    }

    private get showLockedOnly(): boolean {
        return this.state.showLockedOnly;
    }

    private get acceptedRegistration(): boolean {
        return this.state.acceptedRegistration;
    }

    private get validCreditCard(): boolean {
        return this.state.validCreditCard;
    }

    private get hasBoats(): boolean {
        return this.state.hasBoats;
    }

    private get showExpired(): boolean {
        return this.showDeleted && this.state.showExpired;
    }

    private get filterRoleNames(): string[] {
        return this.state.filterRoleNames;
    }

    private get filterServiceProviderId(): string | null {
        return this.state.filterServiceProviderId;
    }

    private get availableServiceProviders(): ServiceProvider[] {
        const allServiceProviders: ServiceProvider[] = this.state.serviceProviders;
        return (this.isCaptain)
            ? (!this.isEntrepreneur)
                ? allServiceProviders.where(item => item.waterOperator)
                : []
            : ((this.isServiceProviderManager) || (this.isInspector))
                ? allServiceProviders
                : [];
    }

    private get availableWaypoints(): Waypoint[] {
        const allWaypoint: Waypoint[] = this.state.waypoints;
        const countryId: string | null = this.user?.countryId || null;
        return (countryId)
            ? allWaypoint.where(item => item.source?.area?.countryId == countryId)
            : [];
    }

    private get kycStatus(): StripeKycStatus | null {
        return this.state.kycStatus;
    }

    private async validatePhoneNumberAsync(value: string | null): Promise<boolean> {
        if (value) {
            const request = new ValidatePhoneNumberRequest();
            request.value = value;
            request.countryId = this.user!.countryId;
            request.personalOnly = false;

            return await this.postAsync("/api/user/isPhoneNumberValid", request);
        }

        return false;
    }

    private async onBlurAsync(sender: PhoneInput): Promise<void> {
        const isValidPhoneNumber: boolean = await this.validatePhoneNumberAsync(this.user!.phone);

        if (isValidPhoneNumber !== this.state.isValidPhoneNumber) {
            this.state.isValidPhoneNumber = isValidPhoneNumber;
            await sender.validateAsync();
        }
    }

    public validatePhoneNumber(): string | null {
        return (!this.state.isValidPhoneNumber)
            ? Localizer.get(BaseRegexValidatorErrorMessage.validatorsPhoneLanguageItemName)
            : null;
    }

    public async initializeAsync(): Promise<void> {

        const [roles, countries, waypoints, serviceProviders] = await Promise.all([
            this.fetchUserRolesAsync(),
            AppController.listCountriesAsync(),
            this.fetchWaypointsAsync(),
            this.fetchServiceProvidersAsync()
        ]);

        await this.setState({roles, countries, serviceProviders, waypoints});
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.userManagement} fullHeight fullWidth>

                <PageHeader title={Localizer.userManagementPageTitle}/>

                <PageRow>
                    <div className="col d-flex flex-column">

                        <ToolbarContainer className={styles.toolbar}>

                            <Inline justify={JustifyContent.Start}>

                                <Dropdown id="roleNameFilter"
                                          inline noWrap multiple autoCollapse clearButton
                                          align={DropdownAlign.Left}
                                          width={210}
                                          minWidth={210}
                                          maxWidth={210}
                                          nothingSelectedText={Localizer.adminRoleFilterNothingSelected}
                                          orderBy={DropdownOrderBy.None}
                                          selectedItems={this.state.filterRoleNames}
                                          selectedTextFormat={1}
                                          items={this.state.roles}
                                          onChange={(sender) => this.setFilterRoleNamesAsync(sender.selectedItems)}
                                />

                                <Dropdown id="serviceProviderFilter"
                                          inline noWrap autoCollapse clearButton
                                          align={DropdownAlign.Left}
                                          className={styles.filterServiceProvider}
                                          width={210}
                                          minWidth={210}
                                          maxWidth={210}
                                          nothingSelectedText={Localizer.adminServiceProviderFilterNothingSelected}
                                          orderBy={DropdownOrderBy.None}
                                          selectedItem={this.state.filterServiceProviderId}
                                          items={this.state.serviceProviders}
                                          onChange={(sender) => this.setFilterServiceProvidersAsync(sender.selectedItem)}
                                />

                                <Checkbox inline
                                          id="showLockedOnly"
                                          label={Localizer.adminShowLockedOnly}
                                          value={this.showLockedOnly}
                                          onChange={(_, value) => this.setShowLockedOnlyAsync(value)}
                                />

                                <Checkbox inline
                                          id="showDeleted"
                                          label={Localizer.adminShowDeleted}
                                          value={this.showDeleted}
                                          onChange={(_, value) => this.setShowDeletedAsync(value)}
                                />

                                {
                                    (this.showDeleted) &&
                                    (
                                        <Checkbox inline
                                                  id="showExpired"
                                                  label={Localizer.userManagementPageCheckboxShowExpired}
                                                  value={this.showExpired}
                                                  onChange={async (_, value) => await this.setShowExpiredAsync(value)}
                                        />
                                    )
                                }

                            </Inline>

                            <Inline justify={JustifyContent.End}>

                                <Dropdown required inline noSubtext noValidate noWrap
                                          align={DropdownAlign.Right}
                                          label={Localizer.userManagementPageDropdownOrderByLabel}
                                          orderBy={DropdownOrderBy.None}
                                          transform={(item: UserOrder) => this.userOrderToListItem(item)}
                                          selectedTextTransform={(sender) => this.orderByToSelectedText(sender.selectedItem!)}
                                          items={[UserOrder.Name, UserOrder.CreatedAt]}
                                          selectedItem={this.state.orderBy}
                                          onChange={(sender, item: UserOrder) => this.orderByAsync(item)}
                                />

                            </Inline>

                        </ToolbarContainer>

                        <ToolbarContainer>

                            <ToolbarRow justify={JustifyContent.SpaceBetween}>

                                <Inline>

                                    <Checkbox inline
                                              id="acceptedRegistration"
                                              label={Localizer.userManagementPageFiltersAcceptedRegistration}
                                              value={this.acceptedRegistration}
                                              onChange={(sender, value) => this.setAcceptedRegistrationAsync(value)}
                                    />

                                    <Checkbox inline
                                              id="validCreditCard"
                                              label={Localizer.userManagementPageFiltersValidCreditCard}
                                              value={this.validCreditCard}
                                              onChange={(sender, value) => this.setValidCreditCardAsync(value)}
                                    />

                                    <Checkbox inline
                                              id="hasBoats"
                                              label={Localizer.userManagementPageFiltersHasBoats}
                                              value={this.hasBoats}
                                              onChange={(sender, value) => this.setHasBoatsAsync(value)}
                                    />

                                </Inline>

                                <Inline>

                                    <ToolbarButton label={Localizer.adminAddUser}
                                                   icon={{name: "plus", size: IconSize.Large}}
                                                   type={ButtonType.Orange}
                                                   disabled={!this.state.showAddButton || this.isModified}
                                                   onClick={() => this.addUserAsync()}
                                    />

                                </Inline>

                            </ToolbarRow>

                        </ToolbarContainer>

                        <div className="row h-100">

                            <div className="col-md-4 pb-4">

                                <List required noGrouping absoluteListItems
                                      ref={this._listRef}
                                      id="users"
                                      className={styles.users}
                                      maxHeight={"auto"}
                                      disabled={!this.state.showAddButton || this.isModified}
                                      orderBy={DropdownOrderBy.None}
                                      filterMinLength={10}
                                      filterMaxLength={10000}
                                      transform={(user: User) => TransformProvider.toUserListItem(user, user.isPassenger)}
                                      fetchItems={(sender: List<User>) => this.getUsersAsync(sender)}
                                      selectedItem={this.user}
                                      onChange={(_, item) => this.setSelectedUser(item)}
                                />

                            </div>

                            <div className="col-md-8">

                                {
                                    (this.user != null) &&
                                    (
                                        <TabContainer ref={this._tabContainerRef} id="userManagementTabs">

                                            <Tab id="account" title={Localizer.topNavAccount}>

                                                <Form id="form" onSubmit={() => this.saveAsync()}>

                                                    <TwoColumns>

                                                        <TextInput id="authType" required readonly
                                                                   label={Localizer.adminLabelAuthenticationType}
                                                                   value={EnumProvider.getAuthTypeText(this.user.authType)}
                                                        />

                                                        <Dropdown id="roleName" required multiple
                                                                  selectedTextFormat={3}
                                                                  requiredType={DropdownRequiredType.Restricted}
                                                                  nothingSelectedText={Localizer.adminChooseRole}
                                                                  label={Localizer.formInputRole}
                                                                  orderBy={DropdownOrderBy.None}
                                                                  items={this.state.roles}
                                                                  selectedItems={this.user.roles || []}
                                                                  onChange={(sender, item, userInteraction) => this.setUserRoles(sender.selectedItems, userInteraction)}
                                                        />

                                                    </TwoColumns>

                                                    <TwoColumns>

                                                        <EmailInput trim required
                                                                    id="email" ref={this._emailRef}
                                                                    label={Localizer.formInputEmail}
                                                                    readonly={(!User.isNew(this.user)) && (!!this.user.email)}
                                                                    value={this.user.email}
                                                                    onChange={(_, value) => this.setEmailAsync(value)}
                                                        />

                                                        <PhoneInput trim required
                                                                    id="phone"
                                                                    label={Localizer.formInputPhone}
                                                                    validators={[() => this.validatePhoneNumber()]}
                                                                    value={this.user.phone}
                                                                    onBlur={(sender) => this.onBlurAsync(sender as PhoneInput)}
                                                                    onChange={(_, value) => this.setPhone(value)}
                                                        />

                                                    </TwoColumns>

                                                    <TwoColumns>

                                                        <TextInput trim
                                                                   id="firstname"
                                                                   label={Localizer.formInputFirstname}
                                                                   value={this.user.firstname} required
                                                                   onChange={async (sender, item) => await this.onFirstNameChangeAsync(item!)}
                                                        />

                                                        <TextInput trim
                                                                   id="lastName"
                                                                   onChange={async (sender, item) => await this.onLastNameChangeAsync(item!)}
                                                                   label={Localizer.formInputLastname}
                                                                   value={this.user.lastName} required
                                                        />

                                                    </TwoColumns>

                                                    <TwoColumns>

                                                        {
                                                            ((this.isCaptain) && (this.isEntrepreneur)) &&
                                                            (
                                                                <TextInput required
                                                                           id="businessId"
                                                                           label={Localizer.userManagementPageTextInputBusinessIdLabel}
                                                                           value={this.user.businessId}
                                                                           onChange={(_, item) => this.onBusinessIdChangeAsync(item!)}
                                                                />
                                                            )
                                                        }

                                                    </TwoColumns>

                                                    <TwoColumns>

                                                        <Dropdown id="language" required
                                                                  label={Localizer.formInputLanguage}
                                                                  items={Localizer.supportedLanguages}
                                                                  selectedItem={Localizer.findLanguage(this.user.language)}
                                                                  onChange={(sender, item, userInteraction) => this.setLanguageAsync(item!, userInteraction)}
                                                        />

                                                        <Dropdown id="country" required
                                                                  label={Localizer.formInputCountry}
                                                                  items={this.countries}
                                                                  selectedItem={this.user.country}
                                                                  onChange={(sender, item, userInteraction) => this.setCountryAsync(item!, userInteraction)}
                                                        />

                                                    </TwoColumns>

                                                    <TwoColumns>

                                                        {
                                                            ((this.isCaptain) || (this.isPassenger)) &&
                                                            (
                                                                <NumberInput required readonly
                                                                             id="paymentBalance"
                                                                             label={Localizer.userManagementPagePaymentBalance}
                                                                             value={this.user.paymentBalance}
                                                                />
                                                            )
                                                        }

                                                        {
                                                            ((this.isServiceProviderManager) || (this.isInspector) || ((this.isCaptain) && (!this.isEntrepreneur))) &&
                                                            (
                                                                <Dropdown id="serviceProviders" required
                                                                          noWrap autoCollapse
                                                                          minWidth={210}
                                                                          label={Localizer.userManagementPageServiceProvider}
                                                                          requiredType={DropdownRequiredType.Restricted}
                                                                          orderBy={DropdownOrderBy.None}
                                                                          selectedItem={this.user.serviceProviderId}
                                                                          items={this.availableServiceProviders}
                                                                          onChange={(_, serviceProvider, userInteraction: boolean) => this.setServiceProviderAsync(serviceProvider?.id, userInteraction)}
                                                                />
                                                            )
                                                        }

                                                        {
                                                            ((this.isPassenger) && (!this.isCaptain) && (!this.isInspector) && (!this.isServiceProviderManager)) &&
                                                            (
                                                                <Dropdown id="waypoints" multiple noWrap autoCollapse groupSelected
                                                                          minWidth={210}
                                                                          label={Localizer.userManagementPageExclusiveWaypoints}
                                                                          selectedTextFormat={1}
                                                                          selectedItems={this.user.waypointIds ?? []}
                                                                          items={this.availableWaypoints}
                                                                          onChange={(sender, _, userInteraction: boolean) => this.setExclusiveWaypointsAsync(sender, userInteraction)}
                                                                />
                                                            )
                                                        }

                                                    </TwoColumns>

                                                    <TwoColumns>

                                                        {
                                                            ((this.isPassenger) || (this.isCaptain)) &&
                                                            (
                                                                <Checkbox id="testUser"
                                                                          label={Localizer.userManagementPageCheckboxTestUserLabel}
                                                                          value={this.user.testUser}
                                                                          onChange={(_, item) => this.onTestUserCheckboxChangeAsync(item!)}
                                                                />
                                                            )
                                                        }

                                                        {
                                                            (this.isPassenger) &&
                                                            (
                                                                <Checkbox id="allowAsPassenger"
                                                                          label={Localizer.userManagementPageCheckboxAllowAsPassengerLabel}
                                                                          value={this.user.allowAsPassenger}
                                                                          onChange={(_, item) => this.onAllowAsPassengerCheckboxChangeAsync(item!)}
                                                                />
                                                            )
                                                        }

                                                    </TwoColumns>
                                                    
                                                    <TwoColumns>

                                                        {
                                                            (this.isCaptain) &&
                                                            (
                                                                <Checkbox id="isEntrepreneur"
                                                                          label={Localizer.userManagementPageCheckboxEntrepreneurLabel}
                                                                          value={this.user.isEntrepreneur}
                                                                          onChange={(_, item) => this.onIsEntrepreneurChangeAsync(item!)}
                                                                />
                                                            )
                                                        }

                                                        {
                                                            (this.isCaptain) &&
                                                            (
                                                                <Checkbox id="allowAsCaptain"
                                                                          label={Localizer.userManagementPageCheckboxAllowAsCaptainLabel}
                                                                          value={this.user.allowAsCaptain}
                                                                          onChange={(_, item) => this.onAllowAsCaptainCheckboxChangeAsync(item!)}
                                                                />
                                                            )
                                                        }

                                                    </TwoColumns>

                                                    <ButtonContainer>

                                                        {
                                                            (!this.state.showAddButton) &&
                                                            (
                                                                <Button small
                                                                        minWidth={90}
                                                                        label={Localizer.genericCancel}
                                                                        type={ButtonType.Primary}
                                                                        icon={{name: "far ban", size: IconSize.Large}}
                                                                        onClick={() => this.cancelAddUserAsync()}
                                                                />
                                                            )
                                                        }

                                                        {
                                                            (this.isModified) &&
                                                            (
                                                                <Button small
                                                                        minWidth={90}
                                                                        label={Localizer.genericCancel}
                                                                        type={ButtonType.Primary}
                                                                        icon={{name: "far ban", size: IconSize.Large}}
                                                                        confirm={Localizer.adminConfirmationButtonRollback}
                                                                        onClick={() => this.cancelModifyingAsync()}
                                                                />
                                                            )
                                                        }

                                                        {
                                                            ((this.state.showAddButton) && (!this.user.isDeleted)) &&
                                                            (
                                                                <Button small
                                                                        minWidth={90}
                                                                        label={Localizer.adminButtonDelete}
                                                                        icon={{name: "trash-alt", size: IconSize.Large}}
                                                                        type={ButtonType.Primary}
                                                                        disabled={!this.canDelete || this.isModified}
                                                                        onClick={() => this.deleteUserAsync()}
                                                                        confirm={Utility.format(Localizer.adminConfirmationButtonDeleteUser, this.userFullName)}
                                                                />
                                                            )
                                                        }

                                                        {
                                                            ((this.state.showAddButton) && (this.user.isDeleted)) &&
                                                            (
                                                                <Button small
                                                                        minWidth={90}
                                                                        label={Localizer.adminButtonRestore}
                                                                        icon={{name: "trash-restore", size: IconSize.Large}}
                                                                        type={ButtonType.Primary}
                                                                        onClick={() => this.restoreUserAsync()}
                                                                        confirm={Utility.format(Localizer.adminConfirmationButtonRestoreUser, this.userFullName)}
                                                                />
                                                            )
                                                        }

                                                        {
                                                            ((this.state.showAddButton) && (!this.user.isDeleted) && (this.user.isLocked)) &&
                                                            (
                                                                <Button small
                                                                        minWidth={90}
                                                                        label={Localizer.adminButtonUnlock}
                                                                        icon={{name: "unlock", size: IconSize.Large}}
                                                                        type={ButtonType.Success}
                                                                        onClick={() => this.unlockUserAsync()}
                                                                        confirm={Utility.format(Localizer.adminConfirmationButtonUnlockUser, this.userFullName)}
                                                                />
                                                            )
                                                        }

                                                        {
                                                            (((this.isCaptain) && (this.isEntrepreneur)) || (this.isPassenger)) &&
                                                            (
                                                                <Button small
                                                                        minWidth={90}
                                                                        label={Localizer.adminButtonResetStripeKeys}
                                                                        icon={{name: "fal fa-wrench", size: IconSize.Large}}
                                                                        type={ButtonType.Primary}
                                                                        disabled={this.user.isDeleted}
                                                                        confirm={Utility.format(Localizer.adminConfirmationButtonResetStripeKeys)}
                                                                        onClick={() => this.resetStripeKeysAsync()}
                                                                />
                                                            )
                                                        }

                                                        {
                                                            ((ch.isDevelopment) && (!User.isNew(this.user))) &&
                                                            (
                                                                <Button small
                                                                        id="setDefaultPassword"
                                                                        label={Localizer.adminButtonSetDefaultPassword}
                                                                        disabled={this.user.isDeleted}
                                                                        onClick={() => this.setDefaultPasswordAsync()}
                                                                />
                                                            )
                                                        }

                                                        <Button submit
                                                                type={ButtonType.Orange}
                                                                icon={{name: "far save"}}
                                                                label={Localizer.formSave}
                                                        />

                                                    </ButtonContainer>

                                                </Form>

                                            </Tab>

                                            {
                                                (this.state.showAddButton) &&
                                                (
                                                    <Tab id="invitations" title={Localizer.adminTabInvitations}>

                                                        <div className={styles.passwordInfo}>
                                                            {
                                                                (!this.user.hasPassword) &&
                                                                (
                                                                    <Alert model={this.alert}/>
                                                                )
                                                            }
                                                        </div>

                                                        <div className={styles.invitations}>

                                                            <Grid columns={this._invitationColumns}
                                                                  data={this.user.invitations}
                                                                  noDataText={Localizer.adminGridNoInvitationsText}
                                                            />

                                                        </div>

                                                        <ButtonContainer>

                                                            <Button small
                                                                    minWidth={90}
                                                                    label={Localizer.adminButtonResendInvitation}
                                                                    icon={{name: "envelope", size: IconSize.Large}}
                                                                    disabled={this.user.isDeleted || this.user.isLocked || this.user.hasPassword}
                                                                    type={ButtonType.Primary}
                                                                    onClick={() => this.resendInvitationAsync()}
                                                                    confirm={Utility.format(Localizer.adminConfirmationButtonResendInvitation, this.userFullName)}
                                                            />

                                                            <Button small
                                                                    minWidth={90}
                                                                    label={Localizer.adminButtonResetPassword}
                                                                    icon={{name: "repeat", size: IconSize.Large}}
                                                                    disabled={this.user.isDeleted || this.user.isLocked || !this.user.hasPassword}
                                                                    type={ButtonType.Success}
                                                                    onClick={() => this.resetPasswordAsync()}
                                                                    confirm={Utility.format(Localizer.adminConfirmationButtonResetPassword, this.userFullName)}
                                                            />

                                                        </ButtonContainer>

                                                    </Tab>
                                                )
                                            }

                                            {
                                                (this.isCaptain) &&
                                                (
                                                    <Tab id="captainBoats" title={Localizer.userManagementPageTabBouts}>
                                                        <BoatsPanel ref={this._boatsPanelRef} captain={this.user}/>
                                                    </Tab>
                                                )
                                            }

                                            <Tab id="avatar" title={Localizer.userManagementPageTabAvatar}>

                                                <ImageInput className="flex-1"
                                                            maxImageRequestSizeInBytes={AppConstants.maxImageRequestSizeInBytes}
                                                            pictures={this.pictures}
                                                            onChange={(sender: ImageInput, pictures: FileModel[]) => this.setAvatarAsync(pictures)}
                                                />

                                            </Tab>

                                            {
                                                ((this.isPassenger) || ((this.isCaptain) && (this.isEntrepreneur))) &&
                                                (
                                                    <Tab id="paymentMethod" title={Localizer.userManagementPageTabKycStatus}>

                                                        <Form>

                                                            <TwoColumns>

                                                                {
                                                                    ((this.isCaptain) && (this.isEntrepreneur)) &&
                                                                    (
                                                                        <OneColumn>

                                                                            <OneColumn>

                                                                                <TextInput readonly
                                                                                           id="stripeAccountId"
                                                                                           label={Localizer.adminButtonStripeAccountId}
                                                                                           value={this.user.stripeAccountId}
                                                                                />

                                                                            </OneColumn>
                                                                             
                                                                                <Button right={false}
                                                                                        icon={{name: "repeat", size: IconSize.Large}}
                                                                                        type={ButtonType.Orange}
                                                                                        disabled={!this.user.stripeAccountId}
                                                                                        label={Localizer.adminButtonUpdateKycStatus}
                                                                                        onClick={() => this.updateKycStatusAsync()}
                                                                                />
                                                                            
                                                                            {
                                                                                (this.kycStatus) &&
                                                                                (
                                                                                    <OneColumn>

                                                                                        <TextAreaInput readonly noRemaining
                                                                                                       className={styles.kycStatus}
                                                                                                       label={Localizer.userManagementPageTabKycStatusDataLabel}
                                                                                                       rows={20}
                                                                                                       value={StripeKycStatus.toJsonResult(this.kycStatus)}/>

                                                                                    </OneColumn>

                                                                                )
                                                                            }

                                                                        </OneColumn>
                                                                    )
                                                                }

                                                                {
                                                                    (this.isPassenger) &&
                                                                    (
                                                                        <OneColumn>

                                                                            <TextInput readonly
                                                                                       id="stripeCustomerId"
                                                                                       label={Localizer.userManagementPageCustomerId}
                                                                                       value={this.user.stripeCustomerId}
                                                                            />

                                                                            <Button right={false}
                                                                                    icon={{name: "fas fa-file-upload"}}
                                                                                    type={ButtonType.Orange}
                                                                                    disabled={!this.user.stripeCustomerId}
                                                                                    label={Localizer.userManagementPageCheckPaymentMethodStatusButton}
                                                                                    onClick={() => this.checkStripePaymentMethodStatusAsync()}
                                                                            />

                                                                            {
                                                                                this.state.stripeCreditCardStatuses.map(status =>
                                                                                    <div className={this.css(styles.creditCardStatuses, !status.isPaymentMethodValid && styles.notValid)}>

                                                                                        <div>

                                                                                            <span>{Localizer.userManagementPageLast4}: </span>

                                                                                            <span>{status.last4}</span>

                                                                                        </div>

                                                                                        <div>

                                                                                            <span>{Localizer.userManagementPageFingerprint}: </span>

                                                                                            <span>{status.fingerPrint}</span>

                                                                                        </div>

                                                                                        {
                                                                                            (!status.isPaymentMethodValid) &&
                                                                                            (
                                                                                                <React.Fragment>

                                                                                                    <div>

                                                                                                        <span>{Localizer.userManagementPageApplicationPaymentMethod}: </span>

                                                                                                        <span>{status.applicationPaymentMethodId}</span>

                                                                                                    </div>

                                                                                                    <div>

                                                                                                        <span>{Localizer.userManagementPageStripePaymentMethod}: </span>

                                                                                                        <span>{status.stripePaymentMethodId}</span>

                                                                                                    </div>

                                                                                                    <Button right={false}
                                                                                                            type={ButtonType.Default}
                                                                                                            label={Localizer.userManagementPageUpdatePaymentMethod}
                                                                                                            confirm={Localizer.userManagementPageUpdatePaymentMethodConfirm}
                                                                                                            onClick={() => this.updatePaymentMethodAsync(status.fingerPrint!)}
                                                                                                    />

                                                                                                </React.Fragment>

                                                                                            )
                                                                                        }

                                                                                    </div>
                                                                                )
                                                                            }

                                                                        </OneColumn>
                                                                    )
                                                                }

                                                            </TwoColumns>

                                                        </Form>

                                                    </Tab>
                                                )
                                            }

                                        </TabContainer>
                                    )
                                }

                            </div>

                        </div>

                    </div>
                </PageRow>

            </PageContainer>
        );
    }
}