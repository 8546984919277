import React from "react";
import {BaseComponent, IBaseComponentProps} from "@reapptor-apps/reapptor-react-common";
import ImageProvider from "@/providers/ImageProvider";
import FileModel from "@reapptor-apps/reapptor-toolkit/src/models/FileModel";
import BoatImage from "@/models/server/BoatImage";
import ServiceProviderController from "@/pages/ServiceProviderController";
import Localizer from "@/localization/Localizer";

import styles from "./BookingAvatar.module.scss";

import thumbnail from "@/img/thumbnail.png";
import boatTypeImage from "./images/BoatType.svg";
import boatTypePjtaImage from "./images/BoatType_Pjta.svg";

interface IBookingAvatarProps extends IBaseComponentProps {
    /**
     * Specify the default image in case there is no boat and no user image.
     * If not specified and there is no boat and no user image - component will show user thumbnail.
     */
    type?: BookingAvatarType;
    avatar?: FileModel | string | null;
    boatImage?: BoatImage | null;
}

interface IBookingAvatarState {
}

export enum BookingAvatarType {
    Boat,

    User,
    
    BoatOnly,
}

export default class BookingAvatar extends BaseComponent<IBookingAvatarProps, IBookingAvatarState> {

    state: IBookingAvatarState = {
    };

    public get singleImageSrc(): string {
        return (this.hasBoatImage)
            ? this.boatImageSrc
            : (this.hasUserAvatar)
                ? this.userAvatarSrc
                : (this.type == BookingAvatarType.Boat)
                    ? this.boatImageSrc
                    : this.userAvatarSrc;
    }

    public get type(): BookingAvatarType | null {
        return this.props.type ?? null;
    }
    
    public get typeImage(): string {
        return (ServiceProviderController.isPjta)
            ? boatTypePjtaImage
            : boatTypeImage;
    }

    public get boatImageSrc(): string {
        return ImageProvider.getRequiredImageSrc(this.props.boatImage?.fileId, this.typeImage);
    }

    public get userAvatarSrc(): string {
        return ImageProvider.getRequiredImageSrc(this.props.avatar, thumbnail);
    }

    public get hasBoatImage(): boolean {
        return (this.props.boatImage != null);
    }

    public get hasUserAvatar(): boolean {
        return (this.props.avatar != null);
    }

    public get showCombinedAvatar(): boolean {
        return ((this.hasBoatImage) && (this.hasUserAvatar) && (!this.boatOnly));
    }

    public get showingDefaultBoatImage(): boolean {
        return ((this.type == BookingAvatarType.Boat) && (!this.hasBoatImage));
    }
    
    public get boatOnly(): boolean {
        return (this.props.type == BookingAvatarType.BoatOnly);
    }

    public render(): React.ReactNode {
        return (
            <div className={this.css(this.props.className, styles.avatar)}>

                {
                    (this.showCombinedAvatar)
                        ?
                        (
                            <div className={styles.combined}>

                                <img className={styles.mainImage}
                                     src={this.boatImageSrc}
                                     alt={Localizer.boatItemPrimaryImageAlt}
                                />

                                <img className={styles.secondaryImage}
                                     src={this.userAvatarSrc}
                                     alt={Localizer.genericAvatar}
                                />

                            </div>
                        )
                        :
                        (
                            <img className={this.cssIf(styles.single, (this.singleImageSrc != this.typeImage), styles.custom)}
                                 src={this.singleImageSrc}
                                 alt={Localizer.genericAvatar}
                            />
                        )
                }

            </div>
        );
    }
}