import React from "react";
import {AlertModel, ApiProvider, ch, IBasePage, PageRoute, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {
    Button,
    ButtonType,
    IWizardStep,
    IWizardSteps,
    LanguageDropdown,
    PageContainer,
    WizardContainer
} from "@reapptor-apps/reapptor-react-components";
import AnonymousWizardPage from "@/models/base/AnonymousWizardPage";
import Comparator from "@/helpers/Comparator";
import PageDefinitions from "@/providers/PageDefinitions";
import {TBasePageParameters} from "@reapptor-apps/reapptor-react-common/src/base/BasePage";
import CruisePackageWizardContext from "@/pages/Mobile/CruisePackageWizard/CruisePackageWizardContext";
import {IWizardNextStep} from "@/models/IWizardNextStep";
import CruisePackageWizardController from "@/pages/Mobile/CruisePackageWizard/CruisePackageWizardController";
import Localizer from "@/localization/Localizer";

import boutStyles from "../../../bout.module.scss";
import styles from "./CruisePackageWizard.module.scss";

export default abstract class CruisePackageWizard<TProps extends TBasePageParameters = {}, TState = {}>
    extends AnonymousWizardPage<TProps, TState> {
    
    private readonly WizardContainerRef: React.RefObject<WizardContainer> = React.createRef();
    private _canNext: boolean | null = null;
    
    private async toAdminAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.adminRoute);
    }

    protected findWidget<TWidget>(id: string): TWidget | null {
        return (this.wizardContainer != null)
            ? this.wizardContainer!.findComponent(id) as TWidget | null
            : null;
    }

    protected get cruiseStyle(): boolean {
        return false;
    }
    
    protected get currentStep(): IWizardStep | null {
        const index: number = this.currentIndex;
        return (index != -1)
            ? this.steps[index]
            : null;
    }

    protected get steps(): IWizardStep[] {
        const steps: IWizardSteps | null = CruisePackageWizardController.getSteps();
        return steps ? steps!.steps : [];
    }

    protected get currentIndex(): number {
        const page: IBasePage = this.getPage();
        const route: PageRoute = page.route;
        return this.steps.findIndex(step => Comparator.isEqual(step.route, route));
    }
    
    protected get containerClassName(): string {
        return "";
    }
    
    protected get contentClassName(): string {
        return "";
    }
    
    protected get buttonsClassName(): string {
        return "";
    }

    protected save(): void {
        CruisePackageWizardController.save();
    }

    protected async validateAsync(): Promise<boolean> {
        const canNext: boolean = this.canNext;
        if (canNext !== this._canNext) {
            this._canNext = canNext;
            await this.reRenderAsync();
        }
        return canNext;
    }
    
    private get wizardContainer(): WizardContainer | null {
        return this.WizardContainerRef.current;
    }

    protected get controller(): string | null {
        return ((this.wizardContainer != null) != null) ? this.wizardContainer!.controller : null;
    }
    
    protected get wizard(): CruisePackageWizardContext {
        return CruisePackageWizardController.wizard;
    }

    protected get canPrev(): boolean {
        return true;
    }
    
    protected get canNext(): boolean {
        return true;
    }
    
    public async prevAsync(): Promise<void> {
        if (this.canPrev) {
            const prevRoute: PageRoute = await CruisePackageWizardController.getPrevStepAsync(this.route);

            await this.redirectPrevAsync(prevRoute);
        }
    }
    
    private async invokeRedirectAsync(nextRoute: PageRoute): Promise<void> {
        const alert: AlertModel | null = this.alert;

        await this.redirectNextAsync(nextRoute);

        if (alert != null) {
            await this.alertAsync(alert);
        }
    }
    
    private async completeAsync(nextRoute: PageRoute): Promise<void> {
        await CruisePackageWizardController.completeActionAsync();

        await this.invokeRedirectAsync(nextRoute);
    }

    public async nextAsync(): Promise<void> {

        const canNext: boolean = await this.validateAsync();

        if (canNext) {
            const nextStep: IWizardNextStep = await CruisePackageWizardController.getNextStepAsync(this.route);

            const nextRoute: PageRoute = nextStep.nextRoute;

            if (nextStep.lastStep) {
                await ApiProvider.invokeWithForcedSpinnerAsync(() => this.completeAsync(nextRoute));
            } else {
                await this.invokeRedirectAsync(nextRoute);
            }
        }
    }

    public get title(): string | null {
        const index: number = this.currentIndex;
        return (index != -1)
            ? this.steps[index].title
            : null;
    }
    
    public get hasTopNav(): boolean {
        return !this.cruiseStyle;
    }

    public get hasLeftNav(): boolean {
        return !this.cruiseStyle;
    }

    public get hasLanguageSelector(): boolean {
        return this.cruiseStyle;
    }
    
    public get hasFooter(): boolean {
        return !this.cruiseStyle;
    }
    
    public get wide(): boolean {
        return false;
    }

    public get hasGenericBackButton(): boolean {
        return true;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        if (this.wizard.completed) {
            await CruisePackageWizardController.startAsync();
        }
    }

    public abstract renderContent(): React.ReactNode;
    
    public renderButtonsContainer(): React.ReactNode {
        return (
            <div className={this.css(styles.buttons, this.buttonsClassName)}>

                {this.renderButtons()}

                {((this.cruiseStyle) && (this.hasGenericBackButton)) && this.renderGenericBackButton()}

            </div>
        );
    }

    public renderButtons(): React.ReactNode {
        return (
            <React.Fragment/>
        );
    }

    public renderGenericBackButton(): React.ReactNode {
        return (
            <Button block
                    type={ButtonType.Primary}
                    label={Localizer.genericBack}
                    onClick={() => this.prevAsync()}
            />
        );
    }
    
    public renderLanguage(): React.ReactNode {
        return (
            <LanguageDropdown className={styles.language}
                              languages={Localizer.supportedLanguages} 
                              currentLanguage={Localizer.language} 
                              onChange={(_, language) => ch.setLanguageAsync(language)}
            />
        )
    }
    
    public renderLogo(): React.ReactNode {
        return (
            <div className={styles.logo} onClick={() => this.toAdminAsync()} />
        );
    }
    
    public renderHeader(title: string | null): React.ReactNode {
        return (
            <React.Fragment>
                
                {
                    (title) &&
                    (
                        <h1 className={styles.title}>{title}</h1>
                    )
                }

                <div className={styles.wave} />
                
            </React.Fragment>
        )
    }
    
    public renderContentContainer(title: string | null, renderContent: () => React.ReactNode): React.ReactNode {
        return (
            <React.Fragment>

                <div className={this.css(styles.content, this.contentClassName)}>

                    <div>

                        { this.renderHeader(title) }

                        { renderContent() }

                    </div>

                </div>

            </React.Fragment>
        )
    }
    
    public renderPageContent(): React.ReactNode {
        return (
            <React.Fragment>

                { (this.cruiseStyle) && this.renderLanguage() }
                
                { (this.cruiseStyle) && this.renderLogo() }

                { this.renderContentContainer(this.title, () => this.renderContent()) }

                { this.renderButtonsContainer() }

            </React.Fragment>
        )
    }

    public render(): React.ReactNode {
        const wideStyle: any = this.wide && styles.wide;
        return (
            <PageContainer fullHeight
                           className={this.css(boutStyles.pageContainer, styles.cruisePackageWizard, wideStyle, this.containerClassName)}
                           alertClassName={boutStyles.alert}
            >
                
                { this.renderPageContent() }
                
            </PageContainer>
        );
    }
}