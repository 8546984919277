// autogenerated
import {LocalizationString} from "@reapptor-apps/reapptor-react-common";
import {CruisePackageOwnerType, CruisePackagePriceInterval} from "@/models/Enums";
import ServiceProvider from "@/models/server/ServiceProvider";
import User from "@/models/server/User";
import Area from "@/models/server/bout/Area";
import Boat from "@/models/server/bout/Boat";
import CruisePackageImage from "@/models/server/cruise/CruisePackageImage";
import CruisePackagePoint from "@/models/server/cruise/CruisePackagePoint";
import CruisePackagePrice from "@/models/server/cruise/CruisePackagePrice";

export default class CruisePackage {

    public id: string = "";

    public serviceProviderId: string | null = null;

    public serviceProvider: ServiceProvider | null = null;

    public ownerId: string | null = null;

    public owner: User | null = null;

    public areaId: string = "";

    public area: Area | null = null;

    public boatId: string = "";

    public boat: Boat | null = null;

    public seasonStartsAt: Date | null = null;

    public seasonEndsAt: Date | null = null;

    public hasSeason: boolean = false;

    public isInSeason: boolean = false;

    public isCrossingSeason: boolean = false;

    public ownerType: CruisePackageOwnerType = CruisePackageOwnerType.ServiceProvider;

    public name: LocalizationString | null = null;

    public details: LocalizationString | null = null;

    public maxCapacity: number = 0;

    public minDurationInHours: number = 0;

    public active: boolean = false;

    public customLocation: boolean = false;

    public customLocationPrice: number | null = null;

    public path: string | null = null;

    public deleted: boolean = false;

    public deletedAt: Date | null = null;

    public deletedById: string | null = null;

    public food: boolean = false;

    public drinks: boolean = false;

    public images: CruisePackageImage[] | null = null;

    public points: CruisePackagePoint[] | null = null;

    public prices: CruisePackagePrice[] | null = null;

    public readonly isCruisePackage: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof CruisePackage) || ((from as any).isCruisePackage === true));
    }

    public static as(from?: object | null): CruisePackage | null {
        return (CruisePackage.is(from)) ? from as CruisePackage : null;
    }

    // #region Placeholder

    public static isGenericValid(model: CruisePackage): boolean {
        return (
            ((model.ownerType != CruisePackageOwnerType.ServiceProvider) || (!!model.serviceProviderId)) &&
            (!!model.areaId) &&
            (!!model.boatId) &&
            ((model.name != null) && (LocalizationString.valid(model.name, true))) &&
            ((model.details != null) && (LocalizationString.valid(model.details, true)))
        )
    }

    public static isOptionsValid(model: CruisePackage): boolean {
        return (model.maxCapacity > 0);
    }

    public static isPointsValid(model: CruisePackage): boolean {
        return (
            (model.points != null) &&
            (model.points.length > 0) &&
            (model.points.all(item => !!item.location?.formattedAddress))
        );
    }

    public static isImagesValid(model: CruisePackage): boolean {
        return (
            (model.images != null) &&
            (model.images.length > 0) &&
            (model.images.all(item => !!item.file))
        );
    }

    public static isPriceValid(model: CruisePackage): boolean {
        return (
            (model.prices != null) &&
            (model.prices.length > 0) &&
            (model.prices.all(item => (item.price > 0)))
        );
    }

    public static isValid(model: CruisePackage): boolean {
        return (
            (CruisePackage.isGenericValid(model)) &&
            (CruisePackage.isOptionsValid(model)) &&
            (CruisePackage.isPointsValid(model)) &&
            (CruisePackage.isImagesValid(model)) &&
            (CruisePackage.isPriceValid(model))
        );
    }

    public static hasData(cruisePackage: CruisePackage | null): boolean {
        return (
            (cruisePackage != null) &&
            (cruisePackage.images != null) &&
            (cruisePackage.prices != null) &&
            (cruisePackage.points != null) &&
            (cruisePackage.area != null) &&
            (cruisePackage.area.country != null) &&
            (cruisePackage.boat != null) &&
            ((cruisePackage.ownerType != CruisePackageOwnerType.ServiceProvider) || (cruisePackage.serviceProvider != null)) &&
            ((cruisePackage.ownerType != CruisePackageOwnerType.Captain) || (cruisePackage.owner != null))
        );
    }

    public static isSplitPriceModel(model: CruisePackage): boolean {
        return ((model.prices != null) && (model.prices.length > 0) && (model.prices.any(item => item.interval != CruisePackagePriceInterval.MondaySunday)))
    }

    public static nextSeasonStartsAt(cruisePackage: CruisePackage | null): Date | null {
        return ((cruisePackage) && (cruisePackage.seasonStartsAt))
            ? cruisePackage.seasonStartsAt.inFuture()
                ? cruisePackage.seasonStartsAt
                : cruisePackage.seasonStartsAt.addYears(1)
            : null;
    }

    // #endregion
}