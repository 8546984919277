import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {Button, Icon, PageContainer, PageHeader} from "@reapptor-apps/reapptor-react-components";
import {TimePeriod} from "@/models/Enums";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import RevenueWidget from "@/pages/Mobile/MyEarnings/RevenueWidget/RevenueWidget";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import GetMyRevenueRequest from "@/models/server/requests/GetMyRevenueRequest";
import GetMyRevenueResponse from "@/models/server/responses/GetMyRevenueResponse";
import {RevenueChartData} from "@/models/server/RevenueChartData";
import RevenueItem from "@/models/server/bout/RevenueItem";
import AllMyRevenueResponse from "@/models/server/responses/AllMyRevenueResponse";
import User from "@/models/server/User";

import Localizer from "@/localization/Localizer";

import boutStyles from "../../../bout.module.scss";
import styles from "./MyEarnings.module.scss";

interface IMyEarningsProps {
}

interface IMyEarningsState {
    thisDayRevenue: number;
    thisWeekRevenue: number;
    thisMonthRevenue: number;
    thisYearRevenue: number;
    confirmedRidesRevenue: number;
    totalRevenue: number;
    month: Date;
    week: number;
    year: number;
    timePeriod: TimePeriod;
    chartData: RevenueChartData[] | null,
    userCurrency: string | null
}

export default class MyEarnings extends AuthorizedPage<IMyEarningsProps, IMyEarningsState> {

    state: IMyEarningsState = {
        thisDayRevenue: 0,
        thisWeekRevenue: 0,
        thisMonthRevenue: 0,
        thisYearRevenue: 0,
        confirmedRidesRevenue: 0,
        totalRevenue: 0,
        month: this.getFirstDayOfMonth(Utility.today()),
        week: this.currentWeek,
        year: this.currentYear,
        timePeriod: TimePeriod.All,
        chartData: null,
        userCurrency: null
    };

    public getFirstDayOfMonth(date: Date): Date {
        date = new Date(date);
        date.setDate(1);
        return date;
    }

    public getFirstDayOfYear(date: Date): Date {
        date = new Date(date);
        date.setMonth(0);
        date.setDate(1);
        return date;
    }

    public getFirstMondayOfYear(date: Date): Date {
        const firstDayOfYear: Date = this.getFirstDayOfYear(date);

        let firstMondayOfYear: Date = firstDayOfYear;
        while(firstMondayOfYear.getDay() != 1) {
            firstMondayOfYear = firstMondayOfYear.addDays(1);
        }

        return firstDayOfYear;
    }

    public getFirstDayOfWeek(date: Date, week: number): Date {
        const firstMondayOfYear: Date = this.getFirstMondayOfYear(date);
        return firstMondayOfYear.addDays(7 * (week - 1));
    }

    public getTitle(): string {
        return Localizer.mobileMyEarningsPageTitle;
    }

    public get currentWeek(): number {
        const today: Date = Utility.today();
        return Utility.getWeekNumber(today)
    }

    public get month(): Date {
        return this.state.month;
    }
    
    public get monthLabel(): string {
        return this.month!.format("MMMM");
    }
    
    public get timePeriod(): TimePeriod {
        return this.state.timePeriod;
    }

    public get week(): number {
        return this.state.week;
    }
    
    public get weekLabel(): string {
        return Localizer.mobileMyEarningsPageGetWeekLabel.format(this.week);
    }

    public get year(): number {
        return this.state.year;
    }

    public get currentYear(): number {
        return new Date().getFullYear();
    }

    public get intervalValueText(): number | string | null {
        switch (this.timePeriod) {
            case TimePeriod.Week:
                return this.weekLabel
            case TimePeriod.Month:
                return this.monthLabel;
            case TimePeriod.Year:
                return this.year;
            default:
                return null;
        }
    }
    
    private getRevenueRequest(timePeriod: TimePeriod): GetMyRevenueRequest {
        const request = new GetMyRevenueRequest();
        request.timePeriod = timePeriod;

        switch (timePeriod) {
            case TimePeriod.Week:
                request.date = this.getFirstDayOfWeek(Utility.today(), this.week);
                break;
                
            case TimePeriod.Month:
                request.date = this.month;
                break;
                
            case TimePeriod.Year:
                const year = new Date(this.year, 1, 1);
                request.date = year;
                break;
        }
        
        return request;
    }
    
    private getRevenueChartData(response: GetMyRevenueResponse): RevenueChartData[] {
        const chartData: RevenueChartData[] = [];

        for (let i: number = 0; i < response.items.length; i++) {
            const item: RevenueItem = response.items[i];
            let name = "";
            switch (response.timePeriod) {
                
                case TimePeriod.Week:
                    name = Utility.getShortDayOfWeek(item.from);
                    break;
                    
                case TimePeriod.Month:
                    name = item.from.getDate().toString();
                    break;
                    
                case TimePeriod.Year:
                    name = Utility.getShortMonth(item.from);
                    break;
            }
            
            const data: RevenueChartData = {
                name: name,
                data: item.value
            };
            chartData.push(data);
        }

        return chartData;
    }

    public async fetchData(): Promise<void> {
        
        if (this.timePeriod == TimePeriod.All) {
            const response: AllMyRevenueResponse = await this.getAsync("/api/mobileApp/getAllMyRevenue");
            
            await this.setState(
                {
                    confirmedRidesRevenue: response.confirmedRides,
                    thisYearRevenue: response.thisYear,
                    thisMonthRevenue: response.thisMonth,
                    thisWeekRevenue: response.thisWeek,
                    thisDayRevenue: response.thisDay
                });

            return;
        }
        
        const request: GetMyRevenueRequest = this.getRevenueRequest(this.timePeriod);

        const response: GetMyRevenueResponse = await this.postAsync("/api/mobileApp/getMyRevenue", request);

        const chartData: RevenueChartData[] = this.getRevenueChartData(response);

        await this.setState({chartData, totalRevenue: response.total});
    };

    public async setPeriodAsync(timePeriod: TimePeriod): Promise<void> {
        await this.setState({timePeriod});
        await this.fetchData();
    }

    public async prevValueAsync(): Promise<void> {
        switch (this.timePeriod) {
            case TimePeriod.Week:
                const week: number = this.week - 1;
                await this.setState({ week });
                await this.fetchData();
                break;
                
            case TimePeriod.Month:
                const month: Date = this.month.addMonths(-1);
                await this.setState({month});
                await this.fetchData();
                break;
                
            case TimePeriod.Year:
                const year: number = this.year - 1;
                await this.setState({ year });
                await this.fetchData();
                break;
        }
    }

    public async nextValueAsync(): Promise<void> {
        switch (this.timePeriod) {
            case TimePeriod.Week:
                const week: number = this.week + 1;
                await this.setState({ week });
                await this.fetchData();
                break;
                
            case TimePeriod.Month:
                const month: Date = this.month.addMonths(1);
                await this.setState({ month });
                await this.fetchData();
                break;
                
            case TimePeriod.Year:
                const year: number = this.year + 1;
                await this.setState({ year });
                await this.fetchData();
                break;
        }
    }

    public get canPrev(): boolean {
        switch (this.timePeriod) {
            case TimePeriod.Week:
                return (this.week > 1);
            case TimePeriod.Month:
                return this.month.getMonth() > 0;
            case TimePeriod.Year:
                return (this.year > 2022)
        }

        return false;
    }

    public get canNext(): boolean {
        switch (this.timePeriod) {
            case TimePeriod.Week:
                return ((this.week < this.currentWeek) && (this.week < 52));
            case TimePeriod.Month:
                return this.month.getMonth() < Utility.today().getMonth();
            case TimePeriod.Year:
                return (this.year < this.currentYear);
        }

        return false;
    }

    public get totalForPeriodLabel(): string | null {
        switch (this.timePeriod) {
            case TimePeriod.Week:
                return this.weekLabel;
            case TimePeriod.Month:
                return this.monthLabel;
            case TimePeriod.Year:
                return this.year.toString();
        }

        return null;
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        const user: User = await this.getUser();
        this.state.userCurrency = user.country!.moneySymbol;
        await this.fetchData();
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.myEarnings, this.mobile && styles.mobile)}
                           alertClassName={boutStyles.alert}
            >
                <PageHeader className={styles.pageHeader} title={this.getTitle} subtitle={Localizer.mobileMyEarningsPageSubtitle}/>

                <div className={styles.timePeriodSelector}>

                    <Button className={(this.state.timePeriod == TimePeriod.All) ? styles.active : ""} 
                            label={Localizer.mobileMyEarningsPageButtonAllLabel} 
                            onClick={() => this.setPeriodAsync(TimePeriod.All)}
                    />

                    <Button className={(this.state.timePeriod == TimePeriod.Week) ? styles.active : ""} 
                            label={Localizer.mobileMyEarningsPageButtonWeekLabel}
                            onClick={() => this.setPeriodAsync(TimePeriod.Week)}
                    />

                    <Button className={(this.state.timePeriod == TimePeriod.Month) ? styles.active : ""}
                            label={Localizer.mobileMyEarningsPageButtonMonthLabel}
                            onClick={() => this.setPeriodAsync(TimePeriod.Month)}
                    />

                    <Button className={(this.state.timePeriod == TimePeriod.Year) ? styles.active : ""} 
                            label={Localizer.mobileMyEarningsPageButtonYearLabel}
                            onClick={() => this.setPeriodAsync(TimePeriod.Year)}
                    />

                </div>

                <div className={styles.chartContainer}>
                    {
                        (this.state.timePeriod == TimePeriod.All)
                            ?
                            (
                                <React.Fragment>

                                    <RevenueWidget title={Localizer.mobileMyEarningsPageRevenueWidgetTodayTitle}
                                                   subtitle={"{0:C} {1}".format(this.state.thisDayRevenue, this.state.userCurrency)}
                                    />

                                    <RevenueWidget title={Localizer.mobileMyEarningsPageRevenueWidgetThisWeekTitle}
                                                   subtitle={"{0:C} {1}".format(this.state.thisWeekRevenue, this.state.userCurrency)}
                                    />

                                    <RevenueWidget title={Localizer.mobileMyEarningsPageRevenueWidgetThisMonthTitle}
                                                   subtitle={"{0:C} {1}".format(this.state.thisMonthRevenue, this.state.userCurrency)}
                                    />

                                    <RevenueWidget title={Localizer.mobileMyEarningsPageRevenueWidgetThisYearTitle}
                                                   subtitle={"{0:C} {1}".format(this.state.thisYearRevenue, this.state.userCurrency)}
                                    />

                                    <RevenueWidget unpaid
                                                   title={Localizer.mobileMyEarningsPageRevenueWidgetConfirmedRidesTitle}
                                                   subtitle={"{0:C} {1}".format(this.state.confirmedRidesRevenue, this.state.userCurrency)}
                                    />

                                </React.Fragment>
                            )
                            :
                            (
                                <React.Fragment>

                                    <div className={styles.periodSwitch}>

                                        <Icon name={"fal fa-arrow-circle-left"}
                                              disabled={!this.canPrev}
                                              onClick={() => this.prevValueAsync()}
                                        />

                                        <span>{this.intervalValueText}</span>

                                        <Icon name={"fal fa-arrow-circle-right"}
                                              disabled={!this.canNext}
                                              onClick={() => this.nextValueAsync()}
                                        />

                                    </div>

                                    {
                                        (this.state.chartData) &&
                                        (
                                            <div className={styles.chart}>
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <LineChart width={500} height={300}
                                                               data={this.state.chartData}
                                                               margin={{
                                                                   top: 5,
                                                                   right: 10,
                                                                   left: 10,
                                                                   bottom: 5,
                                                               }}
                                                    >
                                                        <CartesianGrid strokeDasharray="3 3"/>
                                                        <XAxis dataKey="name"/>
                                                        <YAxis/>
                                                        <Tooltip/>
                                                        <Legend/>
                                                        <Line type="monotone" dataKey="data" stroke="#82ca9d"/>
                                                        
                                                    </LineChart>
                                                </ResponsiveContainer>
                                            </div>
                                        )
                                    }

                                    <div className={styles.expander} />

                                    <RevenueWidget title={Localizer.mobileMyEarningsPageRevenueWidgetEarningsTitle.format(this.totalForPeriodLabel)}
                                                   subtitle={"{0:C} {1}".format(this.state.totalRevenue, this.state.userCurrency)}
                                    />

                                </React.Fragment>
                            )
                    }

                </div>

            </PageContainer>
        );
    }
}