// autogenerated
import {FileModel} from "@reapptor-apps/reapptor-toolkit";

export default class UpdateMyProfileRequest {

    public userId: string = "";

    public firstname: string | null = null;

    public lastName: string | null = null;

    public phoneNumber: string | null = null;

    public language: string | null = null;

    public avatar: FileModel | null = null;

    public readonly isUpdateMyProfileRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof UpdateMyProfileRequest) || ((from as any).isUpdateMyProfileRequest === true));
    }

    public static as(from?: object | null): UpdateMyProfileRequest | null {
        return (UpdateMyProfileRequest.is(from)) ? from as UpdateMyProfileRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}