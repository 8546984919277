// autogenerated

export default class ChangePasswordRequest {

    public username: string | null = null;

    public currentPassword: string | null = null;

    public newPassword: string | null = null;

    public readonly isChangePasswordRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ChangePasswordRequest) || ((from as any).isChangePasswordRequest === true));
    }

    public static as(from?: object | null): ChangePasswordRequest | null {
        return (ChangePasswordRequest.is(from)) ? from as ChangePasswordRequest : null;
    }

    // #region Placeholder

    constructor(newPassword: string, currentPassword: string) {
        this.newPassword = newPassword;
        this.currentPassword = currentPassword;
    }

    // #endregion
}