import React from "react";
import {
    BaseComponent,
    ch,
    IBaseComponentProps,
    IGlobalKeydown,
    LocalizationString
} from "@reapptor-apps/reapptor-react-common";
import CruisePackage from "@/models/server/cruise/CruisePackage";
import CruisePackageImage from "@/models/server/cruise/CruisePackageImage";
import ImageProvider from "@/providers/ImageProvider";
import {Button, ButtonType, Carousel, CarouselNavigation, CarouselPagination, Icon} from "@reapptor-apps/reapptor-react-components";
import CruisePackagePrice from "@/models/server/cruise/CruisePackagePrice";
import {CruisePackageOwnerType} from "@/models/Enums";
import Boat from "@/models/server/bout/Boat";
import PageDefinitions from "@/providers/PageDefinitions";
import Localizer from "@/localization/Localizer";

import styles from "./CruisePackageCard.module.scss";

import loadingImage from "@/img/gif/waving_boat.gif";

enum CruisePackageCardMode {
    Preview,
    
    Details,
    
    ImagesPreview
}
    
interface ICruisePackageCardProps extends IBaseComponentProps {
    cruisePackage: CruisePackage;
    details?: boolean;
    embedded?: boolean;
    hasOnlyInfo?: boolean;
    hasOrderButton?: boolean;
    onOrder?(sender: CruisePackageCard, cruisePackage: CruisePackage): Promise<void>;
    onRequestTrip?(sender: CruisePackageCard, cruisePackage: CruisePackage): Promise<void>;
    onSignIn?(sender: CruisePackageCard): Promise<void>;
    onSignUp?(sender: CruisePackageCard): Promise<void>;
    onClose?(sender: CruisePackageCard): Promise<void>;
}

interface ICruisePackageCardState {
    imageIndex: number;
    mode: CruisePackageCardMode;
}

export default class CruisePackageCard extends BaseComponent<ICruisePackageCardProps, ICruisePackageCardState> implements IGlobalKeydown {

    state: ICruisePackageCardState = {
        imageIndex: 0,
        mode: (this.props.details) ? CruisePackageCardMode.Details : CruisePackageCardMode.Preview,
    };

    private readonly _carouselRef: React.RefObject<Carousel> = React.createRef();
    private readonly _thumbnailCarouselRef: React.RefObject<Carousel> = React.createRef();

    private async onOrderAsync(): Promise<void> {
        if (this.props.onOrder) {
            await this.props.onOrder(this, this.cruisePackage);
        }
    }

    private async onRequestTripAsync(): Promise<void> {
        if (this.props.onRequestTrip) {
            await this.props.onRequestTrip(this, this.cruisePackage);
        }
    }

    private async onPreviewAsync(event: React.MouseEvent<HTMLAnchorElement>): Promise<void> {
        event.preventDefault();
        await this.setState({mode: CruisePackageCardMode.Details});
    }

    private async onCloseDetailsAsync(): Promise<void> {
        if (this.props.details) {
            if (this.props.onClose) {
                await this.props.onClose(this);
            }
        } else {
            await this.setState({mode: CruisePackageCardMode.Preview});
        }
    }

    private async onImageClickAsync(image: CruisePackageImage | null, thumbnail: boolean = false): Promise<void> {

        if (this.state.mode == CruisePackageCardMode.Details) {
            await this.setState({mode: CruisePackageCardMode.ImagesPreview});
        }

        if (this.state.mode == CruisePackageCardMode.ImagesPreview) {
            if ((image) && (this._carouselRef.current)) {

                const images: CruisePackageImage[] = this.cruisePackage.images ?? [];
                const slideImageIndex: number = this._carouselRef.current.currentSlideIndex;

                if (thumbnail) {
                    const imageIndex: number = images.indexOf(image);

                    if (slideImageIndex != imageIndex) {
                        await this._carouselRef.current.slideToAsync(imageIndex);
                    }
                } else {
                    await this._carouselRef.current.slideNextAsync();
                }
            }
        }
    }

    private async onImageWheelAsync(e: React.WheelEvent, image: CruisePackageImage | null, thumbnail: boolean = false): Promise<void> {
        const positive: boolean = ((e.deltaX > 0) || (e.deltaY > 0) || (e.deltaZ > 0))

        if ((this.state.mode == CruisePackageCardMode.ImagesPreview) && (image) && (this._carouselRef.current) && (!thumbnail)) {

            if (positive) {
                await this._carouselRef.current.slidePrevAsync();
            } else {
                await this._carouselRef.current.slideNextAsync();
            }

        }
    }

    private async onCloseImagesPreviewAsync(): Promise<void> {
        await this.setState({mode: CruisePackageCardMode.Details});
    }

    private async onSlideChangeAsync(index: number): Promise<void> {
        if (this._thumbnailCarouselRef.current) {
            await this._thumbnailCarouselRef.current.slideToAsync(index, 0);
        }
    }

    private async slideAsync(first: boolean = true): Promise<void> {
        if (this.isMounted) {
            if ((!first) && (this.cruisePackage)) {
                let imageIndex: number = this.state.imageIndex + 1;

                await this.setState({imageIndex});
            }

            setTimeout(() => this.slideAsync(false), 3000);
        }
    }

    public get cruisePackage(): CruisePackage {
        return this.props.cruisePackage;
    }

    private get cruisePackageActive(): boolean {
        return this.cruisePackage.active;
    }

    public get embedded(): boolean {
        return (this.props.embedded == true);
    }

    public get hasOnlyInfo(): boolean {
        return (this.props.hasOnlyInfo == true);
    }

    public get hasOrderButton(): boolean {
        return (this.props.hasOrderButton == true);
    }

    public get minPrice(): number {
        return this.cruisePackage?.prices?.firstOrDefault()?.price ?? 0;
    }

    public get moneySymbol(): string {
        return this.cruisePackage?.area?.country?.moneySymbol ?? "";
    }

    public get ownerTitle(): string {
        return (this.cruisePackage.ownerType == CruisePackageOwnerType.ServiceProvider)
            ? "{0}".format(this.cruisePackage.serviceProvider?.name)
            : "{0}".format(this.cruisePackage.owner);
    }

    public get capacityDetails(): string {
        if ((this.cruisePackage == null) || (this.cruisePackage.boat == null)) {
            return "-";
        }

        const maxCapacity: number = this.cruisePackage.maxCapacity;
        const boatSeats: number = this.cruisePackage.boat.seats;

        return ((boatSeats > 0) && (maxCapacity > boatSeats))
            ? Localizer.cruisePackageCardCapacityDetails.format(maxCapacity, boatSeats)
            : "{0}".format(maxCapacity);
    }

    public get priceDetails(): string {
        if ((this.cruisePackage == null) || (this.cruisePackage.prices?.length == 0)) {
            return "-";
        }
        const moneySymbol: string = this.moneySymbol;
        const prices: CruisePackagePrice[] = this.cruisePackage.prices ?? [];
        const singlePrice: boolean = (prices.length == 1);
        const minPrice: number = prices.first().price;
        const hourlyPrice: boolean = prices.all((item, index) => (index == 0) || (item.price - prices[index - 1].price == minPrice));
        const minDurationInHours: number = this.cruisePackage.minDurationInHours;

        return (singlePrice)
            ? Localizer.cruisePackageCardPriceDetails.format(minPrice, moneySymbol, minDurationInHours)
            : (hourlyPrice)
                ? Localizer.cruisePackageCardHourlyPriceDetails.format(hourlyPrice, moneySymbol, minDurationInHours)
                : Localizer.cruisePackageCardStartingPriceDetails.format(minPrice, moneySymbol, minDurationInHours);
    }

    public get image(): CruisePackageImage | null {
        const images: CruisePackageImage[] = this.cruisePackage.images ?? [];
        const length: number = images.length;
        if (this.state.imageIndex >= length) {
            this.state.imageIndex = 0;
        }
        return (length > 0)
            ? images[this.state.imageIndex]
            : null;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        if ((this.embedded) && (!this.cruisePackageActive)) {
            await ch.alertWarningAsync(Localizer.cruisePackageCardPackageNotAvailable);
        }

        await this.slideAsync();
    }

    public async onGlobalKeydown(event: React.KeyboardEvent): Promise<void> {
        const isEsc: boolean = (event.key == "Escape");

        if ((isEsc) && (this.state.mode == CruisePackageCardMode.ImagesPreview)) {
            await this.onCloseImagesPreviewAsync();
        }
    }

    private renderImage(image: CruisePackageImage | null, index: number = 0, thumbnail: boolean = false): React.ReactElement {
        return (
            <div key={index}
                 className={styles.image}
                 onClick={() => this.onImageClickAsync(image, thumbnail)}
                 onWheel={(e: React.WheelEvent) => this.onImageWheelAsync(e, image, thumbnail)}
            >
                <img alt={CruisePackageImage.alt(image)}
                    //loading="lazy"
                     src={ImageProvider.getRequiredImageSrc(image?.file ?? image?.fileId, loadingImage)}
                />
            </div>
        );
    }

    private renderCarousel(ref?: React.RefObject<Carousel>, slidesPerView: number = 1, thumbnail: boolean = false): React.ReactElement {

        const images: CruisePackageImage[] = this.cruisePackage.images ?? [];

        slidesPerView = (slidesPerView > images.length)
            ? images.length
            : slidesPerView;

        const navigation: CarouselNavigation = ((slidesPerView == 1) && (images.length > 1))
            ? CarouselNavigation.Inside
            : CarouselNavigation.None;

        const pagination: CarouselPagination = ((slidesPerView == 1) && (images.length > 1))
            ? CarouselPagination.BottomOutside
            : CarouselPagination.None;

        const spaceBetweenSlides: number = (slidesPerView == 1)
            ? 0
            : 10;

        return (
            <Carousel loop
                      ref={ref}
                      navigation={navigation}
                      pagination={pagination}
                      slidesPerView={slidesPerView}
                      spaceBetweenSlides={spaceBetweenSlides}
                      className={this.css(styles.carousel, thumbnail && styles.thumbnail)}
                      onSlideChange={(index) => this.onSlideChangeAsync(index)}
            >

                {
                    images.map((image, index) => this.renderImage(image, index, thumbnail))
                }

            </Carousel>
        )
    }

    private renderOption(title: string, icon: string, value: boolean | number): React.ReactNode {
        const text: string = (typeof value === "boolean")
            ? value
                ? Localizer.genericYes
                : Localizer.genericNo
            : value.toString();

        const noStyle = (value == false) && styles.no;

        return (
            <div title={title} className={this.css(noStyle)}>

                <Icon name={icon}/>

                <span>{text}</span>

            </div>
        )
    }

    private renderOptions(compact: boolean = false): React.ReactNode {
        const cruisePackage: CruisePackage = this.cruisePackage;
        const boat: Boat | null = cruisePackage.boat;

        return (
            <div className={this.css(styles.options, compact && styles.compact)}>

                {
                    (boat) &&
                    (
                        <>
                            {this.renderOption(Localizer.cruisePackageCardTitleWheelchairAccessibility, "fas fa-wheelchair", boat.disabledFriendly)}

                            {this.renderOption(Localizer.cruisePackageCardTitleToilet, "far fa-toilet", boat.toilet)}

                            {this.renderOption(Localizer.cruisePackageCardTitlePetFriendly, "fas fa-cat", boat.petFriendly)}

                            {this.renderOption(Localizer.cruisePackageCardTitleMaxCapacity, "far fa-loveseat", cruisePackage.maxCapacity)}
                        </>
                    )
                }

                {
                    (!compact) && (this.renderOption(Localizer.cruisePackageCardTitleBuffet, "far fa-burger-soda", cruisePackage.drinks || cruisePackage.food))
                }

                {
                    ((boat) && (!compact)) && (this.renderOption(Localizer.cruisePackageCardTitleElectric, "fal fa-bolt", boat.isElectricBoat))
                }

            </div>
        )
    }

    private renderRating(): React.ReactNode {
        const ratingIndex: number = (this.cruisePackage?.boat?.rating || 5) - 1;
        const items: number[] = [0, 1, 2, 3, 4];
        return (
            <div className={styles.rating}>

                <div>

                    {
                        items.map(index =>
                            <Icon key={index}
                                  name={(ratingIndex >= index) ? "fas fa-star" : "fal fa-star"}
                                  className={this.css((ratingIndex >= index) && styles.active)}
                            />
                        )
                    }

                </div>

                <span>{Localizer.cruisePackageCardRatingComment}</span>

            </div>
        );
    }

    private renderPreview(): React.ReactNode {
        const title: string = LocalizationString.value(this.cruisePackage.name);
        const path: string | null = (!!this.cruisePackage.path)
            ? new URL(this.cruisePackage.path, ch.applicationUrl).toString()
            : `#${title}`;

        return (
            <a className={styles.preview}
               href={path}
               title={title}
               hrefLang={Localizer.defaultLanguage}
               onClick={(event) => this.onPreviewAsync(event)}
            >
                <div className={styles.preview}>

                    <div className={styles.top}>

                        <div className={styles.images}>

                            {
                                this.renderImage(this.image)
                            }

                        </div>

                        <div className={styles.info}>

                            <div className={styles.description}>

                                <span className={styles.name}>{LocalizationString.value(this.cruisePackage.name)}</span>

                                <span className={styles.serviceProvider}>{this.ownerTitle}</span>

                                <span className={styles.minPrice}>{Localizer.cruisePackageCardMinPrice.format(this.minPrice, this.moneySymbol)}</span>

                                <span className={styles.minDuration}>{Localizer.cruisePackageCardMinDuration.format(this.cruisePackage.minDurationInHours)}</span>

                            </div>

                            {this.renderOptions(true)}

                        </div>

                    </div>

                </div>
            </a>
        )
    }

    private renderImagesPreview(): React.ReactNode {
        return (
            <div className={styles.imagesPreview}>

                <div className={styles.toolbar}>

                    <Icon name={"fal fa-times-circle"} onClick={() => this.onCloseImagesPreviewAsync()}/>

                </div>

                <div className={styles.images}>

                    {this.renderCarousel(this._carouselRef)}

                    {this.renderCarousel(this._thumbnailCarouselRef, 4, true)}

                </div>

            </div>
        )
    }

    private renderDetailsButtons(): React.ReactNode {
        return (
            <React.Fragment>
                
                {
                    (this.isAuthenticated || ((!this.isAuthenticated) && (this.hasOrderButton))) &&
                    (
                        <div className={this.css(styles.buttons, styles.darkButtonStyle)}>
        
                            <Button block
                                    type={ButtonType.Dark}
                                    disabled={!this.cruisePackageActive}
                                    className={styles.preview}
                                    label={Localizer.cruisePackageCardOrder}
                                    onClick={() => this.onOrderAsync()}
                            />
        
                        </div>
                    )
                }

                {
                    ((!this.isAuthenticated) && (!this.hasOrderButton) && (this.props.onRequestTrip)) &&
                    (
                        <div className={this.css(styles.buttons, styles.darkButtonStyle)}>
        
                            <Button block
                                    disabled={(!this.cruisePackageActive)}
                                    type={ButtonType.Dark}
                                    className={styles.preview}
                                    label={Localizer.mobileBookingInfoButtonRequestTripLabel}
                                    onClick={() => this.onRequestTripAsync()}
                            />
        
                        </div>
                    )
                }

                {
                    ((!this.isAuthenticated) && (!this.hasOrderButton)) &&
                    (
                        <>
                            <div className={styles.buttons}>
        
                                <Button block
                                        type={ButtonType.Blue}
                                        className={styles.preview}
                                        label={Localizer.genericLogin}
                                        route={PageDefinitions.loginRoute}
                                />
        
                            </div>
        
                            <div className={styles.buttons}>
        
                                <Button block
                                        type={ButtonType.Orange}
                                        className={styles.preview}
                                        label={Localizer.genericSignUp}
                                        route={PageDefinitions.signUpRoute}
                                />
        
                            </div>
                        </>
                    )
                }
                
            </React.Fragment>
        )
    }

    private renderDetails(): React.ReactNode {
        const cruisePackage: CruisePackage = this.cruisePackage;
        const boat: Boat | null = cruisePackage.boat;
        const hasButtons: boolean = (!this.hasOnlyInfo);

        return (
            <div className={styles.details}>

                {
                    (!this.embedded) &&
                    (
                        <div className={styles.toolbar}>

                            <Icon name={"fal fa-times-circle"} onClick={() => this.onCloseDetailsAsync()}/>

                        </div>
                    )
                }

                <div className={styles.images}>

                    {this.renderCarousel()}

                </div>

                <div className={styles.info}>

                    <span className={styles.name}>{LocalizationString.value(cruisePackage.name)}</span>

                    <span className={styles.serviceProvider}>{this.ownerTitle}</span>

                </div>

                {this.renderRating()}

                {this.renderOptions()}

                <span className={styles.description}>
                    {LocalizationString.value(cruisePackage.details)}
                </span>

                <div className={styles.maxCapacity}>

                    <span>{Localizer.cruisePackageCardPassengers}</span>
                    <span>{this.capacityDetails}</span>

                </div>

                {
                    (boat) &&
                    (
                        <div className={styles.cruiseSpeed}>

                            <span>{Localizer.cruisePackageCardCruiseSpeed}</span>
                            <span>{Localizer.cruisePackageCardCruiseSpeedDetails.format(boat.cruiseSpeed)}</span>

                        </div>
                    )
                }

                <div className={styles.price}>
                    
                    <span>
                        <b>{Localizer.cruisePackageCardPrice}</b> {this.priceDetails}
                        <br/><small>{Localizer.cruisePackageCardPriceComment}</small>
                    </span>

                </div>
                
                {hasButtons && this.renderDetailsButtons()}
            
            </div>
        )
    }

    public render(): React.ReactNode {

        const imagesPreviewStyles = (this.state.mode == CruisePackageCardMode.ImagesPreview) && styles.imagesPreview;
        const embeddedStyles = (this.embedded) && styles.embedded;

        return (
            <div id={this.id} className={this.css(styles.cruisePackageCard, this.props.className, imagesPreviewStyles, embeddedStyles)}>

                {
                    (this.state.mode == CruisePackageCardMode.Preview) && this.renderPreview()
                }

                {
                    (this.state.mode == CruisePackageCardMode.Details) && this.renderDetails()
                }

                {
                    (this.state.mode == CruisePackageCardMode.ImagesPreview) && this.renderImagesPreview()
                }

            </div>
        )
    }
}