import React from "react";
import {
    Icon,
    IconSize,
    PageContainer
} from "@reapptor-apps/reapptor-react-components";
import {
    ApiProvider,
    BackButtonVisibility,
    BasePageParameters,
    ch, IManualProps, LocalizationString,
    PageRoute,
    PageRouteProvider, ReactUtility, SwipeDirection
} from "@reapptor-apps/reapptor-react-common";
import AuthorizedPage from "../../../models/base/AuthorizedPage";
import PageDefinitions from "@/providers/PageDefinitions";
import User from "@/models/server/User";
import TripInfo from "@/models/server/bout/TripInfo";
import {ApplicationType} from "@/models/Enums";
import HdsModal from "@/pages/Mobile/Dashboard/HdsModal/HdsModal";
import PjtaModal from "@/pages/Mobile/Dashboard/PjtaModal/PjtaModal";
import ServiceProviderController from "@/pages/ServiceProviderController";
import CruisePackageWizardController from "@/pages/Mobile/CruisePackageWizard/CruisePackageWizardController";
import AppController from "@/pages/AppController";
import Localizer from "../../../localization/Localizer";

import boutStyles from "@/bout.module.scss";
import styles from "./Dashboard.module.scss";

import wavesImage from "./images/wafes.png";
import separatorImage from "./images/separator.png";
import rideImage from "./images/ride.png";
import cruiseImage from "./images/cruise.png";
import shuttleImage from "./images/BoatTypeWaterbusElectric.png";

export interface IDashboardParameters extends BasePageParameters {
}

interface IDashboardState {
    trips: TripInfo[];
    currentShowAll: boolean;
    latestShowAll: boolean;
    initialized: boolean;
}

export default class Dashboard extends AuthorizedPage<IDashboardParameters, IDashboardState> {

    state: IDashboardState = {
        trips: [],
        currentShowAll: false,
        latestShowAll: false,
        initialized: false,
    };
    
    private readonly _hdsModal: React.RefObject<HdsModal> = React.createRef();
    private readonly _pjtaModal: React.RefObject<PjtaModal> = React.createRef();

    private async openRideAppAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.mobileHomeRoute);
    }

    private async openShuttleAppAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.mobileShuttleRoute);
    }

    private async openCruisePackageWizardAsync(): Promise<void> {
        await CruisePackageWizardController.startAsync();
    }
    
    private async openTripAsync(item: TripInfo): Promise<void> {
        if ((item.applicationType == ApplicationType.Ride) && (item.bookingId)) {

            const route: PageRoute = PageDefinitions.bookingDetailsById(item.bookingId);

            await PageRouteProvider.redirectAsync(route);

            return;
        }

        if ((item.applicationType == ApplicationType.Cruise) && (item.cruiseUrl)) {
            const cruiseUrl: string = item.cruiseUrl;

            await ApiProvider.invokeWithForcedSpinnerAsync(async () => ch.postToken(cruiseUrl), true);

            return;
        }
    }
    
    private async toggleShowAllAsync(current: boolean): Promise<void> {
        const currentShowAll: boolean = (current) && (!this.state.currentShowAll);
        const latestShowAll: boolean = (!current) && (!this.state.latestShowAll);
        
        await this.setState({ currentShowAll, latestShowAll });
    }
    
    private getUserName(): string {
        const user: User = this.getUser();
        return (user.firstname)
            ? user.firstname
            : "{0}".format(user);
    }

    private get rideEnabled(): boolean {
        return (
            ((!this.serviceProviderSlug) || (this.serviceProviderSlug.applicationTypes.contains(ApplicationType.Ride)))
        );
    }
    
    private get cruisePackagesEnabled(): boolean {
        return (
            (AppController.cruisePackagesEnabled) &&
            ((!this.serviceProviderSlug) || (this.serviceProviderSlug.applicationTypes.contains(ApplicationType.Cruise)))
        );
    }
    
    private get shuttleEnabled(): boolean {
        return (
            (AppController.shuttleEnabled) && 
            (AppController.asPassenger) &&
            ((!this.serviceProviderSlug) || (this.serviceProviderSlug.applicationTypes.contains(ApplicationType.Shuttle)))
        );
    }

    public get hasBackButton(): BackButtonVisibility {
        return BackButtonVisibility.Hidden;
    }

    public getManualProps(): IManualProps {
        if (ServiceProviderController.isPjta) {
            return {
                icon: "fal fa-info",
                onClick: async () => this._pjtaModal.current?.openAsync()
            };
        }
        
        return {};
    }

    public getManual(): string {
        return Localizer.dashboardGetManual;
    }

    public getTitle(): string {
        return AppController.getFullPageTitle(Localizer.topNavFrontpage);
    }

    public async onSwipeHandlerAsync(direction: SwipeDirection): Promise<boolean> {
        return (
            (!this._hdsModal.current?.isOpen) &&
            (!this._pjtaModal.current?.isOpen)
        );
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        const trips: TripInfo[] = await this.postAsync("/api/mobileApp/listMyTripInfos");
        
        await this.setState( { trips, initialized: true });
    }
    
    public static async openAttractionModalAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.mobileDashboardRoute);

        if (ServiceProviderController.isHds) {
            const page: Dashboard | null = ch.findPage() as Dashboard | null;
            await page?._hdsModal?.current?.openAsync();
        }
    }
    
    private renderListItem(item: TripInfo, index: number, current: boolean): React.ReactNode {
        const description: string = item.description || item.date.format("dt");
        
        return (
            <div key={index} className={styles.tripInfo} onClick={() => this.openTripAsync(item)}>

                <div className={this.css(styles.logo, !current && styles.latest)}>
                    <Icon name="fal fa-map-marker-alt" size={IconSize.X2} />
                </div>
                
                <div className={styles.content}>
                    <span>{item.area}</span>
                    <span>{description}</span>
                </div>
                
            </div>
        )
    }
    
    private renderList(current: boolean): React.ReactNode {

        const title: string = (current)
            ? Localizer.mobileDashboardPageCurrentBookings
            : Localizer.mobileDashboardPageLatestTrips
        
        const noItemsLabel: string = (this.state.initialized)
            ? (current)
                ? Localizer.mobileDashboardPageNoBookings
                : Localizer.mobileDashboardPageNoPreviouseTrips
            : Localizer.genericLoading;
        
        const showAll: boolean = (current)
            ? this.state.currentShowAll
            : this.state.latestShowAll;

        let items: TripInfo[] = (current)
            ? this.state.trips.where(item => !item.passed)
            : this.state.trips.where(item => item.passed);
        
        if (!showAll) {
            items = items.take(1);
        }
        
        const hasItems: boolean = (items.length > 0);
        
        return (
            <div className={styles.trips}>

                <div className={styles.title} onClick={() => hasItems && this.toggleShowAllAsync(current)}>
                    <span>{title}</span>
                    { hasItems && <span>{showAll ? Localizer.mobileDashboardPageCollapse : Localizer.mobileDashboardPageViewAll}</span> }
                </div>

                <div className={styles.items}>

                    {
                        (hasItems)
                            ?
                            (
                                <React.Fragment>
                                    
                                    {items.map((item, index) => this.renderListItem(item, index, current))}
                                    
                                </React.Fragment>
                            )
                            :
                            (
                                <div className={styles.tripInfo}>

                                    <div className={this.css(styles.logo, !current && styles.latest)}>
                                        <Icon name="fal fa-map-marker-alt" size={IconSize.X2} />
                                    </div>

                                    <div className={styles.content}>

                                        <span>{noItemsLabel}</span>

                                    </div>

                                </div>
                            )
                    }
                    
                </div>

            </div>
        );
    }

    public render(): React.ReactNode {

        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.dashboard, this.mobile && styles.mobile)}
                           alertClassName={this.css(boutStyles.alert)}
            >
                
                <div className={styles.top}>
                    
                    <div className={styles.title}>
                        
                        {Localizer.mobileDashboardPageWelcome.format(this.getUserName())}
                        
                        <img src={wavesImage} alt={""} />

                        {
                            (this.serviceProviderSlug) &&
                            (
                                <span className={styles.description}>
                                    {ReactUtility.toMultiLines(LocalizationString.value(this.serviceProviderSlug.description))}
                                </span>
                            )
                        }
                        
                    </div>
                    
                    <div className={styles.buttons}>

                        {
                            ((this.rideEnabled) || (this.cruisePackagesEnabled)) &&
                            (
                                <div className={styles.row}>

                                    {
                                        (this.rideEnabled) &&
                                        (
                                            <div className={styles.ride} onClick={() => this.openRideAppAsync()}>

                                                <img src={rideImage} alt={Localizer.mobileDashboardPageRideApp}/>

                                                <div>
                                                    <span>{Localizer.mobileDashboardPageBookRide}</span>
                                                    <Icon name="fal fa-long-arrow-right" size={IconSize.X2}/>
                                                </div>

                                            </div>
                                        )
                                    }

                                    {
                                        (this.cruisePackagesEnabled) &&
                                        (
                                            <div onClick={() => AppController.openCruiseAppAsync()}>

                                                <img src={cruiseImage} alt={Localizer.mobileDashboardPageCruiseApp}/>

                                                <div>
                                                    <span>{Localizer.mobileDashboardPageBookCruise}</span>
                                                    <Icon name="fal fa-long-arrow-right" size={IconSize.X2}/>
                                                </div>

                                            </div>
                                        )
                                    }

                                </div>
                            )
                        }

                        {
                            ((this.cruisePackagesEnabled) || (this.shuttleEnabled)) &&
                            (
                                <div className={styles.row}>

                                    {
                                        (this.shuttleEnabled) &&
                                        (
                                            <div className={styles.shuttle} onClick={() => this.openShuttleAppAsync()}>

                                                <img src={shuttleImage} alt={Localizer.mobileDashboardPageShuttleApp}/>

                                                <div>
                                                    <span>{Localizer.mobileDashboardPageShuttleApp}</span>
                                                    <Icon name="fal fa-long-arrow-right" size={IconSize.X2}/>
                                                </div>

                                            </div>
                                        )
                                    }

                                    {
                                        (this.cruisePackagesEnabled) &&
                                        (
                                            <div onClick={() => this.openCruisePackageWizardAsync()}>

                                                <Icon name="fal fa-tree-palm" size={IconSize.X4}/>

                                                <div>
                                                    <span>{Localizer.mobileDashboardPageCruisePackage}</span>
                                                    <Icon name="fal fa-long-arrow-right" size={IconSize.X2}/>
                                                </div>

                                            </div>
                                        )
                                    }

                                </div>
                            )
                        }
                        
                    </div>

                </div>

                <img className={styles.separator} src={separatorImage} alt="" />
                
                <div className={styles.bottom}>

                    { (!this.state.latestShowAll) && this.renderList(true) }

                    { (!this.state.currentShowAll) && this.renderList(false) }
                                        
                </div>
                
                <HdsModal ref={this._hdsModal} />
                
                <PjtaModal ref={this._pjtaModal} />

            </PageContainer>
        );
    }
}