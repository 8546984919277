import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {Checkbox, Form, OneColumn, Panel, TextAreaInput} from "@reapptor-apps/reapptor-react-components";
import ServiceProvider from "@/models/server/ServiceProvider";
import FirebaseSettings from "@/models/server/serviceproviderinfo/FirebaseSettings";
import Localizer from "@/localization/Localizer";

import styles from "./FirebaseSettingsPanel.module.scss"

interface IFirebaseSettingsPanelProps {
    serviceProvider: ServiceProvider;
}

interface IFirebaseSettingsPanelState {
}

export default class FirebaseSettingsPanel extends BaseComponent<IFirebaseSettingsPanelProps, IFirebaseSettingsPanelState> {

    state: IFirebaseSettingsPanelState = {};

    private readonly _formRef: React.RefObject<Form> = React.createRef();

    private get firebaseSettings(): FirebaseSettings | null {
        return this.serviceProvider.firebaseSettings;
    }

    private async setGoogleCredentialJsonAsync(value: string): Promise<void> {
        if (this.serviceProvider.firebaseSettings) {
            this.serviceProvider.firebaseSettings.googleCredentialJson = value;
            await this.reRenderAsync();
        }
    }

    private async setGoogleWebConfigJsonAsync(value: string): Promise<void> {
        if (this.serviceProvider.firebaseSettings) {
            this.serviceProvider.firebaseSettings.googleWebConfigJson = value;
            await this.reRenderAsync();
        }
    }

    private async setGoogleWebVapidKeyAsync(value: string): Promise<void> {
        if (this.serviceProvider.firebaseSettings) {
            this.serviceProvider.firebaseSettings.googleWebVapidKey = value;
            await this.reRenderAsync();
        }
    }

    private async enableFirebaseSettingsAsync(value: boolean): Promise<void> {
        if (this.serviceProvider) {
            if (this.serviceProvider.firebaseSettings == null) {
                this.serviceProvider.firebaseSettings = new FirebaseSettings();
            }

            this.serviceProvider.firebaseSettings.enabled = value

            await this.reRenderAsync();
        }
    }

    public async isFormValidAsync(): Promise<boolean> {
        if (this._formRef.current) {
            const formValid: boolean = await this._formRef.current.validateAsync();
            
            return (this.firebaseSettings == null) || (!this.firebaseSettings.enabled) || (formValid);
        }

        return false;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public get serviceProvider(): ServiceProvider {
        return this.props.serviceProvider;
    }

    public render(): React.ReactNode {
        return (
            <Panel className={this.css("flex-2", styles.firebaseSettingsPanel)}>
                <div className="col-12">

                    <Form ref={this._formRef} className={styles.form}>

                        <OneColumn>

                            <Checkbox id="firebaseSettingsEnable"
                                      label={Localizer.serviceProvidersManagementPageWhiteLabelFirebaseEnabled}
                                      value={this.firebaseSettings?.enabled || false}
                                      onChange={(_, value) => this.enableFirebaseSettingsAsync(value)}
                            />

                        </OneColumn>

                        {
                            (this.firebaseSettings && this.firebaseSettings.enabled) &&
                            (

                                <OneColumn>

                                    <TextAreaInput id="googleCredentialJson" required
                                                   label={Localizer.serviceProvidersManagementPageWhiteLabelGoogleCredentialJson}
                                                   value={this.firebaseSettings.googleCredentialJson}
                                                   onChange={(_, value) => this.setGoogleCredentialJsonAsync(value)}
                                    />


                                </OneColumn>
                            )
                        }

                        {
                            (this.firebaseSettings && this.firebaseSettings.enabled) &&
                            (

                                <OneColumn>

                                    <TextAreaInput id="googleWebConfigJson" required
                                                   label={Localizer.serviceProvidersManagementPageWhiteLabelGoogleWebConfigJson}
                                                   value={this.firebaseSettings.googleWebConfigJson}
                                                   onChange={(_, value) => this.setGoogleWebConfigJsonAsync(value)}
                                    />

                                </OneColumn>
                            )
                        }

                        {
                            (this.firebaseSettings && this.firebaseSettings.enabled) &&
                            (

                                <OneColumn>

                                    <TextAreaInput id="googleWebVapidKey" required
                                                   label={Localizer.serviceProvidersManagementPageWhiteLabelGoogleWebVapidKey}
                                                   value={this.firebaseSettings.googleWebVapidKey}
                                                   onChange={(_, value) => this.setGoogleWebVapidKeyAsync(value)}
                                    />

                                </OneColumn>
                            )
                        }

                    </Form>

                </div>

            </Panel>
        );
    }
};