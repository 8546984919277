import React from "react";
import {AlertModel, AlertType, BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {Alert, Button, ButtonType, Icon, IconSize, Modal, ModalSize} from "@reapptor-apps/reapptor-react-components";
import Booking from "@/models/server/bout/Booking";
import Localizer from "@/localization/Localizer";

import mobileAppStyles from "../../mobile.module.scss";
import styles from "./BookingRateModal.module.scss";

export interface IBookingRateModalProps {
    booking: Booking;
    asCaptain: boolean;
    alertMessage?: string;
    alertType?: AlertType;
    onClose?: (sender: BookingRateModal, booking: Booking) => Promise<void>;
}

interface IBookingRateModalState {
}

export default class BookingRateModal extends BaseComponent<IBookingRateModalProps, IBookingRateModalState> {

    state: IBookingRateModalState = {
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();

    private async captainRatingPassengerAsync(rating: number): Promise<void> {
        this.booking.captainRatingPassenger = rating;

        await this.reRenderAsync();
    }

    private async passengerRatingCaptainAsync(rating: number): Promise<void> {
        this.booking.passengerRatingCaptain = rating;

        await this.reRenderAsync();
    }

    private async passengerRatingBoatAsync(rating: number): Promise<void> {
        this.booking.passengerRatingBoat = rating;

        await this.reRenderAsync();
    }

    private async onCloseAsync(): Promise<void> {
        if (this.props.onClose) {
            await this.props.onClose(this, this.booking);
        }
    }

    private get modal(): Modal | null {
        return this._modalRef.current;
    }

    private get alertMessage(): string | null {
        return (this.props.alertMessage) ?? null;
    }

    public async openAsync(): Promise<void> {
        if (this.modal) {
            await this.modal.openAsync();
        }
    }

    public async closeAsync(): Promise<void> {
        if (this.modal) {
            await this.modal.closeAsync();
        }
    }

    public get booking(): Booking {
        return this.props.booking;
    }

    public get asCaptain(): boolean {
        return this.props.asCaptain;
    }

    public get asPassenger(): boolean {
        return !this.asCaptain;
    }

    public get alertModel(): AlertModel | null {
        if (this.alertMessage) {
            const alertType: AlertType = (this.props.alertType) ?? AlertType.Success;
            return new AlertModel(this.alertMessage, alertType, false, false);
        }

        return null;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.openAsync();
    }

    private renderRate(label: string, rating: number | null, callback: (rating: number) => Promise<void>): React.ReactNode {
        const ratings = [1, 2, 3, 4, 5];
        return (
            <div className={styles.rating}>
                <span>{label}</span>
                <div>
                    {
                        ratings.map((item, index) => (
                            <Icon key={index}
                                  size={IconSize.X3}
                                  name={(index < (rating || 0)) ? "fas fa-star" : "fal fa-star"}
                                  onClick={() => callback(index + 1)}
                            />
                        ))
                    }
                </div>
            </div>
        );
    }

    public render(): React.ReactNode {
        return (
            <Modal id={this.id} noHeader notResponsive
                   size={ModalSize.Auto}
                   ref={this._modalRef}
                   className={this.css(styles.bookingRateModal, this.mobile && styles.mobile, mobileAppStyles.mobile)}
                   onClose={() => this.onCloseAsync()}>

                <div className={styles.content}>

                    {
                        (this.alertMessage) && <Alert model={this.alertModel!}/>
                    }

                    <span className={styles.label}>{Localizer.mobileBookingRateModalRateTripSpan}</span>

                    {(this.asPassenger) && this.renderRate(Localizer.mobileBookingRateModalCaptain, this.booking.passengerRatingCaptain, (rating) => this.passengerRatingCaptainAsync(rating))}

                    {(this.asPassenger) && this.renderRate(Localizer.mobileBookingRateModalBoat, this.booking.passengerRatingBoat, (rating) => this.passengerRatingBoatAsync(rating))}

                    {(this.asCaptain) && this.renderRate(Localizer.mobileBookingRateModalPassenger, this.booking.captainRatingPassenger, (rating) => this.captainRatingPassengerAsync(rating))}

                    <Button type={ButtonType.Dark}
                            label={Localizer.mobileBookingRateModalButtonThanksLabel}
                            onClick={() => this.closeAsync()}
                    />

                </div>

            </Modal>
        );
    }
}