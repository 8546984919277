// autogenerated

export default class GetServicePointsRequest {

    public isActive: boolean = false;

    public areaId: string | null = null;

    public readonly isGetServicePointsRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof GetServicePointsRequest) || ((from as any).isGetServicePointsRequest === true));
    }

    public static as(from?: object | null): GetServicePointsRequest | null {
        return (GetServicePointsRequest.is(from)) ? from as GetServicePointsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}