import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import PageDefinitions from "../../providers/PageDefinitions";
import {Link, PageContainer, PageHeader, PageRow} from "@reapptor-apps/reapptor-react-components";
import Localizer from "../../localization/Localizer";

import styles from "./Admin.module.scss"

export default class Admin extends AuthorizedPage {
    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.admin}>
                <PageHeader title={Localizer.topNavAdminManagement} subtitle={this.getContext().username}/>
                <PageRow>
                    <div className="col">
                        <div className={styles.menu}>
                            <Link className={styles.menuLink} route={PageDefinitions.userManagementRoute}>{Localizer.userManagementPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.bookingsRoute}>{Localizer.bookingsPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.boatsRoute}>{Localizer.boatsPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.servicePointsRoute}>{Localizer.servicePointsPanelTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.priceManagementRoute}>{Localizer.priceManagementTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.countriesRoute}>{Localizer.countriesPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.serviceProvidersRoute}>{Localizer.serviceProvidersPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.cruisePackagesManagementRoute}>{Localizer.cruiseManagementPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.applicationSettingsRoute}>{Localizer.applicationSettingsPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.adminConsoleRoute}>{Localizer.adminConsolePageTitle}</Link>
                        </div>
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}