import React from "react";
import {
    ActionType,
    BasePageParameters,
    PageRoute,
    PageRouteProvider,
    TextAlign
} from "@reapptor-apps/reapptor-react-common";
import {
    CellAction,
    ColumnActionType,
    Button,
    ButtonType,
    CellModel,
    Checkbox,
    ColumnDefinition,
    Dropdown,
    Grid,
    IconSize,
    Inline,
    InlineType,
    JustifyContent,
    PageContainer,
    PageHeader,
    PageRow,
    ToolbarContainer,
    ToolbarRow, RowModel
} from "@reapptor-apps/reapptor-react-components";
import Country from "@/models/server/bout/Country";
import Area from "@/models/server/bout/Area";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import CruisePackage from "@/models/server/cruise/CruisePackage";
import ListCruisePackagesRequest from "@/models/server/requests/ListCruisePackagesRequest";
import {SortDirection, Utility} from "@reapptor-apps/reapptor-toolkit";
import PageDefinitions from "@/providers/PageDefinitions";
import CruisePackagePreviewModal from "@/components/CruisePackagePreviewModal/CruisePackagePreviewModal";
import {CruisePackageOwnerType} from "@/models/Enums";
import ListAreasRequest from "@/models/server/requests/ListAreasRequest";
import ListAreasResponse from "@/models/server/responses/ListAreasResponse";
import DeleteCruisePackageResponse from "@/models/server/responses/DeleteCruisePackageResponse";
import TransformProvider from "@/providers/TransformProvider";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import styles from "./CruisePackagesManagement.module.scss";

interface ICruisePackagesManagementProps extends BasePageParameters {
}

interface ICruisePackagesManagementState {
    countries: Country[],
    areas: Area[],
    countryAreas: Area[],
    countryFilter: Country | null,
    areasFilter: Area[],
    searchFilter: string | null,
    activeOnlyFilter: boolean,
    showDeletedFilter: boolean
}

export default class CruisePackagesManagement extends AuthorizedPage<ICruisePackagesManagementProps, ICruisePackagesManagementState> {

    state: ICruisePackagesManagementState = {
        countries: [],
        areas: [],
        countryAreas: [],
        countryFilter: null,
        areasFilter: [],
        searchFilter: null,
        activeOnlyFilter: false,
        showDeletedFilter: false,
    }

    private readonly _cruisePackagePreviewModalRef: React.RefObject<CruisePackagePreviewModal> = React.createRef();
    private readonly _cruisePackagesGridRef: React.RefObject<Grid<CruisePackage>> = React.createRef();
    private readonly _pageSize: number = 20;

    private readonly _cruisePackagesColumns: ColumnDefinition[] = [
        {
            header: Localizer.cruisePackagesManagementPageGridOwnerTypeLanguageItemName,
            accessor: nameof.full<CruisePackage>(o => o.ownerType),
            format: nameof<CruisePackageOwnerType>(),
            wordBreak: true,
            visible: (!AppController.asCaptain),
            minWidth: 90
        },
        {
            header: Localizer.cruisePackagesManagementPageGridServiceProviderLanguageItemName,
            accessor: (model: CruisePackage) => model.serviceProvider?.name ?? model.owner,
            minWidth: 200,
            noWrap: true,
            visible: (!AppController.asCaptain),
            settings: {
                infoAccessor: (model: CruisePackage) => model.serviceProvider?.companyName ?? model.owner?.email
            }
        },
        {
            header: Localizer.cruisePackagesManagementPageGridAreaLanguageItemName,
            accessor: nameof.full<CruisePackage>(o => o.area),
            minWidth: 160,
            noWrap: true,
            settings: {
                infoAccessor: nameof.full<CruisePackage>(o => o.area!.country!.name),
            }
        },
        {
            header: Localizer.cruisePackagesManagementPageGridBoatLanguageItemName,
            accessor: nameof.full<CruisePackage>(o => o.boat!.brand),
            minWidth: 150,
            noWrap: true,
            settings: {
                infoAccessor: nameof.full<CruisePackage>(o => o.boat!.model),
            }
        },
        {
            header: Localizer.cruisePackagesManagementPageGridNameLanguageItemName,
            accessor: nameof.full<CruisePackage>(o => o.name),
            minWidth: 200,
            stretch: true,
        },
        {
            header: Localizer.cruisePackagesManagementPageGridActiveLanguageItemName,
            accessor: (model: CruisePackage) => model.active ? "✅︎" : "⛔",
            minWidth: 70,
            maxWidth: 70,
            rotate: true,
            textAlign: TextAlign.Center,
        },
        {
            header: Localizer.cruisePackagesManagementPageGridSeasonLanguageItemName,
            accessor: nameof<CruisePackage>(o => o.seasonStartsAt),
            format: "dd.MM.yy",
            textAlign: TextAlign.Center,
            minWidth: 90,
            className: styles.season,
            settings: {
                infoAccessor: nameof<CruisePackage>(o => o.seasonEndsAt),
                infoFormat: "dd.MM.yy",
            },
            init: (cell: CellModel<CruisePackage>) => this.initCruisePackageSeason(cell),
        } as ColumnDefinition,
        {
            header: Localizer.cruisePackagesManagementPageGridMaxCapacityLanguageItemName,
            accessor: nameof.full<CruisePackage>(o => o.maxCapacity),
            minWidth: 70,
            maxWidth: 70,
            rotate: true,
            textAlign: TextAlign.Center,
        },
        {
            group: Localizer.cruisePackagesManagementPageGridOptionsLanguageItemName,
            header: Localizer.cruisePackagesManagementPageGridCustomLocationLanguageItemName,
            accessor: (model: CruisePackage) => model.customLocation ? "✅︎" : "⛔",
            minWidth: 50,
            maxWidth: 50,
            textAlign: TextAlign.Center,
        },
        {
            group: Localizer.cruisePackagesManagementPageGridOptionsLanguageItemName,
            header: Localizer.cruisePackagesManagementPageGridFoodLanguageItemName,
            accessor: (model: CruisePackage) => model.food ? "✅︎" : "⛔",
            minWidth: 50,
            maxWidth: 50,
            textAlign: TextAlign.Center,
        },
        {
            group: Localizer.cruisePackagesManagementPageGridOptionsLanguageItemName,
            header: Localizer.cruisePackagesManagementPageGridDrinksLanguageItemName,
            accessor: (model: CruisePackage) => model.drinks ? "✅︎" : "⛔",
            minWidth: 50,
            maxWidth: 50,
            textAlign: TextAlign.Center,
        },
        {
            group: Localizer.cruisePackagesManagementPageGridOptionsLanguageItemName,
            header: Localizer.cruisePackagesManagementPageGridDisabledLanguageItemName,
            accessor: (model: CruisePackage) => model.boat?.disabledFriendly ? "✅︎" : "⛔",
            minWidth: 50,
            maxWidth: 50,
            textAlign: TextAlign.Center,
        },
        {
            group: Localizer.cruisePackagesManagementPageGridOptionsLanguageItemName,
            header: Localizer.cruisePackagesManagementPageGridPetFriendlyLanguageItemName,
            accessor: (model: CruisePackage) => model.boat?.petFriendly ? "✅︎" : "⛔",
            minWidth: 50,
            maxWidth: 50,
            textAlign: TextAlign.Center,
        },
        {
            group: Localizer.cruisePackagesManagementPageGridOptionsLanguageItemName,
            header: Localizer.cruisePackagesManagementPageGridToiletLanguageItemName,
            accessor: (model: CruisePackage) => model.boat?.toilet ? "✅︎" : "⛔",
            minWidth: 50,
            maxWidth: 50,
            textAlign: TextAlign.Center,
        },
        {
            header: Localizer.genericActionsLanguageItemName,
            minWidth: 150,
            removable: false,
            init: (cell: CellModel<CruisePackage>) => this.initCruisePackageOperations(cell),
            actions: [
                {
                    name: "activate",
                    title: Localizer.genericActivate,
                    icon: "far play",
                    type: ActionType.Blue,
                    callback: (cell, action) => this.processCruisePackageOperationsAsync(cell, action)
                },
                {
                    name: "deactivate",
                    title: Localizer.genericDeactivate,
                    icon: "far pause",
                    type: ActionType.Blue,
                    callback: (cell: CellModel<CruisePackage>, action) => this.processCruisePackageOperationsAsync(cell, action)
                },
                {
                    right: true,
                    name: "preview",
                    type: ColumnActionType.Preview,
                    callback: (cell: CellModel<CruisePackage>, action) => this.processCruisePackageOperationsAsync(cell, action)
                },
                {
                    right: true,
                    name: "edit",
                    title: Localizer.genericEdit,
                    icon: "far edit",
                    type: ActionType.Create,
                    callback: (cell: CellModel<CruisePackage>, action) => this.processCruisePackageOperationsAsync(cell, action)
                },
                {
                    right: true,
                    name: "delete",
                    title: Localizer.genericDelete,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    callback: (cell: CellModel<CruisePackage>) => this.deletePackageAsync(cell)
                },
                {
                    right: true,
                    name: "restore",
                    title: Localizer.genericRestore,
                    icon: "trash-restore",
                    type: ActionType.Delete,
                    callback: (cell: CellModel<CruisePackage>) => this.restorePackageAsync(cell)
                },
            ]
        }
    ]

    private async setCountryFilterAsync(country: Country | null): Promise<void> {

        if (country != null) {
            this.state.countryFilter = country;
            this.state.countryAreas = this.state.areas.where(item => item.countryId == country.id);
            this.state.areasFilter = [];
        } else {
            this.state.countryFilter = null;
            this.state.countryAreas = this.state.areas;
            this.state.areasFilter = [];
        }

        await this.grid?.reloadAsync();
    }

    private async setAreasFilterAsync(areas: Area[]): Promise<void> {

        this.state.areasFilter = areas;

        await this.grid?.reloadAsync();
    }

    private async setActiveOnlyFilterAsync(activeOnly: boolean): Promise<void> {

        this.state.activeOnlyFilter = activeOnly;
        
        if (activeOnly) {
            this.state.showDeletedFilter = false;
        }

        await this.grid?.reloadAsync();
    }

    private async setShowDeletedFilterAsync(showDeleted: boolean): Promise<void> {

        this.state.showDeletedFilter = showDeleted;

        await this.grid?.reloadAsync();
    }

    private async createCrusePackageAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.cruisePackageManagementRoute);
    }

    private async fetchAreasAsync(): Promise<Area[]> {
        const request = new ListAreasRequest();
        request.asAdmin = AppController.asAdmin;
        request.asCaptain = AppController.asCaptain;

        const response: ListAreasResponse = await this.postAsync("/api/cruisePackage/listAreas", request);

        return response.areas ?? [];
    }

    private async fetchCruisePackagesAsync(pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<CruisePackage[]> {
        const request = new ListCruisePackagesRequest();
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.countryId = this.state.countryFilter?.id || null;
        request.areaIds = this.state.areasFilter.select(item => item.id);
        request.search = this.state.searchFilter;
        request.activeOnly = this.state.activeOnlyFilter;
        request.showDeleted = this.state.showDeletedFilter;
        request.asCaptain = AppController.asCaptain;

        return await this.postAsync("/api/cruisePackage/listCruisePackages", request);
    }

    private async initCruisePackageOperations(cell: CellModel<CruisePackage>): Promise<void> {
        const model: CruisePackage = cell.model;

        const activateAction: CellAction<CruisePackage> = cell.actions[0];
        const deactivateAction: CellAction<CruisePackage> = cell.actions[1];
        const deleteAction: CellAction<CruisePackage> = cell.actions[4];
        const restoreAction: CellAction<CruisePackage> = cell.actions[5];

        const activationControlButtonsHidden: boolean = (AppController.asCaptain) || (model.deleted);

        activateAction.visible = (!model.active) && (!activationControlButtonsHidden);

        deactivateAction.visible = (model.active) && (!activationControlButtonsHidden);

        deleteAction.visible = (!model.deleted) && (!activationControlButtonsHidden);

        restoreAction.visible = (model.deleted);
    }

    private async initCruisePackageSeason(cell: CellModel<CruisePackage>): Promise<void> {
        const model: CruisePackage = cell.model;

        cell.className = this.cssIf(cell.className, model.isInSeason, styles.inSeason);
    }

    private async deletePackageAsync(cell: CellModel<CruisePackage>): Promise<void> {
        const model: CruisePackage = cell.model;
        const cruisePackageFullName: string = this.cruisePackageFullName(model);
        
        const confirm: boolean = await this.confirmAsync(Utility.format(Localizer.adminConfirmationButtonDeleteCruisePackage, cruisePackageFullName));

        if (confirm) {

            const response: DeleteCruisePackageResponse = await cell.grid.postAsync("/api/cruisePackage/deleteCruisePackage", model.id);

            if (response.deletedPermanently) {
                const message: string = Localizer.adminCruisePackageDeletedPermanently.format(cruisePackageFullName);

                await this.alertMessageAsync(message, true);

                // Reload the grid data after deletion
                await this.grid?.reloadAsync();

                return;
            }
            
            if (response.hasAnyOngoingBookings) {
                const message: string = Localizer.adminCruisePackageNotDeleted.format(cruisePackageFullName);
                
                await this.alertErrorAsync(message, true);
                
                return;
            }

            const message: string = Localizer.adminCruisePackageDeleted.format(cruisePackageFullName);
            
            await this.alertMessageAsync(message, true);
            
            model.deleted = true;
            model.active = false;

            await cell.row.bindAsync(true);
        }
    }

    private async restorePackageAsync(cell: CellModel<CruisePackage>): Promise<void> {
        const model: CruisePackage = cell.model;
        const cruisePackageFullName: string = this.cruisePackageFullName(model);
        
        const confirm: boolean = await this.confirmAsync(Utility.format(Localizer.adminConfirmationButtonRestoreCruisePackage, cruisePackageFullName));

        let message: string;

        if (confirm) {
            await cell.grid.postAsync("/api/cruisePackage/restoreCruisePackage", model.id);

            message = Localizer.adminCruisePackageRestored.format(cruisePackageFullName);
            
            await this.alertMessageAsync(message, true);
            
            model.deleted = false;
            
            await cell.row.bindAsync(true);
        }
    }

    private async processCruisePackageOperationsAsync(cell: CellModel<CruisePackage>, action: CellAction<CruisePackage>): Promise<void> {

        const model: CruisePackage = cell.model;

        switch (action.action.name) {

            case "edit":

                const route: PageRoute = PageDefinitions.cruisePackageManagement(model.id);

                await PageRouteProvider.redirectAsync(route);

                break;

            case "activate":

                cell.row.model = await cell.grid.postAsync("/api/cruisePackage/activateCruisePackage", model.id);

                await cell.row.bindAsync();

                break;

            case "deactivate":

                cell.row.model = await cell.grid.postAsync("/api/cruisePackage/deactivateCruisePackage", model.id);

                await cell.row.bindAsync();
                
                break;

            case "preview":

                await this._cruisePackagePreviewModalRef.current?.openAsync(model);

                break;

        }
    }

    private initRow(row: RowModel<CruisePackage>): void {
        const model: CruisePackage = row.model;
        
        row.className = this.cssIf(row.className, model.deleted, styles.deletedPackage);
    }
    
    private get grid(): Grid<CruisePackage> | null {
        return this._cruisePackagesGridRef.current || null;
    }

    public getTitle(): string {
        return Localizer.cruisePackagesManagementPageTitle;
    }

    private cruisePackageFullName(cruisePackage: CruisePackage): string {
        return (cruisePackage)
            ? TransformProvider.cruisePackageToString(cruisePackage)
            : "";
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const [countries, areas] = await Promise.all([
            AppController.listCountriesAsync(),
            this.fetchAreasAsync()
        ]);

        //const countries: Country[] = await AppController.listCountriesAsync();

        //const areas: Area[] = await this.fetchAreasAsync();

        await this.setState({countries, areas, countryAreas: areas});
    }

    public render(): React.ReactNode {
        return (
            <PageContainer fullWidth fullHeight className={this.css(styles.cruisePackagesManagement, "ignore-mobile")}>

                <PageHeader title={this.getTitle()}/>

                <PageRow>

                    <div className="col">

                        <ToolbarContainer>

                            <ToolbarRow justify={JustifyContent.SpaceBetween}>

                                <Inline>

                                    <Dropdown id={"countryIdFilter"} noWrap inline clearButton noSubtext
                                              minWidth={250}
                                              items={this.state.countries}
                                              selectedItem={this.state.countryFilter}
                                              nothingSelectedText={Localizer.cruisePackagesManagementPageAllCountries}
                                              onChange={(sender, item) => this.setCountryFilterAsync(item)}
                                    />

                                    <Dropdown id={"areaIdsFilter"} noWrap inline multiple clearButton autoCollapse noSubtext
                                              selectedTextFormat={1}
                                              minWidth={250}
                                              items={this.state.countryAreas}
                                              selectedItems={this.state.areasFilter}
                                              nothingSelectedText={Localizer.cruisePackagesManagementPageAllAreas}
                                              onChange={(sender) => this.setAreasFilterAsync(sender.selectedItems)}
                                    />

                                    <Checkbox id={"activeOnly"} inline
                                              inlineType={InlineType.Right}
                                              label={Localizer.cruisePackagesManagementPageActive}
                                              value={this.state.activeOnlyFilter}
                                              onChange={(_, value) => this.setActiveOnlyFilterAsync(value)}
                                    />

                                    {
                                        (!this.state.activeOnlyFilter) &&
                                        (
                                            <Checkbox id={"showDeleted"} inline
                                                      inlineType={InlineType.Right}
                                                      label={Localizer.cruisePackagesManagementPageShowDeleted}
                                                      value={this.state.showDeletedFilter}
                                                      onChange={(_, value) => this.setShowDeletedFilterAsync(value)}
                                            />
                                        )
                                    }

                                </Inline>

                                <Inline>

                                    <Button right
                                            icon={{name: "plus", size: IconSize.Large}}
                                            type={ButtonType.Primary}
                                            onClick={() => this.createCrusePackageAsync()}
                                    />

                                </Inline>

                            </ToolbarRow>

                        </ToolbarContainer>

                        <Grid id="cruisePackages" responsive
                              className={styles.grid}
                              headerMinHeight={300}
                              ref={this._cruisePackagesGridRef}
                              pagination={this._pageSize}
                              noDataText={Localizer.genericNoData}
                              columns={this._cruisePackagesColumns}
                              initRow={(row: RowModel<CruisePackage>) => this.initRow(row)}
                              fetchData={(_, pageNumber, pageSize, sortColumnName, sortDirection) => this.fetchCruisePackagesAsync(pageNumber, pageSize, sortColumnName, sortDirection)}
                        />

                    </div>

                </PageRow>

                <CruisePackagePreviewModal id={"cruisePackagePreviewModal"}
                                           ref={this._cruisePackagePreviewModalRef}
                />

            </PageContainer>
        );
    }
}