import React from "react";
import {ch, LocalizationString} from "@reapptor-apps/reapptor-react-common";
import AppConstants from "@/helpers/AppConstants";
import User from "@/models/server/User";
import CruisePackageWizard from "@/pages/Mobile/CruisePackageWizard/CruisePackageWizard";
import CruisePackageBooking from "@/models/server/cruise/CruisePackageBooking";
import CruisePackage from "@/models/server/cruise/CruisePackage";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import CruisePackagePoint from "@/models/server/cruise/CruisePackagePoint";
import Boat from "@/models/server/bout/Boat";
import {PageRow} from "@reapptor-apps/reapptor-react-components";
import BookingInfo from "@/pages/Mobile/BookingDetails/BookingInfo/BookingInfo";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import styles from "./OrderStep.module.scss";
import {PaymentPolicy} from "@/models/Enums";

export interface IOrderStepProps {
}

interface IOrderStepState {
    verified: boolean;
}

export default class OrderStep extends CruisePackageWizard<IOrderStepProps, IOrderStepState> {

    state: IOrderStepState = {
        verified: false,
    };

    private async onChangeAsync(): Promise<void> {
        CruisePackageBooking.estimatePrice(this.booking);

        this.save();

        await this.reRenderAsync();
    }

    private async bookAsync(): Promise<void> {
        this.state.verified = true;
        await this.nextAsync();
    }

    protected get contentClassName(): string {
        return styles.orderStep;
    }

    public get canNext(): boolean {
        return this.state.verified;
    }

    public get cruisePackage(): CruisePackage | null {
        return this.wizard.cruisePackage;
    }

    public get booking(): CruisePackageBooking | null {
        return this.wizard.booking;
    }

    public get title(): string | null {
        return LocalizationString.value(this.cruisePackage?.name);
    }

    private get bookingTime(): Date {
        const nextDay: Date = Utility.now().addDays(1);
        const bookingTime: Date | null = this.booking?.bookingTime || this.wizard.bookingTime;
        const cruisePackage: CruisePackage | null = this.cruisePackage;
        
        if ((cruisePackage) && (cruisePackage.hasSeason)) {
            const seasonStartsAt: Date = cruisePackage.seasonStartsAt!;
            const seasonEndsAt: Date = cruisePackage.seasonEndsAt!;
            const nextSeasonStartsAt: Date = CruisePackage.nextSeasonStartsAt(cruisePackage)!;
            const isInSeason: boolean = cruisePackage.isInSeason;

            if (
                (bookingTime) &&
                (
                    (bookingTime.inInterval(seasonStartsAt, seasonEndsAt, true)) ||
                    ((!isInSeason) && (bookingTime >= nextSeasonStartsAt))
                )
            ) {
                return bookingTime;
            }

            if (cruisePackage.isInSeason) {
                return (Utility.tomorrow() <= seasonEndsAt)
                    ? nextDay
                    : Utility.now();
            }

            return nextSeasonStartsAt;
        }

        return bookingTime || nextDay;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const cruisePackage: CruisePackage = this.cruisePackage!;
        const bookingTime: Date = this.bookingTime;
        const passengers: number = this.booking?.passengers || 1;

        if (((this.booking == null) || (cruisePackage.id != this.booking.cruisePackageId)) && cruisePackage.points != null) {

            const point: CruisePackagePoint = cruisePackage.points.first();
            const boat: Boat = cruisePackage.boat!;

            const booking = new CruisePackageBooking();
            
            booking.bookingTime = bookingTime;
            booking.cruisePackage = cruisePackage;
            booking.cruisePackageId = cruisePackage.id;
            booking.passengers = passengers;
            booking.pointId = point.id;
            booking.point = point;
            booking.boatType = boat.boatType;
            booking.durationInHours = cruisePackage.minDurationInHours || 1;
            booking.paymentPolicy = PaymentPolicy.AcceptedByCaptain;
            booking.noRefund = true;
            
            if (ch.isAuthenticated) {
                booking.passengerId = AppController.userId;
                booking.passenger = AppController.user;
            } else {
                booking.passengerId = AppConstants.defaultGuid;
                booking.passenger = new User(Localizer.language, AppConstants.passengerRole);
            }
            
            CruisePackageBooking.estimatePrice(booking);
            
            this.wizard.booking = booking;
            
            this.save();
        }

        await this.reRenderAsync();
    }

    public renderHeader(title: string | null): React.ReactNode {
        return (!this.cruiseStyle)
            ?
            (
                <span className={styles.bookingInfoTitle}>{Localizer.bookingInfoPageTitle}</span>
            )
            :
            (
                <React.Fragment/>
            )

    }

    public renderContent(): React.ReactNode {
        const hasGenericBackButton: boolean = (this.cruiseStyle) && (this.hasGenericBackButton);
        const bookingButtonStyle: any = hasGenericBackButton && styles.bookingButton;

        return ((this.cruisePackage) && (this.booking))
            ?
            (
                <PageRow className={this.css(styles.wrapper, bookingButtonStyle)}>

                    <BookingInfo previewHasOnlyInfo
                                 item={this.booking}
                                 onChange={() => this.onChangeAsync()}
                                 requestTrip={() => this.bookAsync()}
                                 book={() => this.bookAsync()}
                    />

                </PageRow>
            )
            :
            (
                <React.Fragment/>
            )
    }
}