import React from "react";
import {BaseComponent, IBaseComponent} from "@reapptor-apps/reapptor-react-common";
import Booking from "@/models/server/bout/Booking";
import WaypointMap from "@/components/WaypointMap/WaypointMap";
import BookingAvatar, {BookingAvatarType} from "@/components/BookingAvatar/BookingAvatar";
import {Button, ButtonType, Icon} from "@reapptor-apps/reapptor-react-components";
import User from "@/models/server/User";
import Boat from "@/models/server/bout/Boat";
import {BookingStatus, BookingType, PaymentStatus} from "@/models/Enums";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import BoatImage from "@/models/server/BoatImage";
import BookingTicketModal from "@/pages/Mobile/BookingDetails/BookingInfo/BookingTicketModal/BookingTicketModal";
import ServiceProviderController from "@/pages/ServiceProviderController";
import Localizer from "@/localization/Localizer";

import styles from "./BookingItem.module.scss";

export interface IBookingItemProps {
    asCaptain: boolean;
    isEntrepreneur: boolean;
    booking: Booking;

    onClick?(sender: IBaseComponent, item: Booking): Promise<void>;
}

export default class BookingItem extends BaseComponent<IBookingItemProps> {
    
    private readonly _bookingTicketModelRef: React.RefObject<BookingTicketModal> = React.createRef();
    
    private async onClickAsync(item: Booking): Promise<void> {
        if (this.props.onClick) {
            await this.props.onClick(this, item);
        }
    }

    private async openTicketAsync(): Promise<void> {
        if ((this._bookingTicketModelRef.current) && (this.booking)) {
            await this._bookingTicketModelRef.current?.openAsync(this.booking.id);
        }
    }
    
    private async refreshExpirationTimeAsync(initialize: boolean = false): Promise<void> {
        const left: number | null = this.left;

        if (left) {
            if (!initialize) {
                await this.reRenderAsync();
            }

            setTimeout(() => this.refreshExpirationTimeAsync(), 30000);
        }
    }

    public get booking(): Booking {
        return this.props.booking;
    }

    public get asCaptain(): boolean {
        return this.props.asCaptain;
    }

    public get isEntrepreneur(): boolean {
        return this.props.isEntrepreneur;
    }

    public get avatarType(): BookingAvatarType {
        return ((this.asCaptain) || (this.booking.bookingType != BookingType.Shuttle))
            ? BookingAvatarType.User
            : BookingAvatarType.BoatOnly;
    }

    public get boatPrimaryImage(): BoatImage | null {
        const boat: Boat | null = this.booking.boat ?? null;
        return Boat.findPrimaryImage(boat);
    }
    
    public get left(): number | null {
        const expirationTime: number | null = ServiceProviderController.estimatedBookingExpirationInMinutes;
        const left: number | null = ((expirationTime != null) && (expirationTime > 0))
            ? expirationTime - Utility.diff(Utility.now(), this.booking.createdAt).totalMinutes
            : null;
        return ((left != null) && (left > 0))
            ? left
            : null;
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        await this.refreshExpirationTimeAsync(true);
    }

    public render(): React.ReactNode {
        const booking: Booking = this.booking;
        const isShuttle: boolean = (booking.bookingType == BookingType.Shuttle);
        const hasTicket: boolean = (isShuttle) && (!!booking.ticketCode);
        const captainNotFound: boolean = Booking.captainNotFound(booking);
        const expired: boolean = Booking.expired(booking);
        const left: number | null = this.left;

        const name: string = "{0}".format(booking.waypoint ?? booking.cruisePackage);

        const user: User | null = (this.asCaptain)
            ? booking.passenger
            : booking.captain ?? booking.boat?.captain ?? null;

        const boutName: string = (booking.boat)
            ? booking.boat.model
            : (!captainNotFound)
                ? Localizer.myTripsBookingSearchingCaptain
                : "";

        const moneySymbol: string = booking.area?.country?.moneySymbol ?? "";

        const passengerPrice: string = (booking.price != null)
            ? "{0:C} {1}".format(booking.price, moneySymbol)
            : (booking.estimatedMinPrice != booking.estimatedMaxPrice)
                ? "{0:0} - {1:0} {2}".format(booking.estimatedMinPrice, booking.estimatedMaxPrice, moneySymbol)
                : "{0:C} {1}".format(booking.estimatedMinPrice, moneySymbol);

        const captainPrice: string = "{0:C} {1}".format(booking.captainShare, moneySymbol);

        const pending: boolean = (booking.paymentStatus == PaymentStatus.Pending);
        
        const status: string = (isShuttle)
            ? Localizer.myTripsBookingShuttlePassengersCount.format(booking.passengers)
            : (captainNotFound)
                ? Localizer.myTripsBookingNoCaptain
                : "{0:BookingStatus}".format(booking.latestStatus);

        return (
            <React.Fragment>
                <div className={styles.bookingItem} onClick={() => this.onClickAsync(booking)}>

                    <div className={styles.row1}>
                        
                        <WaypointMap key={`waypointMap_${this.booking.id}`}
                                     readonly
                                     mapTypeControl={false}
                                     point={(booking.waypoint ?? booking.cruisePackagePoint)!}
                        />

                        {
                            (!ServiceProviderController.isSingleApplicationType) &&
                            (
                                <div className={this.css(styles.label, booking.cruisePackage && styles.cruisePackage, isShuttle && styles.shuttle)}>
                                    <span>{Utility.formatValue(this.booking.bookingType, nameof<BookingType>())}</span>
                                </div> 
                            )
                        }
                        
                    </div>

                    <div className={this.css(styles.row_separator, booking.cruisePackage && styles.cruisePackage, isShuttle && styles.shuttle)}/>

                    <div className={styles.row2}>

                        <div className={styles.column1}>

                            <BookingAvatar type={this.avatarType}
                                           avatar={user?.avatar}
                                           boatImage={this.boatPrimaryImage}
                            />

                        </div>

                        <div className={styles.column2}>

                            <div className={styles.inner_row1}>
                                <span>{name}</span>
                            </div>

                            <div className={styles.inner_row2}>

                                <div className={styles.inner_column1}>
                                    <span className={styles.dark}>
                                        {"{0}".format(user)}
                                    </span>
                                    <span className={styles.grey}>
                                        {"{0}".format(boutName)}
                                        {
                                            ((!this.asCaptain) && (!expired) && (left) && (left > 0) && (booking.latestStatus == BookingStatus.New)) &&
                                            (
                                                <span className={styles.expirationTime}>{Localizer.myTripsBookingSearchingCaptainLeft.format(left)}</span>
                                            )
                                        }
                                    </span>
                                </div>

                                <div className={styles.inner_expander}/>

                                <div className={styles.inner_column2}>

                                    {
                                        ((this.asCaptain) && (this.isEntrepreneur))
                                            ?
                                            (
                                                <div className={styles.twoPrices}>

                                                    <span className={this.css(styles.dark)}>{captainPrice}</span>

                                                    <span className={this.css(styles.grey, styles.small)}>{passengerPrice}</span>

                                                </div>
                                            ) :
                                            (
                                                <span className={this.css(styles.dark, styles.price, ((this.mobile) && (passengerPrice.length >= 8)) && styles.small, pending && styles.pending)}>{passengerPrice}</span>
                                            )
                                    }

                                    <span className={this.css(styles.grey, expired && styles.expired)}>
                                        {"{0:dt}".format(Booking.getCountryLocalTime(booking, booking.bookingTime))}
                                    </span>
                                    
                                </div>

                                <div className={styles.inner_column3}>
                                    <Icon name={"fas fa-chevron-right"}/>
                                </div>

                            </div>

                            <div className={styles.inner_row3}>
                                <span className={Booking.getStatusColorStyle(booking)}>
                                    {status}
                                </span>
                            </div>

                        </div>

                    </div>

                    {
                        (hasTicket) &&
                        (
                            <Button stopPropagation
                                    icon={"fal fa-qrcode"}
                                    type={ButtonType.Dark}
                                    label={Localizer.mobileBookingInfoButtonOpenTicketLabel}
                                    className={styles.ticket}
                                    onClick={() => this.openTicketAsync()}
                            />
                        )
                    }

                </div>

                <BookingTicketModal ref={this._bookingTicketModelRef} />
                
            </React.Fragment>
        );
    }
}
