import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {Icon, IconSize, PageContainer, PageHeader, PageRow} from "@reapptor-apps/reapptor-react-components";
import ListPassengersOnBoardResponse from "@/models/server/responses/ListPassengersOnBoardResponse";
import ListPassengersOnBoardRequest from "@/models/server/requests/ListPassengersOnBoardRequest";
import Booking from "@/models/server/bout/Booking";
import {BookingStatus} from "@/models/Enums";

import boutStyles from "../../../bout.module.scss";
import styles from "./PassengersOnBoard.module.scss";
import ShuttleInfo from "@/models/server/shuttle/ShuttleInfo";
import {LocalizationString} from "@reapptor-apps/reapptor-react-common";
import ShuttleLegInstance from "@/models/server/shuttle/ShuttleLegInstance";
import PassengerBookingItem from "@/pages/Mobile/PassengersOnBoard/PassengerBookingItem/PassengerBookingItem";
import Localizer from "@/localization/Localizer";

export interface IPassengersOnBoardProps {
    shuttleLegInstanceId: string;
}

interface IPassengersOnBoardState {
    shuttleInfo: ShuttleInfo | null,
    shuttleLegInstance: ShuttleLegInstance | null,
    legalPassengers: Booking[] | null,
    illegalPassengers: Booking[] | null,
}

export default class PassengersOnBoard extends AuthorizedPage<IPassengersOnBoardProps, IPassengersOnBoardState> {

    state: IPassengersOnBoardState = {
        shuttleInfo: null,
        shuttleLegInstance: null,
        legalPassengers: null,
        illegalPassengers: null
    };

    public getTitle(): string {
        return Localizer.passengersOnBoardPageTitle;
    }

    public get shuttle(): ShuttleInfo | null {
        return this.state.shuttleInfo;
    }

    public get shuttleLegInstance(): ShuttleLegInstance | null {
        return this.state.shuttleLegInstance;
    }

    public get legalPassengers(): Booking[] | null {
        return this.state.legalPassengers;
    }

    public get illegalPassengers(): Booking[] | null {
        return this.state.illegalPassengers;
    }

    public async listPassengersOnBoardAsync(): Promise<ListPassengersOnBoardResponse> {
        const request = new ListPassengersOnBoardRequest();
        request.shuttleLegInstanceId = this.routeId;

        return await this.postAsync("/api/mobileApp/listPassengersOnBoard", request);
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const response: ListPassengersOnBoardResponse = await this.listPassengersOnBoardAsync();

        await this.setState({shuttleInfo: response.shuttleInfo, shuttleLegInstance: response.shuttleLegInstance, legalPassengers: response.legalPassengers, illegalPassengers: response.illegalPassengers});
    }

    public async reloadAsync(): Promise<void> {
        const response: ListPassengersOnBoardResponse = await this.listPassengersOnBoardAsync();

        await this.setState({legalPassengers: response.legalPassengers, illegalPassengers: response.illegalPassengers});
    }

    public renderPassengersBookingInfo(): React.ReactNode {
        return (
            <div className={styles.passengersBookingInfo}>

                <div className={styles.time}>

                    <div>{"{0:d}".format(this.shuttleLegInstance?.departureAt)}</div>

                    <div>{"{0:t} - {1:t}".format(this.shuttleLegInstance!.departureAt, this.shuttleLegInstance!.arrivalAt)}</div>

                </div>

                <hr/>

                {
                    (this.illegalPassengers) &&
                    (
                        <div>
                            {this.state.illegalPassengers!.map((item: Booking) =>
                                <PassengerBookingItem booking={item}
                                                      shuttleInstanceId={this.shuttleLegInstance!.id}
                                                      legal={false}
                                                      reload={() => this.reloadAsync()}
                                />
                            )}
                        </div>

                    )
                }

                {
                    (this.legalPassengers) &&
                    (
                        <div>
                            {this.state.legalPassengers!.map((item: Booking) =>
                                <PassengerBookingItem booking={item}
                                                      shuttleInstanceId={this.shuttleLegInstance!.id}
                                                      legal={true}
                                                      reload={() => this.reloadAsync()}
                                />
                            )}
                        </div>
                    )
                }

                {
                    ((!this.legalPassengers) && (!this.illegalPassengers)) &&
                    (
                        <span>{Localizer.passengersOnBoardPageNoTickets}</span>
                    )
                }

            </div>

        );
    }

    public render(): React.ReactNode {
        const today: Date = new Date();

        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.passengersOnBoard, this.mobile && styles.mobile)}
                           alertClassName={boutStyles.alert}
            >
                <PageHeader className={styles.header} title={Localizer.passengersOnBoardPageTitle}/>

                <div>

                    {
                        (this.shuttle) &&
                        (
                            <>

                                <div className={styles.shuttleInfo}>

                                    <span> <mark>{LocalizationString.value(this.shuttle.name)}</mark> </span>

                                </div>


                                {
                                    (this.shuttleLegInstance)
                                        ? this.renderPassengersBookingInfo()
                                        :
                                        <div className={styles.noTickets}>

                                            <div>{"{0:d}".format(today)}</div>

                                            <div>{Localizer.passengersOnBoardPageNoRide}</div>

                                        </div>
                                }

                            </>
                        )
                    }
                </div>

            </PageContainer>
        );
    }
}