import React from "react";
import {BaseComponent, IBaseComponentProps} from "@reapptor-apps/reapptor-react-common";

import skeletonStyles from "../Skeleton.module.scss";
import styles from "./SkeletonRectangle.module.scss";

export interface ISkeletonRectangleProps extends IBaseComponentProps {
    width?: number | string;
    height?: number | string;
}

export default class SkeletonRectangle extends BaseComponent<ISkeletonRectangleProps> {

    public render(): React.ReactNode {

        const inlineStyles: React.CSSProperties = {
            width: this.props.width,
            height: this.props.height,
        };
        
        return (
            <div id={this.id} className={this.css(skeletonStyles.skeletonItem, styles.skeletonRectangle, this.props.className)} style={inlineStyles}>
            </div>
        )
    }
}