import React from "react";
import BasePasswordPage from "./BasePasswordPage";
import AppController from "@/pages/AppController";
import Localizer from "../../localization/Localizer";

export default class ChangePassword extends BasePasswordPage {

    public getTitle(): string {
        return AppController.getFullPageTitle(Localizer.changePasswordPageTitle);
    }
    
}