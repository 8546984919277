import {ApiProvider, ch, PageRoute, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {IWizardStep, IWizardSteps} from "@reapptor-apps/reapptor-react-components";
import User from "@/models/server/User";
import PageDefinitions from "@/providers/PageDefinitions";
import Comparator from "@/helpers/Comparator";
import CruisePackageWizardContext from "@/pages/Mobile/CruisePackageWizard/CruisePackageWizardContext";
import AppConstants from "@/helpers/AppConstants";
import UserContext from "@/models/server/UserContext";
import Area from "@/models/server/bout/Area";
import Country from "@/models/server/bout/Country";
import {IWizardNextStep} from "@/models/IWizardNextStep";
import ListCruisePackagesRequest from "@/models/server/requests/ListCruisePackagesRequest";
import CruisePackage from "@/models/server/cruise/CruisePackage";
import {IPagedList} from "@reapptor-apps/reapptor-toolkit";
import GetCruisePackageAvailabilityRequest from "@/models/server/requests/GetCruisePackageAvailabilityRequest";
import GetCruisePackageAvailabilityResponse from "@/models/server/responses/GetCruisePackageAvailabilityResponse";
import ApplicationContext from "@/models/server/ApplicationContext";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

class CruisePackageWizardController {
    private _key: string | null = null;
    private _wizard: CruisePackageWizardContext | null = null;

    private get key(): string {
        if (this._key == null) {
            this._key = `${AppConstants.applicationName}.${ch.getSessionId()}.${AppConstants.name}`;
        }
        return this._key!;
    }

    private indexOfCurrentStep(route: PageRoute): number {
        const wizard: IWizardSteps | null = this.getSteps();
        return (wizard)
            ? wizard.steps.findIndex(step => Comparator.isEqual(step.route, route))
            : -1;
    }
    
    public readonly initialRoute: PageRoute = PageDefinitions.cruisePackageWizardCountryStepRoute;

    public async authorizeAsync(): Promise<void> {
        this._key = null;
    }
    
    public async startAsync(): Promise<void> {

        const request = new GetCruisePackageAvailabilityRequest();
        
        const response: GetCruisePackageAvailabilityResponse = await ApiProvider.postAsync("/api/cruisePackageWizard/getCruisePackageAvailability", request);

        this._wizard = new CruisePackageWizardContext();
        this._wizard.availability = response;

        this.save();
        
        const steps: IWizardSteps | null = this.getSteps();
        
        const route: PageRoute = steps?.steps?.firstOrDefault()?.route ?? this.initialRoute;

        await PageRouteProvider.redirectAsync(route);
    }

    public async completeActionAsync(): Promise<void> {
        // Process wizard actions

        this._wizard = new CruisePackageWizardContext();

        this.save();
    }

    public async requestCruisePackageAsync(cruisePackage: CruisePackage): Promise<void> {
        this.wizard.cruisePackage = cruisePackage;
        
        this.save();

        await PageRouteProvider.redirectAsync(PageDefinitions.cruisePackageWizardOrderStepRoute);
    }
    
    public async onLogoClickAsync(): Promise<void> {
        const route: PageRoute = ((ch.isAuthenticated) && (ch.getUser<User>().isAdmin))
            ? PageDefinitions.dashboardRoute
            : PageDefinitions.mobileHomeRoute;
        
        await PageRouteProvider.redirectAsync(route, false, true);
    }
    
    public async listCountriesAsync():Promise<Country[]> {
        return AppController.listCountriesAsync(null, true, true);
    }

    public async listAreasAsync(country?: string | null): Promise<Area[]> {
        return AppController.listAreasAsync(null, country || "fi", true, true);
    }

    public async listCruisePackagesAsync(countryId?: string | null, areaId?: string | null, activeOnly: boolean = true): Promise<CruisePackage[]> {
        const request = new ListCruisePackagesRequest();
        request.countryId = (countryId) && (!areaId) ? countryId : null;
        request.areaIds = (areaId) ? [areaId] : null;
        request.pageSize = 100;
        request.activeOnly = activeOnly;

        const response: IPagedList<CruisePackage> = await ApiProvider.postAsync("/api/cruisePackageWizard/listCruisePackages", request);

        return response.items;
    }
    
    public save(): void {
        const json: string = JSON.stringify(this.wizard);
        window.localStorage.setItem(this.key, json);
    }

    public get wizard(): CruisePackageWizardContext {
        if (this._wizard === null) {
            const json: string | null = window.localStorage.getItem(this.key);
            this._wizard = (json)
                ? CruisePackageWizardContext.parse(json!)
                : new CruisePackageWizardContext();
        }

        return this._wizard!;
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    public get user(): User {
        return ch.getUser();
    }

    public getCountriesCount(): number {
        return GetCruisePackageAvailabilityResponse.getCountriesCount(this.wizard.availability);
    }

    public getAreasCount(countryId: string | null | undefined): number {
        return GetCruisePackageAvailabilityResponse.getAreasCount(this.wizard.availability, countryId);
    }
    
    public clear(): void {
        this._wizard = new CruisePackageWizardContext();
        
        // const token: CrossAuthSyncUserTokenData | null = this.getCrossAuthSyncUserTokenData();
        // if (token) {
        //     this.wizard.email = token.Email;
        //     this.wizard.phone = token.Phone;
        //     this.wizard.name = `${token.Firstname || ""} ${token.LastName || ""}`.trim();
        //     this.wizard.agreementAccepted = true;
        //     this.wizard.registrationAccepted = true;
        //     this.wizard.cancellationPolicyAccepted = true;
        // }

        this.save();
    }
    
    // public getCrossAuthSyncUserTokenData(): CrossAuthSyncUserTokenData | null {
    //     const context: ApplicationContext = ch.getContext();
    //     const tokenData: CrossAuthSyncUserTokenData | null = (context as any).tokenData as (CrossAuthSyncUserTokenData | null);
    //     return (tokenData?.IsCrossAuthSyncUserTokenData)
    //         ? tokenData
    //         : null;
    // }

    public getReturnUrl(): string | null {
        const context: ApplicationContext = ch.getContext();
        const returnUrl = (context as any).returnUrl as (string | null);
        return (!!returnUrl)
            ? returnUrl
            : null;
    }
    
    private async invokeCloseAsync(): Promise<void> {
        const returnUrl: string | null = this.getReturnUrl();
        if (returnUrl) {
            ch.postToken(returnUrl);
        } else {
            //window.location.href = CruiseConstants.companyFrontPage;
            await PageRouteProvider.redirectAsync(PageDefinitions.mobileDashboardRoute, false, true);
        }
    }
    
    public hasReturnUrl(): boolean {
        const returnUrl: string | null = this.getReturnUrl();
        return (!!returnUrl);
    }
    
    public async closeAsync(): Promise<void> {
        await ApiProvider.invokeWithForcedSpinnerAsync(async () => this.invokeCloseAsync(), true);
    }

    public getSteps(): IWizardSteps | null {
        const countryStep: IWizardStep = {route: PageDefinitions.cruisePackageWizardCountryStepRoute, title: Localizer.cruisePackageWizardCountryStepTitle};
        const areaStep: IWizardStep = {route: PageDefinitions.cruisePackageWizardAreaStepRoute, title: Localizer.cruisePackageWizardAreaStepTitle};
        const searchStep: IWizardStep = {route: PageDefinitions.cruisePackageWizardSearchStepRoute, title: Localizer.cruisePackageWizardSearchStepTitle};
        const orderStep: IWizardStep = {route: PageDefinitions.cruisePackageWizardOrderStepRoute, title: Localizer.cruisePackageWizardOrderStepTitle};
        const approveStep: IWizardStep = {route: PageDefinitions.cruisePackageWizardApproveStepRoute, title: Localizer.cruisePackageWizardApproveStepTitle};
        
        const countries: number = this.getCountriesCount();
        const areas: number = this.getAreasCount(this.wizard.country?.id);
        
        const steps: IWizardStep[] = [];
        
        if (countries != 1) {
            steps.push(countryStep);
        }
        if (areas != 1) {
            steps.push(areaStep);
        }

        steps.push(searchStep, orderStep, approveStep);
        
        return {steps: steps};
    }
    
    public async getPrevStepAsync(route: PageRoute): Promise<PageRoute> {
        const index: number = this.indexOfCurrentStep(route);
        const steps: IWizardStep[] = this.getSteps()!.steps;
        if ((index === -1) || (index === 0)) {
            return this.wizard.actionInitialPageRoute || this.initialRoute;
        }
        return steps[index - 1].route;
    }

    public async getNextStepAsync(route: PageRoute): Promise<IWizardNextStep> {
        const index: number = this.indexOfCurrentStep(route);
        const steps: IWizardStep[] = this.getSteps()!.steps;
        const firstStep: boolean = (index === -1) && (steps.length > 0);
        if (firstStep) {
            return {nextRoute: steps[0].route, firstStep: true, lastStep: false};
        }
        const lastStep: boolean = (index === -1) || (index === steps.length - 1);
        if (lastStep) {
            const wizard: CruisePackageWizardContext = this.wizard;
            const nextRoute: PageRoute | null = (!Comparator.isEqualPageRoute(wizard.actionInitialPageRoute, route))
                ? wizard.actionInitialPageRoute
                : null;
            return {nextRoute: nextRoute || this.initialRoute, firstStep: false, lastStep: true};
        }
        return {nextRoute: steps[index + 1].route, firstStep: false, lastStep: false};
    }
}

//Singleton
export default new CruisePackageWizardController();