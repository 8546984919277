import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import {
    Button,
    ButtonType,
    IconSize,
    Inline,
    JustifyContent,
    PageContainer,
    PageHeader,
    PageRow, TextInput,
    ToolbarContainer
} from "@reapptor-apps/reapptor-react-components";
import PageDefinitions from "@/providers/PageDefinitions";
import {PageRoute, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import ServiceProvider from "@/models/server/ServiceProvider";
import ServiceProviderContainer from "@/pages/ServiceProviders/ServiceProviderContainer/ServiceProviderContainer";
import Localizer from "@/localization/Localizer";

import styles from "./ServiceProviders.module.scss";
import ListServiceProvidersRequest from "@/models/server/requests/ListServiceProvidersRequest";
import ListServiceProvidersResponse from "@/models/server/responses/ListServiceProvidersResponse";

interface IServiceProvidersProps {
}

interface IServiceProvidersState {
    initialized: boolean;
    serviceProviders: ServiceProvider[];
    search: string;
}

export default class ServiceProviders extends AuthorizedPage<IServiceProvidersProps, IServiceProvidersState> {

    state: IServiceProvidersState = {
        initialized: false,
        serviceProviders: [],
        search: "",
    };

    private async setSearchAsync(search: string): Promise<void> {
        if (this.state.search != search) {
            await this.setState({search});
        }
    }

    private async addServiceProviderAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.serviceProviderManagementRoute);
    }

    private async openServiceProviderAsync(serviceProviderId: string): Promise<void> {
        const route: PageRoute = PageDefinitions.serviceProviderManagement(serviceProviderId);
        await PageRouteProvider.redirectAsync(route);
    }

    private async reloadAsync(): Promise<void> {
        this.state.search = "";

        const request = new ListServiceProvidersRequest();

        const response: ListServiceProvidersResponse = await this.postAsync("/api/serviceProvider/listServiceProviders", request);

        await this.setState({serviceProviders: response.serviceProviders ?? [], initialized: true});
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.reloadAsync();
    }

    public get serviceProviders(): ServiceProvider[] {
        return this.state.serviceProviders;
    }

    public getTitle(): string {
        return Localizer.serviceProvidersPageTitle;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={this.css(styles.serviceProviders)}>
                
                <PageHeader title={this.getTitle()}/>
                
                <PageRow>
                    <div className="col">

                        <div className={styles.container}>

                            <ToolbarContainer className={styles.toolbar}>

                                <Inline justify={JustifyContent.Center} className={styles.inline}>

                                    <TextInput inline small autoFocus
                                               id="serviceProvider_search"
                                               className={styles.search}
                                               placeholder={Localizer.genericSearch}
                                               value={this.state.search}
                                               onChange={(sender, value) => this.setSearchAsync(value)}
                                    />

                                    <Button title={Localizer.genericReload} className="ml-1"
                                            icon={{name: "far history", size: IconSize.Large}}
                                            type={ButtonType.Info}
                                            onClick={() => this.reloadAsync()}
                                    />

                                    <Button title={Localizer.serviceProvidersPageAddServiceProvider}
                                            icon={{name: "plus", size: IconSize.Large}}
                                            type={ButtonType.Orange}
                                            onClick={() => this.addServiceProviderAsync()}
                                    />

                                    <Button title={Localizer.genericBack}
                                            icon={{name: "fas arrow-alt-circle-left"}}
                                            type={ButtonType.Primary}
                                            route={PageDefinitions.adminRoute}
                                    />

                                </Inline>

                            </ToolbarContainer>

                            {
                                (this.state.initialized) &&
                                (
                                    <ServiceProviderContainer serviceProviders={this.serviceProviders}
                                                              search={this.state.search}
                                                              onClick={(sender, item) => this.openServiceProviderAsync(item.id!)}
                                    />
                                )
                            }

                        </div>

                    </div>
                </PageRow>
                
            </PageContainer>
        );
    }
}