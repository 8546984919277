import React from "react";
import {Button, ButtonType, Checkbox, DateInput, Form, InlineType, Modal} from "@reapptor-apps/reapptor-react-components";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import CaptainAvailableDate from "@/models/server/bout/CaptainAvailableDate";
import Localizer from "@/localization/Localizer";

import styles from "./AvailableDateModal.module.scss";
import mobileStyles from "../../mobile.module.scss";

export interface IAvailableDateModalProps {
    defaultStartTime: Date;
    defaultEndTime: Date;
    onSave(captainDate: CaptainAvailableDate): Promise<void>;
}

interface IAvailableDateModalState {
    captainDate: CaptainAvailableDate;
    holiday: boolean;
    startTime: Date | null;
    endTime: Date | null;
}

export default class AvailableDateModal extends BaseComponent<IAvailableDateModalProps> {

    state: IAvailableDateModalState = {
        captainDate: new CaptainAvailableDate(),
        holiday: false,
        startTime: null,
        endTime: null,
    }

    private readonly _modalRef: React.RefObject<Modal<CaptainAvailableDate>> = React.createRef();

    private get modal(): Modal<CaptainAvailableDate> {
        return this._modalRef.current!;
    }

    private get defaultStartTime(): Date {
        return this.props.defaultStartTime;
    }

    private get defaultEndTime(): Date {
        return this.props.defaultEndTime;
    }

    private get startTime(): Date {
        return this.state.startTime ?? this.defaultStartTime;
    }

    private get endTime(): Date | null {
        return this.state.endTime ?? this.defaultEndTime;
    }

    private get date(): Date {
        return this.captainDate.date;
    }

    private get captainDate(): CaptainAvailableDate {
        return this.state.captainDate;
    }

    private get holiday(): boolean {
        return this.state.holiday;
    }

    private async setHolidayAsync(holiday: boolean): Promise<void> {
        if (holiday) {
            await this.setState({ holiday, startTime: null, endTime: null });
        } else {
            await this.setState({ holiday, startTime: this.defaultStartTime, endTime: this.defaultEndTime });
        }
    }
    
    private async setStartTimeAsync(startTime: Date): Promise<void> {
        await this.setState({ startTime });
    }
    
    private async setEndTimeAsync(endTime: Date): Promise<void> {
        await this.setState({ endTime });
    }
    
    private async saveAsync(): Promise<void> {
        this.captainDate.holiday = this.holiday;
        this.captainDate.startTime = this.startTime;
        this.captainDate.endTime = this.endTime;
        await this.props.onSave(this.captainDate);
        
        await this.closeAsync();
    }
    
    public async openAsync(captainDate: CaptainAvailableDate): Promise<void> {
        this.state.captainDate = captainDate;
        this.state.holiday = captainDate.holiday;
        this.state.startTime = this.state.captainDate.startTime;
        this.state.endTime = this.state.captainDate.endTime;
        
        await this.modal?.openAsync(captainDate);
        
        await this.reRenderAsync();
    }
    
    public async closeAsync(): Promise<void> {
        await this.modal?.closeAsync();
    }

    public render(): React.ReactNode {
        return (
            <Modal notResponsive noHeader
                   ref={this._modalRef}
                   className={this.css(styles.availableDateModal, this.mobile && mobileStyles.mobile, this.mobile && styles.mobile)}
                   bodyClassName={styles.body}
            >
                
                <span className={styles.title}>{"{0:dddd} {0:d}".format(this.date)}</span>

                <Form className={styles.container}>

                    <Checkbox inline
                              inlineType={InlineType.Right}
                              className={styles.holiday}
                              label={Localizer.mobileAviableDateModalCheckboxHolidayLabel}
                              value={this.holiday}
                              onChange={(sender, value: boolean) => this.setHolidayAsync(value)}
                    />

                    <DateInput showTime showOnlyTime
                               timeIntervals={15}
                               className={styles.input}
                               minDate={Utility.now()}
                               readonly={this.holiday}
                               value={this.startTime ?? this.props.defaultStartTime}
                               onChange={(value: Date) => this.setStartTimeAsync(value)}
                    />

                    <DateInput showTime showOnlyTime
                               timeIntervals={15}
                               className={styles.input}
                               minDate={Utility.now()}
                               readonly={this.holiday}
                               value={this.endTime}
                               onChange={(value: Date) => this.setEndTimeAsync(value)}
                    />

                    <div className={styles.buttons}>

                        <Button block
                                label={Localizer.genericSave}
                                icon={{name: "far save"}}
                                type={ButtonType.Dark}
                                onClick={() => this.saveAsync()}
                        />

                        <Button block
                                label={Localizer.genericCancel}
                                icon={{name: "far ban"}}
                                type={ButtonType.Primary}
                                onClick={() => this.closeAsync()}
                        />

                    </div>

                </Form>

            </Modal>
        )
    }
}