// autogenerated
import {GeoCoordinate} from "@reapptor-apps/reapptor-toolkit";
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class GetOngoingShuttlesRequest extends ETagRequest {

    public location: GeoCoordinate | null = null;

    public readonly isGetOngoingShuttlesRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof GetOngoingShuttlesRequest) || ((from as any).isGetOngoingShuttlesRequest === true));
    }

    public static as(from?: object | null): GetOngoingShuttlesRequest | null {
        return (GetOngoingShuttlesRequest.is(from)) ? from as GetOngoingShuttlesRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}