import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";

import styles from "./RevenueWidget.module.scss";

export interface IRevenueWidgetProps {
    className?: string;
    unpaid?: boolean;
    title: string;
    subtitle: string;
}

export default class RevenueWidget extends BaseComponent<IRevenueWidgetProps> {

    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(styles.revenueWidget)}>
                <div className={styles.revenueInfoContainer}>

                    <div className={styles.description}>
                        <div>
                            <p>{this.props.title}</p>
                            <p>{this.props.subtitle}</p>
                        </div>
                    </div>

                    <div className={styles.capacity}>
                        <div>
                            <span className={this.css(this.props.unpaid ? styles.unpaid : styles.paid, "fal fa-chart-line fa-3x")}/>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}