// autogenerated
import {StripeKycState} from "@/models/Enums";
import StripeKycStatusItem from "@/models/server/stripe/kycstatus/StripeKycStatusItem";

export default class StripeKycStatus {

    public state: StripeKycState = StripeKycState.NotStarted;

    public errorItems: StripeKycStatusItem[] | null = null;

    public pendingVerificationItems: StripeKycStatusItem[] | null = null;

    public currentlyDueDocumentItems: StripeKycStatusItem[] | null = null;

    public pastDueDocumentItems: StripeKycStatusItem[] | null = null;

    public accountConnected: string | null = null;

    public exception: string | null = null;

    public disabledReason: string | null = null;

    public startedAt: Date | null = null;

    public verifiedAt: Date | null = null;

    public readonly isStripeKycStatus: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof StripeKycStatus) || ((from as any).isStripeKycStatus === true));
    }

    public static as(from?: object | null): StripeKycStatus | null {
        return (StripeKycStatus.is(from)) ? from as StripeKycStatus : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    public static toJsonResult(status: StripeKycStatus): string {
        const data: any = {
            state: StripeKycState[status.state],
            errorItems: status.errorItems,
            pendingVerificationItems: status.pendingVerificationItems,
            currentlyDueDocumentItems: status.currentlyDueDocumentItems,
            pastDueDocumentItems: status.pastDueDocumentItems,
            accountConnected: status.accountConnected,
            exception: status.exception,
            disabledReason: status.disabledReason,
        };

        let json: string = JSON.stringify(data, null, 4);
        
        if (json.startsWith('{') && json.endsWith('}')) {
            json = json.substring(1, json.length - 1);
        }

        const result: string = json.replace(/"/g, '');

        return result;
    }

    // #endregion
}