import React from "react";
import {BaseComponent, ch, DialogResult} from "@reapptor-apps/reapptor-react-common";
import Booking from "@/models/server/bout/Booking";
import {Icon} from "@reapptor-apps/reapptor-react-components";
import Localizer from "@/localization/Localizer";
import ScanTicketRequest from "@/models/server/requests/ScanTicketRequest";

import styles from "./PassengerBookingItem.module.scss";
import {NotificationType} from "@/models/Enums";
import SendPassengerCheckOutNotificationRequest from "@/models/server/requests/SendPassengerCheckOutNotificationRequest";
import ManuallyCheckOutPassengerRequest from "@/models/server/requests/ManuallyCheckOutPassengerRequest";

interface IPassengerBookingItemProps {
    className?: string;
    booking: Booking;
    shuttleInstanceId: string;
    legal: boolean;

    reload(): Promise<void>;
}

interface IPassengerBookingItemState {
    expanded: boolean;
}

export default class PassengerBookingItem extends BaseComponent<IPassengerBookingItemProps, IPassengerBookingItemState> {

    public state: IPassengerBookingItemState = {
        expanded: false
    }

    public async toggleAsync(): Promise<void> {
        await this.setState({expanded: !this.state.expanded});
    }

    public async checkOutAsync(booking: Booking): Promise<void> {
        const confirmed: boolean = await ch.confirmAsync(Localizer.passengerBookingItemCheckOut.format(booking.passenger!.fullName));

        if (confirmed) {
            const request = new ManuallyCheckOutPassengerRequest();
            request.bookingId = booking.id;
            request.shuttleInstanceLegId = this.props.shuttleInstanceId;

            await this.postAsync("/api/mobileApp/manuallyCheckOutPassenger", request);
            
            await ch.alertMessageAsync(Localizer.passengerBookingItemCheckOut, true, true);
            
            await this.props.reload();
        }

    }

    public async callAsync(phone: string): Promise<void> {
        ch.callTo(phone);
    }

    public async sendSmsAsync(passengerId: string): Promise<void> {
        await this.sendNotificationAsync(passengerId, NotificationType.Sms);
    }

    public async sendPushAsync(passengerId: string): Promise<void> {
        await this.sendNotificationAsync(passengerId, NotificationType.Push);
    }

    private async sendNotificationAsync(passengerId: string, notificationType: NotificationType): Promise<void> {
        const message: string = Localizer.passengerBookingItemFollowCheckOut;

        const confirmed: boolean = await ch.confirmAsync(Localizer.passengerBookingItemNotificationSendConfirmation.format(message));

        if (confirmed) {
            const request = new SendPassengerCheckOutNotificationRequest();
            request.passengerId = passengerId;
            request.notificationType = notificationType;
            request.message = message;

            await this.postAsync("/api/mobileApp/sendPassengerCheckOutNotification", request);
        }
    }

    public get booking(): Booking {
        return this.props.booking;
    }

    public get legal(): boolean {
        return this.props.legal;
    }

    public get expanded(): boolean {
        return this.state.expanded;
    }

    public get childrenCount(): number {
        return this.booking.children;
    }

    public get bikesAndTrolleys(): number {
        return this.booking.bikesAndTrolleys;
    }

    public get pensionersCount(): number {
        return this.booking.pensioners;
    }

    public get passengersCount(): number {
        return this.booking.passengers;
    }

    public get adultsCount(): number {
        return (this.passengersCount - this.pensionersCount - this.childrenCount - this.bikesAndTrolleys);
    }

    public get hasPushUp(): boolean {
        return (this.booking.passenger!.hasPushUpNotifications);
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id}
                 className={this.css(this.props.className, styles.passengerBookingItem, this.mobile && styles.mobile)}
            >
                {
                    (this.booking.passenger) &&
                    (
                        <div>

                            <div className={this.css(styles.ticketShort, (this.legal) ? styles.legal : styles.illegal)}
                                 onClick={() => this.toggleAsync()}
                            >

                                <div className={this.css(styles.col1)}>

                                    <div className={this.css(styles.ticket)}>
                                        <Icon name={"fas fa-sign-out"}/>
                                        <span className={styles.route}>{"{0}".format(this.booking.waypoint!.destination!.name)}</span>
                                    </div>

                                    <div className={styles.passenger}>
                                        <Icon name={"far fa-user"}/>
                                        <span>{this.booking.passenger!.firstname}</span>
                                        <span>{this.booking.passenger!.lastName}</span>
                                    </div>

                                </div>

                                <div className={this.css(styles.col2)}>

                                    <div>

                                        <Icon className={styles.checkOut} name={"far fa-users"}/>

                                        <span className={styles.importantInfo}>

                                            {this.adultsCount}

                                            {
                                                (this.pensionersCount > 0) &&
                                                (
                                                    <span className={styles.pensioners}> + {this.pensionersCount}</span>
                                                )
                                            }

                                            {
                                                (this.childrenCount > 0) &&
                                                (
                                                    <span className={styles.children}> + {this.childrenCount}</span>
                                                )
                                            }

                                            {
                                                (this.bikesAndTrolleys > 0) &&
                                                (
                                                    <span className={styles.bikesAndTrolleys}> + {this.bikesAndTrolleys}</span>
                                                )
                                            }

                                        </span>

                                    </div>

                                    <div>

                                        <Icon className={styles.checkOut} name={"fal fa-qrcode"}/>

                                        <span>{"{0:t}-{1:t}".format(this.booking.departureAt, this.booking.arrivalAt)}</span>

                                    </div>

                                </div>

                            </div>

                            {
                                (this.expanded) &&
                                (
                                    <div className={styles.ticketExpanded}>

                                        <div className={styles.row1}>

                                            <div>

                                                <Icon name={"fas fa-envelope"}/>

                                                <span>{this.booking.passenger!.email}</span>

                                            </div>

                                            <div>

                                                <Icon name={"fas fa-route"}/>

                                                <span className={styles.route}>{"{0} -> {1}".format(this.booking.waypoint!.source!.name, this.booking.waypoint!.destination!.name)}</span>

                                            </div>

                                            {
                                                (this.booking.passenger!.phone) &&
                                                (
                                                    <div>

                                                        <Icon name={"fas fa-phone"}/>

                                                        <span>{this.booking.passenger!.phone}</span>

                                                    </div>
                                                )
                                            }

                                        </div>


                                        <div className={styles.row2}>

                                            {
                                                (this.booking.passenger?.phone) &&
                                                (
                                                    <Icon className={styles.call}
                                                          name={"fas fa-phone"}
                                                          onClick={() => this.callAsync(this.booking.passenger!.phone!)}
                                                    />

                                                )
                                            }

                                            {
                                                (this.booking.passenger?.phone) &&
                                                (
                                                    <Icon className={styles.sms}
                                                          name={"fas fa-sms"}
                                                          onClick={() => this.sendSmsAsync(this.booking.passengerId)}
                                                    />

                                                )
                                            }

                                            {
                                                (this.hasPushUp) &&
                                                (
                                                    <Icon className={styles.push}
                                                          name={"fas fa-bell-on"}
                                                          onClick={() => this.sendPushAsync(this.booking.passengerId)}
                                                    />
                                                )
                                            }

                                            <Icon className={styles.checkOut}
                                                  name={"fas fa-sign-out"}
                                                  onClick={() => this.checkOutAsync(this.booking!)}
                                            />

                                        </div>

                                    </div>
                                )
                            }

                        </div>
                    )
                }

            </div>
        )
    }
}