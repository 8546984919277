import {PageRoute, BasePageDefinitions, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {IUserManagementProps} from "@/pages/UserManagement/UserManagement";
import {IServicePointsProps, ServicePointsTab} from "@/pages/ServicePointsManagement/ServicePointsManagement";
import EstimatedBooking from "@/models/server/bout/EstimatedBooking";
import {IBookingDetailsProps} from "@/pages/Mobile/BookingDetails/BookingDetails";
import {IEditCreditCardProps} from "@/pages/Mobile/EditCreditCard/EditCreditCard";
import {IEditBankAccountProps} from "@/pages/Mobile/EditBankAccount/EditBankAccount";
import Booking from "@/models/server/bout/Booking";
import {IMyTripsProps} from "@/pages/Mobile/MyTrips/MyTrips";
import {IEditBoatProps} from "@/pages/Mobile/EditBoat/EditBoat";
import {IDashboardParameters} from "@/pages/Mobile/Dashboard/Dashboard";
import {IHomeProps} from "@/pages/Mobile/Home/Home";
import {ICruisePackageDetailsProps} from "@/pages/CruisePackageDetails/CruisePackageDetails";
import CruisePackageBooking from "@/models/server/cruise/CruisePackageBooking";
import {IBoatSelectionParameters} from "@/pages/Mobile/BoatSelection/BoatSelection";
import Waypoint from "@/models/server/bout/Waypoint";
import {ISignUpProps} from "@/pages/SignUp/SignUp";

export default class PageDefinitions extends BasePageDefinitions {

    protected async require(pageContainer: string, pageName: string): Promise<any> {
        return await require(`../pages/${pageContainer}${pageName}/${pageName}`);
    }

    constructor() {
        super();
        PageRouteProvider.sendParametersOnRedirect = true;
    }

    public static readonly accountRouteName: string = "Account";

    public static readonly accountRoute: PageRoute = new PageRoute(PageDefinitions.accountRouteName);

    public static readonly adminRouteName: string = "Admin";

    public static readonly adminRoute: PageRoute = new PageRoute(PageDefinitions.adminRouteName);

    public static readonly dashboardRouteName: string = "Dashboard";

    public static readonly dashboardRoute: PageRoute = new PageRoute(PageDefinitions.dashboardRouteName);

    public static readonly changePasswordRouteName: string = "ChangePassword";

    public static readonly changePasswordRoute: PageRoute = new PageRoute(PageDefinitions.changePasswordRouteName);

    public static readonly forgotPasswordRouteName: string = "ForgotPassword";

    public static readonly forgotPasswordRoute: PageRoute = new PageRoute(PageDefinitions.forgotPasswordRouteName);

    public static readonly resetPasswordRouteName: string = "ResetPassword";

    public static readonly resetPasswordRoute: PageRoute = new PageRoute(PageDefinitions.resetPasswordRouteName);

    public static readonly homeRouteName: string = "Home";

    public static readonly homeRoute: PageRoute = new PageRoute(PageDefinitions.homeRouteName);

    public static readonly signUpRouteName: string = "SignUp";

    public static readonly signUpRoute: PageRoute = new PageRoute(PageDefinitions.signUpRouteName);

    public static readonly signUp: (asPassenger: true) => PageRoute = (asPassenger: true) => new PageRoute(PageDefinitions.signUpRouteName, null, null, {asPassenger} as ISignUpProps);
    
    public static readonly userManagementRouteName: string = "UserManagement";

    public static readonly userManagementRoute: PageRoute = new PageRoute(PageDefinitions.userManagementRouteName);

    public static readonly userManagement: (roleName: string) => PageRoute = (roleName: string) => new PageRoute(PageDefinitions.userManagementRouteName, null, null, {roleName} as IUserManagementProps);

    public static readonly applicationSettingsRouteName: string = "ApplicationSettings";

    public static readonly applicationSettingsRoute: PageRoute = new PageRoute(PageDefinitions.applicationSettingsRouteName);

    public static readonly adminConsoleRouteName: string = "AdminConsole";

    public static readonly adminConsoleRoute: PageRoute = new PageRoute(PageDefinitions.adminConsoleRouteName);

    public static readonly ticketsRouteName: string = "Tickets";

    public static readonly ticketsRoute: PageRoute = new PageRoute(PageDefinitions.ticketsRouteName);

    public static readonly bookingsRouteName: string = "Bookings";

    public static readonly bookingsRoute: PageRoute = new PageRoute(PageDefinitions.bookingsRouteName);

    public static readonly boatsRouteName: string = "Boats";

    public static readonly boatsRoute: PageRoute = new PageRoute(PageDefinitions.boatsRouteName);

    public static readonly bookingInfoRouteName: string = "BookingInfo";

    public static readonly bookingInfoRoute: PageRoute = new PageRoute(PageDefinitions.bookingInfoRouteName);

    public static readonly bookingInfo: (bookingId: string) => PageRoute = (bookingId: string) => new PageRoute(PageDefinitions.bookingInfoRouteName, null, bookingId);

    public static readonly servicePointsManagementRouteName: string = "ServicePointsManagement";

    public static readonly servicePointsManagement: (tab: ServicePointsTab) => PageRoute = (tab: ServicePointsTab) => new PageRoute(PageDefinitions.servicePointsManagementRouteName, null, null, {tab} as IServicePointsProps);

    public static readonly servicePointsRoute: PageRoute = new PageRoute(PageDefinitions.servicePointsManagementRouteName);

    public static readonly priceManagementRouteName: string = "PriceManagement";

    public static readonly priceManagementRoute: PageRoute = new PageRoute(PageDefinitions.priceManagementRouteName);

    public static readonly countriesRouteName: string = "Countries";

    public static readonly countriesRoute: PageRoute = new PageRoute(PageDefinitions.countriesRouteName);

    public static readonly countryManagementRouteName: string = "CountryManagement";

    public static readonly countryManagementRoute: PageRoute = new PageRoute(PageDefinitions.countryManagementRouteName);

    public static readonly countryManagement: (countryId: string) => PageRoute = (countryId: string) => new PageRoute(PageDefinitions.countryManagementRouteName, null, countryId);

    public static readonly serviceProvidersRouteName: string = "ServiceProviders";
    
    public static readonly serviceProvidersRoute: PageRoute = new PageRoute(PageDefinitions.serviceProvidersRouteName);
    
    public static readonly serviceProviderManagementRouteName: string = "ServiceProviderManagement";

    public static readonly serviceProviderManagementRoute: PageRoute = new PageRoute(PageDefinitions.serviceProviderManagementRouteName);

    public static readonly serviceProviderManagement: (serviceProviderId: string) => PageRoute = (serviceProviderId: string) => new PageRoute(PageDefinitions.serviceProviderManagementRouteName, null, serviceProviderId);

    public static readonly cruisePackagesManagementRouteName: string = "CruisePackagesManagement";

    public static readonly cruisePackagesManagementRoute: PageRoute = new PageRoute(PageDefinitions.cruisePackagesManagementRouteName);

    public static readonly cruisePackageManagementRouteName: string = "CruisePackageManagement";

    public static readonly cruisePackageManagementRoute: PageRoute = new PageRoute(PageDefinitions.cruisePackageManagementRouteName);

    public static readonly cruisePackageManagement: (cruisePackageId: string) => PageRoute = (cruisePackageId: string) => new PageRoute(PageDefinitions.cruisePackageManagementRouteName, null, cruisePackageId);

    public static readonly cruisePackageDetailsRouteName: string = "CruisePackageDetails";

    public static readonly cruisePackageDetailsRoute: PageRoute = new PageRoute(PageDefinitions.cruisePackageDetailsRouteName);

    public static readonly cruisePackageDetails: (path: string) => PageRoute = (path: string) => new PageRoute(PageDefinitions.cruisePackageDetailsRouteName, null, null, { path: path } as ICruisePackageDetailsProps);

    public static readonly cruisePackagesRouteName: string = "CruisePackages";

    public static readonly cruisePackagesRoute: PageRoute = new PageRoute(PageDefinitions.cruisePackagesRouteName);

    // #region Captain and Passenger Mobile UI

    public static readonly mobileDashboardRouteName: string = "Mobile/Dashboard";

    public static readonly mobileDashboardRoute: PageRoute = new PageRoute(PageDefinitions.mobileDashboardRouteName);
    
    public static readonly mobileHomeRouteName: string = "Mobile/Home";

    public static readonly mobileHomeRoute: PageRoute = new PageRoute(PageDefinitions.mobileHomeRouteName);

    public static readonly mobileHome: (booking: EstimatedBooking) => PageRoute = (booking: EstimatedBooking) => new PageRoute(PageDefinitions.mobileHomeRouteName, null, null, {booking} as IHomeProps);

    public static readonly mobileShuttleRouteName: string = "Mobile/Shuttle";

    public static readonly mobileShuttleRoute: PageRoute = new PageRoute(PageDefinitions.mobileShuttleRouteName);

    public static readonly mobileInspectorRouteName: string = "Mobile/Inspector";

    public static readonly mobileInspectorRoute: PageRoute = new PageRoute(PageDefinitions.mobileInspectorRouteName);

    public static readonly mobileRoleNotAllowedRouteName: string = "Mobile/RoleNotAllowed";

    public static readonly mobileRoleNotAllowedRoute: PageRoute = new PageRoute(PageDefinitions.mobileRoleNotAllowedRouteName);
    
    public static readonly mobileSelectRoleRouteName: string = "Mobile/SelectRole";

    public static readonly mobileSelectRoleRoute: PageRoute = new PageRoute(PageDefinitions.mobileSelectRoleRouteName);
    
    public static readonly myTripsRouteName: string = "Mobile/MyTrips";
    
    public static readonly myTripsAlias: string = "trips";

    public static readonly myTripsRoute: PageRoute = new PageRoute(PageDefinitions.myTripsRouteName);

    public static readonly myTrips: (bookedOnly?: boolean, confirmedOnly?: boolean) => PageRoute = (bookedOnly?: boolean, confirmedOnly?: boolean) => new PageRoute(PageDefinitions.myTripsRouteName, null, null, {bookedOnly, confirmedOnly} as IMyTripsProps);

    public static readonly myPaymentMethodsRouteName: string = "Mobile/MyPaymentMethods";

    public static readonly myPaymentMethodsRoute: PageRoute = new PageRoute(PageDefinitions.myPaymentMethodsRouteName);
    
    public static readonly profileRouteName: string = "Mobile/Profile";

    public static readonly profileRoute: PageRoute = new PageRoute(PageDefinitions.profileRouteName);
    
    public static readonly settingsRouteName: string = "Mobile/Settings";

    public static readonly settingsRoute: PageRoute = new PageRoute(PageDefinitions.settingsRouteName);
    
    public static readonly fAQRouteName: string = "Mobile/Settings/FAQ";

    public static readonly fAQRoute: PageRoute = new PageRoute(PageDefinitions.fAQRouteName);
    
    public static readonly myEarningsRouteName: string = "Mobile/MyEarnings";

    public static readonly myEarningsRoute: PageRoute = new PageRoute(PageDefinitions.myEarningsRouteName);
    
    public static readonly myCalendarRouteName: string = "Mobile/MyCalendar";

    public static readonly myCalendarRoute: PageRoute = new PageRoute(PageDefinitions.myCalendarRouteName);

    public static readonly kycRouteName: string = "Mobile/Kyc";

    public static readonly kycRoute: PageRoute = new PageRoute(PageDefinitions.kycRouteName);
    
    public static readonly myBoatsRouteName: string = "Mobile/MyBoats";

    public static readonly myBoatsRoute: PageRoute = new PageRoute(PageDefinitions.myBoatsRouteName);
    
    public static readonly editBoatRouteName: string = "Mobile/EditBoat";
    
    public static readonly editBoatRoute: PageRoute = new PageRoute(PageDefinitions.editBoatRouteName);
    
    public static readonly editBoat: (boatId: string | null, openHomeLocationModal: boolean) => PageRoute = (boatId: string | null, openHomeLocationModal: boolean) => new PageRoute(PageDefinitions.editBoatRouteName, null, boatId, {openHomeLocationModal} as IEditBoatProps);

    public static readonly boatSelectionRouteName: string = "Mobile/BoatSelection";

    public static readonly boatSelectionRoute: PageRoute = new PageRoute(PageDefinitions.boatSelectionRouteName);

    public static readonly boatSelection: (waypointId: string) => PageRoute = (waypointId: string) => new PageRoute(PageDefinitions.boatSelectionRouteName, null, waypointId);

    public static readonly shuttleSelection: (waypoint: Waypoint) => PageRoute = (waypoint: Waypoint) => new PageRoute(PageDefinitions.boatSelectionRouteName, null, null, { waypoint } as IBoatSelectionParameters);

    public static readonly bookingDetailsRouteName: string = "Mobile/BookingDetails";

    public static readonly bookingDetailsRoute: PageRoute = new PageRoute(PageDefinitions.bookingDetailsRouteName);

    public static readonly bookingDetails: (booking: EstimatedBooking | Booking | CruisePackageBooking) => PageRoute = (booking: EstimatedBooking | Booking | CruisePackageBooking) => new PageRoute(PageDefinitions.bookingDetailsRouteName, null, null, { booking } as IBookingDetailsProps);

    public static readonly bookingDetailsById: (bookingId: string) => PageRoute = (bookingId: string) => new PageRoute(PageDefinitions.bookingDetailsRouteName, null, bookingId);
    
    public static readonly editCreditCardRouteName: string = "Mobile/EditCreditCard";

    public static readonly editCreditCardRoute: PageRoute = new PageRoute(PageDefinitions.editCreditCardRouteName);

    public static readonly editCreditCard: (creditCardId: string | null, required: boolean, returnToBooking: boolean, isExplicitRenew?: boolean) => PageRoute = (creditCardId: string | null, required: boolean, returnToBooking: boolean, isExplicitRenew?: boolean) => new PageRoute(PageDefinitions.editCreditCardRouteName, null, null, { creditCardId, required, returnToBooking, isExplicitRenew } as IEditCreditCardProps);

    public static readonly editBankAccountRouteName: string = "Mobile/EditBankAccount";

    public static readonly editBankAccountRoute: PageRoute = new PageRoute(PageDefinitions.editBankAccountRouteName);

    public static readonly editBankAccount: (bankAccountId?: string | null, required?: boolean, noDeletion?: boolean) => PageRoute = (bankAccountId?: string | null, required?: boolean, noDeletion?: boolean) => new PageRoute(PageDefinitions.editBankAccountRouteName, null, null, { bankAccountId, required, noDeletion } as IEditBankAccountProps);

    public static readonly notificationsRouteName: string = "Mobile/Notifications";

    public static readonly notificationsRoute: PageRoute = new PageRoute(PageDefinitions.notificationsRouteName);
    
    public static readonly passengersOnBoardRouteName: string = "Mobile/PassengersOnBoard";
    
    public static readonly passengersOnBoardRoute: PageRoute = new PageRoute(PageDefinitions.passengersOnBoardRouteName);

    public static readonly passengersOnBoard: (shuttleInstanceId: string | null) => PageRoute = (shuttleInstanceId: string | null) => new PageRoute(PageDefinitions.passengersOnBoardRouteName, null, shuttleInstanceId);

    // #region Cruise Package Wizard

    public static readonly cruisePackageWizardCountryStepRouteName: string = "Mobile/CruisePackageWizard/CountryStep";

    public static readonly cruisePackageWizardCountryStepRoute: PageRoute = new PageRoute(PageDefinitions.cruisePackageWizardCountryStepRouteName);

    public static readonly cruisePackageWizardAreaStepRouteName: string = "Mobile/CruisePackageWizard/AreaStep";

    public static readonly cruisePackageWizardAreaStepRoute: PageRoute = new PageRoute(PageDefinitions.cruisePackageWizardAreaStepRouteName);

    public static readonly cruisePackageWizardSearchStepRouteName: string = "Mobile/CruisePackageWizard/SearchStep";

    public static readonly cruisePackageWizardSearchStepRoute: PageRoute = new PageRoute(PageDefinitions.cruisePackageWizardSearchStepRouteName);

    public static readonly cruisePackageWizardOrderStepRouteName: string = "Mobile/CruisePackageWizard/OrderStep";

    public static readonly cruisePackageWizardOrderStepRoute: PageRoute = new PageRoute(PageDefinitions.cruisePackageWizardOrderStepRouteName);

    public static readonly cruisePackageWizardApproveStepRouteName: string = "Mobile/CruisePackageWizard/ApproveStep";

    public static readonly cruisePackageWizardApproveStepRoute: PageRoute = new PageRoute(PageDefinitions.cruisePackageWizardApproveStepRouteName);

    // #endregion

    public static readonly landingRouteName: string = "Landing";

    public static readonly landingRoute: PageRoute = new PageRoute(PageDefinitions.landingRouteName);

    // #endregion
}

new PageDefinitions();