import React from "react";
import {BaseComponent, BasePageParameters, ch} from "@reapptor-apps/reapptor-react-common";
import {
    Button,
    ButtonContainer,
    ButtonType,
    Dropdown,
    DropdownOrderBy,
    Form,
    IconSize,
    JustifyContent,
    NumberInput,
    PageContainer,
    PageRow,
    Panel,
    Tab,
    TabContainer,
    TabRenderType,
    TextInput,
    ToolbarContainer,
    ToolbarRow,
    TwoColumns
} from "@reapptor-apps/reapptor-react-components";
import BoatPrice from "@/models/server/bout/BoatPrice";
import ListBoatPricesRequest from "@/models/server/requests/ListBoatPricesRequest";
import Area from "@/models/server/bout/Area";
import SaveBoatPriceRequest from "@/models/server/requests/SaveBoatPriceRequest";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import SaveBoatPriceResponse from "@/models/server/responses/SaveBoatPriceResponse";
import DeleteBoatPricesRequest from "@/models/server/requests/DeleteBoatPricesRequest";
import GetDefaultBoatPricingSettingsResponse from "@/models/server/responses/GetDefaultBoatPricingSettingsResponse";
import FixedPricesPanel from "@/pages/PriceManagement/BoatPricingPanel/FixedPricesPanel/FixedPricesPanel";
import BoatsPanel from "@/pages/PriceManagement/BoatPricingPanel/BoatsPanel/BoatsPanel";
import AppController from "@/pages/AppController";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./BoatPricingPanel.module.scss";

interface IBoatPricingPanelProps extends BasePageParameters {
}

interface IBoatPricingPanelState {
    selectedArea: Area | null;
    activeTabIndex: number;
    boatPrices: BoatPrice[];
    areas: Area[];
    generation: number;
}

export default class BoatPricingPanel extends BaseComponent<IBoatPricingPanelProps, IBoatPricingPanelState> {

    state: IBoatPricingPanelState = {
        selectedArea: null,
        activeTabIndex: 0,
        areas: [],
        boatPrices: [],
        generation: 0
    }
    
    private async fetchAreasAsync(): Promise<Area[]> {
        return await AppController.listAreasAsync();
    }
    
    private async fetchBoatPricesAsync(area: Area | null): Promise<BoatPrice[]> {
        const request = new ListBoatPricesRequest();
        request.areaId = area ? area.id : null;
        
        return this.postAsync("/api/boatPrice/listBoatPrices", request);
    }

    private async filterBoatPricesAsync(area: Area | null): Promise<void> {
        const boatPrices: BoatPrice[] = await this.fetchBoatPricesAsync(area)
        
        this.state.selectedArea = area;
        this.state.boatPrices = boatPrices;
        this.state.generation = this.state.generation + 1;
        
        await this.reRenderAsync();
    }

    private async addNewBoatPriceAsync(): Promise<void> {
        const defaultSettings: GetDefaultBoatPricingSettingsResponse = await this.getAsync("/api/boatPrice/getDefaultSettings");

        const newBoatPrice = new BoatPrice();
        newBoatPrice.closestCaptainCount = defaultSettings.closestCaptainCount;
        newBoatPrice.priceIntervalLimit = defaultSettings.priceIntervalLimit;
        newBoatPrice.area = this.state.selectedArea;
        newBoatPrice.name = (this.state.selectedArea)
            ? "{0} {1:BoatType}".format(this.state.selectedArea.name, newBoatPrice.boatType)
            : "{0:BoatType}".format(newBoatPrice.boatType);
        
        this.state.generation = this.state.generation + 1;
        this.state.activeTabIndex = 0;
        
        this.boatPrices.splice(0, 0, newBoatPrice);
        
        await this.reRenderAsync();
    }

    private get boatPrices(): BoatPrice[] {
        return this.state.boatPrices;
    }
    
    private get canRenderPanel(): boolean {
        return (this.boatPrices.length > 0);
    }
    
    private get isDraftAlreadyAdded(): boolean {
        return this.boatPrices.some(boatPrice => !boatPrice.id);
    }
    
    private get hasSelectedArea(): boolean {
        return (this.state.selectedArea != null);
    }
    
    private async saveBoatPriceAsync(boatPrice: BoatPrice, data: Dictionary<string, any>): Promise<void> {
        const request = new SaveBoatPriceRequest();
        
        this.copyTo(data, request);
        
        request.id = boatPrice.id;
        
        const response: SaveBoatPriceResponse = await this.postAsync("/api/boatPrice/saveBoatPrice", request);
        
        if (response.alreadyExists) {
            await ch.alertErrorAsync(Localizer.boatPricingPageAlertErrorBoatPriceAlreadyExist, true, true);
            return;
        }

        const index: number = this.boatPrices.indexOf(boatPrice);
        
        this.boatPrices[index] = response.boatPrice!;

        const newBoatPrice: boolean = !boatPrice.id;

        if (newBoatPrice) {
            this.state.generation = this.state.generation + 1;
        }

        await this.reRenderAsync();

        await ch.alertMessageAsync(Localizer.boatPricingPageAlertErrorSaveBoatPrice, true, true);
    }
    
    private async deleteBoatPriceAsync(boatPrice: BoatPrice) {
        const request = new DeleteBoatPricesRequest();
        request.boatPriceId = boatPrice.id;

        const newBoatPrice: boolean = !boatPrice.id;
        
        if (!newBoatPrice) {
            await this.postAsync("/api/boatPrice/deleteBoatPrice", request);
        }

        this.boatPrices.remove(boatPrice);

        this.state.generation = this.state.generation + 1;

        await this.reRenderAsync();
    }

    private async setBoatPriceNameAsync(id: string | null, value: string) {
        const boatPrice = this.boatPrices.find(boatPrice => boatPrice.id == id);
        boatPrice!.name = value;
        await this.reRenderAsync();
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        const areas: Area[] = await this.fetchAreasAsync();
        
        const boatPrices: BoatPrice[] = await this.fetchBoatPricesAsync(null);
        
        await this.setState({areas, boatPrices});
    }

    public renderBoatPrice(boatPrice: BoatPrice): React.ReactNode {
        return (
            <TabContainer key={`form_boat_price_${boatPrice.id}`} renderType={TabRenderType.Always}>
                
                <Tab id={"dynamicPrice"} title={Localizer.boatPricingPageTabDynamicPrices}>

                    <Form className={styles.form}
                          onSubmit={(_, data) => this.saveBoatPriceAsync(boatPrice, data)}
                    >

                        <TwoColumns>

                            <TextInput required autoFocus
                                       id={"name"}
                                       label={Localizer.boatPricingPageTextInputNameLabel}
                                       value={boatPrice.name!}
                                       onChange={(sender, value) => this.setBoatPriceNameAsync(boatPrice.id, value)}
                            />

                        </TwoColumns>

                        <TwoColumns>

                            <Dropdown required
                                      id={"areaId"}
                                      disabled={this.hasSelectedArea}
                                      label={Localizer.boatPricingPageDropdownAreaLabel}
                                      items={this.state.areas}
                                      selectedItem={boatPrice.area}
                            />

                            <Dropdown required
                                      id={"boatType"}
                                      label={Localizer.boatPricingPageDropdownBoatTypeLabel}
                                      orderBy={DropdownOrderBy.None}
                                      items={EnumProvider.getBoatTypeItems()}
                                      selectedItem={EnumProvider.getBoatTypeItem(boatPrice.boatType)}
                            />

                        </TwoColumns>

                        <TwoColumns>

                            <NumberInput required
                                         id={"averageSpeed"}
                                         label={Localizer.boatPricingPageNumberInputAverageSpeedLabel}
                                         min={1}
                                         max={200}
                                         format={"0.0"}
                                         value={boatPrice.averageSpeed}
                            />

                            <NumberInput required
                                         id={"minutePrice"}
                                         label={Localizer.boatPricingPageNumberInputMinutePriceLabel.format(boatPrice.area?.country?.currency)}
                                         min={0.01}
                                         max={100.0}
                                         format={"0.00"}
                                         value={boatPrice.minutePrice}
                            />

                        </TwoColumns>

                        <TwoColumns>

                            <NumberInput required
                                         id={"basePrice"}
                                         label={Localizer.boatPricingPageNumberInputBasePriceLabel.format(boatPrice.area?.country?.currency)}
                                         format={"0.0"}
                                         value={boatPrice.basePrice}
                            />

                        </TwoColumns>

                        <TwoColumns>

                            <NumberInput required
                                         id={"addonPrice"}
                                         label={Localizer.boatPricingPageNumberInputAddonPriceLabel.format(boatPrice.area?.country?.currency)}
                                         format={"0.0"}
                                         value={boatPrice.addonPrice ?? 0}
                            />

                            <NumberInput required
                                         id={"minimumTime"}
                                         label={Localizer.boatPricingPageNumberInputBufferRideDurationLabel}
                                         format={"0.0"}
                                         value={boatPrice.minimumTime}
                            />

                        </TwoColumns>

                        <TwoColumns>

                            <NumberInput required
                                         id={"prebookNotificationReminderFirstHours"}
                                         label={Localizer.boatPricingPageNumberInputReminderNotificationLabel}
                                         format={"0.0"}
                                         value={boatPrice.prebookNotificationReminderFirstHours}
                            />

                            <NumberInput required
                                         id={"prebookNotificationReminderLastMinutes"}
                                         label={Localizer.boatPricingPageNumberInputReminderNotificationSecondLabel}
                                         format={"0.0"}
                                         value={boatPrice.prebookNotificationReminderLastMinutes}
                            />

                        </TwoColumns>

                        <TwoColumns>

                            <NumberInput required
                                         id={"closestCaptainCount"}
                                         label={Localizer.boatPricingPageNumberInputClosestCaptainCountLabel}
                                         min={1}
                                         max={10}
                                         format={"0.0"}
                                         value={boatPrice.closestCaptainCount}
                            />

                            <NumberInput required
                                         id={"priceIntervalLimit"}
                                         label={Localizer.boatPricingPageNumberInputPriceIntervalLimitLabel}
                                         format={"0.0"}
                                         min={1}
                                         max={2}
                                         value={boatPrice.priceIntervalLimit}
                            />

                        </TwoColumns>

                        <TwoColumns>

                            <NumberInput required
                                         id={"lowerLimit"}
                                         label={Localizer.boatPricingPageNumberInputLowerLimitLabel}
                                         format={"0.0"}
                                         value={boatPrice.lowerLimit}
                            />

                            <NumberInput required
                                         id={"upperLimit"}
                                         label={Localizer.boatPricingPageNumberInputLimitUpperLabel}
                                         format={"0.0"}
                                         value={boatPrice.upperLimit}
                            />

                        </TwoColumns>

                        <TwoColumns>

                            <NumberInput required
                                         id={"minimumPassengerCapacity"}
                                         label={Localizer.boatPricingPageNumberInputMinimumPassengerCapacityLabel}
                                         format={"0"}
                                         value={boatPrice.minimumPassengerCapacity}
                            />

                            <NumberInput required
                                         id={"maximumPassengerCapacity"}
                                         label={Localizer.boatPricingPageNumberInputMaximumPassengerCapacityLabel}
                                         format={"0"}
                                         value={boatPrice.maximumPassengerCapacity}
                            />

                        </TwoColumns>

                        <ButtonContainer className={styles.bottomButtonContainer}>
                            <Button right={true} type={ButtonType.Orange} label={Localizer.formSave} submit />
                        </ButtonContainer>

                    </Form>
                    
                </Tab>

                <Tab id={"fixedPrices"} title={Localizer.boatPricingPageTabFixedPrices}>
                    
                    <FixedPricesPanel boatPrice={boatPrice} />
                    
                </Tab>

                <Tab id={"boats"} title={Localizer.boatPricingPageTabBoats}>
                    
                    <BoatsPanel boatPrice={boatPrice} />
                    
                </Tab>
                
            </TabContainer>
        );
    }
    
    public renderPanel(): React.ReactNode {
        return (
            <Panel className={styles.formPanel}>
                
                <div className="col">
                    
                    <TabContainer id={`tabs_boat_price_${this.state.generation}`}
                                  key={`tabs_boat_price_${this.state.generation}`}
                                  renderType={TabRenderType.ActiveOnly}
                    >
                        {
                            (this.boatPrices.map((boatPrice, index) =>
                                (
                                    <Tab id={`tab_boat_price_${boatPrice.id}`}
                                         key={`tab_boat_price_${boatPrice.id}`}
                                         title={boatPrice.name!}
                                         active={index == this.state.activeTabIndex}
                                         className={(!boatPrice.id) ? styles.newBoatPrice : ""}
                                         closeConfirm={Localizer.boatPricingPageTabCloseConfirm}
                                         onClose={() => this.deleteBoatPriceAsync(boatPrice)}
                                         onSelect={async () => { this.state.activeTabIndex = index }}
                                    >

                                        {this.renderBoatPrice(boatPrice)}

                                    </Tab>
                                )
                            ))
                        }
                        
                    </TabContainer>
                    
                </div>
                
            </Panel>
        );
    }
    
    public render(): React.ReactNode {
        return (
            <PageContainer fullHeight className={styles.boatPricingPanel}>
                
                <PageRow>

                    <ToolbarContainer>

                        <ToolbarRow justify={JustifyContent.Start}>

                            <Dropdown inline noWrap
                                      minWidth={"200px"}
                                      items={this.state.areas}
                                      selectedItem={this.state.selectedArea}
                                      nothingSelectedText={Localizer.genericAllAreas}
                                      disabled={this.isDraftAlreadyAdded}
                                      orderBy={DropdownOrderBy.None}
                                      onChange={(_, area) => this.filterBoatPricesAsync(area)}
                            />

                            <Button title={Localizer.boatPricingPageButtonTitle}
                                    icon={{name: "plus", size: IconSize.Large}}
                                    type={ButtonType.Orange}
                                    disabled={this.isDraftAlreadyAdded}
                                    onClick={() => this.addNewBoatPriceAsync()}
                            />

                        </ToolbarRow>

                    </ToolbarContainer>

                    {
                        (this.canRenderPanel) &&
                        (
                            this.renderPanel()
                        )
                    }
                    
                </PageRow>
            
            </PageContainer>
        );
    }
}