import React from "react";
import {
    Button,
    ButtonType,
    Dropdown,
    DropdownOrderBy,
    Form
} from "@reapptor-apps/reapptor-react-components";
import CruisePackageWizard from "@/pages/Mobile/CruisePackageWizard/CruisePackageWizard";
import Area from "@/models/server/bout/Area";
import CruisePackageWizardController from "@/pages/Mobile/CruisePackageWizard/CruisePackageWizardController";
import Localizer from "@/localization/Localizer";

import styles from "./AreaStep.module.scss";

export interface IAreaStepProps {
}

interface AreaStepState {
    areas: Area[];
}

export default class AreaStep extends CruisePackageWizard<IAreaStepProps, AreaStepState> {

    state: AreaStepState = {
        areas: [],
    };
    
    private readonly _formRef: React.RefObject<Form> = React.createRef();

    private async onChangeAsync(): Promise<void> {
        this.save();
        await this.reRenderAsync();
    }

    public async setAreaAsync(value: Area | null): Promise<void> {
        this.wizard.area = value;

        await this.onChangeAsync();
    }

    public get areas(): Area[] {
        return this.state.areas;
    }

    public get areaId(): string | null {
        return (this.wizard.area) ? this.wizard.area.id : null;
    }

    protected get buttonsClassName(): string {
        return styles.nextButton;
    }

    public async nextAsync(): Promise<void> {
        if (this._formRef.current) {
            await this._formRef.current.validateAsync();
        }

        this.save();
        
        await super.nextAsync();
    }

    public get canNext(): boolean {
        return true;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const areas: Area[] = await CruisePackageWizardController.listAreasAsync(this.wizard.country?.code);

        if ((!this.wizard.area) && (areas.length > 0)) {
            this.wizard.area = areas[0];
        }

        await this.setState({areas});
    }

    public renderButtons(): React.ReactNode {
        return (             
            <Button block
                    type={ButtonType.Dark}
                    label={Localizer.genericNext}
                    onClick={() => this.nextAsync()}
            />
        )
    }

    public renderContent(): React.ReactNode {
        const initialized: boolean = (this.areas.length > 0);
        const single: boolean = (this.areas.length == 1);

        return (
            <>
                {
                    (initialized) &&
                    (
                        <Form id={"form"}
                              ref={this._formRef}
                              className={this.css(styles.area, single && styles.single)}>

                            {
                                (this.areas.length >= 1) &&
                                (
                                    <Dropdown id={"areas"} clearButton
                                              className={styles.item}
                                              orderBy={DropdownOrderBy.None}
                                              items={this.areas}
                                              selectedItem={this.areaId}
                                              nothingSelectedText={Localizer.cruisePackageWizardAreaStepAnyArea}
                                              onChange={(_, value) => this.setAreaAsync(value)}
                                    />
                                )
                            }

                        </Form>
                    )
                }
            </>
        );
    }
}