import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";

import styles from "./ContainerItem.module.scss";

export type TBootstrapColumn = "col" |
    "col-1" | "col-2" | "col-3" | "col-4" | "col-5" | "col-6" |
    "col-sm-1" | "col-sm-2" | "col-sm-3" | "col-sm-4" | "col-sm-5" | "col-sm-6" |
    "col-md-1" | "col-md-2" | "col-md-3" | "col-md-4" | "col-md-5" | "col-md-6" |
    "col-lg-1" | "col-lg-2" | "col-lg-3" | "col-lg-4" | "col-lg-5" | "col-lg-6" |
    "col-xl-1" | "col-xl-2" | "col-xl-3" | "col-xl-4" | "col-xl-5" | "col-xl-6";

export interface IContainerItemProps {
    className?: string;
    col?: TBootstrapColumn;
    onClick?(sender: ContainerItem): Promise<void>;
}

export default class ContainerItem extends BaseComponent<IContainerItemProps> {

    private async invokeOnClick(): Promise<void> {
        if (this.props.onClick) {
            await this.props.onClick(this);
        }
        await this.reRenderAsync();
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(styles.containerItem, this.props.col || "col-lg-6")}>
                <div className={this.css(this.props.className)} onClick={() => this.invokeOnClick()}>
                    {this.children}
                </div>
            </div>
        )
    }
}