// autogenerated
import {GeoCoordinate} from "@reapptor-apps/reapptor-toolkit";

export default class IntermediateWaypoint extends GeoCoordinate {

    public id: string = "";

    public order: number = 0;

    public fairway: boolean = false;

    public readonly isIntermediateWaypoint: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof IntermediateWaypoint) || ((from as any).isIntermediateWaypoint === true));
    }

    public static as(from?: object | null): IntermediateWaypoint | null {
        return (IntermediateWaypoint.is(from)) ? from as IntermediateWaypoint : null;
    }

    // #region Placeholder

    constructor(latitude?: number | null | undefined, longitude?: number | null | undefined) {
        super(latitude, longitude);
    }

    // #endregion
}