import {BasePage, ch, PageRoute, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import {TBasePageParameters} from "@reapptor-apps/reapptor-react-common/src/base/BasePage";
import ApplicationContext from "@/models/server/ApplicationContext";
import AppController from "@/pages/AppController";

export default abstract class AnonymousPage<TProps extends TBasePageParameters = {}, TState = {}>
    extends BasePage<TProps, TState, ApplicationContext> {
    
    protected async getHomeRouteAsync(): Promise<PageRoute> {
        return await this.postAsync("/api/account/getHomePage");
    }
    
    protected async redirectHomeAsync(): Promise<void> {
        const route: PageRoute = await this.getHomeRouteAsync();

        await PageRouteProvider.redirectAsync(route, true, true);
    }

    public get hasLanguageSelector(): boolean {
        return (!AppController.mobileAppContent);
    }
    
    public async initializeAsync(): Promise<void> {
        
        if (ch.isAuthenticated) {
            const routeName: string = ch.findRouteName();
            const cruisePackageWizardPrefix: string = "Mobile/CruisePackageWizard/";
            const routes: string[] = [
                PageDefinitions.errorRouteName,
                PageDefinitions.loginRouteName,
                PageDefinitions.logoutRouteName,
                PageDefinitions.offlineRouteName,
                PageDefinitions.contactSupportRouteName,
                PageDefinitions.cruisePackagesRouteName,
                PageDefinitions.cruisePackageDetailsRouteName,
                PageDefinitions.bookingDetailsRouteName,
                PageDefinitions.authorsRouteName,
                PageDefinitions.landingRouteName,
                PageDefinitions.privacyPolicyRouteName,
            ];
            
            if ((!routes.includes(routeName)) && (!routeName.startsWith(cruisePackageWizardPrefix))) {
                await this.redirectHomeAsync();
            }
        }

        await super.initializeAsync();
    }
    
}