import React from "react";
import {Elements, ElementsConsumer} from "@stripe/react-stripe-js";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {PageContainer, Spinner} from "@reapptor-apps/reapptor-react-components";
import CreditCard from "@/models/server/bout/CreditCard";
import {loadStripe, ConfirmCardSetupData, PaymentMethod, SetupIntentResult, Stripe, StripeCardElement} from '@stripe/stripe-js';
import {Address} from "@stripe/stripe-js/types/api/shared";
import UserContext from "@/models/server/UserContext";
import {BasePageParameters, ch, PageRoute, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import {PaymentMethodResult, StripeConstructorOptions, StripeError} from "@stripe/stripe-js/types/stripe-js/stripe";
import {CreatePaymentMethodData} from "@stripe/stripe-js/types/stripe-js/payment-intents";
import User from "@/models/server/User";
import AddMyCreditCardResponse from "@/models/server/responses/AddMyCreditCardResponse";
import AddMyCreditCardRequest from "@/models/server/requests/AddMyCreditCardRequest";
import StripeForm from "@/pages/Mobile/EditCreditCard/StripeForm/StripeForm";
import {StripeElementLocale, StripeElementsOptions} from "@stripe/stripe-js/types/stripe-js/elements-group";
import LogSaveCreditCardErrorRequest from "../../../models/server/requests/LogSaveCreditCardErrorRequest";
import OnlineData from "@/models/server/OnlineData";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";

import boutStyles from "../../../bout.module.scss";
import styles from "./EditCreditCard.module.scss";

export interface IEditCreditCardProps extends BasePageParameters {
    required?: boolean;
    creditCardId?: string;
    returnToBooking?: boolean;
    isExplicitRenew?: boolean;
}

interface IEditCreditCardState {
    required: boolean;
    stripe: Stripe | null;
    creditCard: CreditCard | null;
    saving: boolean;
}

export default class EditCreditCard extends AuthorizedPage<IEditCreditCardProps, IEditCreditCardState> {

    state: IEditCreditCardState = {
        stripe: null,
        required: false,
        creditCard: new CreditCard(),
        saving: false
    };

    private async saveCreditCardAsync(cardElement: StripeCardElement, isDefault: boolean, holderName: string): Promise<void> {

        //4242424242424242 // US
        //4000000760000002 // BR
        //4000001240000000 // CA
        //4000004840008001 // MX

        try {
            await this.setState({saving: true});

            const user: User = this.getUser();
            const fullName: string = User.getFullName(user);
            const country: string | null = user.country?.code || null;

            const address: Address | null = (country)
                    ? {
                        country: country
                    } as Address
                    : null;

            const options = {
                type: "card",
                card: cardElement,
                billing_details: {
                    email: user.email,
                    phone: user.phone,
                    name: fullName,
                    address: address
                } as PaymentMethod.BillingDetails
            } as CreatePaymentMethodData;

            const createResult: PaymentMethodResult = await this.stripe!.createPaymentMethod(options);

            const stripeCard: PaymentMethod.Card | null = createResult.paymentMethod?.card || null;

            if (!stripeCard) {
                const codeOrDeclineCode = (createResult.error?.decline_code) ?? (createResult.error?.code);
                const error: string = TransformProvider.stripeErrorCodeToString(codeOrDeclineCode as string | null);
                const message: string = Localizer.mobileEditCreditCardDetailsPageAlertErrorCardCreationFailed.format(error);

                await this.logSaveCreditCardErrorAsync("Stripe.createPaymentMethod", createResult, createResult.error);

                await this.alertErrorAsync(message, true, true);

                return;
            }

            const secret: string = await this.getAsync("/api/mobileApp/getStripeIntentClientSecret");

            const confirmResult: SetupIntentResult = await this.stripe!.confirmCardSetup(secret,
                {
                    payment_method: createResult.paymentMethod!.id
                } as ConfirmCardSetupData
            );

            if (confirmResult.error) {
                const CodeOrDeclineCode = (confirmResult.error.decline_code) ?? (confirmResult.error.code);
                const error: string = TransformProvider.stripeErrorCodeToString(CodeOrDeclineCode as string | null);
                const message: string = Localizer.mobileEditCreditCardDetailsPageAlertErrorCardInitializingFailed.format(error);

                await this.logSaveCreditCardErrorAsync("Stripe.confirmCardSetup", confirmResult, confirmResult.error);

                await this.alertErrorAsync(message, true, true);

                return;
            }

            const request = new AddMyCreditCardRequest();
            request.last4 = parseInt(stripeCard.last4);
            request.expirationMonth = stripeCard.exp_month;
            request.expirationYear = stripeCard.exp_year;
            request.brand = stripeCard.brand;
            request.holderName = holderName;
            request.default = isDefault;
            request.isExplicitRenew = this.isExplicitRenew;

            let response: AddMyCreditCardResponse = await this.postAsync("/api/mobileApp/addMyCreditCard", request);

            if (response.hasSameNumberAsExpired) {
                const confirmed: boolean = await this.confirmAsync(Localizer.mobileEditCreditCardDetailsPageConfirmationRenew);

                if (!confirmed) {
                    return;
                }

                request.isExplicitRenew = true;

                response = await this.postAsync("/api/mobileApp/addMyCreditCard", request);
            }

            if (!response.success) {

                if (response.alreadyExist) {
                    await this.alertErrorAsync(Localizer.mobileEditCreditCardDetailsPageAlertErrorCardAlreadyExist, true, true);
                    return;
                }

                await this.alertErrorAsync(Localizer.mobileEditCreditCardDetailsPageAlertErrorCardCannotCreated, true, true);
                return;
            }

            const route: PageRoute = (this.parameters?.returnToBooking)
                ? PageDefinitions.bookingDetailsRoute
                : PageDefinitions.myPaymentMethodsRoute;

            await PageRouteProvider.redirectAsync(route);

            await this.alertMessageAsync(Localizer.mobileEditCreditCardDetailsPageAlertErrorCardAdded.format(request.last4), true, true);

        } finally {
            await this.setState({saving: false});

            await ch.reRenderLeftNavAsync();
        }
    }

    private async logSaveCreditCardErrorAsync(action: string, response: object, error: StripeError | undefined): Promise<void> {
        const request = new LogSaveCreditCardErrorRequest();
        request.action = action;
        request.responseJson = JSON.stringify(response);
        request.errorJson = error ? JSON.stringify(error) : null;
        
        await this.postAsync("/api/mobileApp/logSaveCreditCardError", request);
    }

    private async deleteAsync(): Promise<void> {
        
        if (!this.canDelete) {
            await this.alertErrorAsync(Localizer.mobileEditCreditCardDetailsPageAlertErrorCardNotDeleted, true, true);
            return;
        }
        
        await this.postAsync("/api/mobileApp/deleteMyCreditCard", this.creditCard.id);

        await PageRouteProvider.redirectAsync(PageDefinitions.myPaymentMethodsRoute, true);

        await this.alertMessageAsync(Localizer.mobileEditCreditCardDetailsPageAlertErrorCardDeleted.format(this.creditCard.last4), true, true);

        await ch.reRenderLeftNavAsync();
    }

    private async setDefaultAsync(): Promise<void> {
        await this.postAsync("/api/mobileApp/setDefaultMyCreditCard", this.creditCard.id);

        await PageRouteProvider.redirectAsync(PageDefinitions.myPaymentMethodsRoute);

        await this.alertMessageAsync(Localizer.mobileEditCreditCardDetailsPageAlertErrorCardAsDefault.format(this.creditCard.last4), true, true);
        
        await ch.reRenderLeftNavAsync();
    }

    private get stripe(): Stripe | null {
        return this.state.stripe;
    }

    private get stripeLocale(): StripeElementLocale | undefined {
        switch (Localizer.language) {
            case "en":
            case "gb":
                return "en";
            case "es":
                return "es";
            case "it":
                return "it";
            case "nl":
                return "nl";
            case "fr":
                return "fr";
            case "de":
                return "de";
            case "se":
            case "sv":
                return "sv";
            case "fi":
                return "fi";
            case "ru":
                return "ru";
        }
        return undefined;
    }

    private get stripeOptions(): StripeElementsOptions {
        return {
            locale: this.stripeLocale,
            appearance: {
                disableAnimations: true
            }
        }
    }

    public getTitle(): string {
        return Localizer.mobileEditCreditCardDetailsPageTitle;
    }

    public get creditCard(): CreditCard {
        return this.state.creditCard!;
    }

    public get canDelete(): boolean {
        const userContext: UserContext = this.getContext();
        const onlineData: OnlineData | null = userContext.onlineData;
        return (
            (!userContext.asPassenger) ||
            ((onlineData != null) && (!onlineData.hasPendingPayment) && (!onlineData.hasOngoingTrip) && (onlineData.activeTripsCount == 0))
        );
    }

    public get required(): boolean {
        return this.state.required;
    }

    public get isNew(): boolean {
        return (!this.creditCard.id);
    }

    public hasSpinner(): boolean {
        return this.state.saving;
    }

    public get isExplicitRenew(): boolean {
        return this.parameters?.isExplicitRenew ?? false;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const userContext: UserContext = this.getContext();
        const user: User = userContext.user!;

        const stripeOptions = {locale: Localizer.language} as StripeConstructorOptions;

        const stripeApiKey: string = userContext.settings.stripeApiKey ?? "";
        
        const stripe: Stripe | null = await loadStripe(stripeApiKey, stripeOptions);

        if (!stripe) {
            await this.alertErrorAsync(Localizer.mobileEditCreditCardDetailsPageAlertErrorPaymentSystemError);

            await PageRouteProvider.redirectAsync(PageDefinitions.myPaymentMethodsRoute, true, true);
        }

        const creditCardId: string | null = (this.parameters as IEditCreditCardProps | null)?.creditCardId || null;
        const required: boolean = ((this.parameters as IEditCreditCardProps | null)?.required === true);

        let creditCard: CreditCard;
        if (creditCardId) {
            creditCard = await this.postAsync("/api/mobileApp/getMyCreditCard", creditCardId);
        } else {
            creditCard = new CreditCard();
            creditCard.holderName = User.getFullName(user);
            creditCard.default = required;
        }
        
        await this.setState({stripe, creditCard, required});
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.editCreditCard, this.mobile && styles.mobile)}
                           alertClassName={boutStyles.alert}
            >

                <span className={styles.header}>{this.getTitle()}</span>

                <div className={styles.creditCardContainer}>

                    <div className={styles.creditCard}>

                        {
                            (this.stripe) &&
                            (
                                <Elements stripe={this.stripe} options={this.stripeOptions}>
                                    <ElementsConsumer>
                                        {
                                            ({stripe, elements}) =>
                                                (
                                                    <StripeForm stripe={stripe!}
                                                                elements={elements!}
                                                                creditCard={this.creditCard}
                                                                required={this.required}
                                                                onSave={(_, cardElement, isDefault, holderName) => this.saveCreditCardAsync(cardElement, isDefault, holderName)}
                                                                onDelete={() => this.deleteAsync()}
                                                                onSetDefault={() => this.setDefaultAsync()}
                                                    />
                                                )
                                        }
                                    </ElementsConsumer>
                                </Elements>
                            )
                        }

                    </div>

                </div>

                {(this.state.saving) && (<Spinner/>)}

            </PageContainer>
        );
    }
}