// autogenerated
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class ListActiveServicePointsRequest extends ETagRequest {

    public readonly isListActiveServicePointsRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ListActiveServicePointsRequest) || ((from as any).isListActiveServicePointsRequest === true));
    }

    public static as(from?: object | null): ListActiveServicePointsRequest | null {
        return (ListActiveServicePointsRequest.is(from)) ? from as ListActiveServicePointsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}