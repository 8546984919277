// autogenerated
import {BoatType} from "@/models/Enums";
import Area from "@/models/server/bout/Area";

export default class BoatPrice {

    public id: string = "";

    public name: string | null = null;

    public areaId: string = "";

    public area: Area | null = null;

    public boatType: BoatType = BoatType.Small;

    public averageSpeed: number = 0;

    public minutePrice: number = 0;

    public basePrice: number = 0;

    public addonPrice: number | null = null;

    public minimumTime: number = 0;

    public prebookNotificationReminderLastMinutes: number = 0;

    public prebookNotificationReminderFirstHours: number = 0;

    public cancellationDayBefore: number = 0;

    public cancellationLastDay: number = 0;

    public cancellationLastHour: number = 0;

    public priceIntervalLimit: number = 0;

    public closestCaptainCount: number = 0;

    public lowerLimit: number = 0;

    public upperLimit: number = 0;

    public minimumPassengerCapacity: number = 0;

    public maximumPassengerCapacity: number = 0;

    public speed: boolean = false;

    public readonly isBoatPrice: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof BoatPrice) || ((from as any).isBoatPrice === true));
    }

    public static as(from?: object | null): BoatPrice | null {
        return (BoatPrice.is(from)) ? from as BoatPrice : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}