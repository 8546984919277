import React from "react";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import {ch, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import User from "../../models/server/User";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import {AuthType} from "@/models/Enums";
import PageDefinitions from "../../providers/PageDefinitions";
import SaveUserRequest from "../../models/server/requests/SaveUserRequest";
import SaveUserResponse from "@/models/server/responses/SaveUserResponse";
import {
    BaseRegexValidatorErrorMessage,
    Button,
    ButtonContainer,
    ButtonType,
    Checkbox,
    Dropdown,
    EmailInput,
    Form,
    Inline,
    InlineType,
    Modal,
    ModalSize,
    OneColumn,
    PageContainer,
    PageHeader,
    PageRow,
    PhoneInput,
    TextInput,
    TwoColumns
} from "@reapptor-apps/reapptor-react-components";
import Country from "@/models/server/bout/Country";
import ValidatePhoneNumberRequest from "@/models/server/requests/ValidatePhoneNumberRequest";
import TransformProvider from "@/providers/TransformProvider";
import ServiceProviderController from "@/pages/ServiceProviderController";
import AppController from "@/pages/AppController";
import Localizer from "../../localization/Localizer";

interface IAccountProps {
}

interface IAccountState {
    countries: Country[];
    isValidPhoneNumber: boolean;
}

export default class Account extends AuthorizedPage<IAccountProps, IAccountState> {
    state: IAccountState = {
        countries: [],
        isValidPhoneNumber: true
    };

    private readonly _agreementRef: React.RefObject<Checkbox> = React.createRef();
    private readonly _registrationRef: React.RefObject<Checkbox> = React.createRef();
    private readonly _cancellationPolicyRef: React.RefObject<Checkbox> = React.createRef();
    private readonly _adultRef: React.RefObject<Checkbox> = React.createRef();

    private get adultCheckbox(): Checkbox {
        return this._adultRef.current!;
    }

    private get agreementCheckbox(): Checkbox {
        return this._agreementRef.current!;
    }

    private get isValidPhoneNumber(): boolean {
        return this.state.isValidPhoneNumber;
    }

    private get registrationCheckbox(): Checkbox {
        return this._registrationRef.current!;
    }

    private get cancellationPolicyCheckbox(): Checkbox {
        return this._cancellationPolicyRef.current!;
    }

    private get countries(): Country[] {
        return this.state.countries;
    }

    private get asCaptain(): boolean {
        return AppController.asCaptain;
    }

    public getTitle(): string {
        return Localizer.topNavAccount;
    }

    private async setCountryAsync(country: Country): Promise<void> {
        this.user.countryId = country.id;
        this.user.country = country;
    }

    private async setAdultAsync(value: boolean): Promise<void> {
        User.setAdult(this.user, this.serviceProviderSlug, value);
        await this.reRenderAsync();
    }

    private async agreementAcceptedAsync(value: boolean): Promise<void> {
        User.setAgreementAccepted(this.user, this.serviceProviderSlug, value);
        await this.reRenderAsync();
    }

    private async registrationAcceptedAsync(value: boolean): Promise<void> {
        User.setRegistrationAccepted(this.user, this.serviceProviderSlug, value);
        await this.reRenderAsync();
    }

    private async cancellationPolicyAcceptedAsync(value: boolean): Promise<void> {
        User.setCancellationPolicyAccepted(this.user, this.serviceProviderSlug, value);
        await this.reRenderAsync();
    }

    private async onBlurAsync(sender: PhoneInput): Promise<void> {
        const isValidPhoneNumber: boolean = await this.validatePhoneNumberAsync(this.user.phone);

        if (isValidPhoneNumber !== this.state.isValidPhoneNumber) {
            this.state.isValidPhoneNumber = isValidPhoneNumber;
            await sender.validateAsync();
        }
    }

    private async validatePhoneNumberAsync(value: string | null): Promise<boolean> {
        
        if (value) {
            const request = new ValidatePhoneNumberRequest();
            request.value = value;
            request.countryId = this.user.countryId;
            request.personalOnly = false;

            return await this.postAsync("/api/user/isPhoneNumberValid", request);
        }

        return false;
    }

    private async onBusinessIdChangeAsync(item: string) {
        const user = this.user;
        user!.businessId = item;
    }

    private async setPhoneAsync(value: string): Promise<void> {
        this.user.phone = value;
    }

    private async redirectToPasswordPageAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.changePasswordRoute);
    }

    public validatePhoneNumber(): string | null {
        return (!this.isValidPhoneNumber)
            ? Localizer.get(BaseRegexValidatorErrorMessage.validatorsPhoneLanguageItemName)
            : null;
    }

    public async handleSubmitAsync(data: Dictionary<string, any>): Promise<void> {

        if ((!this.agreementCheckbox.checked) || (!this.registrationCheckbox.checked) || (!this.cancellationPolicyCheckbox.checked)) {
            return await ch.alertErrorAsync(Localizer.myAccountPageAcceptanceRequired);
        }

        if (!this.adultCheckbox.checked) {
            return await ch.alertErrorAsync(Localizer.myAccountPageAdultRequired);
        }
        
        const user: User = this.user;

        const request = new SaveUserRequest();
        request.id = user.id;
        request.authType = AuthType.Email;
        request.businessId = user.businessId;
        request.countryId = user.countryId;
        request.roleNames = user.roles.map(item => item.roleName);
        request.phone = user.phone;
        request.allowAsCaptain = user.allowAsCaptain;
        request.allowAsPassenger = user.allowAsPassenger;
        request.testUser = user.testUser;
        request.serviceProviderId = user.serviceProviderId;
        request.isEntrepreneur = user.isEntrepreneur;
        request.avatar = user.avatar;
        request.adult = this.adult;
        request.agreementAccepted = this.agreementAccepted;
        request.registrationAccepted = this.registrationAccepted;
        request.cancellationPolicyAccepted = this.cancellationPolicyAccepted;

        this.copyTo(data, request, user);

        const response: SaveUserResponse = await this.postAsync("/api/account/saveUserAccount", request);

        if (response.businessIdNotUnique) {
            await this.alertErrorAsync(Localizer.adminAlertErrorBusinessIdNotUnique, true);
            return;
        }

        const message: string = Utility.format(Localizer.myAccountPageDataSaved, TransformProvider.userToString(this.user));
        await this.alertMessageAsync(message, true);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const countries: Country[] = await AppController.listCountriesAsync();
        
        await this.setState({countries});
    }

    public get user(): User {
        return this.getUser();
    }

    public get adult(): boolean {
        return User.isAdult(this.user, this.serviceProviderSlug);
    }

    public get registrationAccepted(): boolean {
        return User.isRegistrationAccepted(this.user, this.serviceProviderSlug);
    }

    public get agreementAccepted(): boolean {
        return User.isAgreementAccepted(this.user, this.serviceProviderSlug);
    }

    public get cancellationPolicyAccepted(): boolean {
        return User.isCancellationPolicyAccepted(this.user, this.serviceProviderSlug);
    }

    public render(): React.ReactNode {
        
        return (
            <PageContainer>
                <PageHeader title={Localizer.myAccountPageTitle}
                            subtitle={Localizer.myAccountPageSubtitle}
                />

                <PageRow>
                    <div className="col">
                        {
                            (this.user) &&
                            (
                                <Form id="form" onSubmit={(_, data) => this.handleSubmitAsync(data)}>

                                    <TwoColumns>
                                        
                                        <EmailInput trim required
                                                    id="email"
                                                    label={Localizer.formInputEmail}
                                                    readonly={!!this.user.email}
                                                    value={this.user!.email}
                                        />

                                        <PhoneInput trim required
                                                    id="phone"
                                                    label={Localizer.formInputPhone}
                                                    validators={[() => this.validatePhoneNumber()]}
                                                    value={this.user.phone}
                                                    onBlur={(sender) => this.onBlurAsync(sender as PhoneInput)}
                                                    onChange={(sender, value) => this.setPhoneAsync(value)}
                                        />
                                        
                                    </TwoColumns>

                                    <TwoColumns>
                                        
                                        <TextInput required trim
                                                   id="firstname"
                                                   label={Localizer.formInputFirstname}
                                                   value={this.user.firstname}
                                        />

                                        <TextInput required trim
                                                   id="lastName"
                                                   label={Localizer.formInputLastname}
                                                   value={this.user.lastName}
                                        />
                                        
                                    </TwoColumns>

                                    <TwoColumns>

                                        <Dropdown required
                                                  id="language"
                                                  label={Localizer.formInputLanguage}
                                                  items={Localizer.supportedLanguages}
                                                  selectedItem={Localizer.findLanguage(this.user.language)}/>

                                        <Dropdown id="country" required
                                                  label={Localizer.formInputCountry}
                                                  items={this.countries}
                                                  transform={item => TransformProvider.toCountryListItem(item, false)}
                                                  selectedItem={this.user.countryId}
                                                  onChange={(_, item) => this.setCountryAsync(item!)}
                                        />
                                        
                                    </TwoColumns>

                                    <TwoColumns>

                                        {
                                            ((this.user.isCaptain) && (this.user.isEntrepreneur)) && 
                                            (
                                                <TextInput trim required
                                                           id="businessId"
                                                           label={Localizer.userManagementPageTextInputBusinessIdLabel}
                                                           value={this.user.businessId}
                                                           onChange={(_, item) => this.onBusinessIdChangeAsync(item!)}
                                                />
                                            )
                                        }
                                        
                                    </TwoColumns>

                                    <OneColumn className="mb-3">

                                        <Inline>

                                            <Checkbox inline
                                                      ref={this._adultRef}
                                                      id="adult"
                                                      inlineType={InlineType.Right}
                                                      label={Localizer.signUpPageAdultLabel}
                                                      value={this.adult}
                                                      readonly={this.adult}
                                                      onChange={(_, value) => this.setAdultAsync(value)}
                                            />

                                        </Inline>

                                        <Inline>
                                            
                                            <Checkbox ref={this._agreementRef}
                                                      id="agreementAccepted"
                                                      label={Localizer.myAccountPageAcceptance}
                                                      inline
                                                      inlineType={InlineType.Right}
                                                      value={this.agreementAccepted}
                                                      readonly={this.agreementAccepted}
                                                      onChange={(_, value) => this.agreementAcceptedAsync(value)}
                                            />

                                            <Button className={"ml-n2"}
                                                    label={Localizer.myAccountPageAcceptanceTerms}
                                                    type={ButtonType.Text}
                                                    toggleModal
                                                    dataTarget="agreementModal"
                                            />
                                            
                                        </Inline>

                                        <Inline>
                                            
                                            <Checkbox inline
                                                      ref={this._registrationRef}
                                                      id="registrationAccepted"
                                                      inlineType={InlineType.Right}
                                                      label={Localizer.myAccountPageAcceptance}
                                                      value={this.registrationAccepted}
                                                      readonly={this.registrationAccepted}
                                                      onChange={(_, value) => this.registrationAcceptedAsync(value)}
                                            />

                                            <Button className={"ml-n2"}
                                                    label={Localizer.myAccountPageAcceptancePrivacyNotice}
                                                    type={ButtonType.Text}
                                                    toggleModal
                                                    dataTarget="privacyNoticeModal"
                                            />
                                            
                                        </Inline>

                                        <Inline>
                                            
                                            <Checkbox ref={this._cancellationPolicyRef}
                                                      id="cancellationPolicyAccepted"
                                                      label={Localizer.myAccountPageAcceptance}
                                                      inline
                                                      inlineType={InlineType.Right}
                                                      value={this.cancellationPolicyAccepted}
                                                      readonly={this.cancellationPolicyAccepted}
                                                      onChange={(_, value) => this.cancellationPolicyAcceptedAsync(value)}
                                            />

                                            <Button className={"ml-n2"}
                                                    label={Localizer.myAccountPageAcceptanceCancellationPolicy}
                                                    type={ButtonType.Text}
                                                    toggleModal
                                                    dataTarget="cancellationPolicyModal"
                                            />
                                            
                                        </Inline>
                                        
                                    </OneColumn>

                                    <ButtonContainer>
                                        
                                        <Button type={ButtonType.Blue}
                                                label={Localizer.changePasswordButton}
                                                onClick={() => this.redirectToPasswordPageAsync()}
                                        />

                                        <Button submit
                                                type={ButtonType.Orange}
                                                label={Localizer.formSave}
                                                icon={{name: "far save"}}
                                        />
                                        
                                    </ButtonContainer>

                                </Form>
                            )
                        }
                    </div>
                </PageRow>

                <Modal id="agreementModal" info keepTextFormatting
                       title={ServiceProviderController.termsTitle}
                       content={(this.asCaptain) ? ServiceProviderController.termsContentCaptain : ServiceProviderController.termsContent}
                       size={ModalSize.Large}
                />

                <Modal id="privacyNoticeModal" info keepTextFormatting
                       title={ServiceProviderController.privacyNoticeTitle}
                       content={ServiceProviderController.privacyNoticeContent}
                       size={ModalSize.Large}
                />

                <Modal id="cancellationPolicyModal" info keepTextFormatting
                       title={ServiceProviderController.cancellationPolicyTitle}
                       content={ServiceProviderController.cancellationPolicyContent}
                       size={ModalSize.Large}
                />

            </PageContainer>
        );
    }
}