import React from "react";
import {Button, ButtonContainer, ButtonType, OneColumn} from "@reapptor-apps/reapptor-react-components";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import User from "@/models/server/User";

import Localizer from "../../../../localization/Localizer";

import styles from "./WaitingApproval.module.scss";
import kycStyles from "../Kyc.module.scss";
import TransformProvider from "@/providers/TransformProvider";

interface IWaitingApprovalProps {
    user: User;
    onModifyButtonClick: (sender: WaitingApproval) => Promise<void>;
    onRefreshUserButtonClick: (sender: WaitingApproval) => Promise<void>;
}

interface IWaitingApprovalState {
}

export default class WaitingApproval extends BaseComponent<IWaitingApprovalProps, IWaitingApprovalState> {

    state: IWaitingApprovalState = {};

    public get user(): User {
        return this.props.user;
    }

    public render(): React.ReactNode {
        return (
            <div className={this.css(kycStyles.statusContainer, styles.inProgress)}>

                <div className={kycStyles.text}>

                    <span>{Localizer.mobileKycPageWaitingApprovalVerifyMessageSpan}</span>

                    <div className={kycStyles.list}>

                        <h5>
                            {Localizer.mobileKycPageWaitingApprovalDocumentsListHeading}
                        </h5>


                        <ul>
                            {
                                (
                                    this.user.stripeKycStatus!.pendingVerificationItems?.map(item =>
                                        <li>{TransformProvider.localizeStripeKycStatusItem(item)}</li>
                                    )
                                )
                            }
                        </ul>
                        
                    </div>

                </div>

                <div className={kycStyles.expander}/>

                <ButtonContainer>

                    <Button type={ButtonType.Primary}
                            icon={{name: "fas edit"}}
                            label={Localizer.mobileKycPageWaitingApprovalModifyButtonLabel}
                            onClick={() => this.props.onModifyButtonClick(this)}
                    />

                    <Button type={ButtonType.Primary}
                            icon={{name:"fas fa-sync"}}
                            label={Localizer.mobileKycPageWaitingApprovalRefreshButtonLabel}
                            onClick={() => this.props.onRefreshUserButtonClick(this)}
                    />

                </ButtonContainer>

            </div>
        );
    }
}