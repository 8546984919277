import {Utility} from "@reapptor-apps/reapptor-toolkit";
import {PageRoute} from "@reapptor-apps/reapptor-react-common";
import Country from "@/models/server/bout/Country";
import Area from "@/models/server/bout/Area";
import CruisePackage from "@/models/server/cruise/CruisePackage";
import CruisePackageBooking from "@/models/server/cruise/CruisePackageBooking";
import GetCruisePackageAvailabilityResponse from "@/models/server/responses/GetCruisePackageAvailabilityResponse";

export default class CruisePackageWizardContext {
    public actionInitialPageRoute: PageRoute | null = null;

    public availability: GetCruisePackageAvailabilityResponse | null = null;

    public country: Country | null = null;

    public cruisePackage: CruisePackage | null = null;

    public area: Area | null = null;

    public passengers: number | null = null;

    public bookingTime: Date | null = null;
    
    public booking: CruisePackageBooking | null = null;

    public completed: boolean = false;

    public sending: boolean = false;

    public static parse(json: string): CruisePackageWizardContext {
        const to = new CruisePackageWizardContext();
        const from: CruisePackageWizardContext = JSON.parse(json);
        Utility.restoreDate(from);
        Utility.copyTo(from, to);
        return to;
    }
}