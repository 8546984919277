// autogenerated
import {GeoCoordinate} from "@reapptor-apps/reapptor-toolkit";

export default class SetBookingCaptainRequest {

    public bookingId: string = "";

    public boatId: string = "";

    public location: GeoCoordinate | null = null;

    public readonly isSetBookingCaptainRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof SetBookingCaptainRequest) || ((from as any).isSetBookingCaptainRequest === true));
    }

    public static as(from?: object | null): SetBookingCaptainRequest | null {
        return (SetBookingCaptainRequest.is(from)) ? from as SetBookingCaptainRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}