import React from "react";
import {
    Button,
    ButtonType,
    PageContainer, PageHeader,
    PageRow
} from "@reapptor-apps/reapptor-react-components";
import AnonymousPage from "../../models/base/AnonymousPage";
import PersonalInfo from "@/pages/SignUp/PersonalInfo/PersonalInfo";
import {BasePageParameters, ch, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import AppConstants from "@/helpers/AppConstants";
import User from "@/models/server/User";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import PageDefinitions from "@/providers/PageDefinitions";
import SignUpResponse from "@/models/server/responses/SignUpResponse";
import SignUpRequest from "@/models/server/requests/SignUpRequest";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "../../localization/Localizer";

import styles from "./SignUp.module.scss";
import selectRoleStyles from "../Mobile/SelectRole/SelectRole.module.scss";
import boutStyles from "../../bout.module.scss";

import loading from "../../img/gif/waving_boat.gif";
import ServiceProviderController from "@/pages/ServiceProviderController";
import AppController from "@/pages/AppController";
import ServiceProvider from "@/models/server/ServiceProvider";


enum SignUpStep {
    RoleSelection,

    PersonalInfo
}

export interface ISignUpProps extends BasePageParameters {
    asPassenger?: boolean | null;
}

interface ISignUpState {
    user: User;
    asCaptain: boolean;
    step: SignUpStep;
    roleName: string | null;
}

export default class SignUp extends AnonymousPage<ISignUpProps, ISignUpState> {

    state: ISignUpState = {
        user: new User(Localizer.language),
        asCaptain: false,
        step: SignUpStep.RoleSelection,
        roleName: null,
    };

    private async switchStep(step: SignUpStep): Promise<void> {
        if (step == SignUpStep.RoleSelection) {
            this.state.roleName = null;
        }

        await this.setState({step});
    }

    private async onNextAsync(step: SignUpStep): Promise<void> {
        switch (step) {
            case SignUpStep.PersonalInfo:
                await this.signUpAsync();
                break;
        }
    }

    private async onPrevAsync(step: SignUpStep): Promise<void> {
        switch (step) {
            case SignUpStep.PersonalInfo:
                await this.switchStep(SignUpStep.RoleSelection);
                break;
        }
    }

    private async selectRoleAsync(roleName: string): Promise<void> {
        this.state.roleName = roleName;
        await this.switchStep(SignUpStep.PersonalInfo);
    }

    private async signUpAsync(): Promise<void> {
        
        const user: User = this.user;
        const serviceProviderSlug: ServiceProvider | null = ServiceProviderController.serviceProviderSlug;

        const request = new SignUpRequest();
        request.email = user.email;
        request.phone = user.phone;
        request.firstname = user.firstname;
        request.lastName = user.lastName;
        request.agreementAccepted = User.isAgreementAccepted(user, serviceProviderSlug);
        request.registrationAccepted = User.isRegistrationAccepted(user, serviceProviderSlug);
        request.cancellationPolicyAccepted = User.isCancellationPolicyAccepted(user, serviceProviderSlug);
        request.adult = User.isAdult(user, serviceProviderSlug);
        request.businessId = user.businessId;
        request.countryId = user.countryId;
        request.avatar = user.avatar;
        request.roleNames = [this.state.roleName!];
        request.isEntrepreneur = this.asCaptain;
        request.allowAsPassenger = true;
        request.language = ch.language;

        const response: SignUpResponse = await this.postAsync("/api/application/signUp", request);

        if (response.businessIdNotUnique) {
            await this.alertErrorAsync(Localizer.adminAlertErrorBusinessIdNotUnique, false);
            return;
        }

        if (response.userAlreadyExists) {
            const message: string = (response.duplicatedEmail)
                ? Localizer.adminAlertErrorAccountWithSameEmailExist
                : Utility.format(Localizer.adminAlertErrorAccountExist, this.userFullName);

            await this.alertErrorAsync(message, true);
            return;
        }

        if (response.invitationSentFailed) {
            const message: string = Utility.format(Localizer.signUpAlertErrorInvitationSentFailed, this.userFullName);
            await this.alertErrorAsync(message, false);
            return;
        }

        await PageRouteProvider.redirectAsync(PageDefinitions.loginRoute);

        await this.alertMessageAsync(Localizer.signUpPageAlertErrorAccountCreated.format(this.user.email), true, true);
    }

    public getTitle(): string {
        return AppController.getFullPageTitle(Localizer.signUpPageTitleDefault);
    }

    public getSubtitle(): string {
        switch (this.roleName) {
            case AppConstants.captainRole:
                return Localizer.signUpPageSubtitleCaptainRole;
            case AppConstants.passengerRole:
                return Localizer.signUpPageSubtitlePassengerRole;
            default:
                return Localizer.signUpPageSubtitleDefault;
        }
    }

    public get step(): SignUpStep {
        return this.state.step;
    }

    public get user(): User {
        return this.state.user;
    }

    public get userFullName(): string {
        return TransformProvider.userToString(this.user);
    }

    public get roleName(): string | null {
        return this.state.roleName;
    }

    public get asCaptain(): boolean {
        return (this.roleName == AppConstants.captainRole);
    }

    public get asPassenger(): boolean {
        return (this.roleName == AppConstants.passengerRole);
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        const asPassenger: boolean = (this.parameters?.asPassenger === true);
        
        if (asPassenger) {
            await this.selectRoleAsync(AppConstants.passengerRole);
        }
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(selectRoleStyles.selectRole, this.mobile && selectRoleStyles.mobile, styles.signUp)}
                           alertClassName={boutStyles.alert}
            >
                {
                    (!this.mobile) &&
                    (
                        <PageHeader title={Localizer.signUpPageTitleDefault}
                                    subtitle={this.getSubtitle()}
                                    className={styles.header}
                        />
                    )
                }
                
                <PageRow>

                    <div className={this.css(selectRoleStyles.container, "col-lg-6")}>

                        {
                            (this.step == SignUpStep.RoleSelection) &&
                            (
                                <>
                                    
                                    <div className={selectRoleStyles.expander}/>
                                    
                                    <div className={selectRoleStyles.logo}>
                                    
                                        <img src={loading} alt="waving_boat"/>
                                    
                                    </div>

                                    <div className={selectRoleStyles.expander}/>
                                    
                                    <div className={selectRoleStyles.buttonContainer}>

                                        {
                                            (ServiceProviderController.captainSignUpAvailable) &&
                                            (
                                                <Button type={ButtonType.Primary}
                                                        block
                                                        right={false}
                                                        icon={{name: "fas ship"}}
                                                        label={Localizer.signUpPageButtonBecomeCaptainLabel}
                                                        onClick={() => this.selectRoleAsync(AppConstants.captainRole)}
                                                />
                                            )
                                        }

                                        {
                                            (ServiceProviderController.passengerSignUpAvailable) &&
                                            (
                                                <Button type={ButtonType.Orange}
                                                        block
                                                        right={false}
                                                        icon={{name: "far users"}}
                                                        label={Localizer.signUpPageButtonBecomePassengerLabel}
                                                        onClick={() => this.selectRoleAsync(AppConstants.passengerRole)}
                                                />
                                            )
                                        }

                                        <Button type={ButtonType.Dark}
                                                block
                                                right={false}
                                                icon={{name: "fas sign-in-alt"}}
                                                label={Localizer.loginPageLoginButton}
                                                route={PageDefinitions.loginRoute}
                                        />

                                    </div>

                                </>
                            )
                        }

                        {
                            (this.step == SignUpStep.PersonalInfo) &&
                            (
                                <PersonalInfo user={this.user}
                                              asCaptain={this.asCaptain}
                                              onNext={() => this.onNextAsync(SignUpStep.PersonalInfo)}
                                              onPrev={() => this.onPrevAsync(SignUpStep.PersonalInfo)}
                                />
                            )
                        }

                    </div>

                </PageRow>
                
            </PageContainer>
        );
    }
}