// autogenerated

export default class SaveCountryRequest {

    public id: string | null = null;

    public code: string | null = null;

    public name: string | null = null;

    public nativeName: string | null = null;

    public moneySymbol: string | null = null;

    public moneyThreeLetterName: string | null = null;

    public vatValue: number = 0;

    public vatPercentage: number = 0;

    public logo: string | null = null;

    public contactEmail: string | null = null;

    public contactPhone: string | null = null;

    public currency: string | null = null;

    public pricePerLiter: number = 0;

    public readonly isSaveCountryRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof SaveCountryRequest) || ((from as any).isSaveCountryRequest === true));
    }

    public static as(from?: object | null): SaveCountryRequest | null {
        return (SaveCountryRequest.is(from)) ? from as SaveCountryRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}