import React from "react";
import {ActionType, BasePageParameters, PageRouteProvider, TextAlign} from "@reapptor-apps/reapptor-react-common";
import {
    Button,
    ButtonType,
    CellModel,
    ColumnActionDefinition,
    ColumnDefinition,
    Dropdown,
    DropdownAlign,
    DropdownOrderBy,
    Grid,
    IconSize,
    JustifyContent,
    PageContainer,
    PageHeader,
    PageRow,
    SelectListItem,
    ToolbarContainer,
    ToolbarRow
} from "@reapptor-apps/reapptor-react-components";
import Area from "@/models/server/bout/Area";
import PageDefinitions from "@/providers/PageDefinitions";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import Booking from "@/models/server/bout/Booking";
import ListBookingsRequest from "@/models/server/requests/ListBookingsRequest";
import {BoatType, BookingStatus, PaymentStatus} from "@/models/Enums";
import WaypointModal from "@/pages/ServicePointsManagement/WayPointsPanel/WaypointModal/WaypointModal";
import {SortDirection} from "@reapptor-apps/reapptor-toolkit";
import ServiceProviderController from "@/pages/ServiceProviderController";
import AppController from "@/pages/AppController";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./Bookings.module.scss";

interface IBookingsProps extends BasePageParameters {
}

interface IBookingsState {
    bookingStatusFilter: BookingStatus | null,
    bookingAreaFilter: Area | null,
    areas: Area[],
    selectedSortColumnName: string | null
}

export default class Bookings extends AuthorizedPage<IBookingsProps, IBookingsState> {

    state: IBookingsState = {
        bookingStatusFilter: null,
        bookingAreaFilter: null,
        areas: [],
        selectedSortColumnName: null
    }

    private readonly _bookingGridRef: React.RefObject<Grid<Booking>> = React.createRef();
    private readonly _waypointModalRef: React.RefObject<WaypointModal> = React.createRef();
    private readonly _pageSize: number = 20;
    
    private readonly _bookingColumns: ColumnDefinition[] = [
        {
            name: "captainPassenger",
            header: Localizer.bookingsPageGridCaptainAndPassengerLanguageItemName,
            accessor: nameof<Booking>(o => o.captain),
            minWidth: 250,
            settings: {
                infoAccessor: nameof<Booking>(o => o.passenger)
            },
        },
        {
            header: Localizer.bookingsPageGridWaypointLanguageItemName,
            accessor: nameof<Booking>(o => o.waypoint),
            minWidth: 300,
            maxWidth: 300,
            className: styles.waypoint,
            settings: {
                infoAccessor: nameof<Booking>(o => o.area)
            },
            callback: (cell) => this.onClickWaypoint(cell),
            actions: [
                {
                    name: "details",
                    title: "details",
                    icon: "far info-circle",
                    type: ActionType.Default,
                    callback: (cell) => this.redirectToDetailsPageAsync(cell)
                } as ColumnActionDefinition
            ]
        },
        {
            name: "latestStatus",
            header: Localizer.bookingsPageGridStatusLanguageItemName,
            accessor: (model: Booking) => Booking.getStatusText(model),
            sorting: true,
            minWidth: 200,
            maxWidth: 200,
            noWrap: true,
        },
        {
            header: Localizer.bookingsPageGridDurationLanguageItemName,
            accessor: nameof<Booking>(o => o.duration),
            minWidth: 90,
            maxWidth: 90,
            format: "0",
            textAlign: TextAlign.Center,
            settings: {
                hideZero: true
            }
        },
        {
            group: Localizer.bookingsPageGridPriceLanguageItemName,
            header: Localizer.bookingsPageGridAmountLanguageItemName,
            accessor: (booking: Booking) => (booking.estimatedMinPrice != booking.estimatedMaxPrice)
                ? "{0:C}-{1:C} {2}".format(booking.estimatedMinPrice, booking.estimatedMaxPrice, booking.moneySymbol)
                : "{0:C} {2}".format(booking.estimatedMinPrice, booking.estimatedMaxPrice, booking.moneySymbol),
            noWrap: true,
            minWidth: 140,
            maxWidth: 140,
            textAlign: TextAlign.Right
        },
        {
            group: Localizer.bookingsPageGridPriceLanguageItemName,
            header: Localizer.bookingsPageGridActualPriceLanguageItemName,
            accessor: (booking: Booking) => (booking.price != null) ? "{0:C} {1}".format(booking.price, booking.moneySymbol) : "-",
            minWidth: 100,
            maxWidth: 100,
            noWrap: true,
            textAlign: TextAlign.Right
        },
        {
            header: Localizer.bookingsPageGridDistanceLanguageItemName,
            accessor: (booking: Booking) => booking.distance ?? booking.estimatedDistance,
            format: "0.00",
            minWidth: 90,
            maxWidth: 90,
            textAlign: TextAlign.Center,
        },
        {
            name: "boatType",
            header: Localizer.bookingsPageGridBoatTypeLanguageItemName,
            accessor: nameof<Booking>(o => o.boatType),
            minWidth: 100,
            maxWidth: 100,
            sorting: true,
            format: nameof(BoatType),
        },
        {
            name: "paymentStatus",
            header: Localizer.bookingsPageGridPaymentLanguageItemName,
            accessor: nameof<Booking>(o => o.paymentStatus),
            minWidth: 100,
            maxWidth: 100,
            sorting: true,
            format: nameof(PaymentStatus),
        },
        {
            name: "createdAt",
            header: Localizer.bookingsPageGridCreatedAtStartedAtLanguageItemName,
            accessor: nameof<Booking>(o => o.createdAt),
            minWidth: 140,
            maxWidth: 140,
            sorting: SortDirection.Desc,
            isDefaultSorting: true,
            format: "dt",
            settings: {
                infoAccessor: (model: Booking) => (model.tripStartedAt != null)
                    ? Booking.getCountryLocalTime(model, model.tripStartedAt)
                    : Booking.getCountryLocalTime(model, model.bookingTime),
            }
        },
    ]

    private async redirectToDetailsPageAsync(cell: CellModel<Booking>): Promise<void> {
        const booking: Booking = cell.model;
        
        await PageRouteProvider.redirectAsync(PageDefinitions.bookingInfo(booking.id));
    }

    private async onClickWaypoint(cell: CellModel<Booking>): Promise<void> {
        const booking: Booking = cell.model;
        
        await this._waypointModalRef.current!.openAsync(booking.waypoint, true);
    }

    private async setBookingStatusFilterAsync(selectedItem: SelectListItem | null): Promise<void> {
        const bookingStatusFilter: BookingStatus | null = (selectedItem != null)
            ? parseInt(selectedItem.value)
            : null;

        await this.setState({bookingStatusFilter});
        
        await this._bookingGridRef.current?.reloadAsync();
    }

    private async setAreaFilterAsync(selectedItem: Area | null): Promise<void> {
        await this.setState({bookingAreaFilter: selectedItem});
        
        await this._bookingGridRef.current?.reloadAsync();
    }

    private static resolveSelectedItem(bookingStatus: BookingStatus | null): SelectListItem {
        return !bookingStatus ? new SelectListItem() : EnumProvider.getBookingStatusItem(bookingStatus);
    }
    
    private async setGridSortingAsync(sortColumnName: string, sortDirection: SortDirection): Promise<void> {
        const grid: Grid<Booking> = this._bookingGridRef.current!;

        if (grid == null) return;

        const gridColumnName: string | null = this.resolveGridColumnName(sortColumnName);
        
        grid.model.sortDirection = sortDirection;
        grid.model.sortColumn = grid.columns.find(col => col.name == gridColumnName)!;
        grid.model.sortColumn!.sorting = true;
        
        await this.setState({selectedSortColumnName: sortColumnName});
        
        await grid.reRenderAsync();
        await grid.reloadAsync();
    }
    
    private resolveSortingColumnName(gridColumnName: string | null): string | null {
        switch (gridColumnName) {
            case "captainPassenger":
            case "createdStartedAt": 
                return this.state.selectedSortColumnName;
            default:
                return gridColumnName;
        }
    }

    private resolveGridColumnName(sortColumnName: string | null): string | null {
        switch (sortColumnName) {
            case "captain":
            case "passenger":
                return "captainPassenger";
            case "createdAt":
            case "startedAt":
                return "createdStartedAt";
            default:
                return sortColumnName;
        }
    }
    
    private async fetchAreasAsync(): Promise<Area[]> {
        return await AppController.listAreasAsync(ServiceProviderController.serviceProviderId);
    }
    
    public async fetchBookingsAsync(pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<Booking[]> {
        const request = new ListBookingsRequest();
        request.serviceProviderId = ServiceProviderController.serviceProviderId;
        request.bookingStatus = this.state.bookingStatusFilter;
        request.areaId = this.state.bookingAreaFilter?.id!;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.sortColumnName = this.resolveSortingColumnName(sortColumnName);
        request.sortDirection = sortDirection;
        
        return await this.postAsync("/api/booking/listBookings", request);
    }

    public getTitle(): string {
        return Localizer.bookingsPageTitle;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const areas: Area[] = await this.fetchAreasAsync();

        const bookingAreaFilter: Area | null = (areas.length == 1)
            ? areas.single()
            : null;

        await this.setState({areas, bookingAreaFilter});
    }

    private renderSortButton(sortDirection: SortDirection): React.ReactNode {
        return (
            <Button right
                    id={sortDirection == SortDirection.Asc ? "ascSorting" : "descSorting"}
                    type={ButtonType.Default}
                    icon={{name: sortDirection == SortDirection.Asc ? "far fa-sort-alpha-up" : "far fa-sort-alpha-down", size: IconSize.Large}}
            >

                <Button.Action title={Localizer.bookingsPageGridPassenger}
                               onClick={() => this.setGridSortingAsync("passenger", sortDirection)}
                />

                <Button.Action title={Localizer.bookingsPageGridCaptain}
                               onClick={() => this.setGridSortingAsync("captain", sortDirection)}
                />

                <Button.Action title={Localizer.bookingsPageGridStatus}
                               onClick={() => this.setGridSortingAsync("latestStatus", sortDirection)}
                />

                <Button.Action title={Localizer.bookingsPageGridBoatType}
                               onClick={() => this.setGridSortingAsync("boatType", sortDirection)}
                />

                <Button.Action title={Localizer.bookingsPageGridPayment}
                               onClick={() => this.setGridSortingAsync("paymentStatus", sortDirection)}
                />

                <Button.Action title={Localizer.bookingsPageGridCreatedAt}
                               onClick={() => this.setGridSortingAsync("createdAt", sortDirection)}
                />

                <Button.Action title={Localizer.bookingsPageGridStartedAt}
                               onClick={() => this.setGridSortingAsync("startedAt", sortDirection)}
                />

            </Button>
        )
    }    
    
    public render(): React.ReactNode {
        return (
            <PageContainer fullWidth fullHeight className={styles.bookings}>

                <PageHeader title={Localizer.bookingsPageTitle} subtitle={Localizer.bookingsPageSubtitle}/>

                <PageRow>

                    <div className="col">

                        <ToolbarContainer>

                            <ToolbarRow justify={JustifyContent.Start}>

                                <Dropdown id={"bookingStatusFilter"} noWrap inline
                                          minWidth={250}
                                          orderBy={DropdownOrderBy.None}
                                          align={DropdownAlign.Left}
                                          label={Localizer.bookingsPageDropdownStatusFilterName}
                                          items={EnumProvider.getBookingStatusItems()}
                                          nothingSelectedText={Localizer.bookingsPageDropdownStatusFilterNothingSelectedText}
                                          selectedItem={Bookings.resolveSelectedItem(this.state.bookingStatusFilter)}
                                          onChange={(sender, item) => this.setBookingStatusFilterAsync(item)}
                                />

                                <Dropdown id={"areaFilter"} noWrap inline
                                          minWidth={250}
                                          label={Localizer.bookingsPageDropdownAreaFilterName}
                                          selectedItem={this.state.bookingAreaFilter}
                                          items={this.state.areas}
                                          nothingSelectedText={Localizer.bookingsPageDropdownAreaFilterNothingSelectedText}
                                          onChange={(sender, item) => this.setAreaFilterAsync(item)}
                                />

                                {this.renderSortButton(SortDirection.Asc)}

                                {this.renderSortButton(SortDirection.Desc)}
                                
                            </ToolbarRow>
                            
                        </ToolbarContainer>
                        
                        <Grid id="bookings" responsive
                              ref={this._bookingGridRef}
                              pagination={this._pageSize}
                              noDataText={Localizer.genericNoData}
                              columns={this._bookingColumns}
                              fetchData={(sender, pageNumber, pageSize, sortColumnName, sortDirection) => this.fetchBookingsAsync(pageNumber, pageSize, sortColumnName, sortDirection)}
                        />
                        
                    </div>

                </PageRow>
                
                <WaypointModal ref={this._waypointModalRef}/>

            </PageContainer>
        );
    }
}