import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {Button, ButtonContainer, ButtonType, Checkbox, Dropdown, DropdownOrderBy, Form, InlineType, PageContainer, TextInput} from "@reapptor-apps/reapptor-react-components";
import BankAccount from "@/models/server/bout/BankAccount";
import {BasePageParameters, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import AddMyBankAccountResponse from "@/models/server/responses/AddMyBankAccountResponse";
import AddMyBankAccountRequest from "@/models/server/requests/AddMyBankAccountRequest";
import {AccountHolderType} from "@/models/Enums";
import User from "@/models/server/User";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import boutStyles from "../../../bout.module.scss";
import styles from "./EditBankAccount.module.scss";

export interface IEditBankAccountProps extends BasePageParameters {
    required?: boolean;
    noDeletion?: boolean;
    bankAccountId?: string;
}

interface IEditBankAccountState {
    initialized: boolean;
    required: boolean,
    noDeletion: boolean,
    bankAccount: BankAccount;
    iban: string,
}

export default class EditBankAccount extends AuthorizedPage<IEditBankAccountProps, IEditBankAccountState> {

    state: IEditBankAccountState = {
        initialized: false,
        required: false,
        noDeletion: true,
        bankAccount: new BankAccount(),
        iban: ""
    };

    private async saveBankAccountAsync(): Promise<void> {

        const request = new AddMyBankAccountRequest();
        request.default = this.isDefault;
        request.iban = this.iban;
        request.accountHolderName = this.accountHolderName;
        request.accountHolderType = this.accountHolderType;

        const response: AddMyBankAccountResponse = await this.postAsync("/api/mobileApp/addMyBankAccount", request);

        if (!response.success) {
            if (response.notValid) {
                await this.alertErrorAsync(Localizer.mobileEditBankAccountPageAlertErrorNotValid.format(request.iban), true, true);
                return;
            }

            if (response.alreadyExist) {
                await this.alertErrorAsync(Localizer.mobileEditBankAccountPageAlertErrorAccountExist, true, true);
                return;
            }
            
            await this.alertErrorAsync(Localizer.mobileEditBankAccountPageAlertErrorAccountCannotCreated, true, true);
            return;
        }

        await PageRouteProvider.redirectAsync(PageDefinitions.myPaymentMethodsRoute);

        await this.alertMessageAsync(Localizer.mobileEditBankAccountPageAlertErrorAccountAdded.format(this.bankAccount.last4), true, true);
    }
    
    private async deleteAsync(): Promise<void> {
        if (!this.noDeletion) {
            await this.postAsync("/api/mobileApp/deleteMyBankAccount", this.bankAccount.id);

            await PageRouteProvider.redirectAsync(PageDefinitions.myPaymentMethodsRoute);

            await this.alertMessageAsync(Localizer.mobileEditBankAccountPageAlertErrorAccountDeleted.format(this.bankAccount.last4), true, true);   
        }
        
        await this.alertWarningAsync(Localizer.mobileEditBankAccountPageAccountCannotBeDeleted, true, true);
    }
    
    private async setIbanAsync(value: string): Promise<void> {
        await this.setState({ iban: value });
    }
    
    private async setAccountHolderNameAsync(value: string): Promise<void> {
        this.bankAccount.holderName = value;
        
        await this.reRenderAsync();
    }
    
    private async setAccountHolderTypeAsync(value: AccountHolderType): Promise<void> {
        this.bankAccount.accountHolderType = value;
        
        await this.reRenderAsync();
    }
    
    private async setDefaultAsync(value: boolean = true): Promise<void> {
        if (this.isNew) {
            
            this.bankAccount.default = value;
            
            await this.reRenderAsync();
            
        } else {
            await this.postAsync("/api/mobileApp/setDefaultMyBankAccount", this.bankAccount.id);

            await PageRouteProvider.redirectAsync(PageDefinitions.myPaymentMethodsRoute);

            await this.alertMessageAsync("The bank account \"*{0:0000}\" has been set as default.".format(this.bankAccount.last4), true, true);
        }
    }
    
    public getTitle(): string {
        return Localizer.mobileEditBankAccountPageTitle;
    }

    public get initialized(): boolean {
        return this.state.initialized;
    }

    public get bankAccount(): BankAccount {
        return this.state.bankAccount;
    }

    public get required(): boolean {
        return this.state.required;
    }

    public get noDeletion(): boolean {
        return this.state.noDeletion;
    }

    private get isDefault(): boolean {
        return this.bankAccount.default;
    }

    private get iban(): string {
        return this.state.iban;
    }

    private get accountHolderName(): string {
        return this.bankAccount.holderName;
    }

    private get accountHolderType(): AccountHolderType {
        return this.bankAccount.accountHolderType;
    }

    public get isNew(): boolean {
        return (!this.bankAccount.id);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        const bankAccountId: string | null = (this.parameters as IEditBankAccountProps | null)?.bankAccountId || null;
        const required: boolean = ((this.parameters as IEditBankAccountProps | null)?.required === true);
        const noDeletion: boolean = ((this.parameters as IEditBankAccountProps | null)?.noDeletion === true);
        const user: User = this.getUser();
        
        let bankAccount: BankAccount;
        if (bankAccountId) {
            bankAccount = await this.postAsync("/api/mobileApp/getMyBankAccount", bankAccountId);
        } else {
            bankAccount = new BankAccount();
            bankAccount.holderName = User.getFullName(user);
            bankAccount.default = required;
        }

        await this.setState({bankAccount, required, noDeletion, initialized: true});
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.editBankAccount, this.mobile && styles.mobile)}
                           alertClassName={boutStyles.alert}
            >

                <span className={styles.header}>{this.getTitle()}</span>

                <div className={styles.bankAccountContainer}>
                    
                    {
                        (this.initialized) &&
                        (
                            <Form className={styles.bankAccount} onSubmit={() => this.saveBankAccountAsync()}>

                                <Checkbox inline
                                          inlineType={InlineType.Right}
                                          className={styles.default}
                                          readonly={this.required || !this.isNew}
                                          label={Localizer.genericDefault}
                                          value={this.isDefault}
                                          onChange={(_, value) => this.setDefaultAsync(value)}
                                />

                                <TextInput required
                                           readonly={!this.isNew}
                                           label={Localizer.mobileEditBankAccountPageTextInputAccountNumberLabel}
                                           value={this.isNew ? this.iban : BankAccount.getIban(this.bankAccount)}
                                           onChange={(_, value) => this.setIbanAsync(value)}
                                />

                                <TextInput label={Localizer.mobileEditBankAccountPageTextInputHolderNameLabel} required
                                           readonly={!this.isNew}
                                           value={this.bankAccount.holderName}
                                           onChange={(_, value) => this.setAccountHolderNameAsync(value)}
                                />

                                <Dropdown required
                                          label={Localizer.mobileEditBankAccountPageDropdownHolderTypeLabel}
                                          orderBy={DropdownOrderBy.None}
                                          disabled={!this.isNew}
                                          selectedItem={this.bankAccount.accountHolderType}
                                          items={EnumProvider.getAccountHolderTypeItems()}
                                          onChange={(_, item) => this.setAccountHolderTypeAsync(parseInt(item!.value))}
                                />

                                {
                                    (!this.isNew) &&
                                    (
                                        <>
                                            <TextInput readonly
                                                       label={Localizer.mobileEditBankAccountPageTextInputBankNameLabel}
                                                       value={this.bankAccount.bankName}
                                            />

                                            <TextInput readonly
                                                       label={Localizer.mobileEditBankAccountPageTextInputBICLabel}
                                                       value={this.bankAccount.bic}
                                            />

                                        </>
                                    )
                                }

                                <div className={styles.expander} />

                                <ButtonContainer>

                                    {
                                        ((!this.isNew) && (!this.isDefault)) &&
                                        (
                                            <Button icon={{name: "fad fa-circle"}}
                                                    label={Localizer.mobileEditBankAccountPageButtonSetDefaultLabel}
                                                    type={ButtonType.Primary}
                                                    onClick={() => this.setDefaultAsync()}
                                            />
                                        )
                                    }

                                    {
                                        (!this.isNew) &&
                                        (
                                            <Button icon={{name: "far trash-alt"}}
                                                    confirm={(!this.noDeletion) ? (Localizer.mobileEditBankAccountPageButtonDeleteAccountConfirm) : null }
                                                    label={Localizer.genericDelete}
                                                    type={ButtonType.Dark}
                                                    disabled={this.required}
                                                    onClick={() => this.deleteAsync()}
                                            />
                                        )
                                    }

                                    {
                                        (this.isNew) &&
                                        (
                                            <Button submit
                                                    icon={{name: "far save"}}
                                                    label={Localizer.genericSave}
                                                    type={ButtonType.Dark}
                                            />
                                        )
                                    }

                                </ButtonContainer>

                            </Form>
                        )
                    }

                </div>

            </PageContainer>
        );
    }
}