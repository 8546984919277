import React from "react";
import {BaseComponent, IBaseContainerComponentProps, IReactChildrenProps} from "@reapptor-apps/reapptor-react-common";
import ContainerItem, {IContainerItemProps, TBootstrapColumn} from "@/components/ContainerItem/ContainerItem";

import styles from "./Container.module.scss";

interface IContainerProps extends IBaseContainerComponentProps {
    col?: TBootstrapColumn;
}

export default class Container extends BaseComponent<IContainerProps> {

    protected extendChildProps(element: React.ReactElement): any | null {
        return {
            col: element.props.col || this.props.col
        };
    }

    public static get Item(): (props: IContainerItemProps & IReactChildrenProps) => React.ReactElement {
        return (props: IContainerItemProps & IReactChildrenProps) => <ContainerItem {...props as IContainerItemProps} />;
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(styles.container, "row", this.props.className)}>
                {this.children}
            </div>
        )
    }
}