import React from "react";
import {BaseComponent, IBaseComponent, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import Container from "@/components/Container/Container";
import CreditCard from "@/models/server/bout/CreditCard";
import Localizer from "@/localization/Localizer";

import styles from "./CreditCardContainer.module.scss";

export interface ICreditCardContainerProps {
    className?: string;
    initialized: boolean;
    creditCards: CreditCard[];
    onClick?(sender: IBaseComponent, item: CreditCard): Promise<void>;
}

export default class CreditCardContainer extends BaseComponent<ICreditCardContainerProps> {

    private async onClickAsync(item: CreditCard): Promise<void> {
        if (this.props.onClick) {
            await this.props.onClick(this, item);
        }
    }

    private renderCreditCard(creditCard: CreditCard, index: number): React.ReactNode {
        return (
            <Container.Item key={index} className={this.css(styles.item)}>
                <div key={index} className={styles.context} onClick={() => this.onClickAsync(creditCard)}>

                    <div className={styles.column1}>
                        <div className={styles.number}>
                            <span>****</span>
                            <span>****</span>
                            <span>****</span>
                            <span>{"{0:0000}".format(creditCard.last4)}</span>
                        </div>
                        <span className={styles.holderName}>{creditCard.holderName}</span>
                        <div className={styles.cardFooter}>
                            <span className={styles.brand}>{creditCard.brand?.toUpperCase()}</span>
                            <span className={this.css(styles.expiration, creditCard.isExpired && styles.expired)}>{CreditCard.getExpiration(creditCard)}</span>
                        </div>
                    </div>

                    <div className={styles.expander}/>

                    <div className={styles.column2}>
                        {
                            (creditCard.default) &&
                            (
                                <span>✓</span>
                            )
                        }
                    </div>

                </div>
            </Container.Item>
        );
    }

    public get initialized(): boolean {
        return this.props.initialized;
    }

    public get creditCards(): CreditCard[] {
        return this.props.creditCards;
    }

    public render(): React.ReactNode {
        const hasData: boolean = (this.creditCards.length > 0);

        const noCreditCardsStyle: any = (!hasData) && styles.noCreditCards;

        return (
            <div id={this.id} className={this.css(styles.creditCardContainer, noCreditCardsStyle, this.props.className)}>
                {
                    (hasData)
                        ?
                        (
                            <Container className={styles.container}>
                                {this.creditCards.map((item, index) => this.renderCreditCard(item, index))}
                            </Container>
                        )
                        :
                        (
                            <span className={styles.message}>
                                {ReactUtility.toMultiLines(this.initialized ? Localizer.mobileCreditCardContainerNoCards : Localizer.genericLoading)}
                            </span>
                        )
                }
            </div>
        )
    }
}