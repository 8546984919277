import React from "react";
import {PageContainer, PageRow} from "@reapptor-apps/reapptor-react-components";
import AnonymousPage from "@/models/base/AnonymousPage";
import {IBaseComponent, PageRoute, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import CruisePackagesPanel from "@/components/CruisePackagesPanel/CruisePackagesPanel";
import CruisePackage from "@/models/server/cruise/CruisePackage";
import CruisePackageWizardController from "@/pages/Mobile/CruisePackageWizard/CruisePackageWizardController";

import styles from "./CruisePackages.module.scss";

export interface IPackagesProps {
}

interface IPackagesState {
    cruisePackages: CruisePackage[];
}

export default class CruisePackages extends AnonymousPage<IPackagesProps, IPackagesState> {

    public state: IPackagesState = {
        cruisePackages: []
    };

    protected get cruisePackages(): CruisePackage[] {
        return this.state.cruisePackages;
    }

    public async requestTripAsync(sender: IBaseComponent, cruisePackage: CruisePackage): Promise<void> {
        // TODO: Create a cruise request in the cruise app.
        return;
    }

    public async openCruisePackageOrderPageAsync(cruisePackage: CruisePackage): Promise<void> {
        await CruisePackageWizardController.requestCruisePackageAsync(cruisePackage);
    }

    public async signInAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.loginRoute);
    }

    public async signUpAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.signUpRoute);
    }

    public static routeResolver(pathname: string): PageRoute | null {
        const page: string = "/cruise/packages";
        pathname = pathname.toLowerCase();

        if ((pathname == page) || (pathname == `${page}/`)) {
            return PageDefinitions.cruisePackagesRoute;
        }

        return null;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const cruisePackages: CruisePackage[] = await CruisePackageWizardController.listCruisePackagesAsync();

        await this.setState({ cruisePackages });
    }

    public render(): React.ReactNode {

        return (
            <PageContainer className={styles.cruisePackages} fullHeight>

                <PageRow className={this.css(styles.pageRow, (this.mobile) && styles.mobile)}>

                    <CruisePackagesPanel cruisePackages={this.cruisePackages}
                                         onSelectOrderPackage={(_, cruisePackage) => this.openCruisePackageOrderPageAsync(cruisePackage)}
                                         onSelectSignIn={() => this.signInAsync()}
                                         onSelectSignUp={() => this.signUpAsync()}
                    />

                </PageRow>

            </PageContainer>
        );
    }
}