export enum PaymentPolicy {
    /**
     * The ride will be charged upon completion of the trip.
     */
    CompletedByCaptain,

    /**
     * The ride will be charged upon captain allocation.
     */
    AcceptedByCaptain,

    /**
     * The ride will be charged right away.
     */
    CreatedByPassenger
}

export enum TicketType {
    /**
     * Adult, the default ticket type
     */
    Adult,
    
    /**
     * Children under 18
     */
    Children,

    /**
     * Bikes & Trolleys
     */
    BikesAndTrolleys,
    
    /**
     * Pensioners
     */
    Pensioners
}

export enum ApplicationType {
    Ride,

    Cruise,
    
    Shuttle
}

export enum CruisePackageOwnerType {
    ServiceProvider,

    Captain
}

export enum CruisePackagePriceInterval {
    MondaySunday,
    
    MondayTuesday,
    
    WednesdaySunday
}

export enum BookingType {
    Ride,

    CruisePackage,

    Shuttle,
}

export enum PostTripEmailType
{
    Complain,

    ForgotSomething
}

export enum TimePeriod
{
    All,

    Week,

    Month,

    Year
}

export enum StripeKycState
{
    NotStarted,

    InProgress,

    Failed,

    WaitingForApproval,

    Verified
}

export enum AccountHolderType
{
    Individual,
    
    Company
}

export enum ActivityStatus
{
    Active,

    Inactive
}

export enum BankAccountStatus
{
    New
}

export enum BoatType
{
    Small,

    SmallElectrical,

    Medium,

    MediumSpeed,

    MediumElectrical,

    Large,

    LargeSpeed,

    LargeElectrical,
    
    Classic,

    SmallSail,

    MediumSail,

    LargeSail,

    Waterbus,

    WaterbusElectical,
    
    ExtraLarge,

    ExtraLargeSpeed,

    ExtraLargeElectrical
}

export enum BookingStatus
{
    New,
    
    CompletedByCaptain,

    StartByCaptain,

    AcceptedByCaptain,
    
    CancelledByCaptain,
    
    CancelledByPassenger
}

export enum CreditCardBrandType
{
    Visa
}

export enum HslZoneType
{
    A,

    B,

    C,

    D
}

export enum PaymentStatus
{
    None,
    
    Pending,
    
    Paid
}

export enum StripeAccountType
{
    Custom
}

export enum LoginResultStatus {
    Success,

    NotFound,

    Deleted,

    Locked,

    /**
     * Token security stamp does not correspond to user security stamp
     */
    TokenInvalidSecurityStamp,

    /**
     * Token already used
     */
    TokenUsed,

    /**
     * Token invalid or expired
     */
    TokenInvalidOrExpired
}

export enum WorkDayState {
    Normal = 0,

    /**
     * Sairas
     */
    SickLeave = 1,

    /**
     * Loma
     */
    Vacation = 2,

    /**
     * Vko lepo
     */
    WeeklyRest = 3,

    /**
     * Maksullinen vapaapäivä
     */
    PaidDayOff = 4,

    /**
     * Pekkanen
     */
    FlexHours = 5,
}

export enum UserRoleGroup {
    Admins,

    Captains,

    Passengers,

    ContactPersons
}

export enum InvitationType {
    Invitation,

    ResetPassword,

    ForgotPassword,
    
    CaptainRequest,
    
    SignUp,

    CaptainAcceptanceNotification
}

export enum NotificationType {
    Sms,

    Application,

    Push,

    Email
}

export enum AuthType {
    Email
}

export enum AuditTimestamp {
    CreatedAt,

    ModifiedAt
}

export enum SavePasswordResultStatus {
    Success,

    WeakPassword,

    WrongCurrent
}

export enum OrganizationContractLevel {
    Operator = 0,

    Company = 1,

    SubCompany = 2
}

export enum OrganizationContractType {
    Customer = 0,

    Subcontractor = 1
}

export enum DayOfWeek {
    Sunday,

    Monday,

    Tuesday,

    Wednesday,

    Thursday,

    Friday,

    Saturday,
}