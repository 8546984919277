import React from "react";
import {BaseComponent, IBaseComponentProps} from "@reapptor-apps/reapptor-react-common";
import {AddressHelper, Icon, IconSize} from "@reapptor-apps/reapptor-react-components";
import Booking from "@/models/server/bout/Booking";
import BookingAvatar, {BookingAvatarType} from "@/components/BookingAvatar/BookingAvatar";
import BoatImage from "@/models/server/BoatImage";
import Boat from "@/models/server/bout/Boat";
import User from "@/models/server/User";
import {GeoCoordinate, Utility} from "@reapptor-apps/reapptor-toolkit";
import Waypoint from "@/models/server/bout/Waypoint";
import CruisePackage from "@/models/server/cruise/CruisePackage";
import AppConstants from "@/helpers/AppConstants";
import Localizer from "@/localization/Localizer";

import styles from "./OngoingTrip.module.scss";

const PassengerDistanceAccuracyInMeters: number = 50;

interface IOngoingTripProps extends IBaseComponentProps {
    booking: Booking;
    captainLocation: GeoCoordinate | null;
    passengerLocation: GeoCoordinate | null;
}

interface IOngoingTripState {
    expanded: boolean;
}

export default class OngoingTrip extends BaseComponent<IOngoingTripProps, IOngoingTripState> {

    state: IOngoingTripState = {
        expanded: true,
    };

    private getCaptainDistanceInMeters(captainLocation: GeoCoordinate | null): number | null {
        const pickUpLocation: GeoCoordinate | null = this.booking.waypoint?.source?.location ?? null;
        if ((captainLocation) && (pickUpLocation)) {
            const distance: number = 1000 * AddressHelper.distance(pickUpLocation, captainLocation);
            return Math.trunc(distance);
        }

        return null;
    }

    private getPassengerDistanceInMeters(passengerLocation: GeoCoordinate | null): number | null {
        const pickUpLocation: GeoCoordinate | null = this.booking.waypoint?.source?.location ?? null;
        if ((passengerLocation) && (pickUpLocation)) {
            const distance: number = 1000 * AddressHelper.distance(pickUpLocation, passengerLocation);
            return Math.trunc(distance);
        }

        return null;
    }

    private getCaptainsDurationInMinutes(distanceInMeters: number | null): number | null {
        const boatCruiseSpeed: number | null = this.boat?.cruiseSpeed || null;
        if ((distanceInMeters) && (boatCruiseSpeed)) {
            const boatCruiseSpeedInKmPerHour: number = AppConstants.kilometersPerNauticalMile * boatCruiseSpeed;
            const cruiseSpeedInMetersPerMinutes: number = 1000 * boatCruiseSpeedInKmPerHour / 60;
            return Math.trunc(distanceInMeters / cruiseSpeedInMetersPerMinutes);
        }

        return null;
    }

    public async toggleAsync(): Promise<void> {
        const expanded: boolean = !this.expanded;
        await this.setState({expanded});
    }

    public get booking(): Booking {
        return this.props.booking;
    }

    public get captainLocation(): GeoCoordinate | null {
        return this.props.captainLocation;
    }

    public get passengerLocation(): GeoCoordinate | null {
        return this.props.passengerLocation;
    }

    public get boat(): Boat | null {
        return this.booking.boat;
    }

    public get captain(): User | null {
        return this.booking.captain;
    }

    public get waypoint(): Waypoint | null {
        return this.booking.waypoint;
    }

    public get cruisePackage(): CruisePackage | null {
        return this.booking.cruisePackage;
    }

    public get boatPrimaryImage(): BoatImage | null {
        return Boat.findPrimaryImage(this.boat);
    }

    public get captainAvatarId(): string | null {
        return this.captain?.avatarId || null;
    }

    public get tripTitle(): string {
        return (this.cruisePackage)
            ? "{0:t} {1}".format(this.booking.bookingTime, this.cruisePackage.name)
            : (this.waypoint)
                ? "{0:t} {1}".format(this.booking.bookingTime, this.waypoint)
                : "";
    }

    public get expanded(): boolean {
        return this.state.expanded;
    }

    public render(): React.ReactNode {
        const captainDistance: number | null = this.getCaptainDistanceInMeters(this.captainLocation);
        const passengerDistance: number | null = this.getPassengerDistanceInMeters(this.passengerLocation);
        const captainDuration: number | null = this.getCaptainsDurationInMinutes(captainDistance);
        const passengerAtDepartureLocation: boolean = (passengerDistance != null) && (passengerDistance <= PassengerDistanceAccuracyInMeters);

        const expandedStyle = (this.expanded) ? styles.expanded : styles.collapsed;
        const passengerWarningStyle = (!passengerAtDepartureLocation) && styles.warning;

        return (
            <div id={this.id}
                 className={this.css(styles.ongoingTrip, this.props.className, expandedStyle, this.mobile && styles.mobile)}>

                <div>

                    <div className={styles.expander} onClick={() => this.toggleAsync()}>
                        <span>{this.expanded ? Localizer.mobileOngoingTripPageHide : Localizer.mobileOngoingTripPageShow}</span>
                        <Icon name={(this.expanded ? "fal fa-angle-down" : "fal fa-angle-up")} size={IconSize.X2}/>
                    </div>

                    {
                        (this.expanded) &&
                        (
                            <div className={this.css(styles.body, expandedStyle)}>

                                <div className={styles.data}>

                                    <div className={styles.avatarContainer}>

                                        <BookingAvatar type={BookingAvatarType.Boat}
                                                       boatImage={this.boatPrimaryImage}
                                                       avatar={this.captainAvatarId}
                                        />

                                    </div>

                                    <div className={styles.mainContainer}>

                                        <span
                                            className={styles.title}>{Localizer.mobileOngoingTripPageRideInProgress}</span>

                                        {
                                            (this.boat) &&
                                            (
                                                <span className={styles.boat}>
                                                    {"{0}".format(this.boat)}
                                                    {
                                                        ((captainDistance != null) && (captainDistance >= 10) && (captainDuration)) &&
                                                        (
                                                            <span className={styles.duration}>{Localizer.mobileOngoingTripPageCaptainDistance.format(captainDistance, captainDuration)}</span>
                                                        )
                                                    }
                                                </span>
                                            )
                                        }

                                        <span className={styles.captain}>{Utility.formatValue(this.captain)}</span>

                                        <span className={styles.tripTitle}>{this.tripTitle}</span>

                                    </div>

                                </div>

                                {
                                    (passengerDistance != null) &&
                                    (
                                        <span className={this.css(styles.passengerLocation, passengerWarningStyle)}>
                                            {
                                                (passengerAtDepartureLocation)
                                                    ? Localizer.mobileOngoingTripPagePassengerLocationSuccess
                                                    : Localizer.mobileOngoingTripPagePassengerLocationWarning.format(passengerDistance)
                                            }
                                        </span>
                                    )
                                }

                            </div>
                        )
                    }

                </div>

            </div>
        )
    }
}