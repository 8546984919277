import React from "react";
import {BaseComponent, IBaseComponent, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import Container from "@/components/Container/Container";
import BankAccount from "@/models/server/bout/BankAccount";
import Localizer from "@/localization/Localizer";

import styles from "./BankAccountContainer.module.scss";

export interface IBankAccountContainerProps {
    className?: string;
    initialized: boolean;
    bankAccounts: BankAccount[];
    onClick?(sender: IBaseComponent, item: BankAccount): Promise<void>;
}

export default class BankAccountContainer extends BaseComponent<IBankAccountContainerProps> {

    private async onClickAsync(item: BankAccount): Promise<void> {
        if (this.props.onClick) {
            await this.props.onClick(this, item);
        }
    }

    private renderBankAccount(bankAccount: BankAccount, index: number): React.ReactNode {
        return (
            <Container.Item key={index} className={this.css(styles.item)}>
                <div key={index} className={styles.context} onClick={() => this.onClickAsync(bankAccount)}>

                    <div className={styles.column1}>
                        <span className={styles.number}>{BankAccount.getIban(bankAccount)}</span>
                        <span className={styles.accountHolderName}>{bankAccount.holderName}</span>
                        <span className={styles.bank}>{bankAccount.bankName}<small>{bankAccount.bic}</small></span>
                    </div>
                    
                    <div className={styles.expander} />

                    <div className={styles.column2}>
                        {
                            (bankAccount.default) &&
                            (
                                <span>✓</span>
                            )
                        }
                    </div>
                    
                </div>
            </Container.Item>
        );
    }

    public get initialized(): boolean {
        return this.props.initialized;
    }

    public get bankAccounts(): BankAccount[] {
        return this.props.bankAccounts;
    }

    public render(): React.ReactNode {
        const hasData: boolean = (this.bankAccounts.length > 0);
        
        const noBankAccountsStyle: any = (!hasData) && styles.noBankAccounts;
        
        return (
            <div id={this.id} className={this.css(styles.bankAccountContainer, noBankAccountsStyle, this.props.className)}>
                {
                    (hasData)
                        ?
                        (
                            <Container className={styles.container}>
                                {this.bankAccounts.map((item, index) => this.renderBankAccount(item, index))}
                            </Container>
                        )
                        :
                        (
                            <span className={styles.message}>
                                {ReactUtility.toMultiLines(this.initialized ? Localizer.mobileBankAccountContainerNoAccounts : Localizer.genericLoading)}
                            </span>
                        )
                }
            </div>
        )
    }
}