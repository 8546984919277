import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {BaseRegexValidatorErrorMessage, Button, ButtonContainer, ButtonType, Dropdown, Form, PageContainer, PageRow, PhoneInput, TextInput, TwoColumns} from "@reapptor-apps/reapptor-react-components";
import User from "@/models/server/User";
import ImageProvider from "@/providers/ImageProvider";
import ProfileModal from "@/pages/Mobile/Profile/ProfileModal/ProfileModal";
import {FileModel, ILanguage} from "@reapptor-apps/reapptor-toolkit";
import {ch, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import ValidatePhoneNumberRequest from "@/models/server/requests/ValidatePhoneNumberRequest";
import UpdateMyProfileRequest from "@/models/server/requests/UpdateMyProfileRequest";
import AppController from "@/pages/AppController";
import ServiceProviderController from "@/pages/ServiceProviderController";
import Localizer from "@/localization/Localizer";

import boutStyles from "../../../bout.module.scss";
import styles from "./Profile.module.scss";

import thumbnail from "../../../img/thumbnail.png";

interface IProfileProps {
}

interface IProfileState {
    me: User | null,
    isValidPhoneNumber: boolean
}

export default class Profile extends AuthorizedPage<IProfileProps, IProfileState> {

    state: IProfileState = {
        me: null,
        isValidPhoneNumber: true
    };

    private readonly _profileModalRef: React.RefObject<ProfileModal> = React.createRef();

    public getTitle(): string {
        return (this.me)
            ? `${Localizer.mobileProfilePageTitle} <small>${this.me!.email}</small>`
            : Localizer.mobileProfilePageTitle;
    }

    public get me(): User {
        return this.state.me!;
    }

    public get asCaptain(): boolean {
        return AppController.asCaptain;
    }

    public get asPassenger(): boolean {
        return AppController.asPassenger;
    }

    public get allowedAsCaptain(): boolean {
        return AppController.allowedAsCaptain;
    }

    public get captainStripeKycVerified(): boolean {
        return AppController.captainStripeKycVerified;
    }

    private async setFirstNameAsync(value: string): Promise<void> {
        this.me.firstname = value;
    }

    private async setLastNameAsync(value: string): Promise<void> {
        this.me.lastName = value.trim();
    }

    private async setPhoneAsync(value: string): Promise<void> {
        this.me.phone = value.trim();
    }

    private async setLanguageAsync(language: ILanguage): Promise<void> {
        this.me.language = language.code;
    }
    
    public validatePhoneNumber(): string | null {
        return (!this.state.isValidPhoneNumber)
            ? Localizer.get(BaseRegexValidatorErrorMessage.validatorsPhoneLanguageItemName)
            : null;
    }

    private async onBlurAsync(sender: PhoneInput): Promise<void> {
        const isValidPhoneNumber: boolean = await this.validatePhoneNumberAsync(this.me!.phone);

        if (isValidPhoneNumber !== this.state.isValidPhoneNumber) {
            this.state.isValidPhoneNumber = isValidPhoneNumber;
            await sender.validateAsync();
        }
    }

    private async validatePhoneNumberAsync(value: string | null): Promise<boolean> {
        if (value) {
            const request = new ValidatePhoneNumberRequest();
            request.value = value;
            request.countryId = this.me!.countryId;
            request.personalOnly = false;

            return await this.postAsync("/api/user/isPhoneNumberValid", request);
        }

        return false;
    }
    
    private async updateProfileAsync(): Promise<void> {

        if (this.asCaptain && !this.me.avatar) {
            await this.alertWarningAsync(Localizer.mobileProfilePageAlertMessageCaptainAvatarIsMandatory, true, true);
            return;
        }

        const request = new UpdateMyProfileRequest();
        request.userId = this.me.id;
        request.firstname = this.me.firstname;
        request.lastName = this.me.lastName;
        request.phoneNumber = this.me.phone;
        request.avatar = this.me.avatar;
        request.language = this.me.language;

        await this.postAsync("/api/mobileApp/updateMyProfile", request);

        if (this.me.language) {
            await ch.setLanguageAsync(this.me.language);
        }
        
        await this.alertMessageAsync(Localizer.mobileProfilePageAlertMessageAccountUpdated, true, true);
    }

    public async deleteProfileAsync(): Promise<void> {
        if (this.asPassenger) {
            if (this.me.isCaptain) {
                await this.alertWarningAsync(Localizer.mobileProfilePageAlertWarningIsCaptain, false, true);
                return;
            } else if (AppController.hasPendingPayments){
                await this.alertWarningAsync(Localizer.mobileProfilePageAlertWarningHasPendingPayments,true, true);
                return;
            }
            
            const user: User = this.me;

            const confirm: boolean = await this.confirmAsync(Localizer.mobileProfilePageRemoveAccountConfirm);

            if (confirm) {
                await this.postAsync("/api/mobileApp/deleteMyProfile", user.id);

                await PageRouteProvider.redirectAsync(PageDefinitions.logoutRoute);

                await this.alertMessageAsync(Localizer.mobileProfilePageAlertMessageAccountRemoved, true, true);
            }
        }
    }
    
    private async openProfileModalAsync(image: FileModel | null): Promise<void> {
        if (this._profileModalRef.current) {
            await this._profileModalRef.current!.onOpenAsync(image);
        }
    }

    private async updateAvatarAsync(avatar: FileModel | null): Promise<void> {
        this.me.avatar = avatar;

        if (avatar == null) {
            this.me.avatarId = "";
        }

        await this.reRenderAsync();
    }

    private async redirectToSettingsPageAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.settingsRoute);
    }

    private async redirectToResetPasswordPageAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.resetPasswordRoute);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const me: User = await this.postAsync("/api/account/me", this.route);

        await this.setState({me});
    }

    public render(): React.ReactNode {
        const asCaptainStyle = this.asCaptain && styles.asCaptain;
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.profile, asCaptainStyle)}
                           alertClassName={boutStyles.alert}
            >
                
                <span className={styles.header}>{this.toMultiLines(this.getTitle())}</span>
                
                {
                    (this.me) &&
                    (
                        <PageRow className={styles.container}>
                            
                            <div className={styles.imageContainer}>

                                <div className={styles.avatar}>

                                    <img alt={Localizer.genericProfileImage}
                                         src={ImageProvider.getImageSrc(this.me.avatar) || thumbnail}
                                         onClick={() => this.openProfileModalAsync(this.me.avatar)}
                                    />

                                </div>

                            </div>

                            <div className={styles.content}>

                                <Form className={styles.form} onSubmit={() => this.updateProfileAsync()}>

                                    <TwoColumns>

                                        <TextInput required trim
                                                   id={"firstname"}
                                                   label={Localizer.formInputFirstname}
                                                   value={this.state.me!.firstname}
                                                   onChange={(_, value) => this.setFirstNameAsync(value)}
                                        />

                                        <TextInput required trim
                                                   id={"lastname"}
                                                   label={Localizer.formInputLastname}
                                                   value={this.state.me!.lastName}
                                                   onChange={(_, value) => this.setLastNameAsync(value)}
                                        />

                                    </TwoColumns>

                                    <TwoColumns>

                                        <PhoneInput trim required
                                                    id="phone"
                                                    label={Localizer.formInputPhone}
                                                    validators={[() => this.validatePhoneNumber()]}
                                                    value={this.me.phone}
                                                    onBlur={(sender) => this.onBlurAsync(sender as PhoneInput)}
                                                    onChange={(_, value) => this.setPhoneAsync(value)}
                                        />

                                        <Dropdown id="language" required
                                                  label={Localizer.formInputLanguage}
                                                  items={Localizer.supportedLanguages}
                                                  selectedItem={Localizer.findLanguage(this.me.language)}
                                                  onChange={(_, item) => this.setLanguageAsync(item!)}
                                        />

                                    </TwoColumns>

                                    <div className={styles.expander}/>

                                    <ButtonContainer className={styles.buttonContainer}>

                                        <Button block
                                                right={false}
                                                type={ButtonType.Link}
                                                label={Localizer.adminButtonResetPassword}
                                                onClick={() => this.redirectToResetPasswordPageAsync()}
                                        />

                                        <Button block submit
                                                right={false}
                                                type={ButtonType.Dark}
                                                label={Localizer.genericUpdate}
                                                icon={{name: "fal save"}}
                                        />

                                        {
                                            ((ServiceProviderController.isHds) && ((this.asPassenger) || ((this.asCaptain) && (this.allowedAsCaptain) && (this.captainStripeKycVerified)))) &&
                                            (
                                                <Button block
                                                        right={false}
                                                        type={ButtonType.Dark}
                                                        label={Localizer.settingsPageTitle}
                                                        icon={{name: "fal fa-cog"}}
                                                        onClick={() => this.redirectToSettingsPageAsync()}
                                                />
                                            )
                                        }

                                        {
                                            (!this.asCaptain) &&
                                            (
                                                <Button block
                                                        right={false}
                                                        type={ButtonType.Dark}
                                                        label={Localizer.genericDelete}
                                                        icon={{name: "fal trash-alt"}}
                                                        onClick={() => this.deleteProfileAsync()}
                                                />
                                            )
                                        }

                                    </ButtonContainer>

                                </Form>

                            </div>

                        </PageRow>
                    )
                }

                <ProfileModal ref={this._profileModalRef}
                              onClose={(image) => this.updateAvatarAsync(image)}
                />

            </PageContainer>
        );
    }
}