// autogenerated
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class ListSlugServiceProvidersRequest extends ETagRequest {

    public readonly isListSlugServiceProvidersRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ListSlugServiceProvidersRequest) || ((from as any).isListSlugServiceProvidersRequest === true));
    }

    public static as(from?: object | null): ListSlugServiceProvidersRequest | null {
        return (ListSlugServiceProvidersRequest.is(from)) ? from as ListSlugServiceProvidersRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}