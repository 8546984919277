// autogenerated

export default class ValidatePhoneNumberRequest {

    public value: string | null = null;

    public countryId: string | null = null;

    public personalOnly: boolean = false;

    public readonly isValidatePhoneNumberRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ValidatePhoneNumberRequest) || ((from as any).isValidatePhoneNumberRequest === true));
    }

    public static as(from?: object | null): ValidatePhoneNumberRequest | null {
        return (ValidatePhoneNumberRequest.is(from)) ? from as ValidatePhoneNumberRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}