import React from "react";
import {BaseComponent, ch, UserInteractionDataStorage} from "@reapptor-apps/reapptor-react-common";
import {AddressHelper, Layout} from "@reapptor-apps/reapptor-react-components";
import AppConstants from "@/helpers/AppConstants";
import CruisePackageDetails from "@/pages/CruisePackageDetails/CruisePackageDetails";
import CruisePackages from "@/pages/CruisePackages/CruisePackages";
import Landing from "@/pages/Landing/Landing";
import MyTrips from "@/pages/Mobile/MyTrips/MyTrips";
import CruisePackageWizardController from "@/pages/Mobile/CruisePackageWizard/CruisePackageWizardController";
import ServiceProviderController from "@/pages/ServiceProviderController";
import AppController from "@/pages/AppController";

import appLogoWhite from "./app_logo_plain_white.png";

import styles from "./bout.module.scss";
import mobileAppStyles from "./pages/Mobile/mobile.module.scss";

class App extends BaseComponent {
    
    public render(): React.ReactNode {
        return (
            <Layout className={() => this.css(styles.layout, AppController.mobileAppContent && mobileAppStyles.mobile, AppController.mobileAppContent && "mobileAppContent", ch.desktop && "desktop")}
                    topNavClassName={() => this.css(AppController.mobileAppContent && mobileAppStyles.topNav)}
                    topNavLogo={appLogoWhite}
                    topNavLogoText={AppConstants.applicationTitle}
                    leftNav={() => AppController.getLeftNav()}
                    languages={() => AppController.languages}
                    notifications={() => AppController.getNotifications()}
                    footerName={() => AppController.companyName}
                    footerLinks={() => AppController.footerLinks}
                    onLogoClick={() => AppController.onLogoClickAsync()}
            />
        );
    }
    
}

// Register initialize events
ch.registerInitializeCallback(() => AddressHelper.loadGoogleLibraryAsync());
ch.registerInitializeCallback(() => AppController.initializeAsync());
ch.registerInitializeCallback(() => ServiceProviderController.initializeAsync());
// Register authorize events
ch.registerAuthorizeCallback(() => UserInteractionDataStorage.onAuthorize());
ch.registerAuthorizeCallback(() => AppController.authorizeAsync());
ch.registerAuthorizeCallback(() => CruisePackageWizardController.authorizeAsync());
// Register route resolver
ch.registerRouteResolver(() => ServiceProviderController.serviceProviderRouteResolver());
ch.registerRouteResolver((pathname: string) => CruisePackages.routeResolver(pathname));
ch.registerRouteResolver((pathname: string) => CruisePackageDetails.routeResolver(pathname));
ch.registerRouteResolver((pathname: string) => Landing.routeResolver(pathname));
ch.registerRouteResolver((pathname: string) => MyTrips.routeResolver(pathname));

export default App;