import React from "react";
import {Button, ButtonType, PageContainer} from "@reapptor-apps/reapptor-react-components";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import UserInvitation from "@/models/server/UserInvitation";
import User from "@/models/server/User";
import UserRole from "@/models/server/UserRole";
import {PageRoute, PageRouteProvider, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import boutStyles from "../../../bout.module.scss";
import styles from "./RoleNotAllowed.module.scss";

interface IRoleNotAllowedProps {
}

interface IRoleNotAllowedState {
    pendingRequest: UserInvitation | null;
}

export default class RoleNotAllowed extends AuthorizedPage<IRoleNotAllowedProps, IRoleNotAllowedState> {

    state: IRoleNotAllowedState = {
        pendingRequest: null
    };

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (this.asCaptain) {
            const pendingRequest: UserInvitation | null = await this.getAsync("/api/mobileApp/findCaptainPendingRequest");
            await this.setState({pendingRequest});
        }
    }

    public async redirectToContactPage(): Promise<void> {
        const route = new PageRoute(PageDefinitions.contactSupportRouteName);
        await PageRouteProvider.redirectAsync(route);
    }

    public getTitle(): string {
        return Localizer.roleNotAllowedPageTitle;
    }

    public get asCaptain(): boolean {
        return AppController.asCaptain;
    }

    public get user(): User {
        return this.getUser();
    }

    public get userRole(): UserRole | null {
        return this.getContext().role;
    }

    public get message(): string {
        return (this.pendingRequest)
            ? Localizer.roleNotAllowedPageRequestRoleAllowed.format(this.user.role, this.pendingRequest.createdAt)
            : Localizer.roleNotAllowedPageRequestRoleNotAllowed.format(this.user, this.userRole);
    }

    public get pendingRequest(): UserInvitation | null {
        return this.state.pendingRequest;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.roleNotAllowed, this.mobile && styles.mobile)}
                           alertClassName={boutStyles.alert}
            >
                
                <div className={styles.container}>

                    <span>{ReactUtility.toMultiLines(this.message)}</span>

                    <div className={styles.expander} />

                    <Button className={styles.buttons}
                            label={Localizer.topNavContactSupport}
                            icon={{name: "fal user-headset"}}
                            type={ButtonType.Primary}
                            route={PageDefinitions.contactSupportRoute}
                    />

                </div>

            </PageContainer>
        );
    }
}