// autogenerated

export default class ForgotPasswordRequest {

    public username: string | null = null;

    public readonly isForgotPasswordRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ForgotPasswordRequest) || ((from as any).isForgotPasswordRequest === true));
    }

    public static as(from?: object | null): ForgotPasswordRequest | null {
        return (ForgotPasswordRequest.is(from)) ? from as ForgotPasswordRequest : null;
    }

    // #region Placeholder

    constructor(username: string) {
        this.username = username;
    }

    // #endregion
}