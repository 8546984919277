import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import ServicePoint from "@/models/server/bout/ServicePoint";
import {GeoCoordinate} from "@reapptor-apps/reapptor-toolkit";
import {
    Button,
    ButtonType,
    Dropdown,
    DropdownAlign,
    DropdownOrderBy,
    Icon,
    IconSize
} from "@reapptor-apps/reapptor-react-components";
import {ApplicationType} from "@/models/Enums";
import Waypoint from "@/models/server/bout/Waypoint";
import SetFavoriteRequest from "@/models/server/requests/SetFavoriteRequest";
import Localizer from "@/localization/Localizer";

import routeImage from "./Images/Route.png";

import styles from "./NewTrip.module.scss";

interface INewTripProps {
    id?: string;
    className?: string;
    noSubtext?: boolean;
    source?: ServicePoint | null;
    destination?: ServicePoint | null;
    mapLocation: GeoCoordinate;
    servicePoints: ServicePoint[];
    applicationType: ApplicationType;
    expanded: boolean;
    fetchDestinationWaypoints?(sender: NewTrip, source: ServicePoint): Promise<Waypoint[]>;
    onChange?: (sender: NewTrip) => Promise<void>;
    findBoats?: (sender: NewTrip, waypoint: Waypoint) => Promise<void>;
    preBook?: (sender: NewTrip, waypoint: Waypoint) => Promise<void>;
}

interface INewTripState {
    expanded: boolean;
    source: ServicePoint | null,
    destination: ServicePoint | null,
    waypoints: Waypoint[],
    waypoint: Waypoint | null,
}

export default class NewTrip extends BaseComponent<INewTripProps, INewTripState> {

    state: INewTripState = {
        expanded: this.props.expanded,
        source: null,
        destination: null,
        waypoints: [],
        waypoint: null,
    };
    
    private async setFavoriteAsync(servicePoint: ServicePoint, favorite: boolean = true): Promise<void> {
        const request = new SetFavoriteRequest();
        request.id = servicePoint.id;
        request.favorite = favorite;
        
        await this.postAsync("/api/user/setFavorite", request);

        servicePoint.favorite = favorite;
    }

    private async fetchDestinationWaypoints(source: ServicePoint): Promise<void> {
        const waypoints: Waypoint[] = (this.props.fetchDestinationWaypoints)
            ? await this.props.fetchDestinationWaypoints(this, source)
            : await this.postAsync("/api/mobileApp/listDestinationWaypoints", source.id);

        await this.setState({waypoints});
    }

    private async setWaypoint(destination: ServicePoint | null): Promise<void> {
        const waypoint: Waypoint | null = (destination)
            ? this.destinationWaypoints.find(item => item.destinationId == destination.id) || null
            : null;
        
        if (waypoint == null) {
            destination == null;
        }
        
        await this.setState({waypoint, destination});
    }
    
    private async setSourceAsync(source: ServicePoint | null, destination: ServicePoint | null = null, userInteraction: boolean = true): Promise<void> {
        if (!userInteraction) {
            return;
        }
        
        if (source != null) {
            if ((this.source == null) || (this.source.id != source.id)) {
                
                await this.fetchDestinationWaypoints(source);
                
                await this.setWaypoint(destination);
                
                await this.setState({source});
            }
        } else {
            await this.setState({source: null, destination: null, waypoint: null, waypoints: []});
        }

        if (this.props.onChange) {
            await this.props.onChange(this);
        }
    }

    private async setDestinationAsync(destination: ServicePoint | null, userInteraction: boolean = true): Promise<void> {
        if (!userInteraction) {
            return;    
        }
        
        await this.setWaypoint(destination);

        await this.setState({destination});

        if (this.props.onChange) {
            await this.props.onChange(this);
        }
    }

    private async findBoatsAsync(): Promise<void> {
        if ((this.props.findBoats) && (this.waypoint)) {
            await this.props.findBoats(this, this.waypoint!);
        }
    }

    private async preBookAsync(): Promise<void> {
        if ((this.props.preBook) && (this.waypoint)) {
            await this.props.preBook(this, this.waypoint);
        }
    }
    
    public async setSourceAndDestinationAsync(source: ServicePoint, destination: ServicePoint | null = null): Promise<void> {
        if (destination == null) {
            await this.setSourceAsync(source, destination);
        } else {
            await this.setDestinationAsync(destination);
        }
    }

    public async toggleAsync(): Promise<void> {
        const expanded: boolean = !this.expanded;
        await this.setState({expanded});
    }

    public async expandAsync(): Promise<void> {
        if (!this.expanded) {
            await this.toggleAsync();
        }
    }

    public async collapseAsync(): Promise<void> {
        if (this.expanded) {
            await this.toggleAsync();
        }
    }

    public async swapAsync(): Promise<void> {
        if (this.waypoint) {
            const source: ServicePoint = this.waypoint.destination!;
            const destination: ServicePoint = this.waypoint.source!;
            
            await this.setSourceAsync(source, destination);
        }
    }

    public get mapLocation(): GeoCoordinate {
        return this.props.mapLocation;
    }

    public get waypoint(): Waypoint | null {
        return this.state.waypoint;
    }

    public get destinationWaypoints(): Waypoint[]{
        return this.state.waypoints;
    }

    public get ready(): boolean {
        return (this.waypoint != null);
    }

    public get source(): ServicePoint | null {
        return this.state.source;
    }

    public get sourceServicePoints(): ServicePoint[] {
        return this.props.servicePoints;
    }

    public get destination(): ServicePoint | null {
        return this.state.destination;
    }

    public get destinationServicePoints(): ServicePoint[] {
        return this.state.waypoints.map(item => item.destination!);
    }

    public get expanded(): boolean {
        return this.state.expanded;
    }

    public get applicationType(): ApplicationType {
        return this.props.applicationType;
    }
    
    public get preBookLabel(): string {
        return (this.applicationType == ApplicationType.Ride)
            ? Localizer.mobileNewTripPageButtonPreBook
            : Localizer.mobileNewTripPageButtonBook;
    }
    
    public async initializeAsync(): Promise<void> {
        if (this.props.source) {
            await this.fetchDestinationWaypoints(this.props.source);
            
            await this.setState({source: this.props.source});
            
            if (this.props.destination) {
                await this.setWaypoint(this.props.destination);
                
                await this.setState({destination: this.props.destination});
            }
        }
    }
    
    public render(): React.ReactNode {
        const expandedStyle: string = (this.expanded) ? styles.expanded : styles.collapsed;

        return (
            <div id={this.id} className={this.css(styles.newTrip, this.props.className, expandedStyle, this.mobile && styles.mobile)}>

                <div>
                    
                    {
                        (this.expanded) &&
                        (
                            <div className={this.css(styles.body, expandedStyle)}>

                                <div className={styles.labelContainer}>
                                    <span>{Localizer.mobileNewTripPageNewTrip}</span>
                                </div>

                                <div className={styles.mainContainer}>

                                    <div className={styles.routeContainer}>
                                        <img alt={"route"} src={routeImage}/>
                                    </div>

                                    <div className={styles.selectorContainer}>

                                        <Dropdown favorite filterFavorite filterAutoFocus clearButton
                                                  toggleIcon={false}
                                                  noSubtext={this.props.noSubtext}
                                                  className={styles.selector}
                                                  nothingSelectedText={Localizer.mobileNewTripPageDropdownSourceNothingSelectedText}
                                                  align={DropdownAlign.Left}
                                                  items={this.sourceServicePoints}
                                                  orderBy={DropdownOrderBy.None}
                                                  selectedItem={this.source}
                                                  disabled={this.sourceServicePoints.length == 0}
                                                  onChange={(_, servicePoint, userInteraction) => this.setSourceAsync(servicePoint, this.destination, userInteraction)}
                                                  onFavoriteChange={(_, servicePoint, favorite) => this.setFavoriteAsync(servicePoint!, favorite)}
                                        />

                                        <Dropdown favorite filterFavorite filterAutoFocus clearButton
                                                  toggleIcon={false}
                                                  noSubtext={this.props.noSubtext}
                                                  nothingSelectedText={Localizer.mobileNewTripPageDropdownDestinationNothingSelectedText}
                                                  className={styles.selector}
                                                  align={DropdownAlign.Left}
                                                  orderBy={DropdownOrderBy.None}
                                                  items={this.destinationServicePoints}
                                                  selectedItem={this.destination}
                                                  disabled={this.destinationServicePoints.length == 0}
                                                  onChange={(_, servicePoint, userInteraction) => this.setDestinationAsync(servicePoint, userInteraction)}
                                                  onFavoriteChange={(_, servicePoint, favorite) => this.setFavoriteAsync(servicePoint!, favorite)}
                                        />

                                    </div>

                                    <div className={styles.swapContainer}>
                                        <Button id={"swap"}
                                                title={Localizer.mobileNewTripPageNewTripButtonSwap}
                                                icon={{name: "fal fa-sort-alt"}}
                                                className={styles.swap}
                                                type={ButtonType.Light}
                                                disabled={!this.ready}
                                                onClick={() => this.swapAsync()}
                                        />
                                    </div>

                                </div>

                                {
                                    (this.ready) &&
                                    (
                                        <div className={styles.buttons}>

                                            {
                                                (this.props.findBoats) &&
                                                (
                                                    <Button id={"findBoats"}
                                                            label={Localizer.mobileNewTripPageButtonFindBoats}
                                                            className={styles.button}
                                                            type={ButtonType.Primary}
                                                            onClick={() => this.findBoatsAsync()}
                                                    />
                                                )
                                            }

                                            {
                                                (this.props.preBook) &&
                                                (
                                                    <Button id={"preBook"}
                                                            label={this.preBookLabel}
                                                            icon={{name: "fal anchor"}}
                                                            className={styles.preBook}
                                                            type={ButtonType.Primary}
                                                            onClick={() => this.preBookAsync()}
                                                    />
                                                )
                                            }

                                        </div>
                                    )
                                }

                            </div>
                        )
                    }

                    <div className={styles.expander} onClick={() => this.toggleAsync()}>
                        <span>{this.expanded ? Localizer.mobileNewTripPageHideSearch : Localizer.mobileNewTripPageShowSearch}</span>
                        <Icon name={(this.expanded ? "fal fa-angle-up" : "fal fa-angle-down")} size={IconSize.X2}/>
                    </div>

                </div>

            </div>
        )
    }
}