import React from "react";
import {AlertModel, AlertType, BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {Alert, Modal} from "@reapptor-apps/reapptor-react-components";
import BookingInfo from "@/pages/Mobile/BookingDetails/BookingInfo/BookingInfo";
import BookTripResponse from "@/models/server/responses/BookTripResponse";
import EstimatedBooking from "@/models/server/bout/EstimatedBooking";

import mobileAppStyles from "../../mobile.module.scss";
import styles from "./BookingDetailsModal.module.scss";

export interface IBookingDetailsModalProps {
    booking: EstimatedBooking;
    
    approveTrip: (sender: BookingDetailsModal, booking: EstimatedBooking) => Promise<BookTripResponse>;
    
    cancelTrip: (sender: BookingDetailsModal, booking: EstimatedBooking) => Promise<boolean>;
    
    onClose?: (sender: BookingDetailsModal) => Promise<void>;
}

interface IBookingDetailsModalState {
    warning: string | null,
}

export default class BookingDetailsModal extends BaseComponent<IBookingDetailsModalProps, IBookingDetailsModalState> {

    state: IBookingDetailsModalState = {
        warning: null
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();

    private async onCloseAsync(): Promise<void> {
        if (this.props.onClose) {
            await this.props.onClose(this);
        }
    }
    
    private get modal(): Modal | null {
        return this._modalRef.current;
    }

    private get warning(): AlertModel | null {
        
        if (this.state.warning) {
            const alert = new AlertModel(this.state.warning, AlertType.Warning);
            alert.dismissible = false;
            return alert;
        }
        
        return null;
    }

    public async onApproveTripAsync(): Promise<void> {
        await this.props.approveTrip(this, this.booking);

        await this.closeAsync();
    }
    
    public async onCancelTripAsync(): Promise<void> {
        const cancelled: boolean = await this.props.cancelTrip(this, this.booking);
        
        if (cancelled) {
            await this.closeAsync();
        }
    }
    
    public async openAsync(): Promise<void> {
        if (this.modal) {
            await this.modal.openAsync();
        }
    }

    public async closeAsync(): Promise<void> {
        if (this.modal) {
            await this.modal.closeAsync();
        }
    }
    
    public get booking(): EstimatedBooking {
        return this.props.booking;
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.openAsync();
    }

    public render(): React.ReactNode {
        return (
            <Modal id={this.id} noHeader notResponsive
                   ref={this._modalRef}
                   className={this.css(styles.bookingDetailsModal, this.mobile && styles.mobile, mobileAppStyles.mobile)}
                   onClose={() => this.onCloseAsync()}>

                {
                    (this.warning) &&
                    (
                        <Alert model={this.warning} />
                    )
                }

                {
                    (this.booking) &&
                    (
                        <BookingInfo readonly
                                     item={this.booking}
                                     approveTrip={() => this.onApproveTripAsync()}
                                     cancelTrip={() => this.onCancelTripAsync()}
                                     close={() => this.closeAsync()}
                        />
                    )
                }
                
            </Modal>
        );
    }
}