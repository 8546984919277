import React from "react";
import {Button, ButtonContainer, ButtonType} from "@reapptor-apps/reapptor-react-components";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import User from "@/models/server/User";

import Localizer from "../../../../localization/Localizer";

import styles from "./InProgress.module.scss";
import kycStyles from "../Kyc.module.scss";
import TransformProvider from "@/providers/TransformProvider";

interface IInProgressProps {
    user: User;
    onButtonClick: (sender: InProgress) => Promise<void>;
}

interface IInProgressState {
}

export default class InProgress extends BaseComponent<IInProgressProps, IInProgressState> {

    state: IInProgressState = {};

    public get user(): User {
        return this.props.user;
    }

    public render(): React.ReactNode {
        return (
            <div className={this.css(kycStyles.statusContainer, styles.inProgress)}>

                <div className={kycStyles.text}>

                    <span>{Localizer.mobileKycPageInProgressStartVerificationMessageSpan}</span>

                    <div className={kycStyles.list}>
                        
                        <h6>
                            {Localizer.mobileKycPageInProgressInformationListHeading}
                        </h6>

                        <ul>
                            {
                                (
                                    this.user.stripeKycStatus!.currentlyDueDocumentItems?.map(item =>
                                        <li>{TransformProvider.localizeStripeKycStatusItem(item)}</li>
                                    )
                                )
                            }

                        </ul>
                        
                    </div>

                </div>

                <div className={kycStyles.expander}/>

                <ButtonContainer>

                    <Button type={ButtonType.Primary}
                            icon={{name:"fas fa-arrow-alt-right"}}
                            label={Localizer.genericContinue}
                            onClick={() => this.props.onButtonClick(this)}
                    />

                </ButtonContainer>

            </div>
        );
    }
}