import React from "react";
import {BaseComponent, IBaseComponent, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import Container from "@/components/Container/Container";
import Booking from "@/models/server/bout/Booking";
import BookingItem from "@/pages/Mobile/MyTrips/BookingItem/BookingItem";
import Localizer from "@/localization/Localizer";

import styles from "./BookingContainer.module.scss";

export interface IBookingContainerProps {
    asCaptain: boolean;
    isEntrepreneur: boolean;
    bookings: Booking[];
    onClick?(sender: IBaseComponent, item: Booking): Promise<void>;
}

export default class BookingContainer extends BaseComponent<IBookingContainerProps> {

    private async onClickAsync(item: Booking): Promise<void> {
        if (this.props.onClick) {
            await this.props.onClick(this, item);
        }
    }

    private renderBooking(booking: Booking, index: number): React.ReactNode {
        return (
            <Container.Item key={index} className={this.css(styles.item)}>
                <BookingItem asCaptain={this.asCaptain}
                             isEntrepreneur={this.isEntrepreneur}
                             booking={booking}
                             onClick={() => this.onClickAsync(booking)}
                />
            </Container.Item>
        );
    }

    public get bookings(): Booking[] {
        return this.props.bookings;
    }

    public get asCaptain(): boolean {
        return this.props.asCaptain;
    }

    public get isEntrepreneur(): boolean {
        return this.props.isEntrepreneur;
    }

    public render(): React.ReactNode {
        const hasData: boolean = (this.bookings.length > 0);
        
        const noBookingsStyle: any = (!hasData) && styles.noBookings;
        
        return (
            <div id={this.id} className={this.css(styles.bookingContainer, noBookingsStyle)}>
                {
                    (hasData)
                        ?
                        (
                            <Container className={styles.container}>
                                {this.bookings.map((item, index) => this.renderBooking(item, index))}
                            </Container>
                        )
                        :
                        (
                            <span className={styles.message}>
                                {ReactUtility.toMultiLines(Localizer.mobileBookingContainerNoBookings)}
                            </span>
                        )
                }
            </div>
        )
    }
}