import React from "react";
import {
    BaseComponent,
    IBaseComponentProps,
    LocalizationString,
    LocalizationStringItem
} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonType, Dropdown, DropdownAlign, DropdownRequiredType, TextAreaInput, TextInput} from "@reapptor-apps/reapptor-react-components";
import {ILanguage} from "@reapptor-apps/reapptor-toolkit";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";

import styles from "./LocalizationTextInput.module.scss";

export enum LocalizationTextInputType {
    TextInput,

    TextAreaInput
}

export interface ILocalizationTextInputProps extends IBaseComponentProps {
    type?: LocalizationTextInputType;
    autoFocus?: boolean;
    trim?: boolean;
    noAutoComplete?: true;
    maxLength?: number;
    rows?: number;
    cols?: number;
    label?: string;
    title?: string;
    required?: true | string[];
    value?: LocalizationString | string | null;
    onChange?(sender: LocalizationTextInput, value: LocalizationString): Promise<void>;
}

export default class LocalizationTextInput extends BaseComponent<ILocalizationTextInputProps> {
    private readonly _languages: string[] = Localizer.supportedLanguageCodes;
    private readonly _defaultLanguage: string = Localizer.defaultLanguage;
    private readonly _primaryTexInputRef: React.RefObject<TextInput> = React.createRef();
    private readonly _primaryTexAreaInputRef: React.RefObject<TextAreaInput> = React.createRef();
    private _value: LocalizationString | null = null;
    private _addedLanguage: string | null = null;
    
    private async validateAsync(): Promise<void> {
        await this._primaryTexInputRef.current?.validateAsync();
        await this._primaryTexAreaInputRef.current?.validateAsync();
    }
    
    private async setAsync(item: LocalizationStringItem, value: string): Promise<void> {
        item.value = value;
        
        await this.validateAsync();

        if (this.props.onChange) {
            await this.props.onChange(this, this.value);
        }
    }
    
    private async deleteItemAsync(item: LocalizationStringItem): Promise<void> {
        this.value.items.remove(item);

        await this.validateAsync();

        await this.reRenderAsync();

        if (this.props.onChange) {
            await this.props.onChange(this, this.value);
        }
    }
    
    private async addItemAsync(language: string): Promise<void> {
        const stringItem = new LocalizationStringItem();
        stringItem.language = language;

        this._addedLanguage = language;

        this.value.items.push(stringItem);

        await this.reRenderAsync();

        if (this.props.onChange) {
            await this.props.onChange(this, this.value);
        }
    }
    
    private isRequired(language: string, primary: boolean): boolean {
        return (this.props.required != null)
            ? (Array.isArray(this.props.required))
                ? ((primary) || (this.props.required.contains(language)))
                : primary && this.props.required
            : false;
    }
    
    private validate(): string | null {
        const items: LocalizationStringItem[] = this.value.items;
        const length: number = items.length;
        for (let i: number = 0; i < length; i++) {
            const item: LocalizationStringItem = items[i];
            if (!item.value) {
                const primary: boolean = (i == 0);
                const required: boolean = this.isRequired(item.language, primary);
                if (!required) {
                    return (primary)
                        ? Localizer.localizationTextInputRequired.format(this.props.label)
                        : Localizer.localizationTextInputRequiredLocalized.format(this.props.label, item.language);
                }
            }
        }
        
        return null;
    }
    
    public get type(): LocalizationTextInputType {
        return this.props.type ?? LocalizationTextInputType.TextInput;
    }

    public get value(): LocalizationString {
        if (this._value == null) {
            this._value = (this.props.value != null)
                ? (typeof this.props.value === "object")
                    ? this.props.value
                    : LocalizationString.create(this.props.value, this.defaultLanguage)
                : new LocalizationString();

            LocalizationString.normalize(this._value, this.requiredLanguages, this.defaultLanguage);
        }

        return this._value;
    }

    public get languages(): string[] {
        return this._languages;
    }

    public get requiredLanguages(): string[] {
        return ((this.props.required != null) && (Array.isArray(this.props.required)))
            ? this.props.required
            : [this.defaultLanguage];
    }

    public get defaultLanguage(): string {
        return this._defaultLanguage;
    }

    public get language(): string {
        return Localizer.language;
    }

    public async componentWillReceiveProps(nextProps: Readonly<ILocalizationTextInputProps>): Promise<void> {

        const newValue: boolean = (this.props.value !== nextProps.value);
        
        await super.componentWillReceiveProps(nextProps);

        if (newValue) {
            this._value = null;
            
            await this.reRenderAsync();
        }
    }

    private renderInput(stringItem: LocalizationStringItem, primary: boolean, autoFocus: boolean): React.ReactNode {
        if (this.type == LocalizationTextInputType.TextInput) {
            return (
                <TextInput required
                           id={`${this.id}_${stringItem.language}`}
                           ref={primary ? this._primaryTexInputRef : undefined}
                           label={primary ? this.props.label : undefined}
                           autoFocus={autoFocus}
                           trim={this.props.trim}
                           noAutoComplete={this.props.noAutoComplete}
                           maxLength={this.props.maxLength}
                           value={stringItem.value}
                           onChange={(sender, value) => this.setAsync(stringItem, value)}
                           validators={primary ? [() => this.validate()] : undefined}
                />
            )
        }

        return (
            <TextAreaInput required
                           id={`${this.id}_${stringItem.language}`}
                           ref={primary ? this._primaryTexAreaInputRef : undefined}
                           label={primary ? this.props.label : undefined}
                           autoFocus={autoFocus}
                           //trim={this.props.trim}
                           //noAutoComplete={this.props.noAutoComplete}
                           maxLength={this.props.maxLength}
                           rows={this.props.rows}
                           cols={this.props.cols}
                           value={stringItem.value}
                           onChange={(sender, value) => this.setAsync(stringItem, value)}
                           validators={primary ? [() => this.validate()] : undefined}
            />
        )
    }
    
    private renderLanguageItem(stringItem: LocalizationStringItem, addedLanguage: string | null, primary: boolean = false): React.ReactNode {
        const required: boolean = this.isRequired(stringItem.language, primary);
        const languages: ILanguage[] = Localizer.supportedLanguages;
        const language: ILanguage = languages.first(item => item.code == stringItem.language);
        const image: string = TransformProvider.toImage(language);
        const missingLanguages: ILanguage[] = (primary)
            ? languages.where(language => this.value.items.all(item => item.language != language.code))
            : [];
        
        const autoFocus: boolean = (addedLanguage)
            ? (addedLanguage == stringItem.language)
            : (primary) && (this.props.autoFocus == true);
        
        return (
            <div className={styles.itemContainer} key={`key_${this.id}_${stringItem.language}`}>
                
                <img src={image}
                     alt={language.label}
                     title={language.label}
                />

                {
                    this.renderInput(stringItem, primary, autoFocus)
                }

                {
                    (primary) &&
                    (
                        <Dropdown required
                                  requiredType={DropdownRequiredType.Manual}
                                  align={DropdownAlign.Left}
                                  className={styles.add}
                                  width={"auto"}
                                  toggleIcon={"fas fa-plus"}
                                  items={missingLanguages}
                                  disabled={missingLanguages.length == 0}
                                  onChange={(sender, value) => this.addItemAsync(value!.code)}
                        />
                    )
                }

                {
                    (!primary) &&
                    (
                        <Button icon="far fa-trash-alt"
                                type={ButtonType.Blue}
                                disabled={required}
                                onClick={() => this.deleteItemAsync(stringItem)}
                        />
                    )
                }
                
            </div>
        );
    }
    
    public render(): React.ReactNode {
        
        const items: LocalizationStringItem[] = this.value.items;
        const primary: LocalizationStringItem = items.first();
        const secondary: LocalizationStringItem[] = items.skip(1);

        const addedLanguage: string | null = this._addedLanguage;
        this._addedLanguage = null;

        return (
            <div id={this.id} className={this.css(this.props.className, styles.localizationTextInput)}>

                {
                    this.renderLanguageItem(primary, addedLanguage, true)
                }

                {
                    secondary.map((item) => this.renderLanguageItem(item, addedLanguage))
                }
                
            </div>
        )
    }
}