// autogenerated

export default class CheckOnlineStatusRequest {

    public id: string | null = null;

    public readonly isCheckOnlineStatusRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof CheckOnlineStatusRequest) || ((from as any).isCheckOnlineStatusRequest === true));
    }

    public static as(from?: object | null): CheckOnlineStatusRequest | null {
        return (CheckOnlineStatusRequest.is(from)) ? from as CheckOnlineStatusRequest : null;
    }

    // #region Placeholder

    constructor(id: string | null = null) {
        this.id = id;
    }

    // #endregion
}