// autogenerated
import {ETagResponse} from "@reapptor-apps/reapptor-react-common";
import ItemAmount from "@/models/server/ItemAmount";

export default class GetCruisePackageAvailabilityResponse extends ETagResponse {

    public activeAreasPerCountry: ItemAmount[] | null = null;

    public readonly isGetCruisePackageAvailabilityResponse: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof GetCruisePackageAvailabilityResponse) || ((from as any).isGetCruisePackageAvailabilityResponse === true));
    }

    public static as(from?: object | null): GetCruisePackageAvailabilityResponse | null {
        return (GetCruisePackageAvailabilityResponse.is(from)) ? from as GetCruisePackageAvailabilityResponse : null;
    }

    // #region Placeholder

    public static getCountriesCount(response: GetCruisePackageAvailabilityResponse | null | undefined): number {
        return response?.activeAreasPerCountry?.length ?? 0;
    }

    public static getAreasCount(response: GetCruisePackageAvailabilityResponse | null | undefined, countryId: string | null | undefined): number {
        return (countryId)
            ? response
            ?.activeAreasPerCountry
            ?.firstOrDefault(item => item.id == countryId)
            ?.amount ?? 0
            : (response?.activeAreasPerCountry?.length == 1)
                ? response?.activeAreasPerCountry[0].amount
                : 0;
    }

    // #endregion
}