// autogenerated
import {GeoCoordinate} from "@reapptor-apps/reapptor-toolkit";

export default class CancelTripRequest {

    public bookingId: string = "";

    public asCaptain: boolean = false;

    public location: GeoCoordinate | null = null;

    public readonly isCancelTripRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof CancelTripRequest) || ((from as any).isCancelTripRequest === true));
    }

    public static as(from?: object | null): CancelTripRequest | null {
        return (CancelTripRequest.is(from)) ? from as CancelTripRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}