import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {Checkbox, Form, OneColumn, Panel, TextAreaInput, TextInput, TwoColumns} from "@reapptor-apps/reapptor-react-components";
import ServiceProvider from "@/models/server/ServiceProvider";
import AndroidAssetsSettings from "@/models/server/serviceproviderinfo/AndroidAssetsSettings";

import styles from "./AndroidAssetsPanel.module.scss"
import Localizer from "@/localization/Localizer";

interface IAndroidAssetsPanelProps {
    serviceProvider: ServiceProvider;
}

interface IAndroidAssetsPanelState {
}

export default class AndroidAssetsPanel extends BaseComponent<IAndroidAssetsPanelProps, IAndroidAssetsPanelState> {

    state: IAndroidAssetsPanelState = {};

    private readonly _formRef: React.RefObject<Form> = React.createRef();

    private get androidAssetsSettings(): AndroidAssetsSettings | null {
        return this.serviceProvider.androidAssetsSettings;
    }

    private async setPackageNameAsync(value: string): Promise<void> {
        if (this.serviceProvider.androidAssetsSettings) {
            this.serviceProvider.androidAssetsSettings.packageName = value;
            await this.reRenderAsync();
        }
    }

    private async setSha256CertFingerprintsAsync(value: string): Promise<void> {
        if (this.serviceProvider.androidAssetsSettings) {
            this.serviceProvider.androidAssetsSettings.sha256CertFingerprints = value;
            await this.reRenderAsync();
        }
    }
    
    private async enableAndroidAssetsSettingsAsync(value: boolean): Promise<void> {
        if (this.serviceProvider) {
            if (this.serviceProvider.androidAssetsSettings == null) {
                this.serviceProvider.androidAssetsSettings = new AndroidAssetsSettings();
            }

            this.serviceProvider.androidAssetsSettings.enabled = value

            await this.reRenderAsync();
        }
    }

    public async isFormValidAsync(): Promise<boolean> {
        if (this._formRef.current) {
            return (this.androidAssetsSettings == null) || (!this.androidAssetsSettings.enabled) || (this._formRef.current.validateAsync());
        }

        return false;
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public get serviceProvider(): ServiceProvider {
        return this.props.serviceProvider;
    }

    public render(): React.ReactNode {
        return (
            <Panel className={this.css("flex-2", styles.androidAssetsPanel)}>
                <div className="col-12">

                    <Form ref={this._formRef} className={styles.form}>

                        <OneColumn>

                            <Checkbox id="androidAssetsEnable"
                                      label={Localizer.serviceProvidersManagementPageAndroidAssetsEnabled}
                                      value={this.androidAssetsSettings?.enabled || false}
                                      onChange={(_, value) => this.enableAndroidAssetsSettingsAsync(value)}
                            />

                        </OneColumn>

                        {
                            (this.androidAssetsSettings?.enabled || false) &&
                            (
                                    <TwoColumns>

                                        <TextInput id="packageName" required
                                                       label={Localizer.serviceProvidersManagementPageAndroidAssetsPackageName}
                                                       value={this.androidAssetsSettings.packageName}
                                                       onChange={(_, value) => this.setPackageNameAsync(value)}
                                        />

                                    </TwoColumns>
                            )
                        }

                        {
                            (this.androidAssetsSettings?.enabled || false) &&
                            (
                                    <OneColumn>

                                        <TextAreaInput id="sha256CertFingerprints" required
                                                       label={Localizer.serviceProvidersManagementPageAndroidAssetsSha}
                                                       value={this.androidAssetsSettings.sha256CertFingerprints}
                                                       onChange={(_, value) => this.setSha256CertFingerprintsAsync(value)}
                                        />

                                    </OneColumn>
                            )
                        }
                                    
                    </Form>

                </div>

            </Panel>
        );
    }
};