import React from "react";
import {ch} from "@reapptor-apps/reapptor-react-common";
import {
    Button,
    EmailInput,
    Inline, OneColumn,
    PageContainer,
    PageHeader,
    PageRow,
    PhoneInput,
    TwoColumns
} from "@reapptor-apps/reapptor-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import TestPdfResponse from "@/models/server/responses/TestPdfResponse";
import TestSmsResponse from "@/models/server/responses/TestSmsResponse";
import TestEmailResponse from "@/models/server/responses/TestEmailResponse";
import TestPushNotificationResponse from "@/models/server/responses/TestPushNotificationResponse";
import UpdateGoogleIndexResponse from "@/models/server/responses/UpdateGoogleIndexResponse";
import AddUpdateSitemapResponse from "@/models/server/responses/AddUpdateSitemapResponse";
import SystemInfo from "@/models/server/SystemInfo";
import OnlineStatistics from "@/models/server/OnlineStatistics";
import Localizer from "../../localization/Localizer";

import styles from "./AdminConsole.module.scss"

interface IAdminConsoleState {
    phone: string;
    email: string;
    systemInfo: SystemInfo | null;
    onlineStatistics: OnlineStatistics | null;
}

export default class AdminConsole extends AuthorizedPage<{}, IAdminConsoleState> {

    state: IAdminConsoleState = {
        phone: this.getUser().phone ?? "",
        email: this.getUser().email,
        systemInfo: null,
        onlineStatistics: null
    };

    private async setPhoneAsync(phone: string): Promise<void> {
        await this.setState({phone});
    }

    private async setEmailAsync(email: string): Promise<void> {
        await this.setState({email});
    }

    private async clearRedisCacheAsync(): Promise<void> {
        await this.postAsync("/api/adminConsole/clearRedisCache");
    }

    private async clearGoogleIndexQueue(): Promise<void> {
        await this.postAsync("/api/adminConsole/clearGoogleIndexQueue");
    }

    private async initializeLdProductsAsync(): Promise<void> {
        await this.postAsync("/api/adminConsole/initializeLdProducts");
    }

    private async updateGoogleIndexAsync(): Promise<void> {
        const response: UpdateGoogleIndexResponse = await this.postAsync("/api/adminConsole/updateGoogleIndex");

        if (response.success) {
            await this.alertMessageAsync(Localizer.adminConsolePageUpdateGoogleIndexStarted);
        } else {
            await this.alertErrorAsync(Localizer.adminConsolePageUpdateGoogleIndexError.format(response.error));
        }
    }

    private async updateSitemapAsync(): Promise<void> {
        const response: AddUpdateSitemapResponse = await this.postAsync("/api/adminConsole/updateSitemapInGoogle");

        if (response.success) {

            await this.alertMessageAsync(Localizer.adminConsolePageUpdateSitemapInGoogleStarted);

        } else {

            await this.alertErrorAsync(Localizer.adminConsolePageUpdateSitemapInGoogleError.format(response.error));

        }
    }

    private async clearRedisSessionAsync(): Promise<void> {
        await this.postAsync("/api/adminConsole/clearRedisSession");
        ch.refresh();
    }

    private async testPdfAsync(): Promise<void> {
        const response: TestPdfResponse = await this.postAsync("/api/adminConsole/testPdf");

        if (response.success) {

            await this.alertMessageAsync(Localizer.adminConsolePageTestPdfSuccess.format(response.version), true);

            if (response.pdf) {
                ch.download(response.pdf);
            }

        } else {

            await this.alertErrorAsync(Localizer.adminConsolePageTestPdfFailed.format(response.version, response.error));

        }
    }

    private async testSmsAsync(): Promise<void> {
        const response: TestSmsResponse = await this.postAsync("/api/adminConsole/testSms", this.state.phone);

        if (response.success) {

            await this.alertMessageAsync(Localizer.adminConsolePageTestSmsSuccess, true);

        } else {

            await this.alertErrorAsync(Localizer.adminConsolePageTestSmsFailed.format(response.error));

        }
    }

    private async testPushNotificationAsync(): Promise<void> {
        const response: TestPushNotificationResponse = await this.postAsync("/api/adminConsole/testPushNotification");

        if (response.success) {

            await this.alertMessageAsync(Localizer.adminConsolePageTestPushNotificationSent, true);

        } else {

            await this.alertErrorAsync(Localizer.adminConsolePageTestPushNotificationFailed.format(response.error));

        }
    }

    private async throwBackendExceptionAsync(): Promise<void> {
        await this.postAsync("/api/adminConsole/throwBackendException");
    }

    private async testEmailAsync(): Promise<void> {
        const response: TestEmailResponse = await this.postAsync("/api/adminConsole/testEmail", this.state.email);

        if (response.success) {

            await this.alertMessageAsync(Localizer.adminConsolePageTestEmailSuccess, true);

        } else {

            await this.alertErrorAsync(Localizer.adminConsolePageTestEmailFailed.format(response.error));

        }
    }

    private async updateSystemInfoAsync(): Promise<void> {
        const systemInfo: SystemInfo = await this.postAsync("/api/adminConsole/getSystemInfo");

        await this.setState({systemInfo});
    }

    private async updateTop100RequestsAsync(): Promise<void> {
        let top100RequestsJson: string = await this.postAsync("/api/adminConsole/getTop100Requests");

        top100RequestsJson = JSON.stringify(JSON.parse(top100RequestsJson), null, 4);
        
        const clipboard: Clipboard = navigator.clipboard;
        
        if (document.hasFocus()) {
            await clipboard.writeText(top100RequestsJson);

            await ch.flyoutMessageAsync(Localizer.adminConsolePageStatisticsCopied);
        }
    }

    private async refreshAsync(): Promise<void> {
        if (ch.getPage() == this) {
            const onlineStatistics: OnlineStatistics = await this.postAsync("/api/adminConsole/getOnlineStatistics");

            await this.setState({onlineStatistics});

            setTimeout(() => this.refreshAsync(), 3000);
        }
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.updateSystemInfoAsync();

        await this.refreshAsync();
    }

    private renderStateItem(title: string, value: boolean): React.ReactNode {
        return (
            <p>
                <span>{title}:</span>
                <span className={this.css(value && styles.active)}>{value ? "✓" : "-"}</span>
            </p>
        );
    }

    public render(): React.ReactNode {
        const systemInfo: SystemInfo | null = this.state.systemInfo;

        return (
            <PageContainer className={styles.adminConsole}>
                <PageHeader title={Localizer.topNavAdminManagement} subtitle={this.getContext().username}/>
                <PageRow>
                    <div className="col">

                        <TwoColumns className={"justify-content-xl-between"}
                                    leftClassName={"pl-3"}
                                    rightClassName={"pl-3"}>

                            <OneColumn className="mr-4">

                                <Inline>

                                    <Button icon={{name: "repeat"}}
                                            label={Localizer.adminConsolePageUpdateGoogleIndex}
                                            confirm={Localizer.adminConsolePageUpdateGoogleIndexConfirm}
                                            onClick={() => this.updateGoogleIndexAsync()}
                                    />

                                    <Button icon={{name: "fas broom"}}
                                            label={Localizer.adminConsolePageClearGoogleIndexQueue}
                                            confirm={Localizer.adminConsolePageClearGoogleIndexQueueConfirm}
                                            onClick={() => this.clearGoogleIndexQueue()}
                                    />

                                </Inline>

                                <Inline>

                                    <Button icon={{name: "fa-spinner"}}
                                            label={Localizer.adminConsolePageInitializeLdProductsCache}
                                            confirm={Localizer.adminConsolePageInitializeLdProductsCacheConfirm}
                                            onClick={() => this.initializeLdProductsAsync()}
                                    />

                                    <Button icon={{name: "fas fa-file-upload"}}
                                            label={Localizer.adminConsolePageUpdateSitemapInGoogle}
                                            confirm={Localizer.adminConsolePageUpdateSitemapInGoogleConfirm}
                                            onClick={() => this.updateSitemapAsync()}
                                    />

                                </Inline>

                                <Inline>

                                    <Button icon={{name: "fad eraser"}}
                                            label={Localizer.adminConsolePageClearRedisCache}
                                            confirm={Localizer.adminConsolePageClearRedisCacheConfirm}
                                            onClick={() => this.clearRedisCacheAsync()}
                                    />

                                    <Button icon={{name: "fad skull-crossbones"}}
                                            label={Localizer.adminConsolePageDropUserSessions}
                                            confirm={Localizer.adminConsolePageDropUserSessionsConfirm}
                                            onClick={() => this.clearRedisSessionAsync()}
                                    />

                                </Inline>

                                <Inline>

                                    <Button icon={{name: "fad bomb"}}
                                            minWidth={"14rem"}
                                            label={Localizer.adminConsolePageTestThrowException}
                                            confirm={Localizer.adminConsolePageConfirmMessageThrowException}
                                            onClick={() => this.throwBackendExceptionAsync()}
                                    />

                                </Inline>

                                <Inline>

                                    <Button icon={{name: "file-pdf"}}
                                            label={Localizer.adminConsolePageTestPdf}
                                            onClick={() => this.testPdfAsync()}
                                    />

                                    <Button icon={{name: "bell"}}
                                            label={Localizer.adminConsolePageTestPushNotification}
                                            onClick={() => this.testPushNotificationAsync()}
                                    />

                                </Inline>

                                <Inline>

                                    <PhoneInput id="test_sms"
                                                width={"14rem"}
                                                value={this.state.phone}
                                                onChange={async (sender, value) => this.setPhoneAsync(value)}
                                    />

                                    <Button icon={{name: "phone"}}
                                            label={Localizer.adminConsolePageTestSms}
                                            disabled={!this.state.phone}
                                            onClick={() => this.testSmsAsync()}
                                    />

                                </Inline>

                                <Inline>

                                    <EmailInput id="test_email"
                                                width={"14rem"}
                                                value={this.state.email}
                                                onChange={async (sender, value) => this.setEmailAsync(value)}
                                    />

                                    <Button icon={{name: "envelope-open"}}
                                            label={Localizer.adminConsolePageTestEmail}
                                            disabled={!this.state.email}
                                            onClick={() => this.testEmailAsync()}
                                    />

                                </Inline>
                                
                                <Inline>
                                    
                                    <Button icon={"fal fa-database"}
                                            label={Localizer.adminConsolePageTop100Requests}
                                            onClick={() => this.updateTop100RequestsAsync()}
                                    />
                                    
                                </Inline>

                            </OneColumn>

                            <OneColumn className={this.css(styles.info)}>

                                {
                                    (!this.mobile) ?
                                        (
                                            <p>
                                                <span>{Localizer.adminConsolePageSystemInfoVersion}</span>
                                                <span>{ch.version}</span>
                                            </p>
                                        )
                                        :
                                        (
                                            <>
                                                <p>
                                                    <span>{Localizer.adminConsolePageSystemInfoVersion}</span>
                                                </p>
                                                <p>
                                                    <span>{ch.version}</span>
                                                </p>
                                            </>
                                        )
                                }
                                
                                {this.renderStateItem(Localizer.adminConsolePageSystemInfoDevelopmentVS, ch.isDevelopmentVS)}
                                {this.renderStateItem(Localizer.adminConsolePageSystemInfoDevelopment, ch.isDevelopment)}
                                {this.renderStateItem(Localizer.adminConsolePageSystemInfoProduction, (!ch.isDevelopmentVS && !ch.isDevelopment!))}
                                {this.renderStateItem(Localizer.adminConsolePageSystemInfoOnline, ch.online)}

                                {
                                    (systemInfo) &&
                                    (
                                        <React.Fragment>
                                            {this.renderStateItem(Localizer.adminConsolePageSystemInfoDebug, systemInfo.isDebug)}
                                            {this.renderStateItem(Localizer.adminConsolePageSystemInfoClDebug, systemInfo.isComponentLibraryDebug)}
                                            {this.renderStateItem(Localizer.adminConsolePageSystemInfoUiDebug, ch.debug)}
                                            {this.renderStateItem(Localizer.adminConsolePageSystemInfoLoggerDebug, systemInfo.isLoggerDebug)}
                                            {this.renderStateItem(Localizer.adminConsolePageSystemInfoOptimized, systemInfo.isOptimized)}
                                            <p>
                                                <span>{Localizer.adminConsolePageSystemInfoCoresPerServer}</span><span>{systemInfo.coresPerServer}</span>
                                            </p>
                                            <p>
                                                <span>{Localizer.adminConsolePageSystemInfoSessionTimeout}</span><span>{systemInfo.sessionTimeoutMinutes}</span>
                                            </p>
                                        </React.Fragment>
                                    )
                                }

                            </OneColumn>

                        </TwoColumns>

                        {
                            (this.state.onlineStatistics) &&
                            (
                                <div className={styles.onlineStatistics}>
                                    
                                    <p>
                                        <span>{Localizer.adminConsolePageNewVisitorsPerDay}</span>
                                        <span>{this.state.onlineStatistics.newVisitorsPerDay}</span>
                                    </p>
                                    
                                    <p>
                                        <span>{Localizer.adminConsolePageCaptainsOnline}</span>
                                        <span>{this.state.onlineStatistics.onlineCaptains}</span>
                                    </p>
                                    
                                    <p>
                                        <span>{Localizer.adminConsolePagePassengersOnline}</span>
                                        <span>{this.state.onlineStatistics.onlinePassengers}</span>
                                    </p>

                                </div>
                            )
                        }
                        
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}