import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {Button, ButtonContainer, ButtonType, PageContainer} from "@reapptor-apps/reapptor-react-components";
import Booking from "@/models/server/bout/Booking";
import CreditCard from "@/models/server/bout/CreditCard";
import {PageRoute, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import BankAccount from "@/models/server/bout/BankAccount";
import BankAccountContainer from "@/pages/Mobile/MyPaymentMethods/BankAccountContainer/BankAccountContainer";
import CreditCardContainer from "@/pages/Mobile/MyPaymentMethods/CreditCardContainer/CreditCardContainer";
import BookingItem from "@/pages/Mobile/MyTrips/BookingItem/BookingItem";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import boutStyles from "../../../bout.module.scss";
import styles from "./MyPaymentMethods.module.scss";

interface IMyPaymentMethodsProps {
}

interface IMyPaymentMethodsState {
    pendingPayment: Booking | null;
    creditCards: CreditCard[];
    bankAccounts: BankAccount[];
    pendingBooking: Booking | null;
    initialized: boolean;
}

export default class MyPaymentMethods extends AuthorizedPage<IMyPaymentMethodsProps, IMyPaymentMethodsState> {

    state: IMyPaymentMethodsState = {
        pendingPayment: null,
        creditCards: [],
        bankAccounts: [],
        pendingBooking: null,
        initialized: false,
    };
    
    private async openPendingBookingAsync(pendingBooking: Booking): Promise<void> {
        const route: PageRoute = PageDefinitions.bookingDetails(pendingBooking);
        await PageRouteProvider.redirectAsync(route);
    }
    
    private async addCreditCardAsync(): Promise<void> {
        const required: boolean = (this.creditCards.length == 0);
        const route: PageRoute = PageDefinitions.editCreditCard(null, required, false);
        
        await PageRouteProvider.redirectAsync(route);
    }
    
    private async editCreditCardAsync(creditCardId: string): Promise<void> {
        const route: PageRoute = PageDefinitions.editCreditCard(creditCardId, false, false);
        
        await PageRouteProvider.redirectAsync(route);
    }

    private async addBankAccountAsync(): Promise<void> {
        const required: boolean = (this.bankAccounts.length == 0);
        const route: PageRoute = PageDefinitions.editBankAccount(null, required);

        await PageRouteProvider.redirectAsync(route);
    }
    
    private async editBankAccountAsync(bankAccountId: string): Promise<void> {
        const noDeletion: boolean = (this.bankAccounts.length == 1);
        const route: PageRoute = PageDefinitions.editBankAccount(bankAccountId, false, noDeletion);
        
        await PageRouteProvider.redirectAsync(route);
    }

    public getTitle(): string {
        return Localizer.myPaymentMethodsPageTitle;
    }

    public get asCaptain(): boolean {
        return AppController.asCaptain;
    }

    public get isEntrepreneur(): boolean {
        return AppController.isEntrepreneur;
    }

    public get asPassenger(): boolean {
        return AppController.asPassenger;
    }

    public get initialized(): boolean {
        return this.state.initialized;
    }

    public get creditCards(): CreditCard[] {
        return this.state.creditCards;
    }

    public get bankAccounts(): BankAccount[] {
        return this.state.bankAccounts;
    }

    public get pendingBooking(): Booking | null {
        return this.state.pendingBooking;
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const pendingBooking: Booking | null = (this.asPassenger)
            ? await this.postAsync("/api/mobileApp/findMyPendingBooking")
            : null;
        
        const creditCards: CreditCard[] = (this.asPassenger)
            ? await this.postAsync("/api/mobileApp/listMyCreditCards")
            : [];
        
        const bankAccounts: BankAccount[] = (this.asCaptain)
            ? await this.postAsync("/api/mobileApp/listMyBankAccounts")
            : [];
        
        await this.setState({ creditCards, bankAccounts, pendingBooking, initialized: true });
    }
    
    private renderPendingBooking(booking: Booking | null): React.ReactNode {
        const moneySymbol: string = booking?.area?.country?.moneySymbol ?? "";
        const header: string = (booking)
            ? "{0:C} {1}".format(booking.price, moneySymbol)
            : Localizer.mobileMyPaymentMethodsPageNoPendingPayments;

        return (
            <div>

                <span className={styles.header}>{Localizer.mobileKycPagePendingPaymentsSpan}</span>
                <span className={this.css(styles.header, styles.pending)}>{header}</span>

                {
                    (booking) &&
                    (
                        <div className={styles.pendingBooking} onClick={() => this.openPendingBookingAsync(booking)}>
                            <BookingItem asCaptain={this.asCaptain}
                                         isEntrepreneur={this.isEntrepreneur}
                                         booking={booking}
                            />
                        </div>
                    )
                }

            </div>
        );
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.myPaymentMethods, this.mobile && styles.mobile)}
                           alertClassName={boutStyles.alert}
            >

                {
                    (this.asPassenger) &&
                    (
                        <>
                            <div className={styles.pendingPaymentContainer}>
                                { this.renderPendingBooking(this.pendingBooking) }
                            </div>

                            <div className={styles.creditCardsContainer}>

                                <div className={styles.creditCards}>
                                    
                                    <span className={styles.header}>{Localizer.mobileKycPageCardsSpan}</span>
                                    
                                    <CreditCardContainer initialized={this.initialized}
                                                         creditCards={this.creditCards}
                                                         onClick={(sender, creditCard) => this.editCreditCardAsync(creditCard.id)}
                                    />

                                </div>

                                <ButtonContainer className={styles.buttons}>

                                    <Button block
                                            right={false}
                                            label={Localizer.mobileMyPaymentMethodsPageButtonAddCardLabel}
                                            icon={{name: "fal plus"}}
                                            type={ButtonType.Primary}
                                            onClick={() => this.addCreditCardAsync()}
                                    />

                                </ButtonContainer>

                            </div>
                        </>
                    )
                }

                {
                    (this.asCaptain) &&
                    (
                        <div className={styles.bankAccountContainer}>

                            <div className={styles.bankAccounts}>

                                <span className={styles.header}>{Localizer.mobileKycPageBankAccountsSpan}</span>
                                
                                <BankAccountContainer initialized={this.initialized}
                                                      bankAccounts={this.bankAccounts}
                                                      onClick={(sender, bankAccount) => this.editBankAccountAsync(bankAccount.id)}
                                />

                            </div>

                            <ButtonContainer className={styles.buttons}>

                                <Button block
                                        right={false}
                                        icon={{name: "fal plus"}}
                                        label={Localizer.mobileMyPaymentMethodsPageButtonAddAccountLabel}
                                        type={ButtonType.Dark}
                                        onClick={() => this.addBankAccountAsync()}
                                />

                            </ButtonContainer>

                        </div>
                    )
                }
                
            </PageContainer>
        );
    }
}