import React from "react";
import {BaseWidget, IBaseWidgetProps} from "@reapptor-apps/reapptor-react-components";
import Localizer from "@/localization/Localizer";

import styles from "./InfoWidget.module.scss";

interface IInfoWidgetProps extends IBaseWidgetProps {
    percentage?: number;
    total?: number;
    online?: number;
    active?: number;
    rideCompleted?: number;
    cancelledByCaptain?: number;
    cancelledByPassenger?: number;
    new?: number;
    acceptedByCaptain?: number;
    fetchedFromDate?: Date;
}

export default class InfoWidget extends BaseWidget<IInfoWidgetProps> {

    static defaultProps: IInfoWidgetProps = {
        id: "InfoWidget",
        async: false
    };

    private get showPercentage(): boolean {
        return (this.props.percentage !== undefined && this.props.percentage >= 0);
    }

    private get showTotal(): boolean {
        return (this.props.total !== undefined && this.props.total >= 0);
    }

    private get showOnline(): boolean {
        return (this.props.online !== undefined && this.props.online >= 0);
    }

    private get showActive(): boolean {
        return (this.props.active !== undefined && this.props.active >= 0);
    }

    private get showRideCompleted(): boolean {
        return (this.props.rideCompleted !== undefined && this.props.rideCompleted >= 0);
    }

    private get showCancelledByCaptain(): boolean {
        return (this.props.cancelledByCaptain !== undefined && this.props.cancelledByCaptain >= 0);
    }

    private get showCancelledByPassenger(): boolean {
        return (this.props.cancelledByPassenger !== undefined && this.props.cancelledByPassenger >= 0);
    }

    private get showNew(): boolean {
        return (this.props.new !== undefined && this.props.new >= 0);
    }

    private get showAcceptedByCaptain(): boolean {
        return (this.props.acceptedByCaptain !== undefined && this.props.acceptedByCaptain >= 0);
    }

    private get fetchedFromDate(): Date {
        if (this.props.fetchedFromDate) {
            return this.props.fetchedFromDate;
        }

        return new Date();
    }

    public render(): React.ReactNode {
        return (
            <div className={this.css(styles.info, "col-md-6")}>
                <div className={styles.content}>
                    <div>
                        <h5>{this.props.label}</h5>
                    </div>
                    <div className={styles.interval}>
                        <span>{"{0:dt}".format(this.fetchedFromDate)} - {"{0:dt}".format(new Date())}</span>
                    </div>

                    {
                        (this.showPercentage) &&
                        (
                            <div>
                                <span><span className={styles.success}>{this.props.percentage != 0 ? this.props.percentage!.toFixed(2) : 0}% </span>{Localizer.infoWidgetOfActiveUsers}</span>
                            </div>
                        )
                    }

                    {
                        (this.showActive) &&
                        (
                            <div>
                                <span><span className={styles.success}> {this.props.active}</span> {Localizer.infoWidgetActiveUsers}</span>
                            </div>
                        )
                    }

                    {
                        (this.showOnline) &&
                        (
                            <div>
                                <span><span className={styles.success}> {this.props.online}</span> {Localizer.infoWidgetOnlineUsers}</span>
                            </div>
                        )
                    }

                    {
                        (this.showTotal) &&
                        (
                            <div>
                                <span><span className={styles.success}> {this.props.total}</span> {Localizer.infoWidgetTotalUsers}</span>
                            </div>
                        )
                    }

                    {
                        (this.showRideCompleted) &&
                        (
                            <div>
                                <span>{Localizer.infoWidgetRideCompleted} : <span className={styles.success}> {this.props.rideCompleted}</span> </span>
                            </div>
                        )
                    }

                    {
                        (this.showNew) &&
                        (
                            <div>
                                <span>{Localizer.infoWidgetNew} : <span className={styles.success}> {this.props.new}</span></span>
                            </div>
                        )
                    }

                    {
                        (this.showAcceptedByCaptain) &&
                        (
                            <div>
                                <span>{Localizer.infoWidgetAcceptedByCaptain} : <span className={styles.success}> {this.props.acceptedByCaptain}</span></span>
                            </div>
                        )
                    }
                    
                    {
                        (this.showCancelledByCaptain) &&
                        (
                            <div>
                                <span>{Localizer.infoWidgetCancelledRideByCaptain} : <span className={styles.danger}> {this.props.cancelledByCaptain}</span></span>
                            </div>
                        )
                    }

                    {
                        (this.showCancelledByPassenger) &&
                        (
                            <div>
                                <span>{Localizer.infoWidgetCancelledRideByPassenger} : <span className={styles.danger}> {this.props.cancelledByPassenger}</span></span>
                            </div>
                        )
                    }
                    <div className={styles.footer}>
                        <i className="fal fa-clock"/>
                        <span>{Localizer.infoWidgetLastUpdated.format(new Date())}</span>
                    </div>

                </div>
            </div>
        );
    }
}