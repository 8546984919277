import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonContainer, ButtonType, Dropdown, DropdownAlign, DropdownOrderBy, Form, Modal, ModalSize, OneColumn} from "@reapptor-apps/reapptor-react-components"
import Waypoint from "@/models/server/bout/Waypoint";
import ServicePoint from "@/models/server/bout/ServicePoint";
import GetServicePointsRequest from "@/models/server/requests/GetServicePointsRequest";
import WaypointMap from "@/components/WaypointMap/WaypointMap";
import Area from "@/models/server/bout/Area";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";

import styles from "./WaypointModal.module.scss";

interface IWaypointModalProps {
    areas?: Area[] | [];
    onModalSave?(waypoint: Waypoint): Promise<void>;
}

interface IWaypointModalState {
    servicePoints: ServicePoint[] | null;
    selectedArea: Area | null;
    waypoint: Waypoint | null;
    isActive: boolean;
    source: ServicePoint | null;
    destination: ServicePoint | null;
    preview: boolean;
    canSave: boolean;
}

export default class WaypointModal extends BaseComponent<IWaypointModalProps, IWaypointModalState> {

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _formRef: React.RefObject<Form> = React.createRef();

    state: IWaypointModalState = {
        servicePoints: null,
        selectedArea: null,
        waypoint: null,
        isActive: false,
        source: null,
        destination: null,
        preview: false,
        canSave: true
    }

    private get areas(): Area[] {
        return (this.props.areas) ? this.props.areas : [];
    }
    
    private async fetchServicePointsAsync(): Promise<void> {
        const request = new GetServicePointsRequest();
        request.isActive = true;
        if (this.state.selectedArea) {
            request.areaId = this.state.selectedArea.id;
        }

        const servicePoints: ServicePoint[] = await this.postAsync("/api/servicePoints/getServicePoints", request);

        await this.setState({servicePoints});
    }

    private async clearState(): Promise<void> {
        this.state.source = null;
        this.state.destination = null;
        this.state.waypoint = null;
        this.state.preview = false;
        this.state.canSave = true;

        await this.setState(this.state)
    }

    private async onCloseAsync(): Promise<void> {
        await this.clearState();
    }

    private get canSave(): boolean {
        return (this.state.source?.id != this.state.destination?.id) && (this.state.canSave);
    }

    public async openAsync(waypoint: Waypoint | null = null, preview: boolean = false, animation: boolean = true): Promise<void> {
        if (!preview) {
            await this.fetchServicePointsAsync();
        }

        if (this._modalRef.current) {
            await this._modalRef.current.openAsync(animation);

            if (waypoint) {
                this.state.waypoint = waypoint;
                this.state.source = waypoint.source;
                this.state.destination = waypoint.destination;
                this.state.preview = preview;
            }
            else {
                this.state.waypoint = new Waypoint();
                this.state.source = this.state.servicePoints![0];
                this.state.destination = this.state.servicePoints![0];
                this.state.preview = false;
            }
            
            await this.setState(this.state);

            await this.reRenderAsync();
        }
    }

    public async onSaveModalClickAsync(): Promise<void> {
        await this.setState({canSave: false});
        
        if (this.props.onModalSave) {
            this.waypoint!.sourceId = this.state.source!.id;
            this.waypoint!.destinationId = this.state.destination!.id;

            await this.props.onModalSave(this.waypoint!);
            await this.closeAsync();
        }
    }
    
    public async onSourceChangeAsync(item: ServicePoint): Promise<void> {
        await this.setState({source: item});
    }

    public async onDestinationChangeAsync(item: ServicePoint): Promise<void> {
        await this.setState({destination: item});
    }

    public async onAreaChangeAsync(selectedArea: Area): Promise<void> {
        await this.setState({selectedArea});
        await this.fetchServicePointsAsync();
    }

    public async closeAsync(): Promise<void> {
        if (this._modalRef.current) {
            await this._modalRef.current.closeAsync();
            await this.clearState();
        }

        await this.reRenderAsync();
    }

    public get waypoint(): Waypoint | null {
        return this.state.waypoint;
    }

    public static get modalId(): string {
        return "mapPointsModal";
    }

    public renderForm(): React.ReactNode {
        return (
            <Form id="form" 
                  ref={this._formRef} 
                  onSubmit={() => this.onSaveModalClickAsync()}
            >

                <OneColumn>

                    <Dropdown required
                              id="waypointModalAreas"
                              title={Localizer.waypointModalDropdownAreasTitle}
                              label={Localizer.waypointModalDropdownAreasLabel}
                              minWidth="80px"
                              nothingSelectedText={Localizer.waypointModalDropdownAreasNothingSelectedText}
                              align={DropdownAlign.Left}
                              orderBy={DropdownOrderBy.Name}
                              selectedItem={this.state.selectedArea}
                              items={this.areas}
                              onChange={(sender, value) => this.onAreaChangeAsync(value!)}
                    />

                    <Dropdown required
                              id="source"
                              title={Localizer.waypointModalDropdownSourceTitle}
                              label={Localizer.waypointModalDropdownSourceLabel}
                              minWidth="80px"
                              filterMaxLength={500}
                              align={DropdownAlign.Left}
                              orderBy={DropdownOrderBy.Name}
                              selectedItem={this.state.source}
                              items={this.state.servicePoints || []}
                              onChange={(sender, value) => this.onSourceChangeAsync(value!)}
                    />

                    <Dropdown required
                              id="destination"
                              title={Localizer.waypointModalDropdownDestinationTitle}
                              label={Localizer.waypointModalDropdownDestinationLabel}
                              minWidth="80px"
                              filterMaxLength={500}
                              align={DropdownAlign.Left}
                              orderBy={DropdownOrderBy.Name}
                              selectedItem={this.state.destination}
                              items={this.state.servicePoints || []}
                              onChange={(sender, value) => this.onDestinationChangeAsync(value!)}
                    />

                </OneColumn>

                <ButtonContainer>
                    
                    <Button submit
                            type={ButtonType.Primary}
                            label={Localizer.genericSave}
                            icon={{name: "far save"}}
                            disabled={!this.canSave} 
                    />
                    
                    <Button type={ButtonType.Default}
                            label={Localizer.genericCancel}
                            onClick={() => this.closeAsync()}
                    />
                    
                </ButtonContainer>

            </Form>
        );
    }

    public renderPreview(): React.ReactNode {
        return (
            <div className={styles.map}>
                <WaypointMap point={this.waypoint!}/>
            </div>
        );
    }

    public render(): React.ReactNode {
        return (
            <Modal id={WaypointModal.modalId} ref={this._modalRef}
                   size={ModalSize.Large}
                   className={styles.waypointModal}
                   title={TransformProvider.waypointToString(this.waypoint)}
                   subtitle={Localizer.waypointModalSubtitle}
                   onClose={() => this.onCloseAsync()}
            >
                {
                    <div className="row">

                        <div className="col">

                            {(this.waypoint && this.state.preview)
                                ? this.renderPreview()
                                : this.renderForm()
                            }

                        </div>
                    </div>
                }

            </Modal>
        )
    }
}