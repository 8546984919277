import React from "react";
import {Button, ButtonType, Modal, Spinner} from "@reapptor-apps/reapptor-react-components";
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import {BaseComponent, ch, CameraType} from "@reapptor-apps/reapptor-react-common";
import Localizer from "@/localization/Localizer";

import styles from "./ProfileModal.module.scss";
import mobileStyles from "@/pages/Mobile/mobile.module.scss";

interface IProfileModalProps {
    onClose(image: FileModel | null): Promise<void>;
}

interface IProfileModalState {
    image: FileModel | null,
    loading: boolean
}

export default class ProfileModal extends BaseComponent<IProfileModalProps, IProfileModalState> {

    state: IProfileModalState = {
        image: null,
        loading: false
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();

    private async onCloseAsync(): Promise<void> {
        if (this.props.onClose) {
            await this.props.onClose(this.state.image);
        }
    }

    public async onOpenAsync(image: FileModel | null): Promise<void> {
        await this.setState({image});
        await this._modalRef.current!.openAsync();
    }

    private async deletePictureAsync(): Promise<void> {
        this.state.image = null;

        await this.closeAsync();
    }

    private async takePictureAsync(camera: boolean | CameraType): Promise<void> {
        try {
            await this.setState({loading: true});
            
            let image: FileModel | null = await ch.takePictureAsync(camera);

            if (image) {
                
                image = await this.postAsync("/api/image/convertImage", image);

                this.state.image = image;

                await this.closeAsync();
            }

        } finally {
            await this.setState({loading: false});
        }
    }

    public getTitle(): string {
        return Localizer.mobileProfilePageTitle;
    }

    public async openAsync(): Promise<void> {
        if (this._modalRef.current) {
            await this._modalRef.current.openAsync();
        }
    }

    public async closeAsync(): Promise<void> {
        if (this._modalRef.current) {
            await this._modalRef.current.closeAsync();
        }
    }

    public hasSpinner(): boolean {
        return true;
    }

    public render(): React.ReactNode {
        return (
            <Modal noHeader notResponsive
                   ref={this._modalRef}
                   className={this.css(styles.profileModal, this.mobile && styles.mobile, this.mobile && mobileStyles.mobile)}
                   bodyClassName={styles.body}
                   onClose={() => this.onCloseAsync()}
            >
                {(this.state.loading) && <Spinner/>}

                <div className={styles.container}>

                    <div className={styles.buttons}>

                        <Button block
                                label={Localizer.genericCamera}
                                icon={{name: "fal camera"}}
                                type={ButtonType.Primary}
                                onClick={() => this.takePictureAsync(CameraType.UserFacingCamera)}
                        />

                        <Button block
                                label={Localizer.genericPhotos}
                                icon={{name: "fal image-polaroid"}}
                                type={ButtonType.Primary}
                                onClick={() => this.takePictureAsync(false)}
                        />

                        {
                            (this.state.image) &&
                            (
                                <Button block
                                        label={Localizer.genericRemove}
                                        icon={{name: "fal trash-alt"}}
                                        type={ButtonType.Dark}
                                        onClick={() => this.deletePictureAsync()}
                                />
                            )
                        }

                    </div>

                </div>

            </Modal>
        );
    }
}