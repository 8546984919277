import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {PageRoute} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonContainer, ButtonType, Modal, ModalSize, PageContainer, PageRow} from "@reapptor-apps/reapptor-react-components";
import PageDefinitions from "@/providers/PageDefinitions";
import User from "@/models/server/User";
import Boat from "@/models/server/bout/Boat";
import ServiceProviderController from "@/pages/ServiceProviderController";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import boutStyles from "../../../bout.module.scss";
import styles from "./Settings.module.scss";

interface ISettingsProps {
}

interface ISettingsState {
    homeLocationPageRoute: PageRoute | null
}

export default class Settings extends AuthorizedPage<ISettingsProps, ISettingsState> {

    state: ISettingsState = {
        homeLocationPageRoute: null
    };

    public getTitle(): string {
        return Localizer.settingsPageTitle;
    }

    public get user(): User {
        return this.getUser();
    }

    private get asCaptain(): boolean {
        return (AppController.asCaptain);
    }

    private get asPassenger(): boolean {
        return (AppController.asPassenger);
    }

    private get isEntrepreneur(): boolean {
        return (AppController.isEntrepreneur);
    }
    
    private async setHomeLocationPageRouteAsync(): Promise<void> {
        let route: PageRoute | null = null;
        if (this.asCaptain) {
            const boats: Boat[] = await this.postAsync("/api/mobileApp/getMyBoats");
            if(boats.length == 1) {
                const boutId: string = boats[0].id;
                route = PageDefinitions.editBoat(boutId, true);
            }
            else {
                route =  PageDefinitions.myBoatsRoute;
            }
        }

        await this.setState({homeLocationPageRoute: route});
    }

    public async initializeAsync(): Promise<void> {
        await this.setHomeLocationPageRouteAsync();
        await super.initializeAsync();
        
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.settings, this.mobile && styles.mobile)}
                           alertClassName={boutStyles.alert}
            >

                <span className={styles.header}>{this.getTitle()}</span>

                <PageRow>

                    <ButtonContainer>

                        {
                            (this.asCaptain) &&
                            (
                                <React.Fragment>

                                    <Button block
                                            right={false}
                                            type={ButtonType.Dark}
                                            icon={{name: "fal location"}}
                                            label={Localizer.genericHomeLocation}
                                            route={this.state.homeLocationPageRoute!}
                                    />

                                    {
                                        (this.isEntrepreneur) &&
                                        (
                                            <Button block
                                                    right={false}
                                                    type={ButtonType.Dark}
                                                    icon={{name: "fal fa-user-check"}}
                                                    label={Localizer.mobileKycPageTitle}
                                                    route={PageDefinitions.kycRoute}
                                            />
                                        )
                                    }

                                </React.Fragment>
                            )
                        }

                        {
                            ((this.asPassenger) || ((this.asCaptain) && (this.isEntrepreneur))) &&
                            (
                                <Button block
                                        right={false}
                                        type={ButtonType.Dark}
                                        icon={{name: "fal fa-credit-card"}}
                                        label={Localizer.myPaymentMethodsPageTitle}
                                        route={PageDefinitions.myPaymentMethodsRoute}
                                />
                            )
                        }

                        <Button block
                                right={false}
                                type={ButtonType.Dark}
                                icon={{name: "fal user-headset"}}
                                label={Localizer.topNavContactSupport}
                                route={PageDefinitions.contactSupportRoute}
                        />

                        <Button block toggleModal
                                right={false}
                                type={ButtonType.Dark}
                                icon={{name: "fal gavel"}}
                                dataTarget="agreementModal"
                                label={Localizer.mobileSettingsPageButtonTermsLabel}
                        />
                        
                        <Button block toggleModal
                                right={false}
                                type={ButtonType.Dark}
                                icon={{name: "fal fa-ban"}}
                                dataTarget="cancellationPolicyModal"
                                label={Localizer.genericCancellationPolicy}
                        />

                        <Button block
                                right={false}
                                type={ButtonType.Dark}
                                icon={{name: "fal question-circle"}}
                                label={Localizer.genericFaq}
                                route={PageDefinitions.fAQRoute}
                        />

                    </ButtonContainer>

                </PageRow>

                <Modal id="agreementModal" info keepTextFormatting
                       title={ServiceProviderController.termsTitle}
                       content={(this.asCaptain) ? ServiceProviderController.termsContentCaptain : ServiceProviderController.termsContent}
                       size={ModalSize.Large}
                />
                
                <Modal id="cancellationPolicyModal" info keepTextFormatting
                       title={ServiceProviderController.cancellationPolicyTitle}
                       content={ServiceProviderController.cancellationPolicyContent}
                       size={ModalSize.Large}
                />

            </PageContainer>
        );
    }
}