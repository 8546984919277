import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {PageContainer} from "@reapptor-apps/reapptor-react-components";
import FaqPanel from "@/components/FaqPanel/FaqPanel";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import boutStyles from "@/bout.module.scss";
import styles from "@/pages/Mobile/Settings/FAQ/FAQ.module.scss";

interface IFAQProps {
}

export default class FAQ extends AuthorizedPage<IFAQProps> {

    public getTitle(): string {
        return AppController.getFullPageTitle(Localizer.genericFaq);
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.faq, this.mobile && styles.mobile)}
                           alertClassName={boutStyles.alert}
            >

                <FaqPanel />
                
            </PageContainer>
        );
    }
}