// autogenerated
import {CruisePackagePriceInterval} from "@/models/Enums";
import AppConstants from "@/helpers/AppConstants";

export default class CruisePackagePrice {

    public id: string = "";

    public cruisePackageId: string = "";

    public hourIndex: number = 0;

    public price: number = 0;

    public interval: CruisePackagePriceInterval = CruisePackagePriceInterval.MondaySunday;

    public readonly isCruisePackagePrice: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof CruisePackagePrice) || ((from as any).isCruisePackagePrice === true));
    }

    public static as(from?: object | null): CruisePackagePrice | null {
        return (CruisePackagePrice.is(from)) ? from as CruisePackagePrice : null;
    }

    // #region Placeholder

    public static initialize(model: CruisePackagePrice): CruisePackagePrice {
        model.id ||= AppConstants.defaultGuid;
        model.cruisePackageId ||= AppConstants.defaultGuid;
        return model;
    }
    
    public static getDurationInHours(price: CruisePackagePrice, prices: CruisePackagePrice[], minDurationInHours: number, intervalInHours: number = 0.5): number {
        const index: number = prices.indexOf(price);
        return minDurationInHours + intervalInHours * index;
    }
    
    public static getIntervalPrice(price: CruisePackagePrice, prices: CruisePackagePrice[], minDurationInHours: number, intervalInHours: number = 0.5): number {
        const durationInHours: number = CruisePackagePrice.getDurationInHours(price, prices, minDurationInHours, intervalInHours);
        const durationInIntervals: number = durationInHours / intervalInHours;
        return price.price / durationInIntervals;
    }

    // #endregion
}