import {DataStorageType, UserInteractionDataStorage} from "@reapptor-apps/reapptor-react-common";
import ServicePoint from "@/models/server/bout/ServicePoint";
import Waypoint from "@/models/server/bout/Waypoint";

class PreBookData {
    source: ServicePoint | null = null;
    destination: ServicePoint | null = null;
    waypoint: Waypoint | null = null;
    bookingDate: Date | null = null;
    passengers: number | null = null;
    adults: number | null = null;
    children: number | null = null;
    bikesAndTrolleys: number | null = null;
    pensioners: number | null = null;
}

class PreBookController {
    
    private _data: PreBookData | null = null;
    
    private get key(): string {
        return "preBookData";
    }

    private get preBookData(): PreBookData {
        return this._data ?? (this._data = UserInteractionDataStorage.get(this.key, new PreBookData(), DataStorageType.Session));
    }
    
    public get source(): ServicePoint | null {
        return this.preBookData?.source ?? null;
    }
    
    public get destination(): ServicePoint | null {
        return this.preBookData?.destination ?? null;
    }
    
    public get waypoint(): Waypoint | null {
        return this.preBookData?.waypoint ?? null;
    }
    
    public get bookingDate(): Date | null {
        return (this.preBookData?.bookingDate)
            ? new Date(this.preBookData?.bookingDate)
            : null;
    }
    
    public get passengers(): number | null {
        return this.preBookData?.passengers ?? null;
    }
    
    public get adults(): number | null {
        return this.preBookData?.adults ?? null;
    }
    
    public get children(): number | null {
        return this.preBookData?.children ?? null;
    }
    
    public get bikesAndTrolleys(): number | null {
        return this.preBookData?.bikesAndTrolleys ?? null;
    }
    
    public get pensioners(): number | null {
        return this.preBookData?.pensioners ?? null;
    }
    
    private setPreBookData(data: PreBookData | null): void {
        this._data = data;
        UserInteractionDataStorage.set(this.key, data, DataStorageType.Session);
    }
    
    public setSource(source: ServicePoint | null): void {
        const preBookData: PreBookData | null = this.preBookData;
        preBookData.source = source;
        this.setPreBookData(preBookData);
    }
    
    public setDestination(destination: ServicePoint | null): void {
        const preBookData: PreBookData | null = this.preBookData;
        preBookData.destination = destination;
        this.setPreBookData(preBookData);
    }
    
    public setWaypoint(waypoint: Waypoint | null): void {
        const preBookData: PreBookData | null = this.preBookData;
        preBookData.waypoint = waypoint;
        this.setPreBookData(preBookData);
    }
    
    public setBookingDate(bookingDate: Date | null): void {
        const preBookData: PreBookData | null = this.preBookData;
        preBookData.bookingDate = bookingDate;
        this.setPreBookData(preBookData);
    }
    
    public setPassengers(passengers: number | null): void {
        const preBookData: PreBookData | null = this.preBookData;
        preBookData.passengers = passengers;
        this.setPreBookData(preBookData);
    }
    
    public setAdults(adults: number | null): void {
        const preBookData: PreBookData | null = this.preBookData;
        preBookData.adults = adults;
        this.setPreBookData(preBookData);
    }
    
    public setPensioners(pensioners: number | null): void {
        const preBookData: PreBookData | null = this.preBookData;
        preBookData.pensioners = pensioners;
        this.setPreBookData(preBookData);
    }
    
    public setChildren(children: number | null): void {
        const preBookData: PreBookData | null = this.preBookData;
        preBookData.children = children;
        this.setPreBookData(preBookData);
    }
    
    public setBikesAndTrolleys(bikesAndTrolleys: number | null): void {
        const preBookData: PreBookData | null = this.preBookData;
        preBookData.bikesAndTrolleys = bikesAndTrolleys;
        this.setPreBookData(preBookData);
    }
    
    public clear(): void {
        this.setPreBookData(null);
    }
}

//Singleton
export default new PreBookController();