import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import Boat from "@/models/server/bout/Boat";
import BoatImage from "@/models/server/BoatImage";
import Localizer from "@/localization/Localizer";

import styles from "./BoatItem.module.scss";

import defaultBoatImage from "@/img/BoatDefaultImage.png";

export interface IBoatItemProps {
    className?: string;
    boat: Boat;
    onClick?(sender: BoatItem, boat: Boat): Promise<void>;
}

export default class BoatItem extends BaseComponent<IBoatItemProps> {

    private async invokeOnClickAsync(): Promise<void> {
        if (this.props.onClick) {
            await this.props.onClick(this, this.boat);
        }
    }
    
    private getBoatPrimaryImage(): string | undefined {
        if ((this.boat.images == null) || (!this.boat.images.length)) {
            return defaultBoatImage;
        }
        const primaryImage: BoatImage | null = this.boat.images.find(item => item.primary) || null;
        return primaryImage?.file?.src ?? defaultBoatImage;
    }
    
    public get boat(): Boat {
        return this.props.boat;
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(styles.boatItem)}>
                <div className={styles.boatInfoContainer} onClick={() => this.invokeOnClickAsync()}>
                    
                    <div className={styles.boatPrimaryImage}>
                        <img src={this.getBoatPrimaryImage()} alt={Localizer.boatItemPrimaryImageAlt}/>
                    </div>
                    
                    <div className={styles.description}>
                        <div>
                            <p>{this.boat.brand}</p>
                            <p>{this.boat.model}</p>
                        </div>
                    </div>
                    
                    <div className={styles.capacity}>
                        <div>
                            <span className="fas fa-user fa-2x"/>
                            <span>1 - {this.boat.maxCapacity}</span>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }
}