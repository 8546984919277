// autogenerated

export default class SetPasswordRequest {

    public username: string | null = null;

    public password: string | null = null;

    public readonly isSetPasswordRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof SetPasswordRequest) || ((from as any).isSetPasswordRequest === true));
    }

    public static as(from?: object | null): SetPasswordRequest | null {
        return (SetPasswordRequest.is(from)) ? from as SetPasswordRequest : null;
    }

    // #region Placeholder

    constructor(password: string) {
        this.password = password;
    }

    // #endregion
}