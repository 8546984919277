// autogenerated
import {TimePeriod} from "@/models/Enums";

export default class GetMyRevenueRequest {

    public timePeriod: TimePeriod = TimePeriod.All;

    public date: Date = new Date();

    public readonly isGetMyRevenueRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof GetMyRevenueRequest) || ((from as any).isGetMyRevenueRequest === true));
    }

    public static as(from?: object | null): GetMyRevenueRequest | null {
        return (GetMyRevenueRequest.is(from)) ? from as GetMyRevenueRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}