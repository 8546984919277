// autogenerated
import SaveUserRequestBase from "./SaveUserRequestBase";

export default class SignUpRequest extends SaveUserRequestBase {

    public readonly isSignUpRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof SignUpRequest) || ((from as any).isSignUpRequest === true));
    }

    public static as(from?: object | null): SignUpRequest | null {
        return (SignUpRequest.is(from)) ? from as SignUpRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}