import React from "react";
import AppConstants from "@/helpers/AppConstants";
import AnonymousPage from "@/models/base/AnonymousPage";
import {
    PageContainer,
    PageHeader,
    PageRow,
    LdDataHeader
} from "@reapptor-apps/reapptor-react-components";
import BasePageParameters from "@reapptor-apps/reapptor-react-common/src/models/BasePageParameters";
import GetAuthorsResponse from "@/models/server/responses/GetAuthorsResponse";
import GetAuthorsRequest from "@/models/server/requests/GetAuthorsRequest";
import {ch, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import ServiceProviderController from "@/pages/ServiceProviderController";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import styles from "./Authors.module.scss";
import logo from "./logo.png";

interface IAuthorsProps extends BasePageParameters {
}

interface IAuthorsState {
    authors: GetAuthorsResponse | null;
}

export default class Authors extends AnonymousPage<IAuthorsProps, IAuthorsState> {
    
    state: IAuthorsState = {
        authors: null,
    };

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        const request = new GetAuthorsRequest();
        
        request.pageUrl = ch.pageUrl;
        
        const authors: GetAuthorsResponse = await this.postAsync("/api/application/getAuthors", request);

        await this.setState({ authors });
    }

    public getTitle(): string {
        return AppController.getFullPageTitle(Localizer.authorsPageTitle);
    }

    public render(): React.ReactNode {
        const authors: GetAuthorsResponse | null = this.state.authors;
        
        return (
            <PageContainer className={styles.authors}>

                <LdDataHeader data={authors?.authorsLdData}
                              canonical={"/authors"}
                              defaultLanguage={ServiceProviderController.applicationDefaultLanguage}
                />
                
                <PageHeader title={Localizer.authorsPageTitle} />

                <PageRow>
                    <div className="col">

                        {
                            (authors) &&
                            (
                                <div className={styles.content}>
                                    
                                    <div className={styles.header}>

                                        <a href={"https://reapptor.com/"} target="_blank" className={styles.left}>
                                            {
                                                <img alt={"authors-logo"} src={logo}/>
                                            }
                                        </a>
                                        
                                        <div className={styles.right}>
                                            <span>{Localizer.authorsPageSpanName}</span>
                                        </div>
                                        
                                    </div>

                                    <div className={styles.description}>
                                        <span>{Localizer.authorsPageSpanDescription}</span>
                                    </div>
                                    
                                    <div className={styles.releases}>
                                        <div>
                                            <span>{Localizer.authorsPageFirstReleaseAt}</span>
                                            {ReactUtility.toTags("<i>{0:D}</i>".format(AppConstants.firstReleaseAt))}
                                        </div>
                                        <div>
                                            <span>{Localizer.authorsPageNewestReleaseAt}:</span>
                                            {ReactUtility.toTags("<i>{0:D}</i>".format(authors.newestReleaseAt))}
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                        
                    </div>
                </PageRow>

            </PageContainer>
        );
    }
}