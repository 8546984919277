import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {
    Form, FourColumns,
    ImageInput,
    Panel,
} from "@reapptor-apps/reapptor-react-components";
import ServiceProvider from "@/models/server/ServiceProvider";
import AppConstants from "@/helpers/AppConstants";
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import Localizer from "@/localization/Localizer";

import styles from "./MetadataPanel.module.scss"

interface IMetadataPanelProps {
    serviceProvider: ServiceProvider;
}

interface IMetadataPanelState {
}

export default class MetadataPanel extends BaseComponent<IMetadataPanelProps, IMetadataPanelState> {

    state: IMetadataPanelState = {
    };

    public readonly _formRef: React.RefObject<Form> = React.createRef();

    private async setFaviconAsync(value: FileModel[]): Promise<void> {
        this.serviceProvider.favicon = (value.length > 0)
            ? value[0].src
            : "";

        await this.reRenderAsync();
    }

    private get favicon(): string | null {
        return this.serviceProvider.favicon;
    }
    
    public get serviceProvider(): ServiceProvider {
        return this.props.serviceProvider;
    }

    public async isFormValidAsync(): Promise<boolean> {
        if (this._formRef.current) {
            let valid: boolean = await this._formRef.current.validateAsync();
            valid = valid && (!!this.favicon);
            return valid;
        }

        return false;
    }

    public render(): React.ReactNode {
        
        return (
            <Panel className={this.css("flex-2", styles.metadataPanel)}>
                <div className="col-12">
                 
                    <Form ref={this._formRef} className={styles.form}>

                        <FourColumns>
                            
                            <div className={styles.favicon}>
                                
                                <span>{Localizer.serviceProvidersManagementPageMetadataFavicon}</span>
    
                                <ImageInput minimizeOnEmpty
                                            className={styles.input}
                                            maxImageRequestSizeInBytes={AppConstants.maxImageLogoSizeInBytes}
                                            selectionToolbar={{editButton: true, deleteButton: true, previewButton: true, uploadButton: true}}
                                            pictures={this.favicon}
                                            onChange={(_, value) => this.setFaviconAsync(value)}
                                />

                            </div>

                        </FourColumns>

                    </Form>

                </div>

            </Panel>
        );
    }
};