// autogenerated

export default class GetMyBookingRequest {

    public bookingId: string = "";

    public notification: boolean | null = null;

    public readonly isGetMyBookingRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof GetMyBookingRequest) || ((from as any).isGetMyBookingRequest === true));
    }

    public static as(from?: object | null): GetMyBookingRequest | null {
        return (GetMyBookingRequest.is(from)) ? from as GetMyBookingRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}