// autogenerated

export default class SocialMediaLinks {

    public facebookUrl: string | null = null;

    public instagramUrl: string | null = null;

    public youtubeUrl: string | null = null;

    public linkedinUrl: string | null = null;

    public twitterUrl: string | null = null;

    public discordUrl: string | null = null;

    public readonly isSocialMediaLinks: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof SocialMediaLinks) || ((from as any).isSocialMediaLinks === true));
    }

    public static as(from?: object | null): SocialMediaLinks | null {
        return (SocialMediaLinks.is(from)) ? from as SocialMediaLinks : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}