import React from "react";
import {BaseComponent, IBaseComponentProps, LocalizationString} from "@reapptor-apps/reapptor-react-common";
import {Modal, Spinner} from "@reapptor-apps/reapptor-react-components"
import CruisePackage from "@/models/server/cruise/CruisePackage";
import CruisePackageCard from "@/components/CruisePackageCard/CruisePackageCard";
import GetCruisePackageRequest from "@/models/server/requests/GetCruisePackageRequest";
import Localizer from "@/localization/Localizer";

import styles from "./CruisePackagePreviewModal.module.scss";

interface ICruisePackagePreviewModalProps extends IBaseComponentProps {
    details?: boolean;
    hasOnlyInfo?: boolean;
}

interface ICruisePackagePreviewModalState {
    loading: boolean;
    cruisePackage: CruisePackage | null;
    hasData: boolean,
}

export default class CruisePackagePreviewModal extends BaseComponent<ICruisePackagePreviewModalProps, ICruisePackagePreviewModalState> {

    state: ICruisePackagePreviewModalState = {
        loading: false,
        cruisePackage: null,
        hasData: false,
    }

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    
    private async onCloseAsync(): Promise<void> {
        this.state.cruisePackage = null;
        this.state.loading = false;
        this.state.hasData = false;
    }

    private async closePreviewAsync(): Promise<void> {
        if (this.props.details) {
            await this.modal.closeAsync();
        }
    }

    private hasData(cruisePackageId: string): boolean {
        const cruisePackage: CruisePackage | null = this.cruisePackage;
        return (
            ((cruisePackage != null) && (cruisePackage.id == cruisePackageId) && (CruisePackage.hasData(cruisePackage)))
        );
    }

    public async openAsync(cruisePackageOrId: CruisePackage | string, animation: boolean = true): Promise<void> {
        await this.modal.openAsync(animation);

        let cruisePackageId: string;
        let hasData: boolean;

        if (typeof cruisePackageOrId === "object") {
            cruisePackageId = cruisePackageOrId.id;

            hasData = this.hasData(cruisePackageId);

            if (!hasData) {
                this.state.cruisePackage = cruisePackageOrId;

                hasData = this.hasData(cruisePackageId);
            }
        } else {
            cruisePackageId = cruisePackageOrId;

            hasData = this.hasData(cruisePackageId);
        }
        
        if (hasData) {
            
            await this.setState( {hasData: true} );
            
        } else {

            if (!this.loading) {
                await this.setState({loading: true});
            }

            const request = new GetCruisePackageRequest();
            request.cruisePackageId = cruisePackageId;

            const cruisePackage: CruisePackage = await this.postAsync("/api/cruisePackage/getCruisePackage", request);
            
            await this.setState( { cruisePackage, loading: false, hasData: true } );
        }
    }
    
    public hasSpinner(): boolean {
        return true;
    }

    private get modal(): Modal {
        return this._modalRef.current!;
    }

    public get hasOnlyInfo(): boolean {
        return (this.props.hasOnlyInfo == true);
    }

    private get cruisePackage(): CruisePackage | null {
        return this.state.cruisePackage;
    }

    private get subtitle(): string {
        return (this.cruisePackage?.name)
            ? LocalizationString.value(this.cruisePackage.name)
            : "*";
    }

    private get loading(): boolean {
        return this.state.loading;
    }

    public get isOpen(): boolean {
        return (this._modalRef.current != null) && (this._modalRef.current.isOpen);
    }

    public render(): React.ReactNode {
        return (
            <Modal id={this.id}
                   ref={this._modalRef}
                   className={this.css(styles.cruisePackagePreviewModal, this.props.className, this.props.details && styles.preview)}
                   title={Localizer.cruisePackagePreviewModalTitle}
                   subtitle={this.subtitle}
                   noHeader={this.props.details}
                   onClose={() => this.onCloseAsync()}
            >

                {
                    ((this.isOpen) && (this.cruisePackage) && (this.state.hasData)) &&
                    (
                        <CruisePackageCard key={this.cruisePackage.id}
                                           hasOnlyInfo={this.hasOnlyInfo}
                                           cruisePackage={this.cruisePackage}
                                           details={this.props.details}
                                           onClose={() => this.closePreviewAsync()}
                        />
                    )
                }

                {(this.loading) && <Spinner />}

            </Modal>
        )
    }
}