import React from "react";
import {Button, ButtonContainer, ButtonType, OneColumn} from "@reapptor-apps/reapptor-react-components";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import User from "@/models/server/User";

import Localizer from "../../../../localization/Localizer";

import styles from "./NotStarted.module.scss";
import kycStyles from "../Kyc.module.scss";

interface INotStartedProps {
    user: User;
    onButtonClick: (sender: NotStarted) => Promise<void>;
}

interface INotStartedState {
}

export default class NotStarted extends BaseComponent<INotStartedProps, INotStartedState> {

    state: INotStartedState = {};

    public get user(): User {
        return this.props.user;
    }

    public render(): React.ReactNode {
        return (
            <div className={this.css(kycStyles.statusContainer, styles.notStarted)}>

                <div className={kycStyles.text}>

                    <span>{Localizer.mobileKycPageNotStartedStartMessageSpan}</span>

                </div>

                <div className={kycStyles.expander}/>

                <ButtonContainer>

                    <Button type={ButtonType.Primary}
                            icon={{name: "fas id-card"}}
                            label={Localizer.mobileKycPageButtonStartKyc}
                            onClick={() => this.props.onButtonClick(this)}
                    />

                </ButtonContainer>

            </div>
        );
    }
}