// autogenerated
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class ListAreasRequest extends ETagRequest {

    public serviceProviderId: string | null = null;

    public withBoatsOnly: boolean = false;

    public withCruisePackagesOnly: boolean = false;

    public asCaptain: boolean = false;

    public asAdmin: boolean = false;

    public countryCode: string | null = null;

    public withBoatPricesOnly: boolean = false;

    public readonly isListAreasRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ListAreasRequest) || ((from as any).isListAreasRequest === true));
    }

    public static as(from?: object | null): ListAreasRequest | null {
        return (ListAreasRequest.is(from)) ? from as ListAreasRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}