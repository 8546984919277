import {AthenaeumConstants, GeoCoordinate} from "@reapptor-apps/reapptor-toolkit";

export default class AppConstants extends AthenaeumConstants {

    public static readonly companyName: string = "Bout";

    public static readonly applicationName: string = "Bout";

    public static readonly applicationTitle: string = "Bout";

    /**
     /* Bout App first release date (11.11.2022)
     */
    public static readonly firstReleaseAt: Date = new Date(2022, 10, 11);
    
    public static readonly maxPageSize: number = 2147483647;

    public static readonly maxHoursPerDay: number = 24;

    public static readonly canModifyHoursRange: number = 24;

    // 8 KB
    public static readonly minImageSize: number = 8 * 1024;

    public static readonly maxImageLogoSizeInBytes: number = 1024 * 1024;

    // 10 MB
    public static readonly maxFileUploadSizeInBytes: number = 10 * 1024 * 1024;

    // 50 MB
    public static readonly maxImageRequestSizeInBytes: number = 50 * 1024 * 1024;

    public static readonly imageFileTypes: string[] = ["image/gif", "image/jpeg", "image/png"];
    
    public static readonly alertCloseDelay: number = 5000;
    
    public static readonly alertAnimationDelay: number = 500;
    
    public static readonly defaultLocation: GeoCoordinate = new GeoCoordinate(60.192059, 24.945831);
    
    // "If user has been signed in more than 15 hours straight throw user out"
    public static readonly signOutExpirationTimeOut: number = 15;
    
    // 255
    public static readonly maxTitleDescriptionLength: number = 255;

    public static readonly hashLength: number = 32;

    public static readonly descriptionLength: number = 1024;

    public static captainRole: string = "Captain";

    public static passengerRole: string = "Passenger";

    public static inspectorRole: string = "Inspector";

    public static serviceProviderManagerRole: string = "ServiceProviderManager";

    public static adminRole: string = "Admin";

    /**
    /* "/cruise/packages/"
    */
    public static cruisePackagesPrefix: string = "/cruise/packages/";

    // 15 minutes
    public static readonly defaultMinBookingDepthInMinutes: number = 15;

    // 10 tickets
    public static readonly defaultShuttleMaxTickets: number = 10;

    // 99 passengers
    public static readonly defaultRideMaxFare: number = 99;

    // 48h
    public static readonly bookingWarningIntervalInHours: number = 48;
    
    public static readonly pollTimeoutInSeconds: number = 15;
    
    public static readonly supportedLocationCountries: string[] = ["fi", "us", "se", "fr", "de"];
    
    public static readonly hideTopNav: string = "hideTopNav";
    
    public static readonly hdsSlug: string = "hds";
    
    public static readonly pjtaSlug: string = "pjta";

    public static readonly boatMaxCapacity: number = 500;

    /**
     /* 8 hours
     */
    public static readonly maxTripDurationInHours: number = 8;

    /**
     /* 1.852 km per nautical mile
     */
    public static readonly kilometersPerNauticalMile: number = 1.852;
}