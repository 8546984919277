
export class CalendarEvent {
    public title: string = "";

    public notes: string = "";

    public startDate: Date = new Date();

    public endDate: Date = new Date();
    
    public isCalendarEvent: true = true;
}