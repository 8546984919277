import React from "react";
import {PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import { LoginResultStatus } from "@/models/Enums";
import AnonymousPage from "../../models/base/AnonymousPage";
import PageDefinitions from "../../providers/PageDefinitions";
import ForgotPasswordRequest from "../../models/server/requests/ForgotPasswordRequest";
import {Button, ButtonContainer, ButtonType, Form, IStringInputModel, PageContainer, PageHeader, PageRow, TextInput} from "@reapptor-apps/reapptor-react-components";
import Localizer from "../../localization/Localizer";

import styles from "./ForgotPassword.module.scss";

export default class ForgotPassword extends AnonymousPage {

    public _usernameInput: IStringInputModel = { value: "" };
    public _formRef: React.RefObject<any> = React.createRef();

    public getTitle(): string {
        return Localizer.forgotPasswordPagePageTitle;
    }

    private async handleSubmitAsync(): Promise<void> {
        const request = new ForgotPasswordRequest(this._usernameInput.value);
        await this.postAsync<LoginResultStatus>("/api/Application/ForgotPassword", request);
    }

    private async redirectToLoginPage() {
        await PageRouteProvider.redirectAsync(PageDefinitions.loginRoute);
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>
                <PageHeader title={Localizer.forgotPasswordPagePageTitle}
                            subtitle={Localizer.forgotPasswordPageHelpText}
                            className={styles.header}
                />

                <PageRow>
                    <div className="col-lg-6">
                        <Form id="form" ref={this._formRef} onSubmit={() => this.handleSubmitAsync()}>
                            
                            <TextInput id="username" required autoFocus noAutoComplete
                                       label={Localizer.forgotPasswordUsernameInput}
                                       model={this._usernameInput}
                            />

                            <ButtonContainer>
                                
                                <Button submit
                                        type={ButtonType.Orange}
                                        label={Localizer.forgotPasswordPageResetButton}
                                />
                                
                                <Button type={ButtonType.Blue}
                                        label={Localizer.forgotPasswordPageLoginButton}
                                        onClick={() => this.redirectToLoginPage()}
                                />
                                
                            </ButtonContainer>

                        </Form>
                    </div>
                </PageRow>
                
            </PageContainer>
        );
    }
}