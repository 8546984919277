// autogenerated
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class GetAuthorsRequest extends ETagRequest {

    public pageUrl: string | null = null;

    public readonly isGetAuthorsRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof GetAuthorsRequest) || ((from as any).isGetAuthorsRequest === true));
    }

    public static as(from?: object | null): GetAuthorsRequest | null {
        return (GetAuthorsRequest.is(from)) ? from as GetAuthorsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}