// autogenerated
import AppConstants from "@/helpers/AppConstants";

export default class UserRole {

    public roleName: string = "";

    public organizationContractId: string = "";

    public readonly isUserRole: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof UserRole) || ((from as any).isUserRole === true));
    }

    public static as(from?: object | null): UserRole | null {
        return (UserRole.is(from)) ? from as UserRole : null;
    }

    // #region Placeholder

    constructor(roleName?: string | null, organizationContractId?: string | null) {
        this.roleName = roleName ?? "";
        this.organizationContractId = organizationContractId ?? AppConstants.defaultGuid;
    }

    // #endregion
}