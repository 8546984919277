// autogenerated
import EstimatedBooking from "@/models/server/bout/EstimatedBooking";
import CruisePackageBooking from "@/models/server/cruise/CruisePackageBooking";
import ShuttleEstimatedBooking from "@/models/server/shuttle/ShuttleEstimatedBooking";

export default class ValidateEstimatedBookingRequest {

    public booking: EstimatedBooking | null = null;

    public cruisePackageBooking: CruisePackageBooking | null = null;

    public shuttleBooking: ShuttleEstimatedBooking | null = null;

    public bookingTime: Date | null = null;

    public readonly isValidateEstimatedBookingRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ValidateEstimatedBookingRequest) || ((from as any).isValidateEstimatedBookingRequest === true));
    }

    public static as(from?: object | null): ValidateEstimatedBookingRequest | null {
        return (ValidateEstimatedBookingRequest.is(from)) ? from as ValidateEstimatedBookingRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}