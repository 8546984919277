import React from "react";
import {BasePageParameters} from "@reapptor-apps/reapptor-react-common";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import {PageContainer, PageHeader, PageRow, Tab, TabContainer, TabRenderType} from "@reapptor-apps/reapptor-react-components";
import BoatPricing from "@/pages/PriceManagement/BoatPricingPanel/BoatPricingPanel";
import FuelConsumption from "@/pages/PriceManagement/FuelConsumptionPanel/FuelConsumptionPanel";
import Localizer from "@/localization/Localizer";

import styles from "./PriceManagement.module.scss";

export interface IPriceManagementProps extends BasePageParameters {
}

interface IPriceManagementState {
}

export default class PriceManagement extends AuthorizedPage<IPriceManagementProps, IPriceManagementState> {

    state: IPriceManagementState = {
    }
    
    private readonly _tabContainerRef: React.RefObject<TabContainer> = React.createRef();

    public getTitle(): string {
        return Localizer.priceManagementTitle;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public render(): React.ReactNode {
        return (
            <PageContainer fullHeight className={styles.priceManagement}>

                <PageHeader title={Localizer.priceManagementPageHeader} 
                            subtitle={Localizer.priceManagementSubtitle}
                            className={styles.header}
                />

                <PageRow>
                    
                    <TabContainer ref={this._tabContainerRef} renderType={TabRenderType.Once}>

                        <Tab id="boatPricing" title={Localizer.priceManagementBoatPricing}>
                            <BoatPricing />
                        </Tab>

                        <Tab id="fuelConsumption" title={Localizer.priceManagementFuelConsumption}>
                            <FuelConsumption />
                        </Tab>

                    </TabContainer>

                </PageRow>
                
            </PageContainer>
        );
    }
}