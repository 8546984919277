import React from "react";
import {BasePageParameters, LocalizationString, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {
    Button,
    ButtonType,
    ColumnDefinition,
    Form,
    Grid,
    Inline,
    JustifyContent,
    NumberInput,
    PageContainer,
    PageHeader,
    PageRow,
    Panel,
    TextInput,
    ThreeColumns,
    TwoColumns
} from "@reapptor-apps/reapptor-react-components";
import {GeoCoordinate, Utility} from "@reapptor-apps/reapptor-toolkit";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import UserCard from "@/components/UserCard/UserCard";
import BookingStatusModel from "@/models/server/bout/BookingStatusModel";
import LocationCard from "@/components/LocationCard/LocationCard";
import {BookingStatus, BookingType, PaymentPolicy, PaymentStatus} from "@/models/Enums";
import WaypointMap from "@/components/WaypointMap/WaypointMap";
import Booking from "@/models/server/bout/Booking";
import ServicePoint from "@/models/server/bout/ServicePoint";
import PageDefinitions from "@/providers/PageDefinitions";
import User from "@/models/server/User";
import ServiceProvider from "@/models/server/ServiceProvider";
import AppController from "@/pages/AppController";
import TransformProvider from "@/providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./BookingInfo.module.scss";

export interface IBookingInfoProps extends BasePageParameters {
}

interface IBookingInfoState {
    booking: Booking | null;
    initialized: boolean;
}

export default class BookingInfo extends AuthorizedPage<IBookingInfoProps, IBookingInfoState> {

    state: IBookingInfoState = {
        booking: null,
        initialized: false
    };

    private readonly _bookingStatusGridRef: React.RefObject<Grid<BookingStatusModel>> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            minWidth: 40
        } as ColumnDefinition,
        {
            header: Localizer.bookingInfoPageGridStatusLanguageItemName,
            accessor: nameof<BookingStatusModel>(o => o.status),
            format: nameof(BookingStatus),
            minWidth: 200,
        } as ColumnDefinition,
        {
            header: Localizer.bookingInfoPageGridLocationLanguageItemName,
            accessor: nameof<BookingStatusModel>(o => o.lat),
            minWidth: 200,
            settings: {
                infoAccessor: nameof<BookingStatusModel>(o => o.lon),
            }
        } as ColumnDefinition,
        {
            header: Localizer.bookingInfoGridCreatedLanguageItemName,
            accessor: nameof<BookingStatusModel>(o => o.createdAt),
            format: "dt",
            minWidth: 120,
        } as ColumnDefinition
    ];

    public getTitle(): string {
        return Localizer.bookingInfoPageTitle;
    }

    private get booking(): Booking | null {
        return this.state.booking;
    }

    private get serviceProvider(): ServiceProvider | null {
        const serviceProvider: ServiceProvider | null = this.booking?.serviceProvider ?? null;
        const parent: ServiceProvider | null = serviceProvider?.parent ?? null;
        
        return ((parent) && (parent.whiteLabeling))
            ? parent
            : ((serviceProvider) && (serviceProvider.whiteLabeling))
                ? serviceProvider
                : null;
    }

    private get waterOperator(): ServiceProvider | null {
        const serviceProvider: ServiceProvider | null = this.booking?.serviceProvider ?? null;
        const captain: User | null = this.captain;
        return ((captain) && (!captain.isEntrepreneur) && (serviceProvider) && (serviceProvider.waterOperator))
            ? serviceProvider
            : null;
    }

    private get captain(): User | null {
        return this.booking?.captain || null;
    }

    private get passenger(): User | null {
        return this.booking?.passenger || null;
    }

    private get source(): ServicePoint | null {
        if (this.booking?.waypoint) {
            return this.booking.waypoint.source;
        }

        return null;
    }

    public get duration(): number {
        return Utility.round(this.booking!.duration, 1);
    }

    public get price(): number {
        return Utility.round(this.booking!.price!, 2);
    }

    public get captainShare(): number {
        return Utility.round(this.booking!.captainShare!, 2);
    }

    public get platformShare(): number {
        return Utility.round(this.booking!.platformShare!, 2);
    }

    public get stripeShare(): number | null {
        return (this.booking?.stripeShare != null)
            ? Utility.round(this.booking.stripeShare, 2)
            : null;
    }

    public get companyShare(): number | null {
        return (this.booking?.companyShare != null)
            ? Utility.round(this.booking.companyShare!, 2)
            : null;
    }

    public get balancedCaptainShare(): number {
        return Utility.round(this.booking!.balancedCaptainShare!, 2);
    }

    public get balancedPassengerPrice(): number {
        return Utility.round(this.booking!.balancedPassengerPrice!, 2);
    }

    public get captainMargin(): number {
        return Utility.round(this.booking!.captainMargin!, 2);
    }

    public get cancelMargin(): number {
        return Utility.round(this.booking!.cancelMargin!, 2);
    }

    public get cancelHours(): number {
        return Utility.round(this.booking!.cancelHours!, 1);
    }

    public get cancelPrice(): number {
        return Utility.round(this.booking!.cancelPrice!, 2);
    }

    public get debt(): number {
        const debt: number = this.booking!.captainShare! - this.booking!.balancedCaptainShare!;
        return Utility.round(debt, 2);
    }

    public get discount(): number {
        const discount: number = this.booking!.passengerPrice! - this.booking!.balancedPassengerPrice!;
        return Utility.round(discount, 2);
    }
    
    private get sourceName(): string | null {
        return (this.booking)
            ? (this.booking.bookingType == BookingType.Ride)
                ? this.booking.waypoint?.source?.name || null
                : (this.booking.customPickupLocation)
                    ? this.booking.customPickupLocationAddress || null
                    : (this.booking.cruisePackagePoint?.location)
                        ? TransformProvider.locationToString(this.booking.cruisePackagePoint.location, true)
                        : null
            : null;
    }
    
    private get cruisePackageName(): string | null {
        return LocalizationString.value(this.booking?.cruisePackage?.name);
    }

    private get destination(): ServicePoint | null {
        if (this.booking?.waypoint) {
            return this.booking.waypoint.destination;
        }

        return null;
    }

    private get subtitle(): string {
        let subtitle: string = "";
        if (this.source && this.destination) {
            subtitle = Localizer.bookingInfoPageSubtitle.format(this.source.name, this.destination.name, this.booking?.createdAt);
        }

        return subtitle;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const bookingId: string | null = this.routeId;

        if (!bookingId) {
            await PageRouteProvider.redirectAsync(PageDefinitions.bookingsRoute, false, true);
        }

        const booking: Booking = await this.postAsync("/api/booking/getBooking", bookingId);

        await this.setState({initialized: true, booking});
    }

    public hasSpinner(): boolean {
        return true;
    }

    public renderUserContainer(): React.ReactNode {
        return (
            <div className={styles.userInfoContainer}>
                <TwoColumns>

                    {
                        <UserCard title={Localizer.bookingInfoPageUserCardPassengerInformationTitle}
                                  user={this.passenger}
                        />
                    }

                    {
                        <UserCard title={Localizer.bookingInfoPageUserCardCaptainInformationTitle}
                                  user={this.captain}
                                  boat={this.booking?.boat}
                        />
                    }

                </TwoColumns>
            </div>
        );
    }

    public renderQuickInfo(): React.ReactNode {

        const booking: Booking = this.booking!;
        
        const moneySymbol: string = booking.area?.country?.moneySymbol ?? "";
        
        const price: string = (booking.price != null)
            ? "{0:C} {1}".format(booking.price, moneySymbol)
            : (booking.estimatedMinPrice != booking.estimatedMaxPrice)
                ? "{0:C} - {1:C} {2}".format(booking.estimatedMinPrice, booking.estimatedMaxPrice, moneySymbol)
                : "{0:C} {1}".format(booking.estimatedMinPrice, moneySymbol);
        
        const timeInMinutes: string = (booking.timeInMinutes != null)
            ? "{0:0}".format(booking.timeInMinutes)
                : (booking.estimatedMinTimeInMinutes != booking.estimatedMaxTimeInMinutes)
                    ? "{0:0} - {1:0}".format(booking.estimatedMinTimeInMinutes, booking.estimatedMaxTimeInMinutes)
                    : "{0:0}".format(booking.estimatedMinTimeInMinutes);
        
        return (
            <Panel title={Localizer.bookingInfoPagePanelTitle} 
                   className={styles.bookingInfoPanel}
            >
                <div className="col">

                    <WaypointMap className={styles.map}
                                 point={(booking.waypoint ?? booking.cruisePackagePoint)}
                    />

                </div>

                <div className="col">

                    <Form id="form">
                        
                        <ThreeColumns>

                            <TextInput readonly
                                       label={Localizer.bookingInfoPageRideType}
                                       value={Utility.formatValue(this.booking?.bookingType, nameof<BookingType>())}
                            />

                            <TextInput readonly
                                       label={Localizer.bookingInfoPageCruisePackage}
                                       value={this.cruisePackageName || "-"}
                            />
                            
                        </ThreeColumns>
                        
                        <ThreeColumns>
                            
                            <TextInput readonly
                                       label={Localizer.bookingInfoPageSource}
                                       value={this.sourceName || "-"}
                            />
                            
                            <TextInput readonly
                                       label={Localizer.bookingInfoPageDestination}
                                       value={booking.waypoint?.destination?.name || "-"}
                            />
                            
                            <TextInput readonly 
                                       label={Localizer.bookingInfoPageAmount}
                                       value={price}
                            />
                            
                        </ThreeColumns>

                        <ThreeColumns>

                            <TextInput readonly
                                       label={Localizer.bookingInfoPageServiceProvider}
                                       value={LocalizationString.value(this.serviceProvider?.name) || "-"}
                            />

                            <TextInput readonly
                                       label={Localizer.bookingInfoPageWaterOperator}
                                       value={LocalizationString.value(this.waterOperator?.name) || "-"}
                            />

                            <TextInput readonly
                                       label={Localizer.bookingInfoPagePaymentPolicy}
                                       value={Utility.formatValue(this.booking?.paymentPolicy, nameof<PaymentPolicy>())}
                            />

                        </ThreeColumns>

                        <ThreeColumns>

                            <TextInput readonly
                                       label={Localizer.bookingInfoPageTypeOfBoat}
                                       value={EnumProvider.getBoatTypeText(booking.boatType!)}
                            />

                            <TextInput readonly
                                       label={Localizer.bookingInfoPageBoatBrand}
                                       value={booking.boat?.brand ?? "-"}
                            />

                            <TextInput readonly
                                       label={Localizer.bookingInfoPageBoatModel}
                                       value={booking!.boat?.model ?? "-"}
                            />

                        </ThreeColumns>

                        <ThreeColumns>

                            <TextInput readonly
                                       label={Localizer.bookingInfoPageBoatCruiseSpeed}
                                       value={booking.boat?.cruiseSpeed ? booking.boat?.cruiseSpeed?.toFixed(1).toString() : "-"}
                            />

                            <TextInput readonly
                                       label={Localizer.bookingInfoPageBoatHorsepower}
                                       value={booking!.boat?.horsepower ? booking!.boat?.horsepower.toFixed(1).toString() : "-"}
                            />

                        </ThreeColumns>

                        <ThreeColumns>

                            <TextInput readonly
                                       label={Localizer.bookingInfoPageBookingStatus}
                                       value={Booking.getStatusText(booking)}
                            />

                            <NumberInput readonly hideArrows
                                         label={Localizer.bookingInfoPageTotalPassengers}
                                         value={booking.passengers}
                            />

                            <TextInput readonly
                                       label={Localizer.bookingInfoPageBookingTime}
                                       value={Booking.getCountryLocalTime(booking, booking.bookingTime).format("dt")}
                            />

                        </ThreeColumns>

                        <ThreeColumns>

                            <NumberInput readonly hideArrows
                                         label={Localizer.bookingInfoPageDistance}
                                         value={booking.distance || booking.estimatedDistance}
                            />

                            <TextInput readonly
                                       label={Localizer.bookingInfoPageTime}
                                       value={timeInMinutes}
                            />

                            <TextInput readonly
                                       label={Localizer.bookingInfoPagePaymentStatus}
                                       value={EnumProvider.getPaymentStatusText(booking.paymentStatus)}
                            />

                        </ThreeColumns>

                        <ThreeColumns>

                            <NumberInput readonly hideArrows
                                         label={Localizer.bookingInfoPageFuelPrice.format(moneySymbol)}
                                         value={booking.pricePerLiter}
                            />

                            <TextInput readonly
                                       label={Localizer.bookingInfoPageBoatFuelConsumption}
                                       value={booking.boatFuelConsumption?.toFixed(2)?.toString() ?? this.booking?.boatFuelConsumption?.toFixed(2)?.toString() ?? "-"}
                            />

                            <TextInput readonly
                                       label={Localizer.bookingInfoPageTotalFuelConsumption}
                                       value={booking.totalFuelConsumption?.toFixed(2)?.toString() ?? this.booking?.totalFuelConsumption?.toFixed(2)?.toString() ?? "-"}
                            />

                        </ThreeColumns>
                        
                    </Form>

                </div>

            </Panel>
        );
    }

    public renderStatusesGrid(): React.ReactNode {
        return (
            <div className={this.css(styles.container, styles.statusesGrid)}>

                <h5>
                    {Localizer.bookingInfoPageGridHeaderStatuses}
                    { 
                        (this.booking) &&
                        (
                            <small> {"({0})".format(Booking.getStatusText(this.booking))}</small>
                        )
                    }
                </h5>

                <Grid id="bookingStatus" responsive
                      ref={this._bookingStatusGridRef}
                      columns={this._columns}
                      noDataText={Localizer.genericNoData}
                      data={this.booking?.statuses ?? []}
                />

                {this.renderStatusChangeLocationCards()}

            </div>
        );
    }
    
    public renderPaymentInfoGrid(): React.ReactNode {
        return (
            <div className={styles.container}>

                <h5>
                    {Localizer.bookingInfoPagePaymentInformationHeading.format(this.booking!.area!.country!.name)}
                </h5>

                <Form>

                    <TwoColumns>

                        <NumberInput readonly hideArrows
                                     label={Localizer.bookingInfoPageNumberInputTripPriceLabel.format(this.booking!.area!.country!.currency)}
                                     value={this.price}
                        />

                        {
                            (AppController.asAdmin) &&
                            (
                                <NumberInput readonly hideArrows
                                             label={Localizer.bookingInfoPageNumberInputCompanyShareLabel.format(this.booking!.area!.country!.currency)}
                                             value={this.companyShare}
                                />
                            )
                        }

                    </TwoColumns>

                    <TwoColumns>

                        <NumberInput readonly hideArrows
                                     label={Localizer.bookingInfoPageNumberInputPlatfromShareLabel.format(this.booking!.area!.country!.currency)}
                                     value={this.platformShare}
                        />

                        <NumberInput readonly hideArrows
                                     label={Localizer.bookingInfoPageNumberInputCaptainShareLabel.format(this.booking!.area!.country!.currency)}
                                     value={this.captainShare}
                        />

                    </TwoColumns>

                    <TwoColumns>

                        <TextInput readonly
                                   label={Localizer.bookingInfoPageStripeCountryCode}
                                   value={this.booking?.stripeCountryCode}
                        />

                        <NumberInput readonly hideArrows
                                     label={Localizer.bookingInfoPageNumberInputStripeShareLabel.format(this.booking!.area!.country!.currency)}
                                     value={this.stripeShare}
                        />

                    </TwoColumns>

                    <TwoColumns>

                        <NumberInput readonly hideArrows
                                     label={Localizer.bookingInfoPageNumberInputBalancedPassengerPriceLabel.format(this.booking!.area!.country!.currency)}
                                     value={this.balancedPassengerPrice}
                        />

                        <NumberInput readonly hideArrows
                                     label={Localizer.bookingInfoPageNumberInputBalancedCaptainShareLabel.format(this.booking!.area!.country!.currency)}
                                     value={this.balancedCaptainShare}
                        />

                    </TwoColumns>

                    <TwoColumns>

                        <NumberInput readonly hideArrows
                                     label={Localizer.bookingInfoPageNumberInputCancelMarginLabel}
                                     value={this.cancelMargin}
                        />

                        <NumberInput readonly hideArrows
                                     label={Localizer.bookingInfoPageNumberInputCaptainMarginLabel}
                                     value={this.captainMargin}
                        />

                    </TwoColumns>
                    
                    <TwoColumns>

                        <NumberInput readonly hideArrows
                                     label={Localizer.bookingInfoPageNumberInputCancelHoursLabel}
                                     value={this.cancelHours}
                        />

                        <NumberInput readonly hideArrows
                                     label={Localizer.bookingInfoPageNumberInputCancelPriceLabel.format(this.booking!.area!.country!.currency)}
                                     value={this.cancelPrice}
                        />

                    </TwoColumns>

                    <TwoColumns>

                        <NumberInput readonly hideArrows
                                     label={Localizer.bookingInfoPageNumberInputCaptainDebtLabel.format(this.booking!.area!.country!.currency)}
                                     value={this.debt}
                        />

                        <NumberInput readonly hideArrows
                                     label={Localizer.bookingInfoPageNumberInputPassengerDiscountLabel.format(this.booking!.area!.country!.currency)}
                                     value={this.discount}
                        />

                    </TwoColumns>

                </Form>
            </div>
        );
    }

    public renderStatusChangeLocationCards(): React.ReactNode {
        return (
            <div className="row">

                {
                    this.booking?.statuses?.map((item, index) =>
                        ((item.status == BookingStatus.StartByCaptain || item.status == BookingStatus.CompletedByCaptain)) &&
                        (
                            <div key={index} className="col-lg-6 col-md-6">
                                <LocationCard title={EnumProvider.getBookingStatusText(item.status)}
                                              subTitle={Localizer.bookingInfoPageLocationCardSubtitle.format(item.createdBy)}
                                              coordinate={new GeoCoordinate(item.lat, item.lon)}
                                              footer={`lon: ${item.lon}, lat: ${item.lat}`}
                                />
                            </div>
                        )
                    )
                }

            </div>
        );
    }

    public render(): React.ReactNode {
        const renderPaymentInfo: boolean = (
            (!!this.booking) &&
            (
                (this.booking.latestStatus == BookingStatus.CompletedByCaptain) ||
                (this.booking.latestStatus == BookingStatus.CancelledByCaptain) ||
                (this.booking.latestStatus == BookingStatus.CancelledByPassenger) ||
                (this.booking.paymentStatus == PaymentStatus.Paid)
            )
        );

        return (
            <PageContainer className={styles.bookingInfo}>

                <PageHeader title={Localizer.bookingInfoPageTitle} subtitle={this.subtitle} />

                <div className={styles.controls}>
                    <Inline justify={JustifyContent.End}>
                        <Button right
                                title={Localizer.genericBack}
                                icon={{name: "fas arrow-alt-circle-left"}}
                                type={ButtonType.Primary}
                                route={PageDefinitions.bookingsRoute}
                        />
                    </Inline>
                </div>

                {
                    (this.state.initialized) &&
                    (
                        <PageRow>

                            {
                                this.renderQuickInfo()
                            }

                            {
                                this.renderUserContainer()
                            }

                            {
                                this.renderStatusesGrid()
                            }

                            {
                                (renderPaymentInfo) && this.renderPaymentInfoGrid()
                            }

                        </PageRow>
                    )
                }
            </PageContainer>
        );
    }
}