import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import {
    Button,
    ButtonContainer, ButtonType, EmailInput, Form, IconSize, ImageInput, Inline, JustifyContent, NumberInput,
    PageContainer,
    PageHeader,
    PageRow, PhoneInput, Tab, TabContainer, TextInput, ThreeColumns, ToolbarContainer
} from "@reapptor-apps/reapptor-react-components";
import Country from "@/models/server/bout/Country";
import PageDefinitions from "@/providers/PageDefinitions";
import SaveCountryRequest from "@/models/server/requests/SaveCountryRequest";
import SaveCountryResponse from "@/models/server/responses/SaveCountryResponse";
import {DataStorageType, PageRoute, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {FileModel, Utility} from "@reapptor-apps/reapptor-toolkit";
import AppConstants from "@/helpers/AppConstants";
import AreasPanel from "@/pages/CountryManagement/AreasPanel/AreasPanel";
import Localizer from "@/localization/Localizer";

import styles from "./CountryManagement.module.scss";

interface ICountryManagementProps  {
}

interface ICountryManagementState {
    country: Country | null;
    hashCode: number;
}

export default class CountryManagement extends AuthorizedPage<ICountryManagementProps, ICountryManagementState> {

    state: ICountryManagementState = {
        country: null,
        hashCode: 0
    };
    
    private readonly _areasPanelRef: React.RefObject<AreasPanel> = React.createRef();
    private readonly _tabContainerRef: React.RefObject<TabContainer> = React.createRef();

    private async setCodeAsync(value: string): Promise<void> {
        if ((this.country) && (this.country.code != value)) {
            this.country.code = value;
            await this.reRenderAsync();
        }
    }

    private async setNameAsync(value: string): Promise<void> {
        if ((this.country) && (this.country.name != value)) {
            this.country.name = value;
            await this.reRenderAsync();
        }
    }

    private async setNativeNameAsync(value: string): Promise<void> {
        if ((this.country) && (this.country.nativeName != value)) {
            this.country.nativeName = value;
            await this.reRenderAsync();
        }
    }

    private async setContactEmailAsync(value: string): Promise<void> {
        if ((this.country) && (this.country.contactEmail != value)) {
            this.country.contactEmail = value;
            await this.reRenderAsync();
        }
    }

    private async setContactPhoneAsync(value: string): Promise<void> {
        if ((this.country) && (this.country.contactPhone != value)) {
            this.country.contactPhone = value;
            await this.reRenderAsync();
        }
    }

    private async setMoneySymbolAsync(value: string): Promise<void> {
        if ((this.country) && (this.country.nativeName != value)) {
            this.country.moneySymbol = value;
            await this.reRenderAsync();
        }
    }

    private async setMoneyThreeLetterNameAsync(value: string): Promise<void> {
        if ((this.country) && (this.country.moneyThreeLetterName != value)) {
            this.country.moneyThreeLetterName = value;
            await this.reRenderAsync();
        }
    }

    private async setCurrencyAsync(value: string): Promise<void> {
        if ((this.country) && (this.country.currency != value)) {
            this.country.currency = value;
            await this.reRenderAsync();
        }
    }

    private async setVatPercentageAsync(value: number): Promise<void> {
        if ((this.country) && (this.country.vatPercentage != value)) {
            this.country.vatPercentage = value;
            this.country.vatValue = 1.0 + value/100.0;
            await this.reRenderAsync();
        }
    }

    private async setPricePerLiterAsync(value: number): Promise<void> {
        if ((this.country) && (this.country.pricePerLiter != value)) {
            this.country.pricePerLiter = value;
            await this.reRenderAsync();
        }
    }
    
    private async setLogoAsync(value: FileModel[]): Promise<void> {
        if (this.country) {
            this.country.logo = (value.length > 0)
                ? value[0].src
                : null;
            
            await this.reRenderAsync();
        }
    }

    private async activateAsync(): Promise<void> {
        if ((this.country) && (!this.isNew)) {
            
            await this.postAsync("/api/country/activateCountry", this.country.id);
            
            this.country.active = true;
            
            await this.alertMessageAsync(Utility.format(Localizer.countryManagementPageAlertErrorCountryActivate, this.country.name), true, false);

            await this.reloadAsync();
        }
    }

    private async deactivateAsync(): Promise<void> {
        if ((this.country) && (!this.isNew)) {

            await this.postAsync("/api/country/deactivateCountry", this.country.id);

            this.country.active = false;

            await this.alertMessageAsync(Utility.format(Localizer.countryManagementPageAlertErrorCountryDeactivate, this.country.name), true, false);

            await this.reloadAsync();
        }
    }
    
    private async addAreaAsync(): Promise<void> {
        if (this._areasPanelRef.current) {
            await this._areasPanelRef.current.addAreaAsync();
        }
    }
    
    private async reloadAreaAsync(): Promise<void> {
        if (this._areasPanelRef.current) {
            await this._areasPanelRef.current.reloadAsync();
        }
    }

    private async reloadAsync(): Promise<void> {

        const countryId: string | null = ((this.country) && (this.country.id))
            ? this.country.id
            : this.routeId;

        let country: Country;
        if (countryId) {
            country = await this.postAsync("/api/country/getCountry", countryId);
        } else {
            country = new Country();
            country.moneySymbol = "€";
            country.moneyThreeLetterName = "EUR";
        }

        const hashCode: number = Utility.getHashCode(country);

        await this.setState({country, hashCode});
    }

    private async submitCountryAsync(): Promise<void> {
        if (this.country) {

            if (!this.country.logo) {
                await this.alertErrorAsync(Localizer.countryManagementPageAlertErrorLogoRequired, true);
                return;
            }

            const request = new SaveCountryRequest();

            this.copyTo(this.country, request);

            const isNew: boolean = (!this.country.id);

            const response: SaveCountryResponse = await this.postAsync("/api/country/saveCountry", request);

            if (response.alreadyExists) {
                await this.alertErrorAsync(`Country name ${this.country.name}, native name ${request.nativeName}, code ${request.code} already exists`, false);

                return;
            }

            const country: Country = response.country!;

            if (isNew) {
                const route: PageRoute = PageDefinitions.countryManagement(country.id);
                await PageRouteProvider.redirectAsync(route);
            } else {
                const hashCode: number = Utility.getHashCode(country);
                await this.setState({country, hashCode});
            }

            await this.alertMessageAsync(Utility.format(Localizer.countryManagementPageAlertErrorCountrySaved, this.country.name), true, false);
        }
    }

    private get country(): Country | null {
        return this.state.country;
    }

    private isModified(): boolean {
        return ((this.country != null) && (this.state.hashCode != Utility.getHashCode(this.country)));
    }
    
    private get pictures(): FileModel[] {
        return ((this.country) && (this.country.logo))
            ? [new FileModel(this.country.logo)]
            : [];
    }

    private get isNew(): boolean {
        return (this.country != null) && (!this.country.id);
    }
    
    private get isAreasTabActive(): boolean {
        return ((this._tabContainerRef.current != null) && (this._tabContainerRef.current.model.activeIndex == 1));
    }

    public getTitle(): string {
        return Localizer.countryManagementPageTitle;
    }

    public getSubtitle(): string {
        return this.country
            ? (!!this.country.id)
                ? this.country.name!
                : "{0} *".format(this.country.name)
            : "...";
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        await this.reloadAsync();
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={this.css(styles.countryManagement)}>
                
                <PageHeader title={this.getTitle()} subtitle={this.getSubtitle()} />
                
                <PageRow>
                    <div className="col">

                        <ToolbarContainer className={styles.toolbar}>

                            <Inline justify={JustifyContent.End} className={styles.inline}>

                                {
                                    ((this.country) && (!this.isNew) && (!this.country.active) && (!this.isAreasTabActive)) &&
                                    (
                                        <Button title={Localizer.genericActivate} className="ml-1"
                                                icon={{name: "far play-circle", size: IconSize.Large}}
                                                type={ButtonType.Secondary}
                                                disabled={this.isModified()}
                                                confirm={Localizer.countryManagementPageButtonActivateConfirm}
                                                onClick={async () => await this.activateAsync()}
                                        />
                                    )
                                }

                                {
                                    ((this.country) && (!this.isNew) && (this.country.active) && (!this.isAreasTabActive)) &&
                                    (
                                        <Button title={Localizer.genericDeactivate} className="ml-1"
                                                icon={{name: "far pause-circle", size: IconSize.Large}}
                                                type={ButtonType.Secondary}
                                                disabled={this.isModified()}
                                                confirm={Localizer.countryManagementPageButtonDeactivateConfirm}
                                                onClick={async () => await this.deactivateAsync()}
                                        />
                                    )
                                }

                                {
                                    (this.isAreasTabActive) &&
                                    (
                                        <Button icon={{name: "plus", size: IconSize.Large}}
                                                type={ButtonType.Orange}
                                                title={Localizer.countryManagementPageButtonAddAreaTitle}
                                                onClick={async () => await this.addAreaAsync()}
                                        />
                                    )
                                }

                                {
                                    (this.isAreasTabActive) &&
                                    (
                                        <Button title={Localizer.countryManagementPageButtonReloadAreasTitle} className="ml-1"
                                                icon={{name: "far history", size: IconSize.Large}}
                                                type={ButtonType.Info}
                                                onClick={async () => await this.reloadAreaAsync()}
                                        />
                                    )
                                }

                                {
                                    (!this.isAreasTabActive) &&
                                    (
                                        <Button title={Localizer.genericReload} className="ml-1"
                                                icon={{name: "far history", size: IconSize.Large}}
                                                type={ButtonType.Info}
                                                confirm={this.isModified() ? Localizer.countryManagementPageButtonReloadAreasConfirm : undefined}
                                                onClick={() => this.reloadAsync()}
                                        />
                                    )
                                }
    
                                <Button right
                                        title={Localizer.genericBack}
                                        icon={{name: "fas arrow-alt-circle-left"}}
                                        type={ButtonType.Primary}
                                        route={PageDefinitions.countriesRoute}
                                />

                            </Inline>

                        </ToolbarContainer>

                        {
                            (this.country) &&
                            (
                                <TabContainer ref={this._tabContainerRef}
                                              id="countryManagementTabs"
                                              dataStorageType={DataStorageType.Route}
                                              onSelect={()=> this.reRenderAsync()}
                                >

                                    <Tab id="info" title={Localizer.countryManagementPageTabCountryInfoTitle}>

                                        <Form onSubmit={async () => await this.submitCountryAsync()}>

                                            <ThreeColumns>

                                                <TextInput id="code" required autoFocus noAutoComplete
                                                           label={Localizer.countryManagementPageTextInputCodeLabel}
                                                           value={this.country.code || ""}
                                                           onChange={(sender, value) => this.setCodeAsync(value)}
                                                />
                                                
                                            </ThreeColumns>

                                            <ThreeColumns>

                                                <TextInput id="name" required noAutoComplete
                                                           label={Localizer.countryManagementPageTextInputNameLabel}
                                                           value={this.country.name || ""}
                                                           onChange={(sender, value) => this.setNameAsync(value)}
                                                />

                                                <TextInput id="nativeName" required noAutoComplete
                                                           label={Localizer.countryManagementPageTextInputNativeNameLabel}
                                                           value={this.country.nativeName || ""}
                                                           onChange={(sender, value) => this.setNativeNameAsync(value)}
                                                />

                                            </ThreeColumns>

                                            <ThreeColumns>

                                                <PhoneInput id="contactPhone" required noAutoComplete
                                                            label={Localizer.countryManagementPagePhoneInputContactPhoneLabel}
                                                            value={this.country.contactPhone || ""}
                                                            onChange={(sender, value) => this.setContactPhoneAsync(value)}
                                                />

                                                <EmailInput id="contactEmail" required noAutoComplete
                                                            label={Localizer.countryManagementPageEmailInputContactEmailLabel}
                                                            value={this.country.contactEmail || ""}
                                                            onChange={(sender, value) => this.setContactEmailAsync(value)}
                                                />

                                            </ThreeColumns>
                                            
                                            <ThreeColumns>

                                                <TextInput id="moneySymbol" required noAutoComplete
                                                           label={Localizer.countryManagementPageTextInputMoneySymbolLabel}
                                                           maxLength={10}
                                                           value={this.country.moneySymbol || ""}
                                                           onChange={(sender, value) => this.setMoneySymbolAsync(value)}
                                                />

                                                <TextInput id="moneyThreeLetterName" required noAutoComplete
                                                           maxLength={3}
                                                           label={Localizer.countryManagementPageTextInputMoneyThreeLetterNameLabel}
                                                           value={this.country.moneyThreeLetterName || ""}
                                                           onChange={(sender, value) => this.setMoneyThreeLetterNameAsync(value)}
                                                />

                                                <TextInput id="currency" required noAutoComplete
                                                           label={Localizer.countryManagementPageTextInputCurrencyLabel}
                                                           value={this.country.currency || ""}
                                                           onChange={(sender, value) => this.setCurrencyAsync(value)}
                                                />
                                                
                                            </ThreeColumns>
                                            
                                            <ThreeColumns>

                                                <NumberInput id="vatPercentage" required
                                                             label={Localizer.countryManagementPageNumberInputVatPercentageLabel}
                                                             min={0}
                                                             max={100}
                                                             step={1}
                                                             value={this.country.vatPercentage}
                                                             onChange={(sender, value) => this.setVatPercentageAsync(value)}
                                                />

                                                <NumberInput id="vatValue" required readonly
                                                             label={Localizer.countryManagementPageNumberInputVatValueLabel}
                                                             value={this.country.vatValue}
                                                />
                                                
                                            </ThreeColumns>
                                            
                                            <ThreeColumns>

                                                <NumberInput id="pricePerLiter" required
                                                             label={Localizer.countryManagementPageNumberInputPricePerLiter}
                                                             min={0}
                                                             max={100}
                                                             step={0.1}
                                                             value={this.country.pricePerLiter}
                                                             onChange={(sender, value) => this.setPricePerLiterAsync(value)}
                                                />
                                                
                                            </ThreeColumns>

                                            <ThreeColumns>
                                                
                                                <ImageInput minimizeOnEmpty
                                                            maxImageRequestSizeInBytes={AppConstants.maxImageLogoSizeInBytes}
                                                            pictures={this.pictures}
                                                            onChange={(sender, value) => this.setLogoAsync(value)}
                                                />
                                                
                                            </ThreeColumns>

                                            <ButtonContainer>

                                                <Button submit
                                                        icon={{name: "save", size: IconSize.Large}}
                                                        label={Localizer.genericSave}
                                                        type={ButtonType.Primary}
                                                />

                                            </ButtonContainer>

                                        </Form>

                                    </Tab>

                                    {
                                        (!this.isNew) &&
                                        (
                                            <Tab id="areas" title={Localizer.countryManagementPageTabAreasTitle}>

                                                <AreasPanel ref={this._areasPanelRef} countryId={this.country.id} />

                                            </Tab>

                                        )
                                    }
                                    
                                </TabContainer>
                            )
                        }

                    </div>
                </PageRow>
                
            </PageContainer>
        );
    }
}