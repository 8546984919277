import React from "react";
import {BasePageParameters} from "@reapptor-apps/reapptor-react-common";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import {PageContainer, PageHeader, PageRow, Tab, TabContainer, TabRenderType} from "@reapptor-apps/reapptor-react-components";
import WayPointsPanel from "@/pages/ServicePointsManagement/WayPointsPanel/WayPointsPanel";
import ServicePointsPanel from "@/pages/ServicePointsManagement/ServicePointsPanel/ServicePointsPanel";
import Area from "@/models/server/bout/Area";
import ServicePoint from "@/models/server/bout/ServicePoint";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

export enum ServicePointsTab {
    ServicePoints,
    
    Waypoints
}

export interface IServicePointsProps extends BasePageParameters {
    tab?: ServicePointsTab | null;
}

interface IServicePointsState {
    areas: Area[] | [];
    servicePoints: ServicePoint[] | null;
}

export default class ServicePoints extends AuthorizedPage<IServicePointsProps, IServicePointsState> {

    state: IServicePointsState = {
        areas: [],
        servicePoints: null
    }

    private readonly _waypointPanelRef: React.RefObject<WayPointsPanel> = React.createRef();
    private readonly _tabContainerRef: React.RefObject<TabContainer> = React.createRef();

    private async fetchAreasAsync(): Promise<void> {
        const areas: Area[] = await AppController.listAreasAsync();

        await this.setState({areas});
    }

    public getTitle(): string {
        return Localizer.servicePointsPanelTitle;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        const parameters = this.parameters as IServicePointsProps | null;
        
        if ((parameters?.tab) && (this._tabContainerRef.current)) {
            const tabIndex: number = (parameters.tab == ServicePointsTab.Waypoints) ? 1 : 0;
            await this._tabContainerRef.current.model.activateTabAsync(tabIndex);
        }

        await this.fetchAreasAsync();
    }

    public render(): React.ReactNode {
        return (
            <PageContainer fullHeight>

                <PageHeader title={Localizer.servicePointsPanelTitle} 
                            subtitle={Localizer.servicePointsManagementSubtitle}
                />

                <PageRow>
                    
                    <TabContainer ref={this._tabContainerRef} renderType={TabRenderType.Once}>

                        <Tab id="servicePoints" title={Localizer.servicePointsPanelTitle}>
                            <ServicePointsPanel areas={this.state.areas}/>
                        </Tab>

                        <Tab id="waypoints" title={Localizer.servicePointsManagementWaypoints}>
                            <WayPointsPanel areas={this.state.areas} ref={this._waypointPanelRef}/>
                        </Tab>

                    </TabContainer>

                </PageRow>
                
            </PageContainer>
        );
    }
}