import React from "react";
import {BaseComponent, IBaseComponent, LocalizationString} from "@reapptor-apps/reapptor-react-common";
import Container from "@/components/Container/Container";
import ServiceProvider from "@/models/server/ServiceProvider";
import Localizer from "@/localization/Localizer";

import styles from "./ServiceProviderContainer.module.scss";

export interface IServiceProviderContainerProps {
    serviceProviders: ServiceProvider[];
    search?: string;
    onClick?(sender: IBaseComponent, item: ServiceProvider): Promise<void>;
}

export default class ServiceProviderContainer extends BaseComponent<IServiceProviderContainerProps> {
    
    private async onClickAsync(item: ServiceProvider): Promise<void> {
        if (this.props.onClick) {
            await this.props.onClick(this, item);
        }
    }

    private renderServiceProvider(item: ServiceProvider, index: number): React.ReactNode {
        return (
            <Container.Item key={index} className={this.css(styles.serviceProvider)}>
                {
                    (item.logo) &&
                    (
                        <div className={styles.logo}>
                            <img src={item.logo}
                                 alt={item.companyName || undefined}
                                 title={item.companyName || undefined}
                            />
                        </div>
                    )
                }
                <div className={styles.info} onClick={() => this.onClickAsync(item)}>
                    <span>
                        {item.companyName}
                        <p>{item.companyBusinessId}</p>
                    </span>
                    <a href={item.url || "#"} target={"_blank"}>{LocalizationString.value(item.name)}</a>
                </div>
            </Container.Item>
        );
    }
    
    private filter(item: ServiceProvider, search: string): boolean {
        return (
            ((!!item.companyName) && (item.companyName.toLowerCase().includes(search))) ||
            ((!!item.companyBusinessId) && (item.companyBusinessId.toLowerCase().includes(search)))
        )
    }
    
    private getServiceProviders(): ServiceProvider[] {
        const search: string | null = this.props.search || null;
        let serviceProviders: ServiceProvider[] = this.props.serviceProviders;
        return (search)
            ? serviceProviders.filter(item => this.filter(item, search.toLowerCase()))
            : serviceProviders;
    }

    public render(): React.ReactNode {
        const serviceProviders: ServiceProvider[] = this.getServiceProviders();
        return (
            <div id={this.id} className={this.css(styles.serviceProviderContainer)}>
                {
                    (serviceProviders.length > 0)
                        ?
                        (
                            <Container>
                                {serviceProviders.map((item, index) => this.renderServiceProvider(item, index))}
                            </Container>
                        )
                        :
                        (
                            <span className={styles.noServiceProviders}>{Localizer.serviceProvidersPageNoServiceProviders}</span>
                        )
                }
            </div>
        )
    }
}