import React from "react";
import {BaseComponent, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import EstimatedBooking from "@/models/server/bout/EstimatedBooking";
import Booking from "@/models/server/bout/Booking";
import CruisePackageBooking from "@/models/server/cruise/CruisePackageBooking";
import ShuttleEstimatedBooking from "@/models/server/shuttle/ShuttleEstimatedBooking";
import Localizer from "@/localization/Localizer";
import AppController from "@/pages/AppController";

import styles from "./PriceInfo.module.scss";
import EnumProvider from "@/providers/EnumProvider";
import ServiceProviderController from "@/pages/ServiceProviderController";
import {BookingStatus} from "@/models/Enums";

export interface IPriceInfoProps {
    booking: EstimatedBooking | Booking | CruisePackageBooking;
}

interface IPriceInfoState {
}

export default class PriceInfo extends BaseComponent<IPriceInfoProps, IPriceInfoState> {

    state: IPriceInfoState = {
    };

    public get booking(): EstimatedBooking | ShuttleEstimatedBooking | Booking | CruisePackageBooking {
        return this.props.booking;
    }

    private get isEstimatedBooking(): boolean {
        return (EstimatedBooking.is(this.booking));
    }

    private get isShuttleEstimatedBooking(): boolean {
        return (ShuttleEstimatedBooking.is(this.booking));
    }

    private get isBooking(): boolean {
        return (Booking.is(this.booking));
    }

    private get approvedBooking(): Booking | null {
        return (Booking.as(this.booking));
    }
    
    public get showPaymentPolicyMessage(): boolean {
        return (
            (AppController.asPassenger) &&
            (
                (this.approvedBooking == null) ||
                (!Booking.completed(this.approvedBooking))
            )
        );
    }
    
    public get paymentPolicyMessage(): string {
        return Localizer.get(EnumProvider.getPaymentPolicyDescription(this.booking.paymentPolicy));
    }
    
    public get refundPolicyMessage(): string {
        return (this.booking.noRefund)
            ? Localizer.mobilePriceInfoNoRefund
            : Localizer.mobilePriceInfoRefundPolicy;
    }
    
    public get expirationPolicyMessage(): string {
        const estimatedBookingExpirationInMinutes: number | null = ServiceProviderController.estimatedBookingExpirationInMinutes;
        const booking: Booking | null = Booking.as(this.booking);
        const showMessage: boolean = (
            (estimatedBookingExpirationInMinutes != null) && (estimatedBookingExpirationInMinutes > 0) &&
            (
                ((this.isEstimatedBooking) && (!this.isShuttleEstimatedBooking)) ||
                ((booking != null) && (booking.latestStatus == BookingStatus.New))
            )
        );
        return (showMessage)
            ? Localizer.mobilePriceInfoExpirationPolicy.format(estimatedBookingExpirationInMinutes)
            : "";
    }

    public get detailedPaymentPolicyMessage(): string {
        const message: string = `${this.paymentPolicyMessage}. ${this.refundPolicyMessage}. ${this.expirationPolicyMessage}.`;
        return message
            .replaceAll("..", ".")
            .replaceAll(". .", ".")
            .replaceAll("  ", " ");
    }
    
    public get header(): string {
        if ((this.isEstimatedBooking) && (!this.isShuttleEstimatedBooking)) {
            const estimatedBooking = this.booking as EstimatedBooking;
            return (estimatedBooking.minPrice != estimatedBooking.maxPrice)
                ? Localizer.mobilePriceInfoPriceEstimation
                : Localizer.mobilePriceInfoPrice;
        }

        const booking: Booking = this.booking as Booking;
        return (booking.price != null)
            ? Localizer.mobilePriceInfoPrice
            : (booking.estimatedMinPrice != booking.estimatedMaxPrice)
                ? Localizer.mobilePriceInfoPriceEstimation
                : Localizer.mobilePriceInfoPrice;
    }

    public get price(): string {
        if (this.isEstimatedBooking) {
            const estimatedBooking = this.booking as EstimatedBooking;
            const moneySymbol: string = estimatedBooking.country?.moneySymbol ?? "";

            return (estimatedBooking.minPrice != estimatedBooking.maxPrice)
                ? "{0:# ##0.0} - {1:# ##0.0} {2}".format(estimatedBooking.minPrice, estimatedBooking.maxPrice, moneySymbol)
                : "{0:# ##0.0} {1}".format(estimatedBooking.minPrice, moneySymbol);
        }

        if (this.isBooking) {
            const booking = this.booking as Booking;
            const moneySymbol: string = booking.area?.country?.moneySymbol ?? "";
            
            if ((AppController.asCaptain) && (AppController.isEntrepreneur)) {
                return (booking.captainShare != null)
                    ? "{0}: {1:# ##0.0} {2}\n{3}: {4:# ##0.0} {5}".format(Localizer.mobilePriceInfoCaptainShare, booking.captainShare, moneySymbol, Localizer.mobilePriceInfoTripPrice, booking.price, moneySymbol)
                    : "{0}: {1} {2}".format(Localizer.mobilePriceInfoTripPrice, booking.price, moneySymbol);
            }
            
            return (booking.price != null)
                ? "{0:# ##0.0} {1}".format(booking.price, moneySymbol)
                : (booking.estimatedMinPrice != booking.estimatedMaxPrice)
                    ? "{0:# ##0.0} - {1:# ##0.0} {2}".format(booking.estimatedMinPrice, booking.estimatedMaxPrice, moneySymbol)
                    : "{0:# ##0.0} {1}".format(booking.estimatedMinPrice, moneySymbol);
        }

        const cruisePackageBooking: CruisePackageBooking = this.booking as CruisePackageBooking;
        const moneySymbol: string = cruisePackageBooking.cruisePackage?.area?.country?.moneySymbol ?? "";
        return "{0:# ##0.0} {1}".format(cruisePackageBooking.estimatedPrice, moneySymbol)
    }

    public render(): React.ReactNode {

        return (
            <div className={styles.priceInfo}>

                <span>{ReactUtility.toTags(this.header)}</span>

                <div className={styles.context}>

                    <span className={styles.price}>{ReactUtility.toMultiLines(this.price)}</span>

                    {
                        (this.showPaymentPolicyMessage) &&
                        (
                            <span className={styles.paymentPolicy}>
                                {ReactUtility.toTags(this.detailedPaymentPolicyMessage)}
                            </span>
                        )
                    }
                    
                </div>

            </div>
        );
    }
}