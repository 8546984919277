import React from "react";
import {Icon} from "@reapptor-apps/reapptor-react-components";
import CruisePackageWizard from "@/pages/Mobile/CruisePackageWizard/CruisePackageWizard";
import Country from "@/models/server/bout/Country";
import CruisePackageWizardController from "@/pages/Mobile/CruisePackageWizard/CruisePackageWizardController";
import Localizer from "@/localization/Localizer";

import styles from "./CountryStep.module.scss";

export interface ICountryStepProps {
}

interface ICountryStepState {
    countries: Country[];
}

export default class CountryStep extends CruisePackageWizard<ICountryStepProps, ICountryStepState> {

    state: ICountryStepState = {
        countries: []
    };
    
    public getCountryNativeName(country: Country): string {
        const countryLanguageTerm: string = `Country.${country.name}`;
        return Localizer.contains(countryLanguageTerm)
            ? Localizer.get(countryLanguageTerm)
            : country.name || country.nativeName!;
    }

    public async selectAsync(country: Country): Promise<void> {
        this.wizard.country = country;
        this.save();
        await super.nextAsync();
    }

    public get countries(): Country[] {
        return this.state.countries;
    }

    public get canNext(): boolean {
        return !!this.wizard.country;
    }

    public get hasGenericBackButton(): boolean {
        return false;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        const countries: Country[] = await CruisePackageWizardController.listCountriesAsync();

        await this.setState({countries});
    }
    
    public renderButtons(): React.ReactNode {
        return (
            <React.Fragment>
            </React.Fragment>
        )
    }

    public renderCountry(country: Country): React.ReactNode {
        const name: string = this.getCountryNativeName(country);
        return (
            <div key={country.id} onClick={() => this.selectAsync(country)}>
                <div className={this.css(styles.image, this.mobile && styles.mobile)}>
                    <img src={country.logo!} alt={name} />
                </div>
                <span>{name}</span>
            </div>
        );
    }

    public renderContent(): React.ReactNode {
        return (
            <div className={this.css(styles.countryStep, this.mobile && styles.mobile)}>
                
                <div className={styles.container}>
                    {
                        this.countries.map((country) => this.renderCountry(country))
                    }
                </div>

                {
                    (this.cruiseStyle) && (
                        <div className={styles.close}>
                            <Icon name={"fal fa-times-circle"} onClick={() => CruisePackageWizardController.closeAsync()}/>
                        </div>
                    )
                }

            </div>
        );
    }
}