import React from "react";
import {
    ApiProvider,
    BasePageParameters,
    PageRoute,
    PageRouteProvider,
    ReactUtility
} from "@reapptor-apps/reapptor-react-common";
import {
    DateInput,
    Icon,
    IconSize,
    NumberWidget,
    PageContainer,
    TextInput
} from "@reapptor-apps/reapptor-react-components";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import WaypointMap from "@/components/WaypointMap/WaypointMap";
import Waypoint from "@/models/server/bout/Waypoint";
import PageDefinitions from "@/providers/PageDefinitions";
import EstimateBookingsRequest from "@/models/server/requests/EstimateBookingsRequest";
import EstimatedBooking from "@/models/server/bout/EstimatedBooking";
import {DateUtility, TimeSpan, Utility} from "@reapptor-apps/reapptor-toolkit";
import ShuttleWaypoint from "@/models/server/shuttle/ShuttleWaypoint";
import EstimatedBookingContainer from "./EstimatedBookingContainer/EstimatedBookingContainer";
import EstimateShuttleBookingsRequest from "@/models/server/requests/EstimateShuttleBookingsRequest";
import EstimateShuttleBookingsResponse from "@/models/server/responses/EstimateShuttleBookingsResponse";
import EstimateBookingsResponse from "@/models/server/requests/EstimateBookingsResponse";
import {TicketType} from "@/models/Enums";
import AppConstants from "@/helpers/AppConstants";
import AppController, {RideBookingRules} from "@/pages/AppController";
import PreBookController from "@/pages/PreBookController";
import ServiceProviderController from "@/pages/ServiceProviderController";
import Localizer from "@/localization/Localizer";

import boutStyles from "../../../bout.module.scss";
import styles from "./BoatSelection.module.scss";

export interface IBoatSelectionParameters extends BasePageParameters {
    waypoint?: Waypoint;
}

interface IBoatSelectionState {
    waypoint: Waypoint | null;
    loading: boolean;
    passengers: number;
    adults: number;
    children: number;
    bikesAndTrolleys: number;
    pensioners: number;
    estimatedBookings: EstimatedBooking[];
    maxAvailableCapacity: number;
    now: Date;
    rideBookingRules: RideBookingRules;
}

export default class BoatSelection extends AuthorizedPage<IBoatSelectionParameters, IBoatSelectionState> {
    
    _now: Date = Utility.now(); //SET date for testing

    state: IBoatSelectionState = {
        waypoint: null,
        loading: true,
        passengers: PreBookController.passengers ?? 1,
        adults: PreBookController.adults ?? 1,
        children: PreBookController.children ?? 0,
        bikesAndTrolleys: PreBookController.bikesAndTrolleys ?? 0,
        pensioners: PreBookController.pensioners ?? 0,
        estimatedBookings: [],
        maxAvailableCapacity: 0,
        now: this._now, // Utility.now(),
        rideBookingRules: AppController.getRideBookingRules(AppController.getRideBookingSettings(), this._now),
    };

    private readonly _dateInputRef: React.RefObject<DateInput> = React.createRef();
    private readonly _timeInputRef: React.RefObject<DateInput> = React.createRef();

    private async selectBookingAsync(booking: EstimatedBooking): Promise<void> {
        const route: PageRoute = PageDefinitions.bookingDetails(booking);

        await PageRouteProvider.redirectAsync(route);
    }

    private async setBookingsAsync(response: EstimateBookingsResponse | EstimateShuttleBookingsResponse): Promise<void> {
        const estimatedBookings: EstimatedBooking[] = response.items;
        const maxAvailableCapacity: number = (response as EstimateBookingsResponse)?.maxAvailableCapacity ?? 0;
        
        await Utility.wait(70);

        await this.setState({estimatedBookings, maxAvailableCapacity, loading: false});
    }

    private async estimatedBookingsAsync(): Promise<void> {
        if (this.waypoint) {

            const shuttleWaypoint: ShuttleWaypoint | null = ShuttleWaypoint.as(this.waypoint);

            if (shuttleWaypoint) {

                const request = new EstimateShuttleBookingsRequest();
                request.shuttleId = shuttleWaypoint.shuttleId;
                request.sourceServicePointId = shuttleWaypoint.sourceId;
                request.destinationServicePointId = shuttleWaypoint.destinationId;
                request.bookingTime = this.bookingTime;
                request.passengers = this.passengers;
                request.adults = (this.askForAdults) ? this.adults : 0;
                request.children = (this.askForChildren) ? this.childrenCount : 0;
                request.bikesAndTrolleys = (this.askForBikesAndTrolleys) ? this.bikesAndTrolleys : 0;
                request.pensioners = (this.askForPensioners) ? this.pensioners : 0;

                await this.setState({loading: true});

                await ApiProvider.backgroundPostAsync("/api/mobileApp/estimateShuttleBookings", request, this, true, (response: EstimateShuttleBookingsResponse) => this.setBookingsAsync(response));

            } else {

                const request = new EstimateBookingsRequest();
                request.waypointId = this.waypoint.id;
                request.bookingTime = this.bookingTime;
                request.passengers = this.passengers;

                await this.setState({loading: true});

                await ApiProvider.backgroundPostAsync("/api/mobileApp/estimateBookings", request, this, true, (response: EstimateBookingsResponse) => this.setBookingsAsync(response));
            }
        }
    }

    private countPassengers(): void {
        const passengers: number = this.adults + this.childrenCount + this.bikesAndTrolleys + this.pensioners;

        this.state.passengers = passengers;

        PreBookController.setPassengers(passengers);
    }

    private async setPassengersAsync(passengers: number): Promise<void> {
        if (this.passengers != passengers) {
            this.state.passengers = passengers;
            this.state.adults = passengers;
            this.state.children = 0;
            this.state.bikesAndTrolleys = 0;

            PreBookController.setPassengers(passengers);
            PreBookController.setAdults(passengers);
            PreBookController.setChildren(0);
            PreBookController.setBikesAndTrolleys(0);

            await this.estimatedBookingsAsync();
        }
    }

    private async setAdultsAsync(adults: number): Promise<void> {
        if (this.adults != adults) {

            this.state.adults = adults;

            this.countPassengers();

            PreBookController.setAdults(adults);

            await this.estimatedBookingsAsync();
        }
    }

    private async setPensionersAsync(pensioners: number): Promise<void> {
        if (this.pensioners != pensioners) {

            this.state.pensioners = pensioners;

            this.countPassengers();

            PreBookController.setPensioners(pensioners);

            await this.estimatedBookingsAsync();
        }
    }

    private async setChildrenAsync(children: number): Promise<void> {
        if (this.childrenCount != children) {
            this.state.children = children;

            this.countPassengers();

            PreBookController.setChildren(children);

            await this.estimatedBookingsAsync();
        }
    }

    private async setBikesAndTrolleysAsync(bikesAndTrolleys: number): Promise<void> {
        if (this.bikesAndTrolleys != bikesAndTrolleys) {
            this.state.bikesAndTrolleys = bikesAndTrolleys;

            this.countPassengers();

            PreBookController.setBikesAndTrolleys(bikesAndTrolleys);

            await this.estimatedBookingsAsync();
        }
    }

    public get waypoint(): Waypoint | null {
        return this.state.waypoint;
    }

    public get shuttleWaypoint(): ShuttleWaypoint | null {
        return ShuttleWaypoint.as(this.waypoint);
    }

    public get bookingTime(): Date {
        let bookingDate: Date | null = PreBookController.bookingDate;
        
        if (!this.isShuttle) {
            bookingDate = bookingDate ?? new Date(this.now);
            return this.rideBookingRules.getValidBookingTime(bookingDate);
        }
        
        const inPastOrZero: boolean = (bookingDate == null) || (bookingDate.getTime() < this.now.getTime());

        if (inPastOrZero) {
            bookingDate = new Date(this.now);
            bookingDate.setHours(bookingDate.getHours() + 1);
            bookingDate.setMinutes(0);
            bookingDate.setMilliseconds(0);
        }

        return bookingDate!;
    }

    public get isShuttle(): boolean {
        return (this.shuttleWaypoint != null);
    }

    public get passengers(): number {
        return this.state.passengers;
    }

    public get adults(): number {
        return this.state.adults;
    }

    public get childrenCount(): number {
        return this.state.children;
    }

    public get bikesAndTrolleys(): number {
        return this.state.bikesAndTrolleys;
    }

    public get pensioners(): number {
        return this.state.pensioners;
    }

    public get estimatedBookings(): EstimatedBooking[] {
        return this.state.estimatedBookings;
    }

    public get maxAvailableCapacity(): number {
        return this.state.maxAvailableCapacity;
    }

    public get maxPassengers(): number {
        return (this.isShuttle)
            ? ServiceProviderController.shuttleMaxTickets
            : AppConstants.defaultRideMaxFare;
    }

    public get loading(): boolean {
        return this.state.loading;
    }

    public get notFound(): boolean {
        return (this.waypoint != null) && (!this.loading) && (this.estimatedBookings.length === 0);
    }

    public get singleTicketType(): boolean {
        return (!this.askForAdults && !this.askForChildren && !this.askForBikesAndTrolleys && !this.askForPensioners);
    }

    public get askForAdults(): boolean {
        return (this.shuttleWaypoint != null) && (this.shuttleWaypoint.ticketTypes.contains(TicketType.Adult));
    }

    public get askForChildren(): boolean {
        return (this.shuttleWaypoint != null) && (this.shuttleWaypoint.ticketTypes.contains(TicketType.Children));
    }

    public get askForBikesAndTrolleys(): boolean {
        return (this.shuttleWaypoint != null) && (this.shuttleWaypoint.ticketTypes.contains(TicketType.BikesAndTrolleys));
    }

    public get askForPensioners(): boolean {
        return (this.shuttleWaypoint != null) && (this.shuttleWaypoint.ticketTypes.contains(TicketType.Pensioners));
    }

    public get adultsMin(): number {
        return ((!this.askForPensioners) || (this.pensioners == 0))
            ? 1
            : 0;
    }

    public get pensionersMin(): number {
        return ((!this.askForAdults) || (this.adults == 0))
            ? 1
            : 0;
    }

    public getTitle(): string {
        return Localizer.mobileBoatSelectionPageTitle;
    }

    public hasSpinner(): boolean {
        return (this.loading);
    }

    private async onChangeBookingTimeAsync(): Promise<void> {
        await this.estimatedBookingsAsync();
    }

    private async openDateAsync(): Promise<void> {
        await this._dateInputRef.current?.openAsync();
    }

    private async openTimeAsync(): Promise<void> {
        await this._timeInputRef.current?.openAsync();
    }

    private async setDateAsync(value: Date): Promise<void> {
        
        const date: Date = value.date();
        const time: TimeSpan = DateUtility.time(this.bookingTime);

        value = DateUtility.add(date, time);

        PreBookController.setBookingDate(value);

        await this.reRenderAsync();

        await this.onChangeBookingTimeAsync()
    }

    private async setTimeAsync(value: Date): Promise<void> {
        
        const date: Date = this.bookingTime.date();
        const time: TimeSpan = DateUtility.time(value);
        value = DateUtility.add(date, time);

        PreBookController.setBookingDate(value);

        await this.reRenderAsync();

        await this.onChangeBookingTimeAsync()
    }

    private get now(): Date {
        return this.state.now;
    }

    private get today(): Date {
        return this.now.date();
    }

    private get rideBookingRules(): RideBookingRules {
        return this.state.rideBookingRules;
    }

    public get isBookingAvailable(): boolean {
        return ((this.isShuttle) || (this.rideBookingRules.isBookingAvailable));
    }

    public get noBookingAvailableMessage(): string {
        return this.rideBookingRules.noBookingAvailableMessage;
    }
    
    public get boatSelectionHeader(): string {
        return (this.isShuttle)
            ? Localizer.mobileBoatSelectionPageChooseShuttleSpan
            : Localizer.mobileBoatSelectionPageChooseBoatSpan;
    }

    public get minDate(): Date {
        return (!this.isShuttle)
            ? this.rideBookingRules.getMinDate()
            : this.today;
    }

    private get minTime(): Date {
        const startOfTheDay: Date = this.today;
        startOfTheDay.setHours(0, 0, 0, 0);

        return (!this.isShuttle)
            ? this.rideBookingRules.getMinTime(this.bookingTime)
            : (this.bookingTime.isToday())
                ? this.now
                : startOfTheDay;
    }

    public get maxDate(): Date | null {
        return (!this.isShuttle)
            ? this.rideBookingRules.getMaxDate()
            : null;
    }

    private get maxTime(): Date | null {
        const endOfTheDay: Date = this.today;
        endOfTheDay.setHours(23, 59, 59, 999);

        return (!this.isShuttle)
            ? this.rideBookingRules.getMaxTime(this.bookingTime)
            : endOfTheDay;
    }

    public async initializeAsync(): Promise<void> {
        const waypointId: string | null = this.routeId;

        let waypoint: Waypoint | null = this.parameters?.waypoint ?? null;

        if ((!waypointId) && (!waypoint)) {
            await PageRouteProvider.redirectAsync(PageDefinitions.mobileHomeRoute, true, true);
        }

        waypoint = waypoint ?? await this.postAsync("/api/mobileApp/getWaypoint", waypointId);

        await this.setState({waypoint});

        await this.estimatedBookingsAsync();
    }

    public renderRouteName(): React.ReactNode {
        const routeName: string = "{0}".format(this.waypoint);

        return (
            <div className={styles.routeNameContainer}>

                <span className={styles.routeName}>{routeName}</span>

            </div>
        );
    }

    public renderDateSelection(isBookingAvailable: boolean): React.ReactNode {
        const bookingTime: Date = (isBookingAvailable)
            ? this.bookingTime
            : this.now;
        const date: string = "{0:dddd}, {1:D}".format(bookingTime, bookingTime);
        const time: string = Localizer.mobileDateInfoBookingTime.format(bookingTime);

        return (
            <div className={styles.date}>

                <span className={styles.header}>{Localizer.mobileBoatSelectionPageSelectDateAndTime}</span>
                
                <div className={styles.dateInput}>

                    <DateInput ref={this._dateInputRef}
                               title={Localizer.mobileBoatSelectionPageSelectDateTitle}
                               className={this.css(styles.input)}
                               minDate={this.minDate}
                               maxDate={this.maxDate}
                               value={this.bookingTime}
                               readonly={!isBookingAvailable}
                               onChange={(value: Date) => this.setDateAsync(value)}
                    />

                    <TextInput readonly
                               className={styles.label}
                               title={Localizer.mobileBoatSelectionPageSelectDateTitle}
                               value={date}
                               onClick={() => this.openDateAsync()}
                    />

                </div>

                <div className={styles.timeInput}>

                    <DateInput showTime showOnlyTime
                               ref={this._timeInputRef}
                               timeIntervals={15}
                               className={styles.input}
                               title={Localizer.mobileBoatSelectionPageSelectTimeTitle}
                               minDate={this.minTime}
                               minTime={this.minTime}
                               maxDate={this.maxTime}
                               maxTime={this.maxTime}
                               value={this.bookingTime}
                               readonly={!isBookingAvailable}
                               onChange={(value: Date) => this.setTimeAsync(value)}
                    />

                    <TextInput readonly
                               className={styles.label}
                               title={Localizer.mobileBoatSelectionPageSelectTimeTitle}
                               value={time}
                               onClick={() => this.openTimeAsync()}
                    />

                </div>

            </div>
        );
    }

    public renderAmountSelection(title: string | null = null, min: number, value: number, onChange: (value: number) => Promise<void>): React.ReactNode {
        
        const selectedStyle = (value > 0) && styles.selected;
        const max: number = (this.maxPassengers - this.passengers + value);
        const disabled: boolean = (max == 0);

        return (
            <div className={this.css(styles.amountContainer, selectedStyle)}>

                {
                    (title) &&
                    (
                        <div className={styles.amountTitle}>
                            <span>{ReactUtility.toTags(title)}</span>
                            <Icon name={"fas fa-caret-right"} size={IconSize.Large}/>
                        </div>
                    )
                }

                <NumberWidget className={styles.amount}
                              minusIcon={{name: "fas fa-minus"}}
                              plusIcon={{name: "fas fa-plus"}}
                              min={min}
                              max={max}
                              readonly={disabled}
                              value={value}
                              onChange={(_, value) => onChange(value)}
                />

            </div>
        )
    }

    public renderBoatsSelection(): React.ReactNode {
        return (
            <div className={styles.boat}>

                <span className={styles.header}>{this.boatSelectionHeader}</span>

                {
                    (this.singleTicketType) &&
                    (
                        this.renderAmountSelection(null, 1, this.passengers, (value) => this.setPassengersAsync(value))
                    )
                }

                {
                    (this.askForAdults) &&
                    (
                        this.renderAmountSelection(Localizer.mobileBoatSelectionPageAdults, this.adultsMin, this.adults, (value) => this.setAdultsAsync(value))
                    )
                }

                {
                    (this.askForPensioners) &&
                    (
                        this.renderAmountSelection(Localizer.mobileBoatSelectionPagePensioners, this.pensionersMin, this.pensioners, (value) => this.setPensionersAsync(value))
                    )
                }

                {
                    (this.askForChildren) &&
                    (
                        this.renderAmountSelection(Localizer.mobileBoatSelectionPageChildren, 0, this.childrenCount, (value) => this.setChildrenAsync(value))
                    )
                }

                {
                    (this.askForBikesAndTrolleys) &&
                    (
                        this.renderAmountSelection(Localizer.mobileBoatSelectionPageBikesAndTrolleys, 0, this.bikesAndTrolleys, (value) => this.setBikesAndTrolleysAsync(value))
                    )
                }

                <div className={styles.list}>

                    <EstimatedBookingContainer bookings={this.estimatedBookings}
                                               maxAvailableCapacity={this.maxAvailableCapacity}
                                               loading={this.loading}
                                               isShuttle={this.isShuttle}
                                               onClick={(sender, booking) => this.selectBookingAsync(booking)}
                    />

                </div>

            </div>
        );
    }

    public render(): React.ReactNode {
        const isBookingAvailable: boolean = this.isBookingAvailable;
        const isShuttle: boolean = this.isShuttle;

        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.boatSelection, this.mobile && styles.mobile)}
                           alertClassName={boutStyles.alert}
            >

                <div className={styles.map}>
                    {
                        (this.waypoint) &&
                        (
                            <WaypointMap readonly
                                         point={this.waypoint}
                            />
                        )
                    }
                </div>

                <div className={styles.selectionContainer}>

                    {
                        (isShuttle) && this.renderRouteName()
                    }

                    {
                        this.renderDateSelection(isBookingAvailable)
                    }

                    {
                        (isBookingAvailable)
                            ?
                            (
                                this.renderBoatsSelection()
                            )
                            :
                            (
                                <span className={styles.noBookingAvailable}>
                                    {ReactUtility.toMultiLines(this.noBookingAvailableMessage)}
                                </span>
                            )
                    }

                </div>

            </PageContainer>
        );
    }
}