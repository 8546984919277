import React from "react";
import {FileModel, IPagedList} from "@reapptor-apps/reapptor-toolkit";
import {BaseComponent, LocalizationString} from "@reapptor-apps/reapptor-react-common";
import {
    Checkbox,
    Dropdown,
    DropdownAlign,
    DropdownOrderBy,
    DropdownRequiredType,
    Form,
    ImageInput,
    Panel,
    SelectListItem,
    TextInput,
    ThreeColumns,
    TwoColumns,
    UrlInput
} from "@reapptor-apps/reapptor-react-components";
import ServiceProvider from "@/models/server/ServiceProvider";
import AppConstants from "@/helpers/AppConstants";
import Area from "@/models/server/bout/Area";
import LocalizationTextInput, {LocalizationTextInputType } from "@/components/LocalizationTextInput/LocalizationTextInput";
import Country from "@/models/server/bout/Country";
import Waypoint from "@/models/server/bout/Waypoint";
import {ApplicationType} from "@/models/Enums";
import ListWaypointsRequest from "@/models/server/requests/ListWaypointsRequest";
import AppController from "@/pages/AppController";
import TransformProvider from "@/providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./CompanyPanel.module.scss"

interface ICompanyPanelProps {
    serviceProvider: ServiceProvider;
    countries: Country[];
    areas: Area[];
}

interface ICompanyPanelState {
    waypoints: Waypoint[];
}

export default class CompanyPanel extends BaseComponent<ICompanyPanelProps, ICompanyPanelState> {

    state: ICompanyPanelState = {
        waypoints: [],
    };

    public readonly _formRef: React.RefObject<Form> = React.createRef();
    public readonly _waypointsRef: React.RefObject<Dropdown<Waypoint>> = React.createRef();
    
    private async fetchWaypointsAsync(): Promise<Waypoint[]> {
        const countryIds: string[] = this.serviceProvider.countryIds ?? [];
        const areaIds: string[] = this.serviceProvider.areaIds ?? [];
        if ((countryIds.length == 0) && (areaIds.length == 0)) {
            return [];
        }

        const request = new ListWaypointsRequest();
        request.countryIds = (countryIds.length > 0) ? countryIds : null;
        request.areaIds = (areaIds.length > 0) ? areaIds : null;
        request.pageNumber = 1;
        request.pageSize = AppConstants.maxPageSize;
        request.asAdmin = AppController.asAdmin;
        
        const response: IPagedList<Waypoint> = await this.postAsync("/api/admin/listWaypoints", request);

        return response.items;
    }
    
    private async reloadWaypointsAsync(): Promise<void> {
        const waypoints: Waypoint[] = await this.fetchWaypointsAsync();
        
        await this.setState({ waypoints });
    }

    private async setCompanyNameAsync(value: string): Promise<void> {
        if (this.serviceProvider.companyName != value) {
            this.serviceProvider.companyName = value;
            await this.reRenderAsync();
        }
    }

    private async setCompanyShortNameAsync(value: string): Promise<void> {
        if (this.serviceProvider.companyShortName != value) {
            this.serviceProvider.companyShortName = value;
            await this.reRenderAsync();
        }
    }

    private async setCompanyBusinessIdAsync(value: string): Promise<void> {
        if (this.serviceProvider.companyBusinessId != value) {
            this.serviceProvider.companyBusinessId = value;
            await this.reRenderAsync();
        }
    }

    private async setCountriesAsync(sender: Dropdown<Country>): Promise<void> {
        const countryIds: string[] = sender.selectedItems.select(item => item.id);
        const clear: boolean = (countryIds.length < (this.serviceProvider.countryIds ?? []).length);
        
        this.serviceProvider.countryIds = countryIds;
        
        if (clear) {
            this.serviceProvider.areaIds = [];
            this.serviceProvider.waypointIds = [];
        }
        
        await this.reloadWaypointsAsync();
    }

    private async setAreasAsync(sender: Dropdown<Area>): Promise<void> {
        const areaId: string[] = sender.selectedItems.select(item => item.id);
        const clear: boolean = (areaId.length < (this.serviceProvider.areaIds ?? []).length);
        
        this.serviceProvider.areaIds = sender.selectedItems.select(item => item.id);
        
        if (clear) {
            this.serviceProvider.waypointIds = [];
        }

        await this.reloadWaypointsAsync();
    }

    private async setWaypointsAsync(sender: Dropdown<Waypoint>): Promise<void> {
        this.serviceProvider.waypointIds = sender.selectedItems.select(item => item.id);
        
        await this.reRenderAsync();
    }

    private async setUrlAsync(value: string): Promise<void> {
        if (this.serviceProvider.url != value) {
            this.serviceProvider.url = value;
            await this.reRenderAsync();
        }
    }
    
    private async setSupportsRatingAsync(value: boolean): Promise<void> {
        this.serviceProvider.supportsRating = value;
        await this.reRenderAsync();
    }

    private async setLogoAsync(value: FileModel[]): Promise<void> {
        this.serviceProvider.logo = (value.length > 0)
            ? value[0].src
            : "";

        await this.reRenderAsync();
    }

    private async setNameAsync(value: LocalizationString): Promise<void> {
        this.serviceProvider.name = value;
        await this.reRenderAsync();
    }

    private async setLanguageAsync(language: string): Promise<void> {
        this.serviceProvider.language = language;
        await this.reRenderAsync();
    }

    private async setApplicationTypesAsync(sender: Dropdown<SelectListItem>): Promise<void> {
        const applicationTypes: ApplicationType[] = sender.selectedItems.select(item => parseInt(item.value));
        
        const shuttleIsEnabled: boolean = applicationTypes.includes(ApplicationType.Shuttle);
        
        if (!shuttleIsEnabled) {
            this.serviceProvider.shuttleMaxTickets = null;
        }

        this.serviceProvider.applicationTypes = applicationTypes;
        
        await this.reRenderAsync();
    }

    private async setDescriptionAsync(value: LocalizationString): Promise<void> {
        this.serviceProvider.description = value;
        await this.reRenderAsync();
    }

    private get pictures(): FileModel[] {
        return ((this.serviceProvider) && (this.serviceProvider.logo))
            ? [new FileModel(this.serviceProvider.logo)]
            : [];
    }
    
    public get serviceProvider(): ServiceProvider {
        return this.props.serviceProvider;
    }
    
    public get countries(): Country[] {
        return this.props.countries;
    }
    
    public get areas(): Area[] {
        const countryIds: string[] = this.serviceProvider.countryIds ?? [];
        return (countryIds.length > 0)
            ? this.props.areas.where(item => countryIds.includes(item.countryId))
            : this.props.areas;
    }
    
    public get waypoints(): Waypoint[] {
        return this.state.waypoints;
    }

    public async isFormValidAsync(): Promise<boolean> {
        if (this._formRef.current) {
            return this._formRef.current.validateAsync();
        }

        return false;
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        await this.reloadWaypointsAsync();
    }

    public render(): React.ReactNode {
        
        return (
            <Panel className={this.css("flex-2", styles.companyPanel)}>
                <div className="col-12">
                 
                    <Form ref={this._formRef} className={styles.form}>
                        
                        <ThreeColumns>
                            
                            <LocalizationTextInput id="name" required noAutoComplete
                                                   label={Localizer.serviceProvidersManagementPageName}
                                                   value={this.serviceProvider.name}
                                                   onChange={(_, value) => this.setNameAsync(value)}
                            />
                            
                        </ThreeColumns>

                        <ThreeColumns>

                            <TextInput id="companyName" required autoFocus noAutoComplete
                                       label={Localizer.serviceProvidersManagementPageCompanyName}
                                       value={this.serviceProvider.companyName}
                                       onChange={(_, value) => this.setCompanyNameAsync(value)}
                            />

                            <TextInput id="companyShortName" required noAutoComplete
                                       label={Localizer.serviceProvidersManagementPageWhiteLabelCompanyShortName}
                                       value={this.serviceProvider.companyShortName}
                                       onChange={(_, value) => this.setCompanyShortNameAsync(value)}
                            />

                            <TextInput id="companyBusinessId" noAutoComplete required
                                       label={Localizer.serviceProvidersManagementPageBusinessId}
                                       value={this.serviceProvider.companyBusinessId}
                                       onChange={(_, value) => this.setCompanyBusinessIdAsync(value)}
                            />

                        </ThreeColumns>

                        <ThreeColumns>

                            <Dropdown id="countries" multiple autoCollapse
                                      selectedTextFormat={1}
                                      label={Localizer.serviceProvidersManagementPageCountries}
                                      nothingSelectedText={Localizer.serviceProvidersManagementPageAllCountries}
                                      items={this.countries}
                                      selectedItems={this.serviceProvider.countryIds ?? []}
                                      transform={(country: Country) => TransformProvider.toCountryListItem(country, false)}
                                      onChange={(sender) => this.setCountriesAsync(sender)}
                            />

                            <Dropdown id="areas" multiple autoCollapse
                                      selectedTextFormat={1}
                                      label={Localizer.serviceProvidersManagementPageAreas}
                                      nothingSelectedText={Localizer.genericAllAreas}
                                      items={this.areas}
                                      selectedItems={this.serviceProvider.areaIds ?? []}
                                      onChange={(sender) => this.setAreasAsync(sender)}
                            />

                            <Dropdown id="waypoints" multiple autoCollapse noWrap
                                      ref={this._waypointsRef}
                                      align={DropdownAlign.Right}
                                      selectedTextFormat={1}
                                      label={Localizer.serviceProvidersManagementPageWaypoints}
                                      nothingSelectedText={Localizer.serviceProvidersManagementPageAllWaypoints}
                                      disabled={(this.waypoints.length == 0)}
                                      items={this.waypoints}
                                      selectedItems={this.serviceProvider.waypointIds ?? []}
                                      onChange={(sender) => this.setWaypointsAsync(sender)}
                            />

                        </ThreeColumns>

                        <ThreeColumns>

                            <Dropdown required
                                      id="language"
                                      label={Localizer.formInputLanguage}
                                      items={Localizer.supportedLanguages}
                                      selectedItem={this.serviceProvider.language || Localizer.defaultLanguage}
                                      onChange={(_, value) => this.setLanguageAsync(value!.code)}
                            />

                            <Dropdown required multiple autoCollapse
                                      id="applicationTypes"
                                      requiredType={DropdownRequiredType.Restricted}
                                      selectedTextFormat={2}
                                      orderBy={DropdownOrderBy.None}
                                      label={Localizer.serviceProvidersManagementPageServiceTypes}
                                      items={EnumProvider.getApplicationTypeItems()}
                                      selectedItems={this.serviceProvider.applicationTypes}
                                      onChange={(sender) => this.setApplicationTypesAsync(sender)}
                            />

                        </ThreeColumns>
                        
                        <TwoColumns>

                            <UrlInput id="url" noAutoComplete
                                      label={Localizer.serviceProvidersManagementPageUrl}
                                      value={this.serviceProvider.url}
                                      onChange={(_, value) => this.setUrlAsync(value)}
                            />

                            <Checkbox label={Localizer.serviceProvidersManagementPageSupportsRating}
                                      value={this.serviceProvider.supportsRating}
                                      onChange={(sender, item) => this.setSupportsRatingAsync(item)}
                            />

                        </TwoColumns>

                        <TwoColumns>

                            <LocalizationTextInput id="description" required noAutoComplete
                                                   label={Localizer.serviceProvidersManagementPageDescription}
                                                   type={LocalizationTextInputType.TextAreaInput}
                                                   value={this.serviceProvider.description}
                                                   onChange={(sender, value) => this.setDescriptionAsync(value)}
                            />

                        </TwoColumns>

                        <ThreeColumns>

                            <ImageInput minimizeOnEmpty
                                        maxImageRequestSizeInBytes={AppConstants.maxImageLogoSizeInBytes}
                                        pictures={this.pictures}
                                        onChange={(sender, value) => this.setLogoAsync(value)}
                            />

                        </ThreeColumns>

                    </Form>

                </div>

            </Panel>
        );
    }
};