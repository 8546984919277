// autogenerated

export default class AddMyCreditCardRequest {

    public last4: number = 0;

    public expirationMonth: number = 0;

    public expirationYear: number = 0;

    public brand: string | null = null;

    public holderName: string | null = null;

    public default: boolean = false;

    public isExplicitRenew: boolean = false;

    public readonly isAddMyCreditCardRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof AddMyCreditCardRequest) || ((from as any).isAddMyCreditCardRequest === true));
    }

    public static as(from?: object | null): AddMyCreditCardRequest | null {
        return (AddMyCreditCardRequest.is(from)) ? from as AddMyCreditCardRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}