import React from "react";
import {ActionType, BaseComponent, ch} from "@reapptor-apps/reapptor-react-common";
import {ButtonType, CellAction, CellModel, ColumnActionDefinition, ColumnDefinition, ColumnType, Grid, GridHoveringType, GridModel, GridOddType, IconSize, Inline, JustifyContent, ToolbarButton} from "@reapptor-apps/reapptor-react-components";
import IntermediateWaypoint from "@/models/server/bout/IntermediateWaypoint";
import Waypoint from "@/models/server/bout/Waypoint";
import SaveIntermediateWaypointRequest from "@/models/server/requests/SaveIntermediateWaypointRequest";
import IntermediateMapPointsModal from "@/pages/ServicePointsManagement/IntermediatePointsPanel/IntermediateMapPointsModal/IntermediateMapPointsModal";
import ListIntermediateWaypointsRequest from "@/models/server/requests/ListIntermediateWaypointsRequest";
import {IPagedList} from "@reapptor-apps/reapptor-toolkit";
import Localizer from "@/localization/Localizer";

import styles from "./IntermediatePointsPanel.module.scss";

interface IIntermediatePointsPanelProps {
    waypoint: Waypoint;
}

interface IIntermediatePointsPanelState {
}

export default class IntermediatePointsPanel extends BaseComponent<IIntermediatePointsPanelProps, IIntermediatePointsPanelState> {

    state: IIntermediatePointsPanelState = {
    };

    private readonly _intermediateWaypointsGridRef: React.RefObject<Grid<IntermediateWaypoint>> = React.createRef();
    private readonly _mapPointsModalRef: React.RefObject<IntermediateMapPointsModal> = React.createRef();
    private readonly _pageSize: number = 10;
    
    private readonly _intermediateWaypointsColumns: ColumnDefinition[] = [
        {
            header: "#",
            minWidth: 50,
            noWrap: true,
            className: "grey",
            accessor: "#"
        } as ColumnDefinition,
        {
            header: Localizer.intermediatePointsPanelGridLatitudeLanguageItemName,
            name: "lat",
            accessor: nameof<IntermediateWaypoint>(o => o.lat),
            minWidth: 175,
            maxWidth: 175,
            editable: true,
            noWrap: true,
            format: "lat",
            type: ColumnType.Number,
            settings: {
                min: -90,
                max: 90
            },
        } as ColumnDefinition,
        {
            header: Localizer.intermediatePointsPanelGridLongitudeLanguageItemName,
            name: "lon",
            accessor: nameof<IntermediateWaypoint>(o => o.lon),
            minWidth: 175,
            maxWidth: 175,
            editable: true,
            noWrap: true,
            format: "lon",
            type: ColumnType.Number,
            settings: {
                min: -180,
                max: 180
            },
        } as ColumnDefinition,
        {
            header: Localizer.intermediatePointsPanelGridFairwayLanguageItemName,
            name: "fairway",
            accessor: nameof<IntermediateWaypoint>(o => o.fairway),
            minWidth: 175,
            maxWidth: 175,
            editable: true,
            noWrap: true,
        } as ColumnDefinition,
        {
            header: Localizer.intermediatePointsPanelGridActionsLanguageItemName,
            minWidth: 140,
            removable: false,
            init: (cell) => IntermediatePointsPanel.initIntermediateWaypointOperations(cell),
            actions: [
                {
                    name: "delete",
                    title: Localizer.genericDelete,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    confirm: Localizer.genericDelete,
                    callback: async (cell, action) => await this.processAreaOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "edit",
                    title: Localizer.genericEdit,
                    icon: "far edit",
                    right: true,
                    type: ActionType.Edit,
                    callback: async (cell, action) => await this.processAreaOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "save",
                    title: Localizer.genericAdd,
                    right: true,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processAreaOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "cancel",
                    title: "cancel",
                    icon: "far ban",
                    right: true,
                    type: ActionType.Delete,
                    callback: async (cell, action) => await this.processAreaOperationAsync(cell, action)
                } as ColumnActionDefinition
            ]
        } as ColumnDefinition,
    ];

    private get mapPointsModal(): IntermediateMapPointsModal {
        return this._mapPointsModalRef.current!;
    }

    private get waypoint(): Waypoint {
        return this.props.waypoint;
    }
    
    private async saveIntermediateWaypoint(point: IntermediateWaypoint): Promise<IntermediateWaypoint> {
        const isNew: boolean = !point.id;

        const request = new SaveIntermediateWaypointRequest();
        request.id = point.id;
        request.latitude = point.lat;
        request.longitude = point.lon;
        request.fairway = point.fairway;
        request.waypointId = this.waypoint.id;
        request.order = isNew ? this.waypoint.intermediateWaypoints?.length ?? 0 : point.order;

        const result: Waypoint = await this.postAsync("/api/Waypoints/saveIntermediateWaypoint", request);

        await ch.alertMessageAsync(Localizer.intermediatePointsPanelAlertErrorSavePoint, true);
        
        this.props.waypoint.intermediateWaypoints = result.intermediateWaypoints;
        
        return result.intermediateWaypoints?.find(item => item.id == point.id)!;
    }

    private async onModalSaveAsync(point: IntermediateWaypoint): Promise<void> {
        await this.saveIntermediateWaypoint(point);
        await this.reloadAsync();
    }

    private async fetchIntermediatePointsAsync(pageNumber: number, pageSize: number): Promise<IPagedList<IntermediateWaypoint>> {
        if (this.waypoint) {
            const request = new ListIntermediateWaypointsRequest();
            request.waypointId = this.waypoint.id;
            request.pageNumber = pageNumber;
            request.pageSize = pageSize;

            return await this.intermediateWaypointsGrid.postAsync("/api/Waypoints/getIntermediateWaypoints", request)
        }
        
        return [].toPagedList(pageNumber, pageSize);
    }

    private static initIntermediateWaypointOperations(cell: CellModel<IntermediateWaypoint>): void {
        const modified: boolean = cell.row.modified;

        const cancelAction: CellAction<IntermediateWaypoint> = cell.actions[2];
        const saveAction: CellAction<IntermediateWaypoint> = cell.actions[3];

        cancelAction.visible = (modified);
        saveAction.visible = (modified);
    }

    private async processAreaOperationAsync(cell: CellModel<IntermediateWaypoint>, action: CellAction<IntermediateWaypoint>): Promise<void> {
        const model: IntermediateWaypoint = cell.model;

        if (action.action.name === "delete") {
            await cell.grid.postAsync("/api/Waypoints/deleteIntermediateWaypoint", model.id);
            await this.intermediateWaypointsGrid.deleteAsync(cell.row.index);
            await ch.alertMessageAsync(Localizer.servicePointsPanelAlertErrorDeleteServicePoint, true);
            await this._intermediateWaypointsGridRef.current!.reloadAsync();
        }

        if (action.action.name === "edit") {
            await this.mapPointsModal.openAsync(model);
        } else if (action.action.name === "cancel") {
            await cell.row.cancelAsync();
        }

        if (action.action.name === "save") {
            cell.row.model = await this.saveIntermediateWaypoint(model);
            await cell.row.bindAsync();
        }
    }
    
    public get intermediateWaypointsGrid(): GridModel<IntermediateWaypoint> {
        return this._intermediateWaypointsGridRef.current!.model;
    }

    private async reloadAsync(): Promise<void> {
        await this.intermediateWaypointsGrid.reloadAsync();
        await this._mapPointsModalRef.current!.reRenderAsync();
    }

    public render(): React.ReactNode {
        return (
            <React.Fragment>
                {(this.waypoint) && (
                    <div>
                        <div className={styles.areaPricesPanel}>

                            <Inline className={styles.toolbar} justify={JustifyContent.Start}>

                                <ToolbarButton icon={{name: "plus", size: IconSize.Large}}
                                               label={Localizer.genericAdd}
                                               title={"Add waypoint"}
                                               type={ButtonType.Primary}
                                               onClick={() => this.mapPointsModal.openAsync()}
                                />

                            </Inline>

                            <Grid autoToggle responsive
                                  ref={this._intermediateWaypointsGridRef}
                                  pagination={this._pageSize}
                                  className={this.css(styles.grid)}
                                  hovering={GridHoveringType.Row}
                                  odd={GridOddType.None}
                                  minWidth="auto"
                                  noDataText={Localizer.genericNoData}
                                  columns={this._intermediateWaypointsColumns}
                                  fetchData={(_, pageNumber: number, pageSize: number) => this.fetchIntermediatePointsAsync(pageNumber, pageSize)}
                            />

                        </div>
                        
                        {
                            (this.waypoint) &&
                            (
                                <IntermediateMapPointsModal ref={this._mapPointsModalRef}
                                                            waypoint={this.waypoint} 
                                                            onModalSave={(point: IntermediateWaypoint) => this.onModalSaveAsync(point)}
                                />
                            )
                        }

                    </div>
                )}

            </React.Fragment>
        );
    }
}