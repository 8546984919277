import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import {
    Button,
    ButtonContainer,
    ButtonType,
    Checkbox,
    Form,
    IconSize,
    Inline,
    JustifyContent,
    PageContainer,
    PageHeader,
    PageRow, Tab, TabContainer, TabModel, TabRenderType,
    ToolbarContainer,
} from "@reapptor-apps/reapptor-react-components";
import ServiceProvider from "@/models/server/ServiceProvider";
import PageDefinitions from "@/providers/PageDefinitions";
import SaveServiceProviderRequest from "@/models/server/requests/SaveServiceProviderRequest";
import SaveServiceProviderResponse from "@/models/server/responses/SaveServiceProviderResponse";
import {PageRoute, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import Country from "@/models/server/bout/Country";
import Area from "@/models/server/bout/Area";
import StripeKycStatus from "@/models/server/stripe/kycstatus/StripeKycStatus";
import FirebaseSettingsPanel from "@/pages/ServiceProviderManagement/FirebaseSettingsPanel/FirebaseSettingsPanel";
import AndroidAssetsPanel from "@/pages/ServiceProviderManagement/AndroidAssetsPanel/AndroidAssetsPanel";
import SocialMediaLinksPanel from "@/pages/ServiceProviderManagement/SocialMediaLinks/SocialMediaLinks";
import CompanyPanel from "@/pages/ServiceProviderManagement/CompanyPanel/CompanyPanel";
import ApplicationPanel from "@/pages/ServiceProviderManagement/ApplicationPanel/ApplicationPanel";
import WaterOperatorPanel from "@/pages/ServiceProviderManagement/WaterOperatorPanel/WaterOperatorPanel";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import styles from "./ServiceProviderManagement.module.scss";
import MetadataPanel from "@/pages/ServiceProviderManagement/MetadataPanel/MetadataPanel";

interface IServiceProviderManagementProps {
}

interface IServiceProviderManagementState {
    serviceProvider: ServiceProvider | null;
    hashCode: number;
    countries: Country[];
    areas: Area[];
    serviceProviders: ServiceProvider[];
    kycStatus: StripeKycStatus | null;
}

enum ServiceProviderTabId {
    CompanySettings = "CompanySettings",
    Application = "Application",
    Metadata = "Metadata",
    FirebaseSettings = "FirebaseSettings",
    AndroidAssetsSettings = "AndroidAssetsSettings",
    SocialMedia = "SocialMedia",
    WaterOperator = "WaterOperator",
}

export default class ServiceProviderManagement extends AuthorizedPage<IServiceProviderManagementProps, IServiceProviderManagementState> {

    state: IServiceProviderManagementState = {
        serviceProvider: null,
        hashCode: 0,
        countries: [],
        areas: [],
        serviceProviders: [],
        kycStatus: null,
    };

    private readonly _tabContainer: React.RefObject<TabContainer> = React.createRef();
    private readonly _companyPanel: React.RefObject<CompanyPanel> = React.createRef();
    private readonly _applicationPanel: React.RefObject<ApplicationPanel> = React.createRef();
    private readonly _metadataPanel: React.RefObject<MetadataPanel> = React.createRef();
    private readonly _firebaseSettingsPanel: React.RefObject<FirebaseSettingsPanel> = React.createRef();
    private readonly _androidAssetsPanel: React.RefObject<AndroidAssetsPanel> = React.createRef();
    private readonly _socialMediaLinksPanel: React.RefObject<SocialMediaLinksPanel> = React.createRef();
    private readonly _waterOperatorPanel: React.RefObject<WaterOperatorPanel> = React.createRef();

    private async reloadAsync(): Promise<void> {
        const serviceProviderId: string | null = ((this.serviceProvider) && (this.serviceProvider.id))
            ? this.serviceProvider.id
            : this.routeId;

        let serviceProvider: ServiceProvider;
        if (serviceProviderId) {
            serviceProvider = await this.postAsync("/api/serviceProvider/getServiceProvider", serviceProviderId);
        } else {
            serviceProvider = new ServiceProvider();
        }

        const hashCode: number = Utility.getHashCode(serviceProvider);

        await this.setState({serviceProvider, hashCode});
    }

    private async setWhiteLabelingAsync(value: boolean): Promise<void> {
        if (this.serviceProvider) {
            this.serviceProvider.whiteLabeling = value;
            await this.reRenderAsync();
        }
    }

    private async setWaterOperatorAsync(value: boolean): Promise<void> {
        if (this.serviceProvider) {
            this.serviceProvider.waterOperator = value;
            await this.reRenderAsync();
        }
    }
    
    private get kycStatus(): StripeKycStatus | null {
        return this.state.kycStatus;
    }

    private async isFormValidAsync(): Promise<boolean> {
        const generalPanelValid: boolean = await this._companyPanel.current?.isFormValidAsync() || false;

        if (!generalPanelValid) {
            await this.alertErrorAsync(Localizer.serviceProvidersManagementPageCompanyFieldsMissingError, true);
            return false;
        }

        if (this.isWhiteLabel) {
            const applicationPanelValid: boolean = await this._applicationPanel.current?.isFormValidAsync() || false;
            if (!applicationPanelValid) {
                await this.alertErrorAsync(Localizer.serviceProvidersManagementPageApplicationFieldsMissingError, true);
                return false;
            }
            
            const metadataPanelValid: boolean = await this._metadataPanel.current?.isFormValidAsync() || false;
            if (!metadataPanelValid) {
                await this.alertErrorAsync(Localizer.serviceProvidersManagementPageMetadataFieldsMissingError, true);
                return false;
            }

            const firebaseSettingsPanelValid: boolean = await this._firebaseSettingsPanel.current?.isFormValidAsync() || false;
            if (!firebaseSettingsPanelValid) {
                await this.alertErrorAsync(Localizer.serviceProvidersManagementPageFirebaseFieldsMissingError, true);
                return false;
            }

            const androidAssetsValid: boolean = await this._androidAssetsPanel.current?.isFormValidAsync() || false;
            if (!androidAssetsValid) {
                await this.alertErrorAsync(Localizer.serviceProvidersManagementPageAndroidAssetsFieldsMissingError, true);
                return false;
            }

            const isSocialMediaLinksValid: boolean = await this._socialMediaLinksPanel.current?.isFormValidAsync() || false;
            if (!isSocialMediaLinksValid) {
                await this.alertErrorAsync(Localizer.serviceProvidersManagementPageSocialMediaError, true);
                return false;
            }
        }
        
        if (this.isWaterOperator) {
            const isWaterOperatorValid: boolean = await this._waterOperatorPanel.current?.isFormValidAsync() || false;
            if (!isWaterOperatorValid) {
                await this.alertErrorAsync(Localizer.serviceProvidersManagementPageWaterOperatorIssuesSaving, true);
                return false;
            }

        }
 
        return true;
    }

    private async submitServiceProviderAsync(): Promise<void> {
        if (this.serviceProvider) {

            const formValid: boolean = await this.isFormValidAsync();

            if (!formValid) {
                return;
            }

            if (!this.serviceProvider.logo) {
                await this.alertErrorAsync(Localizer.serviceProvidersManagementPageLogoRequired, true);
                return;
            }

            const request = new SaveServiceProviderRequest();

            this.copyTo(this.serviceProvider, request);

            request.companyFormattedAddress = this.serviceProvider.companyAddress?.formattedAddress || null;
            request.formattedAddress = this.serviceProvider.location?.formattedAddress || null;
            
            if (this._waterOperatorPanel.current?.isNew) {
                request.iban = this._waterOperatorPanel.current.iban;
                request.accountHolderName = this._waterOperatorPanel.current.holderName;
            }

            const isNew: boolean = (!this.serviceProvider.id);

            const response: SaveServiceProviderResponse = await this.postAsync("/api/serviceProvider/saveServiceProvider", request);

            if (response.alreadyExists) {
                await this.alertErrorAsync(Localizer.serviceProvidersManagementPageAlreadyExistsError.format(this.serviceProvider.companyName, request.companyBusinessId), false);

                return;
            }

            const serviceProvider: ServiceProvider = response.serviceProvider!;

            if (isNew) {
                const route: PageRoute = PageDefinitions.serviceProviderManagement(serviceProvider.id);
                await PageRouteProvider.redirectAsync(route);
            } else {
                const hashCode: number = Utility.getHashCode(serviceProvider);
                await this.setState({serviceProvider, hashCode});
            }

            await this.alertMessageAsync(Utility.format(Localizer.serviceProvidersManagementPageSaved, this.serviceProvider.companyName), true, false);
        }
    }

    private get isWhiteLabel(): boolean {
        return this.serviceProvider?.whiteLabeling || false;
    }

    private get isWaterOperator(): boolean {
        return this.serviceProvider?.waterOperator || false;
    }

    private get passPaymentToParent(): boolean {
        return this.serviceProvider?.passPaymentToParent || false;
    }

    private get serviceProvider(): ServiceProvider | null {
        return this.state.serviceProvider;
    }
    
    private isModified(): boolean {
        return ((this.serviceProvider != null) && (this.state.hashCode != Utility.getHashCode(this.serviceProvider)));
    }

    public getTitle(): string {
        return Localizer.serviceProvidersManagementPageTitle;
    }

    private isTabActive(tabId: ServiceProviderTabId): boolean {
        const activeTab: TabModel | null = this._tabContainer.current?.model.getActiveTab() || null;
        return ((activeTab != null) && (tabId == activeTab.id));
    }

    public getSubtitle(): string {
        return this.serviceProvider
            ? (!!this.serviceProvider.id)
                ? this.serviceProvider.companyName!
                : "{0} *".format(this.serviceProvider.companyName)
            : "...";
    }

    private async updateKycStatusAsync(): Promise<void> {
        if ((this.serviceProvider != null) && (this.serviceProvider.id != null))
        {
            const serviceProviderId: string = this.serviceProvider.id;
            const stripeKycStatus: StripeKycStatus = await this.postAsync("/api/admin/updateWaterOperatorKycStatus", serviceProviderId);
            await this.setState({kycStatus: stripeKycStatus});
        }
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const [countries, areas, serviceProviders] = await Promise.all([
            AppController.listCountriesAsync(),
            AppController.listAreasAsync(),
            AppController.listServiceProvidersAsync()
        ]);
        
        this.state.countries = countries;
        this.state.areas = areas;
        this.state.serviceProviders = serviceProviders;

        await this.reloadAsync();
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={this.css(styles.serviceProviderManagement)}>

                <PageHeader withTabs
                            title={this.getTitle()}
                            subtitle={this.getSubtitle()}
                />

                <PageRow>
                    <div className="col">

                        <ToolbarContainer className={styles.toolbar}>

                            <Inline justify={JustifyContent.End} className={styles.inline}>

                                <Checkbox inline
                                          id="whiteLabel"
                                          label={Localizer.serviceProvidersManagementPageWhiteLabeling}
                                          value={this.isWhiteLabel}
                                          onChange={(_, value) => this.setWhiteLabelingAsync(value)}
                                />

                                <Checkbox inline
                                          id="waterOperator"
                                          label={Localizer.serviceProvidersManagementPageWaterOperator}
                                          value={this.isWaterOperator}
                                          onChange={(_, value) => this.setWaterOperatorAsync(value)}
                                />

                                <Button title={Localizer.genericReload} className="ml-1"
                                        icon={{name: "far history", size: IconSize.Large}}
                                        type={ButtonType.Info}
                                        confirm={this.isModified() ? Localizer.serviceProvidersManagementPageSureWantToReload : undefined}
                                        onClick={() => this.reloadAsync()}
                                />

                                <Button right
                                        title={Localizer.genericBack}
                                        icon={{name: "fas arrow-alt-circle-left"}}
                                        type={ButtonType.Primary}
                                        route={PageDefinitions.serviceProvidersRoute}
                                />

                            </Inline>

                        </ToolbarContainer>

                        {
                            (this.serviceProvider) &&
                            (
                                <Form onSubmit={() => this.submitServiceProviderAsync()}>

                                    <TabContainer id="serviceProviderContainer"
                                                  ref={this._tabContainer}
                                                  key={`serviceProviderContainer${this.isWhiteLabel}${this.isWaterOperator}`}
                                                  renderType={TabRenderType.Always}
                                                  onSelect={() => this.reRenderAsync()}
                                    >

                                        <Tab id={ServiceProviderTabId.CompanySettings}
                                             title={Localizer.serviceProvidersManagementPageCompanyTabTitle}
                                        >

                                            <CompanyPanel ref={this._companyPanel}
                                                          serviceProvider={this.serviceProvider}
                                                          countries={this.state.countries}
                                                          areas={this.state.areas}
                                            />

                                        </Tab>

                                        {
                                            (this.isWhiteLabel) &&
                                            (
                                                <Tab id={ServiceProviderTabId.Application}
                                                     title={Localizer.serviceProvidersManagementPageApplicationTabTitle}
                                                >

                                                    <ApplicationPanel ref={this._applicationPanel}
                                                                      serviceProvider={this.serviceProvider}
                                                                      countries={this.state.countries}
                                                                      areas={this.state.areas}
                                                    />

                                                </Tab>
                                            )
                                        }

                                        {
                                            (this.isWhiteLabel) &&
                                            (
                                                <Tab id={ServiceProviderTabId.Metadata}
                                                     title={Localizer.serviceProvidersManagementPageMetadataTabTitle}
                                                >

                                                    <MetadataPanel ref={this._metadataPanel}
                                                                   serviceProvider={this.serviceProvider}
                                                    />

                                                </Tab>
                                            )
                                        }

                                        {
                                            (this.isWhiteLabel) &&
                                            (
                                                <Tab id={ServiceProviderTabId.FirebaseSettings}
                                                     title={Localizer.serviceProvidersManagementPageFirebaseTabTitle}
                                                >

                                                    <FirebaseSettingsPanel ref={this._firebaseSettingsPanel}
                                                                           serviceProvider={this.serviceProvider}
                                                    />

                                                </Tab>
                                            )
                                        }

                                        {
                                            (this.isWhiteLabel) &&
                                            (
                                                <Tab id={ServiceProviderTabId.AndroidAssetsSettings}
                                                     title={Localizer.serviceProvidersManagementPageAndroidAssetsTabTitle}
                                                >

                                                    <AndroidAssetsPanel ref={this._androidAssetsPanel}
                                                                        serviceProvider={this.serviceProvider}
                                                    />

                                                </Tab>
                                            )
                                        }

                                        {
                                            (this.isWhiteLabel) &&
                                            (
                                                <Tab id={ServiceProviderTabId.SocialMedia}
                                                     title={Localizer.serviceProvidersManagementPageSocialMediaTabTitle}
                                                >

                                                    <SocialMediaLinksPanel ref={this._socialMediaLinksPanel}
                                                                           serviceProvider={this.serviceProvider}
                                                    />

                                                </Tab>
                                            )
                                        }

                                        {
                                            (this.isWaterOperator) &&
                                            (
                                                <Tab id={ServiceProviderTabId.WaterOperator}
                                                     title={Localizer.serviceProvidersManagementPageWaterOperator}
                                                >

                                                    <WaterOperatorPanel ref={this._waterOperatorPanel}
                                                                        serviceProvider={this.serviceProvider}
                                                                        serviceProviders={this.state.serviceProviders}
                                                                        kycStatus={this.kycStatus}
                                                    />

                                                </Tab>
                                            )
                                        }

                                    </TabContainer>

                                    <ButtonContainer>
                                        
                                        {
                                            ((this.isWaterOperator) && (!this.passPaymentToParent) && (this.isTabActive(ServiceProviderTabId.WaterOperator))) &&
                                            (
                                                <Button type={ButtonType.Orange}
                                                        icon={{name: "repeat", size: IconSize.Large}}
                                                        disabled={!this.serviceProvider.stripeAccountId}
                                                        label={Localizer.serviceProvidersManagementPageUpdateKycStatus}
                                                        onClick={() => this.updateKycStatusAsync()}
                                                />
                                            )
                                        }
                                        
                                        <Button submit
                                                icon={{name: "save", size: IconSize.Large}}
                                                label={Localizer.genericSave}
                                                type={ButtonType.Primary}
                                        />

                                    </ButtonContainer>
                                    
                                </Form>
                            )
                        }

                    </div>
                </PageRow>

            </PageContainer>
        );
    }
}