// autogenerated

export default class FilterByAreaRequest {

    public areas: string[] | null = null;

    public readonly isFilterByAreaRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof FilterByAreaRequest) || ((from as any).isFilterByAreaRequest === true));
    }

    public static as(from?: object | null): FilterByAreaRequest | null {
        return (FilterByAreaRequest.is(from)) ? from as FilterByAreaRequest : null;
    }

    // #region Placeholder

    constructor(areas: string[]) {
        this.areas = areas;
    }

    // #endregion
}