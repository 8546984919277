import React from "react";
import {BaseComponent, IBaseComponentProps} from "@reapptor-apps/reapptor-react-common";
import {Carousel, CarouselNavigation, CarouselPagination, Icon, Modal} from "@reapptor-apps/reapptor-react-components"
import Localizer from "@/localization/Localizer";

import styles from "./HdsModal.module.scss";

import slide1 from "./slides/slide_1.jpg";
import slide2 from "./slides/slide_2.jpg";
import slide3 from "./slides/slide_3.jpg";
import slide4 from "./slides/slide_4.jpg";
import slide5 from "./slides/slide_5.jpg";
import slide6 from "./slides/slide_6.jpg";
import slide7 from "./slides/slide_7.jpg";
import slide8 from "./slides/slide_8.jpg";

const slides = [
    //l’escale d’Issy-les-Moulineaux
    {
        tabIndex: 0,
        image: slide1,
        title: Localizer.hdsModalSlide1TitleLanguageItemName,
        description: Localizer.hdsModalSlide1DescriptionLanguageItemName
    },
    {
        tabIndex: 0,
        image: slide2,
        title: Localizer.hdsModalSlide2TitleLanguageItemName,
        description: Localizer.hdsModalSlide2DescriptionLanguageItemName,
    },
    //L’escale de Sèvres
    {
        tabIndex: 1,
        image: slide3,
        title: Localizer.hdsModalSlide3TitleLanguageItemName,
        description: Localizer.hdsModalSlide3DescriptionLanguageItemName,
    },
    {
        tabIndex: 1,
        image: slide4,
        title: Localizer.hdsModalSlide4TitleLanguageItemName,
        description: Localizer.hdsModalSlide4DescriptionLanguageItemName,
    },
    {
        tabIndex: 1,
        image: slide5,
        title: Localizer.hdsModalSlide5TitleLanguageItemName,
        description: Localizer.hdsModalSlide5DescriptionLanguageItemName,
    },
    {
        tabIndex: 1,
        image: slide6,
        title: Localizer.hdsModalSlide6TitleLanguageItemName,
        description: Localizer.hdsModalSlide6DescriptionLanguageItemName,
    },
    //Boulogne-Billancourt
    {
        tabIndex: 2,
        image: slide7,
        title: Localizer.hdsModalSlide7TitleLanguageItemName,
        description: Localizer.hdsModalSlide7DescriptionLanguageItemName,
    },
    {
        tabIndex: 2,
        image: slide8,
        title: Localizer.hdsModalSlide8TitleLanguageItemName,
        description: Localizer.hdsModalSlide8DescriptionLanguageItemName,
    },
];

interface IHdsModalProps extends IBaseComponentProps {
}

interface IHdsModalState {
    //slideIndex: number;
}

export default class HdsModal extends BaseComponent<IHdsModalProps, IHdsModalState> {

    state: IHdsModalState = {
        //slideIndex: 0,
    }

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _carouselRef: React.RefObject<Carousel> = React.createRef();
    
    private async onSlideChangeAsync(index: number): Promise<void> {
        await this.reRenderAsync();
    }
    
    private async selectTabIndexAsync(tabIndex: number): Promise<void> {
        const slide = slides.first(item => item.tabIndex == tabIndex);
        
        const slideIndex: number = slides.indexOf(slide);

        await this._carouselRef.current?.slideToAsync(slideIndex);
    }
    
    private async onCloseAsync(): Promise<void> {
        await this.modal?.closeAsync();
    }

    private get modal(): Modal {
        return this._modalRef.current!;
    }
    
    private get slideIndex(): number {
        return this._carouselRef.current?.currentSlideIndex ?? 0;
    }
    
    private get tabIndex(): number {
        return slides[this.slideIndex].tabIndex;
    }
    
    private get title(): string {
        return slides[this.slideIndex].title;
    }
    
    private get description(): string {
        return slides[this.slideIndex].description;
    }

    public async openAsync(animation: boolean = true): Promise<void> {
        await this.modal.openAsync(animation);
        await this.reRenderAsync();
    }

    public get isOpen(): boolean {
        return (this._modalRef.current != null) && (this._modalRef.current.isOpen);
    }
    
    private renderImage(image: string, index: number = 0): React.ReactElement {
        return (
            <div key={index}
                 className={styles.image}
            >
                <img alt={""}
                     //loading="lazy"
                     src={image}
                />
            </div>
        );
    }

    public render(): React.ReactNode {
        const tabIndex: number = this.tabIndex;
        
        return (
            <Modal noHeader
                   id={this.id}
                   ref={this._modalRef}
                   className={this.css(styles.hdsModal, this.props.className)}
                   onClose={() => this.onCloseAsync()}
            >

                {
                    (this.isOpen) &&
                    (
                        <div className={styles.container}>

                            <div className={styles.toolbar}>

                                <Icon name={"fal fa-times-circle"} onClick={() => this.onCloseAsync()}/>

                            </div>

                            <div className={styles.content}>

                                <div className={styles.navigation}>
                                    <span className={this.css(((tabIndex == 0) && styles.active))}
                                          onClick={() => this.selectTabIndexAsync(0)}
                                    >
                                        Issy-les-Moulineaux
                                    </span>
                                    <span className={this.css(((tabIndex == 1) && styles.active))}
                                          onClick={() => this.selectTabIndexAsync(1)}
                                    >
                                        L’escale de Sèvres
                                    </span>
                                    <span className={this.css(((tabIndex == 2) && styles.active))}
                                          onClick={() => this.selectTabIndexAsync(2)}
                                    >
                                        Boulogne-Billancourt
                                    </span>
                                </div>

                                <div className={styles.images}>

                                    <Carousel ref={this._carouselRef}
                                              navigation={CarouselNavigation.Inside}
                                              pagination={CarouselPagination.BottomOutside}
                                              slidesPerView={1}
                                              className={this.css(styles.carousel, styles.thumbnail)}
                                              onSlideChange={(index) => this.onSlideChangeAsync(index)}
                                    >

                                        {
                                            slides.map((slide, index) => this.renderImage(slide.image, index))
                                        }

                                    </Carousel>

                                </div>

                                <span className={styles.title}>{Localizer.get(this.title)}</span>

                                <div className={styles.description}>

                                    <span>{Localizer.get(this.description)}</span>

                                </div>

                            </div>

                        </div>
                    )
                }

            </Modal>
        )
    }
}