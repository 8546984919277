import React from "react";
import {IPagedList, SortDirection} from "@reapptor-apps/reapptor-toolkit";
import {BasePageParameters, TextAlign} from "@reapptor-apps/reapptor-react-common";
import {
    ColumnDefinition,
    DateInput,
    Dropdown,
    DropdownRequiredType,
    Grid,
    JustifyContent,
    PageContainer,
    PageHeader,
    PageRow,
    ToolbarContainer,
    ToolbarRow
} from "@reapptor-apps/reapptor-react-components";
import ServiceProvider from "@/models/server/ServiceProvider";
import Country from "@/models/server/bout/Country";
import Area from "@/models/server/bout/Area";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import ShuttleLegInstance from "@/models/server/shuttle/ShuttleLegInstance";
import ListShuttleLegInstancesRequest from "@/models/server/requests/ListShuttleLegInstancesRequest";
import ListShuttleLegInstancesResponse from "@/models/server/responses/ListShuttleLegInstancesResponse";
import User from "@/models/server/User";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import styles from "./Tickets.module.scss";

interface ITicketsProps extends BasePageParameters {
}

interface ITicketsState {
    serviceProviders: ServiceProvider[];
    countries: Country[];
    areas: Area[];
    serviceProviderFilter: ServiceProvider | null;
    countryFilter: Country | null;
    areaFilter: Area | null;
    fromFilter: Date | null;
    toFilter: Date | null;
}

export default class Tickets extends AuthorizedPage<ITicketsProps, ITicketsState> {

    state: ITicketsState = {
        serviceProviders: [],
        countries: [],
        areas: [],
        serviceProviderFilter: null,
        countryFilter: null,
        areaFilter: null,
        fromFilter: null,
        toFilter: null,
    };

    private readonly _ticketsGridRef: React.RefObject<Grid<ShuttleLegInstance>> = React.createRef();

    private readonly _ticketsColumns: ColumnDefinition[] = [
        {
            header: Localizer.ticketsPageGridDateLanguageItemName,
            sorting: true,
            accessor: nameof.full<ShuttleLegInstance>(o => o.departureAt),
            format: "D",
            minWidth: 120,
            maxWidth: 120,
        },
        {
            header: Localizer.ticketsPageGridServiceProviderLanguageItemName,
            name: "serviceProvider",
            sorting: true,
            visible: !this.asServiceProviderManager,
            accessor: nameof.full<ShuttleLegInstance>(o => o.shuttleLeg!.shuttle!.serviceProvider),
            minWidth: 120,
            maxWidth: 120,
            settings: {
                infoAccessor: nameof.full<ShuttleLegInstance>(o => o.shuttleLeg!.shuttle!.serviceProvider!.companyName),
            }
        },
        {
            header: Localizer.ticketsPageGridAreaLanguageItemName,
            sorting: true,
            accessor: nameof.full<ShuttleLegInstance>(o => o.shuttleLeg!.shuttle!.area),
            minWidth: 120,
            maxWidth: 120,
            settings: {
                infoAccessor: nameof.full<ShuttleLegInstance>(o => o.shuttleLeg!.shuttle!.area!.country),
            }
        },
        {
            header: Localizer.ticketsPageGridNameLanguageItemName,
            accessor: nameof.full<ShuttleLegInstance>(o => o.shuttleLeg!.shuttle!.name),
            stretch: true,
            minWidth: 150,
        },
        {
            header: Localizer.ticketsPageGridSourceLanguageItemName,
            name: "source",
            sorting: true,
            accessor: nameof.full<ShuttleLegInstance>(o => o.shuttleLeg!.waypoint!.source!.name),
            minWidth: 200,
            maxWidth: 200,
        },
        {
            header: Localizer.ticketsPageGridDestinationLanguageItemName,
            name: "destination",
            sorting: true,
            accessor: nameof.full<ShuttleLegInstance>(o => o.shuttleLeg!.waypoint!.destination!.name),
            minWidth: 200,
            maxWidth: 200,
        },
        {
            group: Localizer.ticketsPageGridScheduleLanguageItemName,
            header: Localizer.ticketsPageGridDepartureAtLanguageItemName,
            name: "departureAt",
            sorting: true,
            accessor: nameof.full<ShuttleLegInstance>(o => o.departureAt),
            format: "t",
            minWidth: 100,
            maxWidth: 100,
        },
        {
            group: Localizer.ticketsPageGridScheduleLanguageItemName,
            header: Localizer.ticketsPageGridArrivalAtLanguageItemName,
            name: "arrivalAt",
            sorting: true,
            accessor: nameof.full<ShuttleLegInstance>(o => o.arrivalAt),
            format: "t",
            minWidth: 100,
            maxWidth: 100,
        },
        {
            header: Localizer.ticketsPageGridTicketsLanguageItemName,
            name: "sold",
            sorting: true,
            accessor: (model: ShuttleLegInstance) => `${model.sold} / ${model.capacity}`,
            className: styles.waypoint,
            textAlign: TextAlign.Center,
            settings: {
                infoAccessor: nameof.full<ShuttleLegInstance>(o => o.available)
            },
        },
        {
            header: Localizer.ticketsPageGridCheckInOutLanguageItemName,
            accessor: nameof.full<ShuttleLegInstance>(o => o.checkedIn),
            className: styles.waypoint,
            textAlign: TextAlign.Center,
            settings: {
                infoAccessor: nameof.full<ShuttleLegInstance>(o => o.checkedOut)
            },
        },
    ]

    private async reloadAsync(): Promise<void> {
        await this._ticketsGridRef.current?.reloadAsync();
    }

    private async setCountryFilterAsync(country: Country | null): Promise<void> {

        const areas: Area[] = await this.fetchAreasAsync(this.state.serviceProviderFilter?.id, country?.code);

        await this.setState({areas, countryFilter: country});

        await this.reloadAsync();
    }

    private async setServiceProviderFilterAsync(serviceProvider: ServiceProvider | null): Promise<void> {
        const [countries, areas] = await Promise.all([
            this.fetchCountriesAsync(serviceProvider?.id),
            this.fetchAreasAsync(serviceProvider?.id)
        ]);

        await this.setState({countries, areas, serviceProviderFilter: serviceProvider});
        
        await this.reloadAsync();
    }

    private async setAreaFilterAsync(area: Area | null): Promise<void> {
        this.state.areaFilter = area;

        await this.reloadAsync();
    }

    private async setFromFilterAsync(date: Date | null): Promise<void> {
        this.state.fromFilter = date;

        await this.reloadAsync();
    }

    private async setToFilterAsync(date: Date | null): Promise<void> {
        this.state.toFilter = date;

        await this.reloadAsync();
    }

    public async fetchCountriesAsync(serviceProviderId: string | null = null): Promise<Country[]> {
        return AppController.listCountriesAsync(serviceProviderId, true);
    }

    public async fetchServiceProvidersAsync(country?: string | null): Promise<ServiceProvider[]> {
        return AppController.listServiceProvidersAsync(country, false, true);
    }

    public async getServiceProviderAsync(serviceProviderId: string): Promise<ServiceProvider> {
        return AppController.getServiceProviderAsync(serviceProviderId);
    }

    public async fetchAreasAsync(serviceProviderId?: string | null, country?: string | null): Promise<Area[]> {
        return AppController.listAreasAsync(serviceProviderId, country, true);
    }

    public get initialized(): boolean {
        return (!this.asServiceProviderManager) || ((this.asServiceProviderManager) && (this.state.serviceProviderFilter != null));
    }

    public async fetchShuttleLegInstancesAsync(sender: Grid<ShuttleLegInstance>, pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<IPagedList<ShuttleLegInstance>> {
        if (!this.initialized) {
            return [].toPagedList(1, pageSize);
        }

        const request = new ListShuttleLegInstancesRequest();
        request.from = this.state.fromFilter;
        request.to = this.state.toFilter;
        request.countryId = this.state.countryFilter?.id || null;
        request.areaId = this.state.areaFilter?.id || null;
        request.serviceProviderId = this.state.serviceProviderFilter?.id || null;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;

        const response: ListShuttleLegInstancesResponse = await sender.postAsync("/api/ticket/listShuttleLegInstances", request);

        return response.page!;
    }

    public async initializeAsync(): Promise<void> {
        if (this.asServiceProviderManager) {
            this.state.serviceProviderFilter = await this.getServiceProviderAsync(this.user.serviceProviderId!);
        } else {
            this.state.serviceProviders = await this.fetchServiceProvidersAsync();
        }

        const serviceProviderId: string | null = (this.asServiceProviderManager)
            ? this.user.serviceProviderId
            : null;

        const [countries, areas] = await Promise.all([
            this.fetchCountriesAsync(serviceProviderId),
            this.fetchAreasAsync(serviceProviderId)
        ]);

        this.state.countries = countries;
        this.state.areas = areas;

        await this.setState(this.state);

        await super.initializeAsync();

        this._ticketsGridRef.current?.reloadAsync();
    }

    public get user(): User {
        return this.getUser();
    }

    public get asServiceProviderManager(): boolean {
        return AppController.asServiceProviderManager;
    }

    public get header(): string {
        return (this.state.serviceProviderFilter != null)
            ? Localizer.ticketsPageGridHeaderServiceProvider.format(this.state.serviceProviderFilter.name)
            : Localizer.ticketsPageGridHeaderAll;
    }

    public getTitle(): string {
        return Localizer.ticketsPageTitle;
    }

    public render(): React.ReactNode {
        const hasOneCountryAccess: boolean = (this.state.countries.length == 1);
        const hasOneAreaAccess: boolean = (this.state.areas.length == 1);
        return (
            <PageContainer fullWidth fullHeight className={styles.tickets}>

                <PageHeader title={this.header}/>

                <PageRow>

                    <div className="col">

                        <ToolbarContainer>

                            <ToolbarRow justify={JustifyContent.Start}>

                                {
                                    (!this.asServiceProviderManager) &&
                                    (
                                        <Dropdown noWrap inline clearButton
                                                  id={"serviceProviderFilter"}
                                                  minWidth={250}
                                                  nothingSelectedText={Localizer.ticketsPageGridFiltersServiceProviders}
                                                  items={this.state.serviceProviders}
                                                  selectedItem={this.state.serviceProviderFilter}
                                                  onChange={(_, item) => this.setServiceProviderFilterAsync(item)}
                                        />

                                    )
                                }

                                <Dropdown noWrap inline clearButton
                                          id={"countryFilter"}
                                          minWidth={250}
                                          required={hasOneCountryAccess}
                                          requiredType={(hasOneCountryAccess) ? DropdownRequiredType.AutoSelect : DropdownRequiredType.Manual}
                                          nothingSelectedText={Localizer.ticketsPageGridFiltersCountries}
                                          items={this.state.countries}
                                          selectedItem={this.state.countryFilter}
                                          onChange={(_, item) => this.setCountryFilterAsync(item)}
                                />

                                <Dropdown noWrap inline clearButton
                                          id={"areaFilter"}
                                          minWidth={250}
                                          required={hasOneAreaAccess}
                                          requiredType={(hasOneAreaAccess) ? DropdownRequiredType.AutoSelect : DropdownRequiredType.Manual}
                                          nothingSelectedText={Localizer.ticketsPageGridFiltersAreas}
                                          items={this.state.areas}
                                          selectedItem={this.state.areaFilter}
                                          onChange={(_, item) => this.setAreaFilterAsync(item)}
                                />

                            </ToolbarRow>

                            <ToolbarRow>

                                <DateInput inline clearButton
                                           placeholder={Localizer.ticketsPageGridFiltersFrom}
                                           name="from"
                                           value={this.state.fromFilter}
                                           onChange={(value) => this.setFromFilterAsync(value)}
                                />

                                <DateInput inline clearButton
                                           placeholder={Localizer.ticketsPageGridFiltersTo}
                                           name="to"
                                           minDate={this.state.fromFilter}
                                           value={this.state.toFilter}
                                           onChange={(value) => this.setToFilterAsync(value)}
                                />

                            </ToolbarRow>

                        </ToolbarContainer>

                        <Grid id="tickets" responsive pagination
                              ref={this._ticketsGridRef}
                              noDataText={Localizer.genericNoData}
                              columns={this._ticketsColumns}
                              fetchData={(sender, pageNumber, pageSize, sortColumnName, sortDirection) => this.fetchShuttleLegInstancesAsync(sender, pageNumber, pageSize, sortColumnName, sortDirection)}
                        />

                    </div>

                </PageRow>

            </PageContainer>
        );
    }
}