import React from "react";
import {GeoCoordinate} from "@reapptor-apps/reapptor-toolkit";
import {BaseComponent, ch} from "@reapptor-apps/reapptor-react-common";
import {AddressHelper, GoogleMap, IGoogleMapMarker} from "@reapptor-apps/reapptor-react-components";
import User from "@/models/server/User";
import ServicePoint from "@/models/server/bout/ServicePoint";
import Waypoint from "@/models/server/bout/Waypoint";
import Booking from "@/models/server/bout/Booking";
import {BookingStatus} from "@/models/Enums";
import PageDefinitions from "@/providers/PageDefinitions";
import TransformProvider from "@/providers/TransformProvider";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import styles from "./MyMap.module.scss";

import myLocationImage from "../../../../img/MyLocation.png";
import captainLocationImage from "../../../../img/CaptainLocationRudder.png";
import sourceLocationImage from "../../../../img/SourceServicePoint.svg";
import sourceLocationYellowImage from "../../../../img/SourceServicePointYellow.svg";
import sourceLocationGreenImage from "../../../../img/SourceServicePointGreen.svg";
import sourceLocationBlueImage from "../../../../img/SourceServicePointBlue.svg";
import destinationLocationImage from "../../../../img/DestinationServicePointYellow.svg";

interface IMyMapProps {
    id?: string;
    className?: string;
    currentLocation: GeoCoordinate | null;
    mapLocation: GeoCoordinate;
    mapZoom?: number | null;
    servicePoints: ServicePoint[];
    waypoint?: Waypoint | null;
    source?: ServicePoint | null;
    activeBookings?: Booking[] | null;
    captainLocation?: GeoCoordinate | null;
    ongoingTrip?: Booking | null;

    onServicePointClick?(sender: MyMap, servicePoint: ServicePoint): Promise<void>;
    onServicePointDoubleClick?(sender: MyMap, servicePoint: ServicePoint): Promise<void>;
}

interface IMyMapState {
}

export default class MyMap extends BaseComponent<IMyMapProps, IMyMapState> {

    state: IMyMapState = {
    };

    private async onServicePointClickAsync(servicePoint: ServicePoint): Promise<void> {
        if (this.props.onServicePointClick) {
            await this.props.onServicePointClick(this, servicePoint);
        }
    }

    private async onServicePointDoubleClickAsync(servicePoint: ServicePoint): Promise<void> {
        if (this.props.onServicePointDoubleClick) {
            await this.props.onServicePointDoubleClick(this, servicePoint);
        }
    }

    private getServicePointIcon(servicePoint: ServicePoint, defaultImage: string | null = null): string | null {
        let myBookings: Booking[] = this.activeBookings.where(item => ((item.waypoint != null) && ((item.waypoint.sourceId === servicePoint.id) || (item.waypoint.destinationId === servicePoint.id))));

        if (myBookings.length > 0) {
            myBookings = myBookings.where(item => (item.passengerId === this.user.id && this.asPassenger) || ((item.captainId === this.user.id || item.captainId == null) && this.asCaptain));
            
            if (myBookings.length > 0) {
                // if there’s ongoing trip for current user and belong to the service point then show it yellow colour
                const isOngoingTrip: boolean = myBookings.some(item => item.tripStartedAt != null && item.tripEndAt == null);
                if (isOngoingTrip) {
                    return sourceLocationYellowImage;
                }

                // if there’s trip created by current user and approved by captain and belong to the service point then show it green colour
                const approvedTrip: boolean = myBookings.some(item => item.captainId != null && item.latestStatus == BookingStatus.AcceptedByCaptain);
                if (approvedTrip) {
                    return sourceLocationGreenImage;
                }

                // if there’s a trip created by current user and not approved by captain and belong to the service point then show it blue colour
                const noteApprovedTrip: boolean = myBookings.some(item => item.captainId == null);
                if (noteApprovedTrip) {
                    return sourceLocationBlueImage;
                }
            }
        }
        
        return defaultImage;
    }

    private getActiveSourceServicePointTitle(sourceServicePoint: ServicePoint): string | null {
        const booking: Booking | undefined = this.activeBookings.find(item => ((item.waypoint != null) && (item.waypoint.sourceId == sourceServicePoint.id)));
        
        if (booking) {
            const message: string = booking.latestStatus == BookingStatus.New
                ? Localizer.mobileMyMapBookingStatusNew
                : booking.latestStatus == BookingStatus.AcceptedByCaptain
                    ? Localizer.mobileMyMapBookingStatusAcceptedByCaptain
                    : Localizer.mobileMyMapBookingStatusOngoingRide

            const numberOfPassengers: string = Localizer.mobileMyMapNumberOfPassengers.format(booking!.passengers);
            const distance: string = (booking.distance)
                ? Localizer.mobileMyMapDistance.format(booking.distance!.toFixed(1))
                : "";
            
            return `<h5>${message}</h5> 
                <h6>${booking.waypoint!.source!.name} - ${booking!.waypoint!.destination!.name}</h6>
                <div>${numberOfPassengers}<div>
                <div>${distance}<div>
                <br/>
                <a href="${PageDefinitions.myTripsAlias}">${Localizer.mobileMyMapTripsPageLink}</a>`;
        }

        return null;
    }

    public get asCaptain(): boolean {
        return AppController.asCaptain;
    }

    public get asPassenger(): boolean {
        return AppController.asPassenger;
    }

    public get user(): User {
        return ch.getUser();
    }

    public get mapLocation(): GeoCoordinate | google.maps.LatLngLiteral {
        return this.props.mapLocation;
    }

    public get currentLocation(): GeoCoordinate | null {
        return this.props.currentLocation;
    }

    public get captainLocation(): GeoCoordinate | null {
        return this.props.captainLocation || null;
    }

    public get ongoingTrip(): Booking | null {
        return this.props.ongoingTrip || null;
    }

    public get servicePoints(): ServicePoint[] {
        return this.props.servicePoints;
    }

    public get waypoint(): Waypoint | null {
        return this.props.waypoint || null;
    }

    public get source(): ServicePoint | null {
        return this.props.source || null;
    }

    public get activeBookings(): Booking[] {
        return this.props.activeBookings ?? [];
    }

    public get activeSourceServicePoints(): ServicePoint[] {
        return this
            .activeBookings
            .where(item => !!item.waypoint?.source)
            .select(item => item.waypoint!.source!);
    }

    public get markers(): IGoogleMapMarker[] {
        const waypoint: Waypoint | null = this.waypoint;
        const source: ServicePoint | null = this.source;

        const markers: IGoogleMapMarker[] = (this.asPassenger)
            ? (waypoint)
                ?
                [
                    TransformProvider.toMarker(waypoint.source, sourceLocationImage, 80, null, null, () => this.onServicePointDoubleClickAsync(waypoint.source!),() => this.onServicePointClickAsync(waypoint.source!)),
                    TransformProvider.toMarker(waypoint.destination, destinationLocationImage, 80, null, null, () => this.onServicePointDoubleClickAsync(waypoint.destination!), () => this.onServicePointClickAsync(waypoint.destination!))
                ]
                : (source)
                    ?
                    [
                        ...this
                            .servicePoints
                            .filter(item => item.id != source.id)
                            .map(item => TransformProvider.toMarker(item, null, null, null, null, () => this.onServicePointDoubleClickAsync(item), () => this.onServicePointClickAsync(item))),
                        TransformProvider.toMarker(this.source, null, 80, null, null, () => this.onServicePointDoubleClickAsync(source), () => this.onServicePointClickAsync(source))
                    ]
                    : this
                        .servicePoints
                        .map(item => TransformProvider.toMarker(item, null, null, null, null, () => this.onServicePointDoubleClickAsync(item), () => this.onServicePointClickAsync(item)))
            : this
                .activeSourceServicePoints
                .map(item => TransformProvider.toMarker(item, this.getServicePointIcon(item), 80, null, this.getActiveSourceServicePointTitle(item), () => this.onServicePointClickAsync(item), () => this.onServicePointDoubleClickAsync(item)));

        if (this.currentLocation) {
            const currentLocation: IGoogleMapMarker = AddressHelper.toMarker(this.currentLocation, null, myLocationImage);
            markers.push(currentLocation);
        }

        if (this.captainLocation) {
            const title: string | null = (this.ongoingTrip)
                ? "{0}".format(this.ongoingTrip.captain)
                : null;

            const captainLocation: IGoogleMapMarker = AddressHelper.toMarker(this.captainLocation, title, captainLocationImage);
            markers.push(captainLocation);
        }

        return markers;
    }

    public get route(): google.maps.LatLng[] {
        return ((this.waypoint) && (this.waypoint.intermediateWaypoints))
            ?
            [
                new google.maps.LatLng(this.waypoint.source!.location!.lat, this.waypoint.source!.location!.lon),
                ...this.waypoint.intermediateWaypoints.map(item => new google.maps.LatLng(item.lat, item.lon)),
                new google.maps.LatLng(this.waypoint.destination!.location!.lat, this.waypoint.destination!.location!.lon),
            ]
            : [];
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(styles.myMap, this.props.className)}>

                <GoogleMap autoCloseInfoWindows clusterMarkers
                           center={this.mapLocation}
                           zoom={this.props.mapZoom || 13}
                           markers={this.markers}
                           streetViewControl={false}
                           fullscreenControl={false}
                           mapTypeControl={false}
                           polyLinePath={this.route}
                           height={"100%"}
                />

            </div>
        )
    }
}