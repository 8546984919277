// autogenerated
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class FindCruisePackageRequest extends ETagRequest {

    public id: string | null = null;

    public path: string | null = null;

    public readonly isFindCruisePackageRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof FindCruisePackageRequest) || ((from as any).isFindCruisePackageRequest === true));
    }

    public static as(from?: object | null): FindCruisePackageRequest | null {
        return (FindCruisePackageRequest.is(from)) ? from as FindCruisePackageRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}