import React from "react";
import {GeoCoordinate, Utility} from "@reapptor-apps/reapptor-toolkit";
import {AddressHelper, PageContainer} from "@reapptor-apps/reapptor-react-components";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import MyMap from "@/pages/Mobile/Home/MyMap/MyMap";
import AppConstants from "@/helpers/AppConstants";
import ServicePoint from "@/models/server/bout/ServicePoint";
import NewTrip from "@/pages/Mobile/Home/NewTrip/NewTrip";
import Waypoint from "@/models/server/bout/Waypoint";
import {
    ApiProvider,
    BackButtonVisibility,
    BasePageParameters,
    ch,
    IBaseComponent,
    PageCacheTtl,
    PageRoute,
    PageRouteProvider,
    SwipeDirection
} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import Booking from "@/models/server/bout/Booking";
import UserContext from "@/models/server/UserContext";
import EstimatedBooking from "@/models/server/bout/EstimatedBooking";
import ShuttleWaypoint from "@/models/server/shuttle/ShuttleWaypoint";
import ListActiveShuttleServicePointsRequest from "@/models/server/requests/ListActiveShuttleServicePointsRequest";
import ListActiveShuttleServicePointsResponse from "@/models/server/responses/ListActiveShuttleServicePointsResponse";
import ListShuttleWaypointsRequest from "@/models/server/requests/ListShuttleWaypointsRequest";
import ListShuttleWaypointsResponse from "@/models/server/responses/ListShuttleWaypointsResponse";
import ServiceProviderController from "@/pages/ServiceProviderController";
import PreBookController from "@/pages/PreBookController";
import AppController from "@/pages/AppController";
import Localizer from "../../../localization/Localizer"

import boutStyles from "../../../bout.module.scss";
import styles from "./Shuttle.module.scss";
import ListMyBookingsRequest from "@/models/server/requests/ListMyBookingsRequest";
import ListMyBookingsResponse from "@/models/server/responses/ListMyBookingsResponse";
import {ApplicationType} from "@/models/Enums";

export interface IShuttleParameters extends BasePageParameters {
}

interface IShuttleState {
    mapLocation: GeoCoordinate | null;
    currentLocation: GeoCoordinate | null;
    captainLocation: GeoCoordinate | null;
    ongoingTrip: Booking | null;
    mapZoom: number | null;
    servicePoints: ServicePoint[];
    waypoint: Waypoint | null;
    source: ServicePoint | null;
    destination: ServicePoint | null;
    booking: EstimatedBooking | null;
    activeBookings: Booking[];
}

export default class Shuttle extends AuthorizedPage<IShuttleParameters, IShuttleState> {

    state: IShuttleState = {
        currentLocation: null,
        mapLocation: null,
        captainLocation: null,
        ongoingTrip: null,
        mapZoom: null,
        servicePoints: [],
        booking: null,
        activeBookings: [],
        waypoint: PreBookController.waypoint,
        source: PreBookController.source,
        destination: PreBookController.destination,
    };

    private readonly _myMapRef: React.RefObject<MyMap> = React.createRef();
    private readonly _newTripRef: React.RefObject<NewTrip> = React.createRef();

    private async fetchDestinationWaypointsAsync(sender: IBaseComponent, source: ServicePoint): Promise<ShuttleWaypoint[]> {
        const request = new ListShuttleWaypointsRequest();
        request.sourceServicePointId = source.id;

        const response: ListShuttleWaypointsResponse = await ApiProvider.postAsync("/api/mobileApp/listShuttleWaypoints", request, sender, PageCacheTtl._1h);

        return response.waypoints;
    }
    
    private async fetchActiveServicePointsAsync(): Promise<ServicePoint[]> {
        const request = new ListActiveShuttleServicePointsRequest();
        request.serviceProviderId = this.serviceProviderSlugId;

        const response: ListActiveShuttleServicePointsResponse = await this.postAsync("/api/mobileApp/listActiveShuttleServicePoints", request, PageCacheTtl._1h);

        return response.servicePoints;
    }
    
    private async fetchMyBookingsAsync(): Promise<Booking[]> {
        const request = new ListMyBookingsRequest();
        request.asCaptain = AppController.asCaptain;
        request.activeOnly = true;

        const response: ListMyBookingsResponse = await this.postAsync("/api/mobileApp/listMyBookings", request, true);

        return response.bookings;
    }

    private async preBookAsync(waypoint: Waypoint): Promise<void> {
        const route: PageRoute = PageDefinitions.shuttleSelection(waypoint);
        await PageRouteProvider.redirectAsync(route);
    }
    
    private async publishRouteToMapAsync(source: ServicePoint, destination: ServicePoint, waypoint: Waypoint): Promise<void> {
        const myMap: MyMap | null = this._myMapRef.current;

        waypoint.intermediateWaypoints = waypoint.intermediateWaypoints ?? await this.postAsync("/api/mobileApp/getIntermediateWaypoints", waypoint.id);

        const route: GeoCoordinate[] = Waypoint.getRoute(waypoint);

        const mapLocation: GeoCoordinate = AddressHelper.getCenter(route);

        const aspectRate: number = (myMap != null)
            ? myMap.outerWidth() / myMap.outerHeight()
            : 1;

        const mapZoom: number = AddressHelper.findZoom(route, 1.2, aspectRate);

        await this.setState({ source, destination, waypoint, mapLocation, mapZoom });
    }

    private async onChangeTripAsync(trip: NewTrip): Promise<void> {
        const source: ServicePoint | null = trip.source;
        const destination: ServicePoint | null = trip.destination;
        const waypoint: Waypoint | null = trip.waypoint;

        if ((source) && (destination) && (waypoint)) {
            
            await this.publishRouteToMapAsync(source, destination, waypoint);

        } else if (source != null) {

            await this.setState({source, mapLocation: source.location, waypoint: null, mapZoom: null});

        } else {

            await this.setState({source: null, waypoint: null, mapZoom: null});

        }
        
        PreBookController.setSource(source);
        PreBookController.setDestination(destination);
        PreBookController.setWaypoint(waypoint);
    }

    private async onServicePointDoubleClickAsync(servicePoint: ServicePoint): Promise<void> {
        if (this.asCaptain) {
            await PageRouteProvider.redirectAsync(PageDefinitions.myTripsRoute);
        } else if ((this._newTripRef.current) && (this.waypoint == null)) {
            if (this.source == null) {
                await this._newTripRef.current.setSourceAndDestinationAsync(servicePoint);
            } else {
                await this._newTripRef.current.setSourceAndDestinationAsync(this.source, servicePoint);
            }
        }
    }

    public async onSwipeHandlerAsync(direction: SwipeDirection): Promise<boolean> {
        return false;
    }

    public getTitle(): string {
        return Localizer.homePageTitle;
    }

    public get asCaptain(): boolean {
        return AppController.asCaptain;
    }

    public get asPassenger(): boolean {
        return AppController.asPassenger;
    }

    public get serviceProviderLocation(): GeoCoordinate | null {
        return ServiceProviderController.serviceProviderSlug?.location || null;
    }

    public get mapLocation(): GeoCoordinate {
        return this.state.mapLocation || this.serviceProviderLocation || AppConstants.defaultLocation;
    }

    public get currentLocation(): GeoCoordinate | null {
        return this.state.currentLocation;
    }

    public get captainLocation(): GeoCoordinate | null {
        return this.state.captainLocation;
    }

    public get ongoingTrip(): Booking | null {
        return this.state.ongoingTrip;
    }

    public get mapZoom(): number | null {
        return this.state.mapZoom;
    }

    public get servicePoints(): ServicePoint[] {
        return this.state.servicePoints;
    }

    public get waypoint(): Waypoint | null {
        return this.state.waypoint;
    }

    public get source(): ServicePoint | null {
        return this.state.source;
    }

    public get destination(): ServicePoint | null {
        return this.state.destination ?? PreBookController.destination;
    }

    public get booking(): EstimatedBooking | null {
        return this.state.booking;
    }

    public get activeBookings(): Booking[] {
        return this.state.activeBookings;
    }

    public get hasBackButton(): BackButtonVisibility {
        return BackButtonVisibility.Hidden;
    }

    public async initializeAsync(): Promise<void> {

        if ((!this.isRoleSelected) && (this.routeName != PageDefinitions.mobileSelectRoleRouteName)) {
            await PageRouteProvider.redirectAsync(PageDefinitions.mobileSelectRoleRoute, true, true);
        }

        await super.initializeAsync();

        const activeBookings: Booking[] = await this.fetchMyBookingsAsync();

        const context: UserContext = this.getContext();

        let captainLocation: GeoCoordinate | null = null;
        let ongoingTrip: Booking | null = null;

        if ((context.asPassenger) && (context.onlineData != null) && (context.onlineData.hasOngoingTrip)) {
            captainLocation = context.onlineData.captainLocation;
            ongoingTrip = context.onlineData.ongoingTrip;
        }

        const servicePoints: ServicePoint[] = await this.fetchActiveServicePointsAsync();

        const currentLocation: GeoCoordinate | null = (!ch.isDevelopmentVS)
            ? await Utility.getLocationAsync({maximumAge: 60000, timeout: 5000})
            : null;
        
        const mapLocation: GeoCoordinate | null = currentLocation;

        if (currentLocation != null) {
            servicePoints.sortBy((item: ServicePoint) =>
                ((item.location) && (AddressHelper.getCoordinate(item.location)))
                    ? AddressHelper.distance(currentLocation, item.location)
                    : Number.MAX_VALUE
            );
        }

        await this.setState({ currentLocation, mapLocation, servicePoints, activeBookings, captainLocation, ongoingTrip });

        if ((this.source) && (this.destination) && (this.waypoint)) {
            await this.publishRouteToMapAsync(this.source, this.destination, this.waypoint);
        }
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.shuttle, this.mobile && styles.mobile)}
                           alertClassName={this.css(boutStyles.alert)}
            >
                <MyMap ref={this._myMapRef}
                       servicePoints={this.servicePoints}
                       waypoint={this.waypoint}
                       source={this.source}
                       activeBookings={this.activeBookings}
                       mapLocation={this.mapLocation}
                       currentLocation={this.currentLocation}
                       mapZoom={this.mapZoom}
                       captainLocation={this.captainLocation}
                       ongoingTrip={this.ongoingTrip}
                       onServicePointDoubleClick={(_, servicePoint) => this.onServicePointDoubleClickAsync(servicePoint)}
                />

                {
                    (this.asPassenger) &&
                    (
                        <NewTrip noSubtext
                                 ref={this._newTripRef}
                                 applicationType={ApplicationType.Shuttle}
                                 servicePoints={this.servicePoints}
                                 mapLocation={this.mapLocation}
                                 source={this.source}
                                 destination={this.destination}
                                 expanded={(!this.ongoingTrip)}
                                 fetchDestinationWaypoints={(sender, source) => this.fetchDestinationWaypointsAsync(sender, source)}
                                 onChange={(sender) => this.onChangeTripAsync(sender)}
                                 preBook={(_, waypoint) => this.preBookAsync(waypoint)}
                        />
                    )
                }

            </PageContainer>
        );
    }
}