import React from "react";
import {BaseComponent, TextAlign} from "@reapptor-apps/reapptor-react-common";
import EstimatedBooking from "@/models/server/bout/EstimatedBooking";
import {Dropdown, DropdownOrderBy, SelectListItem} from "@reapptor-apps/reapptor-react-components";
import Booking from "@/models/server/bout/Booking";

import styles from "./NotificationInfo.module.scss";
import Localizer from "@/localization/Localizer";

export interface INotificationInfoProps {
    booking: EstimatedBooking | Booking;
    onChange: (sender: NotificationInfo) => Promise<void>;
}

interface INotificationInfoState {
}

export default class NotificationInfo extends BaseComponent<INotificationInfoProps, INotificationInfoState> {

    state: INotificationInfoState = {
    };

    private async setNotificationAsync(value: number, userInteraction: boolean): Promise<void> {
        if ((userInteraction) && (this.estimatedBooking)) {
            this.estimatedBooking.captainNotAssignedNotification = value;
            await this.props.onChange(this);
        }
    }
    
    private get isEstimatedBooking(): boolean {
        return (EstimatedBooking.is(this.booking));
    }
    
    private get estimatedBooking(): EstimatedBooking | null {
        return EstimatedBooking.as(this.booking);
    }

    private get readonly(): boolean {
        return (!this.isEstimatedBooking);
    }
    
    private items(): SelectListItem[] {
        return [
            //new SelectListItem("10", Localizer.mobileNotificationInfo10min),
            new SelectListItem("15", Localizer.mobileNotificationInfo15min),
            //new SelectListItem("20", Localizer.mobileNotificationInfo20min),
            //new SelectListItem("25", Localizer.mobileNotificationInfo25min),
            new SelectListItem("30", Localizer.mobileNotificationInfo30min),
            new SelectListItem("45", Localizer.mobileNotificationInfo45min),
            new SelectListItem("60", Localizer.mobileNotificationInfo60min),
            new SelectListItem("120", Localizer.mobileNotificationInfo120min),
            new SelectListItem("1440", Localizer.mobileNotificationInfo1440min),
            new SelectListItem("0", Localizer.mobileNotificationInfo0min),
        ];
    }
    
    private get captainNotAssignedNotification(): number {
        return this.booking.captainNotAssignedNotification;
    }

    public get booking(): EstimatedBooking | Booking {
        return this.props.booking;
    }

    public render(): React.ReactNode {
        return (
            <div className={styles.notificationInfo}>

                <span>{Localizer.mobileNotificationInfoInformOfCancellationsSpan}</span>

                <Dropdown required noFilter
                          textAlign={TextAlign.Center}
                          disabled={this.readonly}
                          items={this.items()}
                          orderBy={DropdownOrderBy.None}
                          selectedItem={this.captainNotAssignedNotification}
                          onChange={(_, item, userInteraction) => this.setNotificationAsync(parseInt(item!.value), userInteraction)}
                />

                <span>{Localizer.mobileNotificationInfoBeforeLeavingSpan}</span>

            </div>
        );
    }
}