import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {PageContainer, PageRow} from "@reapptor-apps/reapptor-react-components";
import {ApiProvider} from "@reapptor-apps/reapptor-react-common";
import User from "@/models/server/User";
import InProgress from "@/pages/Mobile/Kyc/InProgress/InProgress";
import {StripeKycState} from "@/models/Enums";
import Verified from "@/pages/Mobile/Kyc/Verified/Verified";
import NotStarted from "@/pages/Mobile/Kyc/NotStarted/NotStarted";
import StripeKycStatus from "@/models/server/stripe/kycstatus/StripeKycStatus";
import Failed from "@/pages/Mobile/Kyc/Failed/Failed";
import WaitingApproval from "@/pages/Mobile/Kyc/WaitingApproval/WaitingApproval";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import boutStyles from "../../../bout.module.scss";
import styles from "./Kyc.module.scss";

interface IKycProps {
}

interface IKycState {
    user: User | null;
}

export default class Kyc extends AuthorizedPage<IKycProps, IKycState> {

    state: IKycState = {
        user: null
    };

    private async startKycAsync(): Promise<void> {
        const endpoint: string = (AppController.asCaptain)
            ? "/api/application/getCaptainKycRedirectUrl"
            : "/api/application/getServiceProviderKycRedirectUrl";
        
        window.location.href = await ApiProvider.invokeWithForcedSpinnerAsync(() => this.getAsync<string>(endpoint), true);
    }

    private async fetchMyUser(): Promise<void> {
        const user: User = await this.postAsync("/api/account/me", this.route);
        
        await this.setState({user});
    }

    public get user(): User | null {
        return this.state.user;
    }

    public get stripeKycStatus(): StripeKycStatus {
        return this.user!.stripeKycStatus!;
    }

    public getTitle(): string {
        return Localizer.mobileKycPageTitle;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        await this.fetchMyUser();
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.kyc, this.mobile && styles.mobile)}
                           alertClassName={boutStyles.alert}
            >

                <span className={styles.header}>{this.getTitle()}</span>

                {
                    (this.user) &&
                    (
                        <PageRow>

                            {
                                (this.stripeKycStatus.state == StripeKycState.NotStarted) &&
                                (
                                    <NotStarted user={this.user} 
                                                onButtonClick={() => this.startKycAsync()}
                                    />
                                )
                            }

                            {
                                (this.stripeKycStatus.state == StripeKycState.InProgress) &&
                                (
                                    <InProgress user={this.user} 
                                                onButtonClick={() => this.startKycAsync()}
                                    />
                                )
                            }

                            {
                                (this.stripeKycStatus.state == StripeKycState.Failed) &&
                                (
                                    <Failed user={this.user} 
                                            onButtonClick={() => this.startKycAsync()}
                                    />
                                )
                            }

                            {
                                (this.stripeKycStatus.state == StripeKycState.WaitingForApproval) &&
                                (
                                    <WaitingApproval user={this.user} 
                                                     onModifyButtonClick={() => this.startKycAsync()} 
                                                     onRefreshUserButtonClick={() => this.fetchMyUser()}
                                    />
                                )
                            }

                            {
                                (this.stripeKycStatus.state == StripeKycState.Verified) &&
                                (
                                    <Verified user={this.user}
                                              onModify={() => this.startKycAsync()}
                                    />
                                )
                            }

                        </PageRow>
                    )
                }

            </PageContainer>
        );
    }
}