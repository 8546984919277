// autogenerated
import {BoatType, CruisePackagePriceInterval, DayOfWeek} from "@/models/Enums";
import CruisePackage from "@/models/server/cruise/CruisePackage";
import CruisePackagePoint from "@/models/server/cruise/CruisePackagePoint";
import User from "@/models/server/User";
import BaseBooking from "@/models/server/bout/BaseBooking";
import CruisePackagePrice from "@/models/server/cruise/CruisePackagePrice";

export default class CruisePackageBooking extends BaseBooking {

    public cruisePackageId: string = "";

    public cruisePackage: CruisePackage | null = null;

    public pointId: string | null = null;

    public point: CruisePackagePoint | null = null;

    public customPoint: boolean = false;

    public customPointAddress: string | null = null;

    public boatType: BoatType = BoatType.Small;

    public passengers: number = 0;

    public children: number = 0;

    public passengerId: string = "";

    public passenger: User | null = null;

    public estimatedPrice: number = 0;

    public durationInHours: number = 0;

    public readonly isCruisePackageBooking: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof CruisePackageBooking) || ((from as any).isCruisePackageBooking === true));
    }

    public static as(from?: object | null): CruisePackageBooking | null {
        return (CruisePackageBooking.is(from)) ? from as CruisePackageBooking : null;
    }

    // #region Placeholder

    public static valid(booking: CruisePackageBooking): boolean {
        return (
            ((!booking.customPoint) || (!!booking.customPointAddress)) &&
            ((booking.customPoint) || ((booking.point != null) || (!!booking.pointId)))
        )
    }

    public static setAdults(booking: CruisePackageBooking, adults: number, maxPassengers: number): void {
        booking.passengers = (adults + booking.children);

        if (booking.passengers > maxPassengers) {
            const delta: number = booking.passengers - maxPassengers;
            booking.children = booking.children - delta;
            booking.passengers = maxPassengers;
        }
    }

    public static setChildren(booking: CruisePackageBooking, children: number): void {
        const delta: number = children - booking.children;

        booking.children = children;
        booking.passengers = booking.passengers + delta;
    }

    public static estimatePrice(booking?: CruisePackageBooking | null): void {
        if ((booking) && (booking.cruisePackage)) {
            const cruisePackage: CruisePackage = booking.cruisePackage;
            
            // Form prices
            
            let prices: CruisePackagePrice[] = booking.cruisePackage.prices ?? [];

            const splitPriceModel: boolean = CruisePackage.isSplitPriceModel(cruisePackage);

            if (splitPriceModel) {
                const dayOfWeek: DayOfWeek = booking.bookingTime.getDay() as DayOfWeek;
                
                prices = ((dayOfWeek == DayOfWeek.Monday) || (dayOfWeek == DayOfWeek.Tuesday))
                    ? prices.where(item => item.interval == CruisePackagePriceInterval.MondayTuesday)
                    : prices.where(item => item.interval == CruisePackagePriceInterval.WednesdaySunday);
            }
            
            // Define the price per interval
            
            const intervalInHours: number = 0.5;
            const lastCustomPriceIndex: number = prices.length - 1;
            const minDurationInHours: number = cruisePackage.minDurationInHours;
            
            const durationInHours: number = (booking.durationInHours >= minDurationInHours)
                ? booking.durationInHours
                : minDurationInHours;
            
            const lastCustomPrice: CruisePackagePrice = prices[lastCustomPriceIndex];
            const lastCustomPriceDurationInHours: number = CruisePackagePrice.getDurationInHours(lastCustomPrice, prices, minDurationInHours, intervalInHours);
            const defaultPricePerInterval: number = CruisePackagePrice.getIntervalPrice(lastCustomPrice, prices, minDurationInHours, intervalInHours);
            
            const customPriceIndex: number = (durationInHours - minDurationInHours)/intervalInHours;
            const customPrice: CruisePackagePrice = (customPriceIndex <= lastCustomPriceIndex)
                ? prices[customPriceIndex]
                : lastCustomPrice;
            
            const extraDurationInHours: number = (customPriceIndex > lastCustomPriceIndex)
                ? durationInHours - lastCustomPriceDurationInHours
                : 0;
            const extraDurationIntervals: number = extraDurationInHours / intervalInHours;
            const durationBasedPrice: number = customPrice.price + defaultPricePerInterval * extraDurationIntervals;

            const customLocationPrice: number = (booking.customPoint)
                ? cruisePackage.customLocationPrice ?? 0
                : booking.point?.price ?? 0;

            booking.durationInHours = durationInHours;
            booking.estimatedPrice = durationBasedPrice + customLocationPrice;
        }
    }

    // #endregion
}