import React from "react";
import {CardElement} from "@stripe/react-stripe-js";
import * as stripeJs from "@stripe/stripe-js";
import {Stripe, StripeCardElement, StripeCardElementOptions, StripeElements} from "@stripe/stripe-js";
import {BaseComponent, IBaseComponentProps, PageRoute, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonContainer, ButtonType, Checkbox, Form, InlineType, TextInput} from "@reapptor-apps/reapptor-react-components";
import CreditCard from "@/models/server/bout/CreditCard";
import PageDefinitions from "@/providers/PageDefinitions";
import Localizer from "@/localization/Localizer";

import styles from "./StripeForm.module.scss";

export interface IStripeFormProps extends IBaseComponentProps {
    creditCard: CreditCard;
    required: boolean;
    stripe: Stripe;
    elements: StripeElements;
    onSave: (sender: StripeForm, cardElement: StripeCardElement, isDefault: boolean, holderName: string) => Promise<void>;
    onSetDefault: (sender: StripeForm) => Promise<void>;
    onDelete: (sender: StripeForm) => Promise<void>;
}

interface IStripeFormState {
    complete: boolean;
    empty: boolean;
    isDefault: boolean;
}

export default class StripeForm extends BaseComponent<IStripeFormProps, IStripeFormState> {

    state: IStripeFormState = {
        isDefault: this.props.creditCard.default || this.props.required,
        complete: false,
        empty: true
    };
    
    private async onSubmitAsync(): Promise<void> {
        await this.props.onSave(this, this.getStripeElement(), this.isDefault, this.creditCard.holderName!);
    }
    
    private async onDeleteAsync(): Promise<void> {
        await this.props.onDelete(this);
    }
    
    private async onSetDefaultAsync(): Promise<void> {
        await this.props.onSetDefault(this);
    }
    
    private get required(): boolean {
        return this.props.required;
    }

    private get creditCard(): CreditCard {
        return this.props.creditCard;
    }

    private get isNew(): boolean {
        return (!this.creditCard.id);
    }

    private get isDefault(): boolean {
        return this.state.isDefault;
    }

    private get isExpired(): boolean {
        return this.creditCard.isExpired;
    }

    private async setIsDefaultAsync(value: boolean): Promise<void> {
        await this.setState({isDefault: value});
    }

    private async setHolderNameAsync(value: string): Promise<void> {
        this.creditCard.holderName = value;

        await this.reRenderAsync();
    }

    private getStripeElement(): StripeCardElement {
        return this.props.elements.getElement(CardElement)!;
    }
    
    private get stripeOptions(): StripeCardElementOptions {
        return {
            hidePostalCode: true,
            hideIcon: true,
            style: {
                base: {
                    fontSize: "15px"
                }
            }
        }
    }

    private async renewCreditCardAsync(): Promise<void> {
        const route: PageRoute = PageDefinitions.editCreditCard(null, false, false, true);
        await PageRouteProvider.redirectAsync(route);
    }

    private async onChangeAsync(event: stripeJs.StripeCardElementChangeEvent): Promise<void> {
        const complete: boolean = event.complete;
        const empty: boolean = event.empty;
        await this.setState({complete, empty});
    }

    public render(): React.ReactNode {
        return (
            <Form id={this.id}
                  className={this.css(styles.stripeForm, this.props.className)}
                  onSubmit={() => this.onSubmitAsync()}>

                <Checkbox inline
                          inlineType={InlineType.Right}
                          className={styles.setDefault}
                          readonly={this.required || !this.isNew}
                          label={Localizer.mobileStripeFormCheckboxIsDefault}
                          value={this.isDefault}
                          onChange={(sender, value) => this.setIsDefaultAsync(value)}
                />

                {
                    (this.isNew) &&
                    (
                        <>
                            
                            <label className={styles.cardNumberLabel}>{Localizer.mobileStripeFormCreditCardLabel}</label>
                            
                            <CardElement className={styles.cardNumber}
                                         options={this.stripeOptions}
                                         onChange={(event: stripeJs.StripeCardElementChangeEvent) => this.onChangeAsync(event)}
                            />
                            
                        </>
                    )
                }

                {
                    (!this.isNew) &&
                    (
                        <>

                            <TextInput required readonly
                                       label={Localizer.mobileStripeFormTextInputCardNumberLabel}
                                       value={CreditCard.getNumber(this.creditCard)}
                            />

                            <TextInput required readonly
                                       label={Localizer.mobileStripeFormTextInputCardExpirationLabel}
                                       value={CreditCard.getExpiration(this.creditCard)}
                            />

                        </>
                    )
                }
                
                <TextInput label={Localizer.mobileStripeFormTextInputHolderNameLabel} required
                           readonly={!this.isNew}
                           value={this.creditCard.holderName}
                           onChange={(sender, value) => this.setHolderNameAsync(value)}
                />

                {
                    (!this.isNew) &&
                    (
                        <TextInput readonly
                                   label={Localizer.mobileStripeFormTextInputBrandLabel}
                                   value={this.creditCard.brand?.toUpperCase()}
                        />
                    )
                }

                <div className={styles.expander} />

                <ButtonContainer>

                    {
                        ((!this.isNew) && (!this.isDefault)) &&
                        (
                            <Button icon={{name: "fad fa-circle"}}
                                    label={Localizer.mobileStripeFormButtonSetDefaultLabel}
                                    type={ButtonType.Primary}
                                    onClick={() => this.onSetDefaultAsync()}
                            />
                        )
                    }

                    {
                        ((!this.isNew) && (this.isExpired)) &&
                        (
                            <Button icon={{name: "fa-solid fa-repeat"}}
                                    label={Localizer.genericRenew}
                                    type={ButtonType.Blue}
                                    onClick={() => this.renewCreditCardAsync()}
                            />
                        )
                    }
                    
                    {       
                        (!this.isNew) &&
                        (
                            <Button icon={{name: "far trash-alt"}}
                                    confirm={Localizer.mobileStripeFormButtonDeleteCardConfirm}
                                    label={Localizer.genericDelete}
                                    type={ButtonType.Dark}
                                    onClick={() => this.onDeleteAsync()}
                            />
                        )
                    }

                    {
                        (this.isNew) &&
                        (
                            <Button submit
                                    icon={{name: "far save"}}
                                    label={Localizer.genericSave}
                                    type={ButtonType.Dark}
                                    disabled={!this.state.complete}
                            />
                        )
                    }

                </ButtonContainer>

            </Form>
        );
    }
}