import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {
    Checkbox,
    Dropdown,
    EmailInput,
    Form,
    NumberInput,
    NumberInputBehaviour,
    Panel, TextAreaInput,
    TextInput,
    ThreeColumns,
    TwoColumns
} from "@reapptor-apps/reapptor-react-components";
import ServiceProvider from "@/models/server/ServiceProvider";
import BankAccount from "@/models/server/bout/BankAccount";
import StripeKycStatus from "@/models/server/stripe/kycstatus/StripeKycStatus";
import Localizer from "@/localization/Localizer";

import styles from "./WaterOperatorPanel.module.scss"

interface IWaterOperatorPanelProps {
    serviceProvider: ServiceProvider;
    serviceProviders: ServiceProvider[];
    kycStatus: StripeKycStatus | null;
}

interface IWaterOperatorPanelState {
    iban: string;
    kycStatus: StripeKycStatus | null;
}

export default class WaterOperatorPanel extends BaseComponent<IWaterOperatorPanelProps, IWaterOperatorPanelState> {

    state: IWaterOperatorPanelState = {
        iban: "",
        kycStatus: null,
    };

    private readonly _formRef: React.RefObject<Form> = React.createRef();

    private async setIbanAsync(value: string): Promise<void> {
        await this.setState({iban: value});
    }

    private async setAccountHolderNameAsync(value: string): Promise<void> {
        this.bankAccount.holderName = value;
        await this.reRenderAsync();
    }

    private async setServiceProviderAsync(serviceProvider: ServiceProvider | null): Promise<void> {
        this.serviceProvider.parentId = serviceProvider?.id ?? null;

        await this.reRenderAsync();
    }

    private async setMarginAsync(value: number): Promise<void> {
        this.serviceProvider.margin = (value > 0) ? value : null;

        await this.reRenderAsync();
    }

    private async setStripeEmailAsync(stripeEmail: string): Promise<void> {
        this.serviceProvider.stripeEmail = stripeEmail;

        await this.reRenderAsync();
    }

    private async setPassPaymentToParentAsync(passPaymentToParent: boolean): Promise<void> {
        this.serviceProvider.passPaymentToParent = (this.canPassPaymentToParent)
            ? passPaymentToParent
            : null;

        await this.reRenderAsync();
    }

    private get serviceProviders(): ServiceProvider[] {
        return this.props.serviceProviders.where(item => item.id != this.serviceProvider.id);
    }

    private get parentId(): string | null {
        return this.serviceProvider.parentId;
    }

    private get canPassPaymentToParent(): boolean {
        return (!!this.parentId);
    }

    private get passPaymentToParent(): boolean {
        return (this.canPassPaymentToParent) && (this.serviceProvider.passPaymentToParent == true);
    }

    private get bankAccount(): BankAccount {
        return this.serviceProvider.bankAccount ?? (this.serviceProvider.bankAccount = new BankAccount());
    }

    public get isNew(): boolean {
        return (!this.bankAccount.id);
    }

    public get iban(): string {
        return (this.isNew)
            ? this.state.iban
            : BankAccount.getIban(this.bankAccount);
    }

    public get holderName(): string {
        return this.bankAccount.holderName;
    }

    public get serviceProvider(): ServiceProvider {
        return this.props.serviceProvider;
    }

    public get kycStatus(): StripeKycStatus | null {
        return this.props.kycStatus;
    }

    public get margin(): number | null {
        return ((!this.passPaymentToParent) && (this.serviceProvider.margin != null) && (this.serviceProvider.margin > 0))
            ? this.serviceProvider.margin
            : null;
    }

    public async isFormValidAsync(): Promise<boolean> {
        return this._formRef.current?.validateAsync() || false;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public render(): React.ReactNode {
        return (
            <Panel className={this.css("flex-2", styles.waterOperatorPanel)}>
                <div className="col-12">

                    <Form ref={this._formRef} className={styles.form}>

                        <ThreeColumns>

                            <Dropdown id="parentServiceProvider"
                                      label={Localizer.serviceProvidersManagementPageWaterOperatorParentServiceProvider}
                                      nothingSelectedText={Localizer.serviceProvidersManagementPageWaterOperatorNoParent}
                                      items={this.serviceProviders}
                                      selectedItem={this.parentId}
                                      onChange={(_, item) => this.setServiceProviderAsync(item)}
                            />

                            {
                                (!this.passPaymentToParent) &&
                                (
                                    <NumberInput id="margin" hideZero
                                                 required={false}
                                                 label={Localizer.serviceProvidersManagementPageWaterOperatorMargin}
                                                 behaviour={NumberInputBehaviour.Restricted}
                                                 value={this.margin}
                                                 placeholder={Localizer.serviceProvidersManagementPageWaterOperatorAreaSpecific}
                                                 min={0}
                                                 max={100}
                                                 step={0.1}
                                                 onChange={(_, value) => this.setMarginAsync(value)}
                                    />
                                )
                            }

                            {
                                (this.canPassPaymentToParent) &&
                                (
                                    <Checkbox id={nameof(this.serviceProvider.passPaymentToParent)}
                                              label={Localizer.serviceProvidersManagementPageWaterOperatorPassPaymentToParent}
                                              readonly={!this.canPassPaymentToParent}
                                              value={this.passPaymentToParent}
                                              onChange={(_, value) => this.setPassPaymentToParentAsync(value)}
                                    />
                                )
                            }

                        </ThreeColumns>

                        {
                            (!this.passPaymentToParent) &&
                            (
                                <React.Fragment>

                                    <TwoColumns>

                                        <EmailInput id={nameof(this.serviceProvider.stripeEmail)}
                                                    required noAutoComplete
                                                    label={Localizer.serviceProvidersManagementPageWaterOperatorStripeEmail}
                                                    readonly={!!this.serviceProvider.stripeAccountId}
                                                    value={this.serviceProvider.stripeEmail}
                                                    onChange={(_, value) => this.setStripeEmailAsync(value)}
                                        />

                                    </TwoColumns>

                                    <ThreeColumns>

                                        <Checkbox readonly
                                                  id="stripeKycVerified"
                                                  label={Localizer.serviceProvidersManagementPageWaterOperatorStripeKYCVerified}
                                                  value={this.serviceProvider.stripeKycVerified}
                                        />

                                        <TextInput readonly
                                                   id="stripeAccountId"
                                                   label={Localizer.serviceProvidersManagementPageWaterOperatorStripeAccountId}
                                                   value={this.serviceProvider.stripeAccountId}
                                        />

                                        <TextInput readonly
                                                   id="stripeCustomerId"
                                                   label={Localizer.serviceProvidersManagementPageWaterOperatorStripeCustomerId}
                                                   value={this.serviceProvider.stripeCustomerId}
                                        />

                                    </ThreeColumns>

                                    <ThreeColumns>

                                        <TextInput required
                                                   readonly={!this.isNew}
                                                   label={Localizer.mobileEditBankAccountPageTextInputAccountNumberLabel}
                                                   value={this.iban}
                                                   onChange={(sender, value) => this.setIbanAsync(value)}
                                        />

                                        <TextInput required
                                                   readonly={!this.isNew}
                                                   label={Localizer.mobileEditBankAccountPageTextInputHolderNameLabel}
                                                   value={this.holderName}
                                                   onChange={(sender, value) => this.setAccountHolderNameAsync(value)}
                                        />

                                    </ThreeColumns>

                                    {
                                        (this.kycStatus) &&
                                        (
                                            <TextAreaInput readonly noRemaining
                                                           label={Localizer.serviceProvidersManagementPageWaterOperatorStripeKycStatus}
                                                           rows={10}
                                                           value={StripeKycStatus.toJsonResult(this.kycStatus)}/>
                                        )
                                    }
                                    
                                </React.Fragment>
                            )
                        }

                    </Form>

                </div>

            </Panel>
        );
    }
};