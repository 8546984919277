import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {Button, ButtonContainer, ButtonType, Footer, PageContainer, PageHeader, PageRow} from "@reapptor-apps/reapptor-react-components";
import Boat from "@/models/server/bout/Boat";
import BoatItem from "@/pages/Mobile/MyBoats/BoatItem/BoatItem";
import {PageRoute, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";

import Localizer from "@/localization/Localizer";

import boutStyles from "../../../bout.module.scss";
import styles from "./MyBoats.module.scss";

interface IMyBoatsProps {
}

interface IMyBoatsState {
    boats: Boat[] | null;
}

export default class MyBoats extends AuthorizedPage<IMyBoatsProps, IMyBoatsState> {

    state: IMyBoatsState = {
        boats: null
    };

    private async openBoatAsync(boat: Boat | null): Promise<void> {
        const boatId: string | null = boat?.id || null;
        const route: PageRoute = PageDefinitions.editBoat(boatId, false);

        await PageRouteProvider.redirectAsync(route);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const boats: Boat[] = await this.postAsync("/api/mobileApp/GetMyBoats");

        await this.setState({boats});
    }

    public getTitle(): string {
        return Localizer.mobileMyBoatsPageTitle;
    }

    public get boats(): Boat[] | null {
        return this.state.boats;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.myBoats, this.mobile && styles.mobile)}
                           alertClassName={boutStyles.alert}
            >

                <span className={styles.header}>{this.getTitle()}</span>

                <PageRow>

                    <div className={styles.container}>

                        {
                            (this.boats) &&
                            (
                                (this.boats.length > 0)
                                    ?
                                    (
                                        this.boats.map((boat: Boat) => <BoatItem key={boat.id} boat={boat} onClick={() => this.openBoatAsync(boat)}/>)
                                    )
                                    :
                                    (
                                        <div className={styles.noBoats}>
                                            <span>{Localizer.mobileMyBoatsPageNoBoats}</span>
                                        </div>
                                    )
                            )
                        }

                        <div className={styles.expander}/>

                        <ButtonContainer>
                            
                            <Button block
                                    id={"addNewBoat"}
                                    label={Localizer.mobileMyBoatsPageButtonAddNewBoat}
                                    type={ButtonType.Dark}
                                    icon={"fal plus"}
                                    onClick={() => this.openBoatAsync(null)}
                            />
                            
                        </ButtonContainer>
                    </div>

                </PageRow>

            </PageContainer>
        );
    }
}