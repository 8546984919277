import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import Country from "@/models/server/bout/Country";
import {
    Button,
    ButtonType, Checkbox,
    IconSize,
    Inline,
    JustifyContent,
    PageContainer,
    PageHeader,
    PageRow, TextInput,
    ToolbarContainer
} from "@reapptor-apps/reapptor-react-components";
import PageDefinitions from "@/providers/PageDefinitions";
import {PageRoute, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import CountryContainer from "@/pages/Countries/CountryContainer/CountryContainer";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import styles from "./Countries.module.scss";

interface ICountriesProps {
}

interface ICountriesState {
    initialized: boolean;
    countries: Country[];
    search: string;
    activeOnly: boolean;
}

export default class Countries extends AuthorizedPage<ICountriesProps, ICountriesState> {

    state: ICountriesState = {
        initialized: false,
        countries: [],
        search: "",
        activeOnly: true,
    };

    private async setSearchAsync(search: string): Promise<void> {
        if (this.state.search != search) {
            await this.setState({search});
        }
    }

    private async setActiveOnlyAsync(activeOnly: boolean): Promise<void> {
        if (this.state.activeOnly != activeOnly) {
            await this.setState({activeOnly});
        }
    }

    private async addCountryAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.countryManagementRoute);
    }

    private async openCountryAsync(countryId: string): Promise<void> {
        const route: PageRoute = await PageDefinitions.countryManagement(countryId);
        await PageRouteProvider.redirectAsync(route);
    }

    private async reloadAsync(): Promise<void> {
        this.state.search = "";

        const countries: Country[] = await AppController.listCountriesAsync();
        
        await this.setState({countries, initialized: true});
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        await this.reloadAsync();
    }

    public get navigation(): boolean {
        return false;
    }

    public get countries(): Country[] {
        return this.state.countries;
    }

    public getTitle(): string {
        return Localizer.countriesPageTitle;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={this.css(styles.countries)}>
                <PageHeader title={this.getTitle()} />
                <PageRow>
                    <div className="col">

                        <div className={styles.container}>

                            <ToolbarContainer className={styles.toolbar}>

                                <Inline justify={JustifyContent.Center} className={styles.inline}>

                                    <TextInput inline small autoFocus autoComplete
                                               id="country_search"
                                               className={styles.search}
                                               placeholder={Localizer.genericSearch}
                                               value={this.state.search}
                                               onChange={(sender, value) => this.setSearchAsync(value)}
                                    />

                                    <Checkbox inline
                                              label={Localizer.countiesPageCheckboxActiveLabel}
                                              value={this.state.activeOnly}
                                              onChange={async (sender, value) => await this.setActiveOnlyAsync(value)}
                                    />

                                    <Button title={Localizer.genericReload} className="ml-1"
                                            icon={{name: "far history", size: IconSize.Large}}
                                            type={ButtonType.Info}
                                            onClick={async () => await this.reloadAsync()}
                                    />

                                    <Button title={Localizer.countriesPageButtonAddCountryTitle}
                                            icon={{name: "plus", size: IconSize.Large}}
                                            type={ButtonType.Orange}
                                            onClick={async ()=> await this.addCountryAsync()}
                                    />

                                    <Button title={Localizer.genericBack}
                                            icon={{name: "fas arrow-alt-circle-left"}}
                                            type={ButtonType.Primary}
                                            route={PageDefinitions.adminRoute}
                                    />

                                </Inline>

                            </ToolbarContainer>

                            {
                                (this.state.initialized) &&
                                (
                                    <CountryContainer countries={this.countries}
                                                      search={this.state.search}
                                                      activeOnly={this.state.activeOnly}
                                                      onClick={(sender, item) => this.openCountryAsync(item.id!)}
                                    />
                                )
                            }

                        </div>

                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}