import {ApiProvider, ch, PageRoute} from "@reapptor-apps/reapptor-react-common";
import ServiceProvider from "@/models/server/ServiceProvider";
import ListSlugServiceProvidersRequest from "@/models/server/requests/ListSlugServiceProvidersRequest";
import ListSlugServiceProvidersResponse from "@/models/server/responses/ListSlugServiceProvidersResponse";
import ApplicationContext from "@/models/server/ApplicationContext";
import AppConstants from "@/helpers/AppConstants";
import {ApplicationType} from "@/models/Enums";
import PreBookController from "@/pages/PreBookController";
import Localizer from "@/localization/Localizer";

class ServiceProviderController {
    private _initialized: boolean = false;
    private _initializing: boolean = false;
    private _slugs: ServiceProvider[] = [];
    
    public async listSlugServiceProvidersAsync(): Promise<ServiceProvider[]> {
        const request = new ListSlugServiceProvidersRequest();
        const response: ListSlugServiceProvidersResponse = await ApiProvider.postAsync("/api/application/listSlugServiceProviders/", request);
        return response.serviceProviders;
    }
    
    public async selectCurrentServiceProviderAsync(serviceProvider: ServiceProvider | null): Promise<void> {
        const serviceProviderId: string = serviceProvider?.id || AppConstants.defaultGuid;

        const slugServiceProviderId: string = this.serviceProviderSlug?.id || AppConstants.defaultGuid;

        await this.loadStylesAsync(serviceProvider);

        if (serviceProviderId != slugServiceProviderId) {
            PreBookController.clear();
            await ApiProvider.postAsync("/api/application/selectCurrentServiceProvider", serviceProviderId);
        }
    }
    
    private getSlugLocalization(template: string, defaultValue: string): string {
        const slug: string | null = this.serviceProviderSlug?.slug || null;
        if (slug) {
            const name: string = template.format(slug.toPascalCase());
            if (Localizer.contains(name)) {
                return Localizer.get(name);
            }
        }

        return defaultValue;
    }
    
    public get isHds(): boolean {
        const serviceProvider: ServiceProvider | null = this.serviceProviderSlug;
        return (serviceProvider?.slug == AppConstants.hdsSlug);
    }
    
    public get isPjta(): boolean {
        const serviceProvider: ServiceProvider | null = this.serviceProviderSlug;
        return (serviceProvider?.slug == AppConstants.pjtaSlug);
    }
    
    public get privacyNoticeTitle(): string {
        return this.getSlugLocalization(
            `MyAccountPage.Acceptance.PrivacyNotice.{0}.Title`,
            Localizer.myAccountPageAcceptancePrivacyNoticeTitle
        );
    }

    public get privacyNoticeContent(): string {
        return this.getSlugLocalization(
            `MyAccountPage.Acceptance.PrivacyNotice.{0}.Content`,
            Localizer.myAccountPageAcceptancePrivacyNoticeContent
        );
    }
    
    public get termsTitle(): string {
        return this.getSlugLocalization(
            `MyAccountPage.Acceptance.Terms.{0}.Title`,
            Localizer.myAccountPageAcceptanceTermsTitle
        );
    }

    public get termsContent(): string {
        return this.getSlugLocalization(
            `MyAccountPage.Acceptance.Terms.{0}.Content`,
            Localizer.myAccountPageAcceptanceTermsContent
        );
    }

    public get termsContentCaptain(): string {
        return this.getSlugLocalization(
            `MyAccountPage.Acceptance.Terms.{0}.ContentCaptain`,
            Localizer.myAccountPageAcceptanceTermsContentCaptain
        );
    }
    
    public get cancellationPolicyTitle(): string {
        return this.getSlugLocalization(
            `MyAccountPage.Acceptance.CancellationPolicy.{0}.Title`,
            Localizer.myAccountPageAcceptanceCancellationPolicyTitle
        );
    }
    
    public get cancellationPolicyContent(): string {
        return this.getSlugLocalization(
            `MyAccountPage.Acceptance.CancellationPolicy.{0}.Content`,
            Localizer.myAccountPageAcceptanceCancellationPolicyContent
        );
    }

    private async loadStylesAsync(serviceProvider: ServiceProvider | null): Promise<void> {

        const html: HTMLHtmlElement = document.getElementsByTagName("html")[0];

        let className: string = "";

        if (serviceProvider?.slug) {
            const slug: string = serviceProvider.slug;

            await import("../sp/sp.module.scss");

            className = `sp ${slug}`;
        }

        html.setAttribute("class", className);
    }

    public async initializeAsync(): Promise<void> {
        if ((!this._initialized) && (!this._initializing)) {
            this._initializing = true;
            
            try {
                
                this._slugs = await this.listSlugServiceProvidersAsync();

                await this.resolveSlugAsync(true);
                
                this._initialized = true;
                
            } finally {
                this._initializing = false;
            }
        }
    }
    
    public get serviceProviderSlug(): ServiceProvider | null {
        return ch.findContext<ApplicationContext>()?.serviceProvider || null;
    }
    
    public get serviceProviderId(): string | null {
        return this.serviceProviderSlug?.id || null;
    }
    
    public get supportsRating(): boolean {
        const serviceProvider: ServiceProvider | null = this.serviceProviderSlug;
        return ((serviceProvider == null) || (serviceProvider.supportsRating));
    }

    public get isSingleApplicationType(): boolean {
        return ((this.serviceProviderSlug != null) && (this.serviceProviderSlug.applicationTypes.length == 1));
    }

    public get isShuttleApplicationOnly(): boolean {
        const serviceProviderSlug: ServiceProvider | null = this.serviceProviderSlug;
        return (
            (serviceProviderSlug != null) &&
            (serviceProviderSlug.applicationTypes.length == 1) &&
            (serviceProviderSlug.applicationTypes[0] == ApplicationType.Shuttle)
        );
    }

    public get canCallToCaptainForCompletedTrip(): boolean {
        return (!this.isPjta);
    }

    public get personalInformationWarning(): boolean {
        const serviceProvider: ServiceProvider | null = this.serviceProviderSlug;
        return ((serviceProvider != null) && (serviceProvider.personalInformationWarning));
    }

    public get shuttleMaxTickets(): number {
        return this.serviceProviderSlug?.shuttleMaxTickets ?? AppConstants.defaultShuttleMaxTickets;
    }

    public get minBookingDepthInHours(): number | null {
        return this.serviceProviderSlug?.minBookingDepthInHours ?? null;
    }

    public get maxBookingDepthInHours(): number | null {
        return this.serviceProviderSlug?.maxBookingDepthInHours ?? null;
    }

    public get minBookingRangeInMinutes(): number | null {
        return this.serviceProviderSlug?.minBookingRangeInMinutes ?? null;
    }

    public get maxBookingRangeInMinutes(): number | null {
        return this.serviceProviderSlug?.maxBookingRangeInMinutes ?? null;
    }

    public get supportsNowBooking(): boolean {
        return (this.minBookingDepthInHours == 0);
    }

    public get estimatedBookingExpirationInMinutes(): number | null {
        return this.serviceProviderSlug?.estimatedBookingExpirationInMinutes ?? null;
    }

    public get hasEstimatedBookingExpirationInMinutes(): boolean {
        return ((this.estimatedBookingExpirationInMinutes != null) && (this.estimatedBookingExpirationInMinutes > 0));
    }

    public get bookingWarningIntervalInHours(): number | null {
        return (!this.supportsNowBooking)
            ? AppConstants.bookingWarningIntervalInHours
            : null;
    }

    public get androidAppAvailable(): boolean {
        const serviceProviderSlug: ServiceProvider | null = this.serviceProviderSlug;
        return (serviceProviderSlug == null) || (serviceProviderSlug.androidAppAvailable == true);
    }

    public get appleAppAvailable(): boolean {
        const serviceProviderSlug: ServiceProvider | null = this.serviceProviderSlug;
        return (serviceProviderSlug == null) || (serviceProviderSlug.appleAppAvailable == true);
    }

    public get passengerSignUpAvailable(): boolean {
        const serviceProviderSlug: ServiceProvider | null = this.serviceProviderSlug;
        return (serviceProviderSlug == null) || (serviceProviderSlug.passengerSignUpAvailable == true);
    }

    public get captainSignUpAvailable(): boolean {
        const serviceProviderSlug: ServiceProvider | null = this.serviceProviderSlug;
        return (serviceProviderSlug == null) || (serviceProviderSlug.captainSignUpAvailable == true);
    }

    public get userSignUpAvailable(): boolean {
        return ((this.passengerSignUpAvailable) || (this.captainSignUpAvailable));
    }

    public get applicationName(): string {
        const context: ApplicationContext = ch.getContext();
        const serviceProviderSlug: ServiceProvider | null = this.serviceProviderSlug;
        return serviceProviderSlug?.applicationName || context.applicationName;
    }

    public get applicationDefaultLanguage(): string {
        const serviceProviderSlug: ServiceProvider | null = this.serviceProviderSlug;
        return serviceProviderSlug?.language ?? Localizer.defaultLanguage;
    }
    
    public get applicationUrl(): string {
        const context: ApplicationContext = ch.getContext();
        const serviceProviderSlug: ServiceProvider | null = this.serviceProviderSlug;
        
        if (serviceProviderSlug) {
            if (serviceProviderSlug.applicationUrl) {
                return serviceProviderSlug.applicationUrl;
            }
            
            if (serviceProviderSlug.slug) {
                return `${this.trimUrl(context.applicationUrl)}/${this.trimUrl(serviceProviderSlug.slug)}/`;
            }
        }
        
        return context.applicationUrl;
    }

    private static trim(value: string, trimChars: string[] | string): string {
        if (Array.isArray(trimChars)) {
            const trimCharsLength: number = trimChars.length;
            let i: number = 0;
            while(i < trimCharsLength) {
                const sourceLength: number = value.length;
                value = ServiceProviderController.trim(value, trimChars[i]);
                i = (value.length < sourceLength)
                    ? 0
                    : i + 1;
            }
            return value;
        }

        let include: boolean = true;
        const trimLength: number = trimChars.length;
        while (include) {
            include = false;
            const startIndex: number = value.indexOf(trimChars);
            if (startIndex != -1) {
                if (startIndex == 0) {
                    include = true;
                    value = value.substring(trimLength);
                }

                const endIndex: number = value.lastIndexOf(trimChars);
                const length: number = value.length;
                if (endIndex + trimLength == length) {
                    include = true;
                    value = value.substring(0, length - trimLength);
                }
            }
        }
        
        return value;
    }
    
    public trimUrl(url: string): string {
        return ServiceProviderController.trim(url, ['\\', '/', ' ', '\n', '\r']);
    }

    private async resolveSlugAsync(initialize: boolean = false): Promise<void> {
        let origin: string = window.location.origin;
        origin = this.trimUrl(origin.toLowerCase());
        origin = (origin) ? `${origin}/` : "";

        let pathname: string = window.location.pathname;
        pathname = this.trimUrl(pathname.toLowerCase());
        pathname = (pathname) ? `/${pathname}/` : "";

        const serviceProviderId: string | null = this.serviceProviderId;

        let scoped: boolean = false;
        const length: number = this._slugs.length;
        for (let i: number = 0; i < length; i++) {
            const slugServiceProvider: ServiceProvider = this._slugs[i];

            let slug: string = this.trimUrl(slugServiceProvider.slug ?? "").toLowerCase();
            slug = (slug) ? `/${slug}/` : "";

            let applicationUrl: string = this.trimUrl(slugServiceProvider.applicationUrl ?? "").toLowerCase();
            applicationUrl = (applicationUrl) ? `${applicationUrl}/` : "";

            const match: boolean = (
                ((!!slug) && (!!pathname) && ((pathname == slug) || (pathname.startsWith(slug)))) ||
                ((!!applicationUrl) && (!!origin) && (origin == applicationUrl))
            );

            if (match) {
                scoped = true;
                if ((initialize) || (serviceProviderId != slugServiceProvider.id)) {
                    await this.selectCurrentServiceProviderAsync(slugServiceProvider);
                    return;
                }
                break;
            }
        }

        if ((!!serviceProviderId) && (!scoped)) {
            await this.selectCurrentServiceProviderAsync(null);
        }
        return;
    }
    
    public serviceProviderRouteResolver(): PageRoute | null {
        setTimeout(() => this.resolveSlugAsync())
        return null;
    }
}

//Singleton
export default new ServiceProviderController();