import React from "react";
import {GeoCoordinate, Utility} from "@reapptor-apps/reapptor-toolkit";
import {
    BackButtonVisibility,
    BasePageParameters,
    ch,
    IBasePage,
    PageCacheTtl,
    PageRoute,
    PageRouteProvider,
    SwipeDirection
} from "@reapptor-apps/reapptor-react-common";
import {AddressHelper, PageContainer} from "@reapptor-apps/reapptor-react-components";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import MyMap from "@/pages/Mobile/Home/MyMap/MyMap";
import AppConstants from "@/helpers/AppConstants";
import ServicePoint from "@/models/server/bout/ServicePoint";
import NewTrip from "@/pages/Mobile/Home/NewTrip/NewTrip";
import Waypoint from "@/models/server/bout/Waypoint";
import PageDefinitions from "@/providers/PageDefinitions";
import BookingDetailsModal from "@/pages/Mobile/Home/BookingDetailsModal/BookingDetailsModal";
import Booking from "@/models/server/bout/Booking";
import BookingRateModal from "@/pages/Mobile/Home/BookingRateModal/BookingRateModal";
import SetBookingRateRequest from "@/models/server/requests/SetBookingRateRequest";
import UserContext from "@/models/server/UserContext";
import Comparator from "@/helpers/Comparator";
import EstimatedBooking from "@/models/server/bout/EstimatedBooking";
import BookTripRequest from "@/models/server/requests/BookTripRequest";
import BookTripResponse from "@/models/server/responses/BookTripResponse";
import ListMyBookingsRequest from "@/models/server/requests/ListMyBookingsRequest";
import ListMyBookingsResponse from "@/models/server/responses/ListMyBookingsResponse";
import OngoingTrip from "@/pages/Mobile/Home/OngoingTrip/OngoingTrip";
import ListActiveServicePointsRequest from "@/models/server/requests/ListActiveServicePointsRequest";
import ListActiveServicePointsResponse from "@/models/server/responses/ListActiveServicePointsResponse";
import ServiceProviderController from "@/pages/ServiceProviderController";
import PreBookController from "@/pages/PreBookController";
import AppController from "@/pages/AppController";
import Localizer from "../../../localization/Localizer"

import boutStyles from "../../../bout.module.scss";
import styles from "./Home.module.scss";
import {ApplicationType} from "@/models/Enums";

const CurrentLocationAccuracyInMeters: number = 10;
const CurrentLocationRefreshInSeconds: number = 10;

export interface IHomeProps extends BasePageParameters {
    booking?: EstimatedBooking | null;
}

export interface IMobileHomePage extends IBasePage {
    setCaptainLocationAsync: (location: GeoCoordinate | null, hasOngoingTripModified: boolean) => Promise<void>;
}

interface IHomeState {
    mapLocation: GeoCoordinate | null;
    currentLocation: GeoCoordinate | null;
    captainLocation: GeoCoordinate | null;
    ongoingTrip: Booking | null;
    mapZoom: number | null;
    servicePoints: ServicePoint[];
    waypoint: Waypoint | null;
    source: ServicePoint | null;
    destination: ServicePoint | null;
    booking: EstimatedBooking | null;
    activeBookings: Booking[];
    tripRateRequiredBooking: Booking | null,
}

export default class Home extends AuthorizedPage<IHomeProps, IHomeState> implements IMobileHomePage {

    state: IHomeState = {
        currentLocation: null,
        mapLocation: null,
        captainLocation: null,
        ongoingTrip: null,
        mapZoom: null,
        servicePoints: [],
        booking: null,
        activeBookings: [],
        tripRateRequiredBooking: null,
        waypoint: (this.asPassenger) ? PreBookController.waypoint : null,
        source: (this.asPassenger) ? PreBookController.source : null,
        destination: (this.asPassenger) ? PreBookController.destination : null,
    };

    private readonly _myMapRef: React.RefObject<MyMap> = React.createRef();
    private readonly _newTripRef: React.RefObject<NewTrip> = React.createRef();
    private readonly _ongoingTripRef: React.RefObject<OngoingTrip> = React.createRef();
    
    private async getCurrentLocationAsync(): Promise<GeoCoordinate | null> {
        return (!ch.isDevelopmentVS)
            ? await Utility.getLocationAsync({maximumAge: 60000, timeout: 5000})
            : await Utility.getLocationAsync({maximumAge: 60000, timeout: 1000});
    }
    
    private async findTripRateRequiredBookingAsync(): Promise<Booking | null> {
        return ((AppController.tripRateRequired) && (ServiceProviderController.supportsRating))
            ? await this.postAsync("/api/mobileApp/findTripRateRequiredBooking")
            : null;
    }
    
    private async listActiveServicePoints(): Promise<ServicePoint[]> {
        const request = new ListActiveServicePointsRequest();
        
        const response: ListActiveServicePointsResponse = await this.postAsync("/api/mobileApp/listActiveServicePoints", request, PageCacheTtl._1h);
        
        return response.items;
    }

    private async fetchMyActiveBookingsAsync(): Promise<Booking[]> {
        const request = new ListMyBookingsRequest();
        request.asCaptain = AppController.asCaptain;
        request.activeOnly = true;

        const response: ListMyBookingsResponse = await this.postAsync("/api/mobileApp/listMyBookings", request);

        let bookings: Booking[] = response.bookings;
        
        const expiredTripCanBeStartedAfterInHours: number = AppController.settings.expiredTripCanBeStartedAfterInHours;
        
        bookings = bookings.where(item => (!Booking.expired(item, expiredTripCanBeStartedAfterInHours)));
        
        return bookings;
    }
    
    private async clearTripRateRequiredBookingAsync(booking: Booking): Promise<void> {

        const request = new SetBookingRateRequest();
        request.bookingId = booking.id;

        if (this.asCaptain) {
            request.asCaptain = true;
            request.captainRatingPassenger = booking.captainRatingPassenger;
        } else {
            request.asPassenger = true;
            request.passengerRatingBoat = booking.passengerRatingBoat;
            request.passengerRatingCaptain = booking.passengerRatingCaptain;
        }

        await this.postAsync("/api/mobileApp/setBookingRate", request);

        await this.setState({tripRateRequiredBooking: null});
    }

    private async preBookAsync(waypoint: Waypoint): Promise<void> {
        const waypointId: string = waypoint.id;
        const route: PageRoute = PageDefinitions.boatSelection(waypointId);
        await PageRouteProvider.redirectAsync(route);
    }

    private async publishRouteToMapAsync(source: ServicePoint, destination: ServicePoint, waypoint: Waypoint): Promise<void> {
        const myMap: MyMap | null = this._myMapRef.current;

        waypoint.intermediateWaypoints = waypoint.intermediateWaypoints ?? await this.postAsync("/api/mobileApp/getIntermediateWaypoints", waypoint.id);

        const route: GeoCoordinate[] = Waypoint.getRoute(waypoint);

        const mapLocation: GeoCoordinate = AddressHelper.getCenter(route);

        const aspectRate: number = (myMap != null)
            ? myMap.outerWidth() / myMap.outerHeight()
            : 1;

        const mapZoom: number = AddressHelper.findZoom(route, 1.2, aspectRate);

        await this.setState({ source, destination, waypoint, mapLocation, mapZoom });
    }

    private async onChangeTripAsync(trip: NewTrip): Promise<void> {
        const source: ServicePoint | null = trip.source;
        const destination: ServicePoint | null = trip.destination;
        const waypoint: Waypoint | null = trip.waypoint;

        if ((source) && (destination) && (waypoint)) {

            await this.publishRouteToMapAsync(source, destination, waypoint);

        } else if (source != null) {

            await this.setState({source, mapLocation: source.location, waypoint: null, mapZoom: null});

        } else {

            await this.setState({source: null, waypoint: null, mapZoom: null});

        }

        PreBookController.setSource(source);
        PreBookController.setDestination(destination);
        PreBookController.setWaypoint(waypoint);
    }

    private async clearBookingAsync(): Promise<void> {
        await this.setState({booking: null});
        
        await PageRouteProvider.redirectAsync(PageDefinitions.mobileHomeRoute);
    }
    
    private async approveTripAsync(booking: EstimatedBooking): Promise<BookTripResponse> {
        const location: GeoCoordinate | null = await this.getCurrentLocationAsync();
        
        const request = new BookTripRequest(booking, location);
        
        const response: BookTripResponse = await this.postAsync("/api/mobileApp/bookTrip", request);

        PreBookController.clear();
        
        return response;
    }

    private async cancelTripAsync(): Promise<boolean> {
        const confirmed: boolean = await this.confirmAsync(Localizer.mobileBookingDetailsPageCancelTripConfirm);
        
        if (confirmed) {
            await this.clearBookingAsync();

            PreBookController.clear();
        }
        
        return confirmed;
    }

    private async onServicePointDoubleClickAsync(servicePoint: ServicePoint): Promise<void> {
        if (this.asCaptain) {
            await PageRouteProvider.redirectAsync(PageDefinitions.myTripsRoute);
        } else if ((this._newTripRef.current) && (this.waypoint == null)) {
            if (this.source == null) {
                await this._newTripRef.current.setSourceAndDestinationAsync(servicePoint);
            } else {
                await this._newTripRef.current.setSourceAndDestinationAsync(this.source, servicePoint);
            }
        }
    }

    public async onSwipeHandlerAsync(direction: SwipeDirection): Promise<boolean> {
        return false;
    }
    
    public async setCurrentLocationAsync(initialize: boolean = false): Promise<void> {
        if (!initialize) {
            const prevLocation: GeoCoordinate | null = this.currentLocation;
            const currentLocation: GeoCoordinate | null = await this.getCurrentLocationAsync();
            const modified: boolean = (
                (prevLocation != currentLocation) &&
                (
                    (currentLocation == null) ||
                    (prevLocation == null) ||
                    (AddressHelper.distance(currentLocation, prevLocation) >= CurrentLocationAccuracyInMeters / 1000)
                )
            );
            if (modified) {
                await this.setState({currentLocation});
            }
        }
        setTimeout(() => this.setCurrentLocationAsync(), CurrentLocationRefreshInSeconds * 1000);
    }
    
    public async setCaptainLocationAsync(captainLocation: GeoCoordinate | null, hasOngoingTripModified: boolean): Promise<void> {
        const captainLocationModified: boolean = (!Comparator.isEqual(captainLocation, this.state.captainLocation));

        if (captainLocationModified) {
            this.state.captainLocation = captainLocation;
        }

        if (hasOngoingTripModified) {
            const context: UserContext = this.getContext();
            const ongoingTrip: Booking | null = context.onlineData?.ongoingTrip ?? null;

            this.state.ongoingTrip = ongoingTrip;

            if (ongoingTrip) {
                await this._newTripRef.current?.collapseAsync();
            } else {
                await this._newTripRef.current?.expandAsync();

                this.state.tripRateRequiredBooking = await this.findTripRateRequiredBookingAsync();
            }
        }

        if ((captainLocationModified) || (hasOngoingTripModified)) {
            await this.reRenderAsync();
        }
    }

    public getTitle(): string {
        return Localizer.homePageTitle;
    }

    public get asCaptain(): boolean {
        return AppController.asCaptain;
    }

    public get asPassenger(): boolean {
        return AppController.asPassenger;
    }

    public get asInspector(): boolean {
        return AppController.asInspector;
    }

    public get serviceProviderLocation(): GeoCoordinate | null {
        return ServiceProviderController.serviceProviderSlug?.location || null;
    }

    public get mapLocation(): GeoCoordinate {
        return this.state.mapLocation || this.serviceProviderLocation || AppConstants.defaultLocation;
    }

    public get currentLocation(): GeoCoordinate | null {
        return this.state.currentLocation;
    }

    public get captainLocation(): GeoCoordinate | null {
        return this.state.captainLocation;
    }

    public get ongoingTrip(): Booking | null {
        return this.state.ongoingTrip;
    }

    public get mapZoom(): number | null {
        return this.state.mapZoom;
    }

    public get servicePoints(): ServicePoint[] {
        return this.state.servicePoints;
    }

    public get waypoint(): Waypoint | null {
        return this.state.waypoint;
    }

    public get source(): ServicePoint | null {
        return this.state.source;
    }

    public get destination(): ServicePoint | null {
        return this.state.destination;
    }

    public get booking(): EstimatedBooking | null {
        return this.state.booking;
    }

    public get tripRateRequiredBooking(): Booking | null {
        return this.state.tripRateRequiredBooking;
    }

    public get activeBookings(): Booking[] {
        return this.state.activeBookings;
    }

    public get hasBackButton(): BackButtonVisibility {
        return BackButtonVisibility.Hidden;
    }

    public async initializeAsync(): Promise<void> {
        
        if (this.asInspector) {
            await PageRouteProvider.redirectAsync(PageDefinitions.mobileInspectorRoute);
            return;
        }
        
        if ((!this.isRoleSelected) && (this.routeName != PageDefinitions.mobileSelectRoleRouteName)) {
            await PageRouteProvider.redirectAsync(PageDefinitions.mobileSelectRoleRoute, true, true);
        }
        
        await super.initializeAsync();

        const booking: EstimatedBooking | null = ((this.parameters as IHomeProps | null)?.booking || null);

        const [activeBookings, tripRateRequiredBooking] = await Promise.all([
            this.asCaptain ? this.fetchMyActiveBookingsAsync() : [],
            this.findTripRateRequiredBookingAsync()
        ]);

        const context: UserContext = this.getContext();

        let captainLocation: GeoCoordinate | null = null;
        let ongoingTrip: Booking | null = null;

        if ((context.asPassenger) && (context.onlineData != null) && (context.onlineData.hasOngoingTrip)) {
            captainLocation = context.onlineData.captainLocation;
            ongoingTrip = context.onlineData.ongoingTrip;
        }

        if (booking != null) {

            await this.setState({booking, activeBookings, tripRateRequiredBooking, captainLocation, ongoingTrip});

        } else {
            const servicePoints: ServicePoint[] = (this.asPassenger)
                ? await this.listActiveServicePoints()
                : [];

            const currentLocation: GeoCoordinate | null = await this.getCurrentLocationAsync();

            if (currentLocation != null) {
                servicePoints.sortBy((item: ServicePoint) =>
                    ((item.location) && (AddressHelper.getCoordinate(item.location)))
                        ? AddressHelper.distance(currentLocation, item.location)
                        : Number.MAX_VALUE
                );
            }
            
            await this.setState({currentLocation, mapLocation: currentLocation, servicePoints: servicePoints, activeBookings, tripRateRequiredBooking, captainLocation, ongoingTrip});
            
            if ((this.asPassenger) && (this.source) && (this.destination) && (this.waypoint)) {
                await this.publishRouteToMapAsync(this.source, this.destination, this.waypoint);
            }
            
            await this.setCurrentLocationAsync();
        }
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.home, this.mobile && styles.mobile)}
                           alertClassName={this.css(boutStyles.alert)}
            >

                <MyMap ref={this._myMapRef}
                       servicePoints={this.servicePoints}
                       waypoint={this.waypoint}
                       source={this.source}
                       activeBookings={this.activeBookings}
                       mapLocation={this.mapLocation}
                       currentLocation={this.currentLocation}
                       mapZoom={this.mapZoom}
                       captainLocation={this.captainLocation}
                       ongoingTrip={this.ongoingTrip}
                       onServicePointDoubleClick={(_, servicePoint) => this.onServicePointDoubleClickAsync(servicePoint)}
                />

                {
                    (this.asPassenger) &&
                    (
                        <NewTrip ref={this._newTripRef}
                                 applicationType={ApplicationType.Ride}
                                 servicePoints={this.servicePoints}
                                 mapLocation={this.mapLocation}
                                 source={this.source}
                                 destination={this.destination}
                                 expanded={(!this.ongoingTrip)}
                                 onChange={(sender) => this.onChangeTripAsync(sender)}
                                 preBook={(_, waypoint) => this.preBookAsync(waypoint)}
                        />
                    )
                }

                {
                    ((this.asPassenger) && (this.ongoingTrip)) &&
                    (
                        <OngoingTrip ref={this._ongoingTripRef}
                                     booking={this.ongoingTrip}
                                     captainLocation={this.captainLocation}
                                     passengerLocation={this.currentLocation}
                        />
                    )
                }
                
                {
                    (this.booking) &&
                    (
                        <BookingDetailsModal booking={this.booking}
                                             approveTrip={() => this.approveTripAsync(this.booking!)}
                                             cancelTrip={() => this.cancelTripAsync()}
                                             onClose={() => this.clearBookingAsync()}
                        />
                    )
                }

                {
                    ((!this.booking) && (this.tripRateRequiredBooking) && (ServiceProviderController.supportsRating)) &&
                    (
                        <BookingRateModal asCaptain={this.asCaptain}
                                          booking={this.tripRateRequiredBooking}
                                          onClose={(sender, booking) => this.clearTripRateRequiredBookingAsync(booking)}
                        />
                    )
                }

            </PageContainer>
        );
    }
}