import React, {RefObject} from "react";
import {BasePageParameters,     TextAlign} from "@reapptor-apps/reapptor-react-common";
import {
    CellModel,
    ColumnDefinition,
    ColumnType,
    Grid,
    GridHoveringType, GridOddType, PageContainer, PageHeader, PageRow,
    ToolbarContainer,
} from "@reapptor-apps/reapptor-react-components";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import Boat from "@/models/server/bout/Boat";
import {BoatType} from "@/models/Enums";
import ListBoatsRequest from "@/models/server/requests/ListBoatsRequest";
import ListBoatsResponse from "@/models/server/responses/ListBoatsResponse";
import AppConstants from "@/helpers/AppConstants";
import Localizer from "@/localization/Localizer";

import styles from "./Boats.module.scss";

export interface IBoatsProps extends BasePageParameters {
}

interface IBoatsState {
}

export default class Boats extends AuthorizedPage<IBoatsProps, IBoatsState> {

    state: IBoatsState = {
    };

    private readonly _boatsGridRef: RefObject<Grid<Boat>> = React.createRef();

    private readonly _boatColumns: ColumnDefinition[] = [
        {
            header: Localizer.boatsPanelGridBrandLanguageItemName,
            accessor: nameof<Boat>(o => o.brand),
            editable: false,
            minWidth: 200,
            //stretch: true,
            settings: {
                infoAccessor: nameof<Boat>(o => o.model),
            }
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridCaptainLanguageItemName,
            accessor: nameof<Boat>(o => o.captain),
            editable: false,
            minWidth: 250,
            settings: {
                infoAccessor: nameof.full<Boat>(o => o.captain!.email),
            },
            init: (cell: CellModel<Boat>) => this.initCaptainColumn(cell),
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridCapacityLanguageItemName,
            accessor: nameof<Boat>(o => o.maxCapacity),
            editable: false,
            minWidth: 75,
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridHorsePowerLanguageItemName,
            accessor: nameof<Boat>(o => o.horsepower),
            format: "0",
            editable: true,
            type: ColumnType.Number,
            settings: {
                min: 0,
                max: 999,
                hideZero: true
            },
            minWidth: 75,
            maxWidth: 75
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridCruiseSpeedLanguageItemName,
            accessor: nameof<Boat>(o => o.cruiseSpeed),
            format: "0",
            editable: true,
            type: ColumnType.Number,
            settings: {
                min: 0,
                max: AppConstants.boatMaxCapacity,
                hideZero: true
            },
            minWidth: 75,
            maxWidth: 75
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridIsSpeedLanguageItemName,
            accessor: (model: Boat) => model.isSpeedBoat ? "✓" : "-",
            textAlign: TextAlign.Center,
            editable: false,
            minWidth: 50,
            maxWidth: 50
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridAreaLanguageItemName,
            accessor: nameof.full<Boat>(o => o.area!.name),
            editable: false,
            minWidth: 150,
            settings: {
                infoAccessor: nameof.full<Boat>(o => o.boatType),
                infoFormat: nameof(BoatType),
            }
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridMarginLanguageItemName,
            accessor: (model: Boat) => model.margin?.toString() ?? "-",
            textAlign: TextAlign.Center,
            editable: false,
            minWidth: 75,
            maxWidth: 75
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridRatingLanguageItemName,
            accessor: (model: Boat) => (model.rating) ? model.rating : "-",
            editable: false,
            textAlign: TextAlign.Center,
            minWidth: 75,
            maxWidth: 75
        } as ColumnDefinition,
    ];
    
    private async fetchBoatsAsync(): Promise<Boat[]> {
        const request = new ListBoatsRequest();

        const response: ListBoatsResponse = await this.postAsync("/api/boats/listBoats", request);

        return response.boats ?? [];
    }

    private initCaptainColumn(cell: CellModel<Boat>): void {
        const testUser: boolean = cell.model.captain?.testUser ?? false;
        cell.className = this.cssIf(cell.className, testUser, styles.testUser);
    }

    public render(): React.ReactNode {
        return (
            <PageContainer fullWidth fullHeight className={styles.boats}>

                <PageHeader title={Localizer.boatsPageTitle} subtitle={Localizer.boatsPageSubtitle}/>

                <PageRow>

                    <div className="col">

                        <ToolbarContainer>
                        </ToolbarContainer>
        
                        <Grid ref={this._boatsGridRef}
                              className={styles.boatsGrid}
                              hovering={GridHoveringType.Row}
                              odd={GridOddType.None}
                              minWidth="auto"
                              noDataText={Localizer.genericNoData}
                              columns={this._boatColumns}
                              fetchData={() => this.fetchBoatsAsync()}
                        />

                    </div>
                    
                </PageRow>
            
            </PageContainer>
        );
    }
}