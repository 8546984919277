import React from "react";
import {BasePageParameters, LdData, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import AnonymousPage from "../../models/base/AnonymousPage";
import {PageContainer, PageHeader, PageRow, LdDataHeader} from "@reapptor-apps/reapptor-react-components";
import ServiceProviderController from "@/pages/ServiceProviderController";
import AppController from "@/pages/AppController";
import Localizer from "../../localization/Localizer";

import styles from "./PrivacyPolicy.module.scss";

export interface IPrivacyPolicyParameters extends BasePageParameters {
}

interface IPrivacyPolicyState {
    applicationLdData: LdData | null;
}

export default class PrivacyPolicy extends AnonymousPage<IPrivacyPolicyParameters> {

    state: IPrivacyPolicyState = {
        applicationLdData: null,
    };
    
    public getTitle(): string {
        return AppController.getFullPageTitle(Localizer.privacyPolicyPageTitle);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const pageUrl: string = window.location.origin;

        const applicationLdData: LdData = await this.postAsync("/api/application/getApplicationLdData", pageUrl);

        await this.setState({applicationLdData});
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.privacyPolicy}>

                <LdDataHeader data={this.state.applicationLdData}
                              canonical={"/privacy"}
                              defaultLanguage={ServiceProviderController.applicationDefaultLanguage}
                />

                <PageHeader title={Localizer.privacyPolicyPageTitle} 
                            subtitle={ServiceProviderController.privacyNoticeTitle}
                />

                <PageRow>
                    <div className="col-lg-12">
                        
                        <span className={styles.container}>
                            {ReactUtility.toMultiLines(ServiceProviderController.privacyNoticeContent)}
                        </span>

                    </div>
                </PageRow>
                
            </PageContainer>
        );
    }
}