// autogenerated
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class ListPassengersOnBoardRequest extends ETagRequest {

    public shuttleLegInstanceId: string | null = null;

    public readonly isListPassengersOnBoardRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ListPassengersOnBoardRequest) || ((from as any).isListPassengersOnBoardRequest === true));
    }

    public static as(from?: object | null): ListPassengersOnBoardRequest | null {
        return (ListPassengersOnBoardRequest.is(from)) ? from as ListPassengersOnBoardRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}