import React from "react";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import {ActionType, BaseComponent, ch} from "@reapptor-apps/reapptor-react-common";
import {CellAction, CellModel, ColumnActionDefinition, ColumnDefinition, ColumnType, Grid, GridHoveringType, GridModel, GridOddType, RowModel} from "@reapptor-apps/reapptor-react-components";
import Area from "@/models/server/bout/Area";
import SaveAreaRequest from "@/models/server/requests/SaveAreaRequest";
import SaveAreaResponse from "@/models/server/responses/SaveAreaResponse";
import Localizer from "../../../localization/Localizer";

import styles from "./AreasPanel.module.scss";

interface IAreasPanelProps {
    countryId: string;
}

interface IAreasPanelState {
}

export default class AreasPanel extends BaseComponent<IAreasPanelProps, IAreasPanelState> {

    state: IAreasPanelState = {
    };

    private readonly _areasGridRef: React.RefObject<Grid<Area>> = React.createRef();

    private readonly _areasColumns: ColumnDefinition[] = [
        {
            header: Localizer.areasPanelGridNameLanguageItemName,
            name: "name",
            accessor: nameof<Area>(o => o.name),
            minWidth: 350,
            maxWidth: 350,
            type: ColumnType.Text
        } as ColumnDefinition,
        {
            header: Localizer.areasPanelGridCancelPriceLanguageItemName,
            name: "cancelPrice",
            accessor: nameof<Area>(o => o.cancelPrice),
            minWidth: 180,
            maxWidth: 180,
            type: ColumnType.Number
        } as ColumnDefinition,
        {
            header: Localizer.areasPanelGridCancelHoursLanguageItemName,
            name: "cancelHours",
            accessor: nameof<Area>(o => o.cancelHours),
            minWidth: 180,
            maxWidth: 180,
            type: ColumnType.Number
        } as ColumnDefinition,
        {
            header: Localizer.areasPanelGridMarginLanguageItemName,
            name: "margin",
            accessor: nameof<Area>(o => o.margin),
            minWidth: 200,
            maxWidth: 200,
            type: ColumnType.Number,
            settings: {
                min: 0,
                max: 100,
                step: 0.1,
            }
        } as ColumnDefinition,
        {
            header: Localizer.areasPanelGridActionsLanguageItemName,
            minWidth: 140,
            removable: false,
            init: (cell) => this.initAreaOperations(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.genericSave,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processAreaOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "cancel",
                    title: Localizer.genericCancel,
                    icon: "far ban",
                    type: ActionType.Delete,
                    callback: async (cell, action) => await this.processAreaOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "delete",
                    title: Localizer.genericDelete,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    confirm: Localizer.areasPanelGridActionsDeleteConfirm,
                    callback: async (cell, action) => await this.processAreaOperationAsync(cell, action)
                } as ColumnActionDefinition
            ]
        } as ColumnDefinition,
    ];

    private async getAreasAsync(): Promise<Area[]> {
        return await this.areasGrid.postAsync("/api/country/getCountryAreas", this.countryId);
    }

    private isValid(model: Area): boolean {
        let isValid = (!!model.name);
        isValid = isValid && (!this.areasGrid.items.some(item => ((item.id != model.id) && (item.name == model.name))));
        return isValid;
    }

    private initAreaOperations(cell: CellModel<Area>): void {
        const model: Area = cell.row.model;
        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;

        const isValid: boolean = this.isValid(model);

        const saveAction: CellAction<Area> = cell.actions[0];
        const cancelAction: CellAction<Area> = cell.actions[1];
        const deleteAction: CellAction<Area> = cell.actions[2];

        saveAction.visible = (modified) && (isValid) && (!deleted);
        cancelAction.visible = (modified) && (!deleted);
        deleteAction.visible = (!deleted);
    }

    private async processAreaOperationAsync(cell: CellModel<Area>, action: CellAction<Area>): Promise<void> {
        const model: Area = cell.model;
        const isNew: boolean = (!model.id);

        if (action.action.name === "save") {

            const saveAreaRequest = new SaveAreaRequest();
            saveAreaRequest.id = model.id;
            saveAreaRequest.countryId = this.countryId;
            saveAreaRequest.name = model.name!;
            saveAreaRequest.cancelHours = model.cancelHours;
            saveAreaRequest.cancelPrice = model.cancelPrice;
            saveAreaRequest.margin = model.margin;
            
            const response: SaveAreaResponse = await cell.grid.postAsync("/api/country/saveArea", saveAreaRequest);
            
            if (response.alreadyExists) {
                await ch.alertErrorAsync(Utility.format(Localizer.areasPanelAlertErrorAreaAlreadyExist, model.name));
                return;
            }

            cell.row.model = response.area!;

            await cell.row.bindAsync();

        } else if (action.action.name === "cancel") {

            await cell.row.cancelAsync();

        } else if (action.action.name === "delete") {
            
            if (isNew) {
                await cell.grid.deleteAsync(cell.row.index);
            } else {
                const deleted: boolean = await cell.grid.postAsync("/api/country/deleteArea", model.id);
                
                if (!deleted) {
                    await ch.alertErrorAsync(Utility.format(Localizer.areasPanelAlertErrorAreaCannotDeleted, model.name));
                    return;
                }
                
                await cell.grid.reloadAsync();
            }
        }
    }

    private get countryId(): string  {
        return this.props.countryId;
    }

    public get areasGrid(): GridModel<Area> {
        return this._areasGridRef.current!.model;
    }
    
    public async reloadAsync(): Promise<void> {
        await this.areasGrid.reloadAsync();
    }

    public async addAreaAsync(): Promise<void> {
        const defaultMargin: number = await this.getAsync("/api/admin/getDefaultMargin");
        
        const area = new Area();
        area.countryId = this.countryId;
        area.margin = defaultMargin;
        area.cancelPrice = 90;
        area.cancelHours = 24;
        
        const newRows: RowModel<Area>[] = await this.areasGrid.insertAsync(0, area);

        const newRow: RowModel<Area> = newRows[0];
        const nameCell: CellModel<Area> = newRow.get("name");

        await nameCell.editAsync(true);
    }

    public render(): React.ReactNode {
        return (
            <React.Fragment>

                <div className={styles.areasPanel}>
    
                    <Grid id={"areas"}
                          ref={this._areasGridRef} autoToggle
                          className={this.css(styles.grid)}
                          hovering={GridHoveringType.Row}
                          odd={GridOddType.None}
                          minWidth="auto"
                          noDataText={Localizer.genericNoData}
                          columns={this._areasColumns}
                          fetchData={() => this.getAreasAsync()}
                    />
                    
                </div>

            </React.Fragment>
        );
    }
}