import React from "react";
import {ActionType, BaseComponent, ch, IBaseComponent, TextAlign} from "@reapptor-apps/reapptor-react-common";
import {
    Button,
    ButtonType,
    CellAction,
    CellModel,
    ColumnActionDefinition,
    ColumnDefinition,
    ColumnType,
    Dropdown,
    DropdownAlign,
    DropdownOrderBy,
    FileInput,
    Grid,
    GridHoveringType,
    GridModel,
    GridOddType,
    IconSize,
    JustifyContent,
    TextInput,
    ToolbarButton,
    ToolbarContainer,
    ToolbarRow
} from "@reapptor-apps/reapptor-react-components";
import ServicePoint from "@/models/server/bout/ServicePoint";
import ListServicePointsRequest from "@/models/server/requests/ListServicePointsRequest";
import ServicePointsModal from "@/pages/ServicePointsManagement/ServicePointsPanel/ServicePointModal/ServicePointsModal";
import {FileModel, GeoLocation, IPagedList, Utility} from "@reapptor-apps/reapptor-toolkit";
import SaveServicePointRequest from "@/models/server/requests/SaveServicePointRequest";
import Area from "@/models/server/bout/Area";
import Country from "@/models/server/bout/Country";
import GenerateWaypointsResponse from "@/models/server/responses/GenerateWaypointsResponse";
import ImportServicePointsRequest from "@/models/server/requests/ImportServicePointsRequest";
import ImportServicePointsResponse from "@/models/server/responses/ImportServicePointsResponse";
import Localizer from "@/localization/Localizer";

import styles from "@/pages/ServicePointsManagement/WayPointsPanel/WayPointsPanel.module.scss";

interface IServicePointsPanelProps {
    areas: Area[] | [];
}

interface IServicePointsPanelState {
    selectedServicePoint: ServicePoint | null;
    selectedArea: Area | null;
    selectedCountry: Country | null;
    showDeleted: boolean;
    search: string;
}

export default class ServicePointsPanel extends BaseComponent<IServicePointsPanelProps, IServicePointsPanelState> {

    state: IServicePointsPanelState = {
        selectedServicePoint: null,
        selectedArea: null,
        selectedCountry: null,
        showDeleted: false,
        search: "",
    };

    private readonly _servicePointsGridRef: React.RefObject<Grid<ServicePoint>> = React.createRef();
    private readonly _modalRef: React.RefObject<ServicePointsModal> = React.createRef();
    private readonly _servicePointsImportFileInputRef: React.RefObject<FileInput> = React.createRef();

    private readonly _servicePointsColumns: ColumnDefinition[] = [
        {
            name: "area",
            header: Localizer.wayPointsPanelGridAreaLanguageItemName,
            accessor: nameof<ServicePoint>(o => o.area),
            minWidth: 160,
            noWrap: true,
            settings: {
                infoAccessor: nameof.full<ServicePoint>(o => o.area!.country!.name),
            }
        } as ColumnDefinition, 
        {
            name: "areaAlias",
            header: Localizer.wayPointsPanelGridAreaAlias,
            accessor: nameof<ServicePoint>(o => o.areaAlias),
            minWidth: 120,
            maxWidth: 120,
        } as ColumnDefinition,
        {
            header: Localizer.servicePointsPanelGridPlaceLanguageItemName,
            accessor: nameof<ServicePoint>(o => o.name),
            minWidth: 250,
            settings: {
                infoAccessor: nameof.full<ServicePoint>(o => o.location)
            },
        } as ColumnDefinition,
        {
            header: Localizer.servicePointsPanelGridLatitudeLanguageItemName,
            accessor: nameof.full<ServicePoint>(o => o.location!.lat),
            maxWidth: 120,
        } as ColumnDefinition,
        {
            header: Localizer.servicePointsPanelGridLongitudeLanguageItemName,
            accessor: nameof.full<ServicePoint>(o => o.location!.lon),
            maxWidth: 120,
        } as ColumnDefinition,
        {
            header: Localizer.servicePointsPanelGridActiveLanguageItemName,
            accessor: nameof<ServicePoint>(o => o.isActive),
            textAlign: TextAlign.Center,
            type: ColumnType.Boolean,
            minWidth: 10,
            maxWidth: 10,
            editable: true,
            callback: (cell) => this.onActiveChangeAsync(cell),
        } as ColumnDefinition,
        {
            header: Localizer.servicePointsPanelGridCreatedAtLanguageItemName,
            accessor: nameof<ServicePoint>(o => o.createdAt),
            minWidth: 110,
            maxWidth: 110,
            noWrap: true,
            format: "D",
        } as ColumnDefinition,
        {
            header: Localizer.servicePointsPanelGridActionsLanguageItemName,
            minWidth: 125,
            maxWidth: 125,
            removable: false,
            stretch: false,
            actions: [
                {
                    name: "delete",
                    title: Localizer.genericDelete,
                    right: true,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    confirm: Localizer.genericDelete,
                    callback: (cell, action) => this.processServicePointOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "edit",
                    title: Localizer.genericEdit,
                    icon: "far edit",
                    right: false,
                    type: ActionType.Edit,
                    callback: (cell, action) => this.processServicePointOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "generate",
                    title: Localizer.wayPointsPanelGridGenerateTitle,
                    icon: "fad fa-route",
                    right: false,
                    type: ActionType.Blue,
                    callback: (cell, action) => this.processServicePointOperationAsync(cell, action)
                } as ColumnActionDefinition,
            ]
        } as ColumnDefinition,
    ];
    
    private async setSearchAsync(search: string): Promise<void> {
        await this.setState({search});
        await this.reloadAsync();
    }
    
    private async onModalSaveAsync(geoLocation: GeoLocation, areaId: string, place: string, areaAlias: string | null, active: boolean): Promise<void> {
        await this.saveServicePointAsync(geoLocation, areaId, place, areaAlias, active);
        await this.reloadAsync();
    }

    private async saveServicePointAsync(geoLocation: GeoLocation, areaId: string, place: string, areaAlias: string | null, active: boolean): Promise<void> {
        const request = new SaveServicePointRequest();

        if (this.state.selectedServicePoint) {
            request.id = this.state.selectedServicePoint.id;
        }
        request.place = place;
        request.areaAlias = areaAlias;
        request.lat = geoLocation.lat;
        request.lon = geoLocation.lon;
        request.active = active;
        request.areaId = areaId;

        await this.postAsync("/api/servicePoints/saveServicePoint", request);
        
        await ch.alertMessageAsync(Localizer.servicePointsPanelAlertErrorSaveServicePoint, true, true);
    }

    private async reloadAsync(): Promise<void> {
        await this.servicePointGrid.reloadAsync();
        await this.reRenderAsync();
    }

    private async openServicePointsImportFileInputAsync(): Promise<void> {
        await this._servicePointsImportFileInputRef.current?.openAsync();
    }

    private async onServicePointsImportAsync(file: FileModel): Promise<void> {
        const request = new ImportServicePointsRequest();
        request.file = file;
        request.overrideExisting = true;

        const response: ImportServicePointsResponse = await this.postAsync("/api/servicePoints/importServicePoints", request);

        if (response.success) {
            await ch.flyoutMessageAsync("Data has been exported successfully!");

            await this.reloadAsync();
        } else {
            await ch.flyoutErrorAsync("An unexpected error occurred. No data has been exported.");
        }
    }

    private async fetchServicePointsAsync(sender: IBaseComponent, pageNumber: number, pageSize: number): Promise<IPagedList<ServicePoint>> {
        const request = new ListServicePointsRequest();
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.search = this.state.search;
        request.countryId = (this.state.selectedCountry) ? this.state.selectedCountry.id : null;
        request.areaId = (this.state.selectedArea) ? this.state.selectedArea.id : null;
        
        return await sender.postAsync("/api/servicePoints/listServicePoints", request);
    }

    private async processServicePointOperationAsync(cell: CellModel<ServicePoint>, action: CellAction<ServicePoint>): Promise<void> {
        const model: ServicePoint = cell.model;

        if (action.action.name === "delete") {
            await this.deleteServicePointAsync(cell, model.id);
        }
        
        if (action.action.name === "edit") {
            await this.setState({selectedServicePoint: model});
            await this.servicePointModal.openAsync(model.location, model.name, model.area, model.areaAlias, model.isActive);
        }
        
        if (action.action.name === "generate") {
            
            const confirmed: boolean = await ch.confirmAsync(Localizer.wayPointsPanelGridGenerateConfirm);
            
            if (confirmed) {
                const response: GenerateWaypointsResponse = await this.postAsync("/api/servicePoints/generateWaypoints", model.id);

                const message: string = (response.background)
                    ? Localizer.wayPointsPanelGridGenerateBackground
                    : Localizer.wayPointsPanelGridGenerateResult.format(response.processedWaypointsCount, response.newWaypointsCount, response.failedWaypointsCount, response.totalMinutes);

                await ch.messageBoxAsync(message);
                
                if (response.newWaypointsCount > 0)
                {
                    await this.reloadAsync();
                }
            }
        }
    }

    private async onModalCloseAsync(): Promise<void> {
        await this.setState({selectedServicePoint: null});
    }

    private async onActiveChangeAsync(cell: CellModel<ServicePoint>): Promise<void> {
        const model: ServicePoint = cell.model;

        await cell.grid.postAsync("/api/servicePoints/setIsActive", model.id);
        
        await this.servicePointGrid.reloadAsync();

        const message: string = (cell.model.isActive)
            ? Localizer.servicePointsPanelAlertErrorStatusActive.format(model.name)
            : Localizer.servicePointsPanelAlertErrorStatusInactive.format(model.name);

        await ch.alertMessageAsync(message, true, true);
    }

    private async deleteServicePointAsync(cell: CellModel<ServicePoint>, id: string): Promise<void> {

        const has: boolean = await cell.grid.postAsync("/api/servicePoints/hasAnyWaypoints", id);
        
        if (has) {
            await ch.alertErrorAsync(Utility.format(Localizer.servicePointsPanelAlertErrorHasAnyWaypoints, cell.model.name), true, true);
        }
        else {
            await cell.grid.postAsync("/api/servicePoints/deleteServicePoint", id);
            
            await ch.alertMessageAsync(Localizer.servicePointsPanelAlertErrorDeleteServicePoint, true, true);
            
            await this.servicePointGrid.deleteAsync(cell.row.index);
        }
    }
    
    private get countries(): Country[] {
        return this.props
            .areas
            .where(item => item.country != null)
            .select(item => item.country!)
            .distinct(item => item.id);
    }
    
    private get areas(): Area[] {
        return (this.state.selectedCountry != null)
            ? this.props.areas.where(item => item.countryId == this.state.selectedCountry!.id)
            : this.props.areas;
    }

    private get servicePointModal(): ServicePointsModal {
        return this._modalRef.current!;
    }

    private get servicePointGrid(): GridModel<ServicePoint> {
        return this._servicePointsGridRef.current!.model;
    }

    private async onCountryChangeAsync(selectedCountry: Country | null): Promise<void> {
        const selectedArea: Area | null = ((selectedCountry == null) || (selectedCountry.id == this.state.selectedArea?.countryId))
            ? this.state.selectedArea
            : null;
        
        await this.setState({selectedCountry, selectedArea});
        
        await this._servicePointsGridRef.current!.reloadAsync();
    }
    
    private async onAreaChangeAsync(selectedArea: Area | null): Promise<void> {
        await this.setState({selectedArea});
        
        await this._servicePointsGridRef.current!.reloadAsync();
    }
    
    public render(): React.ReactNode {
        return (
            <React.Fragment>
                
                <ToolbarContainer>

                    <ToolbarRow justify={JustifyContent.End}>
                        
                        <ToolbarButton icon={{name: "plus", size: IconSize.Large}}
                                       label={Localizer.servicePointsPanelAddServicePointToolbarLabel}
                                       title={Localizer.servicePointsPanelAddServicePointToolbarTitle}
                                       type={ButtonType.Primary}
                                       onClick={() => this.servicePointModal.openAsync()}
                        />

                        <React.Fragment>

                            <FileInput id="spImport"
                                       inline hidden
                                       ref={this._servicePointsImportFileInputRef}
                                       fileTypes={[".xlsx", ".csv"]}
                                       onChange={(_, file: FileModel) => this.onServicePointsImportAsync(file)}
                            />

                            <Button block
                                    label={"Excel"}
                                    icon={{name: "fas fa-file-upload"}}
                                    type={ButtonType.Primary}
                                    onClick={() => this.openServicePointsImportFileInputAsync()}
                            />

                        </React.Fragment>
                        
                    </ToolbarRow>

                    <ToolbarRow justify={JustifyContent.Start}>

                        <Dropdown id="spCountryFilter" noWrap noSubtext clearButton
                                  align={DropdownAlign.Left}
                                  title={Localizer.genericSelectCountry}
                                  minWidth={"210px"}
                                  items={this.countries}
                                  nothingSelectedText={Localizer.genericAllCountries}
                                  selectedItem={this.state.selectedCountry}
                                  onChange={(_, item) => this.onCountryChangeAsync(item!)}
                        />

                        <Dropdown id="spAreaFilter" noWrap noSubtext clearButton
                                  align={DropdownAlign.Left}
                                  title={Localizer.servicePointsPanelSelectAreaDropdownTitle}
                                  minWidth={"210px"}
                                  items={this.areas}
                                  nothingSelectedText={Localizer.genericAllAreas}
                                  selectedItem={this.state.selectedArea}
                                  orderBy={DropdownOrderBy.None}
                                  onChange={(_, item) => this.onAreaChangeAsync(item)}
                        />

                        <TextInput id="spSearchFilter" inline small clearButton
                                   placeholder={Localizer.servicePointsPanelTextInputSearchPlace}
                                   value={this.state.search}
                                   width="22rem"
                                   onChange={(_, value) => this.setSearchAsync(value)}
                        />

                    </ToolbarRow>

                </ToolbarContainer>

                <Grid autoToggle responsive
                      ref={this._servicePointsGridRef}
                      pagination={20}
                      className={this.css(styles.grid)}
                      hovering={GridHoveringType.Row}
                      odd={GridOddType.None}
                      minWidth="auto"
                      noDataText={Localizer.genericNoData}
                      columns={this._servicePointsColumns}
                      fetchData={(sender, pageNumber: number, pageSize: number) => this.fetchServicePointsAsync(sender, pageNumber, pageSize)}
                />

                <ServicePointsModal ref={this._modalRef}
                                    areas={this.props.areas} 
                                    onModalSave={(geoLocation, areaId, place, areaAlias, active) => this.onModalSaveAsync(geoLocation, areaId, place, areaAlias, active)} 
                                    onModalClose={() => this.onModalCloseAsync()}
                />
                
            </React.Fragment>
        );
    }
}