// autogenerated
import {TicketType} from "@/models/Enums";
import Waypoint from "@/models/server/bout/Waypoint";

export default class ShuttleWaypoint extends Waypoint {

    public shuttleId: string = "";

    public ticketTypes: TicketType[] = [];

    public readonly isShuttleWaypoint: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ShuttleWaypoint) || ((from as any).isShuttleWaypoint === true));
    }

    public static as(from?: object | null): ShuttleWaypoint | null {
        return (ShuttleWaypoint.is(from)) ? from as ShuttleWaypoint : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}