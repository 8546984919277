import React from "react";
import {ActionType, BaseComponent, ch} from "@reapptor-apps/reapptor-react-common";
import {
    ButtonType,
    CellAction,
    CellModel,
    ColumnActionDefinition,
    ColumnDefinition,
    ColumnType,
    Form,
    Grid,
    GridHoveringType,
    GridModel,
    GridOddType,
    IconSize,
    Inline,
    JustifyContent,
    NumberInput,
    NumberInputBehaviour,
    TextInput,
    ToolbarButton,
    ToolbarContainer,
} from "@reapptor-apps/reapptor-react-components";
import FuelConsumption from "@/models/server/bout/FuelConsumption";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import CalculateFuelConsumptionRequest from "@/models/server/requests/CalculateFuelConsumptionRequest";
import SaveFuelConsumptionRequest from "@/models/server/requests/SaveFuelConsumptionRequest";
import Localizer from "@/localization/Localizer";

import styles from "./FuelConsumptionPanel.module.scss";

interface IFuelConsumptionPanelProps {
}

interface IFuelConsumptionPanelState {
    fuelConsumption: FuelConsumption[];
    horsepower: number;
    consumption: number;
}

export default class FuelConsumptionPanel extends BaseComponent<IFuelConsumptionPanelProps, IFuelConsumptionPanelState> {

    state: IFuelConsumptionPanelState = {
        fuelConsumption: [],
        horsepower: 0,
        consumption: 0
    };

    private readonly _consumptionGridRef: React.RefObject<Grid<FuelConsumption>> = React.createRef();

    private readonly _consumptionColumns: ColumnDefinition[] = [
        {
            header: "#",
            minWidth: 50,
            noWrap: true,
            className: "grey",
            accessor: "#"
        } as ColumnDefinition,
        {
            header: Localizer.fuelConsumptionPanelHorsepowerLanguageItemName,
            name: "horsePower",
            sorting: true,
            accessor: nameof<FuelConsumption>(o => o.horsePower),
            minWidth: 300,
            maxWidth: 300,
            type: ColumnType.Number,
            init: async (cell) => this.initHorsePowerCell(cell),
        } as ColumnDefinition,
        {
            header: Localizer.fuelConsumptionPanelConsumptionLanguageItemName,
            name: "consumption",
            sorting: true,
            accessor: nameof<FuelConsumption>(o => o.consumption),
            minWidth: 300,
            maxWidth: 300,
            init: async (cell) => this.initConsumptionCell(cell),
            type: ColumnType.Number,
            settings: {
                min: 0,
                max: 999,
                step: 0.1,
            }
        } as ColumnDefinition,
        {
            header: Localizer.genericActions,
            minWidth: 125,
            removable: false,
            init: (cell) => this.initConsumptionOperations(cell),
            actions: [
                {
                    name: "cancel",
                    title: Localizer.genericCancelLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    callback: async (cell, action) => await this.processConsumptionOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "delete",
                    title: Localizer.genericDeleteLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell, action) => await this.processConsumptionOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "restore",
                    title: Localizer.genericRestoreLanguageItemName,
                    icon: "far undo-alt",
                    type: ActionType.Create,
                    right: true,
                    callback: async (cell, action) => await this.processConsumptionOperationAsync(cell, action)
                } as ColumnActionDefinition
            ]
        } as ColumnDefinition,
    ];

    private initHorsePowerCell(cell: CellModel<FuelConsumption>): void {
        const model: FuelConsumption = cell.model;
        const isValid: boolean = (this.isValidHorsePowerValue(model));
        const isNew: boolean = (model.isNew);
        cell.className = this.cssIf(cell.className, !isValid, styles.invalid);
        cell.className = this.cssIf(cell.className, (isValid && isNew), styles.new);
    }

    private initConsumptionCell(cell: CellModel<FuelConsumption>): void {
        const model: FuelConsumption = cell.model;
        const isValid: boolean = (this.isValidConsumptionValue(model));
        const isNew: boolean = (model.isNew);
        cell.className = this.cssIf(cell.className, !isValid, styles.invalid);
        cell.className = this.cssIf(cell.className, (isValid && isNew), styles.new);
    }

    private initConsumptionOperations(cell: CellModel<FuelConsumption>): void {
        const model: FuelConsumption = cell.row.model;
        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;

        const cancelAction: CellAction<FuelConsumption> = cell.actions[0];
        const deleteAction: CellAction<FuelConsumption> = cell.actions[1];
        const restoreAction: CellAction<FuelConsumption> = cell.actions[2];

        cancelAction.visible = (modified) && (!deleted) && (!model.isNew);
        deleteAction.visible = (!deleted);
        restoreAction.visible = (deleted);
    }
    
    private getFuelConsumption(): FuelConsumption[] {
        return this
            .consumptionGrid
            .rows
            .where(row => !row.deleted)
            .map(row => row.model);
    }
    
    private async setHorsepowerAsync(value: number): Promise<void> {
        if (this.state.horsepower != value) {
            this.state.horsepower = value;
            await this.calculateConsumptionAsync();
        }
    }
    
    private async calculateConsumptionAsync(): Promise<void> {
        const request = new CalculateFuelConsumptionRequest();

        request.fuelConsumptions = this.getFuelConsumption();
        request.horsepower = this.state.horsepower;

        const consumption: number = await this.postAsync("/api/fuelConsumption/calculateFuelConsumption", request);
        
        await this.setState({ consumption });
    }

    public get consumptionGrid(): GridModel<FuelConsumption> {
        return this._consumptionGridRef.current!.model;
    }

    public async reloadAsync(): Promise<void> {
        await this.consumptionGrid.reloadAsync();
        await this.reRenderAsync();
    }

    public async addConsumptionAsync(): Promise<void> {
        const model = new FuelConsumption();
        model.isNew = true;
        model.id = Utility.newGuid();

        await this.consumptionGrid.addAsync(model);

        const nameCell: CellModel<FuelConsumption> = this.consumptionGrid.lastRow.get("horsePower");
        
        await nameCell.editAsync(true);
    }
    
    private async cancelAsync(): Promise<void> {
        await this.reloadAsync();
    }

    private async saveFuelConsumptionsAsync(): Promise<void> {
        if (this.isValidData()) {

            const request = new SaveFuelConsumptionRequest();
            
            request.fuelConsumptions = this.getFuelConsumption();

            await this.consumptionGrid.postAsync("/api/fuelConsumption/SaveFuelConsumption", request);

            await ch.alertMessageAsync(Localizer.fuelConsumptionPanelSuccessfullyCreatedConsumption, true, true);
            
            await this.reloadAsync();
        } else {
            await ch.alertErrorAsync(Localizer.fuelConsumptionPanelAddRequired, true, true);
        }
    }

    private async processConsumptionOperationAsync(cell: CellModel<FuelConsumption>, action: CellAction<FuelConsumption>): Promise<void> {
        if (action.action.name === "cancel") {
            await cell.row.cancelAsync();
        } else if (action.action.name === "delete") {
            if (cell.model.isNew) {
                await cell.grid.deleteAsync(cell.rowIndex);
            } else {
                await this.setDeletedAsync(cell,true);
                await cell.grid.reRenderAsync();
            }
        } else if (action.action.name === "restore") {
            await this.setDeletedAsync(cell,false);
            await cell.grid.reRenderAsync();
        }
    }
    
    private async setDeletedAsync(cell: CellModel<FuelConsumption>, value: boolean) {
        const model: FuelConsumption = cell.model;
        model.markedAsDeleted = true;

        await cell.row.setDeletedAsync(value);
    }

    private isValidData(): boolean {
        return ((this._consumptionGridRef.current != null) && (
            (this.consumptionGrid.rows.length == 0) ||
            (this.consumptionGrid.rows.every(row => (row.model.markedAsDeleted) || (this.isValidFuelConsumption(row.model))))
        ));
    }
    
    private isValidFuelConsumption(consumption: FuelConsumption): boolean {
        return (this.isValidHorsePowerValue(consumption) && (this.isValidConsumptionValue(consumption)));
    }

    private isValidHorsePowerValue(model: FuelConsumption): boolean {
        let isValid = (model.horsePower > 0);

        isValid = isValid && (!this.consumptionGrid.rows.any(item => (item.model.id != model.id) && (item.model.horsePower == model.horsePower)));

        return isValid;
    }

    private isValidConsumptionValue(model: FuelConsumption): boolean {
        return (model.consumption > 0);
    }

    private async listFuelConsumptionAsync(): Promise<FuelConsumption[]> {
        const fuelConsumption: FuelConsumption[] = await this.getAsync("/api/fuelConsumption/listFuelConsumption");

        await this.setState({fuelConsumption});

        return fuelConsumption;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.listFuelConsumptionAsync();
    }

    public render(): React.ReactNode {
        return (
            <div className={styles.fuelConsumptionPanel}>

                <ToolbarContainer>

                    <Inline className={styles.toolbar} justify={JustifyContent.Start}>

                        <ToolbarButton icon={{name: "save", size: IconSize.Large}}
                                       label={Localizer.genericSave}
                                       type={ButtonType.Primary}
                                       onClick={() => this.saveFuelConsumptionsAsync()}
                        />

                        <ToolbarButton icon={{name: "far ban", size: IconSize.Large}}
                                       label={Localizer.genericCancel}
                                       type={ButtonType.Light}
                                       onClick={() => this.cancelAsync()}
                        />

                        <ToolbarButton icon={{name: "plus", size: IconSize.Large}}
                                       label={Localizer.genericAdd}
                                       type={ButtonType.Orange}
                                       onClick={() => this.addConsumptionAsync()}
                        />

                    </Inline>

                </ToolbarContainer>

                <Grid ref={this._consumptionGridRef} autoToggle
                      className={this.css(styles.grid)}
                      hovering={GridHoveringType.Row}
                      odd={GridOddType.None}
                      minWidth="auto"
                      noDataText={Localizer.genericNoData}
                      columns={this._consumptionColumns}
                      fetchData={() => this.listFuelConsumptionAsync()}
                />
                
                <Form>
                    
                    <Inline>
                        
                        <NumberInput id="horspower" hideArrows hideZero inline
                                     behaviour={NumberInputBehaviour.ValidationOnChange}
                                     min={0}
                                     max={1000}
                                     step={1}
                                     placeholder={Localizer.fuelConsumptionPanelHorsepower}
                                     value={this.state.horsepower}
                                     onChange={(_, value) => this.setHorsepowerAsync(value)}
                        />
                        
                        <TextInput id="fuelConsumption" inline readonly
                                   value={this.state.consumption.format("0.00")}
                        />
                        
                    </Inline>
                    
                </Form>

            </div>
        );
    }
}