import React from "react";
import {BaseComponent, TextAlign} from "@reapptor-apps/reapptor-react-common";
import Booking from "@/models/server/bout/Booking";
import CruisePackageBooking from "@/models/server/cruise/CruisePackageBooking";
import {Dropdown, DropdownOrderBy, DropdownRequiredType, SelectListItem} from "@reapptor-apps/reapptor-react-components";
import Boat from "@/models/server/bout/Boat";
import Localizer from "@/localization/Localizer";

import styles from "./PassengersInfo.module.scss";
import AppConstants from "@/helpers/AppConstants";

export interface IPassengersInfoProps {
    readonly?: boolean;
    booking: CruisePackageBooking;
    onChange: (sender: PassengersInfo) => Promise<void>;
}

interface IPassengersInfoState {
}

export default class PassengersInfo extends BaseComponent<IPassengersInfoProps, IPassengersInfoState> {

    state: IPassengersInfoState = {
    };
    
    private adultsCountToSelectListItem(count: number): SelectListItem {
        const text: string = Localizer.mobilePassengersInfoAdults.format(count);
        return new SelectListItem(count.toString(), text);
    }
    
    private childrenCountToSelectListItem(count: number): SelectListItem {
        const text: string = (count == 0) 
            ? Localizer.mobilePassengersInfoNoChildren 
            : Localizer.mobilePassengersInfoChildrenCount.format(count);
        
        return new SelectListItem(count.toString(), text);
    }
    
    private async setAdultsCountAsync(value: number): Promise<void> {
        CruisePackageBooking.setAdults(this.item, value, this.maxPassengers);

        await this.props.onChange(this);
        
        await this.reRenderAsync();
    }
    
    private async setChildrenCountAsync(value: number): Promise<void> {
        CruisePackageBooking.setChildren(this.item, value);

        await this.props.onChange(this);
    }

    public get item(): CruisePackageBooking {
        return this.props.booking;
    }

    public get booking(): Booking | null {
        return Booking.as(this.item);
    }

    public get cruisePackageBooking(): CruisePackageBooking | null {
        return CruisePackageBooking.as(this.item);
    }

    public get adultsCount(): number {
        return (this.item.passengers - this.item.children);
    }

    public get childrenCount(): number {
        return this.item.children;
    }

    public get adultsItems(): SelectListItem[] {
        return Array.from({length: this.maxAdults}, (_, index) => this.adultsCountToSelectListItem(index + 1));
    }
    
    public get childrenItems(): SelectListItem[] {
        return Array.from({length: this.maxChildren + 1}, (_, index) => this.childrenCountToSelectListItem(index));
    }

    public get maxPassengers(): number {
        return this.cruisePackageBooking?.cruisePackage?.maxCapacity ?? this.boat?.maxCapacity ?? AppConstants.boatMaxCapacity;
    }

    public get maxAdults(): number {
        return (this.maxPassengers);
    }

    public get maxChildren(): number {
        return (this.maxPassengers - this.adultsCount);
    }

    public get boat(): Boat | null {
        return this.booking?.boat ?? this.cruisePackageBooking?.cruisePackage?.boat ?? null;
    }

    public render(): React.ReactNode {

        return (
            <div className={styles.passengersInfo}>

                <span>{Localizer.mobilePassengersInfoSpanPassengers.format(this.maxPassengers)}</span>

                <div className={styles.context}>

                    <Dropdown id={"adults"} required filterAutoFocus
                              filterMinLength={10}
                              textAlign={TextAlign.Center}
                              disabled={this.props.readonly}
                              items={this.adultsItems}
                              selectedItem={this.adultsCount}
                              orderBy={DropdownOrderBy.None}
                              onChange={(_, item) => this.setAdultsCountAsync(parseInt(item!.value))}
                    />

                    <Dropdown id={"children"} required noFilter
                              requiredType={DropdownRequiredType.Restricted}
                              textAlign={TextAlign.Center}
                              disabled={(this.props.readonly) || (this.childrenItems.length <= 1)}
                              items={this.childrenItems}
                              selectedItem={this.childrenCount}
                              orderBy={DropdownOrderBy.None}
                              nothingSelectedText={Localizer.mobilePassengersInfoDropdownNoChildren}
                              onChange={(_, item) => this.setChildrenCountAsync(parseInt(item!.value))}
                    />
                    
                </div>

            </div>
        );
    }
}