import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import PageDefinitions from "@/providers/PageDefinitions";
import {
    PageContainer,
    WidgetContainer,
    RouteWidget,
    DropdownWidget,
    NumberWidget
} from "@reapptor-apps/reapptor-react-components";
import InfoWidget from "@/pages/Dashboard/Info/InfoWidget";
import FilterByAreaRequest from "@/models/server/requests/FilterByAreaRequest";
import UserDashboardInfoRequest from "@/models/server/requests/UserDashboardInfoRequest";
import UserDashboardInfoResponse from "@/models/server/responses/UserDashboardInfoResponse";
import BookingsDashboardInfoRequest from "@/models/server/requests/BookingsDashboardInfoRequest";
import BookingsDashboardInfoResponse from "@/models/server/responses/BookingsDashboardInfoResponse";
import AppConstants from "@/helpers/AppConstants";
import {ServicePointsTab} from "@/pages/ServicePointsManagement/ServicePointsManagement";
import Area from "@/models/server/bout/Area";
import UserContext from "@/models/server/UserContext";
import {IBaseComponent, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import ServiceProviderController from "@/pages/ServiceProviderController";
import AppController from "@/pages/AppController";
import Localizer from "../../localization/Localizer";

import boatStyles from "../../bout.module.scss";
import styles from "./Dashboard.module.scss";

const REFRESH_DATA_TIMEOUT_IN_SECONDS: number = 120;

interface IDashboardProps {
    name: string | null;
}

interface IDashboardState {
    areas: Area[],
    selectedAreas: Area[],
    waypointsCount: number,
    servicePointsCount: number,
    bookingsCount: number,
    totalCaptains: number,
    onlineCaptains: number,
    totalPassengers: number,
    onlinePassengers: number,
    totalActiveCaptains: number,
    percentageActiveCaptains: number,
    totalActivePassengers: number,
    percentageActivePassengers: number,
    visitorsCount: number,
    newPassengersCount: number,
    rideCompletedInLastDay: number,
    newInLastDay: number,
    acceptedByCaptainInLastDay: number,
    cancelledByCaptainInLastDay: number,
    cancelledByPassengerInLastDay: number
    rideCompletedInLastWeek: number,
    newInLastWeek: number,
    acceptedByCaptainInLastWeek: number,
    cancelledByCaptainInLastWeek: number,
    cancelledByPassengerInLastWeek: number
}

export default class Dashboard extends AuthorizedPage<IDashboardProps, IDashboardState> {

    state: IDashboardState = {
        areas: [],
        selectedAreas: [],
        waypointsCount: 0,
        servicePointsCount: 0,
        bookingsCount: 0,
        totalCaptains: 0,
        onlineCaptains: 0,
        totalPassengers: 0,
        onlinePassengers: 0,
        totalActiveCaptains: 0,
        percentageActiveCaptains: 0,
        totalActivePassengers: 0,
        percentageActivePassengers: 0,
        visitorsCount: 0,
        newPassengersCount: 0,
        rideCompletedInLastDay: 0,
        newInLastDay: 0,
        acceptedByCaptainInLastDay: 0,
        cancelledByCaptainInLastDay: 0,
        cancelledByPassengerInLastDay: 0,
        rideCompletedInLastWeek: 0,
        newInLastWeek: 0,
        acceptedByCaptainInLastWeek: 0,
        cancelledByCaptainInLastWeek: 0,
        cancelledByPassengerInLastWeek: 0
    }
    
    private readonly _areasWidgetRef: React.RefObject<DropdownWidget<Area>> = React.createRef();
    private readonly _bookingsCountWidgetRef: React.RefObject<RouteWidget> = React.createRef();
    private readonly _bookingInfoLastDayWidgetRef: React.RefObject<InfoWidget> = React.createRef();
    private readonly _bookingInfoLastWeekWidgetRef: React.RefObject<InfoWidget> = React.createRef();
    private readonly _captainPercentageWidgetRef: React.RefObject<InfoWidget> = React.createRef();
    private readonly _passengerPercentageWidgetRef: React.RefObject<InfoWidget> = React.createRef();
    private readonly _visitorsCountWidgetRef: React.RefObject<NumberWidget> = React.createRef();
    private readonly _newPassengersCountWidgetRef: React.RefObject<NumberWidget> = React.createRef();

    private get asAdmin(): boolean {
        return AppController.asAdmin;
    }

    private get asServiceProviderManager(): boolean {
        return AppController.asServiceProviderManager;
    }

    private get waypointsCountLabel(): string {
        return this.state.waypointsCount.toString();
    }

    private get servicePointsCountLabel(): string {
        return this.state.servicePointsCount.toString();
    }

    private get bookingsCountLabel(): string {
        return this.state.bookingsCount.toString();
    }

    private get captainsCountLabel(): string {
        return this.state.totalCaptains.toString();
    }

    private get passengersCountLabel(): string {
        return this.state.totalPassengers.toString();
    }

    private get visitorsCountLabel(): string {
        return this.state.visitorsCount.toString();
    }

    private get newPassengersCountLabel(): string {
        return this.state.newPassengersCount.toString();
    }

    private get selectedAreas(): Area[] {
        return this.state.selectedAreas;
    }
    
    private async fetchDataAsync(initialize: boolean = false, areaSpecific: boolean = false): Promise<void> {

        if (!this.isMounted) {
            return;
        }
        
        areaSpecific = areaSpecific || initialize;
        
        if (this.asAdmin) {
            await Promise.all([
                initialize && await this.fetchAreasAsync(),
                areaSpecific && await this.fetchServicePointsAsync(),
                areaSpecific && await this.fetchWaypointsAsync(),
                areaSpecific && await this.fetchBookingsAsync(),
                areaSpecific && await this.fetchBookingsInfoForLastDayAsync(),
                areaSpecific && await this.fetchBookingsInfoForLastWeekAsync(),
                await this.fetchPassengersCountsAsync(),
                await this.fetchCaptainsCountAsync(),
                await this.fetchActiveCaptainsInfoAsync(),
                await this.fetchActivePassengersInfoAsync(),
                await this.fetchVisitorsCountAsync(),
                await this.fetchNewPassengersCountAsync(),
            ]);
        }

        if (this.asServiceProviderManager) {
            await Promise.all([
                initialize && await this.fetchBookingsAsync(),
                areaSpecific && await this.fetchBookingsInfoForLastDayAsync(),
                areaSpecific && await this.fetchBookingsInfoForLastWeekAsync(),
                await this.fetchActiveCaptainsInfoAsync(),
                await this.fetchActivePassengersInfoAsync(),
                await this.fetchVisitorsCountAsync(),
                await this.fetchNewPassengersCountAsync(),
            ]);
        }
        
        if ((initialize) || (!areaSpecific)) {
            setTimeout(async () => this.fetchDataAsync(), 1000 * REFRESH_DATA_TIMEOUT_IN_SECONDS);
        }
    }

    private async fetchAreasAsync(): Promise<void> {
        const sender: IBaseComponent = this._areasWidgetRef.current || this;
        
        const areas: Area[] = await AppController.listAreasAsync(ServiceProviderController.serviceProviderId, null, null, null, sender);

        await this.setState({areas});
    }

    private buildFilterByAreaRequest(): FilterByAreaRequest {
        const areasIds: string[] = this.selectedAreas.map(area => area.id);
        return new FilterByAreaRequest(areasIds);
    }

    private async fetchBookingsAsync(): Promise<void> {
        const sender: IBaseComponent = this._bookingsCountWidgetRef.current || this;
        const bookingsCount: number = await sender.postAsync("/api/Dashboard/getBookingsCountData", this.buildFilterByAreaRequest());
        await this.setState({bookingsCount});
    }

    private async fetchWaypointsAsync(): Promise<void> {
        const waypointsCount: number = await this.postAsync("/api/Dashboard/getWaypointsCountData", this.buildFilterByAreaRequest())
        await this.setState({waypointsCount});
    }

    private async fetchServicePointsAsync(): Promise<void> {
        const servicePointsCount: number = await this.postAsync("/api/Dashboard/getServicePointsCountData", this.buildFilterByAreaRequest())
        await this.setState({servicePointsCount});
    }

    private async fetchCaptainsCountAsync(): Promise<void> {
        const totalCaptains: number = await this.postAsync("/api/Dashboard/getCaptainsCountData", this.buildFilterByAreaRequest());
        await this.setState({totalCaptains});
    }

    private async fetchPassengersCountsAsync(): Promise<void> {
        const totalPassengers: number = await this.getAsync("/api/Dashboard/getPassengersCountData");
        await this.setState({totalPassengers});
    }

    private async fetchVisitorsCountAsync(): Promise<void> {
        const sender: IBaseComponent = this._visitorsCountWidgetRef.current || this;
        const visitorsCount: number = await sender.getAsync("/api/Dashboard/getVisitorsCountData");
        await this.setState({visitorsCount});
    }

    private async fetchNewPassengersCountAsync(): Promise<void> {
        const sender: IBaseComponent = this._newPassengersCountWidgetRef.current || this;
        const newPassengersCount: number = await sender.getAsync("/api/Dashboard/getNewPassengersCountData");
        await this.setState({newPassengersCount});
    }

    private async fetchActivePassengersInfoAsync(): Promise<void> {
        const sender: IBaseComponent = this._passengerPercentageWidgetRef.current || this;
        
        const request = new UserDashboardInfoRequest();
        request.from = Dashboard.hourBackInTime;
        request.areas = this.selectedAreas.map(area => area.id);

        const response: UserDashboardInfoResponse = await sender.postAsync("/api/Dashboard/getActivePassengersInfo", request);
        await this.setState({totalActivePassengers: response.totalActive, onlinePassengers: response.online, percentageActivePassengers: response.percentage})
    }

    private async fetchActiveCaptainsInfoAsync(): Promise<void> {
        const sender: IBaseComponent = this._captainPercentageWidgetRef.current || this;
        
        const request = new UserDashboardInfoRequest();
        request.from = Dashboard.hourBackInTime;
        request.areas = this.selectedAreas.map(area => area.id);

        const response: UserDashboardInfoResponse = await sender.postAsync("/api/Dashboard/getActiveCaptainsInfo", request);
        await this.setState({totalActiveCaptains: response.totalActive, onlineCaptains: response.online, percentageActiveCaptains: response.percentage})
    }

    private async fetchBookingsInfoForLastDayAsync(): Promise<void> {
        const sender: IBaseComponent = this._bookingInfoLastDayWidgetRef.current || this;
        
        const request = new BookingsDashboardInfoRequest();
        request.from = Dashboard.dayBackInTime;

        const result: BookingsDashboardInfoResponse = await sender.postAsync("/api/Dashboard/getBookingsInfo", request);

        await this.setState({
            rideCompletedInLastDay: result.rideCompleted,
            newInLastDay: result.new,
            acceptedByCaptainInLastDay: result.acceptedByCaptain,
            cancelledByCaptainInLastDay: result.cancelledRideByCaptain,
            cancelledByPassengerInLastDay: result.cancelledRideByPassenger
        });
    }

    private async fetchBookingsInfoForLastWeekAsync(): Promise<void> {
        const sender: IBaseComponent = this._bookingInfoLastWeekWidgetRef.current || this;
        
        const request = new BookingsDashboardInfoRequest();
        request.from = Dashboard.weekBackInTime;

        const result: BookingsDashboardInfoResponse = await sender.postAsync("/api/Dashboard/getBookingsInfo", request);

        await this.setState({
            rideCompletedInLastWeek: result.rideCompleted,
            newInLastWeek: result.new,
            acceptedByCaptainInLastWeek: result.acceptedByCaptain,
            cancelledByCaptainInLastWeek: result.cancelledRideByCaptain,
            cancelledByPassengerInLastWeek: result.cancelledRideByPassenger
        });
    }

    private async onDropDownChangeAsync(areas: Area[]): Promise<void> {
        await this.setState({selectedAreas: areas});

        await this.fetchDataAsync(false, true);
    }

    private static get hourBackInTime(): Date {
        return new Date(new Date().setHours(new Date().getHours() - 1));
    }

    private static get dayBackInTime(): Date {
        return new Date(new Date().setHours(new Date().getHours() - 24));
    }

    private static get weekBackInTime(): Date {
        return new Date(new Date().setHours(new Date().getHours() - 24 * 7));
    }

    private get areasDescription(): string {
        if (this.selectedAreas && this.selectedAreas.length > 0) {
            return this.selectedAreas.map(item => item.name).join(", ");
        }

        return Localizer.dashboardPageAreasDescription;
    }

    public getTitle(): string {
        return AppController.getFullPageTitle(Localizer.topNavDashboard);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const context: UserContext = this.getContext();

        if (context.role == null) {
            await PageRouteProvider.redirectAsync(PageDefinitions.mobileSelectRoleRoute, true, true);
            return;
        }

        await this.fetchDataAsync(true);
    }

    public render(): React.ReactNode {

        return (
            <PageContainer transparent className={this.css(boatStyles.pageContainer, styles.dashboard)} alertClassName={boatStyles.alert}>

                <WidgetContainer controller="Dashboard" async>

                    <DropdownWidget wide multiple autoCollapse
                                    id={"AreasWidget"}
                                    ref={this._areasWidgetRef}
                                    label={Localizer.dashboardPageDropdownWidgetFilterByAreaLabel}
                                    icon={{name: "fas globe-europe"}}
                                    description={this.areasDescription}
                                    items={this.state.areas}
                                    selectedItems={this.selectedAreas}
                                    onChange={(sender) => this.onDropDownChangeAsync(sender.selectedItems)}
                    />

                    <RouteWidget minimized
                                 id={"CaptainsCount"}
                                 label={this.captainsCountLabel}
                                 icon={{name: "fas ship"}}
                                 description={Localizer.infoPanelWidgetCaptains}
                                 route={PageDefinitions.userManagement(AppConstants.captainRole)}
                    />

                    <RouteWidget minimized
                                 id={"PassengersCount"}
                                 label={this.passengersCountLabel}
                                 icon={{name: "fas users"}}
                                 description={Localizer.infoPanelWidgetPassengers}
                                 route={PageDefinitions.userManagement(AppConstants.passengerRole)}
                    />

                    <RouteWidget minimized
                                 id={"WaypointsCount"}
                                 label={this.waypointsCountLabel}
                                 icon={{name: "fas route"}}
                                 description={Localizer.infoPanelWidgetWaypoints}
                                 route={PageDefinitions.servicePointsManagement(ServicePointsTab.Waypoints)}
                    />

                    <RouteWidget minimized
                                 id={"ServicePointsCount"}
                                 label={this.servicePointsCountLabel}
                                 icon={{name: "fas map-marked"}}
                                 description={Localizer.dashboardPageRouteWidgetServicePointsCountDescription}
                                 route={PageDefinitions.servicePointsManagement(ServicePointsTab.ServicePoints)}
                    />

                    <RouteWidget minimized wide
                                 id={"BookingsCount"}
                                 ref={this._bookingsCountWidgetRef}
                                 label={this.bookingsCountLabel}
                                 icon={{name: "fas address-book"}}
                                 description={Localizer.infoPanelWidgetBookings}
                                 route={PageDefinitions.bookingsRoute}
                    />

                    <NumberWidget minimized
                                  id={"VisitorsCount"}
                                  ref={this._visitorsCountWidgetRef}
                                  label={this.visitorsCountLabel}
                                  icon={{name: ""}}
                                  description={Localizer.dashboardPageVisitorsDescription}
                    />

                    <NumberWidget minimized
                                  id={"NewPassengersCount"}
                                  ref={this._newPassengersCountWidgetRef}
                                  label={this.newPassengersCountLabel}
                                  icon={{name: ""}}
                                  description={Localizer.dashboardPageNewPassengersDescription}
                    />

                    <InfoWidget id={"BookingInfoLastDay"}
                                ref={this._bookingInfoLastDayWidgetRef}
                                label={Localizer.dashboardPageInfoWidgetLastDayBookingsLabel}
                                rideCompleted={this.state.rideCompletedInLastDay}
                                new={this.state.newInLastDay}
                                acceptedByCaptain={this.state.acceptedByCaptainInLastDay}
                                cancelledByCaptain={this.state.cancelledByCaptainInLastDay}
                                cancelledByPassenger={this.state.cancelledByPassengerInLastDay}
                                fetchedFromDate={Dashboard.dayBackInTime}
                    />

                    <InfoWidget id={"BookingInfoLastWeek"}
                                ref={this._bookingInfoLastWeekWidgetRef}
                                label={Localizer.dashboardPageInfoWidgetLastWeekBookingsLabel}
                                rideCompleted={this.state.rideCompletedInLastWeek}
                                new={this.state.newInLastWeek}
                                acceptedByCaptain={this.state.acceptedByCaptainInLastWeek}
                                cancelledByCaptain={this.state.cancelledByCaptainInLastWeek}
                                cancelledByPassenger={this.state.cancelledByPassengerInLastWeek}
                                fetchedFromDate={Dashboard.weekBackInTime}
                    />

                    <InfoWidget id={"CaptainPercentage"}
                                ref={this._captainPercentageWidgetRef}
                                label={Localizer.infoPanelWidgetCaptains}
                                online={this.state.onlineCaptains}
                                active={this.state.totalActiveCaptains}
                                total={this.state.totalCaptains}
                                percentage={this.state.percentageActiveCaptains}
                                fetchedFromDate={Dashboard.hourBackInTime}
                    />

                    <InfoWidget id={"PassengerPercentage"}
                                ref={this._passengerPercentageWidgetRef}
                                label={Localizer.infoPanelWidgetPassengers}
                                online={this.state.onlinePassengers}
                                active={this.state.totalActivePassengers}
                                total={this.state.totalPassengers}
                                percentage={this.state.percentageActivePassengers}
                                fetchedFromDate={Dashboard.hourBackInTime}
                    />
                    
                    <RouteWidget id={"Admin"}
                                 icon={{name: "users-cog"}}
                                 route={PageDefinitions.adminRoute}
                                 label={Localizer.topNavAdmin}
                                 description={Localizer.dashboardPageAdminDescription}
                    />

                    <RouteWidget id={"ContactSupport"}
                                 icon={{name: "fas user-headset"}}
                                 route={PageDefinitions.contactSupportRoute}
                                 label={Localizer.topNavContactSupport}
                                 description={Localizer.dashboardPageContactSupportDescription}
                    />
                    
                    <RouteWidget id={"Bookings"}
                                 icon={{name: "fad fa-ticket"}}
                                 route={PageDefinitions.bookingsRoute}
                                 label={Localizer.bookingsPageTitle}
                                 description={Localizer.bookingsPageSubtitle}
                    />

                    <RouteWidget id={"Tickets"}
                                 icon={{name: "fad fa-ticket"}}
                                 route={PageDefinitions.ticketsRoute}
                                 label={Localizer.topNavTickets}
                                 description={Localizer.ticketsPageGridHeaderAll}
                    />

                </WidgetContainer>

            </PageContainer>
        );
    }
}