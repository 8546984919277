import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {AddressHelper, Button, ButtonContainer, ButtonType, Checkbox, Form, IGoogleMapMarker, Modal, NumberInput, TwoColumns} from "@reapptor-apps/reapptor-react-components"
import WaypointMap from "@/components/WaypointMap/WaypointMap";
import Waypoint from "@/models/server/bout/Waypoint";
import {GeoCoordinate} from "@reapptor-apps/reapptor-toolkit";
import IntermediateWaypoint from "@/models/server/bout/IntermediateWaypoint";
import Localizer from "@/localization/Localizer";

import styles from "./IntermediateMapPointsModal.module.scss";

interface IIntermediateMapPointsModalProps {
    waypoint: Waypoint;
    onModalSave?(intermediatePoint: IntermediateWaypoint): Promise<void>;
}

interface IIntermediateMapPointsModalState {
    intermediatePoint: IntermediateWaypoint | null;
    fairway: boolean;
    marker: IGoogleMapMarker | null;
    lat: number;
    lon: number;
    mapCenter: GeoCoordinate | null;
    canSave: boolean;
}

export default class IntermediateMapPointsModal extends BaseComponent<IIntermediateMapPointsModalProps, IIntermediateMapPointsModalState> {

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _mapRef: React.RefObject<WaypointMap> = React.createRef();

    state: IIntermediateMapPointsModalState = {
        lat: 0,
        lon: 0,
        intermediatePoint: null,
        fairway: false,
        marker: null,
        mapCenter: null,
        canSave: true
    }

    public async openAsync(intermediatePoint: IntermediateWaypoint | null = null, animation: boolean = true): Promise<void> {
        const route: GeoCoordinate[] = Waypoint.getRoute(this.waypoint);
        
        this.state.mapCenter = AddressHelper.getCenter(route);
        
        if (this._modalRef.current) {

            if (intermediatePoint) {
                await this.setState({intermediatePoint, lat: intermediatePoint.lat, lon: intermediatePoint.lon});
            }

            const marker: IGoogleMapMarker = AddressHelper.toMarker(new GeoCoordinate(this.state.lat, this.state.lon));
            
            await this.setState({marker});

            await this._modalRef.current.openAsync(animation);
        }
    }

    private async onMapClickAsync(coordinates: GeoCoordinate): Promise<void> {
        const marker: IGoogleMapMarker = AddressHelper.toMarker(coordinates);
        this.state.lat = coordinates.lat;
        this.state.lon = coordinates.lon;

        await this.setState({marker})
    }

    private async onFairwayChangeAsync(value: boolean): Promise<void> {
        await this.setState({fairway: value})
    }

    private async onSaveModalClickAsync(): Promise<void> {
        await this.setState({canSave: false});
        if (this.props.onModalSave) {

            if (this.state.intermediatePoint) {
                this.state.intermediatePoint.lat = this.state.lat;
                this.state.intermediatePoint.lon = this.state.lon;
                this.state.intermediatePoint.fairway = this.state.fairway;

            } else {
                const point = new IntermediateWaypoint();
                point.lat = this.state.lat;
                point.lon = this.state.lon;
                point.fairway = this.state.fairway;

                await this.setState({intermediatePoint: point})
            }

            await this.props.onModalSave(this.state.intermediatePoint!);
            await this.closeAsync();
        }
    }

    private async setLatCoordinateAsync(value: number): Promise<void> {
        this.state.lat = value;
    }

    private async setLonCoordinateAsync(value: number): Promise<void> {
        this.state.lon = value;
    }

    private async closeAsync(): Promise<void> {
        if (this._modalRef.current) {
            await this._modalRef.current.closeAsync();
        }

        await this.reRenderAsync();
    }

    private get waypoint(): Waypoint {
        return this.props.waypoint;
    }

    private get markerOrder(): number {
        return this.state.intermediatePoint 
            ? this.state.intermediatePoint.order + 1
            : this.waypoint.intermediateWaypoints!.length + 1;
    }

    private static get modalId(): string {
        return "mapPointsModal";
    }
    
    private renderContent(): React.ReactNode {
        return (
            <div>
                {
                    (this.state.marker) &&
                    (
                        <WaypointMap ref={this._mapRef}
                                     className={styles.map}
                                     point={this.waypoint}
                                     intermediatePointMarkers={[this.state.marker]}
                                     center={this.state.mapCenter!}
                                     zoom={13}
                                     onClick={(coordinates: GeoCoordinate) => this.onMapClickAsync(coordinates)}
                        />
                    )
                }

                <Form id="form" onSubmit={() => this.onSaveModalClickAsync()}>

                    <TwoColumns>
                        
                        <Checkbox title={Localizer.intermediatePointsPanelGridFairway} 
                                  label={Localizer.intermediatePointsPanelGridFairway} 
                                  value={this.state.fairway} 
                                  onChange={(sender, value) => this.onFairwayChangeAsync(value)}
                        />
                        
                        <NumberInput readonly
                                     title={Localizer.intermediateMapPointsModalOrder}
                                     label={Localizer.intermediateMapPointsModalOrder}
                                     value={this.markerOrder}
                        />
                        
                    </TwoColumns>
                    
                    <TwoColumns>
                        
                        <NumberInput id="latitude"
                                     format={"0.0"}
                                     min={-90}
                                     max={+90}
                                     title={Localizer.intermediatePointsPanelGridLatitude} 
                                     label={Localizer.intermediatePointsPanelGridLatitude} 
                                     value={Number.parseFloat(this.state.lat.toFixed(8))} 
                                     onChange={(_, value) => this.setLatCoordinateAsync(value)}
                        />

                        <NumberInput id="longitude"
                                     format={"0.0"}
                                     min={-180}
                                     max={+180}
                                     title={Localizer.intermediatePointsPanelGridLongitude} 
                                     label={Localizer.intermediatePointsPanelGridLongitude} 
                                     value={Number.parseFloat(this.state.lon.toFixed(8))}
                                     onChange={(_, value) => this.setLonCoordinateAsync(value)}
                        />
                        
                    </TwoColumns>

                    <ButtonContainer>
                        
                        <Button submit
                                type={ButtonType.Primary}
                                label={Localizer.genericSave}
                                icon={{name: "far save"}}
                                disabled={!this.state.canSave}
                        />
                        
                        <Button type={ButtonType.Default}
                                label={Localizer.genericCancel}
                                onClick={() => this.closeAsync()}
                        />
                        
                    </ButtonContainer>

                </Form>

            </div>
        );
    }

    private async clearState(): Promise<void> {
        await this.setState({intermediatePoint: null, mapCenter: null, marker: null, canSave: true});
    }
    
    public render(): React.ReactNode {
        return (
            <Modal id={IntermediateMapPointsModal.modalId}
                   ref={this._modalRef}
                   className={styles.mapPointsModal}
                   title={Localizer.intermediateMapPointsModalTitle}
                   subtitle={Localizer.intermediateMapPointsModalSubtitle}
                   onClose={async () => await this.clearState()}
            >

                {
                    this.renderContent()
                }

            </Modal>
        )
    }
}