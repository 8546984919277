import React from "react";
import {ActionType, BaseComponent, ch, IBaseComponent} from "@reapptor-apps/reapptor-react-common";
import {
    Button,
    ButtonType,
    CellAction,
    CellModel,
    Checkbox,
    ColumnActionDefinition,
    ColumnActionType,
    ColumnDefinition,
    Dropdown, DropdownAlign, DropdownOrderBy, FileInput,
    Form,
    Grid,
    GridHoveringType,
    GridModel,
    GridOddType,
    IconSize,
    JustifyContent,
    RowModel,
    TextInput,
    ToolbarButton,
    ToolbarContainer,
    ToolbarRow
} from "@reapptor-apps/reapptor-react-components";
import Waypoint from "@/models/server/bout/Waypoint";
import ListWaypointsRequest from "@/models/server/requests/ListWaypointsRequest";
import IntermediatePointsPanel from "@/pages/ServicePointsManagement/IntermediatePointsPanel/IntermediatePointsPanel";
import SaveWaypointRequest from "@/models/server/requests/SaveWaypointRequest";
import WaypointModal from "@/pages/ServicePointsManagement/WayPointsPanel/WaypointModal/WaypointModal";
import Area from "@/models/server/bout/Area";
import {FileModel, IPagedList} from "@reapptor-apps/reapptor-toolkit";
import ImportWaypointsRequest from "@/models/server/requests/ImportWaypointsRequest";
import ImportWaypointsResponse from "@/models/server/responses/ImportWaypointsResponse";
import ServicePoint from "@/models/server/bout/ServicePoint";
import GenerateWaypointsResponse from "@/models/server/responses/GenerateWaypointsResponse";
import Country from "@/models/server/bout/Country";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import styles from "./WayPointsPanel.module.scss";

interface IWayPointsPanelProps {
    areas: Area[] | [];
}

interface IWayPointsPanelState {
    activeOnly: boolean;
    selectedArea: Area | null;
    selectedCountry: Country | null;
    search: string;
}

export default class WayPointsPanel extends BaseComponent<IWayPointsPanelProps, IWayPointsPanelState> {

    state: IWayPointsPanelState = {
        activeOnly: true,
        selectedArea: null,
        selectedCountry: null,
        search: ""
    };

    private readonly _waypointGridRef: React.RefObject<Grid<Waypoint>> = React.createRef();
    private readonly _waypointModalRef: React.RefObject<WaypointModal> = React.createRef();
    private readonly _waypointsImportFileInputRef: React.RefObject<FileInput> = React.createRef();

    private readonly _waypointsColumns: ColumnDefinition[] = [
        {
            name: "area",
            header: Localizer.wayPointsPanelGridAreaLanguageItemName,
            accessor: nameof.full<Waypoint>(o => o.source!.area),
            minWidth: 160,
            noWrap: true,
            settings: {
                infoAccessor: nameof.full<ServicePoint>(o => o.area!.country!.name),
            }
        } as ColumnDefinition,
        {
            name: "source",
            header: Localizer.wayPointsPanelGridSourceLanguageItemName,
            accessor: nameof<Waypoint>(o => o.source),
            noWrap: true,
            minWidth: 250,
            settings: {
                infoAccessor: nameof.full<Waypoint>(o => o.source!.location)
            },
        } as ColumnDefinition,
        {
            name: "destination",
            header: Localizer.wayPointsPanelGridDestinationLanguageItemName,
            accessor: nameof<Waypoint>(o => o.destination),
            noWrap: true,
            minWidth: 250,
            settings: {
                infoAccessor: nameof.full<Waypoint>(o => o.destination!.location)
            },
        } as ColumnDefinition,
        {
            header: Localizer.wayPointsPanelGridCreatedLanguageItemName,
            accessor: nameof<Waypoint>(o => o.createdAt),
            noWrap: true,
            minWidth: 110,
            maxWidth: 110,
            stretch: false,
            format: "D",
        } as ColumnDefinition,
        {
            header: Localizer.wayPointsPanelGridActionsLanguageItemName,
            minWidth: 125,
            stretch: true,
            actions: [
                {
                    name: "toggle",
                    title: Localizer.genericDetails,
                    type: ColumnActionType.Details,
                    callback: (cell) => WayPointsPanel.toggleIntermediatePointsAsync(cell)
                } as ColumnActionDefinition,
                {
                    name: "preview",
                    title: Localizer.genericPreview,
                    icon: "far search",
                    type: ActionType.Default,
                    callback: (cell, action) => this.processWaypointOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "edit",
                    title: Localizer.genericEdit,
                    icon: "far edit",
                    type: ActionType.Edit,
                    callback: (cell, action) => this.processWaypointOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "generate",
                    title: Localizer.wayPointsPanelGridGenerateTitle,
                    icon: "fad fa-route",
                    right: false,
                    type: ActionType.Blue,
                    callback: (cell, action) => this.processWaypointOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "delete",
                    title: Localizer.genericDelete,
                    right: true,
                    icon: "far trash-alt",
                    confirm: Localizer.genericDelete,
                    type: ActionType.Delete,
                    callback: (cell, action) => this.processWaypointOperationAsync(cell, action)
                } as ColumnActionDefinition
            ]
        } as ColumnDefinition,
    ];

    private get mapPointsModal(): WaypointModal {
        return this._waypointModalRef.current!;
    }

    private async openWaypointsImportFileInputAsync(): Promise<void> {
        await this._waypointsImportFileInputRef.current?.openAsync();
    }

    public async saveWaypointAsync(point: Waypoint): Promise<void> {
        if (point.sourceId === point.destinationId){
            await ch.alertErrorAsync(Localizer.wayPointsPanelAlertErrorSameSourceAndDestination, true, true);
            return;
        }
        
        const request = new SaveWaypointRequest();
        request.id = point.id;
        request.sourceId = point.sourceId;
        request.destinationId = point.destinationId;

        await this.postAsync("/api/wayPoints/saveWaypoint", request);

        await ch.alertMessageAsync(Localizer.wayPointsPanelAlertErrorSaveWaypoint, true, true);
    }

    private async onModalSaveAsync(point: Waypoint): Promise<void> {
        await this.saveWaypointAsync(point);
        await this.reloadAsync();
    }
    
    private static async toggleIntermediatePointsAsync(cell: CellModel<Waypoint>): Promise<void> {
        await cell.row.toggleAsync();
    }

    private async processWaypointOperationAsync(cell: CellModel<Waypoint>, action: CellAction<Waypoint>): Promise<void> {
        const model: Waypoint = cell.model;

        if (action.action.name === "delete") {

            await cell.grid.postAsync("/api/waypoints/deleteWaypoint", model.id);

            await this.waypointsGrid.deleteAsync(cell.row.index);

            await ch.alertMessageAsync(Localizer.wayPointsPanelAlertErrorDeleteWaypoint, true, true);

        } else if (action.action.name === "edit") {

            await this.mapPointsModal.openAsync(model);

        } else if (action.action.name === "preview") {

            await this.mapPointsModal.openAsync(model, true);

        } else if (action.action.name === "generate") {

            const confirmed: boolean = await ch.confirmAsync(Localizer.wayPointsPanelGridGenerateConfirm);

            if (confirmed) {
                const response: GenerateWaypointsResponse = await this.postAsync("/api/waypoints/generateWaypoints", model.id);

                const message: string = Localizer.wayPointsPanelGridGenerateResult.format(response.processedWaypointsCount, response.newWaypointsCount, response.failedWaypointsCount, response.totalMinutes);

                await ch.messageBoxAsync(message);

                if (response.newWaypointsCount > 0) {
                    await this.reloadAsync();
                }
            }
        }
    }

    private async onWaypointsImportAsync(file: FileModel): Promise<void> {
        const request = new ImportWaypointsRequest();
        request.file = file;
        request.overrideExisting = true;
        request.generateInverseWaypoints = false;
        
        const response: ImportWaypointsResponse = await this.postAsync("/api/waypoints/importWaypoints", request);
        
        if (response.success) {
            await ch.flyoutMessageAsync(Localizer.genericImportSuccess);
            
            await this.reloadAsync();
        } else {
            await ch.flyoutErrorAsync(Localizer.genericImportFailed);
        }
    }

    private async fetchWaypointsAsync(sender: IBaseComponent, pageNumber: number, pageSize: number): Promise<IPagedList<Waypoint>> {
        const request = new ListWaypointsRequest();
        request.activeOnly = this.state.activeOnly;
        request.search = this.state.search;
        request.includeIntermediateWaypoints = true;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.countryId = (this.state.selectedCountry) ? this.state.selectedCountry.id : null;
        request.areaId = (this.state.selectedArea) ? this.state.selectedArea.id : null;
        request.asAdmin = AppController.asAdmin;
        
        return await sender.postAsync("/api/waypoints/listWaypoints", request);
    }

    private async onCountryChangeAsync(selectedCountry: Country | null): Promise<void> {
        const selectedArea: Area | null = ((selectedCountry == null) || (selectedCountry.id == this.state.selectedArea?.countryId))
            ? this.state.selectedArea
            : null;

        await this.setState({selectedCountry, selectedArea});

        await this.waypointsGrid.reloadAsync();
    }

    private async onAreaChangeAsync(selectedArea: Area): Promise<void> {
        
        await this.setState({selectedArea});
        
        await this.waypointsGrid.reloadAsync();
    }

    private async setActiveOnlyAsync(activeOnly: boolean): Promise<void> {
        
        await this.setState({activeOnly});
        
        await this.waypointsGrid.reloadAsync();
    }

    private async setSearchAsync(search: string): Promise<void> {
        
        await this.setState({search});
        
        await this.waypointsGrid.reloadAsync();
    }

    private async reloadAsync(): Promise<void> {
        await this.waypointsGrid.reloadAsync();
    }

    private get countries(): Country[] {
        return this.props
            .areas
            .where(item => item.country != null)
            .select(item => item.country!)
            .distinct(item => item.id);
    }

    private get areas(): Area[] {
        return (this.state.selectedCountry != null)
            ? this.props.areas.where(item => item.countryId == this.state.selectedCountry!.id)
            : this.props.areas;
    }

    public get waypointsGrid(): GridModel<Waypoint> {
        return this._waypointGridRef.current!.model;
    }

    public renderDetails(row: RowModel<Waypoint>): React.ReactNode {
        const model: Waypoint = row.model;
        return (
            <IntermediatePointsPanel key={model.id}
                                     waypoint={model}
            />
        )
    }

    public render(): React.ReactNode {
        return (
            <React.Fragment>

                <ToolbarContainer>

                    <ToolbarRow justify={JustifyContent.End}>
                        
                        <ToolbarButton icon={{name: "plus", size: IconSize.Large}}
                                       label={Localizer.wayPointsPanelAddWayPointToolbarLabel}
                                       title={Localizer.wayPointsPanelAddWayPointToolbarTitle}
                                       type={ButtonType.Primary}
                                       onClick={() => this.mapPointsModal.openAsync()}
                        />

                        <React.Fragment>

                            <FileInput inline hidden
                                       ref={this._waypointsImportFileInputRef}
                                       fileTypes={[".xlsx", ".csv"]}
                                       onChange={(_, file: FileModel) => this.onWaypointsImportAsync(file)}
                            />

                            <Button block
                                    label={"Excel"}
                                    icon={{name: "fas fa-file-upload"}}
                                    type={ButtonType.Primary}
                                    onClick={() => this.openWaypointsImportFileInputAsync()}
                            />

                        </React.Fragment>

                    </ToolbarRow>

                    <ToolbarRow justify={JustifyContent.Start}>
                        
                        <Form inline>

                            <Dropdown id="wpCountryFilter" inline noWrap noSubtext clearButton
                                      align={DropdownAlign.Left}
                                      title={Localizer.genericSelectCountry}
                                      minWidth={"210px"}
                                      items={this.countries}
                                      nothingSelectedText={Localizer.genericAllCountries}
                                      selectedItem={this.state.selectedCountry}
                                      onChange={(_, item) => this.onCountryChangeAsync(item!)}
                            />

                            <Dropdown id="wpAreaFilter" inline noWrap noSubtext clearButton
                                      align={DropdownAlign.Left}
                                      title={Localizer.wayPointsPanelSelectAreaDropdownTitle}
                                      minWidth={"210px"}
                                      items={this.areas}
                                      nothingSelectedText={Localizer.genericAllAreas}
                                      selectedItem={this.state.selectedArea}
                                      orderBy={DropdownOrderBy.None}
                                      onChange={(_, item) => this.onAreaChangeAsync(item!)}
                            />

                            <Checkbox id="wpActiveOnlyFilter" inline
                                      label={Localizer.wayPointsPanelActiveOnlyCheckboxLabel}
                                      value={this.state.activeOnly}
                                      onChange={(_, value) => this.setActiveOnlyAsync(value)}
                            />

                            <TextInput id="wpSearchFilter" inline small clearButton
                                       placeholder={Localizer.wayPointsPanelTextInputSearchPlace}
                                       value={this.state.search}
                                       width="22rem"
                                       onChange={(_, value) => this.setSearchAsync(value)}
                            />
                            
                        </Form>

                    </ToolbarRow>

                </ToolbarContainer>
                
                <Grid autoToggle responsive
                      ref={this._waypointGridRef}
                      pagination={20}
                      className={this.css(styles.grid)}
                      hovering={GridHoveringType.Row}
                      odd={GridOddType.None}
                      minWidth="auto"
                      noDataText={Localizer.genericNoData}
                      columns={this._waypointsColumns}
                      renderDetails={(row) => this.renderDetails(row)}
                      fetchData={(sender, pageNumber: number, pageSize: number) => this.fetchWaypointsAsync(sender, pageNumber, pageSize)}
                />

                <WaypointModal ref={this._waypointModalRef} 
                               areas={this.props.areas} 
                               onModalSave={(item) => this.onModalSaveAsync(item)}
                />
                
            </React.Fragment>
        );
    }
}