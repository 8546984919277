import React from "react";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import {ApiProvider, BaseComponent, LocalizationString} from "@reapptor-apps/reapptor-react-common";
import {Modal, Spinner} from "@reapptor-apps/reapptor-react-components";
import GetShuttleBookingTicketRequest from "@/models/server/requests/GetShuttleBookingTicketRequest";
import GetShuttleBookingTicketResponse from "@/models/server/responses/GetShuttleBookingTicketResponse";
import Booking from "@/models/server/bout/Booking";
import Localizer from "@/localization/Localizer";
import ServiceProviderController from "@/pages/ServiceProviderController";

import styles from "./BookingTicketModal.module.scss";
import mobileStyles from "../../../mobile.module.scss";

import googleLogo from "./Images/google.png"

export interface IBookingTicketModalProps {
}

interface IBookingTicketModalState {
    loading: boolean;
    booking: Booking | null;
    ticketCode: string | null;
    ticketQrCode: string | null;
}

interface IBookingTicketModalData {
}

export default class BookingTicketModal extends BaseComponent<IBookingTicketModalProps, IBookingTicketModalState> {

    state: IBookingTicketModalState = {
        loading: true,
        booking: null,
        ticketCode: null,
        ticketQrCode: null,
    }

    private readonly _modalRef: React.RefObject<Modal<IBookingTicketModalData>> = React.createRef();

    private get hasData(): boolean {
        return (this._modalRef.current != null) && (this.booking != null);
    }

    private get modal(): Modal<IBookingTicketModalData> {
        return this._modalRef.current!;
    }

    private get booking(): Booking | null {
        return this.state.booking;
    }

    private get subtitle(): string | null {
        return (this.booking)
            ? ((this.booking.stopsCount != null) && (this.booking.stopsCount > 0))
                ? Localizer.bookingTicketModalPassengersAndStopsCount.format(this.booking.passengers, this.booking.stopsCount)
                : Localizer.bookingTicketModalPassengersCount.format(this.booking.passengers)
            : Localizer.genericLoading;
    }

    private get ticketCode(): string | null {
        return this.state.ticketCode;
    }

    private get createdAt(): string | null {
        return (this.booking)
            ? this.booking.createdAt.format("dt")
            : null;
    }

    private get ticketQrCode(): string | null {
        return this.state.ticketQrCode;
    }
    
    private get departureAt(): Date | null {
        return this.booking?.departureAt || null;
    }
    
    private get arrivalAt(): Date | null {
        return this.booking?.arrivalAt || null;
    }

    private get boatName(): string {
        return this.booking?.boat?.model || "";
    }

    private get departureDate(): string {
        return (this.departureAt)
            ? "{0:d} {0:dddd}".format(this.departureAt) // Utility.formatValue(this.departureAt, "d")
            : "";
    }

    private get departureTime(): string {
        return ((this.booking) && (this.departureAt))
            ? Utility.formatValue(Booking.getCountryLocalTime(this.booking, this.departureAt), "t")
            : "";
    }

    private get arrivalTime(): string {
        return ((this.booking) && (this.arrivalAt))
            ? Utility.formatValue(Booking.getCountryLocalTime(this.booking, this.arrivalAt), "t")
            : "";
    }

    private get source(): string {
        return this.booking?.waypoint?.source?.name || "";
    }

    private get destination(): string {
        return this.booking?.waypoint?.destination?.name || "";
    }
    
    private async onCloseAsync(): Promise<void> {
        this.state.loading = true;
        this.state.booking = null;
        this.state.ticketQrCode = null;
    }

    private generateGoogleMapsLink(): string | null {
        return Booking.generateGoogleMapsLink(this.booking);
    }
    
    public async openAsync(bookingId: string): Promise<void> {
        
        await this.modal?.openAsync();

        const request = new GetShuttleBookingTicketRequest();
        request.bookingId = bookingId;

        const response: GetShuttleBookingTicketResponse = await ApiProvider.postAsync("/api/mobileApp/getShuttleBookingTicket", request);
        
        await this.setState({ ticketCode: response.ticketCode, ticketQrCode: response.ticketQrCodeHtmlImage, booking: response.booking, loading: false });
    }
    
    public async closeAsync(): Promise<void> {
        await this.modal?.closeAsync();
    }
    
    public hasSpinner(): boolean {
        return true;
    }

    public get ticketsCount(): number {
        return this.booking?.passengers ?? 0;
    }

    public get childrenCount(): number {
        return this.booking?.children ?? 0;
    }

    public get pensionersCount(): number {
        return this.booking?.pensioners ?? 0;
    }

    public get bikesAndTrolleysCount(): number {
        return this.booking?.bikesAndTrolleys ?? 0;
    }

    public get adultsCount(): number {
        return (this.ticketsCount - this.childrenCount - this.bikesAndTrolleysCount - this.pensionersCount);
    }

    public render(): React.ReactNode {
        const mapsLink: string | null = this.generateGoogleMapsLink();
        const serviceProviderLogo: string | null = ServiceProviderController.serviceProviderSlug?.logo || null;
        
        return (
            <Modal notResponsive
                   ref={this._modalRef}
                   title={Localizer.bookingTicketModalTitle}
                   subtitle={this.subtitle}
                   className={this.css(styles.bookingTicketModal, this.mobile && styles.mobile, this.mobile && mobileStyles.mobile)}
                   bodyClassName={styles.body}
                   onClose={() => this.onCloseAsync()}
            >

                { (this.state.loading) && <Spinner /> }

                {
                    (this.hasData) &&
                    (
                        <div className={styles.container}>

                            <div className={styles.info}>

                                {
                                    (serviceProviderLogo) &&
                                    (
                                        <div className={styles.logo}>
                                            <img src={serviceProviderLogo}
                                                 alt={LocalizationString.value(ServiceProviderController.serviceProviderSlug?.name)}
                                            />
                                        </div>
                                    )
                                }

                                <div className={styles.boat}>
                                    <span><b>{this.departureDate}</b></span>
                                    <span>{this.boatName}</span>
                                </div>
                                <div className={styles.ports}>
                                    <p>
                                        <span>{this.departureTime}</span>
                                        <span><b>{this.source}</b></span>
                                    </p>
                                    <p>
                                        <span>{this.arrivalTime}</span>
                                        <span><b>{this.destination}</b></span>
                                    </p>
                                </div>
                            </div>

                            <div className={styles.qrCode}>
                                <img alt={this.ticketCode!}
                                     src={this.ticketQrCode!}
                                />
                            </div>

                            <div className={styles.bottom}>
                                
                                <div className={styles.ticketTypes}>
                                    
                                    {
                                        (this.adultsCount > 0) &&
                                        (
                                            <span>
                                                {Localizer.bookingTicketModalTicketsAdults}: <b>{this.adultsCount}</b>
                                            </span>
                                        )
                                    }
                                    
                                    {
                                        (this.pensionersCount > 0) &&
                                        (
                                            <span>
                                                {Localizer.bookingTicketModalTicketsPensioners}: <b>{this.pensionersCount}</b>
                                            </span>
                                        )
                                    }
                                    
                                    {
                                        (this.childrenCount > 0) &&
                                        (
                                            <span>
                                                {Localizer.bookingTicketModalTicketsChildren}: <b>{this.childrenCount}</b>
                                            </span>
                                        )
                                    }
                                    
                                    {
                                        (this.bikesAndTrolleysCount > 0) &&
                                        (
                                            <span>
                                                {Localizer.bookingTicketModalTicketsBikesAndTrolleys}: <b>{this.bikesAndTrolleysCount}</b>
                                            </span>
                                        )
                                    }
                                    
                                </div>

                                <div className={styles.ticketCode}>
                                    <span>{this.ticketCode}</span>
                                    <span>{this.createdAt}</span>
                                </div>

                            </div>

                            {
                                (mapsLink) &&
                                (
                                    <div className={styles.mapLink}>
                                        <img src={googleLogo} alt="Google" />
                                        <a href={mapsLink} target="_blank">{Localizer.bookingTicketModalFindYourWayToStop}</a>
                                    </div>
                                )
                            }

                        </div>
                    )
                }

            </Modal>
        )
    }
}