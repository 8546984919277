//Autogenerated

import {BaseEnumProvider, SortDirection} from "@reapptor-apps/reapptor-toolkit";
import {SelectListItem} from "@reapptor-apps/reapptor-react-components";
import {WebApplicationType, PasswordValidationError, TokenType} from "@reapptor-apps/reapptor-react-common";
import {PaymentPolicy, TicketType, ApplicationType, PostTripEmailType, TimePeriod, AccountHolderType, CruisePackageOwnerType, CruisePackagePriceInterval, BookingType, BoatType, BookingStatus, HslZoneType, PaymentStatus, LoginResultStatus, OrganizationContractLevel, OrganizationContractType, SavePasswordResultStatus, InvitationType, NotificationType, AuditTimestamp, AuthType, DayOfWeek} from "@/models/Enums";

class EnumProvider extends BaseEnumProvider<SelectListItem> {

    // #region Private
    
    private readonly _types: string[] = ["PaymentPolicy", "TicketType", "ApplicationType", "PostTripEmailType", "TimePeriod", "AccountHolderType", "CruisePackageOwnerType", "CruisePackagePriceInterval", "BookingType", "BoatType", "BookingStatus", "HslZoneType", "PaymentStatus", "LoginResultStatus", "OrganizationContractLevel", "OrganizationContractType", "SavePasswordResultStatus", "InvitationType", "NotificationType", "AuditTimestamp", "AuthType", "SortDirection", "WebApplicationType", "PasswordValidationError", "TokenType", "DayOfWeek"];

    protected get types(): readonly string[] {
        return this._types;
    }

    protected createSelectListItem(value: string, text: string, subtext: string): SelectListItem {
        return new SelectListItem(value, text, subtext);
    }
    
    // #endregion
    
    constructor() {
        super();
    }

    // #region PaymentPolicy

    public getPaymentPolicyItem(value: PaymentPolicy): SelectListItem {
        return this.transform(PaymentPolicy, "PaymentPolicy", value);
    }

    public getPaymentPolicyItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(PaymentPolicy, "PaymentPolicy", reverse);
    }

    public getPaymentPolicyName(value: PaymentPolicy): string {
        return this.getPaymentPolicyItem(value).text;
    }

    public getPaymentPolicyText(value: PaymentPolicy): string {
        return this.localizer.get(this.getPaymentPolicyName(value));
    }

    public getPaymentPolicyDescription(value: PaymentPolicy): string {
        return this.getPaymentPolicyItem(value).subtext;
    }

    // #endregion

    // #region TicketType

    public getTicketTypeItem(value: TicketType): SelectListItem {
        return this.transform(TicketType, "TicketType", value);
    }

    public getTicketTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(TicketType, "TicketType", reverse);
    }

    public getTicketTypeName(value: TicketType): string {
        return this.getTicketTypeItem(value).text;
    }

    public getTicketTypeText(value: TicketType): string {
        return this.localizer.get(this.getTicketTypeName(value));
    }

    public getTicketTypeDescription(value: TicketType): string {
        return this.getTicketTypeItem(value).subtext;
    }

    // #endregion

    // #region ApplicationType

    public getApplicationTypeItem(value: ApplicationType): SelectListItem {
        return this.transform(ApplicationType, "ApplicationType", value);
    }

    public getApplicationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ApplicationType, "ApplicationType", reverse);
    }

    public getApplicationTypeName(value: ApplicationType): string {
        return this.getApplicationTypeItem(value).text;
    }

    public getApplicationTypeText(value: ApplicationType): string {
        return this.localizer.get(this.getApplicationTypeName(value));
    }

    public getApplicationTypeDescription(value: ApplicationType): string {
        return this.getApplicationTypeItem(value).subtext;
    }

    // #endregion

    // #region PostTripEmailType

    public getPostTripEmailTypeItem(value: PostTripEmailType): SelectListItem {
        return this.transform(PostTripEmailType, "PostTripEmailType", value);
    }

    public getPostTripEmailTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(PostTripEmailType, "PostTripEmailType", reverse);
    }

    public getPostTripEmailTypeName(value: PostTripEmailType): string {
        return this.getPostTripEmailTypeItem(value).text;
    }

    public getPostTripEmailTypeText(value: PostTripEmailType): string {
        return this.localizer.get(this.getPostTripEmailTypeName(value));
    }

    public getPostTripEmailTypeDescription(value: PostTripEmailType): string {
        return this.getPostTripEmailTypeItem(value).subtext;
    }

    // #endregion

    // #region TimePeriod

    public getTimePeriodItem(value: TimePeriod): SelectListItem {
        return this.transform(TimePeriod, "TimePeriod", value);
    }

    public getTimePeriodItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(TimePeriod, "TimePeriod", reverse);
    }

    public getTimePeriodName(value: TimePeriod): string {
        return this.getTimePeriodItem(value).text;
    }

    public getTimePeriodText(value: TimePeriod): string {
        return this.localizer.get(this.getTimePeriodName(value));
    }

    public getTimePeriodDescription(value: TimePeriod): string {
        return this.getTimePeriodItem(value).subtext;
    }

    // #endregion

    // #region AccountHolderType

    public getAccountHolderTypeItem(value: AccountHolderType): SelectListItem {
        return this.transform(AccountHolderType, "AccountHolderType", value);
    }

    public getAccountHolderTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AccountHolderType, "AccountHolderType", reverse);
    }

    public getAccountHolderTypeName(value: AccountHolderType): string {
        return this.getAccountHolderTypeItem(value).text;
    }

    public getAccountHolderTypeText(value: AccountHolderType): string {
        return this.localizer.get(this.getAccountHolderTypeName(value));
    }

    public getAccountHolderTypeDescription(value: AccountHolderType): string {
        return this.getAccountHolderTypeItem(value).subtext;
    }

    // #endregion

    // #region CruisePackageOwnerType

    public getCruisePackageOwnerTypeItem(value: CruisePackageOwnerType): SelectListItem {
        return this.transform(CruisePackageOwnerType, "CruisePackageOwnerType", value);
    }

    public getCruisePackageOwnerTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(CruisePackageOwnerType, "CruisePackageOwnerType", reverse);
    }

    public getCruisePackageOwnerTypeName(value: CruisePackageOwnerType): string {
        return this.getCruisePackageOwnerTypeItem(value).text;
    }

    public getCruisePackageOwnerTypeText(value: CruisePackageOwnerType): string {
        return this.localizer.get(this.getCruisePackageOwnerTypeName(value));
    }

    public getCruisePackageOwnerTypeDescription(value: CruisePackageOwnerType): string {
        return this.getCruisePackageOwnerTypeItem(value).subtext;
    }

    // #endregion

    // #region CruisePackagePriceInterval

    public getCruisePackagePriceIntervalItem(value: CruisePackagePriceInterval): SelectListItem {
        return this.transform(CruisePackagePriceInterval, "CruisePackagePriceInterval", value);
    }

    public getCruisePackagePriceIntervalItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(CruisePackagePriceInterval, "CruisePackagePriceInterval", reverse);
    }

    public getCruisePackagePriceIntervalName(value: CruisePackagePriceInterval): string {
        return this.getCruisePackagePriceIntervalItem(value).text;
    }

    public getCruisePackagePriceIntervalText(value: CruisePackagePriceInterval): string {
        return this.localizer.get(this.getCruisePackagePriceIntervalName(value));
    }

    public getCruisePackagePriceIntervalDescription(value: CruisePackagePriceInterval): string {
        return this.getCruisePackagePriceIntervalItem(value).subtext;
    }

    // #endregion

    // #region BookingType

    public getBookingTypeItem(value: BookingType): SelectListItem {
        return this.transform(BookingType, "BookingType", value);
    }

    public getBookingTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(BookingType, "BookingType", reverse);
    }

    public getBookingTypeName(value: BookingType): string {
        return this.getBookingTypeItem(value).text;
    }

    public getBookingTypeText(value: BookingType): string {
        return this.localizer.get(this.getBookingTypeName(value));
    }

    public getBookingTypeDescription(value: BookingType): string {
        return this.getBookingTypeItem(value).subtext;
    }

    // #endregion

    // #region BoatType

    public getBoatTypeItem(value: BoatType): SelectListItem {
        return this.transform(BoatType, "BoatType", value);
    }

    public getBoatTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(BoatType, "BoatType", reverse);
    }

    public getBoatTypeName(value: BoatType): string {
        return this.getBoatTypeItem(value).text;
    }

    public getBoatTypeText(value: BoatType): string {
        return this.localizer.get(this.getBoatTypeName(value));
    }

    public getBoatTypeDescription(value: BoatType): string {
        return this.getBoatTypeItem(value).subtext;
    }

    // #endregion

    // #region BookingStatus

    public getBookingStatusItem(value: BookingStatus): SelectListItem {
        return this.transform(BookingStatus, "BookingStatus", value);
    }

    public getBookingStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(BookingStatus, "BookingStatus", reverse);
    }

    public getBookingStatusName(value: BookingStatus): string {
        return this.getBookingStatusItem(value).text;
    }

    public getBookingStatusText(value: BookingStatus): string {
        return this.localizer.get(this.getBookingStatusName(value));
    }

    public getBookingStatusDescription(value: BookingStatus): string {
        return this.getBookingStatusItem(value).subtext;
    }

    // #endregion

    // #region HslZoneType

    public getHslZoneTypeItem(value: HslZoneType): SelectListItem {
        return this.transform(HslZoneType, "HslZoneType", value);
    }

    public getHslZoneTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(HslZoneType, "HslZoneType", reverse);
    }

    public getHslZoneTypeName(value: HslZoneType): string {
        return this.getHslZoneTypeItem(value).text;
    }

    public getHslZoneTypeText(value: HslZoneType): string {
        return this.localizer.get(this.getHslZoneTypeName(value));
    }

    public getHslZoneTypeDescription(value: HslZoneType): string {
        return this.getHslZoneTypeItem(value).subtext;
    }

    // #endregion

    // #region PaymentStatus

    public getPaymentStatusItem(value: PaymentStatus): SelectListItem {
        return this.transform(PaymentStatus, "PaymentStatus", value);
    }

    public getPaymentStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(PaymentStatus, "PaymentStatus", reverse);
    }

    public getPaymentStatusName(value: PaymentStatus): string {
        return this.getPaymentStatusItem(value).text;
    }

    public getPaymentStatusText(value: PaymentStatus): string {
        return this.localizer.get(this.getPaymentStatusName(value));
    }

    public getPaymentStatusDescription(value: PaymentStatus): string {
        return this.getPaymentStatusItem(value).subtext;
    }

    // #endregion

    // #region LoginResultStatus

    public getLoginResultStatusItem(value: LoginResultStatus): SelectListItem {
        return this.transform(LoginResultStatus, "LoginResultStatus", value);
    }

    public getLoginResultStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(LoginResultStatus, "LoginResultStatus", reverse);
    }

    public getLoginResultStatusName(value: LoginResultStatus): string {
        return this.getLoginResultStatusItem(value).text;
    }

    public getLoginResultStatusText(value: LoginResultStatus): string {
        return this.localizer.get(this.getLoginResultStatusName(value));
    }

    public getLoginResultStatusDescription(value: LoginResultStatus): string {
        return this.getLoginResultStatusItem(value).subtext;
    }

    // #endregion

    // #region OrganizationContractLevel

    public getOrganizationContractLevelItem(value: OrganizationContractLevel): SelectListItem {
        return this.transform(OrganizationContractLevel, "OrganizationContractLevel", value);
    }

    public getOrganizationContractLevelItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(OrganizationContractLevel, "OrganizationContractLevel", reverse);
    }

    public getOrganizationContractLevelName(value: OrganizationContractLevel): string {
        return this.getOrganizationContractLevelItem(value).text;
    }

    public getOrganizationContractLevelText(value: OrganizationContractLevel): string {
        return this.localizer.get(this.getOrganizationContractLevelName(value));
    }

    public getOrganizationContractLevelDescription(value: OrganizationContractLevel): string {
        return this.getOrganizationContractLevelItem(value).subtext;
    }

    // #endregion

    // #region OrganizationContractType

    public getOrganizationContractTypeItem(value: OrganizationContractType): SelectListItem {
        return this.transform(OrganizationContractType, "OrganizationContractType", value);
    }

    public getOrganizationContractTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(OrganizationContractType, "OrganizationContractType", reverse);
    }

    public getOrganizationContractTypeName(value: OrganizationContractType): string {
        return this.getOrganizationContractTypeItem(value).text;
    }

    public getOrganizationContractTypeText(value: OrganizationContractType): string {
        return this.localizer.get(this.getOrganizationContractTypeName(value));
    }

    public getOrganizationContractTypeDescription(value: OrganizationContractType): string {
        return this.getOrganizationContractTypeItem(value).subtext;
    }

    // #endregion

    // #region SavePasswordResultStatus

    public getSavePasswordResultStatusItem(value: SavePasswordResultStatus): SelectListItem {
        return this.transform(SavePasswordResultStatus, "SavePasswordResultStatus", value);
    }

    public getSavePasswordResultStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(SavePasswordResultStatus, "SavePasswordResultStatus", reverse);
    }

    public getSavePasswordResultStatusName(value: SavePasswordResultStatus): string {
        return this.getSavePasswordResultStatusItem(value).text;
    }

    public getSavePasswordResultStatusText(value: SavePasswordResultStatus): string {
        return this.localizer.get(this.getSavePasswordResultStatusName(value));
    }

    public getSavePasswordResultStatusDescription(value: SavePasswordResultStatus): string {
        return this.getSavePasswordResultStatusItem(value).subtext;
    }

    // #endregion

    // #region InvitationType

    public getInvitationTypeItem(value: InvitationType): SelectListItem {
        return this.transform(InvitationType, "InvitationType", value);
    }

    public getInvitationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(InvitationType, "InvitationType", reverse);
    }

    public getInvitationTypeName(value: InvitationType): string {
        return this.getInvitationTypeItem(value).text;
    }

    public getInvitationTypeText(value: InvitationType): string {
        return this.localizer.get(this.getInvitationTypeName(value));
    }

    public getInvitationTypeDescription(value: InvitationType): string {
        return this.getInvitationTypeItem(value).subtext;
    }

    // #endregion

    // #region NotificationType

    public getNotificationTypeItem(value: NotificationType): SelectListItem {
        return this.transform(NotificationType, "NotificationType", value);
    }

    public getNotificationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(NotificationType, "NotificationType", reverse);
    }

    public getNotificationTypeName(value: NotificationType): string {
        return this.getNotificationTypeItem(value).text;
    }

    public getNotificationTypeText(value: NotificationType): string {
        return this.localizer.get(this.getNotificationTypeName(value));
    }

    public getNotificationTypeDescription(value: NotificationType): string {
        return this.getNotificationTypeItem(value).subtext;
    }

    // #endregion

    // #region AuditTimestamp

    public getAuditTimestampItem(value: AuditTimestamp): SelectListItem {
        return this.transform(AuditTimestamp, "AuditTimestamp", value);
    }

    public getAuditTimestampItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AuditTimestamp, "AuditTimestamp", reverse);
    }

    public getAuditTimestampName(value: AuditTimestamp): string {
        return this.getAuditTimestampItem(value).text;
    }

    public getAuditTimestampText(value: AuditTimestamp): string {
        return this.localizer.get(this.getAuditTimestampName(value));
    }

    public getAuditTimestampDescription(value: AuditTimestamp): string {
        return this.getAuditTimestampItem(value).subtext;
    }

    // #endregion

    // #region AuthType

    public getAuthTypeItem(value: AuthType): SelectListItem {
        return this.transform(AuthType, "AuthType", value);
    }

    public getAuthTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AuthType, "AuthType", reverse);
    }

    public getAuthTypeName(value: AuthType): string {
        return this.getAuthTypeItem(value).text;
    }

    public getAuthTypeText(value: AuthType): string {
        return this.localizer.get(this.getAuthTypeName(value));
    }

    public getAuthTypeDescription(value: AuthType): string {
        return this.getAuthTypeItem(value).subtext;
    }

    // #endregion

    // #region SortDirection

    public getSortDirectionItem(value: SortDirection): SelectListItem {
        return this.transform(SortDirection, "SortDirection", value);
    }

    public getSortDirectionItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(SortDirection, "SortDirection", reverse);
    }

    public getSortDirectionName(value: SortDirection): string {
        return this.getSortDirectionItem(value).text;
    }

    public getSortDirectionText(value: SortDirection): string {
        return this.localizer.get(this.getSortDirectionName(value));
    }

    public getSortDirectionDescription(value: SortDirection): string {
        return this.getSortDirectionItem(value).subtext;
    }

    // #endregion

    // #region WebApplicationType

    public getWebApplicationTypeItem(value: WebApplicationType): SelectListItem {
        return this.transform(WebApplicationType, "WebApplicationType", value);
    }

    public getWebApplicationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(WebApplicationType, "WebApplicationType", reverse);
    }

    public getWebApplicationTypeName(value: WebApplicationType): string {
        return this.getWebApplicationTypeItem(value).text;
    }

    public getWebApplicationTypeText(value: WebApplicationType): string {
        return this.localizer.get(this.getWebApplicationTypeName(value));
    }

    public getWebApplicationTypeDescription(value: WebApplicationType): string {
        return this.getWebApplicationTypeItem(value).subtext;
    }

    // #endregion

    // #region PasswordValidationError

    public getPasswordValidationErrorItem(value: PasswordValidationError): SelectListItem {
        return this.transform(PasswordValidationError, "PasswordValidationError", value);
    }

    public getPasswordValidationErrorItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(PasswordValidationError, "PasswordValidationError", reverse);
    }

    public getPasswordValidationErrorName(value: PasswordValidationError): string {
        return this.getPasswordValidationErrorItem(value).text;
    }

    public getPasswordValidationErrorText(value: PasswordValidationError): string {
        return this.localizer.get(this.getPasswordValidationErrorName(value));
    }

    public getPasswordValidationErrorDescription(value: PasswordValidationError): string {
        return this.getPasswordValidationErrorItem(value).subtext;
    }

    // #endregion

    // #region TokenType

    public getTokenTypeItem(value: TokenType): SelectListItem {
        return this.transform(TokenType, "TokenType", value);
    }

    public getTokenTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(TokenType, "TokenType", reverse);
    }

    public getTokenTypeName(value: TokenType): string {
        return this.getTokenTypeItem(value).text;
    }

    public getTokenTypeText(value: TokenType): string {
        return this.localizer.get(this.getTokenTypeName(value));
    }

    public getTokenTypeDescription(value: TokenType): string {
        return this.getTokenTypeItem(value).subtext;
    }

    // #endregion

    // #region DayOfWeek

    public getDayOfWeekItem(value: DayOfWeek): SelectListItem {
        return this.transform(DayOfWeek, "DayOfWeek", value);
    }

    public getDayOfWeekItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(DayOfWeek, "DayOfWeek", reverse);
    }

    public getDayOfWeekName(value: DayOfWeek): string {
        return this.getDayOfWeekItem(value).text;
    }

    public getDayOfWeekText(value: DayOfWeek): string {
        return this.localizer.get(this.getDayOfWeekName(value));
    }

    public getDayOfWeekDescription(value: DayOfWeek): string {
        return this.getDayOfWeekItem(value).subtext;
    }

    // #endregion
}

//Singleton
export default new EnumProvider();