import React from "react";
import {BaseComponent, IBaseComponentProps, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import {Accordion} from "@reapptor-apps/reapptor-react-components";
import ServiceProviderController from "@/pages/ServiceProviderController";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import styles from "./FaqPanel.module.scss";

interface IFaqPanelProps extends IBaseComponentProps {
}

interface IFaqPanelState {
}

interface IFaqItem {
    header: string;

    content: string;
}

export default class FaqPanel extends BaseComponent<IFaqPanelProps, IFaqPanelState> {

    state: IFaqPanelState = {
    };

    private getItems(): IFaqItem[] {
        if (this.asCaptain) {
            return [
                {
                    header: Localizer.mobileFaqPageWhereSeeRideHeading,
                    content: Localizer.mobileFaqPageWhereSeeRideText,
                },
                {
                    header: Localizer.mobileFaqPageHowCanAcceptRideHeading,
                    content: Localizer.mobileFaqPageHowCanAcceptRideText,
                },
                {
                    header: Localizer.mobileFaqPageHowCarryOutRideHeading,
                    content: Localizer.mobileFaqPageHowCarryOutRideText,
                },
                {
                    header: Localizer.mobileFaqPageHowCancelRideCaptainHeading,
                    content: Localizer.mobileFaqPageHowCancelRideCaptainText,
                },
                {
                    header: Localizer.mobileFaqPageWhenGetEarningsHeading,
                    content: Localizer.mobileFaqPageWhenGetEarningsText,
                },
                {
                    header: Localizer.mobileFaqPageHowDeleteProfileCaptainHeading,
                    content: Localizer.mobileFaqPageHowDeleteProfileCaptainText,
                },
            ];
        }

        if (ServiceProviderController.isPjta) {
            return [
                {
                    header: Localizer.mobileFaqPagePjtaHowMuchDoesItCostHeading,
                    content: Localizer.mobileFaqPagePjtaHowMuchDoesItCostText,
                },
                {
                    header: Localizer.mobileFaqPagePjtaHowDoIBookHeading,
                    content: Localizer.mobileFaqPagePjtaHowDoIBookText,
                },
                {
                    header: Localizer.mobileFaqPagePjtaOurGroupIsMoreThan6PassengersHeading,
                    content: Localizer.mobileFaqPagePjtaOurGroupIsMoreThan6PassengersText,
                },
                {
                    header: Localizer.mobileFaqPagePjtaWhereCanISeeMyReservationsHeading,
                    content: Localizer.mobileFaqPagePjtaWhereCanISeeMyReservationsText,
                },
                {
                    header: Localizer.mobileFaqPagePjtaHowDoICancelATripHeading,
                    content: Localizer.mobileFaqPagePjtaHowDoICancelATripText,
                },
                {
                    header: Localizer.mobileFaqPagePjtaHowDoIChangeOrAddANewCreditCardHeading,
                    content: Localizer.mobileFaqPagePjtaHowDoIChangeOrAddANewCreditCardText,
                },
                {
                    header: Localizer.mobileFaqPagePjtaHowDoIDeleteMyProfileHeading,
                    content: Localizer.mobileFaqPagePjtaHowDoIDeleteMyProfileText,
                },
                {
                    header: Localizer.mobileFaqPagePjtaWhenWillTheTripBeChargedHeading,
                    content: Localizer.mobileFaqPagePjtaWhenWillTheTripBeChargedText,
                },
                {
                    header: Localizer.mobileFaqPagePjtaHowDoIContactTheCaptainHeading,
                    content: Localizer.mobileFaqPagePjtaHowDoIContactTheCaptainText,
                },
                {
                    header: Localizer.mobileFaqPagePjtaHowCanIFindThePickUpDestinationPointHeading,
                    content: Localizer.mobileFaqPagePjtaHowCanIFindThePickUpDestinationPointText,
                },
                {
                    header: Localizer.mobileFaqPagePjtaWhatIfTheCaptainIsLateHeading,
                    content: Localizer.mobileFaqPagePjtaWhatIfTheCaptainIsLateText,
                },
                {
                    header: Localizer.mobileFaqPagePjtaWhatIfIAmLateHeading,
                    content: Localizer.mobileFaqPagePjtaWhatIfIAmLateText,
                },
                {
                    header: Localizer.mobileFaqPagePjtaWhatIfIForgetSomethingOnTheBoatHeading,
                    content: Localizer.mobileFaqPagePjtaWhatIfIForgetSomethingOnTheBoatText,
                },
                {
                    header: Localizer.mobileFaqPagePjtaIfACustomerPresentsHimselfInAnInconvenientStateHeading,
                    content: Localizer.mobileFaqPagePjtaIfACustomerPresentsHimselfInAnInconvenientStateText,
                },
                {
                    header: Localizer.mobileFaqPagePjtaOurGroupNeedsABoatAccessibleForWheelchairHeading,
                    content: Localizer.mobileFaqPagePjtaOurGroupNeedsABoatAccessibleForWheelchairText,
                },
            ];
        }

        if (ServiceProviderController.isHds) {
            return [
                {
                    header: Localizer.mobileFaqPageHDSHowDoIBookHeading,
                    content: Localizer.mobileFaqPageHDSHowDoIBookText,
                },
                {
                    header: Localizer.mobileFaqPageHDSHowMuchDoesItCostHeading,
                    content: Localizer.mobileFaqPageHDSHowMuchDoesItCostText,
                },
                {
                    header: Localizer.mobileFaqPageHDSHowManyTicketsCanBuyHeading,
                    content: Localizer.mobileFaqPageHDSHowManyTicketsCanBuyText,
                },
                {
                    header: Localizer.mobileFaqPageHDSWhereCanISeeMyTicketsHeading,
                    content: Localizer.mobileFaqPageHDSWhereCanISeeMyTicketsText,
                },
                {
                    header: Localizer.mobileFaqPageHDSHowDoICancelReservationHeading,
                    content: Localizer.mobileFaqPageHDSHowDoICancelReservationText,
                },
                {
                    header: Localizer.mobileFaqPageHDSHowDoIChangeOrAddANewCreditCardHeading,
                    content: Localizer.mobileFaqPageHDSHowDoIChangeOrAddANewCreditCardText,
                },
                {
                    header: Localizer.mobileFaqPageHDSHowDoIDeleteMyProfileHeading,
                    content: Localizer.mobileFaqPageHDSHowDoIDeleteMyProfileText,
                },
                {
                    header: Localizer.mobileFaqPageHDSWhenWillTheTripBeBilledHeading,
                    content: Localizer.mobileFaqPageHDSWhenWillTheTripBeBilledText,
                },
                {
                    header: Localizer.mobileFaqPageHDSHowCanIFindPointHeading,
                    content: Localizer.mobileFaqPageHDSHowCanIFindPointText,
                },
                {
                    header: Localizer.mobileFaqPageHDSWhatHappensIfShuttleIsLateHeading,
                    content: Localizer.mobileFaqPageHDSWhatHappensIfShuttleIsLateText,
                },
                {
                    header: Localizer.mobileFaqPageHDSWhatHappensIfIAmLateHeading,
                    content: Localizer.mobileFaqPageHDSWhatHappensIfIAmLateText,
                },
                {
                    header: Localizer.mobileFaqPageHDSWhatHappensIfIForgetSomethingHeading,
                    content: Localizer.mobileFaqPageHDSWhatHappensIfIForgetSomethingText,
                },
                {
                    header: Localizer.mobileFaqPageHDSWhatHappensIfACustomerIsDrunkHeading,
                    content: Localizer.mobileFaqPageHDSWhatHappensIfACustomerIsDrunkText,
                },
                {
                    header: Localizer.mobileFaqPageHDSIsWheelchairAccessibleHeading,
                    content: Localizer.mobileFaqPageHDSIsWheelchairAccessibleText,
                },
                {
                    header: Localizer.mobileFaqPageHDSArePetsAllowedHeading,
                    content: Localizer.mobileFaqPageHDSArePetsAllowedText,
                },
                {
                    header: Localizer.mobileFaqPageHDSCanITakeBikeOnBoardHeading,
                    content: Localizer.mobileFaqPageHDSCanITakeBikeOnBoardText,
                },
            ];
        }

        return [
            {
                header: Localizer.mobileFaqPageHowDoIBookHeading,
                content: Localizer.mobileFaqPageHowDoIBookText,
            },
            {
                header: Localizer.mobileFaqPageHowDoICancelPassengerHeading,
                content: Localizer.mobileFaqPageHowDoICancelPassengerText,
            },
            {
                header: Localizer.mobileFaqPageWillBeChargedHeading,
                content: Localizer.mobileFaqPageWillBeChargedText,
            },
            {
                header: Localizer.mobileFaqPageHowDoIContactCaptainHeading,
                content: Localizer.mobileFaqPageHowDoIContactCaptainText,
            },
            {
                header: Localizer.mobileFaqPageCantFindCertainPickUpHeading,
                content: Localizer.mobileFaqPageCantFindCertainPickUpText,
            },
            {
                header: Localizer.mobileFaqPageSuitableNotFoundHeading,
                content: Localizer.mobileFaqPageSuitableNotFoundText,
            },
            {
                header: Localizer.mobileFaqPageHowIBecomeCaptainHeading,
                content: Localizer.mobileFaqPageHowIBecomeText,
            },
            {
                header: Localizer.mobileFaqPageHowDeleteProfileHeading,
                content: Localizer.mobileFaqPageHowDeleteProfileText,
            },
        ];
    }

    public get asPassenger(): boolean {
        return AppController.asPassenger;
    }

    public get asCaptain(): boolean {
        return AppController.asCaptain;
    }

    private renderFaqItem(item: IFaqItem, index: number): React.ReactNode {
        return (
            <Accordion autoCollapse
                       key={index}
                       header={item.header}
            >
                <span>
                    {ReactUtility.toMultiLines(item.content)}
                </span>
            </Accordion>
        )
    }

    public render(): React.ReactNode {
        const items: IFaqItem[] = this.getItems();
        
        return (
            <div id={this.id} className={this.css(styles.faqPanel, this.props.className)}>

                <span className={styles.header}>{Localizer.genericFaq}</span>

                {
                    items.map((item, index) => this.renderFaqItem(item, index))
                }

            </div>
        )
    }
}
