import React from "react";
import CruisePackageWizard from "@/pages/Mobile/CruisePackageWizard/CruisePackageWizard";
import CruisePackage from "@/models/server/cruise/CruisePackage";
import BookingInfo from "@/pages/Mobile/BookingDetails/BookingInfo/BookingInfo";
import {GeoCoordinate, Utility} from "@reapptor-apps/reapptor-toolkit";
import {ch, LocalizationString, PageRoute, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import BookTripRequest from "@/models/server/requests/BookTripRequest";
import BookTripResponse from "@/models/server/responses/BookTripResponse";
import PageDefinitions from "@/providers/PageDefinitions";
import User from "@/models/server/User";
import RequestTripRequest from "@/models/server/requests/RequestTripRequest";
import RequestTripResponse from "@/models/server/responses/RequestTripResponse";
import CruisePackageBooking from "@/models/server/cruise/CruisePackageBooking";
import PersonalInfo from "@/pages/SignUp/PersonalInfo/PersonalInfo";
import {PageRow} from "@reapptor-apps/reapptor-react-components";
import CruisePackageWizardController from "@/pages/Mobile/CruisePackageWizard/CruisePackageWizardController";
import Localizer from "@/localization/Localizer";

import styles from "./ApproveStep.module.scss";

export interface IApproveStepProps {
}

interface IApproveStepState {
}

export default class ApproveStep extends CruisePackageWizard<IApproveStepProps, IApproveStepState> {

    state: IApproveStepState = {};

    private async onApproveTripAsync(): Promise<void> {
        const location: GeoCoordinate | null = await Utility.getLocationAsync();

        if (ch.isAuthenticated) {
            const request = new BookTripRequest(this.booking, location);
            
            const response: BookTripResponse = await this.postAsync("/api/mobileApp/bookTrip", request);
            
            await CruisePackageWizardController.completeActionAsync();

            const route: PageRoute = PageDefinitions.bookingDetailsById(response.booking!.id);

            await PageRouteProvider.redirectAsync(route);

            await this.alertMessageAsync(Localizer.mobileBookingDetailsPageRegistered)
        } else {
            const passenger: User = this.booking!.passenger!;
            
            const passengerEmail: string = passenger.email;
            
            const request = new RequestTripRequest(this.booking, location);

            const response: RequestTripResponse = await this.postAsync("/api/mobileApp/requestTrip", request);

            if (response.userAlreadyExists) {
                const message: string = (response.duplicatedEmail)
                    ? Localizer.adminAlertErrorAccountWithSameEmailExist
                    : Utility.format(Localizer.adminAlertErrorAccountExist, passenger);

                await this.alertErrorAsync(message, true);
                return;
            }

            if (response.invitationSentFailed) {
                const message: string = Utility.format(Localizer.signUpAlertErrorInvitationSentFailed, passenger);
                await this.alertErrorAsync(message, false);
                return;
            }
            
            await CruisePackageWizardController.completeActionAsync();

            const route: PageRoute = PageDefinitions.bookingDetails(response.booking!);

            await PageRouteProvider.redirectAsync(route);
            
            await this.alertMessageAsync(Localizer.signUpPageAlertErrorAccountCreated.format(passengerEmail), true, true);
        }
    }

    private async onCancelTripAsync(): Promise<void> {
        const confirmed: boolean = await this.confirmAsync(Localizer.mobileBookingDetailsPageCancelTripConfirm);

        if (confirmed) {
            await CruisePackageWizardController.startAsync();
        }
    }
    
    protected get contentClassName(): string {
        return styles.approveStep;
    }

    protected get buttonsClassName(): string {
        return styles.buttons;
    }

    public get canNext(): boolean {
        return (this.wizard.cruisePackage != null);
    }

    public get cruisePackage(): CruisePackage | null {
        return this.wizard.cruisePackage;
    }

    public get booking(): CruisePackageBooking | null {
        return this.wizard.booking;
    }

    public get title(): string | null {
        return LocalizationString.value(this.cruisePackage?.name);
    }

    public get hasGenericBackButton(): boolean {
        return false;
    }

    
    public get wide(): boolean {
        return !ch.isAuthenticated;
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        if ((!this.booking) || (!this.cruisePackage)) {
            await this.redirectHomeAsync();
        }
    }

    public renderLeftContentForNotAuthenticated(): React.ReactNode {
        return (
            <React.Fragment>

                {
                    ((this.cruisePackage) && (this.booking) && (this.booking.passenger)) &&
                    (
                        <div>

                            <PersonalInfo user={this.booking.passenger}
                                          asCaptain={false}
                                          nextButton={{icon: "fa-thumbs-up fal", label: Localizer.mobileBookingInfoButtonRequestTripLabel}}
                                          prevButton={{icon: "fa-ban fal", label: Localizer.mobileBookingInfoButtonCancelTripLabel}}
                                          onNext={() => this.onApproveTripAsync()}
                                          onPrev={() => this.onCancelTripAsync()}
                            />

                        </div>
                    )
                }

            </React.Fragment>
        );
    }

    public renderRightContentForNotAuthenticated(): React.ReactNode {
        return (
            <React.Fragment>

                {
                    ((this.cruisePackage) && (this.booking)) &&
                    (
                        <PageRow>

                            <BookingInfo readonly previewHasOnlyInfo
                                         item={this.booking}
                            />

                        </PageRow>
                    )
                }

            </React.Fragment>
        )
    }

    public renderContent(): React.ReactNode {
        return (
            <React.Fragment>

                {
                    ((this.cruisePackage) && (this.booking)) &&
                    (
                        <PageRow>
                            
                            <BookingInfo readonly previewHasOnlyInfo
                                         className={styles.bookingInfo}
                                         item={this.booking}
                                         approveTrip={() => this.onApproveTripAsync()}
                                         cancelTrip={() => this.onCancelTripAsync()}
                            />
                            
                        </PageRow>
                    )
                }

            </React.Fragment>
        );
    }

    public renderPageContent(): React.ReactNode {
        return (
            <React.Fragment>

                { (this.cruiseStyle) && this.renderLanguage() }

                { (this.cruiseStyle) && this.renderLogo() }

                {
                    (ch.isAuthenticated) &&
                    (
                        this.renderContentContainer(Localizer.cruisePackageWizardApproveStepSummary, () => this.renderContent())
                    )
                }
                
                {
                    (!ch.isAuthenticated) &&
                    (

                        <div className="row">

                            <div className="col-md-6">

                                { this.renderContentContainer(Localizer.cruisePackageWizardApproveStepAlmostReady, () => this.renderLeftContentForNotAuthenticated()) }

                            </div>

                            <div className="col-md-6">

                                { this.renderContentContainer(Localizer.cruisePackageWizardApproveStepSummary, () => this.renderRightContentForNotAuthenticated()) }

                            </div>

                        </div>

                    )
                }

            </React.Fragment>
        )
    }
}