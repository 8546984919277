import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {Form, OneColumn, Panel, TextInput, UrlInput} from "@reapptor-apps/reapptor-react-components";
import ServiceProvider from "@/models/server/ServiceProvider";
import SocialMediaLinks from "@/models/server/serviceproviderinfo/SocialMediaLinks";

import styles from "./SocialMediaLinks.module.scss"

interface ISocialMediaLinksProps {
    serviceProvider: ServiceProvider;
}

interface ISocialMediaLinksState {
}

export default class SocialMediaLinksPanel extends BaseComponent<ISocialMediaLinksProps, ISocialMediaLinksState> {

    state: ISocialMediaLinksState = {};

    private readonly _formRef: React.RefObject<Form> = React.createRef();

    private get socialMediaLinks(): SocialMediaLinks {
        if (this.serviceProvider.socialMediaLinks == null) {
            this.serviceProvider.socialMediaLinks = new SocialMediaLinks();
        }
        return this.serviceProvider.socialMediaLinks;
    }

    private async setFacebookAsync(value: string): Promise<void> {
        this.socialMediaLinks.facebookUrl = value;
        await this.reRenderAsync();
    }

    private async setInstagramUrlAsync(value: string): Promise<void> {
        this.socialMediaLinks.instagramUrl = value;
        await this.reRenderAsync();
    }

    private async setYoutubeUrlAsync(value: string): Promise<void> {
        this.socialMediaLinks.youtubeUrl = value;
        await this.reRenderAsync();
    }

    private async setLinkedinUrlAsync(value: string): Promise<void> {
        this.socialMediaLinks.linkedinUrl = value;
        await this.reRenderAsync();
    }

    private async setTwitterUrlAsync(value: string): Promise<void> {
        this.socialMediaLinks.twitterUrl = value;
        await this.reRenderAsync();
    }

    private async setDiscordUrlAsync(value: string): Promise<void> {
        this.socialMediaLinks.discordUrl = value;
        await this.reRenderAsync();
    }

    public async isFormValidAsync(): Promise<boolean> {
        return this._formRef.current?.validateAsync() || false;
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public get serviceProvider(): ServiceProvider {
        return this.props.serviceProvider;
    }

    public render(): React.ReactNode {
        return (
            <Panel className={this.css("flex-2", styles.socialMediaPanel)}>
                <div className="col-12">

                    <Form ref={this._formRef} className={styles.form}>

                        <OneColumn>

                            <UrlInput id="facebook" noAutoComplete
                                       label={"Facebook"}
                                       value={this.socialMediaLinks.facebookUrl}
                                       onChange={(_, value) => this.setFacebookAsync(value)}
                            />

                        </OneColumn>

                        <OneColumn>

                            <UrlInput id="twitter" noAutoComplete
                                       label={"Twitter"}
                                       value={this.socialMediaLinks.twitterUrl}
                                       onChange={(_, value) => this.setTwitterUrlAsync(value)}
                            />

                        </OneColumn>

                        <OneColumn>

                            <UrlInput id="instagram" noAutoComplete
                                       label={"Instagram"}
                                       value={this.socialMediaLinks.instagramUrl}
                                       onChange={(_, value) => this.setInstagramUrlAsync(value)}
                            />

                        </OneColumn>

                        <OneColumn>

                            <UrlInput id="linkedIn" noAutoComplete
                                       label={"LinkedIn"}
                                       value={this.socialMediaLinks.linkedinUrl}
                                       onChange={(_, value) => this.setLinkedinUrlAsync(value)}
                            />

                        </OneColumn>

                        <OneColumn>

                            <UrlInput id="youtube" noAutoComplete
                                       label={"Youtube"}
                                       value={this.socialMediaLinks.youtubeUrl}
                                       onChange={(_, value) => this.setYoutubeUrlAsync(value)}
                            />

                        </OneColumn>

                        <OneColumn>

                            <UrlInput id="discord" noAutoComplete
                                       label={"Discord"}
                                       value={this.socialMediaLinks.discordUrl}
                                       onChange={(_, value) => this.setDiscordUrlAsync(value)}
                            />

                        </OneColumn>
                                    
                    </Form>

                </div>

            </Panel>
        );
    }
};