import React from "react";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import {BaseComponent, LocalizationString} from "@reapptor-apps/reapptor-react-common";
import EstimatedBooking from "@/models/server/bout/EstimatedBooking";
import Booking from "@/models/server/bout/Booking";
import EstimatedBoatBooking from "@/models/server/bout/EstimatedBoatBooking";
import User from "@/models/server/User";
import CruisePackageBooking from "@/models/server/cruise/CruisePackageBooking";
import {BoatType, BookingType, TicketType} from "@/models/Enums";
import {Icon, IconSize, TwoColumns} from "@reapptor-apps/reapptor-react-components";
import BookingAvatar, {BookingAvatarType} from "@/components/BookingAvatar/BookingAvatar";
import CruisePackagePreviewModal from "@/components/CruisePackagePreviewModal/CruisePackagePreviewModal";
import CruisePackage from "@/models/server/cruise/CruisePackage";
import ShuttleEstimatedBooking from "@/models/server/shuttle/ShuttleEstimatedBooking";
import Boat from "@/models/server/bout/Boat";
import BoatImage from "@/models/server/BoatImage";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import styles from "./HeaderInfo.module.scss";

export interface IHeaderInfoProps {
    booking: EstimatedBooking | Booking | CruisePackageBooking;
    previewHasOnlyInfo?: boolean;
}

interface IHeaderInfoState {
}

export default class HeaderInfo extends BaseComponent<IHeaderInfoProps, IHeaderInfoState> {

    state: IHeaderInfoState = {};

    private readonly _cruisePackagePreviewModalRef: React.RefObject<CruisePackagePreviewModal> = React.createRef();

    private async previewCruisePackageAsync(): Promise<void> {
        if ((this.cruisePackage) && (this._cruisePackagePreviewModalRef.current)) {
            await this._cruisePackagePreviewModalRef.current.openAsync(this.cruisePackage);
        }
    }

    public get item(): EstimatedBooking | Booking | CruisePackageBooking {
        return this.props.booking;
    }

    public get estimatedBooking(): EstimatedBooking | null {
        return EstimatedBooking.as(this.item);
    }

    public get isCruisePackage(): boolean {
        return ((this.cruisePackageBooking != null) || (this.booking?.bookingType == BookingType.CruisePackage));
    }

    public get isShuttle(): boolean {
        return ((this.shuttleBooking != null) || (this.booking?.bookingType == BookingType.Shuttle));
    }

    public get isRide(): boolean {
        return (!this.isShuttle) && (!this.isCruisePackage);
    }

    public get isCruisePackageBooking(): boolean {
        return CruisePackageBooking.is(this.item);
    }

    public get cruisePackageBooking(): CruisePackageBooking | null {
        return CruisePackageBooking.as(this.item);
    }

    public get shuttleBooking(): ShuttleEstimatedBooking | null {
        return ShuttleEstimatedBooking.as(this.item);
    }

    public get booking(): Booking | null {
        return Booking.as(this.item);
    }

    public get passenger(): User | null {
        return (this.item as Booking).passenger ?? (this.item as CruisePackageBooking).passenger;
    }

    public get asCaptain(): boolean {
        return AppController.asCaptain;
    }

    public get boat(): Boat | null {
        return this.booking?.boat ?? this.cruisePackageBooking?.cruisePackage?.boat ?? this.singleEstimatedBoatBooking?.boat ?? null;
    }

    public get boatType(): BoatType | null {
        return this.booking?.boatType ?? this.estimatedBooking?.boatType ?? null;
    }

    public get cruisePackage(): CruisePackage | null {
        return this.cruisePackageBooking?.cruisePackage ?? this.booking?.cruisePackage ?? null;
    }

    public get singleEstimatedBoatBooking(): EstimatedBoatBooking | null {
        const estimatedBoatBookings: EstimatedBoatBooking[] = this.estimatedBooking?.boats ?? [];
        return (estimatedBoatBookings.length == 1)
            ? estimatedBoatBookings[0]
            : null;
    }

    public get userAvatarId(): string | null {

        const user: User | null = (this.asCaptain)
            ? this.booking?.passenger ??
            this.cruisePackageBooking?.passenger ??
            null
            : this.booking?.captain ??
            this.cruisePackage?.boat?.captain ??
            this.singleEstimatedBoatBooking?.boat?.captain ??
            null;

        return (user != null)
            ? user.avatarId || null
            : null;
    }

    public get boatPrimaryImage(): BoatImage | null {

        const boat: Boat | null = (
            this.booking?.boat ??
            this.cruisePackageBooking?.cruisePackage?.boat ??
            this.singleEstimatedBoatBooking?.boat ??
            null
        );

        return Boat.findPrimaryImage(boat);
    }

    public get passengersCount(): number {
        return this.item.passengers;
    }

    public get childrenCount(): number {
        return this.cruisePackageBooking?.children ?? this.estimatedBooking?.children ?? this.booking?.children ?? 0;
    }

    public get bikesAndTrolleys(): number {
        return this.booking?.bikesAndTrolleys ?? this.estimatedBooking?.bikesAndTrolleys ?? 0;
    }

    public get pensionersCount(): number {
        return this.booking?.pensioners ?? this.estimatedBooking?.pensioners ?? 0;
    }

    public get adultsCount(): number {
        return (this.passengersCount - this.pensionersCount - this.childrenCount - this.bikesAndTrolleys);
    }

    public get avatarType(): BookingAvatarType {
        return (this.booking?.bookingType != BookingType.Shuttle)
            ? BookingAvatarType.Boat
            : BookingAvatarType.BoatOnly;
    }

    public get name(): string {
        return (this.cruisePackage)
            ? LocalizationString.value(this.cruisePackage.name)
            : (this.boat)
                ? Utility.formatValue(this.boat)
                : (this.boatType != null)
                    ? Utility.formatValue(this.boatType, nameof<BoatType>())
                    : "";
    }

    public get cruisePackageOwnerName(): string | null {
        return (this.cruisePackage)
            ? (this.cruisePackage.serviceProvider)
                ? Utility.formatValue(this.cruisePackage.serviceProvider)
                : (this.cruisePackage.owner)
                    ? Utility.formatValue(this.cruisePackage.owner)
                    : null
            : null;
    }

    public get nextSeasonStartsAt(): Date | null {
        return CruisePackage.nextSeasonStartsAt(this.cruisePackage);
    }

    public get showNextSeason(): boolean {
        return (
            (this.isCruisePackageBooking) &&
            (this.cruisePackage != null) &&
            (this.cruisePackage.hasSeason) &&
            (!this.cruisePackage.isInSeason)
        );
    }

    public get previewHasOnlyInfo(): boolean {
        return (this.props.previewHasOnlyInfo == true);
    }

    public render(): React.ReactNode {
        const name: string = this.name;
        const nextSeasonStartsAt: Date | null = this.nextSeasonStartsAt;
        const showNextSeason: boolean = this.showNextSeason;
        const mobileStyles: any = this.mobile && styles.mobile;

        return (
            <div className={styles.headerInfo}>

                <BookingAvatar type={this.avatarType}
                               avatar={this.userAvatarId}
                               boatImage={this.boatPrimaryImage}
                />

                <div className={this.css(styles.headerContext, this.mobile && styles.mobile)}>

                    {
                        (name) &&
                        (
                            <TwoColumns leftClassName={this.css(styles.left, this.mobile && styles.mobile)}
                                        rightClassName={styles.right}
                            >

                                <span>{Localizer.mobileHeaderInfoNameSpan}</span>

                                <span className={this.css(styles.generalInfo, mobileStyles)}>
                                    {name}
                                </span>

                            </TwoColumns>
                        )
                    }

                    {
                        ((this.asCaptain) && (this.passenger)) &&
                        (
                            <TwoColumns leftClassName={this.css(styles.left, this.mobile && styles.mobile)}
                                        rightClassName={styles.right}
                            >

                                <span>{Localizer.mobileHeaderInfoPassengerSpan}</span>

                                <span className={this.css(styles.passengerInfo, mobileStyles)}>
                                    {"{0}".format(this.passenger)}
                                </span>

                            </TwoColumns>
                        )
                    }

                    {
                        (this.cruisePackage) &&
                        (
                            <TwoColumns leftClassName={this.css(styles.left, this.mobile && styles.mobile)}
                                        rightClassName={styles.right}
                            >

                                <span>{Localizer.mobileHeaderInfoOwnerName}</span>

                                <span className={this.css(styles.generalInfo, mobileStyles)}>
                                    {this.cruisePackageOwnerName}
                                </span>

                            </TwoColumns>
                        )
                    }

                    {
                        (!this.isCruisePackageBooking) &&
                        (
                            <TwoColumns leftClassName={this.css(styles.left, mobileStyles)}
                                        rightClassName={styles.right}
                            >

                                <span>
                                    {
                                        (this.isShuttle)
                                            ? Localizer.mobileHeaderInfoTicketsSpan
                                            : Localizer.mobileHeaderInfoFareSpan
                                    }
                                </span>

                                <span className={styles.importantInfo}>

                                    {
                                        (this.isRide)
                                        ?
                                        (
                                            <React.Fragment>
                                                {this.passengersCount}
                                            </React.Fragment>
                                        )
                                        :
                                        (
                                        <React.Fragment>
                                            
                                            {this.adultsCount} {TicketType.Adult.format(nameof<TicketType>())}
    
                                            {
                                                (this.pensionersCount > 0) &&
                                                (
                                                    <>
                                                        <br/>
                                                        <span
                                                            className={styles.pensioners}>{this.pensionersCount} {TicketType.Pensioners.format(nameof<TicketType>())}</span>
                                                    </>
                                                )
                                            }
    
                                            {
                                                (this.childrenCount > 0) &&
                                                (
                                                    <>
                                                        <br/>
                                                        <span
                                                            className={styles.children}>{this.childrenCount} {TicketType.Children.format(nameof<TicketType>())}</span>
                                                    </>
                                                )
                                            }
    
                                            {
                                                (this.bikesAndTrolleys > 0) &&
                                                (
                                                    <>
                                                        <br/>
                                                        <span
                                                            className={styles.bikesAndTrolleys}>{this.bikesAndTrolleys} {TicketType.BikesAndTrolleys.format(nameof<TicketType>())}</span>
                                                    </>
                                                )
                                            }
                                        </React.Fragment>
                                        )
                                    }

                                </span>

                            </TwoColumns>
                        )
                    }

                    {
                        ((showNextSeason) && (nextSeasonStartsAt)) &&
                        (
                            <TwoColumns leftClassName={this.css(styles.left, mobileStyles)}
                                        rightClassName={styles.right}
                            >

                                <span>{Localizer.mobileHeaderInfoSeasonStartsAtSpan}</span>

                                <span className={styles.importantInfo}>
                                {
                                    nextSeasonStartsAt.format("dd.MM.yyyy")
                                }
                                </span>

                            </TwoColumns>
                        )
                    }

                </div>

                {
                    (this.cruisePackage) &&
                    (
                        <div className={styles.details}>
                            <Icon name="fal info"
                                  size={IconSize.Large}
                                  onClick={() => this.previewCruisePackageAsync()}
                            />
                        </div>
                    )
                }

                <CruisePackagePreviewModal details
                                           id={"cruisePackagePreviewModal"}
                                           ref={this._cruisePackagePreviewModalRef}
                                           hasOnlyInfo={this.previewHasOnlyInfo}
                />

            </div>
        );
    }
}