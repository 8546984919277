import React from "react";
import {BaseComponent, ch, IBaseComponent, IBaseComponentProps} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonType, ColumnDefinition, FileInput, Grid, GridHoveringType, GridOddType, JustifyContent, ToolbarContainer, ToolbarRow} from "@reapptor-apps/reapptor-react-components";
import {FileModel, IPagedList} from "@reapptor-apps/reapptor-toolkit";
import BoatPrice from "@/models/server/bout/BoatPrice";
import Waypoint from "@/models/server/bout/Waypoint";
import ListWaypointsRequest from "@/models/server/requests/ListWaypointsRequest";
import ImportFixedPricesRequest from "@/models/server/requests/ImportFixedPricesRequest";
import ImportFixedPricesResponse from "@/models/server/responses/ImportFixedPricesResponse";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import styles from "./FixedPricesPanel.module.scss";

export interface IFixedPricesPanelProps extends IBaseComponentProps {
    boatPrice: BoatPrice;
}

interface IFixedPricesPanelState {
}

export default class FixedPricesPanel extends BaseComponent<IFixedPricesPanelProps, IFixedPricesPanelState> {

    state: IFixedPricesPanelState = {
    };

    private readonly _importFileInputRef: React.RefObject<FileInput> = React.createRef();
    private readonly _waypointGridRef: React.RefObject<Grid<Waypoint>> = React.createRef();

    private readonly _waypointsColumns: ColumnDefinition[] = [
        {
            name: "source",
            header: Localizer.wayPointsPanelGridSourceLanguageItemName,
            accessor: nameof<Waypoint>(o => o.source),
            noWrap: true,
            minWidth: 250,
            settings: {
                infoAccessor: nameof.full<Waypoint>(o => o.source!.location)
            },
        } as ColumnDefinition,
        {
            name: "destination",
            header: Localizer.wayPointsPanelGridDestinationLanguageItemName,
            accessor: nameof<Waypoint>(o => o.destination),
            noWrap: true,
            minWidth: 250,
            settings: {
                infoAccessor: nameof.full<Waypoint>(o => o.destination!.location)
            },
        } as ColumnDefinition,
        {
            header: Localizer.wayPointsPanelGridCreatedLanguageItemName,
            accessor: nameof<Waypoint>(o => o.createdAt),
            noWrap: true,
            minWidth: 110,
            maxWidth: 110,
            stretch: false,
            format: "D",
        } as ColumnDefinition,
        {
            header: Localizer.wayPointsPanelGridPriceLanguageItemName,
            accessor: (model: Waypoint) => this.getPriceValue(model),
            noWrap: true,
            minWidth: 110,
            maxWidth: 110,
            stretch: false
        } as ColumnDefinition,
    ];

    private getPriceValue(model: Waypoint): string {
        const fixedPrice: number = model
            .fixedPrices
            ?.firstOrDefault(item => item.boatPriceId == this.boatPrice.id)
            ?.price
            ?? 0;
        
        const moneySymbol: string = this.boatPrice.area?.country?.moneySymbol ?? "";
        
        return (fixedPrice)
            ? "{0:C} {1}".format(fixedPrice, moneySymbol)
            : "-";
    }

    private async fetchWaypointsAsync(sender: IBaseComponent, pageNumber: number, pageSize: number): Promise<IPagedList<Waypoint>> {
        const request = new ListWaypointsRequest();
        //request.activeOnly = this.state.activeOnly;
        //request.search = this.state.search;
        request.fixedPriceBoutPriceId = this.boatPrice.id;
        request.includeIntermediateWaypoints = false;
        request.includeFixedPrices = true;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.asAdmin = AppController.asAdmin;

        return await sender.postAsync("/api/boatPrice/listWaypoints", request);
    }

    private async openImportFileInputAsync(): Promise<void> {
        await this._importFileInputRef.current?.openAsync();
    }

    private async onImportAsync(file: FileModel): Promise<void> {
        const request = new ImportFixedPricesRequest();
        request.file = file;
        request.overrideExisting = true;
        request.boatPriceId = this.boatPrice.id!;

        const response: ImportFixedPricesResponse = await this.postAsync("/api/boatPrice/importFixedPrices", request);

        if (response.success) {
            await ch.flyoutMessageAsync(Localizer.genericImportSuccess);

            await this.reloadAsync();
        } else {
            await ch.flyoutErrorAsync(Localizer.genericImportFailed);
        }
    }
    
    private async reloadAsync(): Promise<void> {
        await this._waypointGridRef.current?.reloadAsync();
    }
    
    private get boatPrice(): BoatPrice {
        return this.props.boatPrice;
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id}
                 className={this.css(this.props.className, styles.fixedPricesPanel)}
            >

                <ToolbarContainer>

                    <ToolbarRow justify={JustifyContent.End}>

                        <React.Fragment>

                            <FileInput inline hidden
                                       ref={this._importFileInputRef}
                                       fileTypes={[".xlsx", ".csv"]}
                                       onChange={(_, file: FileModel) => this.onImportAsync(file)}
                            />

                            <Button block
                                    label={"Excel"}
                                    icon={{name: "fas fa-file-upload"}}
                                    type={ButtonType.Primary}
                                    onClick={() => this.openImportFileInputAsync()}
                            />

                        </React.Fragment>

                    </ToolbarRow>

                    <Grid autoToggle responsive
                          ref={this._waypointGridRef}
                          pagination={20}
                          className={this.css(styles.grid)}
                          hovering={GridHoveringType.Row}
                          odd={GridOddType.None}
                          minWidth="auto"
                          noDataText={Localizer.genericNoData}
                          columns={this._waypointsColumns}
                          fetchData={(sender, pageNumber: number, pageSize: number) => this.fetchWaypointsAsync(sender, pageNumber, pageSize)}
                    />

                </ToolbarContainer>
                
            </div>
        )
    }
}