// autogenerated

export default class UserDashboardInfoRequest {

    public from: Date = new Date();

    public areas: string[] | null = null;

    public readonly isUserDashboardInfoRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof UserDashboardInfoRequest) || ((from as any).isUserDashboardInfoRequest === true));
    }

    public static as(from?: object | null): UserDashboardInfoRequest | null {
        return (UserDashboardInfoRequest.is(from)) ? from as UserDashboardInfoRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}