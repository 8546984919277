import React from "react";
import {BaseComponent, IBaseComponentProps} from "@reapptor-apps/reapptor-react-common";

import skeletonStyles from "../Skeleton.module.scss";
import styles from "./SkeletonSquare.module.scss";

export interface ISkeletonSquareProps extends IBaseComponentProps {
    size?: number | string;
}

export default class SkeletonSquare extends BaseComponent<ISkeletonSquareProps> {

    public render(): React.ReactNode {

        const inlineStyles: React.CSSProperties = {
            width: this.props.size,
            height: this.props.size,
        };
        
        return (
            <div id={this.id} className={this.css(skeletonStyles.skeletonItem, styles.skeletonSquare, this.props.className)} style={inlineStyles}>
            </div>
        )
    }
}