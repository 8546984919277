// autogenerated
import {GeoCoordinate} from "@reapptor-apps/reapptor-toolkit";
import EstimatedBooking from "@/models/server/bout/EstimatedBooking";
import CruisePackageBooking from "@/models/server/cruise/CruisePackageBooking";
import ShuttleEstimatedBooking from "@/models/server/shuttle/ShuttleEstimatedBooking";

export default class BookTripRequest {

    public bookingTime: Date | null = null;

    public booking: EstimatedBooking | null = null;

    public cruisePackageBooking: CruisePackageBooking | null = null;

    public shuttleBooking: ShuttleEstimatedBooking | null = null;

    public location: GeoCoordinate | null = null;

    public readonly isBookTripRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof BookTripRequest) || ((from as any).isBookTripRequest === true));
    }

    public static as(from?: object | null): BookTripRequest | null {
        return (BookTripRequest.is(from)) ? from as BookTripRequest : null;
    }

    // #region Placeholder

    constructor(booking?: EstimatedBooking | ShuttleEstimatedBooking | CruisePackageBooking | null, location?: GeoCoordinate | null) {
        this.booking = EstimatedBooking.as(booking);
        this.cruisePackageBooking = CruisePackageBooking.as(booking);
        this.shuttleBooking = ShuttleEstimatedBooking.as(booking);
        this.location = location ?? null;
        this.bookingTime = booking?.bookingTime || null;
    }

    // #endregion
}