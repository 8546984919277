import React from "react";
import {ch, Justify, PageRoute, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {Button, ButtonContainer, ButtonType, DateInput, Icon, PageContainer} from "@reapptor-apps/reapptor-react-components";
import {DateUtility, Utility} from "@reapptor-apps/reapptor-toolkit";
import GetMyCalendarResponse from "@/models/server/responses/GetMyCalendarResponse";
import CaptainAvailableDate from "@/models/server/bout/CaptainAvailableDate";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import AvailableDateModal from "@/pages/Mobile/MyCalendar/AvailableDateModal/AvailableDateModal";
import PageDefinitions from "@/providers/PageDefinitions";
import Localizer from "@/localization/Localizer";

import boutStyles from "../../../bout.module.scss";
import styles from "./MyCalendar.module.scss";

interface IMyCalendarProps {
}

interface IMyCalendarState {
    month: Date;
    calendar: GetMyCalendarResponse,
}

export default class MyCalendar extends AuthorizedPage<IMyCalendarProps, IMyCalendarState> {

    state: IMyCalendarState = {
        month: this.getTodayMonth(),
        calendar: new GetMyCalendarResponse()
    };
    
    private readonly _calendarRef: React.RefObject<DateInput> = React.createRef();
    private readonly _availableDateModalRef: React.RefObject<AvailableDateModal> = React.createRef();
    
    private getTodayMonth(): Date {
        const date: Date = Utility.today();
        date.setDate(1);
        return date.date();
    }
    
    private getDefaultMinDate(): Date {
        const date = new Date(this.month);
        date.setHours(8);
        return date;
    }
    
    private getDefaultMaxDate(): Date {
        const date = new Date(this.month);
        date.setHours(20);
        return date;
    }
    
    private isHoliday(date: Date): boolean {
        const captainDate: CaptainAvailableDate | null = this.availableDates.find(item => (DateUtility.equals(item.date, date))) ?? null;
        return (captainDate != null) && (captainDate.holiday);
    }
    
    private hasBookedTrip(date: Date): boolean {
        return this.bookedTrips.some(item => (DateUtility.equals(item, date)));
    }
    
    private hasConfirmedTrip(date: Date): boolean {
        return this.confirmedTrips.some(item => (DateUtility.equals(item, date)));
    }
    
    private hasCustomWorkingDate(date: Date, defaultMinDate: Date, defaultMaxDate: Date): boolean {
        const captainDate: CaptainAvailableDate | null = this.availableDates.find(item => (DateUtility.equals(item.date, date))) ?? null;
        if ((captainDate != null) && (!captainDate.holiday)) {
            if (((captainDate.startTime != null) && (captainDate.startTime.format("t") != defaultMinDate.format("t"))) ||
                ((captainDate.endTime != null) && (captainDate.endTime.format("t") != defaultMaxDate.format("t")))
            ) {
                return true;
            }
        }
        return false;
    }
    
    private getDatesClassNames(): Dictionary<Date, string[]> {
        const datesClassNames = new Dictionary<Date, string[]>();
        
        let date: Date = this.month.date().addDays(-6);
        
        const defaultMinDate: Date = this.getDefaultMinDate();
        const defaultMaxDate: Date = this.getDefaultMaxDate();
        
        for (let i: number = 0; i < 44; i++) {
            if (this.hasConfirmedTrip(date)) {
                datesClassNames.setValue(date, [styles.confirmed]);
            } else if (this.isHoliday(date)) {
                datesClassNames.setValue(date, [styles.holiday]);
            } else if (this.hasBookedTrip(date)) {
                datesClassNames.setValue(date, [styles.booked]);
            }

            const hasCustomWorkingDate: boolean = this.hasCustomWorkingDate(date, defaultMinDate, defaultMaxDate);
            if (hasCustomWorkingDate) {
                const classNames: string[] = datesClassNames.getValue(date) || [];
                classNames.push(styles.custom);
                datesClassNames.setValue(date, classNames);
            }

            date = date.addDays(1);
        }
        
        return datesClassNames;
    }
    
    private async assignDatesClassNamesAsync(): Promise<void> {
        if (this._calendarRef.current) {
            
            // wait for 1 sec to synchronize calendar rendering
            await Utility.wait(1);
            
            const datesClassNames: Dictionary<Date, string[]> = this.getDatesClassNames();
            
            const node = this.JQuery("#" + this._calendarRef.current.id);
            const children = node.find(".react-datepicker__week div.react-datepicker__day");
            
            const dates: JQuery[] = [];
            for (let i: number = 0; i < children.length; i++) {
                dates.push(this.JQuery(children[i]));
            }

            const firstIndex = dates.findIndex(date => !date.hasClass("react-datepicker__day--outside-month"));

            let date: Date = this.month;
            if (firstIndex > 0) {
                date = date.addDays(-firstIndex);
            }

            for (let i: number = 0; i < dates.length; i++) {
                const dateNode: JQuery = this.JQuery(children[i]);
                const dateClassNames: string[] = datesClassNames.getValue(date) || [];
                
                // remove obsolete classes
                const classNamesToRemove: string[] = [styles.booked, styles.confirmed, styles.holiday, styles.custom];
                classNamesToRemove.remove(dateClassNames);

                for (let j: number = 0; j < classNamesToRemove.length; j++) {
                    const classNameToRemove: string = classNamesToRemove[j];
                    if (dateNode.hasClass(classNameToRemove)) {
                        dateNode.removeClass(classNameToRemove);
                    }
                }
                
                // add new class
                for (let j: number = 0; j < dateClassNames.length; j++) {
                    const dateClassName: string = dateClassNames[j];
                    if (!dateNode.hasClass(dateClassName)) {
                        dateNode.addClass(dateClassName);
                    }
                }
                
                date = date.addDays(1);
            }
        }
    }
    
    private async nextMonthAsync(): Promise<void> {
        const month: Date = this.month.addMonths(1);
        
        await this.setState({month});

        await this.assignDatesClassNamesAsync();
    }
    
    private async prevMonthAsync(): Promise<void> {
        const month: Date = this.month.addMonths(-1);
        
        await this.setState({month});

        await this.assignDatesClassNamesAsync();
    }
    
    private async openAvailableDateModalAsync(date: Date): Promise<void> {
        date = date.date();
        if (this._availableDateModalRef.current) {
            let captainAvailableDate: CaptainAvailableDate | null = this.availableDates.find(item => DateUtility.equals(item.date, date)) || null;
            if (captainAvailableDate == null) {
                captainAvailableDate = new CaptainAvailableDate();
                captainAvailableDate.date = date;
                captainAvailableDate.holiday = false;
                captainAvailableDate.captainId = ch.getUserId();
            }
            await this._availableDateModalRef.current.openAsync(captainAvailableDate);
        }

        await this.assignDatesClassNamesAsync();
    }
    
    private async saveAvailableDateAsync(captainDate: CaptainAvailableDate): Promise<void> {
        const existingAvailableDate: CaptainAvailableDate | null = this.availableDates.find(item => DateUtility.equals(item.date, captainDate.date)) || null;

        if (!existingAvailableDate) {
            this.availableDates.push(captainDate);
        }
        
        await this.postAsync("/api/mobileApp/saveMyCalendarDay", captainDate);

        await this.assignDatesClassNamesAsync();
    }
    
    private async openBookedTripsAsync(): Promise<void> {
        const route: PageRoute = PageDefinitions.myTrips(true);
        await PageRouteProvider.redirectAsync(route);
    }
    
    private async openConfirmedTripsAsync(): Promise<void> {
        const route: PageRoute = PageDefinitions.myTrips(false, true);
        await PageRouteProvider.redirectAsync(route);
    }

    private get month(): Date {
        return this.state.month;
    }

    private get canPrev(): boolean {
        return (Utility.diff(this.month, Utility.today()).days > 0);
    }

    private get bookedTrips(): Date[] {
        return this.state.calendar.bookedTrips ?? (this.state.calendar.bookedTrips = []);
    }

    private get confirmedTrips(): Date[] {
        return this.state.calendar.confirmedTrips ?? (this.state.calendar.confirmedTrips = []);
    }

    private get availableDates(): CaptainAvailableDate[] {
        return this.state.calendar.availableDates ?? (this.state.calendar.availableDates = []);
    }
    
    private get ridesToday(): number {
        return this.confirmedTrips.count(item => item.isToday());
    }

    private get readonlyDates(): Date[] {
        const dates: Date[] = [];
        const firstDate: Date = this.month;
        let date: Date = firstDate.addDays(-6);
        for (let i: number = 0; i < 44; i++) {
            const isReadonly: boolean = (date.inPast(true)) || (date.getMonth() != firstDate.getMonth()) || (this.hasConfirmedTrip(date));
            if (isReadonly) {
                dates.push(date);
            }
            date = date.addDays(1);
        }
        return dates;
    }
    
    private get bookedTripsButtonLabel(): string {
        return Localizer.mobileMyCalendarPageBookedTripsButtonLabel.format(this.bookedTrips.length);
    }
    
    private get confirmedTripsButtonLabel(): string {
        return Localizer.mobileMyCalendarPageConfirmedTripsButtonLabel.format(this.confirmedTrips.length);
    }

    public getTitle(): string {
        return Localizer.myCalendarPageTitle;
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const calendar: GetMyCalendarResponse = await this.postAsync("/api/mobileApp/getMyCalendar");

        await this.setState({calendar});

        await this.assignDatesClassNamesAsync();
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.myCalendar, this.mobile && styles.mobile)}
                           alertClassName={boutStyles.alert}
            >

                <div className={styles.monthSelector}>
                    
                    <Icon name={"fal fa-arrow-circle-left"}
                          disabled={!this.canPrev}
                          onClick={() => this.prevMonthAsync()}
                    />
                    
                    <span>{this.month.format("MMMM")}</span>
                    
                    <Icon name={"fal fa-arrow-circle-right"}
                          onClick={() => this.nextMonthAsync()}
                    />
                    
                </div>
                
                <div className={styles.calendarContainer}>
                    
                    <div className={styles.legend}>
                        <span className={styles.booked}>{Localizer.mobileMyCalendarPageBookingsSpan}</span>
                        <span className={styles.confirmed}>{Localizer.mobileMyCalendarPageConfirmedSpan}</span>
                        <span className={styles.holiday}>{Localizer.mobileMyCalendarPageHolidaySpan}</span>
                    </div>

                    <DateInput expanded
                               id={"myCalendar"}
                               ref={this._calendarRef}
                               className={styles.calendar}
                               value={this.month}
                               excludeDates={this.readonlyDates}
                               onItemClick={(sender, date: Date) => this.openAvailableDateModalAsync(date)}
                    />

                    <div className={styles.today}>
                        <span>{"{0:dddd} {0:d}".format(Utility.today())}</span>
                        <span>{(this.ridesToday > 0) ? Localizer.mobileMyCalendarPageRidesCountSpan.format(this.ridesToday) : Localizer.mobileMyCalendarPageNoRideSpan}</span>
                    </div>

                    <div className={styles.expander} />

                    <ButtonContainer className={styles.buttons}>

                        <Button block
                                right={false}
                                icon={{name: "far fa-chevron-right"}}
                                iconPosition={Justify.Right}
                                label={this.bookedTripsButtonLabel}
                                type={ButtonType.Primary}
                                onClick={() => this.openBookedTripsAsync()}
                        />

                        <Button block
                                right={false}
                                icon={{name: "far fa-chevron-right"}}
                                iconPosition={Justify.Right}
                                label={this.confirmedTripsButtonLabel}
                                type={ButtonType.Dark}
                                onClick={() => this.openConfirmedTripsAsync()}
                        />

                    </ButtonContainer>
                    
                    <AvailableDateModal ref={this._availableDateModalRef}
                                        defaultStartTime={this.getDefaultMinDate()}
                                        defaultEndTime={this.getDefaultMaxDate()}
                                        onSave={(captainDate) => this.saveAvailableDateAsync(captainDate)}
                    />

                </div>
                
            </PageContainer>
        );
    }
}