// autogenerated

export default class Country {

    public id: string = "";

    public name: string | null = null;

    public currency: string | null = null;

    public currencyCode: string | null = null;

    public code: string = "";

    public nativeName: string | null = null;

    public moneySymbol: string | null = null;

    public moneyThreeLetterName: string | null = null;

    public vatValue: number = 0;

    public vatPercentage: number = 0;

    public pricePerLiter: number = 0;

    public contactEmail: string | null = null;

    public contactPhone: string | null = null;

    public active: boolean = false;

    public logo: string | null = null;

    public readonly isCountry: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof Country) || ((from as any).isCountry === true));
    }

    public static as(from?: object | null): Country | null {
        return (Country.is(from)) ? from as Country : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}