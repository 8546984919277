import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {Button, ButtonType, Checkbox, Form, InlineType, LocationPickerModal, NumberInput, PageContainer, PageRow, TextInput} from "@reapptor-apps/reapptor-react-components";
import Boat from "@/models/server/bout/Boat";
import SaveMyBoatResponse from "@/models/server/responses/SaveMyBoatResponse";
import SaveMyBoatRequest from "@/models/server/requests/SaveMyBoatRequest";
import {BasePageParameters, ch, PageRouteProvider, SwipeDirection} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import BoatImage from "@/models/server/BoatImage";
import {GeoLocation} from "@reapptor-apps/reapptor-toolkit";
import BoatImageModal from "@/pages/Mobile/EditBoat/BoatImageModal/BoatImageModal";
import DeleteMyBoatResponse from "@/models/server/responses/DeleteMyBoatResponse";
import ImageProvider from "@/providers/ImageProvider";
import AppConstants from "@/helpers/AppConstants";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";

import styles from "./EditBoat.module.scss";
import boutStyles from "@/bout.module.scss";

import cameraImage from "./Images/Camera.png";
import addActiveImage from "./Images/AddNewActive.png";

export interface IEditBoatProps extends BasePageParameters {
    openHomeLocationModal: boolean
}

export interface IEditBoatState {
    initialized: boolean;
    isValid: boolean;
    boat: Boat | null;
}

export default class EditBoat extends AuthorizedPage<IEditBoatProps, IEditBoatState> {

    state: IEditBoatState = {
        initialized: false,
        isValid: false,
        boat: null
    }

    private readonly _boatImageModalRef: React.RefObject<BoatImageModal> = React.createRef();
    private readonly _homeLocationModalRef: React.RefObject<LocationPickerModal> = React.createRef();

    private get initialized(): boolean {
        return this.state.initialized;
    }

    private get isNew(): boolean {
        return this.boat.id == "";
    }

    private get boat(): Boat {
        return this.state.boat!;
    }

    private get images(): BoatImage[] {
        return this.boat.images!;
    }

    private get homeLocation(): GeoLocation | null {
        return this.state.boat!.homeLocation;
    }

    private get isElectricBoat(): boolean {
        return this.boat.isElectricBoat;
    }

    private get isSailBoat(): boolean {
        return this.boat.isSailBoat;
    }

    private get isSpeedBoat(): boolean {
        return this.boat.isSpeedBoat;
    }

    private get toilet(): boolean {
        return this.boat.toilet;
    }
    
    private get missingRequiredInformation(): string[] {
        let missingRequirements: string[] = [];
        
        if (this.images.find(item => item.primary)?.file == null) {
            missingRequirements.push(Localizer.editBoatPageSaveBoatValidationMessageImages);
        }
        
        if (this.boat.homeLocation == null) {
            missingRequirements.push(Localizer.editBoatPageSaveBoatValidationMessageHomeLocation);
        }
        
        return missingRequirements;
    }

    private resolveImageSource(boat: BoatImage): string {
        const defaultImage: string = (boat.primary) ? cameraImage : addActiveImage;
        return ImageProvider.getRequiredImageSrc(boat.file, defaultImage);
    }

    private async openBoatImageModalAsync(index: number): Promise<void> {
        if ((this._boatImageModalRef.current) && (!this.isImageDisabled(index))) {
            const image: BoatImage = this.images[index];
            await this._boatImageModalRef.current!.openAsync(image, index);
        }
    }

    private async openHomeLocationModalAsync(): Promise<void> {
        if (this._homeLocationModalRef.current) {
            await this._homeLocationModalRef.current.setState({location: this.boat.homeLocation});
            await this._homeLocationModalRef.current.openAsync();
        }
    }

    private isImageDisabled(index: number): boolean {
        return ((index > 0) && (this.images[index - 1].file == null));
    }

    private async setBoatImageAsync(): Promise<void> {
        Boat.initializeImages(this.boat);
        await this.reRenderAsync();
    }

    private async setHomeLocationAsync(geoLocation: GeoLocation): Promise<void> {
        this.boat.homeLocation = geoLocation;
        await this.reRenderAsync();
    }

    private async setBrandAsync(value: string): Promise<void> {
        this.boat.brand = value;
        await this.reRenderAsync();
    }

    private async setModelAsync(value: string): Promise<void> {
        this.boat.model = value;
        await this.reRenderAsync();
    }

    private async setIsElectricBoatAsync(value: boolean): Promise<void> {
        this.boat.isElectricBoat = value;
        await this.setState({boat: this.boat});
    }

    private async setIsSpeedBoatAsync(value: boolean): Promise<void> {
        this.boat.isSpeedBoat = value;
        await this.reRenderAsync();
    }

    private async setIsSailBoatAsync(value: boolean): Promise<void> {
        this.boat.isSailBoat = value;
        await this.reRenderAsync();
    }

    private async setMaxCapacityAsync(value: number): Promise<void> {
        this.boat.maxCapacity = value;
        await this.reRenderAsync();
    }

    private async setSeatsAsync(value: number): Promise<void> {
        this.boat.seats = value;
        await this.reRenderAsync();
    }

    private async setHorsepowerAsync(value: number): Promise<void> {
        this.boat.horsepower = value;
        await this.reRenderAsync();
    }

    private async setCruiseSpeedAsync(value: number): Promise<void> {
        this.boat.cruiseSpeed = value;
        await this.reRenderAsync();
    }

    private async setPetFriendlyAsync(value: boolean): Promise<void> {
        this.boat.petFriendly = value;
        await this.reRenderAsync();
    }

    private async setDisabledFriendlyAsync(value: boolean): Promise<void> {
        this.boat.disabledFriendly = value;
        await this.reRenderAsync();
    }

    private async setToiletAsync(value: boolean): Promise<void> {
        this.boat.toilet = value;
        await this.reRenderAsync();
    }

    private async saveBoatAsync(): Promise<void> {
        const missingRequiredInformation: string[] = this.missingRequiredInformation;
        
        if (missingRequiredInformation.length > 0) {
            const missingInformation: string = missingRequiredInformation.map(item => `— <b>${item}</b>`).join("\n");
            
            await this.alertErrorAsync(Localizer.editBoatPageSaveBoatValidationMessage.format(missingInformation), true, true);
            
            return;
        }

        const request = new SaveMyBoatRequest();
        request.id = this.boat.id;
        request.maxCapacity = this.boat.maxCapacity;
        request.seats = this.boat.seats;
        request.horsepower = this.boat.horsepower;
        request.cruiseSpeed = this.boat.cruiseSpeed;
        request.isElectricBoat = this.boat.isElectricBoat;
        request.isSpeedBoat = this.boat.isSpeedBoat;
        request.isSailBoat = this.boat.isSailBoat;
        request.petFriendly = this.boat.petFriendly;
        request.disabledFriendly = this.boat.disabledFriendly;
        request.toilet = this.boat.toilet;
        request.images = this.boat.images!;
        request.homeLocation = this.boat.homeLocation;
        request.brand = this.boat.brand;
        request.model = this.boat.model;
        request.horsepower = this.boat.horsepower;

        const response: SaveMyBoatResponse = await this.postAsync("/api/mobileApp/saveMyBoat", request);

        if (response.success) {
            if (this.boat.id != "") {
                await this.alertMessageAsync(Localizer.editBoatPageAlertMessageBoatSaved, true, true);
            } else {
                await this.alertMessageAsync(Localizer.editBoatPageAlertMessageBoatAdded, true, true);
            }
            
            const boat: Boat = response.boat!;

            Boat.initializeImages(boat);

            await this.setState({ boat });
        } else {
            if (response.nearestAreaNotFound) {
                await this.alertWarningAsync(Localizer.editBoatPageAlertWarningNearestAreaNotFound, true, true);
            } else if (response.boatTypeNotRecognized) {
                await this.alertWarningAsync(Localizer.editBoatPageAlertWarningBoatTypeWasNotRecognized.format(response.area), true, true);
            } else if (response.hasConfirmedBookings) {
                await this.alertWarningAsync(Localizer.editBoatPageAlertWarningHasConfirmedBookings, true, true);
            } else if (response.isMaxCapacityOutOfRange) {
                await this.alertWarningAsync(Localizer.editBoatPageAlertWarningMaxCapacityOutOfRange.format(request.maxCapacity, response.maxCapacityAllowed, response.area), true, true);
            }
        }
    }

    private async deleteBoatAsync(): Promise<void> {
        
        const response: DeleteMyBoatResponse = await this.postAsync("/api/mobileApp/deleteMyBoat", this.boat.id);
        
        if (response.hasConfirmedBookings) {
            await this.alertWarningAsync(Localizer.editBoatPageAlertWarningHasConfirmedBookings, true, true);
            return;
        }
        
        if (response.success) {
            await PageRouteProvider.redirectAsync(PageDefinitions.myBoatsRoute);

            await this.alertMessageAsync(Localizer.editBoatPageAlertMessageTheBoatdHasBeenRemoved.format(this.boat.model), true, true);

            return;
        }
    }
    
    public async onSwipeHandlerAsync(direction: SwipeDirection): Promise<boolean> {
        const canSwipeRight: boolean = (this._homeLocationModalRef.current == null) || (!this._homeLocationModalRef.current.isOpen);
        return ((direction == SwipeDirection.Right) && (canSwipeRight));
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (!this.initialized) {
            const boatId: string | null = this.routeId;

            const boat: Boat = (boatId)
                ? await this.postAsync("/api/mobileApp/GetBoat", boatId)
                : new Boat();

            Boat.initializeImages(boat);

            await this.setState({boat, initialized: true});
        }
        
        const openHomeLocationModal: boolean = (this.parameters as IEditBoatProps)?.openHomeLocationModal;
        if (openHomeLocationModal) {
            await this.openHomeLocationModalAsync();
        }
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.editBoat, this.mobile && styles.mobile)}
            >

                {
                    (this.initialized) &&
                    (
                        <PageRow className={styles.pageRow}>

                            <div className={styles.imagesContainer}>

                                <div className={styles.header}>
                                    <p>{Localizer.editBoatPageBoatImagesHeader}</p>
                                </div>

                                <div className={styles.primaryImage}>

                                    <img src={this.resolveImageSource(this.images[0])}
                                         alt={Localizer.editBoatPageImagePrimaryImageAlt}
                                         onClick={() => this.openBoatImageModalAsync(0)}
                                    />

                                </div>

                                <div className={styles.secondaryImages}>
                                    {
                                        this.images.map((image, index) => {
                                            if (index > 0) {
                                                return (
                                                    <img key={ch.getComponentId()}
                                                         className={this.css(styles.image, this.isImageDisabled(index) && styles.disabled)}
                                                         src={this.resolveImageSource(image)}
                                                         alt={Localizer.editBoatPageImageSecondaryImageAlt}
                                                         onClick={() => this.openBoatImageModalAsync(index)}
                                                    />
                                                )
                                            }
                                        })
                                    }
                                </div>

                            </div>

                            <div className={styles.content}>

                                <Form className={styles.form} onSubmit={() => this.saveBoatAsync()}>

                                    <TextInput required noAutoComplete
                                               id={"brand"}
                                               label={Localizer.editBoatPageTextInputBrandLabel}
                                               value={this.boat.brand}
                                               onChange={(sender, value) => this.setBrandAsync(value)}
                                    />

                                    <TextInput required noAutoComplete
                                               id={"model"}
                                               label={Localizer.editBoatPageTextInputModelLabel}
                                               value={this.boat.model}
                                               onChange={(sender, value) => this.setModelAsync(value)}
                                    />

                                    <Checkbox inline
                                              inlineType={InlineType.Right}
                                              id={"isElectricBoat"}
                                              label={Localizer.editBoatPageCheckboxElectricalBoatLabel}
                                              value={this.boat.isElectricBoat}
                                              onChange={(sender, checked) => this.setIsElectricBoatAsync(checked)}
                                              readonly={this.isSpeedBoat || this.isSailBoat}
                                    />

                                    <Checkbox inline
                                              inlineType={InlineType.Right}
                                              id={"isSpeedBoat"}
                                              label={Localizer.editBoatPageCheckboxSpeedBoatLabel}
                                              value={this.boat.isSpeedBoat}
                                              onChange={(sender, checked) => this.setIsSpeedBoatAsync(checked)}
                                              readonly={this.isElectricBoat || this.isSailBoat}
                                    />
                                    
                                    <Checkbox inline
                                              inlineType={InlineType.Right}
                                              id={"isSailBoat"}
                                              label={Localizer.editBoatPageCheckboxSailBoatLabel}
                                              value={this.boat.isSailBoat}
                                              onChange={(sender, checked) => this.setIsSailBoatAsync(checked)}
                                              readonly={this.isElectricBoat || this.isSpeedBoat}
                                    />
                                    
                                    <NumberInput required hideArrows hideZero
                                                 label={Localizer.editBoatPageNumberInputMaxCapacityLabel}
                                                 id={"maxCapacity"}
                                                 min={1}
                                                 max={AppConstants.boatMaxCapacity}
                                                 value={this.boat.maxCapacity}
                                                 onChange={(sender, value) => this.setMaxCapacityAsync(value)}
                                    />
                                    
                                    <NumberInput required hideArrows
                                                 label={Localizer.editBoatPageNumberInputSeats}
                                                 id={"seats"}
                                                 min={0}
                                                 max={AppConstants.boatMaxCapacity}
                                                 value={this.boat.seats}
                                                 onChange={(sender, value) => this.setSeatsAsync(value)}
                                    />
                                    
                                    <NumberInput required hideArrows hideZero
                                                 id={"horsepower"}
                                                 label={Localizer.editBoatPageNumberInputHorsepowerLabel}
                                                 min={1}
                                                 value={this.boat.horsepower}
                                                 onChange={(sender, value) => this.setHorsepowerAsync(value)}
                                    />
                                    
                                    <NumberInput required hideArrows hideZero
                                                 id={"cruiseSpeed"}
                                                 label={Localizer.editBoatPageNumberInputCruiseSpeedLabel}
                                                 min={1}
                                                 max={AppConstants.boatMaxCapacity}
                                                 value={this.boat.cruiseSpeed}
                                                 onChange={(sender, value) => this.setCruiseSpeedAsync(value)}
                                    />

                                    <Checkbox inline inlineType={InlineType.Right}
                                              id={"petFriendly"}
                                              label={Localizer.editBoatPageCheckboxPetFriendlyLabel}
                                              value={this.boat.petFriendly}
                                              onChange={(_, checked) => this.setPetFriendlyAsync(checked)}
                                    />

                                    <Checkbox inline inlineType={InlineType.Right}
                                              id={"disabledFriendly"}
                                              label={Localizer.editBoatPageCheckboxDisabledFriendlyLabel}
                                              value={this.boat.disabledFriendly}
                                              onChange={(_, checked) => this.setDisabledFriendlyAsync(checked)}
                                    />

                                    <Checkbox inline inlineType={InlineType.Right}
                                              id={"toilet"}
                                              label={Localizer.editBoatPageCheckboxToiletLanguageItemName}
                                              value={this.boat.toilet}
                                              onChange={(_, checked) => this.setToiletAsync(checked)}
                                    />

                                    <div className={styles.buttonContainer}>

                                        <Button block
                                                type={ButtonType.Dark}
                                                label={Localizer.editBoatPageButtonHomeLocationLabel}
                                                icon={{name: "fal location"}}
                                                onClick={() => this.openHomeLocationModalAsync()}
                                        />

                                        <Button block submit
                                                type={ButtonType.Dark}
                                                label={Localizer.formSave}
                                                icon={{name: "fal save"}}
                                        />

                                        {
                                            (!this.isNew) &&
                                            (
                                                <Button block
                                                        type={ButtonType.Dark}
                                                        label={Localizer.genericDelete}
                                                        icon={{name: "fal trash-alt"}}
                                                        confirm={Localizer.editBoatPageButtonConfirmDeleteMessage}
                                                        onClick={() => this.deleteBoatAsync()}
                                                />
                                            )
                                        }

                                    </div>

                                </Form>

                            </div>

                            <LocationPickerModal id="homeLocation" notResponsive required
                                                 ref={this._homeLocationModalRef}
                                                 location={this.homeLocation || undefined}
                                                 country={AppConstants.supportedLocationCountries}
                                                 title={Localizer.editBoatPageSetHomeLocation}
                                                 subtitle={this.homeLocation ? TransformProvider.toString(this.homeLocation) : ""}
                                                 onSubmit={(sender, location) => this.setHomeLocationAsync(location)}
                            />

                            <BoatImageModal ref={this._boatImageModalRef}
                                            onClose={() => this.setBoatImageAsync()}
                            />

                        </PageRow>
                    )
                }

            </PageContainer>
        )
    }
}