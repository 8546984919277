// autogenerated
import CaptainAvailableDate from "@/models/server/bout/CaptainAvailableDate";

export default class GetMyCalendarResponse {

    public bookedTrips: Date[] | null = null;

    public confirmedTrips: Date[] | null = null;

    public availableDates: CaptainAvailableDate[] | null = null;

    public readonly isGetMyCalendarResponse: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof GetMyCalendarResponse) || ((from as any).isGetMyCalendarResponse === true));
    }

    public static as(from?: object | null): GetMyCalendarResponse | null {
        return (GetMyCalendarResponse.is(from)) ? from as GetMyCalendarResponse : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}