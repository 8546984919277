import React from "react";
import {BaseComponent, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import User from "@/models/server/User";
import {Button, ButtonContainer, ButtonType} from "@reapptor-apps/reapptor-react-components";
import PageDefinitions from "@/providers/PageDefinitions";

import Localizer from "../../../../localization/Localizer";

import styles from "./Verified.module.scss";
import kycStyles from "@/pages/Mobile/Kyc/Kyc.module.scss";

interface IVerifiedProps {
    user: User;
    onModify: (sender: Verified) => Promise<void>;
}

interface IVerifiedState {
}

export default class Verified extends BaseComponent<IVerifiedProps, IVerifiedState> {

    state: IVerifiedState = {};

    public get user(): User {
        return this.props.user;
    }

    public render(): React.ReactNode {
        return (
            <div className={this.css(kycStyles.statusContainer, styles.verified)}>

                <div className={kycStyles.text}>
                    
                    <span>{ReactUtility.toMultiLines(Localizer.mobileKycPageVerifiedVerifiedMessageSpan)}</span>

                </div>
                
                <div className={kycStyles.expander}/>

                <ButtonContainer>

                    <Button type={ButtonType.Primary}
                            icon={{name: "fal fa-ship"}}
                            label={Localizer.mobileMyBoatsPageTitle}
                            route={PageDefinitions.myBoatsRoute}
                    />
                    
                    <Button type={ButtonType.Primary}
                            icon={{name: "fal fa-credit-card"}}
                            label={Localizer.myPaymentMethodsPageTitle}
                            route={PageDefinitions.myPaymentMethodsRoute}
                    />
                    
                    <Button type={ButtonType.Orange}
                            icon={{name: "fas edit"}}
                            label={Localizer.mobileKycPageVerifiedModifyButtonLabel}
                            confirm={Localizer.mobileKycPageVerifiedModifyButtonConfirm}
                            onClick={() => this.props.onModify(this)}
                    />

                </ButtonContainer>

            </div>
        );
    }
}