import React, {RefObject} from "react";
import {BaseComponent, IBaseComponentProps, TextAlign} from "@reapptor-apps/reapptor-react-common";
import {
    CellModel,
    ColumnDefinition,
    ColumnType,
    Grid,
    GridHoveringType,
    GridOddType,
    ToolbarContainer,
} from "@reapptor-apps/reapptor-react-components";
import Boat from "@/models/server/bout/Boat";
import {BoatType} from "@/models/Enums";
import BoatPrice from "@/models/server/bout/BoatPrice";
import ListBoatsRequest from "@/models/server/requests/ListBoatsRequest";
import ListBoatsResponse from "@/models/server/responses/ListBoatsResponse";
import AppConstants from "@/helpers/AppConstants";
import ServiceProvider from "@/models/server/ServiceProvider";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import styles from "./BoatsPanel.module.scss";

export interface IBoatsPanelProps extends IBaseComponentProps {
    boatPrice: BoatPrice;
}

interface IBoatsPanelState {
    serviceProviders: ServiceProvider[];
}

export default class BoatsPanel extends BaseComponent<IBoatsPanelProps, IBoatsPanelState> {

    state: IBoatsPanelState = {
        serviceProviders: [],
    };

    private readonly _boatsGridRef: RefObject<Grid<Boat>> = React.createRef();

    private readonly _boatColumns: ColumnDefinition[] = [
        {
            header: Localizer.boatsPanelGridBrandLanguageItemName,
            accessor: nameof<Boat>(o => o.brand),
            editable: false,
            minWidth: 110,
            stretch: true,
            settings: {
                infoAccessor: nameof<Boat>(o => o.model),
            }
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridCaptainLanguageItemName,
            accessor: nameof<Boat>(o => o.captain),
            editable: false,
            minWidth: 200,
            settings: {
                infoAccessor: nameof.full<Boat>(o => o.captain!.email),
            },
            init: (cell: CellModel<Boat>) => this.initCaptainColumn(cell),
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridServiceProviderLanguageItemName,
            accessor: (model: Boat) => this.findWaterOperator(model.captain?.serviceProviderId),
            editable: false,
            minWidth: 200,
            settings: {
                infoAccessor: (model: Boat) => this.findRootServiceProvider(model.captain?.serviceProviderId),
                infoHideEqual: true,
            },
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridCapacityLanguageItemName,
            accessor: nameof<Boat>(o => o.maxCapacity),
            editable: false,
            minWidth: 75,
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridHorsePowerLanguageItemName,
            accessor: nameof<Boat>(o => o.horsepower),
            format: "0",
            editable: true,
            type: ColumnType.Number,
            settings: {
                min: 0,
                max: 999,
                hideZero: true
            },
            minWidth: 75,
            maxWidth: 75
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridCruiseSpeedLanguageItemName,
            accessor: nameof<Boat>(o => o.cruiseSpeed),
            format: "0",
            editable: true,
            type: ColumnType.Number,
            settings: {
                min: 0,
                max: AppConstants.boatMaxCapacity,
                hideZero: true
            },
            minWidth: 75,
            maxWidth: 75
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridIsSpeedLanguageItemName,
            accessor: (model: Boat) => model.isSpeedBoat ? "✓" : "-",
            textAlign: TextAlign.Center,
            editable: false,
            minWidth: 50,
            maxWidth: 50
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridAreaLanguageItemName,
            accessor: nameof.full<Boat>(o => o.area!.name),
            editable: false,
            minWidth: 150,
            settings: {
                infoAccessor: nameof.full<Boat>(o => o.boatType),
                infoFormat: nameof(BoatType),
            }
        } as ColumnDefinition,
        {
            header: Localizer.boatsPanelGridMarginLanguageItemName,
            accessor: (model: Boat) => model.margin?.toString() ?? "-",
            textAlign: TextAlign.Center,
            editable: false,
            minWidth: 75,
            maxWidth: 75
        } as ColumnDefinition,
    ];
    
    private async fetchBoatsAsync(): Promise<Boat[]> {
        const request = new ListBoatsRequest();
        request.boatPriceId = this.boatPrice.id;

        const response: ListBoatsResponse = await this.postAsync("/api/boatPrice/listBoats", request);

        return response.boats ?? [];
    }
    
    private async fetchServiceProviderAsync(): Promise<ServiceProvider[]> {
        return AppController.listServiceProvidersAsync();
    }
    
    private findServiceProvider(serviceProviderId: string | null | undefined): ServiceProvider | null {
        return this.serviceProviders.firstOrDefault(item => (item.id == serviceProviderId));
    }

    private findWaterOperator(serviceProviderId: string | null | undefined): ServiceProvider | null {
        return this.findServiceProvider(serviceProviderId);
    }
    
    private findRootServiceProvider(serviceProviderId: string | null | undefined): ServiceProvider | null {
        const serviceProvider: ServiceProvider | null = this.findServiceProvider(serviceProviderId);
        return (serviceProvider?.parentId)
            ? this.findRootServiceProvider(serviceProvider.parentId)
            : serviceProvider;
    }

    private initCaptainColumn(cell: CellModel<Boat>): void {
        const testUser: boolean = cell.model.captain?.testUser ?? false;
        cell.className = this.cssIf(cell.className, testUser, styles.testUser);
    }
    
    private get serviceProviders(): ServiceProvider[] {
        return this.state.serviceProviders;
    }
    
    private get boatPrice(): BoatPrice {
        return this.props.boatPrice;
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        const serviceProviders: ServiceProvider[] = await this.fetchServiceProviderAsync();
        
        await this.setState({ serviceProviders });
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id}
                 className={this.css(this.props.className, styles.boatsPanel)}
            >

                <ToolbarContainer>

                    <Grid ref={this._boatsGridRef}
                          className={styles.boatsGrid}
                          hovering={GridHoveringType.Row}
                          odd={GridOddType.None}
                          minWidth="auto"
                          noDataText={Localizer.genericNoData}
                          columns={this._boatColumns}
                          fetchData={() => this.fetchBoatsAsync()}
                    />

                </ToolbarContainer>
                
            </div>
        )
    }
}