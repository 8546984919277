import React from "react";
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import {AlertModel, AlertType, BaseComponent, CameraType, ch} from "@reapptor-apps/reapptor-react-common";
import {Alert, Button, ButtonType, Modal, Spinner} from "@reapptor-apps/reapptor-react-components";
import BoatImage from "@/models/server/BoatImage";
import AppConstants from "@/helpers/AppConstants";
import Localizer from "@/localization/Localizer";

import styles from "./BoatImageModal.module.scss";
import mobileStyles from "../../mobile.module.scss";

import boatPhotoTip from "@/pages/Mobile/EditBoat/Images/BoatPhotoTip.png";

export interface IBoatImageModalProps {
    onClose(image: BoatImage, index: number): Promise<void>;
}

interface IBoatImageModalState {
    loading: boolean;
    imageIsTooSmall: boolean;
}

interface IBoatImageModalData {
    image: BoatImage;
    index: number;
}

export default class BoatImageModal extends BaseComponent<IBoatImageModalProps, IBoatImageModalState> {

    state: IBoatImageModalState = {
        loading: false,
        imageIsTooSmall: false,
    }

    private readonly _modalRef: React.RefObject<Modal<IBoatImageModalData>> = React.createRef();

    private get hasData(): boolean {
        return (this._modalRef.current != null) && (this._modalRef.current.data != null);
    }
    
    private get isPrimaryImage(): boolean {
        return this.image.primary;
    }

    private get modal(): Modal<IBoatImageModalData> {
        return this._modalRef.current!;
    }

    private get data(): IBoatImageModalData {
        return this.modal!.data!;
    }

    private get image(): BoatImage {
        return this.data.image;
    }

    private get index(): number {
        return this.data.index;
    }

    private get noRemove(): boolean {
        return (this.image.file == null);
    }
    
    private get imageIsTooSmallAlert(): AlertModel | null {
        if (!this.state.imageIsTooSmall) {
            return null;
        }
        const alert = new AlertModel(Localizer.editBoatPageUploadImageLowResolution, AlertType.Warning, false, false);
        alert.dismissible = false;
        return alert;
    }
    
    private async onCloseAsync(): Promise<void> {
        this.state.imageIsTooSmall = false;
        if (this.props.onClose) {
            await this.props.onClose(this.image, this.index);
        }
    }
    
    public async openAsync(image: BoatImage, index: number): Promise<void> {
        const data: IBoatImageModalData = {image, index};
        await this.modal?.openAsync(data);
        await this.reRenderAsync();
    }
    
    public async closeAsync(): Promise<void> {
        await this.modal?.closeAsync();
    }

    private async takePictureAsync(camera?: boolean | CameraType): Promise<void> {
        try {
            await this.setState({ loading: true });

            let image: FileModel | null = await ch.takePictureAsync(camera);
            
            if (image) {

                if (image.size < AppConstants.minImageSize) {
                    this.state.imageIsTooSmall = true;
                    return;
                }

                image = await this.postAsync("/api/image/convertImage", image);

                this.image.file = image;

                await this.closeAsync();
            }

        } finally {
            await this.setState({ loading: false });
        }
    }

    private async deletePictureAsync(): Promise<void> {
        this.image.file = null;

        await this.closeAsync();
    }
    
    public hasSpinner(): boolean {
        return true;
    }

    public render(): React.ReactNode {
        const alert: AlertModel | null = this.imageIsTooSmallAlert;
        
        return (
            <Modal noHeader notResponsive
                   ref={this._modalRef}
                   className={this.css(styles.boatImageModal, this.mobile && styles.mobile, this.mobile && mobileStyles.mobile)}
                   bodyClassName={styles.body}
                   onClose={() => this.onCloseAsync()}
            >

                { (this.state.loading) && <Spinner /> }

                {
                    (this.hasData) &&
                    (
                        <div className={styles.container}>

                            {
                                (this.isPrimaryImage) &&
                                (
                                    <div>

                                        <div>
                                            <p>{Localizer.boatImageModalHeaderBoatPhotoTip}</p>
                                        </div>

                                        <div className={styles.boatPhotoTip}>
                                            <img src={boatPhotoTip} alt={Localizer.boatImageModalImageBoatPhotoTipAlt}/>
                                        </div>

                                    </div>
                                )
                            }

                            {
                                (alert) && (<Alert model={alert} />)
                            }

                            <div className={styles.buttons}>

                                <Button block
                                        label={Localizer.genericCamera}
                                        icon={{name: "fal camera"}}
                                        type={ButtonType.Primary}
                                        onClick={() => this.takePictureAsync(CameraType.OutwardFacingCamera)}
                                />

                                <Button block
                                        label={Localizer.genericPhotos}
                                        icon={{name: "fal image-polaroid"}}
                                        type={ButtonType.Primary}
                                        onClick={() => this.takePictureAsync(false)}
                                />

                                {
                                    (!this.noRemove) &&
                                    (
                                        <Button block
                                                label={Localizer.genericRemove}
                                                icon={{name: "fal trash-alt"}}
                                                type={ButtonType.Dark}
                                                onClick={() => this.deletePictureAsync()}
                                        />
                                    )
                                }

                            </div>

                        </div>
                    )
                }

            </Modal>
        )
    }
}