import React from "react";
import {GeoLocation} from "@reapptor-apps/reapptor-toolkit";
import {BaseComponent, IBaseComponentProps, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import {Carousel, CarouselNavigation, CarouselPagination, Icon, Modal} from "@reapptor-apps/reapptor-react-components"
import ServicePoint from "@/models/server/bout/ServicePoint";
import Localizer from "@/localization/Localizer";

import styles from "./PjtaModal.module.scss";

import googleLogo from "@/pages/Mobile/BookingDetails/BookingInfo/BookingTicketModal/Images/google.png";

import slide1 from "./slides/slide_1.png";
import slide2 from "./slides/slide_2.png";
import slide3 from "./slides/slide_3.png";
import slide4 from "./slides/slide_4.png";
import slide5 from "./slides/slide_5.png";
import slide6 from "./slides/slide_6.png";
import slide7 from "./slides/slide_7.png";
import slide8 from "./slides/slide_8.png";
import slide9 from "./slides/slide_9.png";
import slide10 from "./slides/slide_10.png";
import slide11 from "./slides/slide_11.png";
import slide12 from "./slides/slide_12.png";

const slides = [
    //"Seine Taxi"
    {
        tabIndex: 0,
        image: slide1,
        title: Localizer.pjtaModalSlide1TitleLanguageItemName,
        description: Localizer.pjtaModalSlide1DescriptionLanguageItemName
    },
    {
        tabIndex: 0,
        image: slide2,
        title: Localizer.pjtaModalSlide2TitleLanguageItemName,
        description: Localizer.pjtaModalSlide2DescriptionLanguageItemName,
    },
    //Louvre
    {
        tabIndex: 1,
        image: slide3,
        location: new GeoLocation(48.8600974, 2.3324389),
        title: Localizer.pjtaModalSlide3And4TitleLanguageItemName,
        description: Localizer.pjtaModalSlide3And4DescriptionLanguageItemName,
    },
    {
        tabIndex: 1,
        image: slide4,
        location: new GeoLocation(48.8600974, 2.3324389),
        title: Localizer.pjtaModalSlide3And4TitleLanguageItemName,
        description: Localizer.pjtaModalSlide3And4DescriptionLanguageItemName,
    },
    //Eiffel Tower
    {
        tabIndex: 2,
        image: slide5,
        location: new GeoLocation(48.8600974, 2.3324389),
        title: Localizer.pjtaModalSlide5And6TitleLanguageItemName,
        description: Localizer.pjtaModalSlide5And6DescriptionLanguageItemName,
    },
    {
        tabIndex: 2,
        image: slide6,
        location: new GeoLocation(48.8600974, 2.3324389),
        title: Localizer.pjtaModalSlide5And6TitleLanguageItemName,
        description: Localizer.pjtaModalSlide5And6DescriptionLanguageItemName,
    },
    //Boats
    {
        tabIndex: 3,
        image: slide7,
        title: Localizer.pjtaModalSlide7TitleLanguageItemName,
        description: Localizer.pjtaModalSlide7DescriptionLanguageItemName,
    },
    {
        tabIndex: 3,
        image: slide8,
        title: Localizer.pjtaModalSlide8TitleLanguageItemName,
        description: Localizer.pjtaModalSlide8DescriptionLanguageItemName,
    },
    {
        tabIndex: 3,
        image: slide9,
        title: Localizer.pjtaModalSlide9TitleLanguageItemName,
        description: Localizer.pjtaModalSlide9DescriptionLanguageItemName,
    },
    //Iconic Views
    {
        tabIndex: 4,
        image: slide10,
        title: Localizer.pjtaModalSlide10TitleLanguageItemName,
        description: Localizer.pjtaModalSlide10DescriptionLanguageItemName,
    },
    {
        tabIndex: 4,
        image: slide11,
        title: Localizer.pjtaModalSlide11TitleLanguageItemName,
        description: Localizer.pjtaModalSlide11DescriptionLanguageItemName,
    },
    {
        tabIndex: 4,
        image: slide12,
        title: Localizer.pjtaModalSlide12TitleLanguageItemName,
        description: Localizer.pjtaModalSlide12DescriptionLanguageItemName,
    },
];

interface IPjtaModalProps extends IBaseComponentProps {
}

interface IPjtaModalState {
}

export default class PjtaModal extends BaseComponent<IPjtaModalProps, IPjtaModalState> {

    state: IPjtaModalState = {
    }

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _carouselRef: React.RefObject<Carousel> = React.createRef();
    
    private async onSlideChangeAsync(index: number): Promise<void> {
        await this.reRenderAsync();
    }
    
    private async selectTabIndexAsync(tabIndex: number): Promise<void> {
        const slide = slides.first(item => item.tabIndex == tabIndex);
        
        const slideIndex: number = slides.indexOf(slide);

        await this._carouselRef.current?.slideToAsync(slideIndex);
    }
    
    private async onCloseAsync(): Promise<void> {
        await this.modal?.closeAsync();
    }

    private get modal(): Modal {
        return this._modalRef.current!;
    }
    
    private get slideIndex(): number {
        return this._carouselRef.current?.currentSlideIndex ?? 0;
    }
    
    private get tabIndex(): number {
        return slides[this.slideIndex].tabIndex;
    }
    
    private get title(): string {
        return slides[this.slideIndex].title;
    }
    
    private get description(): string {
        return slides[this.slideIndex].description;
    }
    
    private get location(): GeoLocation | null {
        return slides[this.slideIndex].location ?? null;
    }

    public async openAsync(animation: boolean = true): Promise<void> {
        await this.modal.openAsync(animation);
        await this.reRenderAsync();
    }

    public get isOpen(): boolean {
        return (this._modalRef.current != null) && (this._modalRef.current.isOpen);
    }
    
    private renderImage(image: string, index: number = 0): React.ReactElement {
        return (
            <div key={index}
                 className={styles.image}
            >
                <img alt={""}
                     //loading="lazy"
                     src={image}
                />
            </div>
        );
    }

    public render(): React.ReactNode {
        const tabIndex: number = this.tabIndex;
        
        return (
            <Modal noHeader
                   id={this.id}
                   ref={this._modalRef}
                   className={this.css(styles.pjtaModal, this.props.className)}
                   onClose={() => this.onCloseAsync()}
            >

                {
                    (this.isOpen) &&
                    (
                        <div className={styles.container}>

                            <div className={styles.toolbar}>

                                <Icon name={"fal fa-times-circle"} onClick={() => this.onCloseAsync()}/>

                            </div>

                            <div className={styles.content}>

                                <div className={styles.navigation}>
                                    <span className={this.css(((tabIndex == 0) && styles.active))}
                                          onClick={() => this.selectTabIndexAsync(0)}
                                    >
                                        {Localizer.pjtaModalTabWaterTaxi}
                                    </span>
                                    <span className={this.css(((tabIndex == 1) && styles.active))}
                                          onClick={() => this.selectTabIndexAsync(1)}
                                    >
                                        {Localizer.pjtaModalTabPortDuLouvre}
                                    </span>
                                    <span className={this.css(((tabIndex == 2) && styles.active))}
                                          onClick={() => this.selectTabIndexAsync(2)}
                                    >
                                        {Localizer.pjtaModalTabTourEiffel}
                                    </span>
                                    <span className={this.css(((tabIndex == 3) && styles.active))}
                                          onClick={() => this.selectTabIndexAsync(3)}
                                    >
                                        {Localizer.pjtaModalTabOurBoats}
                                    </span>
                                    <span className={this.css(((tabIndex == 4) && styles.active))}
                                          onClick={() => this.selectTabIndexAsync(4)}
                                    >
                                        {Localizer.pjtaModalTabIconicViews}
                                    </span>
                                </div>

                                <div className={styles.images}>

                                    <Carousel ref={this._carouselRef}
                                              navigation={CarouselNavigation.Inside}
                                              pagination={CarouselPagination.BottomOutside}
                                              slidesPerView={1}
                                              className={this.css(styles.carousel, styles.thumbnail)}
                                              onSlideChange={(index) => this.onSlideChangeAsync(index)}
                                    >

                                        {
                                            slides.map((slide, index) => this.renderImage(slide.image, index))
                                        }

                                    </Carousel>

                                </div>

                                <span className={styles.title}>{Localizer.get(this.title)}</span>

                                <div className={styles.description}>

                                    <div>
                                        {ReactUtility.toMultiLines(Localizer.get(this.description))}
                                    </div>

                                    {
                                        (this.location) &&
                                        (
                                            <div className={styles.mapLink}>
                                                <img src={googleLogo} alt="Google"/>
                                                <a href={ServicePoint.generateGoogleMapsLink(this.location)!} target="_blank">
                                                    {Localizer.bookingTicketModalFindYourWayToStop}
                                                </a>
                                            </div>
                                        )
                                    }

                                </div>

                            </div>

                        </div>
                    )
                }

            </Modal>
        )
    }
}