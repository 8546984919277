import React from "react";
import {
    PageContainer,
    PageRow,
    LdDataHeader,
} from "@reapptor-apps/reapptor-react-components";
import CruisePackage from "@/models/server/cruise/CruisePackage";
import {
    BasePageParameters,
    PageRoute,
    PageRouteProvider,
    LdData,
    LocalizationString
} from "@reapptor-apps/reapptor-react-common";
import AnonymousPage from "@/models/base/AnonymousPage";
import CruisePackageCard from "@/components/CruisePackageCard/CruisePackageCard";
import PageDefinitions from "@/providers/PageDefinitions";
import FindCruisePackageRequest from "@/models/server/requests/FindCruisePackageRequest";
import FindCruisePackageResponse from "@/models/server/responses/FindCruisePackageResponse";
import AppConstants from "@/helpers/AppConstants";
import ServiceProviderController from "@/pages/ServiceProviderController";
import CruisePackageWizardController from "@/pages/Mobile/CruisePackageWizard/CruisePackageWizardController";
import Localizer from "@/localization/Localizer";

import styles from "./CruisePackageDetails.module.scss";

export interface ICruisePackageDetailsProps extends BasePageParameters {
    path?: string | null;
}

interface ICruisePackageDetailsState {
    cruisePackage: CruisePackage | null;
    cruisePackageLdData: LdData | null;
}

export default class CruisePackageDetails extends AnonymousPage<ICruisePackageDetailsProps, ICruisePackageDetailsState> {

    state: ICruisePackageDetailsState = {
        cruisePackage: null,
        cruisePackageLdData: null,
    };

    private async closePreviewAsync(): Promise<void> {
        PageRouteProvider.back();
    }

    private async requestTripAsync(cruisePackage: CruisePackage): Promise<void> {
        await CruisePackageWizardController.requestCruisePackageAsync(cruisePackage);
    }

    private get cruisePackage(): CruisePackage | null {
        return this.state.cruisePackage;
    }
    
    private get cruisePackageLdData(): LdData | null {
        return this.state.cruisePackageLdData;
    }

    public getTitle(): string {
        return (this.cruisePackage)
            ? LocalizationString.value(this.cruisePackage.name)
            : Localizer.cruisePackageDetailsPageTitle;
    }

    public static routeResolver(pathname: string): PageRoute | null {
        const prefix: string = AppConstants.cruisePackagesPrefix;
        if (pathname.toLowerCase().startsWith(prefix)) {
            const path: string = pathname.substring(prefix.length);
            return PageDefinitions.cruisePackageDetails(path);
        }

        return null;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const cruisePackageId: string | null = ((this.cruisePackage) && (this.cruisePackage.id))
            ? this.cruisePackage.id
            : this.routeId;

        const cruisePackagePath: string | null = this.parameters?.path || null;

        if ((!cruisePackageId) && (!cruisePackagePath)) {
            await this.redirectHomeAsync();
            return;
        }

        const request = new FindCruisePackageRequest();
        request.id = cruisePackageId;
        request.path = cruisePackagePath;

        const response: FindCruisePackageResponse = await this.postAsync("/api/cruisePackage/findCruisePackage", request);

        if (!response.cruisePackage) {
            await this.redirectHomeAsync();
            return;
        }

        await this.setState({cruisePackage: response.cruisePackage, cruisePackageLdData: response.cruisePackageLdData});

        window.document.title = this.getTitle();
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={this.css(styles.cruisePackageDetails)}>

                <LdDataHeader data={this.cruisePackageLdData}
                              canonical={this.cruisePackage?.path}
                              defaultLanguage={ServiceProviderController.applicationDefaultLanguage}
                />

                <PageRow className={styles.pageRow}>
                    <div className="col">

                        {
                            (this.cruisePackage) &&
                            (
                                <CruisePackageCard details embedded
                                                   cruisePackage={this.cruisePackage}
                                                   onRequestTrip={(_, cruisePackage) => this.requestTripAsync(cruisePackage)}
                                                   onOrder={(_, cruisePackage) => this.requestTripAsync(cruisePackage)}
                                                   onClose={() => this.closePreviewAsync()}
                                />
                            )
                        }

                    </div>
                </PageRow>

            </PageContainer>
        );
    }
}