import React from "react";
import {Button, ButtonContainer, ButtonType} from "@reapptor-apps/reapptor-react-components";
import {BaseComponent, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import User from "@/models/server/User";
import StripeKycStatus from "@/models/server/stripe/kycstatus/StripeKycStatus";
import StripeKycStatusItem from "@/models/server/stripe/kycstatus/StripeKycStatusItem";
import Localizer from "../../../../localization/Localizer";

import styles from "./Failed.module.scss";
import kycStyles from "../Kyc.module.scss";

import TransformProvider from "@/providers/TransformProvider";

interface IFailedProps {
    user: User;
    onButtonClick: (sender: Failed) => Promise<void>;
}

interface IFailedState {
}

export default class Failed extends BaseComponent<IFailedProps, IFailedState> {

    state: IFailedState = {};

    public get user(): User {
        return this.props.user;
    }

    public get stripeKycStatus(): StripeKycStatus {
        return this.user.stripeKycStatus!;
    }

    public render(): React.ReactNode {
        return (
            <div className={this.css(kycStyles.statusContainer, styles.failed)}>

                <div className={kycStyles.text}>
                    
                    <span>{Localizer.mobileKycPageFailedFailedMessageSpan}</span>

                    <div className={kycStyles.list}>

                        <h6>
                            {Localizer.mobileKycPageFailedInformationListHeading}
                        </h6>

                        <div>

                            <ul>
                                {
                                    (
                                        this.stripeKycStatus.currentlyDueDocumentItems?.map(item =>
                                            (
                                                <li>{TransformProvider.localizeStripeKycStatusItem(item)}</li>
                                            )
                                        )
                                    )
                                }
                            </ul>

                        </div>

                        <h6>
                            {Localizer.mobileKycPageFailedErrorListHeading}
                        </h6>

                        <div>

                            <ul>
                                {
                                    (
                                        this.stripeKycStatus.errorItems?.map((item: StripeKycStatusItem, index: number) =>
                                            (
                                                <li>{ReactUtility.toMultiLines(Localizer.mobileKycPageFailedErrorItemsList.format(TransformProvider.localizeStripeKycStatusItem(item), item.reason))}</li>
                                            )
                                        )
                                    )
                                }
                            </ul>

                        </div>

                    </div>

                </div>

                <div className={kycStyles.expander}/>

                <ButtonContainer>

                    <Button type={ButtonType.Primary}
                            icon={{name: "fas fa-arrow-alt-right"}}
                            label={Localizer.genericContinue}
                            onClick={() => this.props.onButtonClick(this)}
                    />

                </ButtonContainer>

            </div>
        );
    }
}