import React from "react";
import {BaseComponent, TextAlign} from "@reapptor-apps/reapptor-react-common";
import EstimatedBooking from "@/models/server/bout/EstimatedBooking";
import {Dropdown, DropdownOrderBy, SelectListItem, SelectListSeparator, TextAreaInput, TextInput} from "@reapptor-apps/reapptor-react-components";
import Booking from "@/models/server/bout/Booking";
import Waypoint from "@/models/server/bout/Waypoint";
import CruisePackageBooking from "@/models/server/cruise/CruisePackageBooking";
import CruisePackage from "@/models/server/cruise/CruisePackage";
import CruisePackagePoint from "@/models/server/cruise/CruisePackagePoint";
import {BookingType} from "@/models/Enums";
import AppConstants from "@/helpers/AppConstants";
import AppController from "@/pages/AppController";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";

import styles from "./WaypointInfo.module.scss";

import routeImage from "./images/Route.png";
import googleLogo from "@/pages/Mobile/BookingDetails/BookingInfo/BookingTicketModal/Images/google.png";

export interface IWaypointInfoProps {
    readonly?: boolean;
    booking: EstimatedBooking | Booking | CruisePackageBooking;
    onChange: (sender: WaypointInfo) => Promise<void>;
}

interface IWaypointInfoState {
}

export default class WaypointInfo extends BaseComponent<IWaypointInfoProps, IWaypointInfoState> {

    state: IWaypointInfoState = {
    };

    private async setCruisePackagePointAsync(point: CruisePackagePoint | SelectListItem): Promise<void> {
        if (this.cruisePackageBooking) {
            const customPoint: boolean = SelectListItem.is(point);

            this.cruisePackageBooking.customPoint = customPoint;
            this.cruisePackageBooking.point = (!customPoint) ? point as CruisePackagePoint : null;
            
            if (!customPoint) {
                this.cruisePackageBooking.customPointAddress = null;
            }

            await this.props.onChange(this);
        }
    }
    
    private async setCustomPointAddressAsync(address: string): Promise<void> {
        if (this.cruisePackageBooking) {
            
            this.cruisePackageBooking.customPointAddress = address;

            await this.props.onChange(this);
        }
    }

    public get item(): EstimatedBooking | Booking | CruisePackageBooking {
        return this.props.booking;
    }

    public get estimatedBooking(): EstimatedBooking | null {
        return EstimatedBooking.as(this.item);
    }

    public get booking(): Booking | null {
        return Booking.as(this.item);
    }

    public get cruisePackageBooking(): CruisePackageBooking | null {
        return CruisePackageBooking.as(this.item);
    }

    public get isBooking(): boolean {
        return Booking.is(this.item);
    }

    public get asPassenger(): boolean {
        return AppController.asPassenger;
    }

    public get isCruisePackageBooking(): boolean {
        return CruisePackageBooking.is(this.item);
    }

    public get cruisePackage(): CruisePackage | null {
        return this.cruisePackageBooking?.cruisePackage ?? null;
    }

    public get cruisePackagePoint(): CruisePackagePoint | null {
        return this.cruisePackageBooking?.point ?? this.booking?.cruisePackagePoint ?? null;
    }

    public get cruisePackagePointName(): string {
        return (this.customPoint)
            ? this.customPointAddress
            : (this.cruisePackagePoint?.location)
                ? TransformProvider.locationToString(this.cruisePackagePoint.location, true)
                : "";
    }

    public get cruisePackagePointItems(): SelectListItem[] {
        const points: CruisePackagePoint[] = this.cruisePackage?.points ?? [];

        const moneySymbol: string = this.cruisePackage?.area?.country?.moneySymbol ?? "";

        const items: SelectListItem[] = points.select(item => TransformProvider.toCruisePackagePointListItem(item, true, moneySymbol));

        const supportsCustomLocation: boolean = (this.cruisePackage?.customLocation == true);
        const customPointPrice: number = this.cruisePackage?.customLocationPrice ?? 0;

        if (supportsCustomLocation) {
            items.push(SelectListSeparator.instance);

            const customLocation = new SelectListItem();
            customLocation.text = Localizer.mobileWaypointInfoCustomerLocation;
            customLocation.value = "0";
            customLocation.subtext = (customPointPrice > 0)
                ? "+{0:C} {1}".format(customPointPrice, moneySymbol)
                : Localizer.mobileWaypointInfoAdditionalFeePossibility;

            items.push(customLocation);
        }

        return items;
    }

    public get customPoint(): boolean {
        return ((this.cruisePackageBooking?.customPoint === true) || (this.booking?.customPickupLocation === true));
    }

    public get customPointAddress(): string {
        return this.cruisePackageBooking?.customPointAddress ?? this.booking?.customPickupLocationAddress ?? "";
    }

    public get waypoint(): Waypoint | null {
        return this.estimatedBooking?.waypoint ?? this.booking?.waypoint ?? null;
    }

    private get readonly(): boolean {
        return ((this.props.readonly === true) || (this.isBooking));
    }

    private get editable(): boolean {
        return (!this.readonly);
    }

    public get title(): string {
        return ((this.cruisePackageBooking) || (this.booking?.bookingType == BookingType.CruisePackage))
            ? Localizer.mobileWaypointInfoPickupLocation
            : Localizer.mobileWaypointInfoTripSpan;
    }

    public render(): React.ReactNode {

        const waypoint: Waypoint | null = this.waypoint;
        const cruisePackagePoint: CruisePackagePoint | null = this.cruisePackagePoint;
        const areaName: string | null = this.cruisePackage?.area?.name || null;
        const mapsLink: string | null = (this.asPassenger) ? Waypoint.generateGoogleMapsLink(waypoint) : null;

        return (
            <div className={styles.waypointInfo}>

                <span>{this.title}</span>

                <div className={styles.context}>

                    {
                        (waypoint) &&
                        (
                            <div>
                                
                                <div className={styles.info}>
    
                                    <img src={routeImage} alt={"waypoint"}/>
    
                                    <div>
    
                                        <TextInput readonly
                                                   label={Localizer.mobileWaypointInfoTextInputRetriveFromLabel}
                                                   value={waypoint.source?.name}
                                        />
    
                                        <TextInput readonly
                                                   label={Localizer.mobileWaypointInfoTextInputDestinationLabel}
                                                   value={waypoint.destination?.name}
                                        />
    
                                    </div>
    
                                </div>

                                {
                                    (mapsLink) &&
                                    (
                                        <div className={styles.mapLink}>
                                            <img src={googleLogo} alt="Google" />
                                            <a href={mapsLink} target="_blank">{Localizer.bookingTicketModalFindYourWayToStop}</a>
                                        </div>
                                    )
                                }

                            </div>
                        )
                    }

                    {
                        ((this.isCruisePackageBooking) || (this.booking?.bookingType == BookingType.CruisePackage)) &&
                        (
                            <div>

                                {
                                    (this.editable)
                                        ?
                                        (
                                            <>
                                                <Dropdown id={"cruisePackagePoint"} required noSubtext
                                                          className={styles.cruisePackagePoint}
                                                          textAlign={TextAlign.Center}
                                                          disabled={this.readonly}
                                                          orderBy={DropdownOrderBy.None}
                                                          items={this.cruisePackagePointItems}
                                                          selectedItem={cruisePackagePoint?.id ?? "0"}
                                                          onChange={(_, item) => this.setCruisePackagePointAsync(item!)}
                                                />

                                                {
                                                    (this.customPoint) &&
                                                    (
                                                        <TextAreaInput id="customPointAddress" required autoFocus
                                                                       placeholder={Localizer.mobileWaypointInfoCustomPointSpecifyAddress.format(areaName)}
                                                                       maxLength={AppConstants.maxTitleDescriptionLength}
                                                                       rows={2}
                                                                       value={this.customPointAddress}
                                                                       onChange={(_, value) => this.setCustomPointAddressAsync(value)}
                                                        />
                                                    )
                                                }
                                            </>
                                        )
                                        :
                                        (
                                            (this.customPoint)
                                                ?
                                                (
                                                    <TextAreaInput id={"cruisePackagePoint"} readonly
                                                                   className={styles.cruisePackagePointName}
                                                                   rows={2}
                                                                   value={this.cruisePackagePointName}
                                                    />
                                                )
                                                :
                                                (
                                                    <TextInput id={"cruisePackagePoint"} readonly
                                                               className={styles.cruisePackagePointName}
                                                               value={this.cruisePackagePointName}
                                                    />
                                                )
                                        )
                                }

                            </div>
                        )
                    }

                </div>

            </div>
        );
    }
}