// autogenerated

export default class FindMyPushNotificationRequest {

    public messageId: string = "";

    public markAsRead: boolean = false;

    public readonly isFindMyPushNotificationRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof FindMyPushNotificationRequest) || ((from as any).isFindMyPushNotificationRequest === true));
    }

    public static as(from?: object | null): FindMyPushNotificationRequest | null {
        return (FindMyPushNotificationRequest.is(from)) ? from as FindMyPushNotificationRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}