import React from "react";
import CruisePackageWizard from "@/pages/Mobile/CruisePackageWizard/CruisePackageWizard";
import CruisePackage from "@/models/server/cruise/CruisePackage";
import CruisePackagesPanel from "@/components/CruisePackagesPanel/CruisePackagesPanel";
import CruisePackageWizardController from "@/pages/Mobile/CruisePackageWizard/CruisePackageWizardController";

import styles from "./SearchStep.module.scss";

export interface ISearchStepProps {
}

interface ISearchStepState {
    cruisePackages: CruisePackage[];
}

export default class SearchStep extends CruisePackageWizard<ISearchStepProps, ISearchStepState> {

    state: ISearchStepState = {
        cruisePackages: []
    };

    protected get contentClassName(): string {
        return styles.searchStep;
    }

    protected get buttonsClassName(): string {
        return styles.buttons;
    }

    public async selectOrderPackageAsync(cruisePackage: CruisePackage): Promise<void> {
        this.wizard.cruisePackage = cruisePackage;
        this.save();

        await super.nextAsync();
    }

    public get cruisePackages(): CruisePackage[] {
        return this.state.cruisePackages;
    }

    public get canNext(): boolean {
        return (this.wizard.cruisePackage != null);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const countryId: string | null = this.wizard.country?.id || null;
        const areaId: string | null = this.wizard.area?.id ?? null;

        const cruisePackages: CruisePackage[] = await CruisePackageWizardController.listCruisePackagesAsync(countryId, areaId);

        await this.setState({cruisePackages});
    }

    public renderHeader(title: string | null): React.ReactNode {
        return (
            <React.Fragment/>
        )
    }

    public renderContent(): React.ReactNode {
        return (
            <CruisePackagesPanel cruisePackages={this.cruisePackages}
                                 onSelectOrderPackage={(_, cruisePackage) => this.selectOrderPackageAsync(cruisePackage)}
            />
        );
    }
}